import { FC } from 'react';

import { TableBody } from '@mui/material';

import { EditPointsModalTableBodyProps } from './EditPointsModal.types';

export const EditPointsModalTableBody: FC<EditPointsModalTableBodyProps> = ({
  renderRows,
}) => {
  return <TableBody>{renderRows()}</TableBody>;
};
