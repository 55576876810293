import { useFilePreview } from '@/core/hooks';

import { ImagePreview } from './ImagePreview';
import { OtherPreview } from './OtherPreview';
import { PdfPreview } from './PdfPreview';
import { FilePreviewProps } from './types';

export const FilePreview = (props: FilePreviewProps) => {
  const { fileExtension, filePath, fileName, downloadFile } = useFilePreview(
    props.file,
    props.onClose,
  );

  if (
    ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(
      fileExtension.toLowerCase(),
    )
  ) {
    return (
      <ImagePreview
        filePath={filePath}
        fileName={fileName}
        onClose={props.onClose}
      />
    );
  }

  if (['pdf'].includes(fileExtension)) {
    return (
      <PdfPreview
        filePath={filePath}
        fileName={fileName}
        onClose={props.onClose}
      />
    );
  }

  return (
    <OtherPreview
      fileName={fileName}
      onClose={props.onClose}
      downloadFile={downloadFile}
    />
  );
};
