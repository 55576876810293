import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RowContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 20,
});

// @Move_to_core
export const PrimaryButton = styled(Button)({
  textTransform: 'none',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  height: '40px',
});

export const BoxContainer = styled(Box)({
  margin: 20,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});