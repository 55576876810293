import {
  KaizenSubmissionFragment,
  KaizenSubmissionFragmentDoc,
} from '@/__generated__/graphql.ts';
import { client } from '@/apollo.ts';

/**
 * Function to write a query of eflow step
 * @param data unknown - data to write
 * @param organizationId string
 */
export const writteSingleKaizenSubmissionFragment = (
  data: KaizenSubmissionFragment,
  organizationId: string,
) => {
  if (!data) return;

  client.writeFragment({
    fragment: KaizenSubmissionFragmentDoc,
    variables: {
      input: {
        organizationId,
        submissionId: data.id,
      },
    },
    data,
  });
};
