/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, CircularProgress, Slide, Snackbar } from '@mui/material';

import { checkGqlSystemRolesPermission } from '@core/utils';

import {
  GetBasicSystemUserQuery,
  useGetBasicSystemUserLazyQuery,
} from '@/__generated__/graphql.ts';
import {
  NoDataInfo,
  TitleContainer,
  UnitSection,
  UserSection,
} from '@/core/components';
import { PermissionsSection } from '@/core/components/UserEdit/PermissionSection/PermissionSection';
import { SettingsContainer } from '@/core/styles';
import { useOrganizationStore, useUserStore } from '@/stores';
import { getLowerRoles } from '@/utils';

type BasicUserInformationProps = {
  forSystemUser?: boolean;
};

export const BasicUserInformation = (props: BasicUserInformationProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { organization, setOrganization } = useOrganizationStore();
  const { organizationId: userOrganizationId } = useUserStore();
  const userGroups = useUserStore((state) => state.userGroups);

  const [canUserCanEditThisUser, setCanUserCanEditThisUser] = useState(false);
  const [organizationId, setOrganizationId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const [query, { data, refetch, error }] = useGetBasicSystemUserLazyQuery();

  useEffect(() => {
    void (async () => {
      if (props.forSystemUser) {
        setOrganizationId(userOrganizationId as string);
      } else if (params.companyName) {
        if (organization[params.companyName]) {
          setOrganizationId(organization[params.companyName]);
        } else {
          await setOrganization(params.companyName);
        }
      }

      if (params.id && organizationId) {
        void query({
          variables: {
            input: {
              id: params.id,
              organizationId: organizationId,
            },
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, userOrganizationId, organization, organizationId]);

  const [snapBarMessage, setSnapBarMessage] = useState<string>('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [employee, setEmployee] =
    useState<GetBasicSystemUserQuery['getEmployee']>();

  useEffect(() => {
    setIsSnackbarOpen(snapBarMessage !== '');
  }, [snapBarMessage]);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
      setEmployee(data.getEmployee);
      setCanUserCanEditThisUser(
        checkGqlSystemRolesPermission(
          data.getEmployee.systemRole,
          getLowerRoles(userGroups),
        ),
      );
    }
  }, [data, userGroups]);

  return (
    <>
      {isLoading && error == undefined ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {data != undefined ? (
            <TitleContainer title={t('systemSettings.basicInformation')}>
              <SettingsContainer>
                <UserSection
                  employee={data?.getEmployee}
                  isLoading={isLoading}
                  setToastMessage={setSnapBarMessage}
                  refetch={async () => await refetch()}
                  isReadOnly={!canUserCanEditThisUser}
                />
                <PermissionsSection
                  employee={data?.getEmployee}
                  isLoading={isLoading}
                  permissions={data?.getEmployee.systemRole || []}
                  setToastMessage={setSnapBarMessage}
                  refetch={async () => await refetch()}
                  isReadOnly={!canUserCanEditThisUser}
                />
                <UnitSection
                  employee={employee}
                  isLoading={isLoading}
                  setToastMessage={setSnapBarMessage}
                  refetch={async () => await refetch()}
                  isReadOnly={!canUserCanEditThisUser}
                />
                <Snackbar
                  open={isSnackbarOpen}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  TransitionComponent={(props) => (
                    <Slide {...props} direction="up" />
                  )}
                  message={snapBarMessage}
                  autoHideDuration={5000}
                  onClose={() => {
                    setSnapBarMessage('');
                  }}
                />
              </SettingsContainer>
            </TitleContainer>
          ) : (
            <Box
              sx={{
                width: '100%',
              }}
            >
              <NoDataInfo
                buttonText={t('common.return')}
                headerText={t('organization.noUser')}
                infoText={t('organization.noUserInfo')}
                onClick={() => {
                  const path = params.companyName
                    ? `/${params.companyName}/e-schema/employees`
                    : `/organizations/system-settings/system-users/`;
                  navigate(path);
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};
