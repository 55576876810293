import { Box, CircularProgress, styled } from '@mui/material';

export function Loading() {
  return (
    <Centered>
      <CircularProgress />
    </Centered>
  );
}

const Centered = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});
