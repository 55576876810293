import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ParameterForReport,
  ParametersTableTypes,
} from '@e-flow/pages/flowReport/components/ParametersTable/ParametersTable.types.ts';
import { SystemParameterKeysEnum } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum.ts';

import { ParameterTypes } from '@/__generated__/graphql.ts';
import { StatisticBox, StatisticRow } from '@/core/components';

export const ParametersTable = (props: ParametersTableTypes) => {
  const { t } = useTranslation('eflow');

  const renderRows = useCallback(() => {
    const rowsToRender: ReactNode[] = [];

    if (!props.params) return rowsToRender;

    const { params } = props;
    Object.keys(params).forEach((key) => {
      if (params[key as keyof typeof params]) {
        const element = params[
          key as keyof typeof params
        ] as unknown as ParameterForReport;

        rowsToRender.push(
          <StatisticRow
            key={key}
            rowValues={[element?.asIs, element?.toBe]}
            displayRowUnit={true}
            rowType={element.unit}
            rowLabel={
              element.type === ParameterTypes.System
                ? t(
                    `newAnalysis.parameters.systemParams.${key as SystemParameterKeysEnum}`,
                  )
                : key
            }
          />,
        );
      }
    });

    return rowsToRender;
  }, [props, t]);

  return (
    <StatisticBox
      title={t('reports.parametersAdd')}
      withCharts={false}
      withTable={true}
      tableHeaderTitles={[null, t('reports.asIs'), t('reports.toBe')]}
      headerAlignment={'right'}
      children={renderRows()}
    />
  );
};
