import { useTranslation } from 'react-i18next';

import { CommissionRole } from '@/core/enums';

export const useCommissionRole = () => {
  const { t } = useTranslation('kaizen');

  const getCommissionRole = (commissionRole: string) => {
    if (
      (commissionRole as CommissionRole) === CommissionRole.COMMISSION_LEADER
    ) {
      return t('commissionMemberStatus.COMMISSION_LEADER');
    }
    return t('commissionMemberStatus.COMMISSION_MEMBER');
  };

  return { getCommissionRole };
};
