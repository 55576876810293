import { useEffect, useState } from 'react';

import { isArray } from 'lodash';

import { useCompanyId } from '@core/hooks';

import {
  SingleSubunitDto,
  SystemRoles,
  useFindOrganizationSubunitsLazyQuery,
} from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

import { OrganizationUnitData } from './OraganizationUnitSelect.types';

export const useOrganizationUnitSelect = (
  multiple: boolean,
  {
    withSubUnits,
    onChange,
    organizationUnitId,
    defaultValue,
  }: {
    withSubUnits?: boolean;
    onChange: (unitId?: string | string[], loadDefault?: boolean) => void;
    organizationUnitId?: string;
    defaultValue?: SingleSubunitDto | SingleSubunitDto[];
  },
) => {
  const { companyId } = useCompanyId();
  const { userGroups } = useUserStore();

  const [unitData, setUnitData] = useState<OrganizationUnitData[]>([]);
  const [selectValue, setSelectValue] = useState<
    SingleSubunitDto | SingleSubunitDto[] | null
  >(multiple ? [] : null);

  const [isInitialDefaultValueSet, setIsInitialDefaultValueSet] =
    useState(true);

  const [
    loadOrganizationUnits,
    { data: organizationUnitData, loading: isOrganizationUnitsLoading },
  ] = useFindOrganizationSubunitsLazyQuery();

  const [
    loadAllOrganizationUnits,
    { data: allOrganizationUnitsData, loading: isAllOrgnizationUnitsLoading },
  ] = useFindOrganizationSubunitsLazyQuery();

  useEffect(() => {
    if (defaultValue && isInitialDefaultValueSet) {
      setIsInitialDefaultValueSet(false);

      const unit = multiple
        ? defaultValue
        : (defaultValue as SingleSubunitDto[])[0];
      onSelectChange(unit, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const loadData = async () => {
    if (!companyId) return;

    if (withSubUnits) {
      await loadOrganizationUnits({
        variables: {
          input: {
            organizationId: companyId,
            ...(organizationUnitId && { organizationUnitId }),
          },
        },
      });
      return;
    }
    await loadAllOrganizationUnits({
      variables: {
        input: {
          organizationId: companyId,
        },
      },
    });
  };

  useEffect(() => {
    void loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    let newData: SingleSubunitDto[] = [];
    if (withSubUnits && organizationUnitData) {
      newData = [...organizationUnitData.findOrganizationSubunits.subunits];
      newData.push(organizationUnitData.findOrganizationSubunits.rootUnit);
    } else if (allOrganizationUnitsData) {
      newData = [...allOrganizationUnitsData.findOrganizationSubunits.subunits];
      newData.push(allOrganizationUnitsData.findOrganizationSubunits.rootUnit);
    }
    const userUnit = newData.find(
      (subunit) => subunit.id == organizationUnitId,
    );

    if (userGroups.includes(SystemRoles.User)) {
      if (userUnit) setUnitData([userUnit]);
    } else {
      setUnitData(newData);
    }
  }, [
    allOrganizationUnitsData,
    organizationUnitData,
    organizationUnitId,
    withSubUnits,
    userGroups,
  ]);

  const onSelectChange = (
    unit: SingleSubunitDto | SingleSubunitDto[] | null,
    loadDefault?: boolean,
  ) => {
    setSelectValue(unit);
    const isUnitIdArray = isArray(unit);

    if (isUnitIdArray)
      return onChange(!unit ? [] : unit.map((unit) => unit.id), loadDefault);

    return onChange(unit?.id, loadDefault);
  };

  return {
    selectValue,
    unitData,
    isLoading: withSubUnits
      ? isOrganizationUnitsLoading
      : isAllOrgnizationUnitsLoading,
    onSelectChange,
    loadData,
  };
};
