import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { FlexCenter } from '@core/styles';

export const EmptyResult = () => {
  const { t } = useTranslation('kpi');

  return (
    <FlexCenter sx={{ height: '100%' }}>
      <Typography variant="h6" color="text.secondary">
        {t('table.noResults')}
      </Typography>
    </FlexCenter>
  );
};
