import { getDefaultUnits } from '@kpi/common';
import { TFunction } from 'i18next';

import { KpiUnitModelDto } from '@/__generated__/graphql';

export const mergeUnits = (
  kpiUnits: KpiUnitModelDto[],
  t: TFunction<'kpi', undefined>,
) => {
  const defaultUnits = getDefaultUnits(t);

  const unitMap = new Map<string, KpiUnitModelDto>();

  const createKey = (unit: KpiUnitModelDto) =>
    `${unit.unitName}-${unit.unitShortcut}`;

  kpiUnits.forEach((unit) => {
    const key = createKey(unit);
    if (!unitMap.has(key)) {
      unitMap.set(key, unit);
    }
  });

  defaultUnits.forEach((unit) => {
    const key = createKey(unit);
    if (!unitMap.has(key)) {
      unitMap.set(key, unit);
    }
  });

  return Array.from(unitMap.values());
};
