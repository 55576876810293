import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { SubmissionDetailsProps } from '../../SubmissionDetails.types';
import { DetailRow, DetailRowLabel } from '../styles/styles';

export const SubmissionSPSection = (props: SubmissionDetailsProps) => {
  const { t } = useTranslation('kaizen');
  return (
    <DetailRow>
      <DetailRowLabel>{t('submissionDetails.settlementPeriod')}</DetailRowLabel>
      <Typography>{props.periodName}</Typography>
    </DetailRow>
  );
};
