import React, { useMemo, useState } from 'react';

import { MenuWithSearchTypes } from './MenuWithSearch.types';
import { filterOptions } from './functions/FilterOptions';

export const useMenuWithSearch = <T,>(props: MenuWithSearchTypes<T>) => {
  const [anchor, setAnchor] = useState<Element | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const FilterOptions = useMemo(
    () => filterOptions(props, searchValue),
    [props, searchValue],
  );

  const handleClick = (e: MouseEvent) => {
    setAnchor(e.currentTarget as Element);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleMenuItemClick = (option: T) => {
    if (props.onSelect) {
      props.onSelect(option);
    }
    setAnchor(null);
  };

  return {
    anchor,
    setAnchor,
    searchValue,
    FilterOptions,
    handleClick,
    handleSearchChange,
    handleMenuItemClick,
  };
};
