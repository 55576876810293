import { useCallback, useEffect } from 'react';

import { FileModel } from '@/__generated__/graphql';

export const useFilePreview = (
  file: File | FileModel,
  handleClose?: () => void,
) => {
  const fileExtension = useCallback(() => {
    if (file instanceof File) return file.name.split('.').at(-1) as string;
    else return file.fileExtension;
  }, [file]);

  const fileName = useCallback(() => {
    if (file instanceof File)
      return file.name.split('.').slice(0, -1).join('.');
    else return file.fileName;
  }, [file]);

  const filePath = useCallback(() => {
    if (file instanceof File) return URL.createObjectURL(file);
    else return file.fileUrl;
  }, [file]);

  const downloadFile = useCallback(() => {
    if (file instanceof File) {
      const url = URL.createObjectURL(file);
      const a = document.createElement('a'); // Specify that you want to create an 'a' element
      a.href = url;
      a.download = file.name;
      a.click();
    } else {
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.href = file.fileUrl;
      a.setAttribute('download', file.fileName);
      a.click();
      document.body.removeChild(a);
      //TODO: it doesn't work :C
    }
  }, [file]);

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && handleClose) {
        handleClose();
      }
    });
  }, [handleClose]);

  return {
    fileExtension: fileExtension(),
    fileName: fileName(),
    filePath: filePath(),
    downloadFile,
  };
};
