import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemButtonTypes } from '@organizations/components/ListItemButton/ListItemButton.types.ts';

export const useListItemButton = (props: ListItemButtonTypes) => {
  const { t } = useTranslation();

  const getTitle = useMemo(() => {
    switch (props.organizationModule) {
      case 'global-permissions':
        return t('organizationSettings.globalPermissions');
      case 'data':
        return t('organizationSettings.organizationData');
      default:
        return t(`organizationModules.${props.organizationModule}`);
    }
  }, [props.organizationModule, t]);

  const hasModule = useMemo(() => {
    switch (props.organizationModule) {
      case 'global-permissions':
      case 'data':
        return true;
      default:
        return props.organizationModules?.includes(props.organizationModule);
    }
  }, [props.organizationModule, props.organizationModules]);

  return {
    hasModule: hasModule,
    title: getTitle,
  };
};
