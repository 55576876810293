import { Stack, TableContainer } from '@mui/material';

import { LabeledPieChart, StatisticTable } from '@core/components';

import { ChartWrapper } from '../ChartWrapper';
import { StatisticBoxTypes } from './StatisticBox.types.ts';

export const StatisticBox = (props: StatisticBoxTypes) => {
  return (
    <ChartWrapper title={props.title}>
      <Stack flexDirection="row" alignItems="center" gap="30px">
        {props.withCharts &&
          props.chartsToRender.map((chart, index) => (
            <LabeledPieChart key={index} {...chart} />
          ))}
      </Stack>
      {props.withTable && (
        <TableContainer>
          {
            <StatisticTable
              headerAlignment={props.headerAlignment}
              tableHeaderTitles={props.tableHeaderTitles}
            >
              {props.children}
            </StatisticTable>
          }
        </TableContainer>
      )}
    </ChartWrapper>
  );
};
