/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, useRef, useState } from 'react';
import {
  Responsive,
  ResponsiveProps,
  WidthProvider,
  WidthProviderProps,
} from 'react-grid-layout';

import { isEqual } from 'lodash';

import { Box, styled } from '@mui/material';

import { theme } from '@/theme';

import { GridBackgroundBoxes } from '../GridBackgroundBoxes/GridBackgroundBoxes';
import { CustomDashboardProps } from './CustomDashboard.types';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const CustomDashboard = (props: CustomDashboardProps) => {
  const layoutRef =
    useRef<Component<ResponsiveProps & WidthProviderProps, any, any>>(null);

  const [isGridDisplayed, setIsGridDisplayed] = useState(false);
  const [colsNumber, setColsNumber] = useState(8);

  return (
    <RelativeBox>
      <GridBackgroundBoxes
        displayGrid={isGridDisplayed}
        colsNumber={colsNumber}
      />
      <ResponsiveGridLayout
        ref={layoutRef}
        layouts={props.layouts}
        onLayoutChange={(layout) => {
          if (isEqual(layout, props.layouts)) {
            return;
          }
          props.onLayoutChange && props.onLayoutChange(layout);
        }}
        className="layout"
        draggableHandle=".drag-icon"
        rowHeight={200}
        isResizable={props.isAdjustable}
        isDraggable={props.isAdjustable}
        onDragStart={() => setIsGridDisplayed(true)}
        onDragStop={() => setIsGridDisplayed(false)}
        breakpoints={{ md: 1200, sm: 768, xs: 0 }}
        onBreakpointChange={(_newBreakpoint, newCols) => setColsNumber(newCols)}
        cols={{ lg: 8, md: 6, sm: 4, xs: 2 }}
        margin={[16, 16]}
      >
        {props.children}
      </ResponsiveGridLayout>
    </RelativeBox>
  );
};

const RelativeBox = styled(Box)({
  position: 'relative',
  width: '100%',
  minHeight: 'calc(100vh - 122px)',
  backgroundColor: theme.palette.grey[25],
});
