import { useTranslation } from 'react-i18next';

import { useReportSingleKpiModalContext } from '@kpi/contexts/useReportSingleKpiModalContext.tsx';

import { Typography } from '@mui/material';

import { Button } from '@core/components';
import { NotificationContainer, NotificationHeader } from '@core/modules';
import { useKpiNotification } from '@core/modules/Notifications/hooks';
import { KpiNotificationsType } from '@core/modules/Notifications/types/KpiNotifications.type.ts';

export const KpiReportSchedule = (props: KpiNotificationsType) => {
  const { t } = useTranslation();

  const { openReportModalWithDefaultDate } = useReportSingleKpiModalContext();

  const { notificationTextTitle, notificationTitle, isKpiMissing } =
    useKpiNotification(props);

  return (
    <NotificationContainer onClick={props.onClick}>
      <NotificationHeader
        sentAt={props.sentAt}
        title={notificationTitle}
        isOpened={props.isOpened}
      />
      <Typography variant="bodyRegular">{notificationTextTitle}</Typography>
      <Button
        disabled={!isKpiMissing}
        textVariant="bodyRegular"
        sx={{ zIndex: 1999999999 }}
        onClick={() => {
          const dueDate = new Date(props.body.dueDate);

          if (isNaN(dueDate.getTime())) return;

          dueDate.setMonth(dueDate.getMonth() - 1);
          openReportModalWithDefaultDate(
            props.body.kpiId,
            dueDate.toISOString(),
          );
        }}
      >
        {t('notification.buttonReportKpi')}
      </Button>
    </NotificationContainer>
  );
};
