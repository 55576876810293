import { useEffect, useState } from 'react';

import { PeriodProps } from '@kpi/components/KpiRatesTable/KpiFilters/types.ts';

import { SelectChangeEvent } from '@mui/material';

export const usePeriodSelect = ({ onChange, value }: PeriodProps) => {
  const [selectedValue, setSelectedValue] = useState(value || '');

  useEffect(() => {
    setSelectedValue(value ? value : '');
  }, [value]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  return {
    selectedValue,
    handleChange,
  };
};
