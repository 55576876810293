import { useMemo } from 'react';

import { useCheckUserPermission } from '@core/hooks';

import { DashboardTypesEnum, SystemRoles } from '@/__generated__/graphql';

export const useWidgetMenuPermission = (type: DashboardTypesEnum) => {
  const { UnitAdmin, Administrator, KeyUser } = SystemRoles;

  const {
    KpiEmployeeDashboard,
    KpiOrganizationUnitDashboard,
    KpiOrganizationDashboard,
    UserDashboard,
  } = DashboardTypesEnum;

  const checkUserPermissions = useCheckUserPermission();

  const isUserPermittedToEdit = useMemo(() => {
    switch (type) {
      case KpiOrganizationDashboard:
        return checkUserPermissions([KeyUser, Administrator, UnitAdmin]);

      case KpiOrganizationUnitDashboard:
        return checkUserPermissions([UnitAdmin, Administrator, KeyUser]);

      case KpiEmployeeDashboard:
        return true;

      case UserDashboard:
        return true;

      default:
        return false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return { isUserPermittedToEdit };
};
