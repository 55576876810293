import { Trans, useTranslation } from 'react-i18next';

import { ErrorDialogTypes } from '@e-flow/pages/flowSteps/components/ErrorDialog/errorDialog.types.ts';

import { Box, Typography, styled } from '@mui/material';

import { Button, CustomModal, ModalContainer } from '@core/components';

import { theme } from '@/theme';

export const ErrorDialog = (props: ErrorDialogTypes) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  return (
    <CustomModal display={props.display}>
      <ModalContainer
        closeModal={props.closeModal}
        headerText={props.headerText}
        sx={{ ...props.sx, minWidth: '500px' }}
      >
        <ModalBody>
          <CustomInformation>
            <Trans t={t} i18nKey={'flowSteps.validationErrors.description'} />
            <ButtonContainer>
              <Button
                color={'error'}
                variant={'contained'}
                onClick={props.onRemove}
              >
                {t('flowSteps.deleteStep')}
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={props.closeModal}
                disabled={false}
                sx={{
                  color: theme.palette.grey[600],
                }}
              >
                {commonT('common.continue')}
              </Button>
            </ButtonContainer>
          </CustomInformation>
        </ModalBody>
      </ModalContainer>
    </CustomModal>
  );
};

const ModalBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '0 20px 20px',
});

const CustomInformation = styled(Typography)({
  whiteSpace: 'pre-wrap',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
  gap: 10,
});
