import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { UnitSelect } from '@kpi/components';
import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';
import { RowStack } from '@organizations/styles';

import { Stack, Switch, Typography } from '@mui/material';

import { AddNewActiveUnit } from './components';
import { useUnit } from './hooks';

export const UnitSection = () => {
  const { t } = useTranslation('kpi');
  const {
    useManageKpiModalState: { errors },
  } = useHandleAddKpiRateContext();

  const {
    isCreateNewUnitActive,
    handleSwitchChange,
    setNewUnitFromSelect,
    defaultValue,
    isLoading,
  } = useUnit();

  return (
    <>
      <UnitContainer>
        {!isLoading && (
          <UnitSelect
            sx={{ width: '160px' }}
            disabled={isCreateNewUnitActive}
            defaultValue={defaultValue}
            onChange={setNewUnitFromSelect}
            error={!!errors?.unit?.unitName?.message}
            helperText={errors?.unit?.unitName?.message}
          />
        )}
        <StyledRowStack>
          <Switch
            onChange={handleSwitchChange}
            checked={isCreateNewUnitActive}
          />
          <Typography variant="bodyRegular">
            {t('addKpi.addNewUnit')}
          </Typography>
        </StyledRowStack>
      </UnitContainer>

      <AddNewActiveUnit isCreateNewUnitActive={isCreateNewUnitActive} />
    </>
  );
};

const UnitContainer = styled(Stack)({
  gap: '24px',
  flexDirection: 'row',
  alignItems: 'flex-start',
  height: '48px',
});

const StyledRowStack = styled(RowStack)({
  gap: '8px',
});
