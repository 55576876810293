import { ApolloCache } from '@apollo/client';

import {
  GetEmployeesForOrganizationDocument,
  GetEmployeesForOrganizationQuery,
  OperationNameEnum,
  SystemRoles,
} from '@/__generated__/graphql';

import { WriteReadUsersQuery } from './useChangePermissionsUsersCache.type';

export const useChangePermissionsUsersCache = () => {
  const updateCache = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cache: ApolloCache<any>,
    data: WriteReadUsersQuery,
    ids: string[],
    systemRoles: SystemRoles[],
    operation: OperationNameEnum,
  ) => {
    // TODO: @bryndalski @swierczynskigor to fix (it doesn't work at all :D)
    // existing Data is always null
    try {
      const existingData: GetEmployeesForOrganizationQuery | null =
        cache.readQuery({
          query: GetEmployeesForOrganizationDocument,
          variables: {
            input: {
              organisationId: data.organizationId,
              limit: data.limit,
              cursor: data.cursor,
              systemRoles: [],
            },
          },
        });

      if (!existingData || !existingData.getEmployees) {
        return;
      }

      const updatedItems = existingData.getEmployees.items.map((employee) => {
        if (ids.includes(employee.id)) {
          return {
            ...employee,
            systemRole:
              operation === OperationNameEnum.Add
                ? [...employee.systemRole, ...systemRoles]
                : employee.systemRole.filter(
                    (role) => !systemRoles.includes(role),
                  ),
          };
        }
        return employee;
      });

      const updatedItemsFiltered = updatedItems.filter((employee) =>
        employee.systemRole.some((role) => systemRoles.includes(role)),
      );

      cache.writeQuery({
        query: GetEmployeesForOrganizationDocument,
        variables: {
          input: {
            organisationId: data.organizationId,
            search: data.search,
            cursor: data.cursor,
            limit: data.limit,
            systemRoles: systemRoles,
          },
        },
        data: {
          getEmployees: {
            items: updatedItemsFiltered,
            total: updatedItemsFiltered.length,
            __typename: 'FindAllEmployees',
          },
        },
      });

      cache.writeQuery({
        query: GetEmployeesForOrganizationDocument,
        variables: {
          input: {
            organisationId: data.organizationId,
            search: data.search,
            cursor: data.cursor,
            limit: data.limit,
            systemRoles: [],
          },
        },
        data: {
          getEmployees: {
            items: updatedItems,
            total: updatedItems.length,
            __typename: 'FindAllEmployees',
          },
        },
      });
    } catch (error) {
      throw 'Error updating cache:' + (error as string);
    }
  };

  return { updateCache };
};
