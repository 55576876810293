import React from 'react';

import { NavbarTypes } from '@core/components/Navbar/Navbar.types.ts';
import { NavbarNavLink, NavbarNavigation } from '@core/styles';

export const NavbarTabs = ({ tabs }: NavbarTypes) => {
  return (
    <NavbarNavigation>
      {tabs?.map((tab) => (
        <NavbarNavLink
          key={tab.tabName}
          to={tab.tabDirection}
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          {tab.tabName}
        </NavbarNavLink>
      ))}
    </NavbarNavigation>
  );
};
