import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { EmployeeAvatar } from '@/core/components';

import { CustomMenu, OrganizationsRowProps } from '.';

export const OrganizationsRow = ({
  row,
  setOrganizationToDelete,
}: OrganizationsRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { t } = useTranslation();

  const navigation = useNavigate();

  return (
    <>
      <TableRow key={row.name}>
        <TableCell align="left" height={'48px'}>
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            onClick={() => navigation(`/${row.url}/`)}
            sx={{ cursor: 'pointer' }}
          >
            <EmployeeAvatar name={row.name} imageUrl={row.logo} size={24} />
            &nbsp;
            <CustomTopography>{row.name}</CustomTopography>
          </Stack>
        </TableCell>
        <TableCell align="left">
          <CustomTopography>
            {row.createdAt
              ? new Date(row.createdAt).toLocaleDateString('pl-PL')
              : t('common.noData')}
          </CustomTopography>
        </TableCell>
        <TableCell align="left">
          <CustomTopography>{row.modules.join(', ')}</CustomTopography>
        </TableCell>

        <TableCell align="right">
          <IconButton
            aria-label="more"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
          <CustomMenu
            organization={row}
            anchorEl={anchorEl}
            open={isMenuOpen}
            handleDeleteOrganization={(id, name) =>
              setOrganizationToDelete({
                id,
                name,
              })
            }
            handleClose={() => setAnchorEl(null)}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
