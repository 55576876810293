import { cloneElement } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
} from '@mui/material';

import { MenuWithSearchTypes } from '@core/components/Menus/MenuWithSearch/MenuWithSearch.types';

import { FlexCenter } from '@/core/styles';

import { MenuItems } from '../MenuItems/MenuItems';
import { useMenuWithSearch } from './useMenuWithSearch';

export const MenuWithSearch = <T,>(props: MenuWithSearchTypes<T>) => {
  const {
    anchor,
    setAnchor,
    searchValue,
    FilterOptions,
    handleClick,
    handleSearchChange,
    handleMenuItemClick,
  } = useMenuWithSearch(props);

  return (
    <>
      {cloneElement(props.fireElement, {
        onClick: handleClick,
      })}
      <Menu
        anchorEl={anchor}
        onScroll={props?.onScroll}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        {...props.menuProps}
        sx={{
          overflow: 'hidden',
        }}
        onSelect={(e) => e.preventDefault()}
      >
        <Input
          sx={{
            margin: 2,
            width: '90%',
          }}
          value={searchValue}
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color={'primary'} />
            </InputAdornment>
          }
        />
        {props.isLoading ? (
          <MenuItem disabled>
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          </MenuItem>
        ) : (
          <MenuItems
            handleMenuItemClick={handleMenuItemClick}
            options={FilterOptions}
            menuItemBody={props.menuItemBody}
          />
        )}
      </Menu>
    </>
  );
};
