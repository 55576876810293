import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsLastPathReport = () => {
  const location = useLocation();

  const isLastPathReport = useMemo(() => {
    const paths = location.pathname.split('/');
    return paths[paths.length - 1] === 'report';
  }, [location.pathname]);

  return isLastPathReport;
};
