import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentViewEnum } from '@kpi/modals/HandleKpiChartModal/enum';

export const useModalView = () => {
  const [currentView, setCurrentView] = useState<CurrentViewEnum>(
    CurrentViewEnum.NEW_CHART_SETTINGS,
  );

  const { t } = useTranslation('kpi');

  const modalTitleKey = (modalVariant: 'add' | 'update') => {
    if (currentView === CurrentViewEnum.NEW_CHART_SETTINGS) {
      if (modalVariant === 'add') {
        return t('charts.addChart');
      }

      return t('charts.updateCharts');
    }

    return t('charts.kpi');
  };

  return {
    currentView,
    modalTitleKey,
    isSettingsView: currentView === CurrentViewEnum.NEW_CHART_SETTINGS,
    isSelectKpisView: currentView === CurrentViewEnum.SELECT_KPIS,
    setSettingsView: () => setCurrentView(CurrentViewEnum.NEW_CHART_SETTINGS),
    setSelectKpisView: () => setCurrentView(CurrentViewEnum.SELECT_KPIS),
  };
};
