import { SynchronizedFlowsActionTypes } from '@e-flow/hooks/useSynchronizedFlows/SynchronizedFlowsAction.enum.ts';
import { fromIndexDB } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/fromIndexDB.ts';
import { EflowStepWithFiles } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';
import { IDBPObjectStore } from 'idb';
import { omit } from 'lodash';

import {
  CreateEflowStepInput,
  UpdateEflowStepInput,
} from '@/__generated__/graphql.ts';

export type ReturnTypeForCreate = {
  eFlowStep: CreateEflowStepInput;
  files: File[];
  actionType: SynchronizedFlowsActionTypes.CREATE;
  eFlowStepId: string;
};

export type ReturnTypeForUpdate = {
  eFlowStep: UpdateEflowStepInput;
  files: File[];
  eFlowStepId: string;
  actionType: SynchronizedFlowsActionTypes.UPDATE;
};

export type ReturnTypeForDelete = {
  eFlowStep: {
    id: string;
  };
  eFlowStepId: string;
  actionType: SynchronizedFlowsActionTypes.DELETE;
};

export type SynchronizeEflowStepReaderReturnType =
  | ReturnTypeForCreate
  | ReturnTypeForUpdate
  | ReturnTypeForDelete;

export const PrepareRecordForSynchronization = async (
  eFlowStepId: string,
  store: IDBPObjectStore,
  actionType: SynchronizedFlowsActionTypes,
): Promise<SynchronizeEflowStepReaderReturnType> => {
  if (actionType === SynchronizedFlowsActionTypes.DELETE) {
    return {
      eFlowStep: {
        id: eFlowStepId,
      },
      eFlowStepId,
      actionType,
    };
  }

  const eFlowStep = await store.get(eFlowStepId);

  if (!eFlowStep) {
    throw new Error(`EFLOW_STEP_NOT_FOUND ${eFlowStepId}`);
  }

  let files: File[] = [];
  let eFlowStepInput = eFlowStep;

  if ('localFiles' in eFlowStep) {
    const conversionResult = fromIndexDB(eFlowStep as EflowStepWithFiles);
    files = conversionResult.files;
    eFlowStepInput = conversionResult.eFlowStep;
  }

  if ('id' in eFlowStepInput) {
    let files: File[] = [];

    if ('localFiles' in eFlowStep) {
      const conversionResult = fromIndexDB(eFlowStep as EflowStepWithFiles);
      files = conversionResult.files;
    }

    return {
      eFlowStep: omit(eFlowStepInput, ['localFiles']) as UpdateEflowStepInput,
      files: files,
      eFlowStepId,
      actionType: SynchronizedFlowsActionTypes.UPDATE,
    };
  }

  return {
    eFlowStep: omit(eFlowStepInput, [
      'localFiles',
    ]) as EflowStepWithFiles as CreateEflowStepInput,
    files: files || [],
    eFlowStepId,
    actionType: SynchronizedFlowsActionTypes.CREATE,
  };
};
