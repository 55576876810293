import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, styled } from '@mui/material';

import { AiSidebar } from '@/pages/AI/components/Sidebar/AiSidebar.tsx';

import { Navbar } from '../components/index.ts';

export const AILayout = () => {
  const [sidebar, setSidebar] = useState<string>('');

  return (
    <div
      style={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Navbar />
      <StyledWithSidebar>
        <AiSidebar setSidebar={(url: string) => setSidebar(url)} />
        <StyledOutlet>
          <Outlet context={sidebar} />
        </StyledOutlet>
      </StyledWithSidebar>
    </div>
  );
};

const StyledWithSidebar = styled(Box)`
  display: flex;
  height: 100%;
  flex-direction: row;
  max-width: 100%;
`;

const StyledOutlet = styled(Box)`
  overflow: auto;
  height: 100%;
`;
