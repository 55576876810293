import { useState } from 'react';

import { useResourceOwner } from '@core/hooks';

import { SingleCommentTypes } from '@/pages/e-flow/components/SingleComment/SingleComment.types';

export const useComment = (commentProps: SingleCommentTypes) => {
  const { isOwner } = useResourceOwner(commentProps.createdBy.id);
  const [isBeeingEdited, setIsBeeingEdited] = useState(false);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  return {
    canEdit: isOwner,
    menuAnchorEl,
    setMenuAnchorEl,
    handleCloseMenu,
    isBeeingEdited,
    handleStartEdit: () => setIsBeeingEdited(true),
    handleCloseEdit: () => setIsBeeingEdited(false),
  };
};
