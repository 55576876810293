import { captureException } from '@sentry/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import {
  DashboardFragment,
  useUpdateDashboardLayoutMutation,
} from '@/__generated__/graphql.ts';

export const useDashboardDisplayOptions = (
  setDashboard: Dispatch<SetStateAction<DashboardFragment | undefined>>,
  dashboardId?: string,
) => {
  const [updateLayoutMutation, { loading: isDisplayOptionsLoading }] =
    useUpdateDashboardLayoutMutation();

  const { t } = useTranslation('dashboard');

  const { companyId } = useCompanyId();

  const { openSnackbar } = useSnackbarContext();

  const updateDisplayOptions = async (key: string, value: boolean) => {
    try {
      const { data } = await updateLayoutMutation({
        variables: {
          input: {
            dashboardId: dashboardId!,
            organizationId: companyId,
            additionalInfo: { [key]: value },
          },
        },
      });

      if (data) {
        setDashboard(data.updateDashboardLayout);
      }
    } catch (e) {
      openSnackbar(t('updateDashboard.error'));
      captureException(e);
    }
  };

  return {
    updateDisplayOptions,
    isDisplayOptionsLoading,
  };
};
