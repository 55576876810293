import { FC, useState } from 'react';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { AddCellModal } from '@organizations/components';
import { t } from 'i18next';

import { Modal, Snackbar, Typography, styled, useTheme } from '@mui/material';

import { NoStructureProps } from './types';

export const NoStructure: FC<NoStructureProps> = ({ handleAddUnit }) => {
  const theme = useTheme();

  const [isAddCellModalVisible, setIsAddCellModalVisible] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const openSnackbar = (message: string) => {
    setIsSnackbarOpen(true);
    setSnackbarMessage(message);
  };
  const closeAddCellModal = () => {
    setIsAddCellModalVisible(false);
  };
  return (
    <>
      <Modal open={isAddCellModalVisible}>
        <AddCellModal
          headerText={t('systemSettings.addOrganizationUnit')}
          closeModal={closeAddCellModal}
          openSnackbar={openSnackbar}
          onUnitAdded={(unit) => handleAddUnit(unit)}
        />
      </Modal>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => {
          setIsSnackbarOpen(false);
        }}
        message={snackbarMessage}
      />
      <Typography variant="h3">
        {t('organizationSettings.noStructureTitle')}
      </Typography>
      <CustomTypography>
        {t('organizationSettings.noStructureMessage')}
      </CustomTypography>
      <PrimaryButton
        variant="contained"
        onClick={() => setIsAddCellModalVisible(true)}
        type={'submit'}
        sx={{
          backgroundColor: theme.palette.primary[600],
          marginTop: '12px',
          width: '156px',
        }}
      >
        <Typography variant="bodyRegular">
          {t('organizationSettings.addNewCell')}
        </Typography>
      </PrimaryButton>
    </>
  );
};
const CustomTypography = styled(Typography)({
  fontSize: '14px',
  width: '540px',
  height: '40px',
  marginTop: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#333333',
});
