import { TFunction } from 'i18next';
import { z } from 'zod';

export const AddCellModalValidationSchema = (t: TFunction) =>
  z.object({
    cellName: z.string().min(1, t('error.requiredField')),
  });

export const AddCellModalValidationWithUnitSchema = (t: TFunction) =>
  AddCellModalValidationSchema(t).and(
    z.object({
      organizationUnitGuid: z.string().min(1, t('error.requiredField')),
    }),
  );
