import { TFunction } from 'i18next';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { Variant } from './MenuItems.types';

export const variants = (t: TFunction): Variant => ({
  delete: {
    text: t('common.delete'),
    icon: <DeleteOutlineIcon />,
  },
  share: {
    text: t('common.share'),
    icon: <ShareOutlinedIcon />,
  },
  duplicate: {
    text: t('common.duplicate'),
    icon: <ContentCopyOutlinedIcon />,
  },
  markAsDone: {
    text: t('common.markAsDone'),
    icon: <CheckOutlinedIcon />,
  },
  hide: {
    text: t('common.hide'),
    icon: <VisibilityOffOutlinedIcon />,
  },
  edit: {
    text: t('common.edit'),
    icon: <EditOutlinedIcon />,
  },
});
