import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AddEmployeeModalValidationSchema } from '@e-schema/components/AddEmployeeModal/AddEmployeeModal.schema.ts';
import { AddEmployeeModalDefaultValues } from '@e-schema/components/AddEmployeeModal/AddEmployeeModalDefaultValues.ts';
import { PrimaryButton } from '@e-schema/styles/Employee';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';

import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Autocomplete,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import {
  CreateEmployeeInput,
  EmployeeFragmentFragment,
  useCreateEmployeeMutation,
  useFindSingleOrganizationPositionsQuery,
} from '@/__generated__/graphql';
import {
  ModalContainer,
  PhoneTextField,
  SelectCellModal,
} from '@/core/components';
import { useOrganizationStore } from '@/stores';
import { TreeTarget } from '@/types';

import type { Props } from './EmployeeModal.types';

export const EmployeeModal = ({
  closeModal,
  organizationUnitCell,
  openSnackbar,
  onEmployeeAdded,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { organization } = useOrganizationStore();
  const { companyName } = useParams();

  const { data: organizationPositions } =
    useFindSingleOrganizationPositionsQuery({
      variables: {
        input: {
          url: companyName!,
        },
      },
    });

  const [createEmployee, { loading: isLoading }] = useCreateEmployeeMutation();

  const [isSend, setIsSend] = useState(false);
  const [organizationUnit, setOrganizationUnit] = useState<TreeTarget>();

  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
    setError,
    setValue,
    control,
    reset,
  } = useForm({
    defaultValues: AddEmployeeModalDefaultValues,
    resolver: zodResolver(AddEmployeeModalValidationSchema(t)),
  });

  useEffect(() => {
    if (!organizationUnitCell) return;
    setValue('organizationUnitGuid', organizationUnitCell.targetId);
  }, [organizationUnitCell, setValue]);

  const onSubmit: SubmitHandler<CreateEmployeeInput> = async (data) => {
    try {
      const response = await createEmployee({
        variables: {
          createEmployeeInput: {
            ...data,
            organizationId: organization[companyName!],
          },
        },
      });

      if (openSnackbar && response.data?.createEmployee?.id) {
        const tempOrganizationUnit = getValues('organizationUnitGuid');
        openSnackbar(t('employees.employeeCreated'));
        reset();
        setValue('organizationUnitGuid', tempOrganizationUnit);
        if (onEmployeeAdded)
          onEmployeeAdded(
            response.data.createEmployee as unknown as EmployeeFragmentFragment,
          );
      }
      closeModal();
    } catch (error) {
      if (error instanceof Error && error.message.includes('EMAIL_TAKEN'))
        setError('email', {
          type: 'validate',
          message: t('systemSettings.emailUsed'),
        });
      else if (error instanceof Error && error) {
        const errorCode = JSON.parse(error.message).statusCode;
        if (errorCode < 300) return;
        openSnackbar(t('errors.errorAppeardTryAgain'));
        closeModal();
      }

      Sentry.captureException(error);
    }
  };

  //close alert
  const closeAlert = () => {
    setIsSend(false);
  };

  return (
    <>
      <ModalContainer
        closeModal={closeModal}
        headerText={t('employees.addEmployee')}
        height="auto"
      >
        {isSend ? (
          <Alert
            variant="outlined"
            severity="error"
            sx={{
              background: theme.palette.error[100],
              margin: '0 24px',
            }}
            action={
              <IconButton onClick={closeAlert} size="small">
                <CloseIcon />
              </IconButton>
            }
          >
            {t('error.empty')}
          </Alert>
        ) : null}
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack padding="24px">
            <Stack gap="24px">
              <Stack justifyContent="space-between" direction="row">
                <TextField
                  {...register('firstName')}
                  sx={{ flex: '0.49' }}
                  size="small"
                  label={t('employees.name')}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
                <TextField
                  {...register('lastName')}
                  sx={{ flex: '0.49' }}
                  size="small"
                  label={t('employees.lastName')}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              </Stack>
              <TextField
                {...register('email')}
                fullWidth
                size="small"
                label={t('employees.email')}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <PhoneTextField
                error={!!errors.phone}
                helperText={errors.phone?.message}
                defaultCountry={'pl'}
                field={{
                  ...register('phone'),
                  value: getValues('phone'),
                  onBlur: () => {},
                  onChange: (e: string) => {
                    setValue('phone', e);
                  },
                }}
                sx={{ width: '0.49' }}
                size="small"
                label={t('systemSettings.phoneNumber')}
              />

              <SelectCellModal
                value={organizationUnit?.path || ''}
                setValue={(value: TreeTarget) => {
                  setOrganizationUnit(value);
                  setValue('organizationUnitGuid', value.targetId);
                }}
                error={!!errors.organizationUnitGuid}
                helperText={errors.organizationUnitGuid?.message}
                label={t('employees.cell')}
                disabled={!!organizationUnitCell?.targetId}
                organizationUnit={organizationUnitCell}
              />
              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    options={
                      organizationPositions?.findSingleOrganization
                        ?.positions || []
                    }
                    value={field.value?.length ? field.value : null}
                    onChange={(_e, val) => {
                      field.onChange(val || null);
                    }}
                    noOptionsText={t('error.noResult')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('employees.position')}
                        error={!!errors.position}
                        helperText={errors.position?.message}
                      />
                    )}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack
            padding="16px 24px 24px 24px"
            justifyContent="flex-end"
            gap="12px"
            direction="row"
          >
            <PrimaryButton onClick={closeModal}>
              <Typography>{t('common.cancel')}</Typography>
            </PrimaryButton>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <PrimaryButton
                data-testid="addButtonModal"
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary[600],
                }}
                type="submit"
              >
                <Typography>{t('employees.addEmployee')}</Typography>
              </PrimaryButton>
            )}
          </Stack>
        </form>
      </ModalContainer>
    </>
  );
};
