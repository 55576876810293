import { createTheme } from '@mui/material';
import { plPL } from '@mui/material/locale';
import { plPL as pickerPlPL } from '@mui/x-date-pickers/locales';

import { palette } from './pallete';
import { typography } from './typography';

export const theme = createTheme(
  {
    palette,
    typography,
  },
  plPL,
  pickerPlPL,
);

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bodySemibold: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    labelMdRegular: React.CSSProperties;
    labelSmRegular: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodySemibold?: React.CSSProperties;
    bodyMedium?: React.CSSProperties;
    bodyRegular?: React.CSSProperties;
    labelMdRegular?: React.CSSProperties;
    labelSmRegular?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodySemibold?: true;
    body1: false;
    bodyMedium?: true;
    bodyRegular?: true;
    labelMdRegular?: true;
    labelSmRegular?: true;
    captionRegular?: true;
  }
}
