import type { ReactNode } from 'react';

import { styled } from '@mui/material';

interface IProps {
  /**
   * Function to be called when button is clicked
   */
  onClick: () => void;
  /**
   * Label of the button
   * @example 'Send code'
   */
  children: ReactNode | string;
  /**
   * Whether the button is disabled
   * @example true
   */
  disabled: boolean;
}

export const PlainButton = ({ onClick, children, disabled }: IProps) => {
  return (
    <StyledButton type={'button'} disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled('button')({
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
