import { z } from 'zod';

export const EFlowSchema = z.object({
  startDate: z.date(),
  analyzeLeadersIds: z
    .array(
      z.object({
        id: z.string().uuid(),
        organizationId: z.string().uuid(),
      }),
    )
    .optional(),
});
