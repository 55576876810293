/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMemo } from 'react';

import {
  Skeleton,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { useChartRow } from './hooks/useChartRow';
import { ChartRowProps } from './types';

export const ChartRow = ({ chart }: ChartRowProps) => {
  const { chartName, isLoading, handleChange, isDisabled } = useChartRow(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    chart.resourceLink.id,
  );

  const handleChartName = useMemo(() => {
    if (!isLoading) return <Typography>{chartName}</Typography>;
    return <Skeleton width={'100%'} height={'80px'} />;
  }, [isLoading, chartName]);

  return (
    <TableRow>
      <TableCell>{handleChartName}</TableCell>
      <TableCell>
        <Switch
          disabled={isDisabled || isLoading}
          checked={!chart.isHidden}
          onChange={async (event) =>
            await handleChange(chart.layoutKey, event.target.checked)
          }
        />
      </TableCell>
    </TableRow>
  );
};
