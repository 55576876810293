import { Link, NavLink } from 'react-router-dom';

import { Box, styled } from '@mui/material';

const NAVBAR_HEIGHT = 56;
const NAVBAR_PADDING = '16px 16px 16px 24px';
const NAVBAR_GAP = 24;
const NAVBAR_LOGO_HEIGHT = 25;
const NAVBAR_TAB_GAP = 12;
const NAVBAR_USER_MENU_GAP = 16;

export const NavbarMainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: NAVBAR_HEIGHT,
  alignItems: 'center',
  padding: NAVBAR_PADDING,
  borderBottom: '1px solid var(--neutrals-gray-200, #dcdfe1)',
  background: '#fff',
  gap: NAVBAR_GAP,
});

export const NavbarUserMenu = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: NAVBAR_USER_MENU_GAP,
  marginLeft: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
});

export const NavbarTitle = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  width: 'auto',
  gap: NAVBAR_GAP,
});

export const NavbarNavigation = styled('div')({
  display: 'flex',
  gap: NAVBAR_TAB_GAP,
});

export const NavbarApps = styled(Link)({
  height: NAVBAR_LOGO_HEIGHT,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NavbarLogo = styled('img')({
  maxHeight: NAVBAR_LOGO_HEIGHT,
  display: 'block',
  width: 'fit-content',
  height: NAVBAR_LOGO_HEIGHT,
  margin: 0,
  cursor: 'pointer',
});

export const NavBarMenuIconContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: NAVBAR_GAP,
});

export const NavbarNavLink = styled(NavLink)(({ theme }) => ({
  color: `var(--text-text-primary, ${theme.palette.text.primary})`,
  fontFamily: 'Inter',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  textDecoration: 'none',
  borderRadius: 'var(--radius-basic, 8px)',
  display: 'flex',
  padding: '6px 12px',
  alignItems: 'center',
  gap: '4px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.active': {
    background: `var(--primary-primary-50, ${theme.palette.primary[50]})`,
  },
}));
