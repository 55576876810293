import { useUpdateCommentMutation } from '@/__generated__/graphql.ts';

export const useUpdateCommentContent = (
  commentId: string,
  organizationId: string,
) => {
  const [updateMutation, { loading: isLoading }] = useUpdateCommentMutation();

  const updateContent = async (content: string) => {
    await updateMutation({
      variables: {
        input: {
          id: commentId,
          organizationId: organizationId,
          content,
        },
      },
    });
  };

  return {
    isLoading,
    updateContent,
  };
};
