import { Employee } from '@/__generated__/graphql';
import { EmployeeRoles } from '@/core/helpers';

/**
 *
 * @param members array of employees
 * @param isUnitAdmin is unit admin should be visible
 * @param isMembers is members should visible
 * @returns array of filtered memebrs
 */

export const filterMembers = (
  members: Employee[] | undefined,
  isUnitAdmin: boolean,
  isMembers: boolean,
) => {
  if (!members || !(isUnitAdmin || isMembers)) return [];

  return members.filter((member) => {
    // when we want to not show unit admin
    if (!isUnitAdmin && EmployeeRoles.isUnitAdmin(member.systemRole))
      return false;

    // when we want to not show other employees
    if (!isMembers && !EmployeeRoles.isUnitAdmin(member.systemRole))
      return false;

    return true;
  });
};
