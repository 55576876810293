import { useMemo } from 'react';

import { ChartOptionsTypes } from '@kpi/modals/HandleKpiChartModal/hooks/useManageChartOptions/ChartOptions.types.ts';

import { RateSumTypes } from '@/__generated__/graphql.ts';

export const useChartLegend = (currentOptions: ChartOptionsTypes) => {
  const isNoLegendEnabled =
    currentOptions?.sumIndicators === RateSumTypes.SumAll;

  const isVariableLegendEnabled =
    currentOptions?.sumIndicators === RateSumTypes.SumAll ||
    currentOptions.sumIndicators === RateSumTypes.SumDistinctName;

  const isOrganizationUnitLegendEnabled =
    currentOptions?.sumIndicators === RateSumTypes.SumOrganizationUnit;

  const isEmployeeLegendEnabled =
    currentOptions?.sumIndicators === RateSumTypes.SumEmployee;

  const isSumOptionEnabled = !!currentOptions?.sumIndicators;

  const isDetailedLegendEnabled = useMemo(() => {
    return (
      !currentOptions?.sumIndicators ||
      currentOptions?.sumIndicators === RateSumTypes.SumAll
    );
  }, [currentOptions?.sumIndicators]);

  return {
    isDetailedLegendEnabled,
    isNoLegendEnabled: isNoLegendEnabled || !isSumOptionEnabled,
    isEmployeeLegendEnabled: isEmployeeLegendEnabled || !isSumOptionEnabled,
    isVariableLegendEnabled: isVariableLegendEnabled || !isSumOptionEnabled,
    isOrganizationUnitLegendEnabled:
      isOrganizationUnitLegendEnabled || !isSumOptionEnabled,
  };
};
