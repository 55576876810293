import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { confirmResetPassword, resendSignUpCode } from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';

import { Button, Stack, Typography, styled } from '@mui/material';

import { BackButton, PasswordTextField } from '@/core/components';
import AuthContainer from '@/core/components/AuthContainer/AuthContainer';
import { CodeInput } from '@/core/components/CodeInput/CodeInput';
import { CoolDownButton } from '@/core/components/CooldownButton/CoolDownButton.tsx';
import { ResetPasswordSchema } from '@/pages/auth/pages/ResetPassword/ResetPassword.schema.ts';
import { useUserStore } from '@/stores';
import { theme } from '@/theme';
import { ValidationSchema } from '@/utils';

/**
 * Define the initial values of the form.
 */
const values = {
  password: '',
  repeatPassword: '',
  code: '',
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const { email } = useUserStore();
  const navigate = useNavigate();
  //Handle the form submission. Sets new password and redirects to the login page.
  const onSubmit: SubmitHandler<ValidationSchema> = useCallback(async () => {
    try {
      await confirmResetPassword({
        confirmationCode: getValues('code'),
        newPassword: getValues('password'),
        username: email,
      });
      navigate('/forgot-password/password-confirmation');
    } catch (err) {
      if (err instanceof Error) {
        switch (err.name) {
          case 'CodeMismatchException':
            setError('code', {
              type: 'manual',
              message: t('forgotPassword.codeInvalid'),
            });
            break;
          case 'LimitExceededException':
            setError('code', {
              type: 'manual',
              message: t('forgotPassword.exceededAttempts'),
            });
            break;
          case 'ExpiredCodeException':
            setError('code', {
              type: 'manual',
              message: t('forgotPassword.codeExpired'),
            });
            break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  //Resend the code to the user's email.
  const handleResendCode = useCallback(async () => {
    try {
      await resendSignUpCode({
        username: email,
      });
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'LimitExceededException':
            setError('code', {
              type: 'manual',
              message: t('forgotPassword.exceededAttempts'),
            });
            break;
          default:
            setError('code', {
              type: 'manual',
              message: t('common.somethingWentWrong'),
            });
        }
        Sentry.captureException(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    setError,
    setValue,
  } = useForm({
    values,
    defaultValues: values,
    resolver: zodResolver(
      ResetPasswordSchema({
        passwordRequired: t('login.passwordRequired'),
        passwordDonMatch: t('errors.passwordsDontMatch'),
        passwordInvalid: t('login.passwordRequirements'),
        codeRequired: t('common.fillTheField'),
        codeInvalid: t('forgotPassword.codeInvalid'),
      }),
    ),
  });

  return (
    <AuthContainer>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Stack flexDirection={'row'} alignItems={'center'}>
          <BackButton href="/login" />
        </Stack>
        <Stack flexDirection={'column'} alignContent={'center'}>
          <Typography variant="h3" sx={{ marginBottom: '16px' }}>
            {t('forgotPassword.setNewPass')}
          </Typography>

          <Typography variant="subtitle2">
            <Trans
              values={{
                mailAddress: email,
              }}
              i18nKey={'forgotPassword.emailConfirm'}
            />
          </Typography>
        </Stack>
        <Stack
          width={'100%'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
        >
          <CodeInput
            name="code"
            styles={{
              marginBottom: '16px',
            }}
            onChange={(value) => {
              setValue('code', value, {
                shouldValidate: true,
              });
            }}
            errorMessage={errors?.code?.message}
            value={values.code}
          />
          <CoolDownButton
            coolDownLength={60000}
            label={t('forgotPassword.sendCode')}
            onClick={handleResendCode}
          ></CoolDownButton>
        </Stack>

        <PasswordTextField
          error={!!errors.password}
          label={t('common.password')}
          helperText={errors?.password?.message}
          {...register('password')}
        />
        <PasswordTextField
          error={!!errors.repeatPassword}
          label={t('common.repeatPassword')}
          helperText={errors?.repeatPassword?.message}
          {...register('repeatPassword')}
        />
        <Button
          variant="contained"
          sx={{
            width: '100%',
            bgcolor: theme.palette.primary[600],
            textTransform: 'none',
          }}
          type="submit"
        >
          {t('login.setUpNewPassword')}
        </Button>
      </FormContainer>
    </AuthContainer>
  );
};

const FormContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
});

export default ResetPassword;
