import { useTranslation } from 'react-i18next';

import { ModuleContainer } from '@e-flow/pages/flowSteps/components';
import { NameModuleTypes } from '@e-flow/pages/flowSteps/flowStepsModules/NameModules/NameModule.types.ts';

import { Alert } from '@mui/material';

import { CharsCountingInput } from '@core/components';

export const EFlowName = (params: NameModuleTypes) => {
  const { t } = useTranslation('eflow');

  return (
    <ModuleContainer title={t('flowSteps.operationDescription')}>
      <CharsCountingInput
        value={params.operationName}
        maxInputLength={215}
        required
        sx={{ width: '100%', marginBottom: 0 }}
        onChange={(e) =>
          params.onFlowStepChange({
            operationName: e.target.value,
          })
        }
      />
      {params.isError && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('flowSteps.requiredNameError')}
        </Alert>
      )}
    </ModuleContainer>
  );
};
