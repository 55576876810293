import { SystemRoles } from '@/__generated__/graphql';

export class EmployeeRoles {
  /**
   *
   * @description check if user is unit admin
   */
  static isUnitAdmin(systemRoles: SystemRoles[]) {
    return systemRoles.includes(SystemRoles.UnitAdmin);
  }

  static isUser(systemRoles: SystemRoles[]) {
    return systemRoles.includes(SystemRoles.User);
  }

  /**
   *
   * @param systemRoles
   * @returns system role
   */
  static getSystemRoles(systemRoles: SystemRoles[]): SystemRoles[] {
    return [
      systemRoles.find((role) =>
        [
          SystemRoles.Administrator,
          SystemRoles.KeyUser,
          SystemRoles.SystemAdmin,
          SystemRoles.UnitAdmin,
          SystemRoles.User,
          SystemRoles.Root,
        ].includes(role),
      )!,
    ];
  }
}
