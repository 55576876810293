import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, TableCell, TableRow } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { RowCell } from './RowCell';
import { useUserRow } from './UseUserRow';
import { UserRowProps } from './UserRow.types';
import { UserRowMenu } from './UserRowMenu';

export const UserRow = ({
  IsCheckboxVisible = true,
  data,
  isMenuVisible = true,
  isSelected,
  onCheck,
  disabled = false,
  onEmployeMenu,
}: UserRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const { handleCheck } = useUserRow(isSelected);

  return (
    <TableRow>
      {IsCheckboxVisible && (
        <TableCell>
          <Checkbox
            disabled={disabled}
            checked={isSelected}
            onClick={() => handleCheck(onCheck)}
          ></Checkbox>
        </TableCell>
      )}
      {data.map((item) => (
        <RowCell item={item} disabled={disabled} />
      ))}
      {isMenuVisible && (
        <TableCell key="menu">
          <IconButton
            disabled={disabled}
            aria-label="more"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
          <UserRowMenu
            setDeleteModalOpen={() => {
              onEmployeMenu();
              setAnchorEl(null);
            }}
            anchorEl={anchorEl}
            open={isMenuOpen}
            handleClose={() => setAnchorEl(null)}
          />
        </TableCell>
      )}
    </TableRow>
  );
};
