/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { OperationSchema, OperationTypesEnum } from '@/__generated__/graphql';
import {
  CharsCountingInput,
  CustomModal,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { FlexCenter } from '@/core/styles';
import { OperationIcon } from '@/pages/e-flow/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { RowStack } from '@/pages/organizations/styles';
import { theme } from '@/theme';

import { AddOperationSchema } from './AddOperation.schema';
import { AddOperationModalProps } from './OperationsModule.types';
import { useOpearationsOptions } from './useOperationsOptions';

export const AddNewOperationModal = (props: AddOperationModalProps) => {
  const operationsOptions = useOpearationsOptions();

  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  const [isOperationIconSelected, setIsOperationIconSelected] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    setFocus,
  } = useForm<OperationSchema>({
    resolver: zodResolver(
      AddOperationSchema(
        commonT('error.requiredField'),
        t('newAnalysis.operations.modal.operationAlreadyExists'),
        props.operations,
      ),
    ),
  });

  const onSubmit = (data: OperationSchema) => {
    props.setSnackBar(t('newAnalysis.operations.newOperationWasCreated'));
    props.addOperation({
      operationType: data.operationType,
      name: data.name,
    });
    props.close();
  };

  return (
    <CustomModal display={true}>
      <ModalContainer
        width="90%"
        sx={{ maxWidth: '528px' }}
        closeModal={props.close}
        headerText={t('newAnalysis.operations.modal.title')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomStack>
            <CustomRowStack>
              <CustomFormControl size="small">
                <InputLabel id="operation-select">
                  {commonT('common.symbol')}
                </InputLabel>
                <Select
                  {...register('operationType')}
                  error={!!errors.operationType}
                  sx={{ marginBottom: '20px' }}
                  variant="outlined"
                  input={<OutlinedInput label={commonT('common.symbol')} />}
                  size="small"
                  id="operation-select"
                  autoComplete="off"
                  renderValue={(selected) => {
                    return (
                      <FlexCenter>
                        <OperationIcon
                          operation={selected as OperationTypesEnum}
                          width="18px"
                          height="18px"
                        />
                      </FlexCenter>
                    );
                  }}
                  onChange={(e) => {
                    setIsOperationIconSelected(true);
                    setValue(
                      'name',
                      operationsOptions.find(
                        (option) => option.operationType === e.target.value,
                      )?.name || '',
                    );
                    setFocus('name');
                  }}
                >
                  {operationsOptions.map((operation) => (
                    <CustomMenuItem
                      key={operation.name}
                      value={operation.operationType}
                    >
                      <OperationIcon
                        operation={operation.operationType}
                        width="18px"
                        height="18px"
                      />
                      {operation.name}
                    </CustomMenuItem>
                  ))}
                </Select>
              </CustomFormControl>
              <CharsCountingInput
                label={t('newAnalysis.operations.modal.operationName')}
                {...register('name')}
                size="small"
                maxInputLength={40}
                InputLabelProps={
                  isOperationIconSelected ? { shrink: true } : {}
                }
                error={!!errors.name}
                helperText={errors.name?.message}
                sx={{ width: '100%' }}
                defaultText={getValues('name')}
              />
            </CustomRowStack>

            <RowStack justifyContent="end" gap="12px">
              <TextButton
                text={commonT('common.cancel')}
                width="66px"
                onClick={props.close}
              />
              <PrimaryButton
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary[600],
                }}
                type="submit"
              >
                <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
                  {commonT('common.add')}
                </Typography>
              </PrimaryButton>
            </RowStack>
          </CustomStack>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};

const CustomMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
});

const CustomStack = styled(Stack)({
  gap: '16px',
  padding: '24px 16px',
});

const CustomFormControl = styled(FormControl)({
  width: '20%',
  minWidth: '20%',
});

const CustomRowStack = styled(RowStack)({
  gap: '14px',
  marginBottom: '10px',
});
