import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { NotificationContainer, NotificationHeader } from '@core/modules';
import { useKpiNotification } from '@core/modules/Notifications/hooks';
import { KpiReportedNotificationsType } from '@core/modules/Notifications/types/KpiReportedNotification.type.ts';

export const KpiReported = (props: KpiReportedNotificationsType) => {
  const { t } = useTranslation();

  const { notificationTitle, isKpiMissing } = useKpiNotification(props);

  return (
    <NotificationContainer onClick={props.onClick}>
      <NotificationHeader
        sentAt={props.sentAt}
        title={notificationTitle}
        isOpened={props.isOpened}
      />
      <Typography variant="bodyRegular">
        {isKpiMissing
          ? t('notification.reportedKpiMissing')
          : t('notification.reportedKpi', {
              kpiName: props.body.kpiName,
            })}
      </Typography>
    </NotificationContainer>
  );
};
