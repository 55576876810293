import { Card, CardContent, Skeleton, Typography, styled } from '@mui/material';

import { theme } from '@/theme';

import { StatisticBlockProps } from './StatisticBlock.types';

export const StatisticBlock = (props: StatisticBlockProps) => {
  if (props.isLoading) {
    return (
      <StyledCard>
        <CardContent>
          <Skeleton />
          <Skeleton />
        </CardContent>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <StyledCardContent>
        <Typography variant="h3">{props.title}</Typography>
        <Typography
          variant="bodyRegular"
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {props.subtitle}
        </Typography>
      </StyledCardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  gap: '30px',
  padding: '10px 24px',
  height: '102px',
  flex: '1',
  minWidth: '260px',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '7px',
});
