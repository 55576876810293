import { TFunction } from 'i18next';

import { UnitKpiRateInput } from '@/__generated__/graphql';

import { UnitsEnum } from './defaultUnits.enum';

export const getDefaultUnits = (t: TFunction<'kpi', undefined>) =>
  [
    {
      unitName: UnitsEnum.PERCENT,
      unitShortcut: '%',
    },
    { unitName: UnitsEnum.CURRENCY, unitShortcut: t('unitShortcut.PLN') },
    { unitName: UnitsEnum.QUANTITY, unitShortcut: t('unitShortcut.szt') },
    { unitName: UnitsEnum.TIME, unitShortcut: t('unitShortcut.H') },
  ] as UnitKpiRateInput[];
