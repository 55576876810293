import { useTranslation } from 'react-i18next';

import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';

import { Box, styled } from '@mui/material';

import { RtfTextEditorProps } from '@core/components/TextEditors/RtfTextEditor/RtfTextEditor.types.ts';

import { theme } from '@/theme';

export const RtfTextEditor = (props: RtfTextEditorProps) => {
  const { t } = useTranslation();

  return (
    <CustomEditable>
      <MUIRichTextEditor
        label={t('common.textEditor')}
        maxLength={props.maxLength}
        onChange={(state) => {
          const contentState = state.getCurrentContent();
          const rawContentState = convertToRaw(contentState);
          props.onChange(JSON.stringify(rawContentState));
        }}
        defaultValue={props.defaultValue}
      />
    </CustomEditable>
  );
};

const CustomEditable = styled(Box)({
  width: '100%',
  height: '30vh',
  border: `1.5px solid ${theme.palette.grey[200]}`,
  maxWidth: '100%',
  borderRadius: '4px',
  overflow: 'auto',
});
