import { captureException } from '@sentry/react';

import { useUpdateSingleSubmissionCache } from '@kaizen/hooks';

import {
  KaizenSubmissionFragment,
  useSetKaizenSubmissionSupervisorAssessmentStatusMutation,
} from '@/__generated__/graphql';
import { useCompanyId } from '@/core/hooks';

export const useSupervisorAssessment = () => {
  const { companyId } = useCompanyId();

  const [sendSupervisorAssessmentMutation, { loading: isLoading }] =
    useSetKaizenSubmissionSupervisorAssessmentStatusMutation();

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const sendSupervisorAssessment = async (submissionId: string) => {
    try {
      const result = await sendSupervisorAssessmentMutation({
        variables: {
          input: {
            submissionId,
            organizationId: companyId,
          },
        },
      });

      if (result.data?.setKaizenSubmissionSupervisorAssessmentStatus) {
        updateSingleSubmissionCache(
          result.data
            .setKaizenSubmissionSupervisorAssessmentStatus as KaizenSubmissionFragment,
        );
        return true;
      }

      return false;
    } catch (error) {
      captureException(error);
    }
    return false;
  };

  return {
    isLoading,
    sendSupervisorAssessment: sendSupervisorAssessment,
  };
};
