import { EFlowStepFile } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types';
import { CreateEflowStepInput } from '@/__generated__/graphql.ts';
import { EflowStepWithFiles } from './types/eFlowStep.types';

/**
 * Convert eFlow step from IndexDB to a normal object
 * @param elementToConvert
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const fromIndexDB = (
  elementToConvert: EflowStepWithFiles,
): {
  eFlowStep: CreateEflowStepInput;
  files: File[];
} => {
  const files = elementToConvert?.localFiles?.map((file: EFlowStepFile) => {
    return new File([file.body], file.name, { type: file.type });
  });
  return { eFlowStep: elementToConvert as CreateEflowStepInput, files };
};
