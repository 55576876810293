import { Chip, Stack } from '@mui/material';

import { useCreateChartContext } from '../../context';

export const SelectedKpisSection = () => {
  const { tempSelectedKpis, handleSelectKpi } = useCreateChartContext();

  return (
    <Stack direction="row" spacing={1}>
      {tempSelectedKpis.map((kpi) => (
        <Chip label={kpi.rateName} onDelete={() => handleSelectKpi(kpi)} />
      ))}
    </Stack>
  );
};
