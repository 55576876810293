import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs, { Dayjs } from 'dayjs';

import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { CheckBoxWithTextModal, Checkbox } from '@core/components';
import { CheckBoxWithTextModalDefaultTypes } from '@core/components/Modals/CheckBoxWithTextModal/CheckBoxWithTextModal.types.ts';
import { SettlementPeriodErrorsEnum } from '@core/enums';

import { useKpiSettlementPeriodContext } from '../contexts';
import { useAddSettlementPeriod } from '../hooks/useSettlemetPeriod/Functions';

export const CreateSettlementPeriodModal = () => {
  const { t: settlementPeriods } = useTranslation('settlementPeriods');
  const { t: kpiT } = useTranslation('kpi');
  const { t: commonT } = useTranslation();

  const [isCheckboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [isValidationError, setValidationError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] =
    useState<SettlementPeriodErrorsEnum | null>(null);
  const [newKpiSettlementPeriodYear, setNewKpiSettlementPeriodYear] =
    useState<number>(new Date().getFullYear());

  const { validate, addSettlementPeriod } = useAddSettlementPeriod();

  const {
    isSettlementPeriodModalOpen,
    closeCreateSettlementPeriodModal,
    open: openSnackbar,
  } = useKpiSettlementPeriodContext();

  const handleResponse = async function (
    response: CheckBoxWithTextModalDefaultTypes,
  ) {
    const errorMessage = validate(response.text, isValidationError);

    if (errorMessage) {
      setValidationError(true);
      setErrorMessage(errorMessage);
      return false;
    }

    setValidationError(false);

    if (
      await addSettlementPeriod(
        response.text,
        response.checkbox,
        isCheckboxChecked,
        newKpiSettlementPeriodYear,
      )
    ) {
      openSnackbar(
        'success',
        settlementPeriods('addSettlementPeriodModal.successMessage'),
      );
      closeCreateSettlementPeriodModal();
      return true;
    }

    openSnackbar(
      'error',
      settlementPeriods('addSettlementPeriodModal.settlementPeriodAddError'),
    );
    closeCreateSettlementPeriodModal();
    return false;
  };

  return (
    <CheckBoxWithTextModal
      isOpen={isSettlementPeriodModalOpen}
      closeModal={() => {
        setErrorMessage(null);
        closeCreateSettlementPeriodModal();
        setCheckboxChecked(false);
      }}
      headerText={settlementPeriods('addSettlementPeriodModal.headerText')}
      placeholder={settlementPeriods('addSettlementPeriodModal.placeholder')}
      checkboxLabel={settlementPeriods(
        'addSettlementPeriodModal.activateAfterCreation',
      )}
      submitButton={commonT('common.add')}
      isError={isValidationError}
      errorInputMessage={
        errorMessage &&
        settlementPeriods(`addSettlementPeriodModal.${errorMessage}`)
      }
      defaultValues={{
        text: new Date().getFullYear().toString(),
        checkbox: false,
      }}
      additionalInput={
        <Box>
          <DatePicker
            label={settlementPeriods('addSettlementPeriodModal.year')}
            name="startDate"
            views={['year']}
            slotProps={{ textField: { size: 'small' } }}
            maxDate={dayjs().add(1, 'year')}
            minDate={dayjs()}
            defaultValue={dayjs()}
            onChange={(date: Dayjs | null) => {
              if (date) {
                setNewKpiSettlementPeriodYear(date.get('year'));
              }
            }}
          />
        </Box>
      }
      additionalContent={
        <Box sx={{ mt: -2 }}>
          <Checkbox
            label={kpiT(
              'organisationSettings.copyIndicatorsFromPreviousSettlementPeriod',
            )}
            value={isCheckboxChecked}
            onChange={() => setCheckboxChecked(!isCheckboxChecked)}
          />
        </Box>
      }
      onResponse={handleResponse}
    />
  );
};
