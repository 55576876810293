import { useTranslation } from 'react-i18next';

import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context/useHandleKpRateContext.tsx';

import { TextField } from '@mui/material';

export const NameAndDescriptionSection = () => {
  const { t } = useTranslation('kpi');

  const {
    useManageKpiModalState: { register, errors },
  } = useHandleAddKpiRateContext();

  return (
    <>
      <TextField
        size="small"
        fullWidth
        label={t('addKpi.name')}
        {...register('rateName')}
        error={!!errors.rateName}
        helperText={errors.rateName?.message}
      />

      <TextField
        size="small"
        fullWidth
        label={t('addKpi.description')}
        {...register('rateDescription')}
        error={!!errors.rateDescription}
      />
    </>
  );
};
