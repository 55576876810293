import { useTranslation } from 'react-i18next';

import { OrganizationModules } from '@/__generated__/graphql';
import { useKpiRedirects } from '@/core/redirects';

import kpiIcon from '/assets/navbar-digital-factory-kpi.svg';

export const useKpiTabs = () => {
  const { t } = useTranslation('kpi');
  const { dashboardPath, indicatorsPath } = useKpiRedirects();

  const kpiNavbarTabs = {
    variant: OrganizationModules.Kpi,
    icon: kpiIcon,
    tabs: [
      {
        tabName: t('navigation.dashboard'),
        tabDirection: dashboardPath,
      },
      {
        tabName: t('navigation.indicators'),
        tabDirection: indicatorsPath,
      },
    ],
  };

  return { kpiNavbarTabs };
};
