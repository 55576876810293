import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCompanyId } from '@core/hooks';

import {
  AsIsToBeNamesEnum,
  GetAsIsToBeIdsDocument,
  GetAsIsToBeIdsQuery,
  useGetAsIsToBeIdsLazyQuery,
} from '@/__generated__/graphql';
import { IncrementDecrementOperationEnum } from '@/core/types';

import {
  readQuery,
  writeQuery,
} from '../../pages/flowDashboard/hooks/useCacheUpdate/Functions';

type AsIsToBeCount = {
  asIsCount: number;
  toBeCount: number;
};

type AsIsToBeProps = {
  flowId: string;
};

export const useAsIsToBeCount = (props: AsIsToBeProps) => {
  const [asIsToBeCount, setAsIsToBeCount] = useState<AsIsToBeCount>({
    asIsCount: 0,
    toBeCount: 0,
  });

  const { companyId } = useCompanyId();
  const { flowId } = useParams();

  const [query, { loading: isLoading, data }] = useGetAsIsToBeIdsLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!companyId || !props.flowId) return;

    void query({
      variables: {
        input: {
          organizationId: companyId,
          id: props.flowId,
        },
      },
    });
  }, [companyId, props.flowId, query]);

  useEffect(() => {
    if (!data) return;

    const asIsCount = data?.findOneEFlow?.asIsState?.length || 0;
    const toBeCount = data?.findOneEFlow?.toBeState?.length || 0;

    setAsIsToBeCount({
      asIsCount,
      toBeCount,
    });
  }, [data]);

  const updateCache = (
    flowStepIds: string[],
    operation: IncrementDecrementOperationEnum,
    analyzeType: AsIsToBeNamesEnum,
    stepNumber?: number,
  ) => {
    const data: GetAsIsToBeIdsQuery = readQuery(
      GetAsIsToBeIdsDocument,
      flowId!,
      companyId,
    );

    const recordStepsType =
      analyzeType === AsIsToBeNamesEnum.AsIs ? 'asIsState' : 'toBeState';

    if (operation === IncrementDecrementOperationEnum.Increment) {
      if (typeof stepNumber === 'number') {
        data.findOneEFlow[recordStepsType] = [
          ...(data.findOneEFlow[recordStepsType] || []).slice(0, stepNumber),
          ...flowStepIds,
          ...(data.findOneEFlow[recordStepsType] || []).slice(stepNumber),
        ];
      } else
        data.findOneEFlow[recordStepsType] = [
          ...(data.findOneEFlow[recordStepsType] || []),
          ...flowStepIds,
        ];
    } else if (operation === IncrementDecrementOperationEnum.Decrement) {
      data.findOneEFlow[recordStepsType] = data.findOneEFlow[
        recordStepsType
      ]!.filter((step: string) => flowStepIds.includes(step));
    }

    writeQuery(GetAsIsToBeIdsDocument, data, flowId!, companyId);
  };

  return {
    ...asIsToBeCount,
    isAsIsEmpty: asIsToBeCount.asIsCount === 0,
    isToBeEmpty: asIsToBeCount.toBeCount === 0,
    isLoading,
    updateCache,
  };
};
