import { TableRow, Typography, styled } from '@mui/material';

import { theme } from '@/theme';

export const CommonTableRow = styled(TableRow)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 0',
});

export const ValueTypography = styled(Typography)({
  flex: '3',
});

export const TitleTypography = styled(Typography)({
  color: theme.palette.grey[900],
  flex: '1',
});
