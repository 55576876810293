import { Route, Routes } from 'react-router-dom';

import { AILayout } from '@/pages/AI/layout';
import { Production } from '@/pages/AI/pages/Production/Production.tsx';
import { Sales } from '@/pages/AI/pages/Sales/Sales.tsx';
import { ProtectedRoute } from '@/router';

import { Dashboard } from '../pages/Dashboard';

export const AIRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            redirectOnForbidden={true}
            redirectOnUnauthorized={true}
          >
            <AILayout />
          </ProtectedRoute>
        }
      >
        <Route path={'*'} element={<Dashboard />}></Route>
        <Route path={'production'} element={<Production />}></Route>
        <Route path={'sales'} element={<Sales />}></Route>
      </Route>
    </Routes>
  );
};
