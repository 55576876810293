import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddKpiRateContext } from '@kpi/contexts';
import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';
import { RowStack } from '@organizations/styles';

import { Typography } from '@mui/material';

import { LoadingButton, TextButton } from '@core/components';

import { theme } from '@/theme';

type ButtonsSectionProps = {
  close: () => void;
};

export const ButtonsSection: FC<ButtonsSectionProps> = ({ close }) => {
  const { t: commonT } = useTranslation();

  const {
    useAddKpiRateNetwork: { isLoading, submitData },
    useManageKpiModalState: { handleSubmit },
  } = useHandleAddKpiRateContext();

  const { isUpdateModal } = useAddKpiRateContext();

  return (
    <RowStack justifyContent={'end'} alignSelf={'end'} gap={2}>
      <TextButton
        text={commonT('common.cancel')}
        width="66px"
        type="button"
        onClick={close}
      />
      <LoadingButton
        isLoading={isLoading}
        variant="contained"
        sx={{
          backgroundColor: theme.palette.primary[600],
        }}
        type="submit"
        onClick={() => {
          handleSubmit(submitData);
        }}
      >
        <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
          {isUpdateModal ? commonT('common.save') : commonT('common.add')}
        </Typography>
      </LoadingButton>
    </RowStack>
  );
};
