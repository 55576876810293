/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { signOut } from '@aws-amplify/auth';

import { Typography, styled, useTheme } from '@mui/material';

import { useErrorStore, useOrganizationStore, useUserStore } from '@/stores';

import { Button } from '..';
import { ErrorContentProps } from './ErrorContent.types';

export const ErrorContent: FC<ErrorContentProps> = ({
  shadow,
  errorCode = false,
  errorUuid,
  displayCode,
  onNavigateBack,
}) => {
  const theme = useTheme();
  const params = useParams<{
    errorCode?: string;
  }>();
  const { setError } = useErrorStore();
  const userStore = useUserStore();
  const organizationStore = useOrganizationStore();

  const [code] = useState(errorCode || params.errorCode!);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = window.location.href.split('/');
  const backPath = userStore.organizationUrl;
  if (!code && !displayCode) return null;

  return (
    <Content style={!shadow ? { boxShadow: 'none' } : {}}>
      <Row>
        <img src="/assets/ecps-logo.svg" alt={'Ecps-logo'} />
      </Row>
      <Row>
        <Typography variant="h3">
          {`${code}`.substring(0, 3)} -{' '}
          {t(`errors.${code}.title` as unknown as [key: TemplateStringsArray])}
        </Typography>
        <Typography variant="bodyRegular" color={theme.palette.text.secondary}>
          {t(
            `errors.${code}.message` as unknown as [key: TemplateStringsArray],
          )}
        </Typography>
        <Typography variant="bodyRegular" color={theme.palette.text.secondary}>
          {errorUuid && `${String(t('errors.errorCode'))}:  ${errorUuid}`}
        </Typography>
      </Row>
      <Row>
        <Button
          variant="contained"
          color="primary"
          onClick={async (): Promise<void> => {
            if (
              userStore.organizationUrl !== '' ||
              location.includes('organizations')
            )
              onNavigateBack ? onNavigateBack() : navigate(`/${backPath}`);
            else {
              await signOut();
              organizationStore.resetStorage();
              userStore.resetStorage();
              window.location.href = '/login';
            }
            setError(null);
          }}
        >
          {t('common.return')}
        </Button>
      </Row>
    </Content>
  );
};

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  width: '100%',
  maxWidth: '528px',
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  gap: '32px',
  boxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
}));

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '16px',
}));
