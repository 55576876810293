/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEditPointsModal } from '@organizations/components/EditPointsModal/useEditPointsModal.tsx';

import { Box, Typography, styled } from '@mui/material';

import {
  Button,
  CustomModal,
  InfoModalContent,
  ModalContainer,
} from '@core/components';

import { theme } from '@/theme';

import { Props } from './EditPointsModal.types';
import { EditPointsModalTable } from './EditPointsModalTable';

export const EditPointsModal: React.FC<Props> = ({
  closeModal,
  points,
  setTableData,
}) => {
  const { t } = useTranslation('kaizen');

  const { handleSubmit, onSubmit, setValue, pointsData, renderRows } =
    useEditPointsModal({
      pointsData: points,
      closeModal,
      setTableData,
    });

  return (
    <CustomModal display={true}>
      <ModalContainer
        closeModal={closeModal}
        headerText={t('points.editPoints')}
        width={'528px'}
        height={'650px'}
        sx={{ overflow: 'auto' }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <InfoModalContent
            contentText={
              <CustomStack>
                <Typography variant="bodyRegular">
                  {t('points.bodyText')}
                </Typography>

                <EditPointsModalTable
                  setValue={setValue}
                  renderRows={renderRows}
                  pointsData={pointsData}
                />
              </CustomStack>
            }
          >
            <Button
              type={'button'}
              variant={'text'}
              textColor={theme.palette.common.black}
              textVariant={'bodyRegular'}
              onClick={closeModal}
            >
              {t('points.cancel')}
            </Button>
            <Button type="submit" variant={'contained'}>
              {t('points.save')}
            </Button>
          </InfoModalContent>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};

const CustomStack = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px',
});
