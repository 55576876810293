import { captureException } from '@sentry/react';

import { useUpdateKpiSettlementPeriodMutation } from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

import { useUpdateSettlementPeriodCache } from './useUpdateSettlementPeriodCache';

export const useUpdateSettlementPeriod = () => {
  const { updateSettlementPeriod: updateSettlementPeriodQuery } =
    useUpdateSettlementPeriodCache();

  const { companyId } = useCompanyId();

  const [updateSettlementPeriodMutation] =
    useUpdateKpiSettlementPeriodMutation();

  const updateSettlementPeriod = async (
    id: string,
    settlementPeriodName: string,
    isActive: boolean,
  ) => {
    try {
      const { data } = await updateSettlementPeriodMutation({
        variables: {
          input: {
            id: id,
            periodName: settlementPeriodName,
            ...(isActive && { isActive: isActive }),
            organizationId: companyId,
          },
        },
      });

      if (data?.updateKpiSettlementPeriod) {
        updateSettlementPeriodQuery(data.updateKpiSettlementPeriod);
        return true;
      }

      return false;
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  const activateSettlementPeriod = async (id: string) => {
    try {
      const { data } = await updateSettlementPeriodMutation({
        variables: {
          input: {
            id: id,
            isActive: true,
            organizationId: companyId,
          },
        },
      });

      if (data?.updateKpiSettlementPeriod) {
        updateSettlementPeriodQuery(data.updateKpiSettlementPeriod);
        return true;
      }

      return false;
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  return {
    updateSettlementPeriod,
    activateSettlementPeriod,
  };
};
