/* eslint-disable @typescript-eslint/no-misused-promises */
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { styled } from '@mui/system';

import { InfoModal } from '@core/components';

import { useKpiSettlementPeriodContext } from '../contexts';
import { useSettlementPeriod } from '../hooks/useSettlemetPeriod/useSettlementPeriod';

export const ActivateSettlementPeriodModal = () => {
  const { t: settlementPeriods } = useTranslation('settlementPeriods');

  const { currentActivePeriod, activateSettlementPeriod } =
    useSettlementPeriod();

  const {
    isActiveSettlementPeriodModalOpen,
    closeActivateSettlementPeriodModal,
    settlementPeriodActiveModalValues,
    open: openSnackbar,
  } = useKpiSettlementPeriodContext();

  const activatePeriod = async () => {
    if (await activateSettlementPeriod(settlementPeriodActiveModalValues!.id)) {
      openSnackbar(
        'success',
        settlementPeriods('activateSettlementPeriodModal.successMessage'),
      );
    } else {
      openSnackbar(
        'error',
        settlementPeriods(
          'activateSettlementPeriodModal.settlementPeriodActivateError',
        ),
      );
    }

    closeActivateSettlementPeriodModal();
    return;
  };

  const formatMessageContent = useCallback(() => {
    return (
      <TextPreWrap>
        <Trans
          t={settlementPeriods}
          i18nKey="activateSettlementPeriodModal.contentText"
          values={{
            currentPeriodName: currentActivePeriod?.periodName,
            newPeriodName: settlementPeriodActiveModalValues?.periodName,
          }}
        />
      </TextPreWrap>
    );
    //eslint-disable-next-line
  }, [
    settlementPeriodActiveModalValues?.periodName,
    currentActivePeriod?.periodName,
  ]);

  return (
    <InfoModal
      display={isActiveSettlementPeriodModalOpen}
      content={formatMessageContent()}
      onConfirm={activatePeriod}
      confirmText={settlementPeriods('activateSettlementPeriodModal.activate')}
      closeModal={closeActivateSettlementPeriodModal}
      headerText={settlementPeriods('activateSettlementPeriodModal.headerText')}
    />
  );
};

const TextPreWrap = styled('span')({
  whiteSpace: 'pre-wrap',
});
