import { useEffect, useState } from 'react';
import { useResizable } from 'react-resizable-layout';

import { useDisplaySplitScreenReducer } from '@core/hooks/useSplitScreen/useDisplaySplitScreenReducer.tsx';

export const useSplitScreen = () => {
  const splitScreenReducer = useDisplaySplitScreenReducer();

  const [dividerPosition, setDividerPosition] = useState<number>(
    window.innerWidth / 2,
  );

  const {
    position: resizableDividerPosition,
    separatorProps: resizableDividerProps,
  } = useResizable({
    axis: 'x',
    min: 200,
    max: window.innerWidth - 200,
    initial: window.innerWidth / 2,
  });

  useEffect(() => {
    setDividerPosition(resizableDividerPosition);

    window.onresize = () => {
      if (window.innerWidth - resizableDividerPosition < 200)
        setDividerPosition(window.innerWidth - 200);
    };
  }, [resizableDividerPosition]);

  return {
    ...splitScreenReducer,
    dividerPosition,
    resizableDividerProps,
  };
};
