import { useCallback, useRef } from 'react';

import { BarChartProps } from '@mui/x-charts';

import useResize from '@/core/hooks/useResize/useResize';

export const useBarChart = (
  cartProps: Omit<BarChartProps, 'width' | 'height'>,
) => {
  const reactContainerRef = useRef<HTMLDivElement>(null);

  const getLegendData = useCallback(() => {
    if (!cartProps.series) return [];

    return cartProps.series.map((item) => {
      return {
        name: (item.label as string) || '',
        color: item.color,
      };
    });
  }, [cartProps.series]);

  const { width: chartWidth } = useResize(reactContainerRef);

  return {
    reactContainerRef,
    getLegendData,
    chartWidth: chartWidth <= 0 ? 500 : chartWidth,
  };
};
