import { useTranslation } from 'react-i18next';

import { Box, Typography, styled } from '@mui/material';

import { theme } from '@/theme';

export const EmptyDashboard = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Container>
      <Typography
        sx={{
          color: theme.palette.grey[500],
        }}
        variant={'h6'}
      >
        {t('empty.kpi')}
      </Typography>
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: 300,
  gap: 10,
});
