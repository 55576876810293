import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { Sidebar } from '@e-schema/components';

import { Box, styled } from '@mui/material';

import { MenuNavLink, SimpleNavbar } from '@/core/components';

export const SystemSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const spliteddUrl = window.location.href.split('/');
  const baseUrl = `/organizations/system-settings/`;

  useEffect(() => {
    if (
      spliteddUrl[spliteddUrl.length - 1] === 'system-settings' ||
      spliteddUrl[spliteddUrl.length - 1] === ''
    ) {
      navigate(`system-users`);
    }
  }, [spliteddUrl, navigate]);

  return (
    <>
      <SimpleNavbar
        action={() => navigate('/organizations')}
        title={t('systemSettings.title')}
      />
      <Container>
        <Sidebar baseWidth={320} resize={false}>
          <SidebarContent>
            <Menu>
              <MenuNavLink
                to={baseUrl + 'system-users'}
                title={t('systemSettings.systemUsers')}
              />
            </Menu>
          </SidebarContent>
        </Sidebar>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </Container>
    </>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: 'calc(100vh - 56px)',
  display: 'flex',
});

const ContentContainer = styled(Box)({
  padding: '0 24px 0 24px',
  overflow: 'auto',
  display: 'flex',
  width: '100%',
});

const SidebarContent = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '8px 12px 32px 12px',
  rowGap: '32px',
});

const Menu = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});
