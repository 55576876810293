import { useTranslation } from 'react-i18next';

import { pick } from 'lodash';

import {
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';

import { OperationTypesEnum } from '@/__generated__/graphql';
import { FlexCenter } from '@/core/styles';
import { OperationIcon } from '@/pages/e-flow/components';

import { useOpearationsOptions } from '../useOperationsOptions';

export const SelectEditInputCell = (props: GridRenderCellParams) => {
  const { t: commonT } = useTranslation();

  const apiRef = useGridApiContext();
  const operationsOptions = useOpearationsOptions();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      ...pick(props, ['id', 'field']),
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode(pick(props, ['id', 'field']));
  };

  return (
    <Select
      variant="filled"
      input={<OutlinedInput label={commonT('common.symbol')} />}
      size="small"
      defaultOpen={true}
      defaultValue={props.value}
      sx={{ width: '100%', height: '100%' }}
      inputProps={{ IconComponent: () => null }}
      autoComplete="off"
      renderValue={(selected) => {
        return (
          <FlexCenter>
            <OperationIcon
              operation={selected as OperationTypesEnum}
              width="22px"
              height="22px"
            />
          </FlexCenter>
        );
      }}
      onChange={(e) => void handleChange(e)}
    >
      {operationsOptions.map((operation) => (
        <CustomMenuItem key={operation.name} value={operation.operationType}>
          <OperationIcon
            operation={operation.operationType}
            width="18px"
            height="18px"
          />
          {operation.name}
        </CustomMenuItem>
      ))}
    </Select>
  );
};

const CustomMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
});
