import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, MenuItem, Typography, styled } from '@mui/material';

import { MainTabProps } from './types';

export const MainTab = (props: MainTabProps) => {
  return (
    <>
      {props.controlls}
      <TabsButtons>
        {props.tabs?.map((tab) => (
          <>
            <CustomDevider />
            <CustomMenuItem
              key={tab.key}
              onClick={() => props.handleOpenTab(tab.key)}
            >
              <Typography>{tab.title}</Typography>
              <KeyboardArrowRight />
            </CustomMenuItem>
          </>
        ))}

        <CustomDevider />
      </TabsButtons>
    </>
  );
};

const TabsButtons = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const CustomMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 60,
  width: '100%',
});

/**
 * I could not find a way to disable margins from normal divider so I created a custom one :D
 */
const CustomDevider = styled(Box)({
  height: 1,
  width: '100%',
  backgroundColor: '#E0E0E0',
});
