import { useAddKpiModalState } from '@kpi/modals/AddKpiRate/hooks/useAddKpiModalState.tsx';

import { useAddKpiRateNetwork } from './useAddKpiRateNetwork';
import { useUpdateKpiRateNetwork } from './useUpdateKpiRateNetwork';

export const useAddKpiRate = () => {
  const useManageKpiModalState = useAddKpiModalState();

  return {
    useManageKpiModalState,
    useAddKpiRateNetwork: useAddKpiRateNetwork(),
    useUpdateKpiRateNetwork: useUpdateKpiRateNetwork(),
  };
};
