import { captureException } from '@sentry/react';

import { useCompanyId } from '@core/hooks';
import { useDashboardStateDataContext } from '@core/modules/DashbordModule/contexts/SetDashboard.context.tsx';

import {
  DashboardTypesEnum,
  SupportedWidgetsEnum,
  usePinToDashboardMutation,
} from '@/__generated__/graphql';
import { useUserDashboardContext } from '@/pages/home/pages/Dashboard/contexts/UserDashboardContext';

export const usePinChart = () => {
  const [pinToDashboardMutation, { loading: isLoading }] =
    usePinToDashboardMutation();

  const { companyId } = useCompanyId();

  const { closeWidgetSidebar } = useUserDashboardContext();
  const { setDashboard } = useDashboardStateDataContext();

  const pinChartToUserDashboard = async (chartId?: string) => {
    if (!chartId || !companyId) return;

    try {
      const { data } = await pinToDashboardMutation({
        variables: {
          input: {
            organizationId: companyId,
            chartId,
            dashboardType: DashboardTypesEnum.UserDashboard,
            widgetType: SupportedWidgetsEnum.KpiChart,
          },
        },
      });
      closeWidgetSidebar();

      if (data?.pinToDashboard) {
        setDashboard(data.pinToDashboard);
      }
    } catch (ex) {
      captureException(ex);
    }
  };

  return { pinChartToUserDashboard, isLoading };
};
