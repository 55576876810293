import { useMemo } from 'react';

import { DashboardWidgetDto } from '@/__generated__/graphql';

export const useTransformLayoutData = (widgets: DashboardWidgetDto[]) => {
  const layouts = useMemo(() => {
    return widgets.map((widget) => {
      return {
        i: widget.layoutKey,
        x: widget.layoutModel.x,
        y: widget.layoutModel.y,
        w: widget.layoutModel.w,
        h: widget.layoutModel.h,
      };
    });
  }, [widgets]);

  return {
    layouts,
  };
};
