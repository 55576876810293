import { useMemo } from 'react';

import { OrganizationModules } from '@/__generated__/graphql';
import { useOrganizationStore } from '@/stores';

export const useOrganizationModule = () => {
  const { organizationModules } = useOrganizationStore();

  const hasOrganizationModule = (moduleName: OrganizationModules) => {
    return organizationModules.find((module) => module === moduleName);
  };

  const filteredOrganizationModules = useMemo(() => {
    return organizationModules.filter((module) =>
      hasOrganizationModule(module),
    );
    //eslint-disable-next-line
  }, []);

  return {
    hasOrganizationModule,
    organizationModules,
    filteredOrganizationModules,
  };
};
