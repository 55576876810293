import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UnitSelectTypes } from '@e-flow/pages/flowReport/components/BalanceChart/components/UnitSelect/UnitSelect.types.ts';

import { OptionsSelect } from '@core/components';

import {
  EFlow,
  ParameterObject,
  ParameterUnitsTypes,
} from '@/__generated__/graphql.ts';
import { useUnitTypes } from '@/pages/e-flow/hooks';

export const UnitSelect = (props: UnitSelectTypes<EFlow['parameters']>) => {
  const { t } = useTranslation('eflow');

  const { types } = useUnitTypes({
    selectedParameterUnit: props.selectedParameterUnit!,
  });

  const getDefaultValue = useMemo(() => {
    if (!props.selectedParameterUnit) return types[0];

    if (
      [
        ParameterUnitsTypes.TimeHhMm,
        ParameterUnitsTypes.TimeHhMm,
        ParameterUnitsTypes.TimeMmSs,
        ParameterUnitsTypes.TimeHhMmSs,
      ].includes(types[0])
    ) {
      return ParameterUnitsTypes.TimeS;
    }
    return props.selectedParameterUnit || types[0];
  }, [types, props.selectedParameterUnit]);

  const adjustTypes = useMemo(() => {
    let units = types;
    if (props.selectedParameterUnit?.includes('TIME')) {
      units = [
        ParameterUnitsTypes.TimeD,
        ParameterUnitsTypes.TimeH,
        ParameterUnitsTypes.TimeMin,
        ParameterUnitsTypes.TimeS,
      ];
    }

    return units.reduce(
      (
        acc: {
          unit: ParameterUnitsTypes;
          label: string;
        }[],
        type,
      ) => {
        acc.push({
          unit: type,
          label: t(`paramsShort.${type}`),
        });
        return acc;
      },
      [],
    );
  }, [props.selectedParameterUnit, t, types]);

  return (
    <OptionsSelect
      items={adjustTypes}
      disabled={true}
      valueKey={'unit'}
      labelKey={'label'}
      value={getDefaultValue}
      name={t('reports.balanceChart.unit')}
      onChange={(e) => {
        if (props.onChange) props.onChange(e.target.value as ParameterObject[]);
      }}
    />
  );
};
