import { Route, Routes } from 'react-router-dom';

import { SynchronizeEflowStepsProvider } from '@e-flow/hooks/useSynchronizedFlows';
import {
  Dashboard,
  FlowReport,
  FlowSteps,
  MainFlowDashboard,
  NewAnalysis,
  UpdateAnalysis,
} from '@e-flow/pages';

import { OrganizationModules, SystemRoles } from '@/__generated__/graphql.ts';
import { ProtectedRoute } from '@/router';

export const EFlowRoutes = () => {
  return (
    <SynchronizeEflowStepsProvider>
      <Routes>
        <Route
          element={
            <ProtectedRoute
              allowedGroups={[
                SystemRoles.Root,
                SystemRoles.SystemAdmin,
                SystemRoles.KeyUser,
                SystemRoles.Administrator,
                SystemRoles.EflowSpectator,
                SystemRoles.EflowCreator,
              ]}
              module={OrganizationModules.EFlowAnalize}
            />
          }
        >
          <Route path={'/'} element={<Dashboard />} />
          <Route
            element={
              <ProtectedRoute
                allowedGroups={[
                  SystemRoles.Root,
                  SystemRoles.SystemAdmin,
                  SystemRoles.KeyUser,
                  SystemRoles.Administrator,
                  SystemRoles.EflowCreator,
                ]}
                module={OrganizationModules.EFlowAnalize}
              />
            }
          >
            <Route path={'create-analysis'} element={<NewAnalysis />} />
          </Route>
          <Route path=":flowId">
            <Route index element={<MainFlowDashboard />} />
            <Route path={'create-operation'} element={<FlowSteps />} />
            <Route path={'report'} element={<FlowReport />} />
            <Route path={'update-analysis'} element={<UpdateAnalysis />} />
            <Route path={'operations/:analizeType'} element={<FlowSteps />} />
          </Route>
        </Route>
      </Routes>
    </SynchronizeEflowStepsProvider>
  );
};
