import { useCallback } from 'react';

import { useKpiDashboardFiltersContext } from '@kpi/components/KpiRatesTable/context';

import { useCompanyId, useTablePagination } from '@core/hooks';

import { KpiRatingOrderBy, OrderBy } from '@/__generated__/graphql';

export const useFindKpiData = ({
  order,
  orderBy,
}: {
  order: OrderBy;
  orderBy: KpiRatingOrderBy;
}) => {
  const { cursor, setCursor, rowsPerPage } = useTablePagination(20);
  const { companyId } = useCompanyId();

  const {
    debounceSearch,
    assignedPersonId,
    organizationUnitId,
    selectedUnit,
    periodId,
  } = useKpiDashboardFiltersContext();

  const createQueryInputVariables = useCallback(() => {
    return {
      limit: rowsPerPage,
      cursor,
      organizationId: companyId,
      ...(assignedPersonId && { employeeIds: [assignedPersonId] }),
      organizationUnitIds: organizationUnitId,
      settlementPeriodIds: [periodId],
      nameSearch: debounceSearch,
      unitSearch: selectedUnit?.unitName,
      order,
      orderBy,
    };
  }, [
    rowsPerPage,
    cursor,
    companyId,
    assignedPersonId,
    organizationUnitId,
    periodId,
    debounceSearch,
    selectedUnit,
    order,
    orderBy,
  ]);

  return {
    cursor,
    rowsPerPage,
    queryInputVariables: createQueryInputVariables(),
    setCursor,
  };
};
