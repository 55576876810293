import { OrganizationNode } from '@core/types';
import { bfsSearch } from '@core/utils';
import { NodeId } from '@/types';


/**
 *
 * @description it try to find specific node by id. If find it, it recursively find all fathers of node
 * @param {NodeId} id
 * @param {OrganizationNode} organizationTree source
 * @param {NodeId[]} list list to collect ids
 * @returns list of ids of all fathers given id
 */
export const getAllFathers = (
  id: NodeId,
  organizationTree: OrganizationNode[],
  list: NodeId[] = [],
): NodeId[] => {
  const node = bfsSearch(organizationTree, id);
  if (node && node.parentId !== undefined) {
    list.push(node.parentId);
    return getAllFathers(node.parentId, organizationTree, list);
  }
  return list;
};