import { useTranslation } from 'react-i18next';

import { EditSubmissionButtonType } from '@kaizen/components/Buttons/EditSubmissionButton/EditSubmissionButton.type.ts';

import { LightButton } from '@core/components';
import { useKaizenRedirects } from '@core/redirects';

export const EditSubmissionButton = ({
  submissionId,
}: EditSubmissionButtonType) => {
  const { redirectToUpdateSubmission } = useKaizenRedirects();
  const { t } = useTranslation();

  return (
    <LightButton onClick={() => redirectToUpdateSubmission(submissionId)}>
      {t('common.edit')}
    </LightButton>
  );
};
