import { FC } from 'react';

import { Table, TableContainer } from '@mui/material';

import { EditPointsModalTableProps } from './EditPointsModal.types';
import { EditPointsModalTableBody } from './EditPointsModalTableBody';
import { EditPointsModalTableHeader } from './EditPointsModalTableHeader';

export const EditPointsModalTable: FC<EditPointsModalTableProps> = ({
  setValue,
  pointsData,
  renderRows,
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <EditPointsModalTableHeader />
        <EditPointsModalTableBody
          pointsData={pointsData}
          setValue={setValue}
          renderRows={renderRows}
        />
      </Table>
    </TableContainer>
  );
};
