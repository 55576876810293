import { captureException } from '@sentry/react';

import { useUpdateSettlementPeriodCache } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/Functions/useUpdateSettlementPeriodCache.ts';

import { useCompanyId } from '@core/hooks';

import { useRemoveSettlementPeriodMutation } from '@/__generated__/graphql';

export const useRemoveSettlementPeriod = () => {
  const { removeSettlementPeriod: removeSettlementPeriodCache } =
    useUpdateSettlementPeriodCache();

  const { companyId } = useCompanyId();

  const [removeSettlementPeriodMutation] = useRemoveSettlementPeriodMutation();

  const removeSettlementPeriod = async (settlementPeriodId: string) => {
    try {
      const { data } = await removeSettlementPeriodMutation({
        variables: {
          input: {
            id: settlementPeriodId,
            organizationId: companyId,
          },
        },
      });

      if (data?.removeSettlementPeriod) {
        removeSettlementPeriodCache(settlementPeriodId);
        return true;
      }

      return false;
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  return {
    removeSettlementPeriod,
  };
};
