import { useTranslation } from 'react-i18next';

import { useSchemaRedirects } from '@core/redirects/useSchemaRedirects.ts';

import { OrganizationModules } from '@/__generated__/graphql';

export const useSchemaTabs = () => {
  const { t } = useTranslation();
  const { organizationTreePath, structurePath, employeesPath } =
    useSchemaRedirects();

  const schemaNavbarTabs = {
    variant: OrganizationModules.ESchema,
    tabs: [
      {
        tabName: t('structure.title'),
        tabDirection: structurePath,
      },
      {
        tabName: t('organizationTree.title'),
        tabDirection: organizationTreePath,
      },
      {
        tabName: t('employees.title'),
        tabDirection: employeesPath,
      },
    ],
  };

  return { schemaNavbarTabs };
};
