import { EflowStep, ParameterObject } from '@/__generated__/graphql.ts';

/**
 * converts step parameters from object to array
 * @param parameters - the parameters of the step
 * @returns an array of parameter objects. If there are no parameters, it returns an empty array
 */
export const DisplayParametersObjects = (
  parameters?: EflowStep['parameters'],
): [string, ParameterObject & { value: number }][] => {
  if (!parameters) return [];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return Object.entries(parameters);
};
