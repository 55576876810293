import styled from '@emotion/styled';

import { Box, Typography } from '@mui/material';

export const MainPageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
});

export const MainContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '32px',
});

export const ButtonsContainer = styled(Box)({
  display: 'flex',
  gap: '16px',
});

export const CustomTypography = styled(Typography)({
  marginLeft: '8px',
});

export const TopPanel = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const SubmissionDetailsContainer = styled(Box)({
  marginLeft: '-24px',
  marginTop: '-32px',
});
