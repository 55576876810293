import { useTranslation } from 'react-i18next';

import { InformationModal } from '@core/components';

import { DeleteLeaderInfoModalProps } from './DeleteLeaderInfoModal.types';

export const DeleteLeaderInfoModal = ({
  open,
  closeModal,
}: DeleteLeaderInfoModalProps) => {
  const { t: kaizenT } = useTranslation('kaizen');
  const { t: commontT } = useTranslation();

  return (
    <InformationModal
      display={open}
      content={kaizenT('deleteCommissionMemberModal.leaderInfo')}
      onConfirm={closeModal}
      confirmText={commontT('common.ok')}
      closeModal={closeModal}
      headerText={kaizenT('deleteCommissionMemberModal.deleteComissionMember')}
    />
  );
};
