import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useSubmissionNameView } from '@kaizen/views/SubmissionNameView/hooks/useSubmissionNameView.tsx';

import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { CharsCountingInput, StepFormViewWrapper } from '@/core/components';

import { SubmissionNameModuleProps } from './SubmissionNameModule.types.ts';

export const SubmissionNameView = (props: SubmissionNameModuleProps) => {
  const { t } = useTranslation('kaizen');

  const { register, isFormError, submit, formErrorMessage } =
    useSubmissionNameView({
      defaultValue: props.submissionName,
      onSubmit: props.onMoveToNextStep,
    });

  return (
    <StepFormViewWrapper
      title={t('submissionModificationView.submissionName.name')}
      subtitle={t('submissionModificationView.submissionName.label')}
      withPreviousStep={false}
      withMiddleButton={false}
      withNextStep={true}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={submit}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onNextStep={submit}
    >
      <FormSection>
        <CharsCountingInput
          focused
          maxInputLength={128}
          error={isFormError}
          helperText={formErrorMessage as ReactNode}
          label={t('submissionModificationView.submissionName.placeholder')}
          variant="outlined"
          size="small"
          fullWidth
          required
          {...register('name')}
        />
      </FormSection>
    </StepFormViewWrapper>
  );
};

const FormSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxWidth: '768px',
});
