import { useCallback, useEffect, useState } from 'react';

import { useFindKaizenModuleInfoQuery } from '@/__generated__/graphql';
import { useCompanyId } from '@/core/hooks';

import { KaizenEditableStatusesOrder } from '../../common/KaizenEditableStatuses.enum';
import { Scoring } from './Points.types';
import { PointsRow } from './PointsRow';

export const usePointsTable = () => {
  const { companyId } = useCompanyId();
  const [tableData, setTableData] = useState<Scoring>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: kaizenScoring } = useFindKaizenModuleInfoQuery({
    variables: {
      input: {
        organizationId: companyId,
      },
    },
  });

  useEffect(() => {
    if (kaizenScoring) {
      const scoring = kaizenScoring.findKaizenModuleInfo.scoringSet
        ?.scoring as Scoring;

      setTableData({
        SUPERVISOR_ASSESSMENT: scoring?.SUPERVISOR_ASSESSMENT || 0,
        COMMISSION_ASSESSMENT: scoring?.COMMISSION_ASSESSMENT || 0,
        ACCEPTED: scoring?.ACCEPTED || 0,
        TODO: scoring?.TODO || 0,
        IMPLEMENTED: scoring?.IMPLEMENTED || 0,
      });
    }
  }, [kaizenScoring]);

  const renderRows = useCallback(() => {
    return KaizenEditableStatusesOrder.map((status) => {
      return (
        <PointsRow
          key={status}
          status={status}
          points={tableData[status] || 0}
        />
      );
    });
  }, [tableData]);

  return {
    tableData,
    setTableData,
    isModalOpen,
    closeModal: () => setIsModalOpen(false),
    openModal: () => setIsModalOpen(true),
    kaizenScoring,
    renderRows,
  };
};
