export const typography = {
  h3: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 24,
  },
  h4: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 20,
  },
  bodySemibold: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 16,
  },
  bodyMedium: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 16,
  },
  bodyRegular: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 14,
  },
  labelMdRegular: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 16,
  },
  labelSmRegular: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 12,
  },
  captionRegular: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 12,
  },
};

export type CustomTypography = typeof typography;
