import { GetCheckboxOrganizationUnitStructureQuery } from '@/__generated__/graphql';
import { Unpacked } from '@/types/unpacked.ts';


type Tree =
  GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure'];

type Child = Unpacked<
  GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure']
>;

/**
 *
 * @param tree whole tree
 * @param nodeId id to remove
 * @returns whole tree without removed node
 * @description function find node by id and remove it
 *
 * @example
 * @param tree {id: 0, name: 'zarzad', children: [{id:1, parentGuid: 1, name: 'podzarzad'}]}
 * @param nodeId '1'
 *
 * @returns wholeTree {id: 0, name: 'zarzad', children: []}
 */

export function findAndDeleteChild(tree: Tree, nodeId: string): Tree | null {
  const findAndRemove = (node: Tree): Tree | null => {
    if (node.id === nodeId) {
      return null; // Remove this node
    }
    if (node.children) {
      node.children = (node.children as Child[])
        .map((child) => findAndRemove(child))
        .filter(Boolean);
    }
    return node;
  };

  const updatedTree = findAndRemove({ ...tree }); // Create a copy of the original tree
  return updatedTree;
}