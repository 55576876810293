import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';

import { Select } from '@core/components';

import { EflowCommentModel } from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

type CommentCategoriesSelectType = {
  isErrors: boolean;
  setSelectedSymbol: (selectedSymbol: string) => void;
  selectedSymbol: string;
  commentsCategory: EflowCommentModel[];
};

export const CommentCategoriesSelect = (props: CommentCategoriesSelectType) => {
  const { t: commonT } = useTranslation();

  return (
    <Select
      sx={{
        width: '33%',
        minWidth: '200px',
        alignSelf: 'flex-start',
        margin: 0,
        ...(props.isErrors
          ? {
              borderColor: theme.palette.error[500],
            }
          : {}),
      }}
      name={commonT('common.category')}
      label={commonT('common.category')}
      error={props.isErrors}
      onChange={(e) => {
        props.setSelectedSymbol(e.target.value as string);
      }}
      size={'small'}
      value={props.selectedSymbol}
    >
      {props.commentsCategory.map((category) => (
        <MenuItem key={category.symbol} value={category.symbol}>
          ({category.symbol}) - {category.categoryName}
        </MenuItem>
      ))}
    </Select>
  );
};
