import { OperationIcon } from '@e-flow/components';

import { Box, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { SectionWithTitle } from '@core/components';

import { OperationNameTypes } from './OperationName.types.ts';

export const OperationName = (props: OperationNameTypes) => {
  return (
    <SectionWithTitle>
      <Container>
        {props.operationIndex && (
          <Title variant={'h6'}>{props?.operationIndex}.</Title>
        )}
        {props.operationType && (
          <OperationIcon operation={props?.operationType} />
        )}
        <Title
          sx={{
            textDecoration: props?.isActive ? 'none' : 'line-through',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          variant={'h6'}
        >
          {!props.isLoading ? (
            props?.operationName
          ) : (
            <Skeleton variant="text" width={200} />
          )}
        </Title>
      </Container>
    </SectionWithTitle>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 10,
  gap: 10,
  width: '100%',
  alignItems: 'center',
});

const Title = styled(Typography)({
  textOverflow: 'ellipsis',
  maxHeight: 200,
});
