 
import { useTranslation } from 'react-i18next';

import { z } from 'zod';

export const AddUnitAdminModalScheme = () => {
  const { t } = useTranslation();
  return z.object({
    userId: z.string().min(1, t('login.requiredField')),
    organizationUnitGuid: z.string().min(1, t('login.requiredField')),
  });
};
