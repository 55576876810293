import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Typography, useTheme } from '@mui/material';

import { CustomModal } from '@/core/components';

import { WorkstationModal } from './WorkstationModal';
import { AddWorkstationProps } from './WorkstationModal.types';

export const AddWorkstationModal = ({
  handleAddWorkstation,
  workstations,
}: AddWorkstationProps) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <PrimaryButton
        data-testid="addButton"
        variant="contained"
        style={{
          backgroundColor: theme.palette.primary[600],
        }}
        onClick={() => setIsShowing(true)}
      >
        <Typography>{t('organizationSettings.addWorkstation')}</Typography>
      </PrimaryButton>
      <CustomModal display={isShowing}>
        <WorkstationModal
          closeModal={() => setIsShowing(false)}
          data-testid="workstationModal"
          setWorkstations={handleAddWorkstation}
          workstations={workstations}
        />
      </CustomModal>
    </>
  );
};
