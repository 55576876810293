import { GetCheckboxOrganizationUnitStructureQuery } from '@/__generated__/graphql';

type Tree =
  GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure'];

/**
 *
 * @param tree whole tree
 * @param newNode edited single node
 * @returns whole tree with updated newNode
 * @description function find oldNode by id and replace to new properties
 *
 * @example
 * @param tree {id: 0, name: 'zarzad', children: [{id:1, parentGuid: 1, name: 'podzarzad'}]}
 * @param newNode {id:1, parentGuid: 0, name: 'newpodzarzad'}
 *
 * @returns wholeTree {id: 0, name: 'zarzad', children: [{id:1, parentGuid: 1, name: 'newpodzarzad'}]}
 */

export function findAndReplaceChild(tree: Tree, newNode: Tree): Tree {
  const findAndReplace = (oldNode: Tree): Tree => {
    if (oldNode.id === newNode.id) {
      return { ...oldNode, name: newNode.name, parentGuid: newNode.parentGuid };
    }
    if (oldNode.children) {
      oldNode.children = oldNode.children.map(findAndReplace);
    }
    return oldNode;
  };

  return findAndReplace({ ...tree });
}
