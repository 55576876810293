import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CommentsModule,
  OperationsModule,
  ParameterModule,
  ProductModule,
  SettingsModule,
  WorkGroupModule,
} from '@e-flow/pages/newAnalysis/new-analysis-modules';
import { SelectedRowsTypes } from '@e-flow/pages/newAnalysis/new-analysis-modules/WorkGroupModule/types';

import { useCompanyId } from '@core/hooks';

import {
  CreateEflowInput,
  GetEmployeesForMultiSelectQuery,
  ParameterObject,
} from '@/__generated__/graphql.ts';

import { useCreateEflow } from './useCreateEflow';

export const useEflowStepChange = () => {
  const { t } = useTranslation('eflow');

  const { companyId } = useCompanyId();
  const { handleCreateEflow, snackBarMessage, isSnackBarOpen, closeSnackbar } =
    useCreateEflow();

  const [currentStep, setCurrentStep] = useState(1);

  const [analyseLeaders, setAnalyseLeaders] = useState<
    GetEmployeesForMultiSelectQuery['getEmployees']['items']
  >([]);
  const [selectedWorkGroupEflowUser, setSelectedWorkGroupEflowUser] =
    useState<SelectedRowsTypes>({});

  const steps = [
    t('newAnalysis.steps.settings'),
    t('newAnalysis.steps.product'),
    t('newAnalysis.steps.workingGroup'),
    t('newAnalysis.steps.parameters'),
    t('newAnalysis.steps.comments'),
    t('newAnalysis.steps.operations'),
  ];

  //move between steps

  const handleStepBack = () =>
    setCurrentStep((currentValue) => (currentValue > 0 ? currentValue - 1 : 0));

  const handleStepNext = () =>
    setCurrentStep((currentValue) =>
      currentValue < steps.length ? currentValue + 1 : currentValue,
    );

  /**
   * Set part of analysis
   * @param flowPart
   */
  const handleSetMainAnalysisPart = (flowPart: Partial<CreateEflowInput>) => {
    setNewAnalysis((currentFlow) => {
      if (currentFlow)
        return {
          ...currentFlow,
          ...flowPart,
        } as CreateEflowInput;
      return flowPart as CreateEflowInput;
    });
  };

  const [newAnalysis, setNewAnalysis] = useState<CreateEflowInput>({
    analyzeLeadersIds: [],
    commentTypes: [],
    name: '',
    operations: [],
    organizationId: companyId,
    parameters: [],
    workGroupUsers: [],
  });

  const [addedParameters, setAddedParameters] = useState<ParameterObject[]>([]);

  const handleSubmit = async () => {
    return await handleCreateEflow(newAnalysis);
  };

  const renderStepChange = useCallback(() => {
    switch (currentStep) {
      case 1:
        return (
          <SettingsModule
            analyseLeaders={analyseLeaders}
            setAnalyseLeaders={setAnalyseLeaders}
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
          />
        );
      case 2:
        return (
          <ProductModule
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            stepBack={handleStepBack}
          />
        );
      case 3:
        return (
          <WorkGroupModule
            selectedWorkGroupEflowMembers={selectedWorkGroupEflowUser}
            setSelectedWorkGroupEflowMembers={setSelectedWorkGroupEflowUser}
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            stepBack={handleStepBack}
          />
        );

      case 4:
        return (
          <ParameterModule
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            stepBack={handleStepBack}
            addedParameters={addedParameters}
            setAddedParameters={setAddedParameters}
          />
        );
      case 5:
        return (
          <CommentsModule
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            stepBack={handleStepBack}
          />
        );
      case 6:
        return (
          <OperationsModule
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            createEFlow={handleSubmit}
            stepBack={handleStepBack}
          />
        );
    }
    return <div />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleStepNext, currentStep]);

  return {
    steps,
    currentStep,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
    renderStepChange,
  };
};
