import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { NotificationContainer, NotificationHeader } from '@core/modules';
import { KaizenNewCommentNotificationType } from '@core/modules/Notifications/types/KaizenNotifications.type';

export const KaizenNewCommentNotification = (
  props: KaizenNewCommentNotificationType,
) => {
  const { t } = useTranslation();

  return (
    <>
      <NotificationContainer onClick={props.onClick}>
        <NotificationHeader
          sentAt={props.sentAt}
          title={props.body.submissionName}
          isOpened={props.isOpened}
        />
        <Typography variant="bodyRegular">
          {t('notification.newComment')}
        </Typography>
      </NotificationContainer>
    </>
  );
};
