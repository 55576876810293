import { useGetDocumentType } from '@e-flow/pages/flowDashboard/hooks/useCacheUpdate/hooks/useGetDocumentType.ts';
import { DocumentNode } from 'graphql';
import { sortBy } from 'lodash';

import { AsIsToBeNamesEnum } from '@/__generated__/graphql.ts';

import { readQuery, writeQuery } from '../Functions';

export const useMoveEflowStepRecordInCache = (
  eFlowId: string,
  organizationId: string,
) => {
  const { getRecordStepType } = useGetDocumentType();

  /**
   * Handles moving steps in live update
   * @param query {DocumentNode}
   * @param newOrder {string[]}
   * @param analizeType  {AsIsToBeNamesEnum}
   */
  const handleMoveSteps = (
    query: DocumentNode,
    newOrder: string[],
    analizeType: AsIsToBeNamesEnum,
  ) => {
    const clonedRecords = readQuery(query, eFlowId, organizationId);

    const recordStepsType = getRecordStepType(analizeType);

    const collection = clonedRecords.findOneEFlow[recordStepsType];

    sortBy(collection, (step) => newOrder?.findIndex((id) => id === step.id));

    const dataToSave = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      findOneEFlow: {
        ...clonedRecords.findOneEFlow,
        [recordStepsType]: collection,
      },
    };

    writeQuery(query, dataToSave, eFlowId, organizationId);
  };

  return { handleMoveSteps };
};
