import { useTranslation } from 'react-i18next';

import { useCanAddSubmission } from '@kaizen/hooks';

import {
  Button,
  DisabledTooltipButton,
  SkeletonButtonWithTooltip,
} from '@core/components';
import { useKaizenRedirects } from '@core/redirects';

export const MoveToAddSubmissionButton = () => {
  const { t } = useTranslation('kaizen');

  const { isLoading, canAddSubmission } = useCanAddSubmission();

  const { navigateToAddSubmission } = useKaizenRedirects();

  if (isLoading) {
    return (
      <SkeletonButtonWithTooltip
        title={t('buttons.addSubmission')}
        tooltip={t('buttons.waitingForModuleData')}
      ></SkeletonButtonWithTooltip>
    );
  }

  if (!canAddSubmission) {
    return (
      <DisabledTooltipButton
        title={t('buttons.addSubmission')}
        tooltip={t('buttons.canNotAddSubmission')}
      />
    );
  }

  return (
    <Button onClick={navigateToAddSubmission}>
      {t('buttons.addSubmission')}
    </Button>
  );
};
