import { useState } from 'react';

/**
 * @description hook to menage modal content and visibility
 *
 */

export const useModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<string | JSX.Element>('');

  return {
    isModalVisible,
    setIsModalVisible,
    modalContent,
    setModalContent,
  };
};
