import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';
import { RowStack } from '@organizations/styles';

import { InputAdornment, TextField, Typography } from '@mui/material';

import { useGetMonthName } from '@core/hooks';

export const SingleGoal = ({ monthNumber }: { monthNumber: number }) => {
  const {
    useManageKpiModalState: { register, errors, watch, getValues },
  } = useHandleAddKpiRateContext();

  const { getMonthName } = useGetMonthName();

  return (
    <RowStack gap="16px" width={'48%'}>
      <Typography variant="bodyRegular" sx={{ width: '68px' }}>
        {getMonthName(monthNumber)}
      </Typography>
      <TextField
        size="small"
        inputMode={'numeric'}
        defaultValue={getValues(`targetData.${'' + monthNumber}`)}
        {...register(`targetData.${'' + monthNumber}`, {
          setValueAs: (value) => {
            const result = parseFloat(value as string);

            if (isNaN(result)) {
              return null;
            }

            return result;
          },
        })}
        error={!!errors.targetData}
        sx={{ width: '140px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {watch('unit.unitShortcut')}
            </InputAdornment>
          ),
        }}
      />
    </RowStack>
  );
};
