import {
  AddEflowCommentInput,
  EFlowStepCommentDto,
} from '@/__generated__/graphql.ts';

export function formatComment(
  comment: EFlowStepCommentDto | AddEflowCommentInput,
): EFlowStepCommentDto {
  if ('id' in comment) {
    return comment;
  }
  return {
    ...comment,
    createdBy: {
      id: comment.createdByModel.id,
      organizationId: comment.createdByModel.organizationId,
    },
  } as unknown as EFlowStepCommentDto;
}
