import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PickOptionMenu } from '@e-flow/pages/flowDashboard/components/Menus/PickOptions/PickOptionMenu.tsx';
import { ModalHeaderTypes } from '@e-flow/pages/flowDashboard/modals/StepDetails/components/ModalHeader/ModalHeader.types.ts';

import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import { Box, Typography, styled } from '@mui/material';

import { Close, NavigateBefore, NavigateNext } from '@core/components';

import { AsIsToBeNamesEnum } from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

import { StepModalViewsContext } from '../../context';
import { useControlDetailsView } from '../../hooks';

export const ModalHeader = (props: ModalHeaderTypes) => {
  const { t } = useTranslation('eflow');
  const { openViews, handleChangeViews } = useContext(StepModalViewsContext);

  const {
    handleSetPreviousStep,
    handleSetNextStep,
    isAsIs,
    handleSetFlowStepId,
  } = useControlDetailsView(props.eFlowId, props.stepId, props.analizeType);

  return (
    <Container>
      <Typography>{isAsIs ? t('common.asIs') : t('common.toBe')}</Typography>
      <ControlsContainer>
        <NavigateBefore
          wrapperProps={{
            title: t('detailsView.previousOperation'),
            placement: 'top',
            onClick: () => handleSetPreviousStep(),
          }}
        />
        <NavigateNext
          wrapperProps={{
            title: t('detailsView.nextOperation'),
            placement: 'top',
            onClick: () => handleSetNextStep(),
          }}
        />
        <PickOptionMenu
          organizationId={props.organizationId}
          eFlowId={props.eFlowId}
          analizeType={props.analizeType}
          icon={
            <FormatListNumberedIcon
              sx={{
                color: theme.palette.grey[800],
              }}
            />
          }
          onChange={(id) => {
            handleSetFlowStepId(id);
          }}
          text={t('detailsView.pickOperation')}
        />

        <PickOptionMenu
          organizationId={props.organizationId}
          eFlowId={props.eFlowId}
          analizeType={isAsIs ? AsIsToBeNamesEnum.ToBe : AsIsToBeNamesEnum.AsIs}
          onChange={(id) => {
            handleSetFlowStepId(
              id,
              isAsIs ? AsIsToBeNamesEnum.ToBe : AsIsToBeNamesEnum.AsIs,
            );
            if (isAsIs) handleChangeViews(AsIsToBeNamesEnum.ToBe);
            else handleChangeViews(AsIsToBeNamesEnum.AsIs);
          }}
          icon={
            <SplitscreenIcon
              sx={{
                color: theme.palette.grey[800],
                transform: 'rotate(90deg)',
              }}
            />
          }
          text={!isAsIs ? t('common.asIs') : t('common.toBe')}
          display={openViews.length < 2}
        />

        <Close
          wrapperProps={{
            title: '',
            placement: 'top',
            onClick: () => {
              handleChangeViews(props.analizeType);
            },
          }}
        />
      </ControlsContainer>
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  background: '#fff',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ControlsContainer = styled(Box)({});
