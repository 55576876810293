import { ExtractChartKeyType } from '@core/hooks/useRecharts/types';

/**
 * Get the name of the key
 * @description because a key format is `uuid_kpi name` we need to get the name of the key
 * @param key - key to get the name from
 * @returns name of the key
 */
export function getName(key: string) {
  return key.split('_').slice(1).join('_');
}

export function getNameFromDataSet(dataSet: ExtractChartKeyType) {
  if (dataSet?.length === 0) {
    return '';
  }

  const key = Object.keys(dataSet[0]);

  const dataKeyNotName = key.filter((k) => k !== 'name')[0];

  if (dataKeyNotName) {
    return getName(dataKeyNotName);
  }

  return '';
}
