import { TFunction } from 'i18next';
import { z } from 'zod';

import { passwordValidationSchema } from '@/utils';

export const PasswordSectionSchema = (t: TFunction) =>
  z
    .object({
      oldPassword: passwordValidationSchema(
        t('login.passwordRequired'),
        t('login.passwordValidation'),
      ),
      password: passwordValidationSchema(
        t('login.passwordRequired'),
        t('login.invalidPassword'),
      ),
      repeatPassword: z.string(),
    })
    .superRefine(({ repeatPassword, password }, ctx) => {
      if (repeatPassword !== password) {
        ctx.addIssue({
          code: 'custom',
          message: t('errors.passwordsDontMatch'),
          path: ['repeatPassword'],
        });
      }
    });
