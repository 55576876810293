import styled from '@emotion/styled';

import { Box, Drawer } from '@mui/material';

import { theme } from '@/theme';

import { DrawerWithTabsProps } from './DrawerWithTabs.types';
import { useDrawerWithTabs } from './hooks';

export const DrawerWithTabs = (props: DrawerWithTabsProps) => {
  const { renderTab } = useDrawerWithTabs(props);

  return (
    <Drawer anchor="right" open={props.isOpen} onClose={props.handleClose}>
      <DrawerContent>{renderTab()}</DrawerContent>
    </Drawer>
  );
};

const DrawerContent = styled(Box)({
  padding: '24px',
  minWidth: '320px',
  width: '500px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
});
