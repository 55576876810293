import { useTranslation } from 'react-i18next';

import { useCalculateReport } from '@kaizen/pages/reports/hooks';

import { CircularProgress } from '@mui/material';

import { ReportPieChart } from '@core/components/Statistica/ReportPieChart/ReportPieChart';
import { useDashboardWidgetContext } from '@core/modules/DashbordModule/contexts/DashboardWidget.context.tsx';

import { useKaizenReport } from '@/pages/home/views/hooks/useKaizenReport.ts';

export const KaizenSubmissionNumberChartWidget = () => {
  const { t } = useTranslation('kaizen');

  const { setWidgetTitle } = useDashboardWidgetContext();

  const { isLoading, kaizenReport } = useKaizenReport();

  const { formatPieChartData } = useCalculateReport(kaizenReport);

  setWidgetTitle(t('report.submissionCount'));

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <ReportPieChart
      values={formatPieChartData}
      isLoading={isLoading}
      emptyMessage={t('report.noData')}
      width={'100%'}
      cardStyle={{
        padding: 0,
        border: 'none',
        margin: 0,
        boxShadow: 'none',
        maxHeight: 'fit-content',
        gap: 0,
        backgroundColor: 'transparent',
      }}
    />
  );
};
