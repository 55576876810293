import { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputAdornment, TextField } from '@mui/material';

import { SearchTextFieldProps } from './SearchTextField.types';

export const SearchTextField: FC<SearchTextFieldProps> = ({
  placeholder,
  value,
  onChange,
  limit,
  inputStyles,
}) => {
  return (
    <FormControl
      sx={{
        maxWidth: 328,
        minWidth: 200,
        width: '100%',
      }}
      size="small"
    >
      <TextField
        id="search-textfield"
        size="small"
        placeholder={placeholder}
        value={value}
        inputProps={{ maxLength: limit }}
        onChange={onChange}
        sx={inputStyles}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
