import { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, FormGroup, Switch } from '@mui/material';

import { TreeDetailsProps } from './TreeDetails.types';

export const TreeDetails: FC<TreeDetailsProps> = ({
  state,
  updateTreeDetails,
}) => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      {/* control to show nested level of node */}
      <FormControlLabel
        control={<Switch checked={state.isNestedLevel} />}
        label={t('treeDetails.nestedLevel')}
        onChange={(
          _event: SyntheticEvent<Element, Event>,
          checked: boolean,
        ) => {
          updateTreeDetails({
            isNestedLevel: checked,
          });
        }}
      />

      {/* control to mark color of node */}
      <FormControlLabel
        control={<Switch checked={state.isColorMark} />}
        label={t('treeDetails.colorMark')}
        onChange={(
          _event: SyntheticEvent<Element, Event>,
          checked: boolean,
        ) => {
          updateTreeDetails({
            isColorMark: checked,
          });
        }}
      />

      {/* control to show unit admins */}
      <FormControlLabel
        control={<Switch checked={state.isUnitAdmin} />}
        label={t('treeDetails.unitMenager')}
        onChange={(
          _event: SyntheticEvent<Element, Event>,
          checked: boolean,
        ) => {
          updateTreeDetails({
            isUnitAdmin: checked,
          });
        }}
      />

      {/* control to show members' avatar */}
      <FormControlLabel
        control={<Switch checked={state.isAvatar} />}
        label={t('treeDetails.avatar')}
        onChange={(
          _event: SyntheticEvent<Element, Event>,
          checked: boolean,
        ) => {
          updateTreeDetails({
            isAvatar: checked,
          });
        }}
      />

      {/* control to show members' position */}
      <FormControlLabel
        control={<Switch checked={state.isPosition} />}
        label={t('treeDetails.position')}
        onChange={(
          _event: SyntheticEvent<Element, Event>,
          checked: boolean,
        ) => {
          updateTreeDetails({
            isPosition: checked,
          });
        }}
      />
      {/* control to show members' position */}
      <FormControlLabel
        control={<Switch checked={state.isMembers} />}
        label={t('organizationTree.employees')}
        onChange={(
          _event: SyntheticEvent<Element, Event>,
          checked: boolean,
        ) => {
          updateTreeDetails({
            isMembers: checked,
          });
        }}
      />
    </FormGroup>
  );
};
