import { useState } from 'react';

export const useAccordionViewer = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  return {
    isExpanded,
    handleChange,
  };
};
