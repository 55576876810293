import { useNavigate, useParams } from 'react-router-dom';

export const useFlowRedirects = () => {
  const navigate = useNavigate();

  const { companyName, flowId } = useParams();

  return {
    redirectToFlowDashboard: () => navigate(`/${companyName}/e-flow`),
    redirectToSingleFlow: () => navigate(`/${companyName}/e-flow/${flowId}`),
    redirectToNewFlow: (newFlowId: string) =>
      navigate(`/${companyName}/e-flow/${newFlowId}`),
    redirectToAsIsFlow: () =>
      navigate(`/${companyName}/e-flow/${flowId}/operations/AS_IS`),
    redirectToToBeFlow: () =>
      navigate(`/${companyName}/e-flow/${flowId}/operations/TO_BE`),
    openSingleFlowSettings: (isFromOperations = false) =>
      navigate(
        `/${companyName}/e-flow/${flowId}/update-analysis`,
        isFromOperations
          ? {
              state: { from: 'operations' },
            }
          : undefined,
      ),
  };
};
