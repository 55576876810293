import { Stack, Table, TableBody, TableContainer, styled } from '@mui/material';

import { ButtonSection } from './ButtonsSection';
import { ChartRow } from './ChartRow';
import { useHandleChartVisibilityContext } from './context/useHandleChartVisibilityContext';

export const HandleChartVisibilityContent = () => {
  const { charts } = useHandleChartVisibilityContext();

  return (
    <MainContainer>
      <CustomTableLayout>
        <CustomTable>
          <TableBody>
            {charts.map((chart) => (
              <ChartRow key={chart.layoutKey} chart={chart} />
            ))}
          </TableBody>
        </CustomTable>
      </CustomTableLayout>

      <ButtonSection />
    </MainContainer>
  );
};

const MainContainer = styled(Stack)({
  gap: '24px',
  padding: '0 24px 24px 24px',
});

const CustomTableLayout = styled(TableContainer)({
  width: '100%',
  height: '500px',
});

const CustomTable = styled(Table)({
  width: '100%',
});
