/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/naming-convention */
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';

import { Typography } from '@mui/material';

import {
  SystemRoles,
  useUpdateEmployeeMutation,
} from '@/__generated__/graphql';
import {
  Button,
  CustomModal,
  InfoModalContent,
  ModalContainer,
} from '@/core/components';
import { UserSelect } from '@/core/components/UserSelect';
import { useOrganizationStore } from '@/stores/useOrganizationStore/useOrganizationStore';
import { theme } from '@/theme';

import { AddAdminodalScheme } from './AddAdminModal.scheme';
import { AddAdminModalProps } from './types';

export const AddAdminModal = ({
  open,
  closeModal,
  addUser,
  setSnackBar,
}: AddAdminModalProps) => {
  const { t } = useTranslation();
  const { organization } = useOrganizationStore();
  const { companyName: companyUrl } = useParams();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { systemKeyUserUUID: '' },
    resolver: zodResolver(AddAdminodalScheme()),
  });

  const [updateEmployee] = useUpdateEmployeeMutation();

  const onSubmit = async (data: { systemKeyUserUUID: string }) => {
    try {
      const result = await updateEmployee({
        variables: {
          input: {
            id: data.systemKeyUserUUID,
            organizationId: organization[companyUrl!],
            systemRole: [SystemRoles.Administrator],
          },
        },
      });
      if (result.data) {
        setSnackBar(t('organizationSettings.addAdminConfirmation'));
        addUser(result.data.updateEmployee);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      closeModal();
    }
  };

  return (
    <CustomModal display={open}>
      <ModalContainer
        closeModal={closeModal}
        width="528px"
        headerText={t('organizationSettings.addAdmin')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <InfoModalContent
            contentText={
              <UserSelect
                organizationId={organization[companyUrl!]}
                onChange={(userId: string) => {
                  setValue('systemKeyUserUUID', userId);
                }}
                width="100%"
                systemRoles={[SystemRoles.UnitAdmin, SystemRoles.User]}
                error={!!errors.systemKeyUserUUID}
                helperText={errors.systemKeyUserUUID?.message}
              />
            }
          >
            <Button
              variant={'text'}
              onClick={closeModal}
              disabled={false}
              typographySx={{
                color: theme.palette.common.black,
              }}
            >
              {t('common.cancel')}
            </Button>
            <PrimaryButton
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
              type="submit"
            >
              <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
                {t('organizationSettings.addAdmin')}
              </Typography>
            </PrimaryButton>
          </InfoModalContent>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};
