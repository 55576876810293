import { KpiDashboardProvider } from '@kpi/pages/Dashboard/contexts/KpiDashbaordContext';

import { Navbar } from '@core/components/Navbar/Navbar';
import { useDrawerStatus } from '@core/hooks';

import { DashboardTypesEnum } from '@/__generated__/graphql';

import { DashboardContainer } from './pages/Dashboard/components/MainDashboardContainer/MainDashboardContainer';
import { UserDashboardProvider } from './pages/Dashboard/contexts/UserDashboardContext';

export const Home = () => {
  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } =
    useDrawerStatus();
  return (
    <>
      <Navbar />
      <KpiDashboardProvider dashboardType={DashboardTypesEnum.UserDashboard}>
        <UserDashboardProvider
          closeWidgetSidebar={handleCloseDrawer}
          openWidgetSidebar={handleOpenDrawer}
          isDrawerOpen={isDrawerOpen}
        >
          <DashboardContainer />
        </UserDashboardProvider>
      </KpiDashboardProvider>
    </>
  );
};
