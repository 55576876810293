import { useTranslation } from 'react-i18next';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import { useRemoveKpiRateMutation } from '@/__generated__/graphql';

import { useKpiTableContext } from '../../context';

export const useKpiActions = (
  setIsDeleteModalVisible: (visible: boolean) => void,
) => {
  const { companyId } = useCompanyId();
  const { t } = useTranslation('kpi');
  const { openSnackbar } = useSnackbarContext();
  const { filterKpiRates } = useKpiTableContext();
  const [removeKpiRate] = useRemoveKpiRateMutation();

  const handleDeleteKpiRate = async (id: string) => {
    await removeKpiRate({
      variables: {
        input: {
          kpiId: id,
          organizationId: companyId,
        },
      },
      update: (cache) => {
        cache.evict({ id: `KpiRate:${id}` });
        filterKpiRates(id);
      },
    });
  };

  const deleteKpiRateToast = async (id: string) => {
    try {
      await handleDeleteKpiRate(id);
      openSnackbar(t('menu.removedKpiMessage'));
    } catch {
      openSnackbar(t('menu.removedKpiErrorMessage'));
    } finally {
      setIsDeleteModalVisible(false);
    }
  };

  return {
    handleDeleteKpiRate,
    deleteKpiRateToast,
  };
};
