import { Box, Typography, styled, useTheme } from '@mui/material';

import { organizationTreeColors } from '@/pages/e-schema/common/organizationTreeColors';

import { NodeCellProps } from './NodeCell.types';
import { NodeCellUser } from './NodeCellUser';
import { filterMembers } from './utils';

export default function NodeCell({
  details,
  level_id,
  members,
  name,
}: NodeCellProps) {
  const theme = useTheme();

  const getBackgroundColor = () => {
    const index = (Number(level_id) % 20) + 1;
    return organizationTreeColors[!isNaN(index) ? index : 1];
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <NodeContainer>
        <NodeMainBox
          sx={{
            border: details.isColorMark
              ? null
              : `2px solid ${theme.palette.common.black}`,
          }}
          backgroundColor={
            details.isColorMark
              ? getBackgroundColor()
              : theme.palette.common.white
          }
        >
          <Typography
            whiteSpace="nowrap"
            color={
              details.isColorMark
                ? theme.palette.common.white
                : theme.palette.common.black
            }
          >
            {details.isNestedLevel && `N-${level_id} `}
            {name}
          </Typography>
        </NodeMainBox>
        <MembersList>
          {filterMembers(members, details.isUnitAdmin, details.isMembers).map(
            (member) => (
              <NodeCellUser
                key={member.id}
                user={member}
                isAvatar={details.isAvatar}
                isPosition={details.isPosition}
              />
            ),
          )}
        </MembersList>
      </NodeContainer>
    </Box>
  );
}
const NodeContainer = styled(Box)`
  display: inline-flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-self: center;
  gap: 10px;
  min-width: auto;
`;

const NodeMainBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor?: string }>(({ backgroundColor }) => ({
  display: 'flex',
  padding: '10px 12px',
  marginBottom: '5px',
  gap: ' 12px',
  background: backgroundColor,
  borderRadius: '8px',
}));

const MembersList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});
