import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import { Box } from '@mui/material';

import { NoDataInfo } from '@core/components';

import { useKpiSettlementPeriodContext } from '../../contexts';

export const NoSettlementPeriods = () => {
  const { t: settlementPeriods } = useTranslation('settlementPeriods');

  const { openCreateSettlementPeriodModal } = useKpiSettlementPeriodContext();

  return (
    <Container>
      <NoDataInfo
        onClick={openCreateSettlementPeriodModal}
        headerText={settlementPeriods('settlementPeriod.noSettlementPeriods')}
        infoText={settlementPeriods('settlementPeriod.noSettlementPeriodsInfo')}
        buttonText={settlementPeriods('settlementPeriod.addSettlementPeriod')}
      />
    </Container>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '50px',
});
