import React from 'react';

import { FileModel } from '@/__generated__/graphql';

export const useFilePreviewView = () => {
  const [file, setFile] = React.useState<File | FileModel | null>(null);

  const openPreview = (file: File | FileModel) => {
    setFile(file);
  };

  const closePreview = () => {
    setFile(null);
  };

  return {
    file,
    openPreview,
    closePreview,
  };
};
