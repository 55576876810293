import { KpiFilters } from './KpiFilters';
import { KpiTable } from './KpiTable';
import { KpiIndicatorsProvider } from './context';

export const KpiRatesTable = () => {
  return (
    <KpiIndicatorsProvider>
      <KpiFilters />
      <KpiTable />
    </KpiIndicatorsProvider>
  );
};
