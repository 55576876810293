import { Box, styled } from '@mui/material';

import { Employee } from '@/__generated__/graphql';
import { AvatarGroup } from '@/core/components';

import { CustomCell, CustomTopography } from './styles';

type Members =
  | Array<Pick<Employee, 'firstName' | 'lastName' | 'id' | 'profilePicture'>>
  | undefined
  | null;

export const AvatarCell = ({ members }: { members: Members }) => {
  return (
    <CustomCell>
      {members && (
        <AvatarContainer>
          <AvatarGroup members={members} />
          <CustomTopography>{`${members[members.length - 1].firstName} ${members[members.length - 1].lastName}`}</CustomTopography>
        </AvatarContainer>
      )}
    </CustomCell>
  );
};

const AvatarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
});
