import { TFunction } from 'i18next';
import { z } from 'zod';
import {
  ParameterObject,
  ParameterTypes,
  ParameterUnitsTypes,
} from '@/__generated__/graphql.ts';

/**
 * Add Parameter schema for zod validation
 */
export const AddParameterSchema = (
  allParams: ParameterObject[],
  t: TFunction<'eflow'>,
) =>
  z.object({
    name: z
      .string()
      .min(
        3,
        t('newAnalysis.parameters.errors.nameMinLength', {
          length: 3,
        }),
      )
      .max(50, t('newAnalysis.parameters.errors.nameMaxLength', { length: 50 }))
      .refine(
        (value) =>
          !allParams.some(
            (param: ParameterObject) =>
              param.type === ParameterTypes.Local && param.name === value,
          ),
        {
          message: t('newAnalysis.parameters.nameTaken'),
        },
      ),
    unit: z.nativeEnum(ParameterUnitsTypes),
  });
