import {
  KpiRateType,
  MonthEntry,
} from '@kpi/components/KpiRatesTable/KpiTable';

import { MonthNumberArray } from '@core/constants';

import {
  KpiRateDto,
  KpiRateFragment,
  KpiRatingTargetType,
} from '@/__generated__/graphql.ts';

export function formatKpiData(kpiData?: KpiRateFragment[]): KpiRateType[] {
  if (!kpiData) return [];

  const newKpiRates = kpiData.map((item) => {
    const months: { [key: string]: MonthEntry } = {};

    MonthNumberArray.forEach((monthNumber) => {
      const key = monthNumber.toString();

      let targetValue =
        item.hasTarget && item.targetData[key] ? item.targetData[key] : null;

      if (item.targetType === KpiRatingTargetType.MonthlyUnified)
        targetValue = item.targetData['1'] || null;

      months[key] = {
        entryValue:
          typeof item.entries[key]?.entry === 'number'
            ? item.entries[key]?.entry
            : '',
        targetValue: targetValue,
      };
    });

    return {
      kpi: item as KpiRateDto,
      nameCell: item.rateName,
      orgUnitCell: item.organizationUnit.name,
      assigneeCell: {
        name: `${item.assignee.firstName} ${item.assignee.lastName}`,
        id: item.assignee.id,
        avatar: item.assignee.profilePicture,
      },
      id: item.id,
      unit: item.unit,
      descriptionCell: item.rateDescription || '',
      data: months,
    };
  });
  return newKpiRates || [];
}
