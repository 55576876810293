import { useCallback, useRef } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CircularProgress, SxProps } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import { Button, LightButton } from '@core/components';
import { SplitButtonType } from '@core/components/Buttons/SplitButton/SplitButton.types.ts';

export function SplitButton<T>(splitButtonProps: SplitButtonType<T>) {
  const anchorRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleToggle = () => {
    splitButtonProps.setIsMenuOpen((prevOpen: boolean) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    splitButtonProps.setIsMenuOpen(false);
  };

  const renderArrowButton = useCallback(() => {
    if (splitButtonProps.isLight) {
      return (
        <LightButton onClick={handleToggle} sx={{ width: 20 }}>
          {splitButtonProps.isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ArrowDropDownIcon />
          )}
        </LightButton>
      );
    }
    return (
      <Button
        color={splitButtonProps.color}
        onClick={handleToggle}
        sx={{ width: 20 }}
      >
        {splitButtonProps.isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <ArrowDropDownIcon />
        )}
      </Button>
    );
  }, [
    splitButtonProps.isLight,
    splitButtonProps.color,
    splitButtonProps.isLoading,
    handleToggle,
  ]);

  return (
    <>
      <ButtonGroup
        color={splitButtonProps.color}
        disabled={splitButtonProps.isLoading}
        sx={
          {
            borderRadius: 8,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '& > :first-of-type': {
              border: splitButtonProps.isLight && 'none',
            },
          } as SxProps
        }
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        {splitButtonProps.mainButton}

        {renderArrowButton()}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={splitButtonProps.isMenuOpen}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {splitButtonProps.options.map(splitButtonProps.renderOptions)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
