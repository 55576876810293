import { useTranslation } from 'react-i18next';

import { SubmissionManagementCommonTypes } from '@kaizen/components/Buttons/SubmissionManagementCommon/SubmissionManagementCommon.types.ts';

import { Stack, TextField, Typography } from '@mui/material';

import { RejectButtonWithModal } from '@/core/components';

import { useRejectButton } from './hooks/useRejectButton.tsx';

export const RejectSubmissionImplementation = ({
  submissionId,
}: SubmissionManagementCommonTypes) => {
  const { t } = useTranslation('kaizen');

  const {
    isModalVisible,
    setIsModalVisible,
    justification,
    setJustification,
    error,
    handleReject,
    isLoading,
  } = useRejectButton();

  const modalContent = (
    <Stack flexDirection="column" gap="12px">
      <Typography>{t('rejectSubmission.rejectText')}</Typography>
      <TextField
        value={justification}
        onChange={(event) => setJustification(event.target.value)}
        size="small"
        error={!!error}
        helperText={error}
        label={t('rejectSubmission.justify')}
      />
    </Stack>
  );

  return (
    <RejectButtonWithModal
      onClick={() => setIsModalVisible(true)}
      closeModal={() => setIsModalVisible(false)}
      display={isModalVisible}
      headerText={t('rejectSubmission.rejectSubmission')}
      content={modalContent}
      onRemove={() => {
        void handleReject(submissionId);
      }}
      isLoading={isLoading}
    >
      {t('rejectSubmission.reject')}
    </RejectButtonWithModal>
  );
};
