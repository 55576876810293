import { Box } from '@mui/material';
import { styled } from '@mui/styles';

import { RechartPieChartCustomTooltipTypes } from '@core/components/Statistica/Tooltips/RechartPieChartCustomTooltip/RechartPieChartCustomTooltip.types.ts';
import { useGetMonthName } from '@core/hooks';

export const RechartPieChRechartPieChartCustomTooltip = (
  props: RechartPieChartCustomTooltipTypes,
) => {
  const { active: isActive, payload, unitShortCut } = props;
  const { getMonthName } = useGetMonthName();

  if (!isActive || !payload) {
    return null;
  }

  return (
    <LegendContainer>
      <Box>
        {getMonthName(payload[0].name)}:&nbsp;
        {payload[0].payload?.tooltipValue || payload[0].value}{' '}
        {unitShortCut || ''}
      </Box>
    </LegendContainer>
  );
};

const LegendContainer = styled(Box)({
  backgroundColor: 'white',
  padding: '10px',
});
