import { ModalBody } from '@e-flow/pages/flowDashboard/modals/StepDetails/components/ModalBody/ModalBody.tsx';
import { OperationName } from '@e-flow/pages/flowDashboard/modals/StepDetails/components/OperationName/OperationName.tsx';
import { DisplayParameters } from '@e-flow/pages/flowDashboard/modals/StepDetails/components/Parameters/DisplayParameters.tsx';

import { EFlowStepCommentDto, FileModel } from '@/__generated__/graphql';
import { AttachmentsModule, CommentModule } from '@/pages/e-flow/components';

import { useControlDetailsView, useCurrentEflow } from '../../hooks';
import { StepModalTypes } from './StepModal.types';

export const StepModal = (props: StepModalTypes) => {
  const {
    isLoading,
    stepData,
    stepIndex,
    updateComments,
    updatePinnedComments,
  } = useControlDetailsView(props.eFlowId, props.stepId, props.analizeType);

  const currentEflow = useCurrentEflow(props.eFlowId, props.organizationId);

  return (
    <ModalBody
      organizationId={props.organizationId}
      eFlowId={props.eFlowId}
      analizeType={props.analizeType}
      stepId={props.stepId}
    >
      <OperationName
        isLoading={isLoading}
        operationName={stepData?.operationName}
        operationType={stepData?.operation.operationType}
        operationIndex={stepIndex}
        isActive={stepData?.isActive || false}
      />
      <DisplayParameters
        isLoading={isLoading}
        parameters={stepData?.parameters || {}}
      />
      <CommentModule
        commentTypes={currentEflow.flow?.commentTypes || []}
        titleVariant={'h4'}
        showNumberOfComments
        currentComments={stepData?.comments as EFlowStepCommentDto[]}
        stepId={stepData?.id}
        onFlowStepChange={(comments) => {
          void updateComments(comments);
        }}
        updateCommentModels={(commentModels) => {
          updatePinnedComments(commentModels);
        }}
        commentModels={stepData?.commentModels || []}
        addCommentToReport
      />
      <AttachmentsModule
        titleVariant={'h4'}
        showNumberOfAttachments
        alreadyUploadedFiles={(stepData?.files as FileModel[]) || []}
      />
    </ModalBody>
  );
};
