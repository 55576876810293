import { useEffect, useState } from 'react';

import { useNetworkState } from '@uidotdev/usehooks';

import { useIndexDB } from '@core/hooks';

import {
  EFlow,
  FindOneEflowQuery,
  useFindOneEflowLazyQuery,
} from '@/__generated__/graphql.ts';

import { UseEflowTypesReturn } from './useEflow.types.ts';

/**
 * Hook used to handle fetching and synchronization of eFlow
 */
export const useEflow = (): UseEflowTypesReturn => {
  const [currentEflow, setCurrentEflow] =
    useState<FindOneEflowQuery['findOneEFlow']>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [eFlowId, setEflowId] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string>('');

  const { online: isOnline } = useNetworkState();

  const {
    saveElement: saveNewEflow,
    data: localEflows,
    updateElement: updateEflow,
    isReady: isEflowsDBReady,
  } = useIndexDB<FindOneEflowQuery['findOneEFlow']>('eFlowSchemas');

  const [findEflowParams] = useFindOneEflowLazyQuery();

  /**
   * Handle fetching of eFlow. If fetched, update a local database
   */
  useEffect(() => {
    void (async () => {
      if (isEflowsDBReady && organizationId && eFlowId) {
        await loadCurrentEflow();
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEflowsDBReady, organizationId, eFlowId]);

  /**
   * Used to load and synchronize eFlow
   * @returns Promise<void>
   * @private
   */
  const loadCurrentEflow = async () => {
    if (isOnline) {
      const { data } = await findEflowParams({
        variables: {
          input: {
            id: eFlowId,
            organizationId,
          },
        },
      });

      if (data) {
        setCurrentEflow(data.findOneEFlow as EFlow);

        if (localEflows[eFlowId]) {
          await updateEflow(eFlowId, data.findOneEFlow);
        } else {
          await saveNewEflow(data.findOneEFlow, eFlowId);
        }
      }
    } else {
      setCurrentEflow(localEflows[eFlowId]);
    }
  };

  /**
   * initialize eFlowHook
   * @param props
   */
  const init: UseEflowTypesReturn['init'] = (props) => {
    setEflowId(props.eFlowId);
    setOrganizationId(props.organizationId);
  };

  return {
    isLoading,
    eFlow: currentEflow,
    eFlowId,
    init,
  };
};
