import { useTranslation } from 'react-i18next';

import { SubmissionManagementCommonTypes } from '@kaizen/components/Buttons/SubmissionManagementCommon/SubmissionManagementCommon.types.ts';

import { LoadingButton } from '@/core/components';

import { useAdjustmentSubmission } from './hooks/useAdjustmentSubmission';

export const AdjustmentButton = ({
  submissionId,
}: SubmissionManagementCommonTypes) => {
  const { t } = useTranslation('kaizen');
  const { isLoading, onClick } = useAdjustmentSubmission(submissionId);

  return (
    <LoadingButton
      variant="contained"
      onClick={onClick}
      isLoading={isLoading}
      variantLight={true}
    >
      {t('adjustment.adjustment')}
    </LoadingButton>
  );
};
