import { create } from 'zustand';
import { useLoginStateStoreTypes } from './useLoginStatusStore.types';

/**
 * This store is used to manage the login status of the user.
 * @example when user logs in for the first time status should be "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
 */
export const useLoginStatusStore = create<useLoginStateStoreTypes>((set) => ({
  status: null, // Add the missing 'status' property
  setStatus: (status) => set(() => ({ status })),
}));
