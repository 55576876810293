import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee.ts';
import { EditPointsModal } from '@organizations/components/EditPointsModal';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';
import { styled } from '@mui/system';

import { theme } from '@/theme';

import { usePointsTable } from './usePointsTable';

export const PointsTable: React.FC = () => {
  const { t } = useTranslation('kaizen');
  const {
    tableData,
    setTableData,
    isModalOpen,
    closeModal,
    openModal,
    renderRows,
  } = usePointsTable();

  return (
    <>
      <MainContainer>
        <CustomStack>
          <PrimaryButton
            data-testid="editButton"
            variant="contained"
            onClick={openModal}
            style={{
              backgroundColor: theme.palette.primary[600],
            }}
          >
            {t('points.editPoints')}
          </PrimaryButton>
        </CustomStack>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell>{t('points.statusHeader')}</TableCell>
              <TableCell>{t('points.pointsHeader')}</TableCell>
            </TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </TableContainer>
      </MainContainer>
      {isModalOpen ? (
        <EditPointsModal
          closeModal={closeModal}
          points={tableData}
          setTableData={setTableData}
        />
      ) : null}
    </>
  );
};

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
});

const CustomStack = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  alignItems: 'center',
});
