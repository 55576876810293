import { captureException } from '@sentry/react';
import { Dispatch, SetStateAction } from 'react';

import {
  DashboardFragment,
  DashboardTypesEnum,
  FindDashboardInput,
  useGetDashboardLazyQuery,
} from '@/__generated__/graphql.ts';

export const useFetchDashboard = (
  setDashboard: Dispatch<SetStateAction<DashboardFragment | undefined>>,
  setInitalized: Dispatch<SetStateAction<boolean>>,
) => {
  const [loadData, { loading: isLoading, refetch }] = useGetDashboardLazyQuery({
    initialFetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const fetchDashboard = async (findDashboardInput: FindDashboardInput) => {
    try {
      if (!findDashboardInput.organizationId || !findDashboardInput.type) {
        return;
      }

      const { data } = await loadData({
        variables: { input: findDashboardInput },
      });

      if (data) {
        setDashboard(data.getKpiDashboard);
      } else {
        if (findDashboardInput.type !== DashboardTypesEnum.UserDashboard)
          setDashboard(undefined);
      }
    } catch (error) {
      captureException(error);
    } finally {
      setInitalized(true);
    }
  };

  return {
    isLoading,
    fetchDashboard,
    reFetchDashboard: refetch,
  };
};
