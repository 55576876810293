import { useTranslation } from 'react-i18next';

import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

/**
 * This just renders a header for table inside SystemUsers, for code readability
 * @constructor
 */
export const EmployeesTableHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell height={'40px'}>
          <CustomTopography>{t('employee.employee')}</CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>{t('employees.email')}</CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>{t('employees.position')}</CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>{t('employees.searchCell')}</CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>{t('employees.status')}</CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>{t('employees.createdAt')}</CustomTopography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
});
