/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChartDataSetType,
  ChartLegendType,
  ChartTypes,
} from '@/__generated__/graphql';

export default {
  additionalInfo: {},
  chartName: 'Rentowność sprzedarzy krajowej',
  chartType: ChartTypes.LineChart,
  dataSet: [
    {
      name: 1,
      'aaa_EBIT (zysk z działalności netto)': { value: 54, target: 10 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 44, target: 43 },
    },
    {
      name: 2,
      'aaa_EBIT (zysk z działalności netto)': { value: 33, target: 81 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 64, target: 92 },
    },
    {
      name: 3,
      'aaa_EBIT (zysk z działalności netto)': { value: 86, target: 85 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 5, target: 86 },
    },
    {
      name: 4,
      'aaa_EBIT (zysk z działalności netto)': { value: 61, target: 67 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 41, target: 15 },
    },
    {
      name: 5,
      'aaa_EBIT (zysk z działalności netto)': { value: 78, target: 40 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 57, target: 25 },
    },
    {
      name: 6,
      'aaa_EBIT (zysk z działalności netto)': { value: 95, target: 65 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 35, target: 100 },
    },
    {
      name: 7,
      'aaa_EBIT (zysk z działalności netto)': { value: 41, target: 20 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 31, target: 30 },
    },
    {
      name: 8,
      'aaa_EBIT (zysk z działalności netto)': { value: 67, target: 86 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 46, target: 37 },
    },
    {
      name: 9,
      'aaa_EBIT (zysk z działalności netto)': { value: 14, target: 23 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 50, target: 100 },
    },
    {
      name: 10,
      'aaa_EBIT (zysk z działalności netto)': { value: 29, target: 70 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 24, target: 29 },
    },
    {
      name: 11,
      'aaa_EBIT (zysk z działalności netto)': { value: 54, target: 10 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 35, target: 45 },
    },
    {
      name: 12,
      'aaa_EBIT (zysk z działalności netto)': { value: 60, target: 63 },
      'bbb_EBITDA (zysk operacyjny + amortyzacja)': { value: 89, target: 13 },
    },
  ],
  dataSourceSet: ['aaa', 'bbb'],
  datasetType: ChartDataSetType.KpiSet,
  displayOptions: {
    legendType: ChartLegendType.VariableLegend,
    showDataAsPercent: true,
    showDataInAscendingOrder: false,
    showDataLabels: null,
    showDetailedLegend: null,
    showTarget: true,
    sumIndicators: null,
  },
  id: '111',
  isError: false,
  selectedMonth: 10,
};
