import { ReactNode, createContext, useContext } from 'react';

import { useAddKpiModalContext } from '@kpi/hooks';
import { AddKpiRate } from '@kpi/modals';

const AddKpiRateContext = createContext<
  ReturnType<typeof useAddKpiModalContext> | undefined
>(undefined);

export const AddKpiRateProvider = ({ children }: { children: ReactNode }) => {
  const contextValue = useAddKpiModalContext();

  return (
    <AddKpiRateContext.Provider value={contextValue}>
      {children}
      <AddKpiRate
        display={contextValue.isAddKpiModelOpen}
        close={contextValue.closeAddKpiModal}
      />
    </AddKpiRateContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAddKpiRateContext = () => {
  const context = useContext(AddKpiRateContext);

  if (!context) {
    throw new Error(
      'useAddKpiRateContext must be used within a AddKpiRateProvider',
    );
  }

  return context;
};
