import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

import { theme } from '@/theme';

export const CustomTooltip = styled(({ ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -14],
            },
          },
        ],
      },
    }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.text.primary,
  },
}));
