import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { sortModules } from '@organizations/pages/organizationSettings/utils';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton, Stack, styled } from '@mui/material';

import {
  CompanyComponent,
  DrawerWithTabs,
  IconModule,
  WelcomeComponent,
} from '@core/components';
import { useOrganizationModule } from '@core/hooks';
import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context';
import { Dashboard } from '@core/modules/DashbordModule/views/Dashboard';

import { theme } from '@/theme';

import { SettingsSwitches } from '../../components/SettingsSwitches/SettingsSwitches';
import { DashboardDisplayOptions } from '../../types';
import { KaizenSidebarView } from '../../views/KaizenSidebarView/KaizenSidebarView';
import { KpiChartSidebarView } from '../../views/KpiChartSidebarPreview/KpiChartSidebarView';
import { useUserDashboardContext } from './contexts/UserDashboardContext';

export const HomeDashboard = () => {
  const { filteredOrganizationModules } = useOrganizationModule();

  const { closeWidgetSidebar, isDrawerOpen } = useUserDashboardContext();

  const { t: dashboardT } = useTranslation('dashboard');

  const { dashboard, addOnEmpty } = useDashboardContext();

  const displayOptions = useMemo(() => {
    if (!dashboard?.additionalInfo)
      return {
        displayApps: true,
        displayCompanyName: true,
        displayConsultants: true,
        displayWelcomeMessage: true,
      } as DashboardDisplayOptions;

    return dashboard.additionalInfo as DashboardDisplayOptions;
  }, [dashboard]);

  return (
    <MainPageContainer>
      <DrawerWithTabs
        contentAboveTabs={<SettingsSwitches />}
        isOpen={isDrawerOpen}
        handleClose={closeWidgetSidebar}
        title={dashboardT('settings.title')}
        tabs={[
          {
            key: 'kpi',
            title: 'KPI',
            content: <KpiChartSidebarView />,
          },
          {
            key: 'kaizen',
            title: 'Kaizen',
            content: <KaizenSidebarView />,
          },
        ]}
      />
      <MainDashboardContainer>
        <WelcomeContainer>
          <DashboardItem>
            <CompanyComponent
              displayCompanyName={displayOptions.displayCompanyName}
              displayKeyUsers={displayOptions.displayConsultants}
            />
          </DashboardItem>
          <DashboardItem justifyContent="center">
            <WelcomeComponent
              displayWelcomeMessage={displayOptions.displayWelcomeMessage}
            />
          </DashboardItem>
          <DashboardItem justifyContent="flex-end">
            <IconButton
              onClick={() => void addOnEmpty()}
              sx={{ width: '40px' }}
            >
              <SettingsOutlinedIcon
                sx={{
                  color: theme.palette.grey[900],
                }}
              />
            </IconButton>
          </DashboardItem>
        </WelcomeContainer>
        <ModuleContainer>
          {displayOptions.displayApps &&
            sortModules(filteredOrganizationModules).map((module) => (
              <IconModule key={module} variant={module} />
            ))}
        </ModuleContainer>
      </MainDashboardContainer>
      <Dashboard />
    </MainPageContainer>
  );
};

const MainDashboardContainer = styled('div')({
  width: '100%',
  display: 'flex',
  paddingBottom: '20px',
  flexDirection: 'column',
  gap: '30px',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
});

const WelcomeContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const DashboardItem = styled(Stack)({
  width: '33%',
  flexDirection: 'row',
});

const ModuleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '10px',
  justifyContent: 'center',
});

const MainPageContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '10px',
  padding: '20px',
});
