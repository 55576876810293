import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChevronRight, ExpandMore } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Checkbox, Skeleton, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import { useGetCheckboxOrganizationUnitStructureQuery } from '@/__generated__/graphql.ts';
import { useOrganizationStore } from '@/stores/useOrganizationStore/useOrganizationStore';
import { NodeId } from '@/types';

import { CheckboxTreeProps, OrganizationNode } from './types';
import { checkSelectedNodes, getAncestors } from './utils';

export const CheckboxTree: FC<CheckboxTreeProps> = ({
  setSelectedNodes,
  selectedNodes,
}) => {
  //list of indexes which are selected in tree checkbox

  const { organization } = useOrganizationStore();
  const { t } = useTranslation();

  const { companyName: companyUrl } = useParams();
  const [organizationUnits, setOrganizationUnits] = useState<
    OrganizationNode[]
  >([]);

  const { loading: isLoading, data } =
    useGetCheckboxOrganizationUnitStructureQuery({
      variables: {
        input: {
          id: organization[companyUrl || ''],
        },
      },
    });

  useEffect(() => {
    !!data?.getOrganizationUnitsStructure &&
      setOrganizationUnits([
        //TODO Fix this i really want develop now
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        data.getOrganizationUnitsStructure as OrganizationNode,
      ]);
  }, [data]);

  const [localNodes, setLocalNodes] = useState<NodeId[]>([]);

  useEffect(() => {
    setSelectedNodes(localNodes);
  }, [localNodes, setSelectedNodes]);

  useEffect(() => {
    setLocalNodes(selectedNodes);
    // eslint-disable-next-line
  }, []);

  const ancestors = useMemo(() => {
    return getAncestors(localNodes, organizationUnits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localNodes]);

  const handleNodeSelect = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, nodeId: number) => {
      event.stopPropagation();
      checkSelectedNodes(localNodes, setLocalNodes, nodeId, organizationUnits);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localNodes],
  );

  const renderTree = (nodes: OrganizationNode): JSX.Element => (
    <TreeItem
      key={nodes.id}
      itemId={nodes.id.toString()}
      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation();
      }}
      label={
        <>
          <Checkbox
            checked={
              localNodes.includes(nodes.id) || ancestors.includes(nodes.id)
            }
            tabIndex={-1}
            checkedIcon={
              localNodes.includes(nodes.id) ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlinedIcon />
              )
            }
            disableRipple
            onClick={(event) => handleNodeSelect(event, nodes.id)}
          />
          {nodes.name}
        </>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <SimpleTreeView
      multiSelect
      slots={{
        expandIcon: ChevronRight,
        collapseIcon: ExpandMore,
      }}
      selectedItems={localNodes.map((id) => id.toString())}
    >
      {isLoading ? (
        <Skeleton height={400} />
      ) : organizationUnits.length > 0 ? (
        organizationUnits.map((node) => renderTree(node))
      ) : (
        <Typography variant="bodyRegular">
          {t('organizationTree.noElements')}
        </Typography>
      )}
    </SimpleTreeView>
  );
};
