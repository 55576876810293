import { FC } from 'react';

import { CircularProgress, Typography } from '@mui/material';

import { ConvertJsonToReactTree } from './ConvertJsonToReactTree';
import { RenderedContentProps } from './RenderedContent.types';

export const RenderedContent: FC<RenderedContentProps> = ({
  isLoading,
  error,
  organizationUnits,
  t,
  details,
}) => {
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography>
          {t(
            !error.message.includes('EMPTY_ORGANIZATION')
              ? 'organizationTree.getUnitError'
              : 'organizationTree.noElements',
          )}
        </Typography>
      ) : (
        organizationUnits && ConvertJsonToReactTree(organizationUnits, details)
      )}
    </>
  );
};
