import { SetStateAction } from 'react';
import {
  bfsSearch,
  getAllChild,
  getAllFathers,
  isAllChildrenChecked,
} from '@core/utils';
import { NodeId } from '@/types';
import { OrganizationNode } from './types';


/**
 * @description it check if nodeId is in localNodes array. If it is - remove from there. if not -
 check all children and fathers to know which nodes should be selected too.
 * @param localNodes list of actual selected indexes
 * @param setLocalNodes setState action to update localNodes
 * @param nodeId id of current clicked element
 * @param organizationTree source
 */
export const checkSelectedNodes = (
  localNodes: NodeId[],
  setLocalNodes: (value: SetStateAction<NodeId[]>) => void,
  nodeId: NodeId,
  organizationTree: OrganizationNode[],
) => {
  const allChild = getAllChild(nodeId, organizationTree);
  const fathers = getAllFathers(nodeId, organizationTree);

  if (localNodes.includes(nodeId)) {
    // Need to de-check
    setLocalNodes((prevSelectedNodes) =>
      prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id)),
    );
  } else {
    const ToBeChecked = allChild;
    for (const father of fathers) {
      if (
        isAllChildrenChecked(
          bfsSearch(organizationTree, father),
          ToBeChecked,
          organizationTree,
          localNodes,
        )
      ) {
        ToBeChecked.push(father);
      }
    }
    setLocalNodes((prevSelectedNodes) => [
      ...prevSelectedNodes,
      ...ToBeChecked,
    ]);
  }
};

/**
 * @description check selected nodes and find all ancestors add push it to list
 * @param localNodes state list of selectwed nodes
 * @param organizationTree source
 * @returns list of ids of all ancestors
 */
export const getAncestors = (
  localNodes: NodeId[],
  organizationTree: OrganizationNode[],
) => {
  const list: NodeId[] = [];
  localNodes.forEach((nodeID) => {
    list.push(...getAllFathers(nodeID, organizationTree));
  });
  return list;
};