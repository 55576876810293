import { useTranslation } from 'react-i18next';

import { EditSubmissionButtonType } from '@kaizen/components/Buttons/EditSubmissionButton/EditSubmissionButton.type.ts';

import { LightButton, RemoveItemModal } from '@core/components';

import { useCompanyId } from '@/core/hooks';
import { useKaizenRedirects } from '@/core/redirects';
import { useSettlementPeriod } from '@/pages/kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod';

import { useRemoveKaizenSubmission } from '../../SubmissionTable/hooks/useRemoveKaizenSubmission';

export const RemoveSubmissionButton = ({
  submissionId,
}: EditSubmissionButtonType) => {
  const { t } = useTranslation();
  const { t: kaizenT } = useTranslation('kaizen');
  const { currentActivePeriod } = useSettlementPeriod();
  const { navigateToSubmissionsDashboard } = useKaizenRedirects();

  const { companyId } = useCompanyId();

  const {
    isModalVisible,
    handleCancel,
    handleClickRemove,
    handleRemoveSubmission,
  } = useRemoveKaizenSubmission();

  return (
    <>
      <RemoveItemModal
        headerText={kaizenT('dashboard.removeSubmission')}
        content={kaizenT('dashboard.areYouSureToRemoveSubmission')}
        onRemove={() => {
          void handleRemoveSubmission({
            organizationId: companyId,
            limit: 10,
            cursor: 0,
            settlementPeriodId: [currentActivePeriod?.id || ''],
          });
          navigateToSubmissionsDashboard();
          handleCancel();
        }}
        closeModal={() => {
          handleCancel();
        }}
        display={isModalVisible}
      />
      <LightButton
        onClick={() => {
          handleClickRemove(submissionId);
        }}
      >
        {t('common.delete')}
      </LightButton>
    </>
  );
};
