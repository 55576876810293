import {
  EFlowStepCommentDto,
  EflowReportComment,
} from '@/__generated__/graphql.ts';

import { LocalCommentType } from '../../types';

/**
 * Change value of single comment `showInReport` property
 * @param newCommentValue
 * @param comments
 */
export const handleCommentsOnServer = (
  newCommentValue: {
    commentId: string;
    showInReport: boolean;
  },
  comments?: EflowReportComment[],
): EflowReportComment[] => {
  if (!comments) {
    throw new Error('Comments are required');
  }

  const tempComments = comments.map((comment) => {
    if (comment.commentId === newCommentValue.commentId) {
      return {
        ...comment,
        showInReport: newCommentValue.showInReport,
      };
    }
    return comment;
  });

  return tempComments;
};

/**
 * Updates comments for clinet side
 * @param currentComments
 * @param commentIndex
 * @param showInReport
 */
export const handleCommentsOnClient = (
  currentComments: (EFlowStepCommentDto | LocalCommentType)[],
  commentIndex: number,
  showInReport: boolean,
): (EFlowStepCommentDto | LocalCommentType)[] => {
  if (!currentComments) {
    throw new Error('Comments are required');
  }

  const comments = currentComments.map((comment, index) => {
    if (index === commentIndex && 'showInReport' in comment) {
      return {
        ...comment,
        showInReport,
      };
    }
    return comment;
  });
  return comments;
};
