import React from 'react';
import { useTranslation } from 'react-i18next';

import { SettlementPeriods } from '@kpi/module-settings';
import { KpiSettlementPeriodProvider } from '@kpi/module-settings/SettlementPeriods/contexts';

import { TabViewWrapper } from '@core/components';
import { Users } from '@core/modules/PermissionModule/views/Users';

export const KpiSetting: React.FC = () => {
  const { t } = useTranslation('kpi');

  return (
    <TabViewWrapper
      tabs={[
        {
          tabName: t('organisationSettings.users'),
          tabComponent: <Users />,
        },
        {
          tabName: t('organisationSettings.settlementsPeriods'),
          tabComponent: (
            <KpiSettlementPeriodProvider>
              <SettlementPeriods />
            </KpiSettlementPeriodProvider>
          ),
        },
      ]}
    />
  );
};
