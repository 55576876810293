import { FC } from 'react';

import { Button as MuiButton, Typography, styled } from '@mui/material';

import { ButtonProps } from './Button.types';

export const Button: FC<ButtonProps> = ({
  variant = 'contained',
  children,
  backgroundColor,
  textVariant,
  fontSize,
  fontWeight,
  startIcon,
  endIcon,
  textColor = 'white',
  onClick,
  type = 'submit',
  fullWidth,
  sx,
  color,
  typographySx,
  disabled,
}) => {
  return (
    <ButtonStyled
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      backgroundColor={backgroundColor}
      onClick={onClick}
      type={type}
      fullWidth={!!fullWidth}
      sx={{ ...sx, backgroundColor }}
      color={color}
      disabled={disabled}
    >
      <Typography
        variant={textVariant}
        fontSize={fontSize}
        color={textColor}
        fontWeight={fontWeight}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          ...typographySx,
        }}
      >
        {children}
      </Typography>
    </ButtonStyled>
  );
};

const ButtonStyled = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor?: string }>(({ backgroundColor }) => ({
  textTransform: 'none',
  boxShadow: 'none',
  borderRadius: 8,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    backgroundColor,
  },
}));
