import { useCallback } from 'react';

import { DashboardWidgetContentType } from '@core/modules/DashbordModule/types';

import {
  DashboardWidgetDto,
  SupportedWidgetsEnum,
} from '@/__generated__/graphql.ts';

import { KpiChartMenu } from '../../components/menus/KpiChartMenu';

export const useWidgetMenu = (
  dashboardWidgetData: DashboardWidgetDto,
  widgetContent: DashboardWidgetContentType,
  closeMenu: () => void,
) => {
  const renderMenuVariant = useCallback(() => {
    switch (dashboardWidgetData.widgetType) {
      case SupportedWidgetsEnum.KpiChart:
        return (
          <KpiChartMenu
            widgetContent={widgetContent}
            closeMenu={closeMenu}
            dashboardWidgetData={dashboardWidgetData}
          />
        );
    }
  }, [dashboardWidgetData, widgetContent, closeMenu]);

  return {
    renderMenuVariant,
  };
};
