import { ReactNode, createContext, useContext } from 'react';

import { useSettlementPeriod } from '../module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod';

type KaizenSettlementPeriodType = { [key: string]: string };

export const KaizenSettlementPeriodContext =
  createContext<KaizenSettlementPeriodType | null>(null);

export const KaizenSettlementPeriodProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { settlementsPeriodMap } = useSettlementPeriod();

  return (
    <KaizenSettlementPeriodContext.Provider value={settlementsPeriodMap}>
      {children}
    </KaizenSettlementPeriodContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useKaizenSettlementPeriodContext = () => {
  const context = useContext(KaizenSettlementPeriodContext);

  if (!context) {
    throw new Error(
      'useSettlementPeriodContext must be used within a KaizenSettlementPeriodProvider',
    );
  }

  return context;
};
