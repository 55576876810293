import { useTranslation } from 'react-i18next';

import { CommentCategoriesSelect } from '@e-flow/components/CommentModule/components/CommentCategoriesSelect.tsx';
import { PrimaryButton } from '@e-schema/styles/Employee.ts';

import { Box, styled } from '@mui/material';

import { Button, CharsCountingInput } from '@core/components';

import { theme } from '@/theme';

import { EditCommentType } from './EditComment.type';
import { useEditComment } from './useEditComment';

export const EditCommentForm = (props: EditCommentType) => {
  const { t: commonT } = useTranslation();

  const {
    selectedSymbol,
    isContentError,
    isCategoryError,
    contentErrorMessage,
    setSelectedSymbol,
    handleSubmit,
    registerContent,
    commentsCategory,
    submitForm,
  } = useEditComment(props);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <AddCommentContainer onSubmit={handleSubmit(submitForm)}>
      <CharsCountingInput
        maxInputLength={215}
        sx={{ width: '100%', maxHeight: '70px' }}
        placeholder={commonT('common.addComment')}
        {...registerContent()}
        error={isContentError}
        helperText={contentErrorMessage}
      />
      <RowAlignLeft>
        <CommentCategoriesSelect
          isErrors={isCategoryError}
          selectedSymbol={selectedSymbol}
          setSelectedSymbol={setSelectedSymbol}
          commentsCategory={commentsCategory}
        />
      </RowAlignLeft>
      <RowAlignRight sx={{ marginTop: '10px' }}>
        <Button
          onClick={props.onCancel}
          variant="text"
          textColor={theme.palette.text.primary}
        >
          {commonT('common.cancel')}
        </Button>

        <PrimaryButton variant={'contained'} type={'submit'}>
          {commonT('common.edit')}
        </PrimaryButton>
      </RowAlignRight>
    </AddCommentContainer>
  );
};

const AddCommentContainer = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const RowAlignLeft = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  gap: '5px',
  width: '100%',
  alignItems: 'center',
});

const RowAlignRight = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  gap: '10px',
  width: '100%',
  alignItems: 'center',
});
