import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoneIcon from '@mui/icons-material/Done';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { Menu } from '@mui/material';

import { CustomMenuItem, Snackbar } from '@/core/components';

import {
  useCloneEflow,
  useEflowPermissions,
  useUpdateEflowStatus,
} from '../../hooks';
import { MenuProps } from './AnalizeCard.types';

export const AnalizeMenu: FC<MenuProps> = ({
  anchorEl,
  open,
  handleClose,
  flowData,
}) => {
  const { t } = useTranslation();
  const { t: eflowT } = useTranslation('eflow');

  const navigate = useNavigate();
  const { cloneEflow, snackBarMessage, isSnackBarOpen, closeSnackbar } =
    useCloneEflow();

  const { setEflowStatusAsCompleted, isSetAsFinishedDisabled } =
    useUpdateEflowStatus(flowData.id, flowData.status);

  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: flowData.id,
  });

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <CustomMenuItem
          text={t('common.share')}
          onClick={() => {}}
          icon={<ShareIcon />}
          disabled={true}
        />
        <CustomMenuItem
          text={t('common.duplicate')}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async () => await cloneEflow(flowData.id)}
          disabled={!canModifyAnalize}
          icon={<ContentCopyIcon />}
        />
        <CustomMenuItem
          text={t('common.delete')}
          onClick={() => {}}
          icon={<DeleteOutlineOutlinedIcon />}
          disabled={true}
        />
        <CustomMenuItem
          text={eflowT('dashboard.analizeSettings')}
          onClick={() => {
            navigate(`${flowData.id}/update-analysis`);
          }}
          icon={<SettingsOutlinedIcon />}
          disabled={!canModifyAnalize}
        />
        <CustomMenuItem
          text={eflowT('dashboard.markAsDone')}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => setEflowStatusAsCompleted()}
          icon={<DoneIcon />}
          disabled={!canModifyAnalize || isSetAsFinishedDisabled}
        />
        <CustomMenuItem
          text={eflowT('dashboard.openInNew')}
          onClick={() => {}}
          icon={<OpenInNewIcon />}
          disabled={true}
        />
      </Menu>

      <Snackbar
        message={snackBarMessage}
        onClose={closeSnackbar}
        open={isSnackBarOpen}
      />
    </>
  );
};
