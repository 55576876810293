import { useEffect, useMemo, useState } from 'react';

import { useSettlementPeriod } from '@kpi/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod';
import { useDebounceValue } from 'usehooks-ts';

import {
  useCompanyId,
  useGetCurrentUserDetails,
  useTablePagination,
} from '@core/hooks';

import {
  UnitKpiRateInput,
  useFindAllKpisLazyQuery,
} from '@/__generated__/graphql';

export const useGetKpis = () => {
  const { currentActivePeriod } = useSettlementPeriod();
  const { companyId } = useCompanyId();
  const { user } = useGetCurrentUserDetails();

  const [search, setSearch] = useState('');
  const [debounceSearch, setDebounceSearch] = useDebounceValue('', 500);
  const [assignedPersonId, setAssignedPersonId] = useState('');
  const [organizationUnitId, setOrganizationUnitId] = useState<string[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<
    UnitKpiRateInput | undefined
  >();

  const { cursor, setCursor, rowsPerPage, setRowsPerPage } =
    useTablePagination();

  const [findAllKpisQuery, { data, loading: isLoadingKpis }] =
    useFindAllKpisLazyQuery();

  useEffect(() => {
    void findAllKpisQuery({
      variables: {
        input: {
          organizationId: companyId,
          organizationUnitIds: organizationUnitId,
          settlementPeriodIds: currentActivePeriod?.id
            ? [currentActivePeriod?.id]
            : [],
          nameSearch: debounceSearch,
          limit: rowsPerPage,
          cursor,
          unitSearch: selectedUnit?.unitName,
          ...(assignedPersonId && {
            employeeIds: [assignedPersonId],
          }),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debounceSearch,
    assignedPersonId,
    organizationUnitId,
    selectedUnit,
    cursor,
    rowsPerPage,
    companyId,
    currentActivePeriod?.id,
  ]);

  const setSearchInput = (value: string) => {
    setSearch(value);
    setDebounceSearch(value);
  };

  const reset = () => {
    setSearch('');
    setDebounceSearch('');
    setAssignedPersonId('');
    setOrganizationUnitId([]);
    setSelectedUnit(undefined);
    setCursor(0);
    setRowsPerPage(10);
  };

  const defaultOrganizationUnitData = useMemo(() => {
    if (user) {
      return [
        {
          id: user.organizationUnitGuid,
          name: user.organizationUnit.name,
        },
      ];
    }
  }, [user]);

  return {
    // Data
    kpiRates: data?.findAllKpis.items || [],
    total: data?.findAllKpis.total || 0,
    isLoadingKpis,
    //Filters
    debounceSearch,
    search,
    assignedPersonId,
    organizationUnitId,
    defaultOrganizationUnitData,
    selectedUnit,
    // Setters
    setSearchInput,
    setAssignedPersonId,
    setDebounceSearch,
    setOrganizationUnitId,
    setSelectedUnit,
    // Pagination
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,
    // Reset
    reset,
  };
};
