import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { UseSubmissionNameView } from '@kaizen/views/SubmissionNameView/hooks/useSubmissionNameView.ts';

import { SubmissionNameSchema } from './SubmisionName.schema';

export const useSubmissionNameView = ({
  defaultValue = '',
  onSubmit,
}: UseSubmissionNameView) => {
  const { t: kaizenT } = useTranslation('kaizen');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      name: defaultValue,
    },
    resolver: zodResolver(SubmissionNameSchema(kaizenT)),
  });

  useEffect(() => {
    setValue('name', defaultValue, { shouldTouch: true, shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const submit: SubmitHandler<{ name: string }> = (currentValue) => {
    const nameValue = currentValue.name.trim();

    if (!nameValue) {
      setError('name', {
        message: kaizenT(
          'submissionModificationView.common.submissionName.errors.nameRequired',
        ),
      });
      return;
    }

    if (nameValue.length < 3) {
      setError('name', {
        message: kaizenT(
          'submissionModificationView.common.submissionName.errors.nameTooShort',
          {
            charLength: 3,
          },
        ),
      });
      return;
    }

    if (nameValue.length > 128) {
      setError('name', {
        message: kaizenT(
          'submissionModificationView.common.submissionName.errors.nameTooLong',
          {
            charLength: 128,
          },
        ),
      });
      return;
    }

    onSubmit(nameValue);
  };

  return {
    isFormError: !!errors.name,
    formErrorMessage: errors.name?.message,
    register,
    submit: handleSubmit(submit),
  };
};
