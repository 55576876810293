import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { maxKpisSelectedForChart } from '@kpi/constant';
import { KpisRateTable } from '@kpi/modals/HandleKpiChartModal/components/KpisRateTable/KpisRateTable.tsx';
import { useCreateChartContext } from '@kpi/modals/HandleKpiChartModal/context';
import { RowStack } from '@organizations/styles';

import { Alert, Box, Typography, styled } from '@mui/material';

import { TextButton } from '@core/components';

import { theme } from '@/theme';

import { SelectedKpisSection } from '../components/SelectedKpisSection/SelectedKpisSection';

export const PickKpiRateView = () => {
  const { t: commonT } = useTranslation();
  const { t } = useTranslation('kpi');

  const {
    tempSelectedKpis,
    handleConfirmSelection,
    setSettingsView,
    handleAbordSelection,
  } = useCreateChartContext();

  return (
    <MainContainer>
      <Typography variant="bodyRegular">
        {t('charts.selectKpisCommunicate', {
          maxAmount: maxKpisSelectedForChart,
        })}
      </Typography>
      {tempSelectedKpis.length === maxKpisSelectedForChart && (
        <Alert severity="info">
          {t('charts.maxKpisSelected', {
            maxAmount: maxKpisSelectedForChart,
          })}
        </Alert>
      )}

      <KpisRateTable />

      <SelectedKpisSection />
      <RowStack justifyContent={'end'}>
        <TextButton
          text={commonT('common.cancel')}
          width="66px"
          onClick={() => {
            handleAbordSelection();
            setSettingsView();
          }}
        />
        <PrimaryButton
          disabled={tempSelectedKpis.length === 0}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary[600],
          }}
          onClick={() => {
            handleConfirmSelection();
            setSettingsView();
          }}
        >
          <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
            {commonT('common.select')} ({tempSelectedKpis.length}/
            {maxKpisSelectedForChart})
          </Typography>
        </PrimaryButton>
      </RowStack>
    </MainContainer>
  );
};

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '24px',
  overflow: 'auto',
  maxHeight: '700px',
});
