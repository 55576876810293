import { useTranslation } from 'react-i18next';

import { Close } from '@mui/icons-material';
import { Stack, Table, TableCell, TableRow, Typography } from '@mui/material';

import { Button } from '@core/components';
import { IconWrapper } from '@core/components/Icons/Wrapper/IconWrapper';

import { theme } from '@/theme';

import { useCreateChartContext } from '../../context';

export const KpisTable = () => {
  const { t: commonT } = useTranslation();
  const { t } = useTranslation('kpi');

  const {
    selectedKpis,
    unit,
    setSelectedKpis,
    setSelectKpisView,
    handleOpenSelectKpiView,
  } = useCreateChartContext();

  return (
    <Stack gap="16px">
      <Typography variant="h6">{t('charts.kpi')}</Typography>
      <Table>
        {selectedKpis.map((kpi) => (
          <TableRow>
            <TableCell>{kpi.rateName}</TableCell>
            <TableCell>{kpi.organizationUnit.name}</TableCell>
            <TableCell>{`${kpi.assignee.firstName} ${kpi.assignee.lastName}`}</TableCell>
            <TableCell>
              {kpi.hasTarget ? commonT('common.target') : '-'}
            </TableCell>
            <TableCell>{kpi.unit.unitShortcut}</TableCell>
            <TableCell align="right">
              <IconWrapper
                onClick={() =>
                  setSelectedKpis((prev) =>
                    prev.filter((clickedKpi) => clickedKpi.id !== kpi.id),
                  )
                }
                icon={<Close />}
                title={commonT('common.delete')}
              />
            </TableCell>
          </TableRow>
        ))}
      </Table>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Button
          backgroundColor={theme.palette.grey[200]}
          textColor={theme.palette.text.primary}
          type="button"
          onClick={() => {
            setSelectKpisView();
            handleOpenSelectKpiView();
          }}
        >
          {t('charts.selectKpi')}
        </Button>
        <Typography variant="bodyRegular">
          {commonT('common.unit')}: ({unit?.unitShortcut})
        </Typography>
      </Stack>
    </Stack>
  );
};
