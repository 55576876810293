import { z } from 'zod';

import { passwordValidationSchema } from '@/utils';

export type NewPasswordValidationSchemaErrors = {
  /**
   * Error message for required password
   * @example
   * Password is required
   */
  passwordRequired: string;
  /**
   * Error message for passwords that don't match
   * @example
   * Passwords do not match
   */
  passwordDonMatch: string;
  /**
   * Error message for invalid password
   * @example
   * Password is invalid
   */
  passwordInvalid: string;
};

/**
 * New password validation schema
 * @param passwordRequired Error message for required password
 * @param passwordDonMatch Error message for passwords that don't match
 * @param passwordInvalid Error message for invalid password
 * @returns New password validation schema, including password and repeatPassword
 */
export const newPasswordValidationSchema = ({
  passwordRequired,
  passwordDonMatch,
  passwordInvalid,
}: NewPasswordValidationSchemaErrors) =>
  z
    .object({
      password: passwordValidationSchema(passwordRequired, passwordInvalid),
      repeatPassword: z.string({
        required_error: passwordRequired,
      }),
    })
    .superRefine(({ repeatPassword, password }, ctx) => {
      if (repeatPassword !== password) {
        ctx.addIssue({
          code: 'custom',
          message: passwordDonMatch,
          path: ['repeatPassword'],
        });
      }
    });
