import { create } from 'zustand';

import { IuseErrorStore } from '.';

export const useErrorStore = create<IuseErrorStore>((set) => ({
  setError: (code, uuid = null) => {
    const stringCode = code === null ? null : String(code);
    set((state) => ({
      ...state,
      errorCode: stringCode,
      errorUuid: uuid,
    }));
    if (stringCode && stringCode[0] !== '5')
      location.href = '/error/' + stringCode;
  },
  errorCode: null,
  errorUuid: null,
}));
