import { TFunction } from 'i18next';

import { ChartTypes } from '@/__generated__/graphql';

import { PickColor } from '.';
import { ExtractChartKeyType } from '../types';
import { FormatPieChartDataType } from '../types/FormatPieChartData.type';

export const FormatPieChartSingleDataSetInAscOrder = (
  props: ExtractChartKeyType,
  monthsSet: number[],
  isPercent: boolean,
  getMonthName: (month?: string | number | undefined) => string | number,
  t: TFunction<'kpi', undefined>,
): FormatPieChartDataType[] => {
  const monthIndex = monthsSet?.[0] ?? 0;

  const endMonthIndex =
    monthsSet && monthsSet.length > 0 ? monthsSet[monthsSet.length - 1] : 0;

  const sumOfIndicators = props.reduce(
    (previousValue: { target: number; value: number }, currentValue) => {
      const newValue = { ...previousValue };

      for (const key in currentValue) {
        if (key === 'name' || key === 'target') {
          continue;
        }

        const target = currentValue[key].target;

        if (target) {
          newValue.target += target;
        }

        if (!monthsSet.includes(+currentValue.name)) {
          continue;
        }

        if (currentValue[key].value) {
          newValue.value += currentValue[key].value;
        }
      }

      return newValue;
    },
    { target: 0, value: 0 },
  );

  if (isPercent) {
    if (!sumOfIndicators.target) {
      return [
        {
          name: `${getMonthName(monthIndex)} - ${getMonthName(endMonthIndex)}`,
          fill: PickColor.pickColor([], ChartTypes.PieChart),
          value: 100,
        },
      ];
    }
    const value = +(
      (sumOfIndicators.value / sumOfIndicators.target) *
      100
    ).toFixed(1);
    const target = 100 - value;

    return returnValue(
      { value, target },
      t,
      getMonthName,
      monthIndex,
      endMonthIndex,
      100,
    );
  }

  const tooltipValue = sumOfIndicators.target;

  if (sumOfIndicators.target > sumOfIndicators.value) {
    sumOfIndicators.target = sumOfIndicators.target - sumOfIndicators.value;
  } else {
    sumOfIndicators.target = 0;
  }

  if (!sumOfIndicators.target) {
    return [
      {
        name: t('charts.yearlyTarget'),
        fill: PickColor.pickColor([], ChartTypes.PieChart),
        value: sumOfIndicators.value,
      },
    ];
  }

  return returnValue(
    sumOfIndicators,
    t,
    getMonthName,
    monthIndex,
    endMonthIndex,
    tooltipValue,
  );
};

const returnValue = (
  sumOfIndicators: { target: number; value: number },
  t: TFunction<'kpi', undefined>,
  getMonthName: (month?: string | number | undefined) => string | number,
  monthIndex: number,
  endMonthIndex: number,
  tooltipValue?: number,
) => {
  const usedColors: string[] = [];

  return [
    {
      name: `${getMonthName(monthIndex)} - ${getMonthName(endMonthIndex)}`,
      fill: PickColor.pickColor(usedColors, ChartTypes.PieChart),
      value: sumOfIndicators.value,
    },
    {
      name: t('charts.yearlyTarget'),
      fill: PickColor.pickColor(usedColors, ChartTypes.PieChart),
      value: sumOfIndicators.target,
      tooltipValue,
    },
  ];
};
