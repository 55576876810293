import { useState } from 'react';

import {
  Box,
  AvatarGroup as MuiAvatarGroup,
  Popover,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { Employee } from '@/__generated__/graphql';
import { EmployeeAvatar, NamedAvatar } from '@/core/components';

import { AvatarGroupProps } from './AvatarGroup.types';

export const AvatarGroup = ({ members }: AvatarGroupProps) => {
  const [anchorPopover, setAnchorPopover] = useState<HTMLElement | null>(null);

  const handlePopoverClose = () => {
    setAnchorPopover(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPopover(event.currentTarget);
  };

  const getName = (
    member: Pick<Employee, 'firstName' | 'lastName' | 'profilePicture' | 'id'>,
  ) =>
    !member.profilePicture ? `${member.firstName[0]}${member.lastName[0]}` : '';

  const isOpen = Boolean(anchorPopover);
  return (
    <>
      <StyledAvatarGroup
        aria-owns={isOpen ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        total={members.length}
        max={3}
      >
        {members.map((member, index) => (
          <EmployeeAvatar
            key={index}
            imageUrl={member.profilePicture}
            name={getName(member)}
          ></EmployeeAvatar>
        ))}
      </StyledAvatarGroup>
      <MembersPopover
        open={isOpen}
        anchorOrigin={{
          vertical: -70,
          horizontal: 'center',
        }}
        anchorEl={anchorPopover}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {members.map((member, index) => (
          <MemberBox key={index}>
            <MemberStack>
              <NamedAvatar key={member.id} {...member} />
              <Typography>
                {member.firstName} {member.lastName}
              </Typography>
            </MemberStack>
          </MemberBox>
        ))}
      </MembersPopover>
    </>
  );
};

const StyledAvatarGroup = styled(MuiAvatarGroup)({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& .MuiAvatar-root': {
    width: '24px',
    height: '24px',
    fontSize: '12px',
  },
});

const MemberBox = styled(Box)({
  padding: '24px',
});

const MemberStack = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
});

const MembersPopover = styled(Popover)({
  pointerEvents: 'none',
  padding: '12px',
});
