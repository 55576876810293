import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee.ts';

import { Skeleton, Typography, styled } from '@mui/material';

import { UserEditPropertyWrapperProps } from '@/core/components/UserEdit/UserEditProp/userEditPropertyWrapper.typs.ts';
import { Row, TypographyLabel } from '@/core/components/UserEdit/style.ts';
import { theme } from '@/theme';

export const UserEditPropertyWrapper = (
  props: UserEditPropertyWrapperProps,
) => {
  const { t } = useTranslation();

  if (props.isLoading) {
    return (
      <Row>
        <Skeleton width={450} />
      </Row>
    );
  }
  return (
    <Row>
      <TypographyLabel variant="bodyRegular" fontSize={14}>
        {props.propertyName}
      </TypographyLabel>
      {props.edit ? (
        props.children
      ) : (
        <Typography variant="bodyRegular" fontSize={14}>
          {props.propertyValue}
        </Typography>
      )}
      {props.isEditButtonVisible && !props.edit && (
        <EditRowButton onClick={() => props.onEdit && props.onEdit()}>
          {t('common.edit')}
        </EditRowButton>
      )}
    </Row>
  );
};

const EditRowButton = styled(PrimaryButton)({
  color: theme.palette.text.primary,
  padding: 0,
  position: 'absolute',
  right: 0,
});
