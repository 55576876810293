import { OrganizationModules } from '@/__generated__/graphql.ts';

import eFlowIcon from '/assets/navbar-digital-factory-eflow.svg';
import homePageIcon from '/assets/navbar-digital-factory-home-page.svg';
import kaizenIcon from '/assets/navbar-digital-factory-kaizen.svg';
import kpiIcon from '/assets/navbar-digital-factory-kpi.svg';
import eSchemaIcon from '/assets/navbar-digital-factory-structure.svg';

export const getNavbarModuleIcons = (variant?: OrganizationModules): string => {
  switch (variant) {
    case OrganizationModules.Kpi:
      return kpiIcon;
    case OrganizationModules.ESchema:
      return eSchemaIcon;
    case OrganizationModules.EFlowAnalize:
      return eFlowIcon;
    case OrganizationModules.Kaizen:
      return kaizenIcon;
    default:
      return homePageIcon;
  }
};
