import { captureException } from '@sentry/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Box, Skeleton, Typography } from '@mui/material';

import { useUpdateProfilePictureMutation } from '@/__generated__/graphql';
import { ImageUpload } from '@/core/components';
import { ISectionProps, UserEditTypes } from '@/core/types';
import { useUserStore } from '@/stores';
import { theme } from '@/theme';

import {
  EditButton,
  Row,
  SectionTitleRow,
  SettingsSectionContainer,
  TypographyLabel,
} from '../style';
import { AvatarSectionProps } from './AvatarSection.types';

export const AvatarSection: FC<ISectionProps & AvatarSectionProps> = ({
  edit,
  setEdit,
  employee,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { setProfilePicture } = useUserStore();

  const [image, setImage] = useState<string | null>(null);

  const [updateProfilePicture] = useUpdateProfilePictureMutation();

  const updateAvatar = async (file: File | null) => {
    try {
      const res = await updateProfilePicture({
        variables: {
          input: {
            id: employee!.id,
            organizationId: employee!.organizationId,
          },
          file,
        },
      });
      const profilePicture: string | null =
        res.data?.updateEmployeeProfilePicture.profilePicture || null;
      setImage(profilePicture);
      setProfilePicture(profilePicture);
    } catch (error) {
      captureException(error);
    }
  };

  useEffect(() => {
    setImage((employee?.profilePicture as string) || null);
  }, [employee]);

  return (
    <>
      <ImageUpload
        isOpen={UserEditTypes.Avatar === edit}
        onClose={() => setEdit(UserEditTypes.None)}
        onUpload={updateAvatar}
        onRemove={() => {
          void (async () => {
            await updateAvatar(null);
          })();
        }}
        imageUrl={image}
        title={`${t('common.edit')} ${t('common.avatar').toLocaleLowerCase()}`}
        selectImageLabel={t('employee.avatar.select')}
        changeImageLabel={t('employee.avatar.change')}
        removeImageLabel={t('common.delete')}
        placeholderLetter={employee?.firstName?.charAt(0) || ''}
        altImageText="avatar"
      />

      <SettingsSectionContainer>
        <SectionTitleRow>
          <Row>
            <TypographyLabel variant="bodyRegular" fontSize={14}>
              {t('common.avatar')}
            </TypographyLabel>
            {isLoading ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : image ? (
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary[600],
                }}
                src={image}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary[600],
                }}
              >
                {employee?.firstName?.charAt(0)}
              </Avatar>
            )}
          </Row>
          <EditButton
            disabled={edit !== UserEditTypes.None}
            type="button"
            onClick={() => setEdit(UserEditTypes.Avatar)}
          >
            <Typography fontSize={14} variant="bodyMedium">
              {t('common.edit')}
            </Typography>
          </EditButton>
        </SectionTitleRow>
        <Box />
      </SettingsSectionContainer>
    </>
  );
};
