import React from 'react';

import { StepProgressProps } from './types.ts';

export const StepProgressContext = React.createContext<StepProgressProps>({
  stepProgressSnackbarOpen: false,
  setStepProgressSnackbarOpen: () => {},
  stepProgressValue: 0,
  setStepProgressValue: () => {},
});
