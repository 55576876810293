import { captureException } from '@sentry/react';

import { useUpdateSingleSubmissionCache } from '@kaizen/hooks';

import {
  KaizenSubmissionFragment,
  useSetKaizenSubmissionCommissionAssessmentStatusMutation,
} from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

export const useSendToCommissionAssessment = () => {
  const { companyId } = useCompanyId();

  const [sendToCommissionAssessmentMutation, { loading: isLoading }] =
    useSetKaizenSubmissionCommissionAssessmentStatusMutation();

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const sendToCommissionAssessment = async (submissionId: string) => {
    try {
      const result = await sendToCommissionAssessmentMutation({
        variables: {
          input: {
            submissionId,
            organizationId: companyId,
          },
        },
      });
      if (result.data?.setKaizenSubmissionCommissionAssessmentStatus) {
        updateSingleSubmissionCache(
          result.data
            .setKaizenSubmissionCommissionAssessmentStatus as KaizenSubmissionFragment,
        );
        return true;
      }
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  return {
    isLoading,
    sendToCommissionAssessment,
  };
};
