import { useEffect } from 'react';

import { useGetSettlementPeriodSubmissionNumberLazyQuery } from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

export const useFindSubmissionNumberForPeriod = (
  settlementPeriodId?: string,
) => {
  const { companyId } = useCompanyId();

  const [getSettlementPeriodSubmissionNumQuery, { data, loading: isLoading }] =
    useGetSettlementPeriodSubmissionNumberLazyQuery({
      nextFetchPolicy: 'cache-only',
    });

  useEffect(() => {
    if (!companyId || !settlementPeriodId) return;

    void getSettlementPeriodSubmissionNumQuery({
      variables: {
        input: {
          id: settlementPeriodId,
          organizationId: companyId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, settlementPeriodId]);

  return {
    isLoading,
    totalNumberOfSubmissions: data?.getSettlementPeriodSubmissionNumber.total,
  };
};
