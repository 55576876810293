import { useMemo } from 'react';

import { useKaizenSubmissionFlow } from '@kaizen/hooks';
import {
  useRenderButtonForCommisionLeader,
  useRenderButtonForSubmissionAuthor,
  useRenderButtonForSubmissionContractor,
  useRenderButtonForUnitAdmin,
} from '@kaizen/hooks/useManageKaizenSubmissionsFlowButtons/handlers';

import { checkSystemRolesPermission } from '@core/utils';

import { KaizenStatuses, SystemRoles } from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

export const useManageKaizenSubmissionFlowButtons = (
  submissionStatus: KaizenStatuses,
  submissionId: string,
  submissionAuthorId: string,
  contractorId?: string,
) => {
  const { isLoading, submissionFlow } = useKaizenSubmissionFlow();

  const { userGroups, userUuid } = useUserStore();

  const { renderButtonForCommisionLeader } =
    useRenderButtonForCommisionLeader();
  const { renderButtonForSubmissionContractor } =
    useRenderButtonForSubmissionContractor();
  const { renderButtonForSubmissionUnitAdmin } = useRenderButtonForUnitAdmin();
  const { renderButtonsForSubmissionAuthor } =
    useRenderButtonForSubmissionAuthor();

  const isPrivilegedUser = useMemo(() => {
    return checkSystemRolesPermission(userGroups, [
      SystemRoles.KaizenCommisionLeader,
      SystemRoles.SystemAdmin,
      SystemRoles.Administrator,
      SystemRoles.KeyUser,
      SystemRoles.Root,
    ]);
  }, [userGroups]);

  const isUnitAdmin = useMemo(() => {
    return checkSystemRolesPermission(userGroups, [SystemRoles.UnitAdmin]);
  }, [userGroups]);

  const submissionButtons = useMemo(() => {
    if (
      [
        KaizenStatuses.Draft,
        KaizenStatuses.SupervisorAssessment,
        KaizenStatuses.ImplementationAccepted,
      ].includes(submissionStatus)
    ) {
      if (isUnitAdmin && submissionAuthorId !== userUuid) {
        return renderButtonForSubmissionUnitAdmin(
          submissionStatus,
          submissionId,
        );
      }

      if (submissionAuthorId === userUuid) {
        return renderButtonsForSubmissionAuthor(
          submissionStatus,
          submissionId,
          isPrivilegedUser,
        );
      }
    }

    if (
      submissionStatus === KaizenStatuses.Todo ||
      submissionStatus === KaizenStatuses.InProgress
    ) {
      if (contractorId === userUuid) {
        return renderButtonForSubmissionContractor(
          submissionStatus,
          submissionId,
          isPrivilegedUser,
        );
      }
    }

    if (checkSystemRolesPermission(userGroups, [SystemRoles.UnitAdmin])) {
      return renderButtonForSubmissionUnitAdmin(submissionStatus, submissionId);
    }

    if (isPrivilegedUser) {
      return renderButtonForCommisionLeader(submissionStatus, submissionId);
    }

    return [];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submissionFlow,
    submissionStatus,
    submissionId,
    userGroups,
    isPrivilegedUser,
  ]);

  return {
    isLoading,
    submissionButtons,
  };
};
