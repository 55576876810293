import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectPositionInput } from '@e-schema/components';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box } from '@mui/material';

import { findPathById } from '@core/utils';

import {
  EmployeeState,
  useUpdateEmployeeUnitStatePositionMutation,
} from '@/__generated__/graphql.ts';
import { ButtonGroupConfirmCancel } from '@/core/components/ButtonGroupConfirmCancel/ButtonGroupConfirmCancel.tsx';
import { UnitSectionSchema } from '@/core/components/UserEdit/UnitSection/UnitSection.Schema.ts';
import { UserEditBadgeRow } from '@/core/components/UserEdit/UserEditBadgeRow/UserEditBadgeRow.tsx';
import { UserEditPropertyWrapper } from '@/core/components/UserEdit/UserEditProp/UserEditProperityWrapper.tsx';
import { UserSectionProps } from '@/core/components/UserEdit/UserSection/UpdateUser.types.ts';
import {
  FlexEndBox,
  FormContainer,
  SettingsSectionContainer,
} from '@/core/components/UserEdit/style.ts';
import { TreeTarget } from '@/types';

import { SelectCellModal } from '../../Modals/SelectCellModal';

const getPath = (employee: UserSectionProps['employee']) => {
  return employee?.organizationUnitPath?.id
    ? findPathById(
        // eslint-disable-next-line
        employee?.organizationUnitPath,
        employee?.organizationUnitGuid,
      ) || ''
    : '';
};

export const UnitSection = (props: UserSectionProps) => {
  const [tempCell, setTempCell] = useState<TreeTarget>({
    targetId: props.employee?.organizationUnitGuid || '',
    path: getPath(props.employee),
  });
  const [isEdit, setIsEdit] = useState(false);

  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: props.employee,
    resolver: zodResolver(UnitSectionSchema(t)),
  });

  useEffect(() => {
    reset(props.employee);
    setTempCell({
      targetId: props.employee?.organizationUnitGuid || '',
      path: getPath(props.employee),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employee]);

  const [query] = useUpdateEmployeeUnitStatePositionMutation();

  const handleSubmitForm = async (data: UserSectionProps['employee']) => {
    try {
      await query({
        variables: {
          input: {
            organizationId: props.employee!.organizationId,
            id: props.employee!.id,
            ...(tempCell.targetId && {
              organizationUnitGuid: tempCell.targetId,
            }),
            position: data!.position,
            state: data!.state,
            systemRole: props.employee!.systemRole,
          },
        },
      });
      props.setToastMessage(t('common.dataSaved'));
    } catch (e) {
      captureException(e);
      props.setToastMessage(t('common.dataNotSaved'));
    } finally {
      setIsEdit(false);
      if (props.refetch) props.refetch();
    }
  };

  if (props.isLoading) return null;

  return (
    <SettingsSectionContainer>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <FormContainer onSubmit={handleSubmit(handleSubmitForm)}>
        <Box width={'100%'}>
          {/*position*/}
          <UserEditPropertyWrapper
            isLoading={props.isLoading}
            edit={isEdit}
            propertyName={t('employees.position')}
            propertyValue={props?.employee?.position}
            isEditButtonVisible={!props.isReadOnly}
            onEdit={() => {
              setIsEdit(true);
            }}
          >
            <SelectPositionInput
              organizationId={props.employee?.organizationId}
              {...register('position')}
              defaultValue={props.employee?.position}
              error={!!errors.position}
              helperText={errors.position?.message}
            />
          </UserEditPropertyWrapper>
          {/*cell*/}
          {props.employee?.organizationUnitPath &&
            props.employee?.organizationUnitPath.id && (
              <UserEditPropertyWrapper
                isLoading={props.isLoading}
                edit={isEdit}
                propertyName={t('employees.cell')}
                propertyValue={getPath(props.employee)}
              >
                <SelectCellModal
                  value={tempCell.path}
                  width={'100%'}
                  setValue={(value: TreeTarget) => {
                    setTempCell(value);
                  }}
                  label={t('employees.cell')}
                />
              </UserEditPropertyWrapper>
            )}
          {/*status*/}
          <UserEditBadgeRow
            selectProps={{
              ...register('state'),
              value: props?.employee?.state,
              onChange: (e) => {
                setValue('state', e.target.value as EmployeeState);
              },
              variant: 'outlined',
            }}
            isLoading={props.isLoading}
            edit={isEdit}
            propertyName={t('employees.status')}
            employeeState={props?.employee?.state}
          />
          <FlexEndBox>
            <ButtonGroupConfirmCancel
              isVisible={isEdit}
              onCancel={() => {
                reset();
                setIsEdit(false);
              }}
            />
          </FlexEndBox>
        </Box>
      </FormContainer>
    </SettingsSectionContainer>
  );
};
