import { TableCell, Typography, styled } from '@mui/material';

import { theme } from '@/theme';

import { RowContentProps } from './UserRow.types';

export const RowCell = ({ item, disabled }: RowContentProps) => {
  const itemToReturn =
    typeof item === 'string' ? (
      <CustomTopography>{item}</CustomTopography>
    ) : (
      item
    );
  return (
    <TableCell sx={disabled ? { color: theme.palette.grey[400] } : null}>
      {itemToReturn}
    </TableCell>
  );
};
const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
