import { useTranslation } from 'react-i18next';

import { Box, styled } from '@mui/material';

import { SearchTextField } from '@/core/components';
import { SelectCellModal } from '@/core/components/Modals/SelectCellModal';
import { TreeTarget } from '@/types';

import { PaginationFilterLayoutProps } from './PaginationFilterLayout.types';

export const PaginationFilterLayout = (props: PaginationFilterLayoutProps) => {
  const { t: commonT } = useTranslation();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 255) {
      props.setSearch(event.target.value);
    }
  };

  return (
    <SearchContainer>
      {/* search */}
      <SearchTextField
        onChange={(event) => {
          handleSearch(event);
        }}
        placeholder={commonT('organization.search')}
        value={props.search}
      />
      {/*select cell*/}
      <SelectCellModal
        value={props.organizationUnit.path}
        setValue={(value: TreeTarget) => props.setOrganizationUnit(value)}
        label={commonT('employees.searchCell')}
        onClear={() =>
          props.setOrganizationUnit({
            targetId: '',
            path: '',
          })
        }
      />
    </SearchContainer>
  );
};

const SearchContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '12px',
});
