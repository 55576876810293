/**
 * Funtion to calculate new cursor
 * @param page - current page
 * @param rowsPerPage - rows per page
 * @param total - total number of rows
 * @returns new cursor
 */

export const calculateCursor = (
  page: number,
  rowsPerPage: number,
  total: number,
) => {
  if (page * rowsPerPage <= total) return page * rowsPerPage;
  else return total - (total % rowsPerPage);
};
