import { useEffect, useState } from 'react';

import { AsIsToBeNamesEnum } from '@/__generated__/graphql';

export const useManageSteps = (initStep: AsIsToBeNamesEnum) => {
  const [openViews, setOpenViews] = useState<AsIsToBeNamesEnum[]>([initStep]);
  const [asIsStepId, setAsIsStepId] = useState('');
  const [toBeStepId, setToBeStepId] = useState('');

  useEffect(() => {
    setOpenViews([initStep]);
  }, [initStep]);

  const handleInit = (view: AsIsToBeNamesEnum, id: string) => {
    setOpenViews([view]);
    if (view === AsIsToBeNamesEnum.AsIs) setAsIsStepId(id);
    else setToBeStepId(id);
  };

  const handleChangeViews = (view: AsIsToBeNamesEnum) => {
    if (openViews.includes(view))
      setOpenViews(openViews.filter((currentView) => currentView !== view));
    else setOpenViews([...openViews, view]);
  };

  return {
    openViews,
    handleInit,
    handleChangeViews,
    asIsStepId,
    setAsIsStepId: (id: string) => setAsIsStepId(id),
    toBeStepId,
    setToBeStepId: (id: string) => setToBeStepId(id),
  };
};
