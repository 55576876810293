import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { fetchUserAttributes } from '@aws-amplify/auth';
import { CheckIfUserCanEnter } from '@router/components/ProtectedRoute/CheckAllowedGroups.ts';

import { CCognitoHandler } from '@core/providers/Cognito.ts';
import { CCognitoUser } from '@core/providers/CognitoUser.ts';

import { ErrorContent } from '@/core/components';
import { FlexCenter } from '@/core/styles';
import { IProtectedRoute } from '@/router/components/ProtectedRoute/ProtectedRoute.interface';
import { Loading } from '@/router/pages';
import { IuseErrorStore, useOrganizationStore, useUserStore } from '@/stores';

import { ProtectedRouteTypes } from './ProtectedRoute.types';

/**
 * Protected route wrapper.
 * @description
 * This component is a wrapper for the Route component from react-router-dom. Redirect user if not authenticated
 * or if the user does not have the required permissions.
 */
export const ProtectedRoute = ({
  redirectOnUnauthorized = true,
  mustBeLoggedIn = true,
  ...props
}: IProtectedRoute): ReactNode => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState<ProtectedRouteTypes>();

  const { companyName } = useParams();

  const { organization, setOrganization, organizationModules } =
    useOrganizationStore();
  const { userGroups, organizationUrl, setUser, setUserGroups } =
    useUserStore();

  useEffect(() => {
    //wait for pathname and systemRoute
    if (companyName || props.isSystemRoute) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
      void getAllRequiredUserData();
    }
    //eslint-disable-next-line
  }, [companyName, props.isSystemRoute]);

  const getAllRequiredUserData = async () => {
    try {
      const userGroups = await CCognitoHandler.getUserGroups();

      setUserGroups(userGroups);

      if (!mustBeLoggedIn) {
        setResult(ProtectedRouteTypes.Unauthorized);
      }

      const attributes = fetchUserAttributes();

      let organizationUrlPromise = null;

      if (!props.isSystemRoute) {
        organizationUrlPromise = setOrganization(companyName!);
      }

      const [resolvedAttributes] = await Promise.allSettled([
        attributes,
        organizationUrlPromise,
      ]);

      //user attributes are resolved
      if (resolvedAttributes.status === 'fulfilled') {
        const user = CCognitoUser.transformCognitoAttributes(
          resolvedAttributes.value,
        );

        setUser({
          ...user,
        });
        setUserGroups(userGroups);
      }
      if (organizationUrl) {
        setResult(setResultCalculation);
      }
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === 'NotAuthorizedException'
      ) {
        setResult(
          mustBeLoggedIn
            ? ProtectedRouteTypes.Unauthorized
            : ProtectedRouteTypes.Allowed,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const setResultCalculation = useMemo(
    () =>
      CheckIfUserCanEnter({
        userGroups: userGroups,
        allowedGroups: props.allowedGroups,
        mustBeLoggedIn: mustBeLoggedIn,
        organizationUrlFromRoute: companyName ? companyName : '',
        organizationUrlFromUser: organizationUrl,
        organization,
        isSystemRoute: props.isSystemRoute,
        organizationModules,
        currentModule: props.module,
      }),
    [
      userGroups,
      props.allowedGroups,
      mustBeLoggedIn,
      companyName,
      organizationUrl,
      organizationModules,
      organization,
      props.isSystemRoute,
      props.module,
    ],
  );

  useEffect(
    () => {
      setResult(setResultCalculation);
    },
    //eslint-disable-next-line
    [
      userGroups,
      props.allowedGroups,
      mustBeLoggedIn,
      companyName,
      organizationUrl,
      organization,
      props.isSystemRoute,
      isLoading,
    ],
  );

  //respond
  const respond = useCallback(() => {
    if (!isLoading) {
      let errorCode: IuseErrorStore['errorCode'] = '';
      switch (result) {
        case ProtectedRouteTypes.Allowed:
          if (props.children) return props.children;
          return <Outlet />;
        case ProtectedRouteTypes.Forbidden:
          errorCode = '403';
          break;
        case ProtectedRouteTypes.MissingResource:
          errorCode = '404';
          break;
        case ProtectedRouteTypes.ResourceOutsideOrganization:
          errorCode = '403-outside-org';
          break;
        case ProtectedRouteTypes.Unauthorized:
          if (redirectOnUnauthorized) {
            return (
              <Navigate
                to={organizationUrl ? `/${organizationUrl}` : '/login'}
              />
            );
          }
          errorCode = '401';
          break;
      }
      return (
        <FlexCenter sx={{ height: '100vh' }}>
          <ErrorContent
            errorCode={errorCode}
            displayCode={errorCode.substring(0, 3)}
          />
        </FlexCenter>
      );
    }
    return <Loading />;
  }, [
    isLoading,
    result,
    props.children,
    redirectOnUnauthorized,
    organizationUrl,
  ]);

  return respond();
};
