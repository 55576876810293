import { FilePreviewLayout } from './FilePreviewLayout';
import { PreviewProps } from './types';

export const ImagePreview = (props: PreviewProps) => {
  return (
    <FilePreviewLayout onClose={props.onClose}>
      <img src={props.filePath} alt={props.fileName} />
    </FilePreviewLayout>
  );
};
