import { LoadingButton, RemoveItemModal } from '@core/components';

import { RejectButtonWithModalProps } from './RejectButtonWithModal.types';

export const RejectButtonWithModal = ({
  children,
  content,
  onRemove,
  headerText,
  display,
  closeModal,
  onClick,
  isLoading,
}: RejectButtonWithModalProps) => {
  return (
    <>
      <LoadingButton
        variant="contained"
        onClick={onClick}
        isLoading={isLoading}
        variantLight={false}
        color="error"
      >
        {children}
      </LoadingButton>
      <RemoveItemModal
        headerText={headerText}
        display={display}
        closeModal={closeModal}
        content={content}
        onRemove={onRemove}
      />
    </>
  );
};
