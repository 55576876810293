import { ReactNode, createContext, useContext } from 'react';

import { useAddKpiRate } from '@kpi/modals/AddKpiRate/hooks/useAddKpiRate.tsx';

const HandleAddKpiRateContext = createContext<
  ReturnType<typeof useAddKpiRate> | undefined
>(undefined);

export const HandleAddKpiRateProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const contextValue = useAddKpiRate();

  return (
    <HandleAddKpiRateContext.Provider value={contextValue}>
      {children}
    </HandleAddKpiRateContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useHandleAddKpiRateContext = () => {
  const context = useContext(HandleAddKpiRateContext);

  if (!context) {
    throw new Error(
      'useHandleAddKpiRateContext must be used within a AddKpiRateProvider',
    );
  }

  return context;
};
