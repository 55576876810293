import { CommentComponentProps } from '@e-flow/components/SingleComment/SingleComment.types.ts';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Skeleton, Stack, styled } from '@mui/material';

import { useUserStore } from '@/stores';
import { theme } from '@/theme';

import { NamedAvatar } from '../NamedAvatar';
import { CommentMenu } from './CommentMenu';

export const CommentComponent = (props: CommentComponentProps) => {
  const { name, lastName, profilePicture } = useUserStore();

  if (props.isLoading)
    return (
      <CommentContainer>
        <CommentTitleStack sx={{ marginBottom: '10px' }}>
          <Row sx={{ width: '100%' }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" sx={{ fontSize: '24px' }} width="60%" />
          </Row>
        </CommentTitleStack>
        <Skeleton variant="rounded" height={60} />
      </CommentContainer>
    );
  else
    return (
      <>
        <CommentContainer>
          {/** Comment title 📝 */}
          <CommentTitleStack>
            <Row>
              <NamedAvatar
                firstName={props.commentProps.createdBy.firstName || name}
                lastName={props.commentProps.createdBy.lastName || lastName}
                profilePicture={
                  props.commentProps.createdBy.profilePicture || profilePicture
                }
                withNameNadSurname={true}
              />
              <span style={{ marginLeft: 4 }}>
                {props.commentProps.commentCategory}
              </span>
            </Row>

            <StyledIconButton
              onClick={(event) => props.setMenuAnchorEl(event.currentTarget)}
            >
              <MoreVert />
            </StyledIconButton>
          </CommentTitleStack>
          {/** Comment content 📄 */}
          <CommentContent>{props.commentProps.content}</CommentContent>
        </CommentContainer>
        {/** Menu options🧏‍♂️ */}
        <CommentMenu
          {...props.commentProps}
          additionalMenuItems={props.additionalMenuItems}
          anchorEl={props.menuAnchorEl}
          handleCloseMenu={props.handleCloseMenu}
          onRemove={props.handleDelete}
          canEdit={props.canEdit}
          handleStartEdit={props.handleEdit}
        />
      </>
    );
};

const CommentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  borderRadius: 10,
  width: '100%',
});

const CommentContent = styled(Box)({
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.primary,
  fontWeight: 300,
  marginTop: '5px',
  marginBottom: '10px',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  hyphens: 'auto',
  backgroundColor: theme.palette.grey[50],
  width: '100%',
  borderRadius: 24,
  padding: 18,
});

const CommentTitleStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '5px',
  paddingLeft: '18px',
  alignItems: 'center',
});

const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  alignItems: 'center',
});

const StyledIconButton = styled(IconButton)({
  cursor: 'pointer',
});
