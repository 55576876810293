import { formatCommentsBeforeSave } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/Functions/formatCommentsBeforeSave.ts';
import { EFlowStepTypes } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';
import { omit, pick } from 'lodash';

import {
  AddEflowCommentInput,
  EflowStepFragmentFragment,
  OperationInput,
  UpdateEflowStepInput,
} from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

export const useFormatEflowStepForUpdate = (organizationId: string) => {
  const { userUuid, organizationId: userOrganizationId } = useUserStore();

  function _handleUpdateComments(eFlowStep: EFlowStepTypes) {
    const newComments: AddEflowCommentInput[] = [];

    if (eFlowStep.comments) {
      newComments.push(
        ...formatCommentsBeforeSave(
          organizationId,
          {
            id: userUuid,
            organizationId: userOrganizationId!,
          },
          eFlowStep.comments,
        ),
      );
    }

    if ('commentModels' in eFlowStep) {
      return {
        newComments,
        commentModels: eFlowStep.commentModels?.map((commentModel) =>
          pick(commentModel, ['commentId', 'showInReport']),
        ),
      };
    }

    return { newComments };
  }

  function handlePrepareEflowStepForUpdate(
    eFlowStep: EFlowStepTypes,
  ): UpdateEflowStepInput {
    return {
      id: (eFlowStep as EflowStepFragmentFragment).id,
      organizationId: organizationId,
      eFlowId: eFlowStep.eFlowId,
      operation: omit(eFlowStep.operation, ['__typename']) as OperationInput,
      operationName: eFlowStep.operationName,
      parameters: eFlowStep.parameters,
      ..._handleUpdateComments(eFlowStep),
    };
  }

  return {
    handlePrepareEflowStepForUpdate,
  };
};
