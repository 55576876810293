import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UnitsEnum } from '@kpi/common';
import { isArray } from 'lodash';

import { useCompanyId } from '@core/hooks';

import {
  KpiUnitModelDto,
  UnitKpiRateInput,
  useFindKpiUnitsLazyQuery,
} from '@/__generated__/graphql.ts';

import { SelectedUnitType } from './UnitSelect.types';
import { mergeUnits } from './utils';

export const useFetchOrganizationUnits = (periodId?: string) => {
  const { t } = useTranslation('kpi');

  const { companyId } = useCompanyId();

  const [loadUnits, { data }] = useFindKpiUnitsLazyQuery();

  const [units, setUnits] = useState<KpiUnitModelDto[]>([]);

  const findUnits = async () => {
    if (!periodId || !companyId) return;
    await loadUnits({
      variables: {
        input: { organizationId: companyId, settlementPeriodIds: [periodId] },
      },
    });
  };

  const isCustomUnit = (unitName: string): boolean => {
    return !Object.values(UnitsEnum).includes(unitName as UnitsEnum);
  };

  const getUnitLabel = (option: UnitKpiRateInput): string => {
    const isCustom = isCustomUnit(option.unitName);
    return isCustom
      ? `${option.unitName} (${option.unitShortcut})`
      : `${t(`units.${option.unitName as UnitsEnum}`)} (${option.unitShortcut})`;
  };

  useEffect(() => {
    if (!data?.findKpiUnits.units) return;
    setUnits(data.findKpiUnits.units);
  }, [data]);

  useEffect(() => {
    void findUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodId, companyId]);

  const onChangeSelect = (
    unit: SelectedUnitType | null,
    onChange: (unit: SelectedUnitType | SelectedUnitType[]) => void,
  ) => {
    const isUnitArray = isArray(unit);

    if (!unit) {
      if (isUnitArray) {
        return onChange([]);
      } else {
        return onChange({ unitName: '', unitShortcut: '' } as UnitKpiRateInput);
      }
    }

    return onChange(unit);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataValue = useMemo(() => mergeUnits(units, t), [units]);

  return {
    data: dataValue,
    onChangeSelect,
    getUnitLabel,
  };
};
