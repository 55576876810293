import { useTranslation } from 'react-i18next';

import { useKaizenReportContext } from '@kaizen/pages/reports/context/kaizenReport.context.tsx';
import { useCalculateReport } from '@kaizen/pages/reports/hooks';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BarChart } from '@core/components';
import { ReportPieChart } from '@core/components/Statistica/ReportPieChart/ReportPieChart.tsx';
import { useIsMobile } from '@core/hooks';

export const ChartsGroup = () => {
  const { t } = useTranslation('kaizen');

  const { isLoading, report } = useKaizenReportContext();

  const { formatPieChartData, formatBarChartData } = useCalculateReport(report);

  const isMobile = useIsMobile();

  return (
    <Container>
      <ReportPieChart
        values={formatPieChartData}
        isLoading={isLoading}
        title={t('report.submissionCount')}
        emptyMessage={t('report.noData')}
        width={isMobile ? '100%' : '50%'}
      />
      <BarChart
        isLoading={isLoading}
        series={formatBarChartData}
        title={t('report.duration')}
        noDataMessage={t('report.noData')}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0,
          },
        }}
        grid={{ horizontal: true }}
        bottomAxis={null}
        xAxis={[{ scaleType: 'band', data: [t('report.submissionStatus')] }]}
      />
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  gap: 15,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '@media (min-width: 768px)': {
    flexDirection: 'row',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '@media (max-width: 767px)': {
    flexDirection: 'column',
  },
});
