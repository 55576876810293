import { createContext } from 'react';

import { omit } from 'lodash';

import { PermissionsModuleTypes } from '@core/modules/PermissionModule/PermissionsModule.types.ts';
import { PermissionsModuleContextTypes } from '@core/modules/PermissionModule/context/PermissionModuleContext.types.ts';

export const PermissionsModuleContext = createContext<
  PermissionsModuleTypes | undefined
>(undefined);

export const PermissionsModuleProvider = (
  props: PermissionsModuleContextTypes,
) => {
  const value: PermissionsModuleTypes = {
    ...omit(props, ['children']),
  };

  return (
    <PermissionsModuleContext.Provider value={value}>
      {props.children}
    </PermissionsModuleContext.Provider>
  );
};
