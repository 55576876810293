import { Dispatch, SetStateAction } from 'react';
import { SystemRoles } from '@/__generated__/graphql.ts';

export type IUser = {
  /**
   * User first name
   * @example
   * John
   */
  name: string;

  /**
   * User last name
   * @example
   * Doe
   */
  lastName: string;

  /**
   * User email
   * @example
   * mail@xd.com
   */
  email: string;

  /**
   * User groups
   */
  userGroups: SystemRoles[];

  /**
   * User phone number
   * @example
   * +123456789
   */
  phone: string;

  /**
   * User position
   * @example
   * boss
   */
  position?: string;

  /**
   * User organization url
   * @example
   * luqam
   */
  organizationUrl: string;
  /**
   * user uuid
   *
   */
  userUuid: string;

  /* * User organization id
   * @example
   * 79b553bc-b5f4-4b2d-9493-113125b02078
   */
  organizationId?: string;

  /**
   * User profile picture
   * @example
   * https://example.com/image.png
   */
  profilePicture?: string | null;
};

export interface IPasswords {
  oldPassword: string;
  password: string;
  repeatPassword: string;
}

export interface ISectionProps {
  edit: UserEditTypes;
  setEdit: Dispatch<SetStateAction<UserEditTypes>>;
  isLoading?: boolean;
}

export enum UserEditTypes {
  Employee = 'employee',
  Password = 'password',
  Avatar = 'avatar',
  Unit = 'unit',
  Permissions = 'permissions',
  None = 'none',
}
