import { ReactNode, createContext, useContext } from 'react';

import { useReportSingleKpiModal } from '@kpi/hooks/useReportSingleKpiModalContext.tsx';
import { ReportSingleKpiModal } from '@kpi/modals/ReportSingleKpi';

type ReportModalContextProps = ReturnType<typeof useReportSingleKpiModal>;

const ReportModalContext = createContext<ReportModalContextProps | undefined>(
  undefined,
);

export const ReportSingleKpiModalProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const reportKpiModal = useReportSingleKpiModal();

  return (
    <ReportModalContext.Provider value={reportKpiModal}>
      {children}

      <ReportSingleKpiModal
        id={reportKpiModal.kpiId}
        kpi={reportKpiModal.kpi}
        defaultDate={reportKpiModal.reportDefaultDate}
        display={reportKpiModal.isReportModalVisible}
        close={reportKpiModal.closeReportModal}
        isLoading={reportKpiModal.isLoading}
      />
    </ReportModalContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useReportSingleKpiModalContext = () => {
  const context = useContext(ReportModalContext);
  if (!context) {
    throw new Error(
      'useReportModalContext must be used within a ReportSingleKpiModalContext',
    );
  }
  return context;
};
