/* eslint-disable @typescript-eslint/no-misused-promises */
import { captureException } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee.ts';

import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography, styled } from '@mui/material';

import { useFlowRedirects } from '@core/redirects';

import { OperationSchema } from '@/__generated__/graphql';
import { Button, CustomSnackBar } from '@/core/components';
import { useIsMobile } from '@/core/hooks';
import { theme } from '@/theme';

import { AddNewOperationModal } from './AddNewOperationModal';
import { OperationRow } from './OperationRow';
import { OperationsModuleProps } from './OperationsModule.types';
import { useBasicOperations } from './useBasicOperations';

export const OperationsModule = ({
  isUpdate = false,
  ...props
}: OperationsModuleProps) => {
  const { t } = useTranslation('eflow');
  const { t: tCommon } = useTranslation();

  const { state } = useLocation();
  const { flowId } = useParams();

  const [operationsArray, setOperationsArray] = useState<OperationSchema[]>(
    (props.currentFormValues.operations as OperationSchema[]) || [],
  );
  const [isAddNewOperationVisible, setIsAddNewOperationVisible] =
    useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { redirectToNewFlow, redirectToFlowDashboard } = useFlowRedirects();

  const basicData = useBasicOperations();

  const isMobile = useIsMobile();

  useEffect(() => {
    props.setMainAnalysisPart({
      operations: [...operationsArray],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationsArray]);

  const createEflow = async () => {
    try {
      await props.createEFlow();
      if (state?.from === 'operations' && flowId) redirectToNewFlow(flowId);
      else redirectToFlowDashboard();
    } catch (ex) {
      captureException(ex);
    }
  };

  const createAndStartEflow = async () => {
    try {
      const eFlowId = await props.createEFlow();
      redirectToNewFlow(eFlowId!);
    } catch (ex) {
      captureException(ex);
    }
  };

  return (
    <>
      <MainStack>
        <TitleRow>
          <Typography variant="h3">
            {t('newAnalysis.operations.title')}
          </Typography>
          <PrimaryButton
            sx={{
              color: theme.palette.common.black,
            }}
            onClick={() => setIsAddNewOperationVisible(true)}
          >
            <AddIcon fontSize="small" sx={{ marginRight: '5px' }} />
            <Typography variant="bodyMedium" sx={{ fontSize: '14px' }}>
              {t('newAnalysis.operations.newOperationType')}
            </Typography>
          </PrimaryButton>
        </TitleRow>
        <Stack>
          {basicData.map((operation, i) => (
            <React.Fragment key={operation.name}>
              <OperationRow operation={operation} />
              {(i < basicData.length - 1 || operationsArray.length > 0) && (
                <Separator />
              )}
            </React.Fragment>
          ))}
          {operationsArray.map((operation, i) => (
            <React.Fragment key={operation.name}>
              <OperationRow
                operation={operation}
                handleDelete={() =>
                  setOperationsArray([
                    ...operationsArray.filter((_operation, idx) => i !== idx),
                  ])
                }
              />
              {i < operationsArray.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        </Stack>
        <ButtonsStack flexDirection={isMobile ? 'column' : 'row'}>
          <PrimaryButton
            sx={{
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.common.black,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
              },
            }}
            onClick={props.stepBack}
          >
            <Typography variant="bodyRegular">
              {tCommon('common.back')}
            </Typography>
          </PrimaryButton>
          {/*create eflow*/}
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary[600],
            }}
            onClick={async () => {
              await createEflow();
            }}
          >
            <Typography variant="bodyRegular">
              {isUpdate
                ? tCommon('common.save')
                : t('newAnalysis.operations.createAnalysis')}
            </Typography>
          </Button>
          {!isUpdate && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
              onClick={async () => {
                await createAndStartEflow();
              }}
            >
              <Typography variant="bodyRegular">
                {t('newAnalysis.operations.createAndStartAnalysis')}
              </Typography>
            </Button>
          )}
        </ButtonsStack>
      </MainStack>
      {isAddNewOperationVisible && (
        <AddNewOperationModal
          addOperation={(operation) => {
            setOperationsArray((prev) => [...prev, operation]);
          }}
          operations={operationsArray}
          close={() => setIsAddNewOperationVisible(false)}
          setSnackBar={(message) => setSnackbarMessage(message)}
        />
      )}
      <CustomSnackBar
        open={!!snackbarMessage}
        close={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

const TitleRow = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '16px',
  width: '100%',
});

const Separator = styled('div')({
  width: '100%',
  height: '1px',
  backgroundColor: theme.palette.grey[200],
});

const MainStack = styled(Stack)({
  maxWidth: '728px',
  gap: '24px',
  padding: '24px 0 24px 0',
});

const ButtonsStack = styled(Stack)({
  justifyContent: 'start',
  gap: '12px',
});
