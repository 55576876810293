import { CustomModal } from '@/core/components';
import { ErrorContent } from '@/core/components/ErrorContent';
import { useErrorStore } from '@/stores';

export const ErrorModal = () => {
  const { errorCode, errorUuid } = useErrorStore((state) => {
    return {
      errorCode: state.errorCode,
      errorUuid: state.errorUuid,
      setError: state.setError,
    };
  });

  return (
    <CustomModal display={true}>
      <ErrorContent shadow={true} errorCode={errorCode} errorUuid={errorUuid} />
    </CustomModal>
  );
};
