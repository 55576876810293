import { useTranslation } from 'react-i18next';
import {
  CountryIso2,
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import 'react-international-phone/style.css';

import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { IPhoneTextFieldProps } from '@/core/components';

export const PhoneTextField = ({
  error,
  helperText,
  field,
  defaultCountry = 'pl',
}: IPhoneTextFieldProps) => {
  const { t } = useTranslation();
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry,
      value: field?.value,
      countries: defaultCountries,
      onChange: (data) => {
        field?.onChange(data.phone);
      },
    });

  return (
    <TextField
      variant="outlined"
      label={t('systemSettings.phoneNumber')}
      color="primary"
      placeholder={t('systemSettings.phoneNumber')}
      value={inputValue}
      onChange={handlePhoneValueChange}
      helperText={helperText}
      type="tel"
      inputRef={inputRef}
      error={error}
      sx={{
        // eslint-disable-next-line
        '.MuiInputBase-input': {
          paddingTop: '8.5px',
          paddingBottom: '8.5px',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{
              marginRight: '2px',
              marginLeft: '-8px',
            }}
          >
            <Select
              MenuProps={{
                style: {
                  height: '300px',
                  width: '360px',
                  top: '10px',
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',
                fieldset: {
                  display: 'none',
                },
                // eslint-disable-next-line
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                // eslint-disable-next-line
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                // eslint-disable-next-line
                '.MuiOutlinedInput-notchedOutline': {
                  border: '0px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(value) => (
                <FlagImage iso2={value} style={{ display: 'flex' }} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{
                        marginRight: '8px',
                      }}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
};
