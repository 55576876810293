import { useReducer } from 'react';

import { Employee, FileModel } from '@/__generated__/graphql.ts';

import {
  SubmissionBuilderInitialState,
  SubmissionBuilderInitialStateType,
} from './SubmissionBuilderInitialState.tsx';
import {
  Action,
  SubmissionBuilderReducerTypes,
} from './SubmissionBuilderReducer.types.ts';

const reducer = (
  state: SubmissionBuilderInitialStateType,
  action: Action,
): SubmissionBuilderInitialStateType => {
  switch (action.type) {
    case 'SET_SUBMISSION_NAME':
      return { ...state, submissionName: action.payload };

    case 'SET_SUBMISSION_PROBLEM':
      return { ...state, problem: action.payload };

    case 'SET_SUBMISSION_SOLUTION':
      return { ...state, solution: action.payload };

    case 'SET_SUBMISSION_AUTHORS':
      return { ...state, authors: action.payload };

    default:
      return state;
  }
};

export const useSubmissionBuilderReducer =
  (): SubmissionBuilderReducerTypes => {
    const [state, dispatch] = useReducer(
      reducer,
      SubmissionBuilderInitialState,
    );

    const setSubmissionName = (submissionName: string) => {
      dispatch({ type: 'SET_SUBMISSION_NAME', payload: submissionName });
    };

    const setProblem = (
      problemDescription: string,
      problemFiles: Array<File | FileModel>,
    ) => {
      dispatch({
        type: 'SET_SUBMISSION_PROBLEM',
        payload: {
          description: problemDescription,
          files: problemFiles,
        },
      });
    };

    const setSolution = (
      solutionDescription: string,
      solutionFiles: Array<File | FileModel>,
    ) => {
      dispatch({
        type: 'SET_SUBMISSION_SOLUTION',
        payload: {
          description: solutionDescription,
          files: solutionFiles,
        },
      });
    };

    const setAuthors = (authors: Employee[]) => {
      dispatch({
        type: 'SET_SUBMISSION_AUTHORS',
        payload: authors,
      });
    };

    return {
      submissionName: state.submissionName,
      problem: state.problem,
      solution: state.solution,
      authors: state.authors,
      setProblem,
      setSolution,
      setSubmissionName,
      setAuthors,
    };
  };
