import { useEffect } from 'react';

import { useDashboardWidgetContext } from '@core/modules/DashbordModule/contexts/DashboardWidget.context.tsx';

import {
  DashboardWidgetDto,
  useFindChartQuery,
} from '@/__generated__/graphql.ts';

export const useKpiChartWidget = (props: DashboardWidgetDto) => {
  const { setWidgetTitle, setWidgetContent } = useDashboardWidgetContext();

  const { loading: isLoading, data } = useFindChartQuery({
    variables: {
      input: {
        id: props.resourceLink.id as string,
        organizationId: props.resourceLink.organizationId,
      },
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setWidgetTitle(data.findChart.chartName);
    setWidgetContent(data.findChart);
  }, [data, setWidgetContent, setWidgetTitle]);

  return {
    isLoading,
    chart: data?.findChart,
  };
};
