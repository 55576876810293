import { useTranslation } from 'react-i18next';

import { Stack, Typography, styled } from '@mui/material';

export const WrongSearchInfo = () => {
  const { t } = useTranslation('eflow');
  return (
    <Container>
      <Typography variant="h4">
        {t('newAnalysis.parameters.EmptySearchTitle')}
      </Typography>
      <Typography variant="bodyRegular">
        {t('newAnalysis.parameters.EmptySearchDescription')}
      </Typography>
    </Container>
  );
};
const Container = styled(Stack)({
  padding: '64px 0px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
