import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportSectionesTypes } from '@e-flow/pages/flowReport/components/Section/ReportSection.types.ts';

import { Box, Typography, styled } from '@mui/material';

export const ReportSections = forwardRef<HTMLDivElement, ReportSectionesTypes>(
  (
    { isEmpty = false, flexDirection = 'row', ...props }: ReportSectionesTypes,
    ref,
  ) => {
    const { t } = useTranslation('eflow');

    if (isEmpty) {
      return (
        <SectionContainer ref={ref}>
          {props.title && <Typography variant="h4">{props.title}</Typography>}
          {t('reports.emptySection')}
        </SectionContainer>
      );
    }

    return (
      <SectionContainer ref={ref}>
        {props.title && <Typography variant="h4">{props.title}</Typography>}
        <ItemsContainer
          sx={{
            flexDirection: flexDirection,
          }}
        >
          {props.children}
        </ItemsContainer>
      </SectionContainer>
    );
  },
);

const SectionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  borderRadius: '0.5rem',
  backgroundColor: '#fff',
  marginBottom: '1rem',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
  margin: 10,
  padding: '1rem',
});

const ItemsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '1rem',
});
