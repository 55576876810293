import { useEffect, useState } from 'react';

import { maxKpisSelectedForChart } from '@kpi/constant';
import { checkKpiDistinctUnit } from '@kpi/utils';

import { KpiRateDto, UnitKpiRateInput } from '@/__generated__/graphql';

export const useSelectKpis = () => {
  const [tempSelectedKpis, setTempSelectedKpis] = useState<KpiRateDto[]>([]);
  const [tempSelectedKpisIds, setTempSelectedKpisIds] = useState<string[]>([]);
  const [selectedKpis, setSelectedKpis] = useState<KpiRateDto[]>([]);

  const [unit, setUnit] = useState<UnitKpiRateInput | null>(null);

  const [areSelectedKpisValid, setAreSelectedKpisValid] = useState(true);

  useEffect(() => {
    setAreSelectedKpisValid(checkKpiDistinctUnit(selectedKpis));
  }, [selectedKpis]);

  useEffect(() => {
    if (tempSelectedKpis.length === 0) setUnit(null);
    setTempSelectedKpisIds(tempSelectedKpis.map((kpi) => kpi.id));
  }, [tempSelectedKpis]);

  const handleOpenSelectKpiView = () => {
    setTempSelectedKpis([...selectedKpis]);
  };

  useEffect(() => {
    if (unit) return;
    if (selectedKpis[0]) setUnit(selectedKpis[0].unit);
  }, [selectedKpis, unit]);

  const handleSelectKpi = (kpi: KpiRateDto) => {
    if (tempSelectedKpis.length >= maxKpisSelectedForChart) return;
    if (tempSelectedKpis.length === 0) setUnit(kpi.unit);

    if (determineIfSelected(kpi)) handleDeselectKpi(kpi);
    else setTempSelectedKpis((prev) => [...prev, kpi]);
  };

  const handleDeselectKpi = (kpi: KpiRateDto) => {
    setTempSelectedKpis((prev) =>
      prev.filter((selectedKpi) => selectedKpi.id !== kpi.id),
    );
  };

  const determineIfSelected = (kpi: KpiRateDto) =>
    tempSelectedKpis.some((selectedKpi) => selectedKpi.id === kpi.id);

  const determineIfDisabled = (kpi: KpiRateDto) => {
    if (tempSelectedKpis.length === 0) return false;
    if (tempSelectedKpis.length >= maxKpisSelectedForChart) return true;
    if (determineIfSelected(kpi)) return false;
    if (unit === null) return true;
    return (
      unit.unitName !== kpi.unit.unitName &&
      unit.unitShortcut !== kpi.unit.unitShortcut
    );
  };

  const handleAbordSelection = () => {
    setTempSelectedKpis([...selectedKpis]);
  };

  const handleConfirmSelection = () => {
    setSelectedKpis([...tempSelectedKpis]);
  };

  const handleSelectAllKpis = (currentDisplayedKpis: KpiRateDto[]) => {
    const tempUnit = unit || currentDisplayedKpis[0].unit;
    const newSelectedKpis = currentDisplayedKpis.filter(
      (kpi) => kpi.unit.unitName === tempUnit.unitName,
    );
    if (newSelectedKpis.find((kpi: KpiRateDto) => !determineIfSelected(kpi))) {
      setUnit(tempUnit);
      setTempSelectedKpis(newSelectedKpis.slice(0, maxKpisSelectedForChart));
    } else {
      setTempSelectedKpis((prev) =>
        prev.filter((kpi) => !newSelectedKpis.includes(kpi)),
      );
    }
  };

  const reset = () => {
    setTempSelectedKpis([]);
    setTempSelectedKpisIds([]);
    setSelectedKpis([]);
    setUnit(null);
  };

  return {
    // Data
    areSelectedKpisValid,
    areKpisSelected: selectedKpis.length > 0,
    tempSelectedKpis,
    tempSelectedKpisIds,
    selectedKpis,
    unit,
    // Setters
    setTempSelectedKpis,
    setSelectedKpis,
    setUnit,
    // Methods
    handleSelectKpi,
    determineIfSelected,
    determineIfDisabled,
    handleConfirmSelection,
    handleSelectAllKpis,
    handleAbordSelection,
    setTempSelectedKpisIds,
    handleOpenSelectKpiView,
    // Reset
    reset,
  };
};
