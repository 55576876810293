/**
 * Transforms a date string from the format "YYYY-MM-DDTHH:MM:SS.SSSZ" to "DD/MM/YYYY".
 *
 * @param {string} dateString - The date string in the format "YYYY-MM-DDTHH:MM:SS.SSSZ".
 * @returns {string} The formatted date string in the format "DD/MM/YYYY".
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function transformDateStringToDDMMYYYY(dateString: string): string {
  const date = new Date(dateString);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}
