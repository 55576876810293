import { Tooltip, Typography } from '@mui/material';

import { shortenTitle } from '@/core/utils';

import { ShortenTypographyProps } from './ShortenTypography.type';

export const ShortenTypography = (props: ShortenTypographyProps) => {
  if (props.children.length <= props.maxLength) {
    return <Typography {...props}>{props.children}</Typography>;
  }

  return (
    <Tooltip title={props.children}>
      <Typography {...props}>
        {shortenTitle(props.children, props.maxLength)}
      </Typography>
    </Tooltip>
  );
};
