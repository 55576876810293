import { deepClone } from '@mui/x-data-grid/internals';

import { GetAllSettlementPeriodsDocument } from '@/__generated__/graphql.ts';
import { client } from '@/apollo.ts';

/**
 * Function to read query
 * @param query DocumentNode
 * @param eFlowId string
 * @param organizationId  string
 */
export const readQuery = (organizationId: string) => {
  const results = client.readQuery({
    query: GetAllSettlementPeriodsDocument,
    variables: {
      input: {
        organizationId,
        withArchive: false,
      },
    },
  });

  if (results === null) {
    return {
      findAllSettlementPeriods: {
        settlementPeriods: [],
        organizationId,
        __typename: 'FindAllSettlementPeriodsDto',
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return
  return deepClone(results);
};
