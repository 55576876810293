import { captureEvent } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { useCompanyId, useSnackbar } from '@core/hooks';

import {
  CreateEflowInput,
  useCreateEFlowMutation,
} from '@/__generated__/graphql.ts';

export const useCreateEflow = () => {
  const [createEflow] = useCreateEFlowMutation();

  const { companyId } = useCompanyId();

  const { t } = useTranslation('eflow');

  const { isSnackBarOpen, snackBarMessage, openSnackbar, closeSnackbar } =
    useSnackbar();

  const handleCreateEflow = async (
    newAnalysis: CreateEflowInput,
  ): Promise<string | null> => {
    try {
      const { data } = await createEflow({
        variables: {
          input: {
            ...newAnalysis,
            organizationId: companyId,
            commentTypes: [...(newAnalysis.commentTypes || [])],
            operations: [...(newAnalysis.operations || [])],
            analyzeLeadersIds: newAnalysis?.analyzeLeadersIds?.map((user) => ({
              id: user.id,
              organizationId: user.organizationId,
            })),
          },
        },
      });

      return (data?.createEFlow?.id as string) || null;
    } catch (error) {
      if (error instanceof Error) handleError(error);
      return null;
    }
  };

  function handleError(error: Error) {
    switch (error.message) {
      case 'DUPLICATE_IDS':
        openSnackbar(t('newAnalysis.errors.duplicatesId'));
        break;
      case 'EMPLOYEE_NOT_FOUND':
        openSnackbar(t('newAnalysis.errors.employeeNotExists'));
        break;
      case 'ORGANIZATION_DOES_NOT_HAVE_MODULE':
        openSnackbar(t('newAnalysis.errors.organizationDoesNotHaveModule'));
        break;
      default:
        if (error.message) {
          openSnackbar(t('newAnalysis.errors.unknownError'));
        }
        break;
    }

    captureEvent(error);
  }

  return { isSnackBarOpen, snackBarMessage, closeSnackbar, handleCreateEflow };
};
