import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  TextField,
  styled,
} from '@mui/material';

import { SingleSubunitDto } from '@/__generated__/graphql';

import { OrganizationUnitSelectProps } from './OraganizationUnitSelect.types';
import { useOrganizationUnitSelect } from './useOrganzizationUnitSelect';

export const OrganizationUnitSelect = ({
  organizationUnitId,
  onChange,
  error,
  helperText,
  multiple = false,
  tags = 1,
  defaultValue,
  disabled,
  getOnlySubunits = false,
}: OrganizationUnitSelectProps) => {
  const { t } = useTranslation('kpi');

  const {
    isLoading: isLoadingOrgUnits,
    unitData,
    loadData,
    onSelectChange,
    selectValue,
  } = useOrganizationUnitSelect(multiple, {
    onChange,
    organizationUnitId,
    defaultValue,
    withSubUnits: getOnlySubunits,
  });

  return (
    <CustomAutocomplete
      value={selectValue}
      disabled={disabled}
      limitTags={tags}
      size="small"
      multiple={multiple}
      loadingText={<CircularProgress />}
      options={Array.isArray(unitData) ? unitData : []}
      loading={isLoadingOrgUnits}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onOpen={loadData}
      getOptionLabel={(option: unknown) => {
        const optionObject = option as SingleSubunitDto;
        return optionObject && optionObject.name ? optionObject.name : '';
      }}
      onChange={(_e: SyntheticEvent, value: unknown) =>
        onSelectChange(value as SingleSubunitDto | SingleSubunitDto[])
      }
      isOptionEqualToValue={(option, value) =>
        (option as SingleSubunitDto).id === (value as SingleSubunitDto).id
      }
      {...(multiple && {
        renderOption: (props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox checked={selected} />
              {(option as SingleSubunitDto).name}
            </li>
          );
        },
      })}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={t('addKpi.cell')}
            error={error}
            helperText={helperText}
          />
        );
      }}
    />
  );
};

const CustomAutocomplete = styled(Autocomplete)({
  position: 'relative',
  width: '232px',
  height: '40px',
  div: {
    div: {
      maxHeight: '40px',
      overflow: 'clip',
    },
  },
});
