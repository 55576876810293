import { KpiRateDto } from '@/__generated__/graphql.ts';

/**
 * Check if all KPIs have the same unit
 * @param kpis
 */
export const checkKpiDistinctUnit = (kpis: KpiRateDto[]): boolean => {
  if (kpis.length === 0) return true;

  const { unitName, unitShortcut } = kpis[0].unit;

  return kpis.every(
    (kpi) =>
      kpi.unit.unitName === unitName && kpi.unit.unitShortcut === unitShortcut,
  );
};
