import { useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { NodeId } from '@/types';
import { TreeDetailsInterface } from './OrganizationTree.types';

export const useOrganizationTree = () => {
  const { t } = useTranslation();

  const [selectedNodes, setSelectedNodes] = useState<NodeId[]>([]);
  const [isVisible, setIsVisible] = useState(true);
  const [zoom, setZoom] = useState(1);
  const [tabValue, setTabValue] = useState('cells');

  const [details, updateDetails] = useReducer(
    (
      prev: TreeDetailsInterface,
      next: Partial<TreeDetailsInterface>,
    ): TreeDetailsInterface => {
      return { ...prev, ...next };
    },
    {
      isNestedLevel: true,
      isColorMark: true,
      isUnitAdmin: true,
      isAvatar: true,
      isPosition: true,
      isMembers: false,
    },
  );

  useEffect(() => {
    if (!details.isUnitAdmin)
      updateDetails({ isAvatar: false, isPosition: false });
  }, [details.isUnitAdmin]);

  const handleChangeVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  const handleTransform = (e: ReactZoomPanPinchRef) => {
    setZoom(e.state.scale);
  };

  return {
    t,
    selectedNodes,
    setSelectedNodes,
    isVisible,
    zoom,
    tabValue,
    setTabValue,
    handleChangeVisibility,
    handleTransform,
    details,
    updateDetails,
    setZoom,
  };
};
