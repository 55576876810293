import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlainButton } from '..';

interface CoolDownButtonProps {
  /**
   * Cool down length in milliseconds
   * @example 5000
   */
  coolDownLength: number;
  /**
   * Function to be called when button is clicked
   */
  onClick: () => void | Promise<void>;
  /**
   * Label of the button
   * @example 'Send code'
   */
  label: string;
}

export const CoolDownButton: React.FC<CoolDownButtonProps> = ({
  coolDownLength: coolDownLength,
  onClick,
  label,
}) => {
  const { t } = useTranslation();
  const [countDownInterval, setCountDownInterval] = useState<NodeJS.Timeout>();
  const coolingRef = React.useRef(false);
  const [isCoolDown, setIsCoolDown] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(coolDownLength / 1000);
  /*
   * Manages a cool down interval
   * @param alreadyCountingDown - if true, will start the countdown from the time remaining in local storage
   */
  const startCoolDown = (startedFromLocalStorage: boolean) => {
    if (!startedFromLocalStorage) {
      window.localStorage.setItem(
        'coolDownEndDate',
        new Date(Date.now() + coolDownLength).toISOString(),
      );
    }
    if (isCoolDown) return;
    const interval = setInterval(() => {
      setTimeRemaining((timeRemaining) => {
        //countdown ends
        if (timeRemaining <= 1) {
          coolingRef.current = false;
          setIsCoolDown(false);
          window.localStorage.removeItem('coolDownEndDate');
          clearInterval(interval);
          setCountDownInterval(interval);
          return coolDownLength / 1000;
        }
        setCountDownInterval(interval);
        setIsCoolDown(true);
        coolingRef.current = true;
        return timeRemaining - 1;
      });
    }, 1000);
  };

  const handleClick = async () => {
    if (!isCoolDown) {
      await onClick();
      startCoolDown(false);
    }
  };

  useEffect(() => {
    //prevent 2 times loading
    if (!coolingRef.current) {
      coolingRef.current = true;
      return;
    }

    const coolDownEndDate = window.localStorage.getItem('coolDownEndDate');

    if (coolDownEndDate) {
      const remainingTime = new Date(coolDownEndDate).getTime() - Date.now();
      if (remainingTime > 0) {
        setTimeRemaining(Math.floor(remainingTime / 1000));
        startCoolDown(true);
      }
    }
    return () => {
      if (isCoolDown) {
        clearInterval(countDownInterval);
        coolingRef.current = false;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <PlainButton onClick={handleClick} disabled={isCoolDown}>
      {isCoolDown
        ? t('forgotPassword.codeSent', {
            remainingTime: timeRemaining,
          })
        : label}
    </PlainButton>
  );
};

export default CoolDownButton;
