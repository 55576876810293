import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { UnitKpiRateInput } from '@/__generated__/graphql';

import { UnitSelectProps } from './UnitSelect.types';
import { useFetchOrganizationUnits } from './useFetchOrganizationUnits';

export const UnitSelect = ({
  disabled = false,
  onChange,
  error,
  helperText,
  sx,
  multiselect = false,
  periodId,
  defaultValue,
}: UnitSelectProps) => {
  const { t } = useTranslation('kpi');

  const { data, onChangeSelect, getUnitLabel } =
    useFetchOrganizationUnits(periodId);

  return (
    <Autocomplete
      size="small"
      multiple={multiselect}
      sx={sx}
      options={data}
      defaultValue={defaultValue}
      getOptionLabel={getUnitLabel}
      onChange={(_e, unit) =>
        onChangeSelect(
          unit as UnitKpiRateInput,
          onChange as (unit: UnitKpiRateInput | UnitKpiRateInput[]) => void,
        )
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('addKpi.unit')}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};
