import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { Box, styled } from '@mui/material';

import { theme } from '@/theme';

export const ResizableDivider = (
  props: Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLHRElement>, HTMLHRElement>,
    'ref'
  >,
) => {
  return <Divider tabIndex={0} {...props} />;
};

const Divider = styled(Box)({
  height: '100%',
  width: '2px',
  backgroundColor: theme.palette.grey[300],
  cursor: 'ew-resize',
});
