import { useMemo, useState } from 'react';

import { NotificationType } from '@core/modules/Notifications/types/Notifications.types.ts';

export const useManageNotificationsState = () => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const markNotificationAsRead = (id: string) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id
          ? { ...notification, isOpened: true }
          : notification,
      ),
    );
  };

  const unOpenedNotifications = useMemo(() => {
    return notifications.filter((notification) => !notification.isOpened);
  }, [notifications]);

  return {
    notifications,
    shouldDisplayDot: unOpenedNotifications.length > 0,
    unOpenedNotifications,
    setNotifications,
    markNotificationAsRead,
  };
};
