import { NotificationsOutlined } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';

import { theme } from '@/theme';

import { NotificationsButtonProps } from './NofiticationsButton.types';

export const NotificationsButton = (props: NotificationsButtonProps) => {
  return (
    <IconButton onClick={(e) => props.handleMenuOpen(e)}>
      <Badge
        variant="dot"
        invisible={false}
        color={props.shouldDisplayDot ? 'info' : 'default'}
      >
        <NotificationsOutlined htmlColor={theme.palette.grey[900]} />
      </Badge>
    </IconButton>
  );
};
