import { ReactNode, createContext, useContext } from 'react';

import { useDashboardWidget } from '@core/modules/DashbordModule/hooks';

const DashboardWidgetContext = createContext<
  ReturnType<typeof useDashboardWidget> | undefined
>(undefined);

export const DashboardWidgetProvider = ({
  children,
  contextHook,
}: {
  children: ReactNode;
  contextHook: ReturnType<typeof useDashboardWidget> | undefined;
}) => {
  return (
    <DashboardWidgetContext.Provider value={contextHook}>
      {children}
    </DashboardWidgetContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useDashboardWidgetContext = () => {
  const context = useContext(DashboardWidgetContext);
  if (!context) {
    throw new Error(
      'useDashboardWidgetContext must be used within a DashboardWidgetProvider',
    );
  }
  return context;
};
