import { ForwardedRef, LegacyRef, ReactElement, forwardRef } from 'react';

import { omit } from 'lodash';

import { MenuItem } from '@mui/material';

import { Select } from '@core/components';
import { OptionsSelectTypes } from '@core/components/Selects/OptionsSelect/OptionsSelect.types';

const OptionsSelectComponent = <T,>(
  props: OptionsSelectTypes<T>,
  ref: ForwardedRef<HTMLSelectElement>,
): ReactElement => {
  return (
    <Select
      sx={{ margin: 0, minWidth: 138 }}
      {...omit(props, ['labelKey', 'valueKey'])}
      ref={ref as LegacyRef<HTMLSelectElement>}
      selectProps={{
        ...(props.defaultValue ? { defaultValue: props.defaultValue } : {}),
      }}
      value={props.value || ''}
    >
      {props.items?.map((option, index) => (
        <MenuItem key={index} value={option[props.valueKey] as string}>
          {option[props.labelKey] as string}
        </MenuItem>
      ))}
    </Select>
  );
};

export const OptionsSelect = forwardRef(OptionsSelectComponent) as unknown as <
  T,
>(
  props: OptionsSelectTypes<T> & {
    ref?: LegacyRef<ForwardedRef<HTMLSelectElement>>;
  },
) => ReactElement;
