import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteUserModal } from '@e-schema/components';
import { AddUserModal } from '@e-schema/components/AddUserModal';

import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';

import {
  GetEmployeesQuery,
  SystemRoles,
  useGetEmployeesLazyQuery,
} from '@/__generated__/graphql';
import {
  CustomSnackBar,
  TablePaginationWrapper,
  TitleContainer,
} from '@/core/components';
import { useTablePagination } from '@/core/hooks';
import { FlexCenter } from '@/core/styles';
import { useUserStore } from '@/stores';

import { SystemUsersTableHeader, SystemUsersTableRow } from './SystemUserTable';

export const SystemUsers = () => {
  const { t } = useTranslation();
  const { organizationId } = useUserStore();
  const { cursor, setCursor, rowsPerPage, setRowsPerPage } =
    useTablePagination();

  const [query, { data, loading: isLoading }] = useGetEmployeesLazyQuery();

  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState<string>('');

  const [actionUser, setActionUser] = useState(-1);
  const [rows, setRows] = useState<GetEmployeesQuery['getEmployees']['items']>(
    [],
  );

  /**
   * Handle open/close delete modal
   * @param id
   */
  const handleOpenDeleteModal = (id: number) => {
    setActionUser(id);
    setIsDeleteModalShow(true);
  };

  const setSnackBar = (message: string) => {
    setIsShowSnackbar(true);
    setSnackBarMessage(message);
  };

  useEffect(() => {
    if (organizationId)
      void (async () => {
        await query({
          variables: {
            getEmployeesInput: {
              systemRoles: [
                SystemRoles.Root,
                SystemRoles.SystemAdmin,
                SystemRoles.KeyUser,
              ],
              organisationId: organizationId,
              limit: rowsPerPage,
              cursor,
            },
          },
        });
      })();
  }, [cursor, query, rowsPerPage, organizationId]);

  useEffect(() => {
    if (data) {
      setRows(data.getEmployees.items || []);
    }
  }, [data]);

  if (isLoading)
    return (
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    );
  return (
    <>
      {isDeleteModalShow && (
        <DeleteUserModal
          {...rows[actionUser]}
          open={isDeleteModalShow}
          closeModal={() => setIsDeleteModalShow(false)}
          setSnackBar={setSnackBar}
        />
      )}
      <TitleContainer
        title={t('systemSettings.systemUsers')}
        rightContainer={<AddUserModal />}
      >
        <TableContainer>
          <TablePaginationWrapper
            total={data?.getEmployees.total || 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(newValue: number) => setRowsPerPage(newValue)}
            cursor={cursor}
            setCursor={(newValue: number) => setCursor(newValue)}
          >
            <Table size="small">
              <SystemUsersTableHeader />
              <TableBody>
                {rows.map((row, id) => (
                  <SystemUsersTableRow
                    row={row}
                    key={row.id}
                    id={id}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                  />
                ))}
              </TableBody>
            </Table>
          </TablePaginationWrapper>
        </TableContainer>
        <CustomSnackBar
          message={snackbarMessage}
          open={isShowSnackbar}
          close={() => setIsShowSnackbar(false)}
        />
      </TitleContainer>
    </>
  );
};
