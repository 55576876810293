import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useEflowPermissions } from '@e-flow/hooks';

import {
  Add,
  DeleteOutline,
  FilterList,
  ViewColumnOutlined,
} from '@mui/icons-material';
import { Box, IconButton, Typography, styled } from '@mui/material';

import { Button } from '@/core/components';
import { useMenu } from '@/core/hooks';
import { theme } from '@/theme';

import { OptionsToDisplay, TableDisplayMenu } from '..';
import { ToBeHeaderProps } from './ToBeHeader.types';

export const ToBeHeader = (props: ToBeHeaderProps) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();
  const navigate = useNavigate();

  const { isMenuOpen, anchorEl, handleMenuClose, handleMenuOpen } = useMenu();

  const handleChangeColumnsToDisplay = (options: OptionsToDisplay) => {
    const columnsNotToDisplay = Object.keys(options).filter(
      (key) => !options[key],
    );
    props.handleChangeColumnsToDisplay(columnsNotToDisplay);
  };

  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: props.eFlowId,
  });

  return (
    <>
      <Main>
        <Typography>{props.title}</Typography>
        <Buttons>
          <ButtonsSection>
            {props.removeOperations.isVisible && canModifyAnalize && (
              <Button
                onClick={props.removeOperations.onClick}
                startIcon={<DeleteOutline />}
                variant={'text'}
                sx={{
                  color: theme.palette.text.primary,
                }}
                typographySx={{
                  color: theme.palette.text.primary,
                }}
              >
                {commonT('common.delete')}
              </Button>
            )}

            {canModifyAnalize && (
              <IconButton
                title={t('eflow.addOperation')}
                onClick={() => navigate('operations/TO_BE')}
              >
                <Add
                  color="inherit"
                  style={{
                    color: theme.palette.text.primary,
                  }}
                />
              </IconButton>
            )}
            <IconButton disabled>
              <FilterList
                color="inherit"
                style={{
                  color: theme.palette.grey[500],
                }}
              />
            </IconButton>

            <IconButton onClick={handleMenuOpen}>
              <ViewColumnOutlined
                color="inherit"
                style={{
                  color: theme.palette.text.primary,
                }}
              />
            </IconButton>
          </ButtonsSection>
          <ButtonsSection>{props.currentActionButtons}</ButtonsSection>
        </Buttons>
      </Main>
      <TableDisplayMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        handleClose={handleMenuClose}
        parameters={props.parameters}
        onChange={handleChangeColumnsToDisplay}
      />
    </>
  );
};

const Main = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const Buttons = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
});

const ButtonsSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '6px',
});
