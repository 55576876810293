import { HandleChartProvider } from '@kpi/modals/HandleKpiChartModal/context';

import { Stack, styled } from '@mui/material';

import { DashboardStateDataProvider } from '@core/modules/DashbordModule/contexts/SetDashboard.context.tsx';

import { DashboardTabs } from './Dashboard/DashboardTabs';

export const Dashboard = () => {
  return (
    <DashboardStateDataProvider>
      <HandleChartProvider>
        <Container>
          <DashboardTabs />
        </Container>
      </HandleChartProvider>
    </DashboardStateDataProvider>
  );
};

const Container = styled(Stack)({
  gap: '24px',
  padding: '24px',
});
