//@bryndalski similar component has already been written in the e-flow module. Extract logic to represent part of `useTimeFormat` hook to the `TimeCell` component.
import { useEffect, useState } from 'react';

import { TimeCellTypes } from '@core/components/Statistica/StatisticTable/Cells/TimeCell/TimeCell.types.ts';
import { getTimeAsString, useTimeFormat } from '@core/hooks';

import { CustomTableCell } from '../CustomTableCell';

export const TimeCell = (props: TimeCellTypes) => {
  const { time } = useTimeFormat({
    inputTime: props.rowValue,
    timeFormat: props.rowType,
  });

  const [timeToDisplay, setTimeToDisplay] = useState<string>();

  useEffect(() => {
    if (!props.rowValue) return;

    setTimeToDisplay(getTimeAsString(time, props.rowType));
  }, [props.rowType, props.rowValue, time]);

  return <CustomTableCell align={props.align}>{timeToDisplay}</CustomTableCell>;
};
