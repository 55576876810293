import { Alert, Snackbar } from '@mui/material';

import { AlertSnackbarType } from '@core/components/Snackbars/AlertSnackbar/AlertSnackbar.types.ts';

export const AlertSnackbar = ({
  isOpen,
  onClose,
  severity,
  autoHideDuration = 6000,
  text = '',
}: AlertSnackbarType) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%', color: 'white' }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};
