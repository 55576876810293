import { useParams } from 'react-router-dom';

import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AvatarMenu, EflowStatus } from '@/core/components';
import { NavbarMainContainer } from '@/core/styles/Navbar';
import { shortenTitle } from '@/core/utils';
import { NavbarHamburgerMenu } from '@/pages/e-flow/components';
import { theme } from '@/theme';

import { ActionButtons } from './ActionButtons';
import { NavbarProps } from './Navbar.types';

export const Navbar = (props: NavbarProps) => {
  const { flowId } = useParams();

  return (
    <NavbarMainContainer>
      <NavbarLinks>
        <NavbarHamburgerMenu status={props.status} eFlowId={flowId} />
        <NavbarTitle>
          <Tooltip title={props.title}>
            <TitleTypography variant="h4">
              {shortenTitle(props.title)}
            </TitleTypography>
          </Tooltip>
          {props.status && <EflowStatus status={props.status} />}
        </NavbarTitle>
      </NavbarLinks>

      <NavbarActions>
        <ActionButtons
          showAddAnalyzeButton={props.showAddAnalyzeButton}
          eFlowId={flowId}
        />
        <AvatarMenu />
      </NavbarActions>
    </NavbarMainContainer>
  );
};

const NavbarLinks = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const NavbarActions = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: 8,
});

export const NavbarTitle = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  width: 'auto',
  columnGap: 8,
  maxWidth: '100%',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '@media (max-width: 600px)': {
    maxWidth: '200px',
  },
});

const TitleTypography = styled(Typography)({
  color: theme.palette.text.primary,
  fontWeight: 600,
});
