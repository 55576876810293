import { EFlowStepFile } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types';

import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Typography, styled } from '@mui/material';

import { FileModel } from '@/__generated__/graphql.ts';

import { AttachmentsDisplayProps } from './types';

export const AttachmentsDisplay = (props: AttachmentsDisplayProps) => {
  const test = (file: File | EFlowStepFile | FileModel): string => {
    if ('fileUrl' in file) {
      return file.fileUrl;
    } else {
      if (file instanceof File) {
        return URL.createObjectURL(file);
      }

      return URL.createObjectURL(file.body);
    }
  };

  const formatFileName = (file: File | EFlowStepFile | FileModel): string => {
    if ('fileName' in file) {
      return file.fileName;
    } else {
      return file.name;
    }
  };

  return (
    <MainContainer>
      {props.files.map((file, index) => (
        <ImageContainer key={index}>
          <CustomImage src={test(file)} alt={formatFileName(file)} />
          <LowerData>
            <Typography variant="bodyRegular">
              {formatFileName(file)}
            </Typography>
            {props.handleDelete && (
              <IconButton
                onClick={() =>
                  props.handleDelete!(
                    formatFileName(file),
                    !(file instanceof File),
                  )
                }
              >
                <ClearIcon />
              </IconButton>
            )}
          </LowerData>
        </ImageContainer>
      ))}
    </MainContainer>
  );
};

const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '16px',
  maxWidth: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ImageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '240px',
  position: 'relative',
});

const CustomImage = styled('img')({
  width: '100%',
  height: 'auto',
  backgroundColor: 'white',
});
const LowerData = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '8px',
  bottom: 0,
});
