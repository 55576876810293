import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { Button, Typography, styled } from '@mui/material';

import { useUserStore } from '@/stores';

import { AssignToMeButtonProps } from './AssignToMeButton.types';

export const AssignToMeButton: FC<AssignToMeButtonProps> = ({
  onClick,
  disabled,
}) => {
  const { t } = useTranslation();
  const { userUuid } = useUserStore();

  const handleButtonClick = () => {
    onClick(userUuid);
  };
  return (
    <CustomButton
      sx={{
        backgroundColor: 'white',
        background: 'none',
        color: 'black',
      }}
      onClick={handleButtonClick}
      disabled={disabled}
    >
      <PersonAddOutlinedIcon />
      <CustomTypography>{t('AssignToMeButton.assignToMe')}</CustomTypography>
    </CustomButton>
  );
};

const CustomTypography = styled(Typography)({
  gap: '8px',
  paddingRight: '4px',
  paddingLeft: '4px',
});

const CustomButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  variant: 'contained',
});
