import { useEffect, useState } from 'react';

import { useCompanyId } from '@core/hooks';

import { useFindSingleKaizenReportLazyQuery } from '@/__generated__/graphql.ts';

export const useKaizenReport = () => {
  const [settlementPeriodId, setSettlementPeriodId] = useState<string | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { companyId } = useCompanyId();

  const [getKaizenReportQuery, { data, loading: isQueryLoading }] =
    useFindSingleKaizenReportLazyQuery();

  useEffect(() => {
    if (!companyId || !settlementPeriodId) return;

    void getKaizenReportQuery({
      variables: {
        input: {
          id: settlementPeriodId,
          organizationId: companyId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, settlementPeriodId]);

  useEffect(() => {
    if (!companyId || !settlementPeriodId) {
      setIsLoading(true);
      return;
    }

    if (isQueryLoading) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [companyId, settlementPeriodId, isQueryLoading]);

  return {
    isLoading,
    settlementPeriodId,
    report: data?.findSingleKaizenReport,
    setSettlementPeriodId,
  };
};
