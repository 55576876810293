import { useEffect, useState } from 'react';

import { StepDetailsTypes } from '@e-flow/pages/flowDashboard/modals/StepDetails/StepDetails.types.ts';
import { StepModal } from '@e-flow/pages/flowDashboard/modals/StepDetails/components/StepModal/StepModal.tsx';

import { CustomModal, ModalWithDividers } from '@core/components';

import { AsIsToBeNamesEnum } from '@/__generated__/graphql.ts';

import { StepModalViewsContext } from './context';
import { useManageSteps } from './hooks';

export const StepDetails = (props: StepDetailsTypes) => {
  const [initialView, setInitialView] = useState<AsIsToBeNamesEnum>(
    props.initialView,
  );

  const {
    openViews,
    handleInit,
    handleChangeViews,
    asIsStepId,
    toBeStepId,
    setAsIsStepId,
    setToBeStepId,
  } = useManageSteps(initialView);

  useEffect(() => {
    if (openViews.length === 0) props.closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openViews]);

  useEffect(() => {
    handleInit(props.initialView, props.initialStepId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.display]);

  useEffect(() => {
    if (props.initialView === AsIsToBeNamesEnum.AsIs) {
      setAsIsStepId(props.initialStepId);
      setInitialView(AsIsToBeNamesEnum.AsIs);
    } else {
      setToBeStepId(props.initialStepId);
      setInitialView(AsIsToBeNamesEnum.ToBe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.display]);

  return (
    <CustomModal display={props.display}>
      <StepModalViewsContext.Provider
        value={{
          openViews,
          handleChangeViews,
          asIsStepId,
          toBeStepId,
          setAsIsStepId,
          setToBeStepId,
        }}
      >
        <ModalWithDividers headerText={''}>
          {openViews.includes(AsIsToBeNamesEnum.AsIs) && (
            <StepModal
              analizeType={AsIsToBeNamesEnum.AsIs}
              eFlowId={props.eFlowId}
              organizationId={props.organizationId}
              stepId={asIsStepId}
            />
          )}
          {openViews.includes(AsIsToBeNamesEnum.ToBe) && (
            <StepModal
              analizeType={AsIsToBeNamesEnum.ToBe}
              eFlowId={props.eFlowId}
              organizationId={props.organizationId}
              stepId={toBeStepId}
            />
          )}
        </ModalWithDividers>
      </StepModalViewsContext.Provider>
    </CustomModal>
  );
};
