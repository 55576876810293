import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import {
  FindSingleOrganizationPositionsQuery,
  useFindSingleOrganizationPositionsLazyQuery,
} from '@/__generated__/graphql';

import { SelectPositionProps } from './SelectPosition.types';

export const SelectPosition = (props: SelectPositionProps) => {
  const { t } = useTranslation();
  const [positions, setPositions] = useState<
    FindSingleOrganizationPositionsQuery['findSingleOrganization']['positions']
  >([]);

  const [query, { data }] = useFindSingleOrganizationPositionsLazyQuery();

  useEffect(() => {
    if (props.organizationId) {
      void query({
        variables: {
          input: {
            id: props.organizationId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organizationId]);

  useEffect(() => {
    if (data?.findSingleOrganization) {
      setPositions(data?.findSingleOrganization.positions);
    }
  }, [data]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      options={positions || []}
      value={props.value}
      onChange={(_, value) => props.handleChange(value)}
      noOptionsText={t('error.noResult')}
      renderInput={(params) => (
        <TextField {...params} label={t('employees.position')} />
      )}
    />
  );
};
