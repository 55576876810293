import { useGetDocumentType } from '@e-flow/pages/flowDashboard/hooks/useCacheUpdate/hooks/useGetDocumentType.ts';
import { DocumentNode } from 'graphql';

import { AsIsToBeNamesEnum, EflowStep } from '@/__generated__/graphql.ts';

import { readQuery, writeQuery } from '../Functions';

export const useRemoveEflowStepFromCache = (
  eFlowId: string,
  organizationId: string,
) => {
  const { getRecordStepType } = useGetDocumentType();
  /**
   * Removed element from cache in live update
   * @param document
   * @param recordsToRemove {string[]} - records to remove
   * @param analizeType {AsIsToBeNamesEnum} - AsIs or ToBe
   */
  const removeRecordsFromCache = (
    document: DocumentNode,
    recordsToRemove: string[],
    analizeType: AsIsToBeNamesEnum,
  ) => {
    const data = readQuery(document, eFlowId, organizationId);

    const recordStepsType = getRecordStepType(analizeType);
    //update steps
    data.findOneEFlow[recordStepsType] = data.findOneEFlow[
      recordStepsType
    ].filter((step: EflowStep) => {
      return !recordsToRemove.includes(step.id);
    });

    writeQuery(document, data, eFlowId, organizationId);
  };

  return { removeRecordsFromCache };
};
