import { captureException } from '@sentry/react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddKpiRateContext } from '@kpi/contexts';
import { omit, pick } from 'lodash';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import {
  CreateKpiRateInput,
  useCreateKpiRateMutation,
} from '@/__generated__/graphql.ts';

export const useAddKpiRateNetwork = () => {
  const { t } = useTranslation('kpi');

  const { companyId } = useCompanyId();
  const { openSnackbar } = useSnackbarContext();

  const { isUpdateModal } = useAddKpiRateContext();

  const [createKpiRate, { loading: isLoading }] = useCreateKpiRateMutation();

  const submitData: SubmitHandler<CreateKpiRateInput> = async (kpiRate) => {
    try {
      const res = await createKpiRate({
        variables: {
          input: {
            ...omit(kpiRate, ['targetData', 'targetType']),
            organizationId: companyId,
            ...(kpiRate.hasTarget && {
              ...pick(kpiRate, ['targetData', 'targetType']),
            }),
          },
        },
      });
      if (res.data) {
        openSnackbar(
          isUpdateModal
            ? t('addKpi.snackBarSuccessMessageUpdate')
            : t('addKpi.snackBarSuccessMessage'),
        );
      }
    } catch (error) {
      captureException(error);
      openSnackbar(
        isUpdateModal
          ? t('addKpi.snackBarFailMessageUpdate')
          : t('addKpi.snackBarFailMessage'),
      );
    }
  };

  return {
    isLoading,
    submitData,
  };
};
