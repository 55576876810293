import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDebounceValue } from 'usehooks-ts';

import {
  Autocomplete,
  CircularProgress,
  TextField,
  styled,
} from '@mui/material';

import {
  FindAllOrganizationsQuery,
  Organization,
  useFindAllOrganizationsLazyQuery,
} from '@/__generated__/graphql.ts';

type OrganizationSelectProps = {
  onChange: (organization: Organization | null) => void;
  width?: string;
  error?: FieldError | undefined;
};

/**
 * Component is rendered for each user in the list
 * @param onChange function to be called when user is selected
 */
export const OrganizationSelect = ({
  onChange,
  width,
  error,
}: OrganizationSelectProps) => {
  const [search, setSearch] = useState('');
  const [limit] = useState<number>(10);
  const [cursor, setCursor] = useState<number>(0);
  const [organizations, setOrganizations] = useState<
    FindAllOrganizationsQuery['findAllOrganizations']['items']
  >([]);

  const [debouncedSearchTerm, setDebounceSearchTerm] = useDebounceValue(
    search,
    500,
  );

  const { t } = useTranslation();

  const [loadKeyUsers, { loading: isLoading, data }] =
    useFindAllOrganizationsLazyQuery();

  /**
   * Function to set the organizations state when data is loaded
   */
  useMemo(() => {
    if (data) {
      const tempOrganization = [
        ...organizations,
        ...data.findAllOrganizations.items,
      ];
      setOrganizations(
        tempOrganization.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //Finds key user when search has changed
  useEffect(() => {
    void (async () => {
      await loadKeyUsers();
    })();
  }, [debouncedSearchTerm, loadKeyUsers]);

  const loadUsers = async () => {
    await loadKeyUsers({
      variables: {
        paginationInput: {
          limit,
          cursor,
          search,
        },
      },
    });

    if (data && data?.findAllOrganizations.total > cursor + limit)
      setCursor(cursor + limit);
  };

  return (
    <CustomAutoComplete
      loading={isLoading}
      loadingText={<CircularProgress />}
      id="tags-standard"
      sx={{ width: width ?? '280px' }}
      options={organizations}
      getOptionLabel={(option) => (option as Organization).name}
      //Pagination
      ListboxProps={{
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onScroll: async (event: SyntheticEvent) => {
          const listBoxNode = event.currentTarget;
          if (
            listBoxNode.scrollTop + listBoxNode.clientHeight ===
              listBoxNode.scrollHeight &&
            data &&
            data?.findAllOrganizations.total > cursor + limit
          ) {
            await loadUsers();
          }
        },
      }}
      // Render of the option
      onInputChange={(_e, val) => {
        setSearch(val);
        setDebounceSearchTerm(val);
      }}
      autoComplete
      onChange={(_e, val) => {
        if (val) {
          onChange(val as Organization);
        } else {
          onChange(null);
        }
      }}
      includeInputInList
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onOpen={loadUsers}
      // Selected option display
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            placeholder={t('common.organization')}
            helperText={!!error && error.message}
            error={!!error}
          />
        );
      }}
    />
  );
};

const CustomAutoComplete = styled(Autocomplete)({
  width: '100%',
  margin: '0 24px',
  maxWidth: '480px',
});
