import { PieElementProps } from './ReportPieChart.types';

export const useReportPieData = (data: PieElementProps[]) => {
  let valueSum = 0;
  data.forEach((item) => (valueSum += item.value));

  const legendData = data.map((item) => {
    return { name: item.label, color: item.color };
  });

  return { valueSum, legendData };
};
