import { KpiDashboardProvider } from '@kpi/pages/Dashboard/contexts/KpiDashbaordContext.tsx';
import { KpiDashboard } from '@kpi/pages/Dashboard/views';

import { DashboardTypesEnum } from '@/__generated__/graphql.ts';

export const SingleTab = ({
  dashboardType,
}: {
  dashboardType: DashboardTypesEnum;
}) => {
  return (
    <KpiDashboardProvider dashboardType={dashboardType}>
      <KpiDashboard dashboardType={dashboardType} />
    </KpiDashboardProvider>
  );
};
