// @Move_to_core
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, styled, useTheme } from '@mui/material';

import { StatusProps } from './Status.types';
import { getStatusData } from './utils';

export const Status: FC<StatusProps> = ({ status }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const statusData = getStatusData(theme, status, t);

  return (
    <BoxContainer>
      {statusData && (
        <>
          <ColorBox
            style={{
              backgroundColor: statusData.color,
            }}
          />
          <StatusBox>{statusData.name}</StatusBox>
        </>
      )}
    </BoxContainer>
  );
};

const BoxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
const ColorBox = styled(Box)({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
});
const StatusBox = styled(Box)({
  marginLeft: 10,
});
