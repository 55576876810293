import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NavbarTypes } from '@e-flow/pages/flowReport/components/Navbar/Navbar.types.ts';

import { RefreshOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography, styled } from '@mui/material';

import { AvatarMenu, Button, EflowStatus } from '@core/components';
import { NavbarMainContainer } from '@core/styles';

import { NavbarHamburgerMenu } from '@/pages/e-flow/components';
import { theme } from '@/theme';

/**
 * Navbar component
 * @param props {NavbarTypes}
 * @constructor
 */
export const Navbar = ({
  title = '',
  isLoading = true,
  ...props
}: NavbarTypes) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  const navigate = useNavigate();
  const { flowId, companyName } = useParams();

  return (
    <NavbarMainContainer>
      <NavbarWithButtonName>
        <NavbarHamburgerMenu handleExport={props.handleExport} />
        {isLoading ? (
          <Skeleton variant="rectangular" width={210} />
        ) : (
          <Typography
            variant="h5"
            color="textPrimary"
            sx={{ wordWrap: 'break-word', textWrap: 'wrap' }}
          >
            {title}
          </Typography>
        )}
        {props.status && <EflowStatus status={props.status} />}
      </NavbarWithButtonName>
      <ActionContainer>
        {!isLoading && (
          <>
            <Button
              variant="text"
              sx={{
                color: theme.palette.text.primary,
              }}
              typographySx={{
                color: theme.palette.text.primary,
              }}
              startIcon={<RefreshOutlined />}
              onClick={props.handleRefresh}
            >
              {commonT('common.refresh')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/${companyName}/e-flow/${flowId}`)}
            >
              {t('reports.editAnalysis')}
            </Button>
          </>
        )}
        <AvatarMenu />
      </ActionContainer>
    </NavbarMainContainer>
  );
};

const NavbarWithButtonName = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  alignItems: 'center',
});

const ActionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  alignItems: 'center',
});
