import { cloneDeep } from '@apollo/client/utilities';
import { GetAsIsToBeEflowParamsQuery } from '@/__generated__/graphql.ts';
import { FilterOperationTypesTypes } from './FilterOperationTypes.types';

/**
 * Filters all operation inside the eFlow data by operation type
 * @param params {GetAsIsToBeEflowParamsQuery} - The input props for the hook
 * @param operationType {OperationTypesEnum | 'ALL'} - The name of the parameter
 *
 * @example
 * const dataSeries = filterOperationTypes({
 *  params: data,
 *  operationType: operation as OperationTypesEnum | 'ALL',
 *  });
 *
 *  @returns {Pick<GetAsIsToBeEflowParamsQuery, 'findOneEFlow'>} - clone of the input data with filtered operations
 */
export const filterOperationTypes = ({
  params,
  operationType,
}: FilterOperationTypesTypes): Pick<
  GetAsIsToBeEflowParamsQuery,
  'findOneEFlow'
> => {
  const eFlowData = params?.findOneEFlow;

  const asIsSteps = cloneDeep(eFlowData?.asIsSteps) || [];
  const toBeSteps = cloneDeep(eFlowData?.toBeSteps) || [];

  const filteredAsIsSteps = asIsSteps.filter(
    (step) => step.operation.operationType === operationType || operationType === 'ALL',
  );
  const filteredToBeSteps = toBeSteps.filter(
    (step) => step?.operation.operationType === operationType || operationType === 'ALL',
  );

  return {
    ...params,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    findOneEFlow: {
      ...params.findOneEFlow,
      asIsSteps: filteredAsIsSteps,
      toBeSteps: filteredToBeSteps,
    },
  };
};
