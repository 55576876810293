import { OrganizationNode } from '@core/types';
import { bfsSearch, getAllIds } from '@core/utils';
import { NodeId } from '@/types';


/**
 * @description it try to find node with a specific id in tree.
 * if it is a return getAllIds function with node, which returns all children, If not return empty list
 * @param {NodeId} id
 * @param {OrganizationNode[]} organizationTree source
 * @returns list of indexes of child node and given parent
 */
export const getAllChild = (
  id: NodeId,
  organizationTree: OrganizationNode[],
): NodeId[] => {
  const node = bfsSearch(organizationTree, id);
  return node ? getAllIds(node) : [];
};