import { useTranslation } from 'react-i18next';

import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

export const TableHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <CustomTopography fontWeight={500}>
            {t('employee.employee')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography fontWeight={500}>
            {t('employees.email')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography fontWeight={500}>
            {t('employees.position')}
          </CustomTopography>
        </TableCell>
        {/* TableCell for button row */}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};
const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
