import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useSnackbar } from '@core/hooks';

import { useCloneEflowMutation } from '@/__generated__/graphql';
import { useOrganizationStore } from '@/stores';

export const useCloneEflow = () => {
  const { organization } = useOrganizationStore();
  const { companyName } = useParams();

  const [cloneEflowMutation] = useCloneEflowMutation();

  const { t } = useTranslation('eflow');

  const { openSnackbar, isSnackBarOpen, closeSnackbar, snackBarMessage } =
    useSnackbar();

  const cloneEflow = async (eFlowId: string) => {
    try {
      openSnackbar(t('duplicate.duplicatingMessage'));
      await cloneEflowMutation({
        variables: {
          input: {
            id: eFlowId,
            organizationId: organization[companyName!],
          },
        },
      });
      openSnackbar(t('duplicate.duplicateSuccessMessage'));
    } catch (ex) {
      openSnackbar(t('duplicate.duplicateErrorMessage'));
      captureException(ex);
    }
  };

  return { cloneEflow, isSnackBarOpen, closeSnackbar, snackBarMessage };
};
