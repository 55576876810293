import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { Button } from '@core/components';
import { ModalWithTimeoutProps } from '@core/components/Modals/LoadingModal/LoadingModal.types.ts';

export const OnTooLong = (props: ModalWithTimeoutProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <CustomText variant="bodyMedium">{props.onTimeoutText}</CustomText>
      <CustomButtonContainer>
        <Button
          variant="contained"
          onClick={props.closeModal}
          color={'warning'}
        >
          <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
            {t('common.close')}
          </Typography>
        </Button>
        <Button
          variant="contained"
          color={'success'}
          type="submit"
          onClick={() => {
            props.onTimeoutButtonAction();
            props.closeModal();
          }}
        >
          <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
            {props.onTimeoutButtonLabel}
          </Typography>
        </Button>
      </CustomButtonContainer>
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  minHeight: '100px',
  flexDirection: 'column',
  margin: '0 ',
  padding: '0',
  gap: 15,
});

const CustomButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'end',
  gap: 10,
  flexDirection: 'row',
});

const CustomText = styled(Typography)({
  whiteSpace: 'pre-wrap',
  lineHeight: 1.3,
});
