import { TFunction } from 'i18next';
import { z } from 'zod';

/**
 * Add category schema - validator
 * @param t {TFunction<'eflow'>} - translation function
 * @param symbols  {string[]} - symbols
 * @param comments {string[]} - comments
 */
export const AddCategorySchema = (
  t: TFunction<'eflow'>,
  symbols: string[],
  comments: string[],
) =>
  z.object({
    symbol: z
      .string()
      .min(1, {
        message: t('newAnalysis.comments.errors.symbolMustExist'),
      })
      .regex(/^[A-Z]+$/, {
        message: t('newAnalysis.comments.errors.symbolRegex'),
      })
      .refine((value) => !symbols.includes(value), {
        message: t('newAnalysis.comments.errors.symbolExists'),
      }),
    categoryName: z
      .string()
      .min(5, {
        message: t('newAnalysis.comments.errors.categoryNameMinLength', {
          length: 5,
        }),
      })
      .refine((value) => !comments.includes(value), {
        message: t('newAnalysis.comments.errors.categoryNameExists'),
      }),
  });
