import { EmptyResult } from '@kpi/components/KpiRatesTable/KpiTable/EmptyResult.tsx';
import { KpiTableRow } from '@kpi/components/KpiRatesTable/KpiTable/KpiTableRow.tsx';

import { CircularProgress } from '@mui/material';

import { CustomDataGrid } from '@core/components/CustomDataGrid/components/CustomDataGrid.tsx';
import { FlexCenter } from '@core/styles';

import { useKpiTableContext } from '../context';
import { KpiTableHead } from './KpiTableHead';

export const KpiTable = () => {
  const {
    order,
    total,
    orderBy,
    kpiRates,
    isLoading,
    rowsPerPage,
    setCursor,
    handleRequestSort,
  } = useKpiTableContext();

  if (isLoading) {
    return (
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    );
  }

  if (kpiRates.length === 0 && !isLoading) {
    return <EmptyResult />;
  }

  return (
    <CustomDataGrid
      infiniteScroll={true}
      infiniteScrollProps={{
        dataLength: total,
        next: () => setCursor((prev) => prev + rowsPerPage),
        hasMore: kpiRates.length < total,
        loader: <></>,
        style: {
          minWidth: '100%',
          width: 'calc( 100vw - 40px)', // 2*40 for padding of container
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'auto',
        },
      }}
      header={
        <KpiTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
      }
      stickyHeader={true}
      rowData={kpiRates}
      isLoading={isLoading}
      firstRowSticky={true}
      lastRowSticky={true}
      hideColumns={['assigneeCell']}
      row={(kpiRate, __index, hideColumns) => (
        <KpiTableRow {...kpiRate} key={kpiRate.id} hideColumns={hideColumns} />
      )}
    />
  );
};
