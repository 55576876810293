import { ParameterUnitsTypes } from '@/__generated__/graphql';

/**
 * Function to check if the parameter is time
 * @param unit - Parameter unit
 * @returns
 */
export const isTimeParameter = (unit: ParameterUnitsTypes): boolean => {
  return [
    ParameterUnitsTypes.TimeD,
    ParameterUnitsTypes.TimeH,
    ParameterUnitsTypes.TimeHhMm,
    ParameterUnitsTypes.TimeHhMmSs,
    ParameterUnitsTypes.TimeMin,
    ParameterUnitsTypes.TimeMmSs,
    ParameterUnitsTypes.TimeS,
  ].includes(unit);
};
