import React from 'react';
import { useTranslation } from 'react-i18next';

import { PointsTable, SettlementPeriods } from '@kaizen/module-settings';
import { KaizenSettlementPeriodProvider } from '@kaizen/module-settings/SettlementPeriods/contexts';

import { TabViewWrapper } from '@core/components';
import { Users } from '@core/modules/PermissionModule/views/Users.tsx';

import { KaizenCommission } from '@/pages/kaizen/module-settings/Commission/KaizenCommission';

export const KaizenSettings: React.FC = () => {
  const { t } = useTranslation('kaizen');

  return (
    <TabViewWrapper
      tabs={[
        {
          tabName: t('organizationSettings.access'),
          tabComponent: <Users />,
        },
        {
          tabName: t('organizationSettings.commission'),
          tabComponent: <KaizenCommission />,
        },
        {
          tabName: t('organizationSettings.settlementPeriod'),
          tabComponent: (
            <KaizenSettlementPeriodProvider>
              <SettlementPeriods />
            </KaizenSettlementPeriodProvider>
          ),
        },
        {
          tabName: t('organizationSettings.points'),
          tabComponent: <PointsTable />,
        },
      ]}
    />
  );
};
