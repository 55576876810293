import {
  EflowStepFragmentFragment,
  useGetEflowToBeStepsLazyQuery,
} from '@/__generated__/graphql.ts';

import { AnalizeSteps, LoadAnalizeSteps } from './LoadSteps.types';

/**
 * Load the to be step steps of the analysis
 * @param organizationId {string} The organization id
 * @param eFlowId {string} The eFlow id
 */
export const useLoadToBeSteps = ({
  organizationId,
  eFlowId,
}: LoadAnalizeSteps): AnalizeSteps => {
  const [findAllEflowSteps] = useGetEflowToBeStepsLazyQuery();

  const getSteps = async () => {
    const { data } = await findAllEflowSteps({
      variables: {
        input: {
          id: eFlowId,
          organizationId,
        },
      },
    });

    return {
      steps:
        (data?.findOneEFlow?.toBeSteps as EflowStepFragmentFragment[]) || [],
      stepsIds: data?.findOneEFlow?.toBeState || [],
    };
  };

  return {
    getSteps: getSteps,
  };
};
