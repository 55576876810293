import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableHead, Typography } from '@mui/material';

export const EditPointsModalTableHeader: FC = () => {
  const { t } = useTranslation('kaizen');

  return (
    <TableHead>
      <TableCell>
        <Typography fontWeight={500}>{t('points.statusHeader')}</Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight={500}>{t('points.pointsHeader')}</Typography>
      </TableCell>
    </TableHead>
  );
};
