import { Box, Typography } from '@mui/material';

import data from '@/core/constants/dummyData';

import { RechartChart } from '../Statistica';
import { PropsType } from './types';

export const LineChartWithDummyData = (props: PropsType) => {
  return (
    <>
      <Typography variant="h6" sx={{ margin: 'auto' }}>
        {props.title}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: props.height,
          margin: 0,
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <RechartChart {...data} />
      </Box>
    </>
  );
};
