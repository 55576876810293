import { ChartInput } from '@core/components/Statistica/types';

export class Chart {
  static isFromSingleDataSet(data: ChartInput) {
    return data.dataSourceSet.length === 1;
  }

  static isTargetDisplayed(data: ChartInput) {
    return data.displayOptions.showTarget;
  }

  static isShowDataAsPercent(data: ChartInput) {
    return !!data.displayOptions.showDataAsPercent;
  }

  static isAscendingOrder(data: ChartInput) {
    return data.displayOptions.showDataInAscendingOrder;
  }
}
