import { useCallback } from 'react';

import { KpiRateType } from '@kpi/components/KpiRatesTable/KpiTable';
import { KpiTableCell } from '@kpi/components/KpiRatesTable/KpiTable/components/KpiTableCell.tsx';

import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';

import { KpiUnitModelDto } from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

export const MonthCellsGroup = (props: {
  data: KpiRateType['data'];
  monthKey: number;
  unit: KpiUnitModelDto;
}) => {
  const renderEntryValue = useCallback(() => {
    if (typeof props.data[props.monthKey]?.entryValue !== 'number') {
      return null;
    }

    return (
      <Typography variant="bodyMedium" whiteSpace="nowrap" height={'50%'}>
        {props.data[props.monthKey]?.entryValue} {props.unit.unitShortcut}
      </Typography>
    );
  }, [props.data, props.monthKey, props.unit.unitShortcut]);

  const renderTargetValue = useCallback(() => {
    return (
      <Typography
        variant="bodyRegular"
        fontSize={10}
        whiteSpace="nowrap"
        color={theme.palette.grey['500']}
        height={'50%'}
      >
        {props.data[props.monthKey]?.targetValue}{' '}
        {props.data[props.monthKey]?.targetValue && props.unit.unitShortcut}
      </Typography>
    );
  }, [props.data, props.monthKey, props.unit.unitShortcut]);

  return (
    <KpiTableCell variant={(props.monthKey + 1) % 2 === 0 ? 'dark' : 'light'}>
      <Stack>
        <StyledEntry>{renderEntryValue()}</StyledEntry>
        <StyledTarget>{renderTargetValue()}</StyledTarget>
      </Stack>
    </KpiTableCell>
  );
};

const StyledEntry = styled(Box)({
  height: '22px',
});

const StyledTarget = styled(Box)({
  height: '21px',
});
