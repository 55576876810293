import { useTranslation } from 'react-i18next';

import { Box, styled } from '@mui/material';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`;

const Note = styled(Box)`
  font-size: 24px;
  color: #333;
`;

export const EmptyResults = () => {
  const { t } = useTranslation('eflow');

  return (
    <Container>
      <Note>{t('dashboard.noAnalyses')}</Note>
    </Container>
  );
};
