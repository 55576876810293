/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Stack, Typography, styled } from '@mui/material';

import { GetKeyUsersQuery, SystemRoles } from '@/__generated__/graphql';
import {
  Checkbox,
  CustomModal,
  InfoModalContent,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { UserSelect } from '@/core/components/UserSelect';
import { CommissionRole } from '@/core/enums';
import { useCompanyId } from '@/core/hooks';
import { CommissionMember } from '@/pages/kaizen/module-settings/Commission/hooks/useCommission/types';
import { useCommission } from '@/pages/kaizen/module-settings/Commission/hooks/useCommission/useCommission';
import { theme } from '@/theme';

import { AddMemberToCommissionModalProps } from './AddMemberToCommissionModal.types';

export const AddMemberToCommissionModal = ({
  open,
  closeModal,
  members,
  openSnackBar,
}: AddMemberToCommissionModalProps) => {
  const { t } = useTranslation();
  const { t: kaizenT } = useTranslation('kaizen');

  const { companyId } = useCompanyId();

  const [isCommissionLeader, setIsCommissionLeader] = useState(false);
  const [selectedUser, setSelectedUser] = useState<CommissionMember>();

  const { addCommissionMember } = useCommission();

  return (
    <CustomModal display={open}>
      <ModalContainer
        closeModal={closeModal}
        width="528px"
        headerText={kaizenT(
          'organizationSettings.addMemberToCommission.modalTitle',
        )}
      >
        <form>
          <InfoModalContent
            contentText={
              <Content>
                <UserSelect
                  disabledUsers={
                    members as unknown as GetKeyUsersQuery['getEmployees']['items']
                  }
                  onChange={() => {}}
                  systemRoles={[SystemRoles.KaizenMember]}
                  organizationId={companyId}
                  onSelectedUser={(user) => {
                    setSelectedUser(user as unknown as CommissionMember);
                  }}
                  width="100%"
                  placeholder={kaizenT(
                    'organizationSettings.addMemberToCommission.userSelectPlaceholder',
                  )}
                />
                <Checkbox
                  value={isCommissionLeader}
                  disabled={
                    !!members.find(
                      (member) =>
                        (member.commissionRole as CommissionRole) ===
                        CommissionRole.COMMISSION_LEADER,
                    )
                  }
                  onChange={(_event, value) => setIsCommissionLeader(value)}
                  label={kaizenT(
                    'organizationSettings.addMemberToCommission.commissionLeader',
                  )}
                />
              </Content>
            }
          >
            <TextButton
              text={t('common.cancel')}
              width="66px"
              onClick={closeModal}
            />
            <PrimaryButton
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
              disabled={!selectedUser}
              onClick={async () => {
                if (!selectedUser) return;
                void (await addCommissionMember({
                  ...selectedUser,
                  isCommissionLeader,
                }));
                openSnackBar();
                closeModal();
              }}
            >
              <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
                {t('common.add')}
              </Typography>
            </PrimaryButton>
          </InfoModalContent>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};

const Content = styled(Stack)({
  gap: '16px',
});
