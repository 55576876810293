import { useNavigate } from 'react-router';

import { ListItemIcon, MenuItem, Typography, styled } from '@mui/material';

import { CustomMenuItemProps } from '@/core/components';
import { theme } from '@/theme';

export const CustomMenuItem = ({
  text,
  link,
  icon,
  onClick,
  disabled = false,
}: CustomMenuItemProps) => {
  const navigate = useNavigate();

  return (
    <StyledMenuItem
      disabled={disabled}
      onClick={() => (onClick ? onClick() : navigate(`${link}`))}
    >
      <ListItemIcon sx={{ color: theme.palette.common.black }}>
        {icon}
      </ListItemIcon>
      <Typography variant="bodyRegular">{text}</Typography>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(MenuItem)({
  height: '44px',
  padding: '10px, 24px, 10px, 24px',
  gap: '4px',
});
