import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

export const InProgress = () => {
  return (
    <InProgressContainer>
      <CircularProgress />
    </InProgressContainer>
  );
};

const InProgressContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: '100px',
  flexDirection: 'column',
});
