import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@mui/material';

import { theme } from '@/theme';

import { TimeCell, TimeCellTypes } from '../Cells';
import { CustomTableCell } from '../Cells/CustomTableCell';
import { StatisticRowTypes } from './StatisticRow.types';

/**
 * Hook that renders the cells of the StatisticRow component
 * @param props - StatisticRowTypes
 * @returns
 */
export const useStatisticCells = () => {
  const { t } = useTranslation('eflow');

  const renderCells = useCallback((props: StatisticRowTypes) => {
    const cellsToRender: ReactNode[] = props.rowValues.map((value, index) => {
      if (props.isLoading)
        return (
          <CustomTableCell
            key={index + 1}
            align="right"
            sx={{
              ...(!props.withBorder && {
                borderBottom: 'none',
              }),
            }}
          >
            <Skeleton variant="rectangular" />
          </CustomTableCell>
        );

      if (!value) {
        return (
          <CustomTableCell
            key={index + 1}
            align="right"
            sx={{
              ...(!props.withBorder && {
                borderBottom: 'none',
              }),
            }}
          >
            -
          </CustomTableCell>
        );
      }

      if (props.rowType?.includes('TIME') && typeof value === 'number')
        return (
          <TimeCell
            align={props.cellContentAlignment}
            key={index + 1}
            rowValue={value}
            sx={{
              textAlignLast: 'right',
              justifyContent: 'flex-end',
              alignItems: 'center',
              ...(!props.withBorder && {
                borderBottom: 'none',
              }),
            }}
            rowType={props.rowType as TimeCellTypes['rowType']}
          />
        );

      return (
        <CustomTableCell
          key={index + 1}
          align={props.cellContentAlignment}
          sx={{
            textAlignLast: 'right',
            justifyContent: 'flex-end',
            alignItems: 'center',
            ...(!props.withBorder && {
              borderBottom: 'none',
            }),
          }}
        >
          {value}
        </CustomTableCell>
      );
    });

    cellsToRender.unshift(
      <CustomTableCell
        key={0}
        sx={{
          color: props.labelColor || theme.palette.text.primary,
          ...(!props.withBorder && {
            borderBottom: 'none',
          }),
        }}
      >
        {props.rowLabel}{' '}
        {props.displayRowUnit &&
          props.rowType &&
          `(${t(`newAnalysis.parameters.params.${props.rowType}`)})`}
      </CustomTableCell>,
    );

    return cellsToRender;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    renderCells,
  };
};
