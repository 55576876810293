/**
 * Format key for Recharts.
 * @param key
 * @param variant
 * @returns formatted key
 *
 * @description
 * because the kpi chart has both target and value, we need to format the key to be unique
 */
export function formatKey(key: string, variant: 'target' | 'value'): string {
  if (variant === 'target') {
    return `${key.replace(/\s/g, '')}_target`;
  }

  return `${key.replace(/\s/g, '')}_value`;
}
