import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ErrorWrapperProps } from './ErrorWrapper.types';

export const ErrorWrapper: FC<ErrorWrapperProps> = ({
  children,
  showError,
  errorMessage,
}) => {
  if (!showError) {
    return <Box>{children}</Box>;
  }

  return (
    <Box>
      {children}

      <Typography variant="bodyRegular" color="error">
        {errorMessage}
      </Typography>
    </Box>
  );
};
