import { createContext, useContext } from 'react';

import { useHandleChartVisibility } from '../hooks/useHandleChartVisibility';
import { HandleChartVisibilityContextProps } from './types';

export const HandleChartVisibilityContext = createContext<ReturnType<
  typeof useHandleChartVisibility
> | null>(null);

export const HandleChartVisibilityProvider = ({
  children,
  close,
}: HandleChartVisibilityContextProps) => {
  const singleReport = useHandleChartVisibility(close);

  return (
    <HandleChartVisibilityContext.Provider value={singleReport}>
      {children}
    </HandleChartVisibilityContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useHandleChartVisibilityContext = () => {
  const context = useContext(HandleChartVisibilityContext);

  if (!context) {
    throw new Error(
      'useSingleKpiReportContext must be used within a SingleKpiReportProvider',
    );
  }

  return context;
};
