import { GetAllSettlementPeriodsDocument } from '@/__generated__/graphql.ts';
import { client } from '@/apollo.ts';

/**
 * Function to write a query of eflow step
 * @param data unknown - data to write
 * @param organizationId string
 */
export const writeQuery = (data: unknown, organizationId: string) => {
  if (!data) return;

  client.writeQuery({
    query: GetAllSettlementPeriodsDocument,
    variables: {
      input: {
        organizationId,
        withArchive: false,
      },
    },
    data,
  });
};
