import { SystemRoles as GQLSystemRoles } from '@/__generated__/graphql';
import { SystemRoles } from '@/__generated__/graphql.ts';

/**
 * Function checks if userGroups are in permissions array
 * This function is based on SystemRoles enum from auth/Common/SystemRoles.enum
 * @returns boolean
 * @param userGroups
 * @param permissions
 */
export const checkSystemRolesPermission = (
  userGroups: SystemRoles[],
  permissions: SystemRoles[],
) => {
  for (const group of userGroups) {
    if (permissions.indexOf(group) !== -1) return true;
  }
  return false;
};
/**
 * Function checks if userGroups are in a permissions array
 * This function is based on SystemRoles enum from graphql
 * @returns
 * @param userGroups
 * @param permissions
 */
export const checkGqlSystemRolesPermission = (
  userGroups: GQLSystemRoles[],
  permissions: GQLSystemRoles[],
) => {
  for (const group of userGroups) {
    if (permissions.indexOf(group) !== -1) return true;
  }
  return false;
};
