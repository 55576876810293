import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePagination } from '@mui/material';

import { CalculatePages } from '@/core/utils';

import { calculateCursor } from './Functions';
import { TablePaginationWrapperProps } from './TablePaginationWrapper.types';
import { usePrevious } from './usePrevious';

export const TablePaginationWrapper = (props: TablePaginationWrapperProps) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  const previousRowsPerPage = usePrevious(props.rowsPerPage);

  useEffect(() => {
    setPage(
      CalculatePages(props.rowsPerPage, previousRowsPerPage, props.total, page),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowsPerPage]);

  useEffect(() => {
    if (props.cursor === 0) setPage(0);
  }, [props.cursor]);

  useMemo(() => {
    if (props.total && props.total !== 0)
      props.setCursor(calculateCursor(page, props.rowsPerPage, props.total));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, props.rowsPerPage, props.total]);

  return (
    <>
      {props.children}
      {!!props.total && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          sx={{ minHeight: '56px' }}
          count={props.total}
          rowsPerPage={props.rowsPerPage}
          page={page}
          onPageChange={(_e, newPage) => {
            setPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            props.setRowsPerPage(parseInt(event.target.value));
          }}
          labelRowsPerPage={t('common.rowsPerPage')}
        />
      )}
    </>
  );
};
