import { captureException } from '@sentry/react';

import {
  OperationNameEnum,
  useModifyPermissionsMutation,
} from '@/__generated__/graphql';

import { useChangePermissionsUsersCache } from '..';
import { useUpdatePermissionProps } from './useUpdatePermission.types';

export const useUpdatePermission = (props: useUpdatePermissionProps) => {
  const [modifyPermission, { loading: isLoading }] =
    useModifyPermissionsMutation();

  const { updateCache } = useChangePermissionsUsersCache();

  const handleModifyPermissions = async (
    operation: OperationNameEnum,
    userIds: string[],
  ) => {
    try {
      await modifyPermission({
        variables: {
          input: {
            moduleName: props.moduleName,
            operation,
            organizationId: props.organizationId,
            role: props.roleName,
            usersId: userIds,
          },
        },
        update(cache, { data }) {
          if (data?.modifyPermissions) {
            updateCache(
              cache,
              {
                organizationId: props.organizationId,
                cursor: 0,
                limit: 10,
              },
              userIds,
              [props.roleName],
              operation,
            );
          }
        },
      });
    } catch (error) {
      captureException(error);
      throw new Error('error: ' + (error as string));
    }
  };

  const addPermissions = async (userIds: string[]) => {
    await handleModifyPermissions(OperationNameEnum.Add, userIds);
  };
  const removePermissions = async (userIds: string[]) => {
    await handleModifyPermissions(OperationNameEnum.Remove, userIds);
  };

  return {
    isLoading,
    addPermissions,
    removePermissions,
  };
};
