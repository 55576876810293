import { ArrowBack, Close } from '@mui/icons-material';
import { Box, IconButton, Typography, styled } from '@mui/material';

import { TabWrapperProps } from './types';

export const TabWrapper = (props: TabWrapperProps) => {
  return (
    <>
      <DrawerHeader>
        <Typography variant="h6">
          {!!props.handleGoBack && (
            <IconButton onClick={props.handleGoBack} sx={{ marginLeft: -2 }}>
              <ArrowBack />
            </IconButton>
          )}
          {props.title}
        </Typography>
        <IconButton onClick={props.closeDrawer}>
          <Close />
        </IconButton>
      </DrawerHeader>
      {/* Other content */}
      {props.children}
    </>
  );
};

const DrawerHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
