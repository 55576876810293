import { useTranslation } from 'react-i18next';

import { useChartLegend } from '@kpi/modals/HandleKpiChartModal/hooks/useChartLegend/useChartLegend.tsx';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { ChartLegendType } from '@/__generated__/graphql';

import { useCreateChartContext } from '../../context';

export const Legend = () => {
  const { t } = useTranslation('kpi');

  const { legendType, currentOptions, setLegendType } = useCreateChartContext();

  const {
    isVariableLegendEnabled,
    isOrganizationUnitLegendEnabled,
    isEmployeeLegendEnabled,
  } = useChartLegend(currentOptions);

  return (
    <Stack gap="10px">
      <Typography variant="h6">{t('charts.legend.legend')}</Typography>

      <CustomRadioGruop
        value={legendType}
        onChange={(e) => setLegendType(e.target.value as ChartLegendType)}
      >
        <FormControlLabel
          value={ChartLegendType.NoLegend}
          control={<Radio />}
          label={t(`charts.legend.${ChartLegendType.NoLegend}`)}
        />
        <FormControlLabel
          value={ChartLegendType.VariableLegend}
          control={<Radio />}
          disabled={!isVariableLegendEnabled}
          label={t(`charts.legend.${ChartLegendType.VariableLegend}`)}
        />
        <FormControlLabel
          value={ChartLegendType.OrganizationUnitLegend}
          control={<Radio />}
          disabled={!isOrganizationUnitLegendEnabled}
          label={t(`charts.legend.${ChartLegendType.OrganizationUnitLegend}`)}
        />
        <FormControlLabel
          value={ChartLegendType.EmployeeLegend}
          control={<Radio />}
          disabled={!isEmployeeLegendEnabled}
          label={t(`charts.legend.${ChartLegendType.EmployeeLegend}`)}
        />
      </CustomRadioGruop>
    </Stack>
  );
};

const CustomRadioGruop = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
});
