import { KaizenStatuses } from '@/__generated__/graphql';

export const useSortedStatuses = () => {
  const {
    Adjustment,
    Draft,
    Rejected,
    SupervisorAssessment,
    CommissionAssessment,
    Accepted,
    Todo,
    InProgress,
    ImplementationAccepted,
    ImplementationRejected,
    Implemented,
  } = KaizenStatuses;

  const statuses = [
    Adjustment,
    Draft,
    Rejected,
    SupervisorAssessment,
    CommissionAssessment,
    Accepted,
    Todo,
    InProgress,
    ImplementationAccepted,
    ImplementationRejected,
    Implemented,
  ];

  const statusesWithNoDraft = statuses.filter(
    (status) => status !== KaizenStatuses.Draft,
  );

  return {
    statuses,
    statusesWithNoDraft,
  };
};
