/**
 * Check if a prop is a number and greater than 0
 * @param props all props of the component
 * @param propName prop name to check
 * @param componentName name of the component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isGreaterThanZero = <T extends Record<string, any>>(
  props: T,
  propName: keyof T,
  componentName: string,
): Error | null => {
  if (typeof props[propName] !== 'number') {
    return new Error(
      `Invalid prop ${
        propName as string
      } passed to ${componentName}. Expected a number.`,
    );
  }
  if (props[propName] <= 0) {
    return new Error(
      `Invalid prop ${
        propName as string
      } passed to ${componentName}. Expected a number greater than 0.`,
    );
  }
  return null;
};
