import { captureException } from '@sentry/react';

import { useUpdateSingleSubmissionCache } from '@kaizen/hooks';

import {
  KaizenSubmissionFragment,
  useCancelSubmissionMutation,
} from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

export const useCancelSubmission = () => {
  const { companyId } = useCompanyId();

  const [cancelSubmissionMutation, { loading: isLoading }] =
    useCancelSubmissionMutation();

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const cancelSubmission = async (submissionId: string) => {
    try {
      const result = await cancelSubmissionMutation({
        variables: {
          input: {
            submissionId,
            organizationId: companyId,
          },
        },
      });
      if (result.data?.kaizenCancelSubmission) {
        updateSingleSubmissionCache(
          result.data.kaizenCancelSubmission as KaizenSubmissionFragment,
        );
        return true;
      }
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  return {
    isLoading,
    cancelSubmission,
  };
};
