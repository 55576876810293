import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { AuthScreenWrapper, Button } from '@/core/components';

export const LoggedOut = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <AuthScreenWrapper>
      <Stack>
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          marginBottom={'15px'}
        >
          <CheckCircleOutlineIcon
            sx={{ marginRight: '10px' }}
            fontSize="large"
          />
          <Typography variant="h3">{t('loggedOut.title')}</Typography>
        </Stack>
        <Stack width={'110%'}>
          <Typography variant="subtitle2" width={'100%'}>
            {t('loggedOut.loggedOutMessage')}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Button onClick={() => navigate('/login')}>
          {t('loggedOut.logInAgain')}
        </Button>
      </Stack>
    </AuthScreenWrapper>
  );
};
