//@byrndalski move me to hooks root component
import { useTranslation } from 'react-i18next';

import { OperationSchema, OperationTypesEnum } from '@/__generated__/graphql';

export const useBasicOperations = (): OperationSchema[] => {
  const { t } = useTranslation('eflow');
  return [
    {
      operationType: OperationTypesEnum.AddedValue,
      name: t('newAnalysis.operations.basic.addedValue'),
    },
    {
      operationType: OperationTypesEnum.Operation,
      name: t('newAnalysis.operations.basic.operation'),
    },
    {
      operationType: OperationTypesEnum.Transport,
      name: t('newAnalysis.operations.basic.transport'),
    },
    {
      operationType: OperationTypesEnum.Inspection,
      name: t('newAnalysis.operations.basic.inspection'),
    },
    {
      operationType: OperationTypesEnum.Storage,
      name: t('newAnalysis.operations.basic.storage'),
    },
  ];
};
