import { useUpdateCommentMutation } from '@/__generated__/graphql.ts';

export const useUpdateCommentCategory = (
  commentId: string,
  organizationId: string,
) => {
  const [updateMutation, { loading: isLoading }] = useUpdateCommentMutation();

  const updateCategory = async (commentCategory: string) => {
    await updateMutation({
      variables: {
        input: {
          id: commentId,
          organizationId: organizationId,
          commentCategory,
        },
      },
    });
  };

  return {
    isLoading,
    updateCategory,
  };
};
