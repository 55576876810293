import { useMemo } from 'react';
import { useUserStore } from '@/stores';

export const useResourceOwner = (resourceOwnerId: string) => {
  const { userUuid } = useUserStore();

  const isResourceOwner = useMemo(() => {
    return resourceOwnerId === userUuid;
  }, [resourceOwnerId, userUuid]);

  return {
    isOwner: isResourceOwner,
  };
};
