import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Typography, styled } from '@mui/material';

import {
  CustomModal,
  InfoModalContent,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { theme } from '@/theme';

import { DeleteCommissionMemberModalProps } from './DeleteCommissionMemberModalProps.types';

export const DeleteCommissionMemberModal = ({
  open,
  closeModal,
  remove,
}: DeleteCommissionMemberModalProps) => {
  const { t } = useTranslation('kaizen');
  const { t: commonT } = useTranslation();

  return (
    <CustomModal display={open}>
      <ModalContainer
        width="528px"
        closeModal={closeModal}
        headerText={t('deleteCommissionMemberModal.deleteComissionMember')}
      >
        <InfoModalContent
          contentText={
            <Typography>{t('deleteCommissionMemberModal.bodyText')}</Typography>
          }
        >
          <TextButton
            text={commonT('common.cancel')}
            width="66px"
            onClick={closeModal}
          />
          <RedButton
            variant="contained"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={remove}
          >
            <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
              {commonT('common.delete')}
            </Typography>
          </RedButton>
        </InfoModalContent>
      </ModalContainer>
    </CustomModal>
  );
};

const RedButton = styled(PrimaryButton)({
  backgroundColor: theme.palette.error[600],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    backgroundColor: theme.palette.error[700],
  },
});
