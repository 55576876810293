import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { EmployeeAvatar } from '@/core/components';

import { CustomMenu } from './CustomMenu';
import { IEmployeeRowProps } from './types';

export const EmployeeRow = ({
  row,
  setEmployeeToDelete,
  setEmployeeToChangeCell,
}: IEmployeeRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <TableRow key={row.id}>
        <CustomTableCell>
          <Stack direction="row" alignItems="center" gap="12px">
            <EmployeeAvatar
              name={row.firstName}
              imageUrl={row.profilePicture}
              size={24}
            />
            <CustomTopography marginLeft={1} fontWeight={500}>
              {row.firstName} {row.lastName}
            </CustomTopography>
          </Stack>
        </CustomTableCell>
        <CustomTableCell>
          <CustomTopography fontWeight={500}>{row.email}</CustomTopography>
        </CustomTableCell>
        <CustomTableCell>
          <CustomTopography fontWeight={500}>{row.position}</CustomTopography>
        </CustomTableCell>
        <CustomTableCell>
          <IconButton
            aria-label="more"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
          <CustomMenu
            employeeId={row.id}
            setDeleteModalOpen={() => setEmployeeToDelete(row)}
            setChangeCellModalOpen={() => setEmployeeToChangeCell(row.id)}
            anchorEl={anchorEl}
            open={isMenuOpen}
            handleClose={() => setAnchorEl(null)}
          />
        </CustomTableCell>
      </TableRow>
    </>
  );
};

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});

const CustomTableCell = styled(TableCell)({
  align: 'right',
  height: '48px',
});
