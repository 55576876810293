import { Avatar } from '@mui/material';

import { theme } from '@/theme';

import { EmployeeAvatarProps } from './EmployeeAvatar.types';

export const EmployeeAvatar = ({
  name,
  imageUrl,
  size = 32,
  backgroundColor,
}: EmployeeAvatarProps) => {
  const commonStyles = {
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${size / 2}px`,
  };

  return (
    <Avatar
      alt={name}
      src={imageUrl || undefined}
      sx={{
        ...commonStyles,
        backgroundColor: imageUrl
          ? undefined
          : backgroundColor || theme.palette.primary[600],
      }}
    >
      {!imageUrl && name.charAt(0)}
    </Avatar>
  );
};
