import {
  CreateKpiRateInput,
  KpiRateDto,
  KpiRatingTargetType,
} from '@/__generated__/graphql';

const defaultValues: CreateKpiRateInput = {
  dueToDateTimeout: 10,
  hasTarget: false,
  organizationUnitId: '',
  rateDescription: '',
  organizationId: '',
  rateName: '',
  reporterId: '',
  targetData: {},
  targetType: KpiRatingTargetType.MonthlyUnified,
  unit: { unitName: '', unitShortcut: '' },
};

export function createKpiRateDefaultValues(kpiRate?: KpiRateDto) {
  if (!kpiRate) {
    return defaultValues;
  }

  return {
    dueToDateTimeout: kpiRate.dueToDayTimeout,
    hasTarget: kpiRate.hasTarget,
    organizationUnitId: kpiRate.organizationUnitId,
    rateDescription: kpiRate.rateDescription || '',
    organizationId: kpiRate.organizationId,
    rateName: kpiRate.rateName,
    reporterId: kpiRate.assigneeEmployeeId,
    targetType: kpiRate.targetType,
    targetData: kpiRate.targetData,
    unit: {
      unitName: kpiRate.unit.unitName,
      unitShortcut: kpiRate.unit.unitShortcut,
    },
  };
}
