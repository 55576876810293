import type { FetchUserAttributesOutput } from '@aws-amplify/auth';
import { SystemRoles } from '@/__generated__/graphql.ts';
import { IUser } from '@/core/types';

/**
 * Cognito user
 * @implements {IUser}
 * @class
 * @description
 * this class is used to manage cognito user attributes transformation
 */
export class CCognitoUser implements IUser {
  email: string;
  lastName: string;
  name: string;
  phone: string;
  userGroups: SystemRoles[];
  organizationUrl: string;
  userUuid: string;
  organizationId?: string;

  /**
   * Creates new cognito user
   * @param name {string} user name -> cognito equivalent: given_name
   * @param lastName {string} user last name -> cognito equivalent: family_name
   * @param email {string} user email -> cognito equivalent: email
   * @param phone {string} user phone number -> cognito equivalent: phone_number
   * @param userUuid
   * @param userGroups
   * @param organizationUrl
   * @param organizationId
   */
  constructor(
    name: string,
    lastName: string,
    email: string,
    phone: string,
    userUuid: string,
    userGroups?: SystemRoles[],
    organizationUrl?: string,
    organizationId?: string,
  ) {
    this.name = name;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.userGroups = userGroups || [];
    this.userUuid = userUuid;
    this.userGroups = [];
    this.organizationUrl = organizationUrl || '';
    this.organizationId = organizationId;
  }

  /**
   * Transforms cognito user attributes to match format used in the app
   * @see {@link IUser} user interface used in the app
   * @param cognitoUser
   * @returns {IUser}
   */
  public static transformCognitoAttributes(
    cognitoUser: FetchUserAttributesOutput,
  ): IUser {
    return new CCognitoUser(
      cognitoUser.given_name || '',
      cognitoUser.family_name || '',
      cognitoUser.email || '',
      cognitoUser.phone_number || '',
      cognitoUser['custom:userUUID'] || '',
      undefined,
      cognitoUser['custom:organizationUrl'] || '',
      cognitoUser['custom:organizationId'] || '',
    );
  }
}
