import { ForwardedRef, forwardRef } from 'react';

import { omit } from 'lodash';

import { InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/system';

import { NumberInputType } from '@core/components/inputs/NumberInput/NumberInput.types.ts';

export const NumberInput = forwardRef(
  (props: NumberInputType, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <CustomTextField
        size="small"
        type="number"
        ref={ref}
        {...omit(props, [
          'maxVal',
          'minVal',
          'onChange',
          'startAdornment',
          'endAdornment',
        ])}
        onChange={(e) => {
          if (e.target.value === '') {
            props.onChange('');
            return;
          }

          props.onChange(Number(e.target.value));
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          ...(props.startAdornment
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    {props.startAdornment}
                  </InputAdornment>
                ),
              }
            : {}),
          ...(props.endAdornment
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {props.endAdornment}
                  </InputAdornment>
                ),
              }
            : {}),

          type: 'number',
          inputProps: {
            min: props.minVal,
            max: props.maxVal,
          },
        }}
      />
    );
  },
);

const CustomTextField = styled(TextField)({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});
