import { useTranslation } from 'react-i18next';

import { StatusLabel } from '@kaizen/components';

import EastIcon from '@mui/icons-material/East';
import { Typography } from '@mui/material';

import { NotificationContainer, NotificationHeader } from '@core/modules';
import { KaizenStatusChangeNotificationType } from '@core/modules/Notifications/types/KaizenNotifications.type.ts';

import { RowStack } from '@/pages/organizations/styles';

export const KaizenStatusChangeNotification = (
  props: KaizenStatusChangeNotificationType,
) => {
  const { t } = useTranslation();

  return (
    <NotificationContainer onClick={props.onClick}>
      <NotificationHeader
        sentAt={props.sentAt}
        title={props.body.submissionName}
        isOpened={props.isOpened}
      />
      <Typography variant="bodyRegular">
        {t('notification.submissionStatusChanged')}
      </Typography>
      <RowStack gap={'10px'}>
        <StatusLabel variant={props.body.previousStatus} strikeThrough />
        <EastIcon fontSize="small" />
        <StatusLabel variant={props.body.newStatus} />
      </RowStack>
    </NotificationContainer>
  );
};
