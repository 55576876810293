import { captureException } from '@sentry/react';
import { useState } from 'react';

import { AddOperationsToBeOptionsEnum } from '@e-flow/pages/flowDashboard/modals/AddOperationsToToBe/AddOperationsToBe.options.enum.ts';

import {
  useChangeStepPositionMutation,
  useCloneEflowStepsMutation,
  useUpdateEflowStepMutation,
} from '@/__generated__/graphql';
import { useCompanyId } from '@/core/hooks';
import { useAsIsToBeCount } from '@/pages/e-flow/hooks';

import { RowMenuProps } from './RowMenu.types';

export const useRowMenu = (props: RowMenuProps) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [desiredEflowStartPosition, setDesiredEflowStartPosition] =
    useState<number>(0);

  const [selectedModalOption, setSelectedModalOption] = useState<string>(
    AddOperationsToBeOptionsEnum.ADD_TO_BOTTOM,
  );

  const [changeStepPosition] = useChangeStepPositionMutation();
  const [updateEflowStep] = useUpdateEflowStepMutation();
  const [cloneOperationsMutation] = useCloneEflowStepsMutation();

  const { toBeCount } = useAsIsToBeCount({ flowId: props.eFlowId as string });

  const { companyId } = useCompanyId();

  const moveStepPosition = async (move: number) => {
    try {
      await changeStepPosition({
        variables: {
          input: {
            organizationId: companyId,
            eFlowStepId: props.analizeStep.id,
            desiredPosition: props.analizeStep.rowIndex + move,
            analizeType: props.analizeType,
            eFlowId: props.eFlowId!,
          },
        },
      });
    } catch (ex) {
      captureException(ex);
    }
  };

  const changeEflowStepStatus = async (currentStatus: boolean) => {
    try {
      await updateEflowStep({
        variables: {
          input: {
            organizationId: companyId,
            id: props.analizeStep.id,
            eFlowId: props.eFlowId!,
            isActive: !currentStatus,
          },
        },
      });
    } catch (ex) {
      captureException(ex);
    }
  };

  const addToToBe = async () => {
    try {
      await cloneOperationsMutation({
        variables: {
          input: {
            eFlowId: props.eFlowId!,
            organizationId: companyId,
            eFlowStepsIds: [props.analizeStep.id],
            desiredEflowStartPosition: desiredEflowStartPosition,
          },
        },
      });
    } catch (ex) {
      captureException(ex);
    }
  };

  return {
    toBeCount,
    isModalVisible,
    selectedModalOption,
    desiredEflowStartPosition,
    addToToBe,
    moveStepPosition,
    setIsModalVisible,
    changeEflowStepStatus,
    setSelectedModalOption,
    setDesiredEflowStartPosition,
  };
};
