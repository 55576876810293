import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography, styled, useTheme } from '@mui/material';

import { useGetEmployeeQuery } from '@/__generated__/graphql';
import { EmployeeAvatar, MenuNavLink } from '@/core/components';
import { useOrganizationStore, useUserStore } from '@/stores';
import { theme } from '@/theme';

interface IProps {
  back?: string;
  menu?: { title: string; to: string }[];
  title: string;
}

export const UserSidebarContent: FC<IProps> = ({ back, menu, title }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { companyName, id } = useParams();
  const { organization } = useOrganizationStore();

  const userStore = useUserStore();

  const { data } = useGetEmployeeQuery({
    variables: {
      input: {
        id: id!,
        organizationId: organization[companyName!] || userStore.organizationId!,
      },
    },
  });

  return (
    <SidebarContent>
      <TitleRow>
        <Box>
          {back && (
            <ArrowBackIcon
              onClick={() => navigate(back)}
              style={{
                marginTop: '2px',
                color: theme.palette.text.primary,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
        <Typography fontSize={14} variant="bodyMedium">
          {title}
        </Typography>
      </TitleRow>
      <EmployeeInfo>
        <EmployeeAvatar
          name={data?.getEmployee?.firstName || ''}
          imageUrl={data?.getEmployee?.profilePicture || ''}
          size={48}
        />
        <UserData>
          <Typography fontSize={14} variant="bodyMedium">
            {data?.getEmployee?.firstName} {data?.getEmployee?.lastName}
          </Typography>
          <Typography
            fontSize={14}
            variant="bodyRegular"
            color={theme.palette.text.secondary}
          >
            {data?.getEmployee?.email}
          </Typography>
        </UserData>
      </EmployeeInfo>
      <Menu>
        {menu &&
          menu.map(({ title, to }) => (
            <MenuNavLink key={title} title={title} to={to} />
          ))}
      </Menu>
    </SidebarContent>
  );
};

const SidebarContent = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 12px 32px 12px',
  rowGap: '24px',
});

const TitleRow = styled(Box)({
  height: '40px',
  width: 'auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '8px',
  padding: '0px 8px',
  fontSize: '1.2em',
  color: theme.palette.text.primary,
});

const EmployeeInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  gap: '8px 16px',
  alignSelf: 'stretch',
  padding: '0px 12px',
  flexWrap: 'wrap',
  minHeight: 48,
});

const UserData = styled(Box)({
  width: 'auto',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
});

const Menu = styled(Box)({
  display: 'flex',
  paddingTop: '8px',
  flexDirection: 'column',
  width: '100%',
  a: {
    display: 'flex',
    padding: '10px 12px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    width: '100%',
    lineHeight: '20px',
    textDecoration: 'none',
    borderRadius: 'var(--radius-basic, 8px)',
    color: theme.palette.text.primary,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&.active': {
      background: `var(--primary-primary-50, ${theme.palette.primary[50]})`,
      fontWeight: 500,
    },
  },
});
