import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Typography, styled } from '@mui/material';

import { Employee } from '@/__generated__/graphql';
import {
  Button,
  PaginationFilterLayout,
  RemoveItemModal,
  Snackbar,
  UserTable,
} from '@/core/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { theme } from '@/theme';

import { PermissionsModuleContext } from '../context';
import { AddPermissionsModal } from '../modals/AddPermissionsModal';
import { getCells } from './getCells';
import { useUsers } from './useUsers';

export const Users = () => {
  const { t } = useTranslation();

  const { t: permissionT } = useTranslation('permissions');

  const permissionContext = useContext(PermissionsModuleContext);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const {
    isLoading,
    employees,
    totalEmployees,
    search,
    setSearch,
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,
    organizationUnit,
    setOrganizationUnit,
    setSystemRoles,
    selectedRows,
    setSelectedRows,
    handleRemovePermissionButtonClick,
    usersToRemove,
    setUsersToRemove,
    handleRemovePermission,
    isModalVisible,
    setIsModalVisible,
    modalContent,
    setModalContent,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
    openSnackbar,
  } = useUsers();

  useEffect(() => {
    if (permissionContext) setSystemRoles(permissionContext.possibleRoles);
  }, [permissionContext, setSystemRoles]);

  return (
    <>
      <ActionContainer>
        <PaginationFilterLayout
          search={search}
          setSearch={setSearch}
          organizationUnit={organizationUnit}
          setOrganizationUnit={setOrganizationUnit}
        />
        <FieldsContainer>
          {selectedRows.length > 0 && (
            <RemovePermissionButton
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={handleRemovePermissionButtonClick}
            >
              <Typography>{permissionT('flow.users.removeAccess')}</Typography>
            </RemovePermissionButton>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsAddModalVisible(true)}
          >
            <Typography>{t('employees.addEmployees')}</Typography>
          </Button>
        </FieldsContainer>
      </ActionContainer>
      <UserTable
        employees={employees as Employee[]}
        total={totalEmployees!}
        isLoading={isLoading}
        cursor={cursor}
        setCursor={(newValue) => setCursor(newValue)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(newValue) => setRowsPerPage(newValue)}
        onSelectedRowsChange={(selectedIds) =>
          setSelectedRows([...selectedIds])
        }
        onEmployeeModalOpen={(employee) => {
          setUsersToRemove([employee.id]);
          setModalContent(
            <>
              {permissionT('flow.users.removeSingleModal')}
              <strong>
                {employee.firstName} {employee.lastName}
              </strong>
              ?
            </>,
          );
          setIsModalVisible(true);
        }}
        noEmployeesInfoText={permissionT('flow.users.removeAccess')}
        isMenuVisible
        noEmployeesButtonText={permissionT('flow.users.removeAccess')}
        cells={getCells(t)}
      />

      <AddPermissionsModal
        open={isAddModalVisible}
        openSnackbar={(message: string) => {
          openSnackbar(message);
        }}
        closeModal={() => setIsAddModalVisible(false)}
      />
      <RemoveItemModal
        headerText={permissionT('flow.users.removeAccess')}
        content={modalContent}
        display={isModalVisible}
        onRemove={() => void handleRemovePermission(usersToRemove)}
        closeModal={() => setIsModalVisible(false)}
      />
      <Snackbar
        open={isSnackBarOpen}
        onClose={() => closeSnackbar()}
        message={snackBarMessage}
      />
    </>
  );
};

const ActionContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '16px',
});

const FieldsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  maxWidth: 600,
  gap: '12px',
});

const RemovePermissionButton = styled(PrimaryButton)({
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.common.black,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
});
