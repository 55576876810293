import type { ChartColors as ChartColorsVariants } from './PieChart.types';

export const ChartColors: { [key in ChartColorsVariants]: string[] } = {
  danger: ['rgba(255, 0, 0, 1)', 'rgba(255, 0, 0, 0.5)', 'rgba(0, 0, 0, 0)'],
  primary: ['rgba(0, 128, 0, 1)', 'rgba(0, 128, 0, 0.6)', 'rgba(0, 0, 0, 0)'],
  secondary: ['rgba(0, 0, 255, 1)', 'rgba(0, 0, 255, 0.5)', 'rgba(0, 0, 0, 0)'],
  warning: [
    'rgba(255, 255, 0, 1)',
    'rgba(255, 255, 0, 0.5)',
    'rgba(0, 0, 0, 0)',
  ],
};
