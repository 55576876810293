import { TFunction } from 'i18next';

import { SingleRadioModalOption } from '@core/components';

/**
 * Clone to be steps modal options enum
 * @enum {string}
 */
// eslint-disable-next-line react-refresh/only-export-components
export enum CloneToBeStepsModalOptionsEnum {
  /**
   * Copy operation
   */
  CopyOperation = 'CopyOperation',
  /**
   * Copy operation comments and attachments
   */
  CopyOperationCommentsAndAttachments = 'CopyOperationCommentsAndAttachments',
}

/**
 * Clone to be steps modal options
 * @param t - translation function
 */
export const CloneToBeStepsModalOptions = (
  t: TFunction<'eflow'>,
): SingleRadioModalOption[] => {
  return [
    {
      value: CloneToBeStepsModalOptionsEnum.CopyOperation,
      label: t('eflow.table.copyOperation'),
    },
    {
      value: CloneToBeStepsModalOptionsEnum.CopyOperationCommentsAndAttachments,
      label: t('eflow.table.copyOperationCommentsAndAttachments'),
    },
  ];
};
