import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FindAllSubmissionsDocument,
  FindAllSubmissionsInput,
  KaizenSubmissionFragmentFragment,
  useRemoveKaizenSubmissionMutation,
} from '@/__generated__/graphql';
import { client } from '@/apollo';
import { useSnackbarContext } from '@/core/contexts';
import { useCompanyId, useModal } from '@/core/hooks';

export const useRemoveKaizenSubmission = () => {
  const { t } = useTranslation('kaizen');

  const { isModalVisible, setIsModalVisible } = useModal();
  const { companyId } = useCompanyId();

  const [submissionId, setSubmissionId] = useState<string | null>(null);

  const [removeSubmission] = useRemoveKaizenSubmissionMutation();

  const { openSnackbar } = useSnackbarContext();

  const handleCancel = () => setIsModalVisible(false);

  const handleClickRemove = (id: string) => {
    setIsModalVisible(true);
    setSubmissionId(id);
  };

  const handleRemoveSubmission = async (
    currentInput: FindAllSubmissionsInput,
  ) => {
    if (!submissionId) return;

    await removeSubmission({
      variables: {
        input: {
          submissionId,
          organizationId: companyId,
        },
      },
    });

    openSnackbar(t('dashboard.submissionRemoved'));

    const res = client.readQuery({
      query: FindAllSubmissionsDocument,
      variables: {
        input: currentInput,
      },
    });

    if (res) {
      const items = res.findAllKaizenSubmissions.items.filter(
        (submission: KaizenSubmissionFragmentFragment) =>
          submission.id !== submissionId,
      );

      client.writeQuery({
        query: FindAllSubmissionsDocument,
        variables: {
          input: currentInput,
        },
        data: {
          findAllKaizenSubmissions: {
            total: res.findAllKaizenSubmissions.total - 1,
            items,
          },
        },
      });
    }

    setIsModalVisible(false);
  };

  return {
    isModalVisible,
    handleCancel,
    handleClickRemove,
    handleRemoveSubmission,
  };
};
