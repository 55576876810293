import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  KaizenEditableStatuses,
  KaizenEditableStatusesOrder,
} from '@kaizen/common/KaizenEditableStatuses.enum.ts';
import { Scoring } from '@kaizen/module-settings/Points/Points.types.ts';
import {
  EditPointsModalFormTypes,
  useEditPointsModalProps,
} from '@organizations/components/EditPointsModal/EditPointsModal.types.ts';

import { useCreateKaizenScoringMutation } from '@/__generated__/graphql.ts';
import { useOrganizationStore, useUserStore } from '@/stores';

import { EditPointsModalTableRow } from './EditPointsModalTableRow';
import { writeQuery } from './Query';

export const useEditPointsModal = (props: useEditPointsModalProps) => {
  const { companyName } = useParams();
  const { organizationId } = useUserStore();

  const { organization } = useOrganizationStore();
  const [pointsData, setPointsData] = useState<EditPointsModalFormTypes>(
    props.pointsData,
  );
  const [createKaizenScoring] = useCreateKaizenScoringMutation();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditPointsModalFormTypes>({
    defaultValues: pointsData,
  });

  const onSubmit: SubmitHandler<EditPointsModalFormTypes> = useCallback(
    async (data) => {
      try {
        const response = await createKaizenScoring({
          variables: {
            input: {
              organizationId: organization[companyName!],
              scoring: data,
            },
          },
        });

        if (response.data?.createKaizenScoring) {
          const scoring = response.data.createKaizenScoring.scoring as Scoring;
          props.setTableData({
            SUPERVISOR_ASSESSMENT: scoring.SUPERVISOR_ASSESSMENT || 0,
            COMMISSION_ASSESSMENT: scoring.COMMISSION_ASSESSMENT || 0,
            ACCEPTED: scoring.ACCEPTED || 0,
            TODO: scoring.TODO || 0,
            IMPLEMENTED: scoring.IMPLEMENTED || 0,
          });
          writeQuery(scoring, organizationId!);
        }
      } catch (err) {
        captureException(err);
      } finally {
        props.closeModal();
      }
    },
    // eslint-disable-next-line
    [],
  );

  const renderRows = useCallback(() => {
    return KaizenEditableStatusesOrder.map((status) => (
      <EditPointsModalTableRow
        key={status}
        setValue={setValue}
        pointsValue={pointsData[status] || 0}
        status={status}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointsData]);

  useEffect(() => {
    const scoringData: EditPointsModalFormTypes = Object.keys(
      KaizenEditableStatuses,
    ).reduce((acc, status) => {
      acc[status as KaizenEditableStatuses] =
        props.pointsData[status as KaizenEditableStatuses] || 0;
      return acc;
    }, {} as EditPointsModalFormTypes);

    setPointsData(scoringData);
  }, [props.pointsData]);

  return {
    handleSubmit,
    setValue,
    errors,
    onSubmit,
    pointsData,
    renderRows,
  };
};
