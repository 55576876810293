import { Clear } from '@mui/icons-material';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { CommentsTableProps } from './CommentsModule.types';
import { useBasicComments } from './useBasicComments';

export const CommentsTable = ({
  symbolsArray,
  removeComment,
}: CommentsTableProps) => {
  const basicComments = useBasicComments();
  return (
    <TableContainer>
      <Table size="medium">
        <TableBody>
          {basicComments.map((comment) => (
            <TableRow key={comment.symbol + comment.categoryName}>
              <TableCell
                sx={{
                  width: '60px',
                }}
              >
                <Typography variant="bodyRegular">{comment.symbol}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="bodyRegular">
                  {comment.categoryName}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
          {symbolsArray.map((symbol, i) => (
            <TableRow key={symbol.symbol}>
              <TableCell
                sx={{
                  width: '60px',
                }}
              >
                <Typography variant="bodyRegular">{symbol.symbol}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="bodyRegular">
                  {symbol.categoryName}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  sx={{ width: '40px' }}
                  onClick={() => removeComment(i)}
                >
                  <Clear />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
