import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Skeleton, Stack, Typography, useTheme } from '@mui/material';

import {
  Organization,
  useFindSingleOrganizationQuery,
  useUpdateOrganizationLogoMutation,
} from '@/__generated__/graphql';
import { EmployeeAvatar, ImageUpload } from '@/core/components';
import { OrganizationEditTypes } from '@/core/types';

import {
  CardStack,
  OrganizationNameSection,
} from './OrganizationDataComponents';

export const OrganizationData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();

  const companyName = params.companyName || '';

  const [organization, setOrganization] =
    useState<Pick<Organization, 'id' | 'name' | 'url'>>();

  const { data, loading: isLoading } = useFindSingleOrganizationQuery({
    variables: {
      input: {
        url: companyName,
      },
    },
  });

  const [image, setImage] = useState<string | null>(null);
  const [updateLogoMutation] = useUpdateOrganizationLogoMutation();

  useEffect(() => {
    if (data) {
      setOrganization(data.findSingleOrganization);
      setImage((data.findSingleOrganization.logo as string) || null);
    }
  }, [data]);

  const [edit, setEdit] = useState<OrganizationEditTypes>(
    OrganizationEditTypes.None,
  );

  const updateLogo = async (file: File | null) => {
    try {
      const res = await updateLogoMutation({
        variables: {
          input: {
            id: organization!.id,
          },
          file,
        },
      });

      setImage((res.data?.updateOrganisation.logo as string) || null);
    } catch (error) {
      captureException(error);
    }
  };

  return (
    <>
      <Typography variant="h3">
        {t('organizationSettings.organizationData')}
      </Typography>
      <Stack alignItems="flex-start">
        {organization ? (
          <OrganizationNameSection
            isLoading={isLoading}
            organization={organization}
          />
        ) : (
          <Skeleton width={501} height={40} />
        )}
        <CardStack>
          <Stack display="flex" flexDirection="row" alignItems="center">
            <Typography
              textAlign="left"
              variant="bodyRegular"
              width="160px"
              color={theme.palette.grey[900]}
            >
              {t('organization.url')}
            </Typography>
            {isLoading ? (
              <Skeleton width={501} height={40} />
            ) : (
              <Typography variant="bodyRegular" textAlign="left" width="373px">
                {organization?.url}
              </Typography>
            )}
          </Stack>
        </CardStack>
        <CardStack>
          <Stack display="flex" flexDirection="row" alignItems="center">
            <Typography
              textAlign="left"
              variant="bodyRegular"
              width="160px"
              color={theme.palette.grey[900]}
            >
              {t('common.logo')}
            </Typography>
            <Typography variant="bodyRegular" textAlign="left" width="373px">
              <EmployeeAvatar
                name="L"
                imageUrl={image || undefined}
                size={32}
              />
            </Typography>
          </Stack>
          <PrimaryButton onClick={() => setEdit(OrganizationEditTypes.Logo)}>
            <Typography
              variant="bodyMedium"
              fontSize="14px"
              color={theme.palette.common.black}
            >
              {t('common.edit')}
            </Typography>
          </PrimaryButton>
          <ImageUpload
            isOpen={edit === OrganizationEditTypes.Logo}
            onClose={() => setEdit(OrganizationEditTypes.None)}
            onUpload={updateLogo}
            onRemove={() => {
              void (async () => {
                setImage(null);
                await updateLogo(null);
              })();
            }}
            title={`${t('common.edit')} ${t('common.logo').toLocaleLowerCase()}`}
            imageUrl={image}
            selectImageLabel={t('organizationSettings.logo.select')}
            changeImageLabel={t('organizationSettings.logo.change')}
            removeImageLabel={t('common.delete')}
            altImageText="logo"
            placeholderLetter={organization?.name?.charAt(0)}
          />
        </CardStack>
      </Stack>
    </>
  );
};
