import { ListItemButtonTypes } from '@organizations/components/ListItemButton/ListItemButton.types.ts';
import { useListItemButton } from '@organizations/components/ListItemButton/hooks/useListItemButton.tsx';

import { ListItemButton, Typography, styled } from '@mui/material';

import { useOrganizationSettingsRedirects } from '@core/redirects/useOrganizationSettingsRedirects.ts';

export const SettingsListItemButton = (props: ListItemButtonTypes) => {
  const { navigateTo, isPathSelected } = useOrganizationSettingsRedirects();
  const { title, hasModule } = useListItemButton(props);

  if (!hasModule) {
    return null;
  }

  return (
    <CustomListItemButton
      sx={{
        pl: 4,
      }}
      selected={isPathSelected(props.organizationModule)}
      onClick={() => {
        navigateTo(props.organizationModule);
      }}
    >
      <Typography variant="bodyRegular">{title}</Typography>
    </CustomListItemButton>
  );
};

const CustomListItemButton = styled(ListItemButton)({
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});
