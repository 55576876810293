import { useGetDocumentType } from '@e-flow/pages/flowDashboard/hooks/useCacheUpdate/hooks/useGetDocumentType.ts';

import {
  AsIsToBeNamesEnum,
  EflowStepFragmentFragment,
} from '@/__generated__/graphql.ts';
import { SortArrayBasedOnStringArray } from '@/utils/SortArrayBasedOnStringArray.ts';

import { readQuery, writeQuery } from '../Functions';

/**
 * Function to update eflow step
 * @param eFlowId
 * @param organizationId
 */
export const useUpdateEflowStepCache = (
  eFlowId: string,
  organizationId: string,
) => {
  const { getRecordStepType, getDocumentType } = useGetDocumentType();

  const updateEflowStep = (
    analizeType: AsIsToBeNamesEnum,
    eFlowStep: EflowStepFragmentFragment,
    position: number,
    idsArray?: string[],
  ) => {
    const document = getDocumentType(analizeType);

    const currentQueryStateClone = readQuery(document, eFlowId, organizationId);
    if (!currentQueryStateClone) return;

    const recordStepsType = getRecordStepType(analizeType);

    if (
      currentQueryStateClone.findOneEFlow[recordStepsType][position]?.id ===
      eFlowStep.id
    ) {
      currentQueryStateClone.findOneEFlow[recordStepsType][position] =
        eFlowStep;
    } else {
      if (!currentQueryStateClone.findOneEFlow[recordStepsType][position]) {
        currentQueryStateClone.findOneEFlow[recordStepsType][position] =
          eFlowStep;
      } else {
        currentQueryStateClone.findOneEFlow[recordStepsType].splice(
          position,
          0,
          eFlowStep,
        );
      }

      if (idsArray)
        currentQueryStateClone.findOneEFlow[recordStepsType] =
          SortArrayBasedOnStringArray(
            idsArray,
            currentQueryStateClone.findOneEFlow[
              recordStepsType
            ] as EflowStepFragmentFragment[],
            'id',
          );
    }

    writeQuery(document, currentQueryStateClone, eFlowId, organizationId);
  };

  return { updateEflowStep };
};
