import { TFunction } from 'i18next';

import { Avatar } from '@mui/material';

import { Employee } from '@/__generated__/graphql';

import { Row } from '../../../../components/UserEdit/style';

export const getCells = (commonT: TFunction) => {
  return [
    {
      key: 'employee',
      label: commonT('employee.employee'),
      content: (employee: Employee) => (
        <Row>
          <Avatar
            src={employee.profilePicture || ''}
            alt={employee.firstName}
          />
          <span>
            {employee.firstName} {employee.lastName}
          </span>
        </Row>
      ),
    },
    {
      key: 'email',
      label: commonT('employees.email'),
    },
    {
      key: 'organizationUnit',
      label: commonT('employee.unit'),
      content: (employee: Employee) => employee.organizationUnit?.name ?? '',
    },
  ];
};
