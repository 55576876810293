import { SyntheticEvent, useState } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, styled } from '@mui/material';

import { TabViewWrapperProps } from './types';

export const TabViewWrapper = (props: TabViewWrapperProps) => {
  const [currentTab, setCurrentTab] = useState('0');

  return (
    <Content>
      <TabContext value={currentTab}>
        <Box
          // PLS don't move this and don't comment. If it is styled, it doesn't work (don't know why it just does)
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
          }}
        >
          <TabList
            onChange={(_event: SyntheticEvent, newValue: string) =>
              setCurrentTab(newValue)
            }
          >
            {props.tabs.map((tab, index) => (
              <CustomTab
                key={index}
                label={tab.tabName}
                value={index.toString()}
              />
            ))}
          </TabList>
        </Box>
        {props.tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={index.toString()}>
            {tab.tabComponent}
          </CustomTabPanel>
        ))}
      </TabContext>
    </Content>
  );
};

const Content = styled(Box)({
  width: '100%',
  height: 'auto',
});

const CustomTab = styled(Tab)({
  textTransform: 'none',
});
const CustomTabPanel = styled(TabPanel)({
  padding: 0,
});
