import { DocumentNode } from 'graphql/language';

import { client } from '@/apollo.ts';

/**
 * Function to write a query of eflow step
 * @param query DocumentNode
 * @param data unknown - data to write
 * @param eFlowId string
 * @param organizationId string
 */
export const writeQuery = (
  query: DocumentNode,
  data: unknown,
  eFlowId: string,
  organizationId: string,
) => {
  if (!data) return;

  client.writeQuery({
    query,
    variables: {
      input: {
        id: eFlowId,
        organizationId,
      },
    },
    data,
  });
};
