import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkGroupModuleTypes } from '@e-flow/pages/newAnalysis/new-analysis-modules/WorkGroupModule/types';

import { Stack, Typography } from '@mui/material';

import { WorkGroupInput, WorkGroupModel } from '@/__generated__/graphql.ts';
import { CustomModal } from '@/core/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { RowStack } from '@/pages/organizations/styles';
import { theme } from '@/theme';

import { AddLocalEmployeeModal } from './Modals/index.ts';
import { SelectEmployeeModal } from './SelectEmployeeModal.tsx';
import { EmployeeTable } from './tables/EmployeeTable.tsx';

export const WorkGroupModule = ({
  moveNext,
  stepBack,
  selectedWorkGroupEflowMembers,
  setSelectedWorkGroupEflowMembers,
  currentFormValues,
  setMainAnalysisPart,
}: WorkGroupModuleTypes) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  const [visibleModal, setVisibleModal] = useState<
    'ecpsEmployee' | 'localEmployee' | 'none'
  >('none');

  const [localRows, setLocalRows] = useState<WorkGroupModel[]>([]);
  const [transformedEcpsSelectedRows, setTransformedEcpsSelectedRows] =
    useState<WorkGroupInput[]>([]);

  useEffect(() => {
    setTransformedEcpsSelectedRows(
      Object.values(selectedWorkGroupEflowMembers).map((row) => {
        return {
          id: row.id,
          nameAndSurname: `${row.firstName} ${row.lastName}`,
        } as WorkGroupInput;
      }),
    );
  }, [selectedWorkGroupEflowMembers]);

  useEffect(() => {
    if (currentFormValues.workGroupUsers)
      setTransformedEcpsSelectedRows(currentFormValues.workGroupUsers);
  }, [currentFormValues]);

  useEffect(() => {
    if (currentFormValues.workGroupUsers)
      setTransformedEcpsSelectedRows(currentFormValues.workGroupUsers);
  }, [currentFormValues.workGroupUsers]);

  /**
   * Handle logic related to adding new user to a selected array
   * @param row
   * @param index
   */
  const handleDeleteRow = (row: WorkGroupModel, index: number) => {
    if (row.id) {
      setSelectedWorkGroupEflowMembers((prevState) => {
        const newState = { ...prevState };
        delete newState[row.id!];
        return newState;
      });
    } else {
      setLocalRows((prevState) => {
        const newState = [...prevState];
        newState.splice(index, 1);
        return newState;
      });
    }
  };

  const handleRowSubmit = (callback: () => void) => {
    setMainAnalysisPart({
      workGroupUsers: [...localRows, ...transformedEcpsSelectedRows],
    });
    callback();
  };

  return (
    <>
      <Stack gap="24px" padding={'24px'} maxWidth={'768px'}>
        <Typography variant="h3">{t('newAnalysis.groups.title')}</Typography>
        <Typography variant="bodyRegular">
          {t('newAnalysis.groups.description')}
        </Typography>

        {/* add local options */}
        <Stack flexDirection="row" gap={'12px'} flexWrap={'wrap'}>
          <PrimaryButton
            sx={{
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.common.black,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
              },
            }}
            onClick={() => setVisibleModal('localEmployee')}
          >
            {/*add local employee*/}
            <Typography>
              {t('newAnalysis.groups.addLocalEmployeeButton')}
            </Typography>
          </PrimaryButton>
          <PrimaryButton
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary[600],
            }}
            onClick={() => setVisibleModal('ecpsEmployee')}
          >
            <Typography>
              {t('newAnalysis.groups.addEmployeeFromESchema')}
            </Typography>
          </PrimaryButton>
        </Stack>

        {/* employee table */}
        <EmployeeTable
          rows={[...localRows, ...transformedEcpsSelectedRows]}
          handleDeleteRow={handleDeleteRow}
        />

        {/* back and continue button */}
        <RowStack gap="12px">
          <PrimaryButton
            onClick={() => handleRowSubmit(stepBack)}
            sx={{
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.common.black,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
              },
            }}
          >
            <Typography variant="bodyRegular">
              {commonT('organization.back')}
            </Typography>
          </PrimaryButton>
          <PrimaryButton
            disabled={
              localRows.length === 0 && transformedEcpsSelectedRows.length === 0
            }
            onClick={() => {
              handleRowSubmit(moveNext);
            }}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary[600],
            }}
          >
            <Typography variant="bodyRegular">
              {commonT('common.continue')}
            </Typography>
          </PrimaryButton>
        </RowStack>
      </Stack>

      {/* modals */}
      <CustomModal display={visibleModal === 'ecpsEmployee'}>
        {/*add schema user*/}
        <SelectEmployeeModal
          close={() => setVisibleModal('none')}
          setSelectedRows={setSelectedWorkGroupEflowMembers}
          selectedRows={selectedWorkGroupEflowMembers}
          disabledEmployees={currentFormValues.analyzeLeadersIds}
        />
      </CustomModal>

      <CustomModal display={visibleModal === 'localEmployee'}>
        <AddLocalEmployeeModal
          close={() => setVisibleModal('none')}
          addEmployee={(nameAndSurname: string) => {
            setLocalRows((prevState) => [
              ...prevState,
              { id: null, nameAndSurname },
            ]);
          }}
          localEmployees={localRows}
        />
      </CustomModal>
    </>
  );
};
