import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { sortModules } from '@organizations/pages/organizationSettings/utils.ts';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { Box, Divider, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/styles';

import { NavbarModules } from '@core/components/NavbarMenuItems/NavbarModules.tsx';
import { useMenu, useOrganizationModule } from '@core/hooks';
import { useGlobalRedirects } from '@core/redirects';

export const NavbarMenuItems = () => {
  const { t } = useTranslation('navbar');
  const { handleMenuClose } = useMenu();
  const { homePagePath } = useGlobalRedirects();

  const navigate = useNavigate();

  const { filteredOrganizationModules } = useOrganizationModule();

  return (
    <div>
      <ItemsContainer>
        <NavbarModules modules={sortModules(filteredOrganizationModules)} />
      </ItemsContainer>

      <Divider />

      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate(homePagePath);
        }}
        sx={{ height: 35, display: 'flex', marginTop: 1 }}
      >
        <DashboardOutlinedIcon />
        <Typography style={{ marginLeft: 10 }}>
          {t('homePage.homePage')}
        </Typography>
      </MenuItem>
    </div>
  );
};

const ItemsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  width: 320,
  padding: 16,
});
