import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Stack, Typography, styled } from '@mui/material';

import {
  CustomModal,
  ModalContainer,
  PaginationFilterLayout,
  TextButton,
  UserTable,
} from '@core/components';

import {
  Employee,
  ModuleNamesEnum,
  useGetEmployeesForOrganizationLazyQuery,
} from '@/__generated__/graphql';
import { useCompanyId, useFetchEmployees } from '@/core/hooks';
import { PrimaryButton, RowContainer } from '@/pages/e-schema/styles/Employee';

import { PermissionsModuleContext } from '../../context';
import { useUpdatePermission } from '../../hooks/useUpdatePermission';
import { AddPermissionsModalTypes } from './AddPermissionsModal.types';
import { getCells } from './getCells';

export const AddPermissionsModal = ({
  closeModal,
  open,
  openSnackbar,
}: AddPermissionsModalTypes) => {
  const { t: commonT } = useTranslation();
  const { t } = useTranslation('eflow');

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const context = useContext(PermissionsModuleContext);

  const possibleRoles = context?.possibleRoles || [];

  const { companyId } = useCompanyId();
  const getEmployees = useGetEmployeesForOrganizationLazyQuery();

  const {
    search,
    setSearch,
    organizationUnit,
    setOrganizationUnit,
    employees,
    totalEmployees,
    cursor,
    setCursor,
    isLoading,
    rowsPerPage,
    setRowsPerPage,
  } = useFetchEmployees(companyId, getEmployees);

  const { addPermissions } = useUpdatePermission({
    organizationId: companyId,
    roleName: possibleRoles[0],
    moduleName: context?.moduleName as ModuleNamesEnum,
  });

  return (
    <CustomModal display={open}>
      <ModalContainer
        width="75vw"
        sx={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
        }}
        closeModal={closeModal}
        headerText={commonT('organization.moduleAccess')}
      >
        <ContentContainer>
          <RowContainer>
            <PaginationFilterLayout
              search={search}
              setSearch={setSearch}
              organizationUnit={organizationUnit}
              setOrganizationUnit={setOrganizationUnit}
            />
          </RowContainer>
          <UserTable
            employees={employees as Employee[]}
            total={totalEmployees || 0}
            isLoading={isLoading}
            cursor={cursor}
            setCursor={(newValue) => setCursor(newValue)}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(newValue) => setRowsPerPage(newValue)}
            onSelectedRowsChange={(selectedIds) =>
              setSelectedRows([...selectedIds])
            }
            itemSelected={(employee) =>
              employee.systemRole.some((role) => possibleRoles.includes(role))
            }
            shouldDisableRow={(employee) =>
              employee.systemRole.some((role) => possibleRoles.includes(role))
            }
            cells={getCells(commonT)}
            noEmployeesInfoText={t('newAnalysis.groups.noEmployees')}
          />
        </ContentContainer>
        <Divider />
        <RowContainer>
          <Typography>
            {commonT('organization.selectedEmployeesCounterLabel', {
              count: selectedRows.length,
            })}
          </Typography>
          <CustomButtonsContainer>
            <TextButton
              text={commonT('common.cancel')}
              width="auto"
              onClick={closeModal}
            />
            <PrimaryButton
              variant="contained"
              onClick={() =>
                void (async () => {
                  await addPermissions(selectedRows);
                  openSnackbar(
                    commonT('organizationSettings.addPermissionConfirmation'),
                  );
                  closeModal();
                })()
              }
            >
              <Typography>{commonT('organization.grantAccess')}</Typography>
            </PrimaryButton>
          </CustomButtonsContainer>
        </RowContainer>
      </ModalContainer>
    </CustomModal>
  );
};

const ContentContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

const CustomButtonsContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'end',
  gap: '12px',
});
