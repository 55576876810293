import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee.ts';

import { Box, styled } from '@mui/material';

import { theme } from '@/theme';

import { ButtonGroupConfirmCancelTypes } from './ButtonGroupConfirmCancel.types.ts';

export const ButtonGroupConfirmCancel = (
  props: ButtonGroupConfirmCancelTypes,
) => {
  const { t } = useTranslation();

  if (!props.isVisible) return null;

  return (
    <StyledContainer>
      <StyledCancelButton onClick={props.onCancel}>
        {t('common.cancel')}
      </StyledCancelButton>
      <PrimaryButton type={'submit'} variant={'contained'}>
        {t('common.save')}
      </PrimaryButton>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '10px',
});

const StyledCancelButton = styled(PrimaryButton)({
  color: theme.palette.text.primary,
});
