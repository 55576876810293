import { OrganizationModules } from '@/__generated__/graphql';

const getModulesData = [
  {
    icon: '/assets/menu-structure-logo.svg',
    moduleName: OrganizationModules.ESchema,
  },
  {
    icon: '/assets/menu-flow-logo.svg',
    moduleName: OrganizationModules.EFlowAnalize,
  },
  {
    icon: '/assets/menu-kaizen-logo.svg',
    moduleName: OrganizationModules.Kaizen,
  },
  {
    icon: '/assets/menu-kpi-logo.svg',
    moduleName: OrganizationModules.Kpi,
  },
  {
    icon: '/assets/project-icon-module.svg',
    moduleName: OrganizationModules.Project,
  },
  {
    icon: '/assets/plan-icon-module.svg',
    moduleName: OrganizationModules.ActionPlan,
  },
  {
    icon: '/assets/calendar-icon-module.svg',
    moduleName: OrganizationModules.Calendar,
  },
  {
    icon: '/assets/matryce-icon-module.svg',
    moduleName: OrganizationModules.Matrix,
  },
  {
    icon: '/assets/audit5s-icon-module.svg',
    moduleName: OrganizationModules.Audit5s,
  },
  {
    icon: '/assets/opexity-icon-module.svg',
    moduleName: OrganizationModules.Opexity,
  },
  {
    icon: '/assets/digitaltwin-icon-module.svg',
    moduleName: OrganizationModules.DigitalTwin,
  },
  {
    icon: '/assets/times-icon-module.svg',
    moduleName: OrganizationModules.TiMes,
  },
  {
    icon: '/assets/ai-icon-module.svg',
    moduleName: OrganizationModules.Ai,
  },
];
export default getModulesData;
