import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DeleteOutline,
  PersonOutline,
  SwapVertOutlined,
} from '@mui/icons-material';
import { Menu } from '@mui/material';

import { CustomMenuItem } from '@/core/components/index.ts';
import { useOrganizationStore, useUserStore } from '@/stores/index.ts';
import { userIsPermitted } from '@/utils/index.ts';

import { MenuProps } from './types.ts';

export const CustomMenu = ({
  anchorEl,
  open,
  handleClose,
  setDeleteModalOpen,
  setChangeCellModalOpen,
  employeeId,
}: MenuProps) => {
  const { t } = useTranslation();

  const { userGroups, organizationId } = useUserStore();
  const { organization } = useOrganizationStore();
  const { companyName } = useParams();
  const navigate = useNavigate();

  const moreOptions = [
    {
      text: t('common.delete'),
      onClick: () => setDeleteModalOpen(),
      icon: <DeleteOutline />,
    },
    {
      text: t('employees.changeCell'),
      onClick: () => setChangeCellModalOpen(),
      icon: <SwapVertOutlined />,
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      {userIsPermitted(
        userGroups,
        organizationId!,
        organization[companyName!],
      ) &&
        moreOptions.map((option) => (
          <CustomMenuItem key={option.text} {...option} />
        ))}
      <CustomMenuItem
        text={t('employees.showProfile')}
        onClick={() => {
          navigate(`../employees/${employeeId}`);
        }}
        icon={<PersonOutline />}
      />
    </Menu>
  );
};
