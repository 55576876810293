import { useTranslation } from 'react-i18next';

import { SubmissionManagementCommonTypes } from '@kaizen/components/Buttons/SubmissionManagementCommon/SubmissionManagementCommon.types.ts';
import { useSupervisorAssessment } from '@kaizen/hooks/useSupervisorAssessment/useSupervisorAssessment.tsx';

import { Typography } from '@mui/material';

import { useSnackbarContext } from '@core/contexts';

import { LoadingButton } from '@/core/components';

export const SendSubmissionToSupervisorAssessmentButton = ({
  submissionId,
}: SubmissionManagementCommonTypes) => {
  const { t } = useTranslation();
  const { t: kaizenT } = useTranslation('kaizen');

  const { openSnackbar } = useSnackbarContext();

  const { sendSupervisorAssessment, isLoading } = useSupervisorAssessment();

  const sendToSupervisorAssessmentHandler = async () => {
    if (await sendSupervisorAssessment(submissionId))
      openSnackbar(kaizenT('updateStatus.successMessage'));
    else openSnackbar(kaizenT('updateStatus.errorMessage'));
  };

  return (
    <LoadingButton
      synchronizeText={t('common.send')}
      isLoading={isLoading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={sendToSupervisorAssessmentHandler}
    >
      <Typography variant="bodyRegular">{t('common.send')}</Typography>
    </LoadingButton>
  );
};
