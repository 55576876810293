import { createContext, useContext } from 'react';

import { useSingleReport } from '../hooks';
import { SingleReportContextProps } from './types';

export const SingleKpiReportContext = createContext<ReturnType<
  typeof useSingleReport
> | null>(null);

export const SingleKpiReportProvider = ({
  children,
  id,
  close,
  kpi,
  defaultDate,
}: SingleReportContextProps) => {
  const singleReport = useSingleReport(id, close, kpi, defaultDate);

  return (
    <SingleKpiReportContext.Provider value={singleReport}>
      {children}
    </SingleKpiReportContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useSingleKpiReportContext = () => {
  const context = useContext(SingleKpiReportContext);

  if (!context) {
    throw new Error(
      'useSingleKpiReportContext must be used within a SingleKpiReportProvider',
    );
  }

  return context;
};
