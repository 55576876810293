import dayjs from 'dayjs';
import { CreateEflowInput } from '@/__generated__/graphql.ts';

export const defaultValues: Pick<
  CreateEflowInput,
  'startDate' | 'analyzeLeadersIds'
> = {
  startDate: dayjs().toDate() as unknown as string,
  analyzeLeadersIds: [],
};
