import { captureException } from '@sentry/react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { CircularProgress, Typography } from '@mui/material';

import { useDeleteOrganizationUnitMutation } from '@/__generated__/graphql';
import {
  InfoModalContent,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { useOrganizationStore } from '@/stores';
import { theme } from '@/theme';

import { DeleteCellModalProps } from './types';
import { processUnitDeletedResult } from './utils';

export const DeleteCellModal: FC<DeleteCellModalProps> = ({
  closeModal,
  nodeId,
  name,
  onDeleteError,
  onUnitDeleted,
  openSnackbar,
}) => {
  const { t } = useTranslation();

  const { companyName } = useParams();

  const { organization } = useOrganizationStore();

  const [deleteOrganizationUnit, { loading: isLoading }] =
    useDeleteOrganizationUnitMutation();

  const handleDeleteUnit = useCallback(async () => {
    try {
      const response = await deleteOrganizationUnit({
        variables: {
          deleteOrganizationUnitInput: {
            id: nodeId,
            organizationId: organization[companyName!],
          },
        },
      });

      processUnitDeletedResult(
        response,
        onDeleteError,
        openSnackbar,
        onUnitDeleted,
        nodeId,
        t,
      );
    } catch (err) {
      openSnackbar(t('organizationSettings.deleteCellError'));
      captureException(err);
    } finally {
      closeModal();
    }
    //eslint-disable-next-line
  }, [
    closeModal,
    companyName,
    deleteOrganizationUnit,
    nodeId,
    onDeleteError,
    onUnitDeleted,
    openSnackbar,
    organization,
  ]);
  return (
    <ModalContainer
      closeModal={() => closeModal()}
      width={'528px'}
      headerText={t('organizationTree.removeCell')}
    >
      <InfoModalContent
        contentText={`${t('structure.removeCellModalText')} ${name}?`}
      >
        <TextButton
          text={t('common.cancel')}
          width="66px"
          onClick={() => closeModal()}
        />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <PrimaryButton
            variant="contained"
            onClick={() => {
              void handleDeleteUnit();
            }}
            sx={{
              backgroundColor: theme.palette.error[600],
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&:hover': {
                backgroundColor: theme.palette.error[700],
              },
            }}
          >
            <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
              {t('organizationTree.removeCell')}
            </Typography>
          </PrimaryButton>
        )}
      </InfoModalContent>
    </ModalContainer>
  );
};
