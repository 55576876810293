import { captureException } from '@sentry/react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddKpiRateContext } from '@kpi/contexts';
import { omit, pick } from 'lodash';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import {
  UpdateKpiRateInput,
  useUpdateKpiRateMutation,
} from '@/__generated__/graphql.ts';

export const useUpdateKpiRateNetwork = () => {
  const { t } = useTranslation('kpi');

  const { companyId } = useCompanyId();
  const { openSnackbar } = useSnackbarContext();

  const { kpiRate } = useAddKpiRateContext();

  const [updateKpiRate, { loading: isLoading }] = useUpdateKpiRateMutation();

  const submitUpdateData: SubmitHandler<
    Omit<UpdateKpiRateInput, 'id'>
  > = async (kpiRateToUpdate) => {
    try {
      if (!kpiRate) return;
      const res = await updateKpiRate({
        variables: {
          input: {
            id: kpiRate.id,
            ...omit(kpiRateToUpdate, ['targetData', 'targetType']),
            organizationId: companyId,
            ...(kpiRateToUpdate.hasTarget && {
              ...pick(kpiRateToUpdate, ['targetData', 'targetType']),
            }),
          },
        },
      });
      if (res.data) {
        openSnackbar(t('addKpi.snackBarSuccessMessageUpdate'));
      }
    } catch (error) {
      captureException(error);
      openSnackbar(t('addKpi.snackBarFailMessageUpdate'));
    }
  };

  return {
    isLoading,
    submitUpdateData,
  };
};
