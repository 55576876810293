import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { RemoveItemModal } from '@/core/components';

import { ModulesTable } from './ModulesTable';
import { useOrganizationModules } from './hooks';

export const Modules = () => {
  const { t } = useTranslation();

  const {
    modules,
    moduleToInactive,
    setModuleToInactive,
    handleCheckboxClick,
    handleUpdateModules,
    isLoading,
    allowedModules,
  } = useOrganizationModules();

  return (
    <>
      <RemoveItemModal
        headerText={t('organizationSettings.removeModuleTitle')}
        content={t('organizationSettings.areYouSureRemoveModule', {
          moduleName: t(`tiles.${moduleToInactive!}`),
        })}
        onRemove={() => {
          handleUpdateModules(
            modules.filter((module) => module !== moduleToInactive),
          );
          setModuleToInactive(undefined);
        }}
        closeModal={() => setModuleToInactive(undefined)}
        display={!!moduleToInactive}
      />
      <Typography variant="h3">{t('organization.modules')}</Typography>

      <ModulesTable
        modules={allowedModules}
        isLoading={isLoading}
        handleCheckboxClick={handleCheckboxClick}
      />
    </>
  );
};
