import { useTranslation } from 'react-i18next';

import { SettlementPeriodsTable } from '@kaizen/module-settings/SettlementPeriods/components';
import { useKaizenSettlementPeriodContext } from '@kaizen/module-settings/SettlementPeriods/contexts';

import { Box, styled } from '@mui/material';

import { Button } from '@core/components';

import { KaizenSettlementPeriodFragment } from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

export const MainSettlementPeriodView = ({
  settlementPeriods,
}: {
  settlementPeriods: KaizenSettlementPeriodFragment[];
}) => {
  const { t } = useTranslation('kaizen');

  const { openCreateSettlementPeriodModal } =
    useKaizenSettlementPeriodContext();

  return (
    <MainContainer>
      <CustomStack>
        <Button
          data-testid="editButton"
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary[600],
          }}
          onClick={openCreateSettlementPeriodModal}
        >
          {t('settlementPeriod.addSettlementPeriod')}
        </Button>
      </CustomStack>
      <SettlementPeriodsTable settlementPeriods={settlementPeriods} />
    </MainContainer>
  );
};

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
  gap: '24px',
});

const CustomStack = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  alignItems: 'center',
});
