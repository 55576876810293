import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useBasicOperations } from '@e-flow/pages/newAnalysis/new-analysis-modules';

import { OptionsSelect } from '@core/components';

import {
  OperationSchema,
  OperationTypesEnum,
} from '@/__generated__/graphql.ts';

import { OperationsSelectTypes } from './OperationsSelect.types';

export const OperationsSelect = (props: OperationsSelectTypes) => {
  const operations: OperationSchema[] = useBasicOperations();

  const { t } = useTranslation('eflow');

  const translateOperations = useMemo(() => {
    const combinedOperations: OperationSchema[] = [
      ...operations,
      ...((props.items as OperationSchema[]) || []),
    ];

    return combinedOperations.map((operation) => ({
      translatedOperationName: t(`operationNames.${operation.operationType}`),
      operationType: operation?.operationType,
    }));
  }, [operations, props.items, t]);

  return (
    <OptionsSelect
      items={translateOperations}
      valueKey={'operationType'}
      labelKey={'translatedOperationName'}
      value={props.value as string}
      name={t('reports.balanceChart.operationTypes')}
      onChange={(event) =>
        props.onChange(event.target.value as OperationTypesEnum)
      }
    />
  );
};
