import React, { useCallback, useEffect, useState } from 'react';

import { styled, useTheme } from '@mui/material';

import { ISidebarProps } from '.';

export const Sidebar: React.FC<ISidebarProps> = ({
  children,
  resize = true,
  baseWidth = 300,
  withPadding = true,
  withGap = true,
}) => {
  const theme = useTheme();
  const [isResizing, setIsResizing] = useState(false);
  const [posX, setPosX] = useState(0);
  const [width, setWidth] = useState<number>(baseWidth);

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const handleResize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing) {
        setWidth(mouseMoveEvent.clientX - posX);
      }
    },
    [isResizing, posX],
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleResize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', handleResize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [handleResize, stopResizing]);

  return (
    <SidebarContainer
      width={width + 1}
      ref={(el) => {
        if (!el) return;
        setPosX(el.getBoundingClientRect().left);
      }}
    >
      <SidebarCom width={width} withPadding={withPadding} withGap={withGap}>
        {children}
      </SidebarCom>
      {resize ? (
        <ResizeHandle
          style={{
            backgroundColor: theme.palette.grey[200],
          }}
          sx={{ cursor: 'ew-resize' }}
          onMouseDown={startResizing}
        />
      ) : (
        <ResizeHandle
          style={{
            backgroundColor: theme.palette.grey[200],
          }}
        />
      )}
    </SidebarContainer>
  );
};

const SidebarContainer = styled('div')<{
  width: number;
}>(({ width }) => ({
  width,
  display: 'flex',
  position: 'relative',
  height: '100%',
  minWidth: '301px',
  maxWidth: '769px',
}));

const SidebarCom = styled('div')(
  ({
    width,
    withPadding,
    withGap,
  }: {
    width: number;
    withPadding: boolean;
    withGap: boolean;
  }) => ({
    flex: `0 0 ${width}px`,
    flexDirection: 'column',

    display: 'flex',
    minWidth: '300px',
    maxWidth: '768px',
    overflow: 'auto',
    ...(withPadding && {
      padding: '24px 24px 48px 24px',
    }),
    ...(withGap && { gap: '32px' }),
  }),
);

const ResizeHandle = styled('div')({
  width: '1px',
  height: '100%',
});
