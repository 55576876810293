import { ReactNode, createContext, useContext } from 'react';

import { useKpiTable } from '../KpiTable';
import { useKpiDashboardFilters } from '../hooks';

type KpiDashboardFiltersType = ReturnType<typeof useKpiDashboardFilters>;
type KpiTableType = ReturnType<typeof useKpiTable>;

export const KpiDashboardFiltersContext =
  createContext<KpiDashboardFiltersType | null>(null);

export const KpiTableContext = createContext<KpiTableType | null>(null);

export const KpiIndicatorsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const kpiDashboardFilters = useKpiDashboardFilters();

  return (
    <KpiDashboardFiltersContext.Provider value={kpiDashboardFilters}>
      <KpiTableProvider>{children}</KpiTableProvider>
    </KpiDashboardFiltersContext.Provider>
  );
};

// Separate KpiTableProvider
const KpiTableProvider = ({ children }: { children: ReactNode }) => {
  const kpiTable = useKpiTable();

  return (
    <KpiTableContext.Provider value={kpiTable}>
      {children}
    </KpiTableContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useKpiDashboardFiltersContext = () => {
  const context = useContext(KpiDashboardFiltersContext);

  if (!context) {
    throw new Error(
      'useKpiDashboardFiltersContext must be used within a KpiDashboardFiltersProvider',
    );
  }

  return context;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useKpiTableContext = () => {
  const context = useContext(KpiTableContext);

  if (!context) {
    throw new Error(
      'useKpiTableContext must be used within a KpiDashboardFiltersProvider',
    );
  }

  return context;
};
