import { FormControlLabel, MenuItem, Switch } from '@mui/material';

import { MenuItemWithSwitchTypes } from '@core/components/MenuItems/MenuItemsWithSwitch/MenuItemWithSwitch.types.ts';

export const MenuitemWithSwitch = (props: MenuItemWithSwitchTypes) => {
  return (
    <MenuItem>
      <FormControlLabel control={<Switch />} {...props} />
    </MenuItem>
  );
};
