import { useMemo, useState } from 'react';

import { EflowStep } from '@/__generated__/graphql';

export const useCountParameters = (stepsToDisplay: Array<EflowStep>) => {
  const [params, setParams] = useState({});

  useMemo(() => {
    if (!stepsToDisplay) return;

    const summedParams: { [key: string]: number } = {};

    stepsToDisplay.forEach((step) => {
      if (!step.parameters || !step.isActive) return;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(step.parameters).forEach((param) => {
        if (!summedParams[param]) {
          summedParams[param] = 0;
        }

        summedParams[param] += step.parameters[param].value;
      });
    });
    setParams(summedParams);
  }, [stepsToDisplay]);

  return params;
};
