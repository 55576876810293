import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';

import { theme } from '@/theme';

import { DeleteRowProps } from './DeleteRow.types';

export const DeleteRow = ({ showModal }: DeleteRowProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  //set Menu position to clicked component
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  //close Menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon
          fontSize="small"
          sx={{ color: theme.palette.grey[900] }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        sx={{ marginLeft: '-60px' }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            showModal();
          }}
          sx={{ width: '90px', height: '40px' }}
        >
          <DeleteOutlineIcon fontSize="small" />
          <Typography
            variant="bodyRegular"
            sx={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            {t('common.delete')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
