import { TableCell, Typography, styled } from '@mui/material';

export const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
export const CustomCell = styled(TableCell)({
  padding: '5px 16px',
});
