import { FC } from 'react';

import { omit } from 'lodash';

import { Box, styled } from '@mui/material';

import { LabeledPieChartProps } from '@core/components/Statistica/LabeledPieChart/ChartComponent.types.ts';

import { theme } from '@/theme';

import { PieChart } from '../PieChart/PieChart';

export const LabeledPieChart: FC<LabeledPieChartProps> = (props) => {
  return (
    <PieChartContainer>
      <PieChart {...omit(props, ['chartTitle', 'chartSubtitle'])} height={80} />
      <PieChartAnnotationsContainer>
        <LeftTopography>{props.chartTitle}</LeftTopography>
        <LeftTopography
          sx={{
            color: theme.palette.grey[600],
          }}
        >
          {props.chartSubtitle}
        </LeftTopography>
      </PieChartAnnotationsContainer>
    </PieChartContainer>
  );
};

const PieChartContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
});

const PieChartAnnotationsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 3,
});

const LeftTopography = styled('span')({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
});
