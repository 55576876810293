import { InputMaybe, ParameterInput } from '@/__generated__/graphql';

import { systemParams } from './SystemParams';

/**
 * @description function check which parameters are added by user
 * @param parameters
 * @returns new added parameters
 */

export const getNewAddedParameters = (
  parameters: InputMaybe<ParameterInput[]> | undefined,
) => {
  const newAddedParameters: ParameterInput[] = [];
  parameters?.forEach((parameter) => {
    if (
      !systemParams.find((systemParam) => systemParam.name === parameter.name)
    ) {
      newAddedParameters.push({ ...parameter });
    }
  });

  return newAddedParameters;
};
