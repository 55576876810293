import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSubmissionBuilderReducer } from '@kaizen/hooks/useSubmissionBuilder/Reducers/SubmissionBuilderReducer.tsx';
import { ProblemView, SolutionView } from '@kaizen/views';
import { SubmissionNameView } from '@kaizen/views/SubmissionNameView/SubmissionNameView.tsx';
import { SummaryModule } from '@kaizen/views/SummaryView/SummaryModule.tsx';

import { useCompanyId, useSnackbar } from '@core/hooks';

import {
  KaizenSubmissionFragment,
  useCreateKaizenSubmissionMutation,
  useGetEmployeeLazyQuery,
} from '@/__generated__/graphql';
import { useKaizenRedirects } from '@/core/redirects';
import { SummaryButtonType } from '@/pages/kaizen/common';
import { useUpdateSubmissionCache } from '@/pages/kaizen/hooks';
import { useUserStore } from '@/stores';

export const useAddSubmission = () => {
  const { t } = useTranslation('kaizen');
  const { companyId } = useCompanyId();
  const { redirectToSingleSubmission, redirectToSubmissions } =
    useKaizenRedirects();
  const { userUuid } = useUserStore();

  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonType, setButtonType] = useState<SummaryButtonType>();

  const [createKaizenSubmission] = useCreateKaizenSubmissionMutation();

  const {
    addNewSubmission: addNewSubmissionToCache,
    createNewSubmission: createNewSubmissionToCache,
  } = useUpdateSubmissionCache();

  const [getEmployeeQuery, { data }] = useGetEmployeeLazyQuery();

  useEffect(() => {
    void getEmployeeQuery({
      variables: {
        input: {
          organizationId: companyId,
          id: userUuid,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const { isSnackBarOpen, snackBarMessage, openSnackbar, closeSnackbar } =
    useSnackbar();

  const steps = [
    t('submissionModificationView.common.steps.submissionName'),
    t('submissionModificationView.common.steps.problem'),
    t('submissionModificationView.common.steps.solution'),
    t('submissionModificationView.common.steps.summary'),
  ];

  const handleStepBack = () =>
    setCurrentStep((currentValue) => (currentValue > 0 ? currentValue - 1 : 0));

  const handleStepNext = () =>
    setCurrentStep((currentValue) =>
      currentValue < steps.length ? currentValue + 1 : currentValue,
    );

  //IMPORTANT BORING STUFF
  const {
    submissionName,
    problem,
    solution,
    authors,
    setSubmissionName,
    setProblem,
    setSolution,
    setAuthors,
  } = useSubmissionBuilderReducer();

  const createSubmission = async (isDraft: boolean) => {
    try {
      setIsLoading(true);
      const response = await createKaizenSubmission({
        variables: {
          input: {
            name: submissionName,
            problem: { description: problem.description },
            solution: { description: solution.description },
            coAuthors: authors.map((author) => ({
              id: author.id,
              organizationId: author.organizationId,
            })),
            organizationUnitId: data?.getEmployee.organizationUnitGuid || '',
            isDraft,
            organizationId: companyId,
          },
          problemFiles: [...problem.files],
          solutionFiles: [...solution.files],
        },
      });
      setIsLoading(false);

      if (response.data) {
        addNewSubmissionToCache(
          response.data.createKaizenSubmission as KaizenSubmissionFragment,
        );
        if (!isDraft && response.data.createKaizenSubmission)
          createNewSubmissionToCache(
            response.data.createKaizenSubmission as KaizenSubmissionFragment,
          );

        return response.data.createKaizenSubmission;
      }
    } catch (error) {
      return null;
    }
  };

  const handleSubmitAndSend = async () => {
    setButtonType(SummaryButtonType.SubmitAndSend);

    const res = await createSubmission(false);

    if (res) redirectToSingleSubmission(res.id);
    else {
      openSnackbar(t('submissionModificationView.common.errors.submitError'));
    }
  };

  const handleSubmit = async () => {
    setButtonType(SummaryButtonType.Submit);

    const res = await createSubmission(true);

    if (res) redirectToSubmissions();
    else {
      openSnackbar(t('submissionModificationView.common.errors.submitError'));
    }
  };

  const renderStepChange = useCallback(() => {
    switch (currentStep) {
      case 1:
        return (
          <SubmissionNameView
            submissionName={submissionName}
            onMoveToNextStep={(currentSubmissionName) => {
              setSubmissionName(currentSubmissionName);
              handleStepNext();
            }}
          />
        );
      case 2:
        return (
          <ProblemView
            defaultValue={problem}
            onMoveToNextStep={(description, files) => {
              setProblem(description, files);
              handleStepNext();
            }}
            onStepBack={(description, files) => {
              setProblem(description, files);
              handleStepBack();
            }}
          />
        );
      case 3:
        return (
          <SolutionView
            defaultValue={solution}
            onMoveToNextStep={(description, files) => {
              setSolution(description, files);
              handleStepNext();
            }}
            onStepBack={(description, files) => {
              setSolution(description, files);
              handleStepBack();
            }}
          />
        );
      case 4:
        return (
          <SummaryModule
            buttonType={buttonType}
            isLoading={isLoading}
            summaryName={submissionName}
            onSubmitAndSend={() => void handleSubmitAndSend()}
            onSubmit={() => void handleSubmit()}
            onPreviousStep={() => handleStepBack()}
            onChangeSubmissionsAuthors={(newAuthors) => setAuthors(newAuthors)}
            authors={authors}
            submissionAuthors={authors}
          />
        );
    }
    return <div />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleStepNext, currentStep, solution, problem, submissionName]);

  return {
    steps,
    currentStep,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
    renderStepChange,
  };
};
