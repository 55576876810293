import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckBoxWithTextModal } from '@core/components';
import { CheckBoxWithTextModalDefaultTypes } from '@core/components/Modals/CheckBoxWithTextModal/CheckBoxWithTextModal.types.ts';

import { useKpiSettlementPeriodContext } from '../contexts';
import { useSettlementPeriod } from '../hooks/useSettlemetPeriod/useSettlementPeriod';

export const UpdateSettlementPeriodModal = () => {
  const { t: settlementPeriods } = useTranslation('settlementPeriods');
  const { t: commonT } = useTranslation();

  const [isValidationError, setValidationError] = useState<boolean>();

  const { validate, updateSettlementPeriod } = useSettlementPeriod();

  const {
    isUpdateSettlementPeriodModalOpen,
    settlementPeriodUpdateModalValues,
    closeUpdateSettlementPeriodModal,
    open: openSnackbar,
  } = useKpiSettlementPeriodContext();

  const handleResponse = async function (
    response: CheckBoxWithTextModalDefaultTypes,
  ) {
    setValidationError(false);
    if (!validate(response.text, !!isValidationError)) {
      setValidationError(true);
      return;
    }

    if (
      await updateSettlementPeriod(
        settlementPeriodUpdateModalValues!.id,
        response.text,
        response.checkbox,
      )
    ) {
      if (response.checkbox) {
        openSnackbar(
          'success',
          settlementPeriods(
            'updateSettlementPeriodModal.successAndActivatedMessage',
          ),
        );
      } else
        openSnackbar(
          'success',
          settlementPeriods('updateSettlementPeriodModal.successMessage'),
        );

      closeUpdateSettlementPeriodModal();
      return;
    }

    openSnackbar(
      'error',
      settlementPeriods('updateSettlementPeriodModal.settlementPeriodAddError'),
    );
    closeUpdateSettlementPeriodModal();
    return;
  };

  return (
    <CheckBoxWithTextModal
      isOpen={isUpdateSettlementPeriodModalOpen}
      closeModal={closeUpdateSettlementPeriodModal}
      headerText={settlementPeriods('updateSettlementPeriodModal.headerText')}
      placeholder={settlementPeriods('updateSettlementPeriodModal.placeholder')}
      checkboxLabel={settlementPeriods(
        'updateSettlementPeriodModal.activateAfterUpdate',
      )}
      submitButton={commonT('common.saveChanges')}
      checkboxTooltip={settlementPeriods(
        `updateSettlementPeriodModal.${settlementPeriodUpdateModalValues?.isActive ? 'activateAfterUpdateTooltip' : 'activateAfterUpdateTooltipWhenEnable'}`,
      )}
      errorInputMessage={settlementPeriods(
        'updateSettlementPeriodModal.errorMessage',
      )}
      isError={isValidationError}
      defaultValues={{
        text: settlementPeriodUpdateModalValues?.periodName || '',
        checkbox: settlementPeriodUpdateModalValues?.isActive || false,
      }}
      disableCheckbox={settlementPeriodUpdateModalValues?.isActive}
      onResponse={handleResponse}
    />
  );
};
