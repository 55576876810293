import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { useChartLegend } from '@kpi/modals/HandleKpiChartModal/hooks/useChartLegend/useChartLegend.tsx';
import { ChartOptionsTypes } from '@kpi/modals/HandleKpiChartModal/hooks/useManageChartOptions/ChartOptions.types.ts';
import { omit } from 'lodash';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import {
  ChartFragment,
  ChartFragmentDoc,
  DashboardTypesEnum,
  SupportedWidgetsEnum,
  usePinToDashboardMutation,
  useUpdateKpiChartMutationMutation,
} from '@/__generated__/graphql.ts';
import { client } from '@/apollo.ts';

export const useKpiChartMenu = (kpiChart: ChartFragment) => {
  const [updateKpiChart, { loading: isLoading }] =
    useUpdateKpiChartMutationMutation();

  const [pinToOrganizationDashboard] = usePinToDashboardMutation();

  const { t } = useTranslation('charts');

  const { companyId } = useCompanyId();

  const { openSnackbar } = useSnackbarContext();

  const { isDetailedLegendEnabled } = useChartLegend(
    kpiChart.displayOptions as unknown as ChartOptionsTypes,
  );

  const pinToDashboard = async (settlementPeriodId: string) => {
    try {
      openSnackbar(t('pinningInProgress'));
      await pinToOrganizationDashboard({
        variables: {
          input: {
            organizationId: companyId,
            chartId: kpiChart.id,
            settlementPeriodId,
            dashboardType: DashboardTypesEnum.KpiOrganizationDashboard,
            widgetType: SupportedWidgetsEnum.KpiChart,
          },
        },
      });
      openSnackbar(t('pinningSuccess'));
    } catch (error) {
      captureException(error);
      openSnackbar(t('pinningError'));
    }
  };

  const updateChart = async (
    key: keyof ChartFragment['displayOptions'],
    value: string | boolean,
  ) => {
    try {
      openSnackbar(t('updateInProgress'));
      const { data } = await updateKpiChart({
        variables: {
          input: {
            chartName: kpiChart.chartName,
            chartId: kpiChart.id,
            organizationId: companyId,
            displayOptions: {
              ...omit(kpiChart?.displayOptions, '__typename'),
              [key]: value,
            },
          },
        },
      });

      client.writeFragment({
        fragment: ChartFragmentDoc,
        id: 'Chart:' + kpiChart.id,
        data: {
          ...data?.updateKpiChart,
          __typename: 'Chart',
        },
      });

      openSnackbar(t('chartUpdated'));
    } catch (error) {
      captureException(error);
      openSnackbar(t('chartUpdateError'));
    }
  };

  const toggleDetailLegend = async () => {
    await updateChart(
      'showDetailedLegend',
      !kpiChart.displayOptions.showDetailedLegend,
    );
  };

  const toggleDataLabel = async () => {
    await updateChart(
      'showDataLabels',
      !kpiChart.displayOptions?.showDataLabels,
    );
  };

  return {
    isDetailLegend: Boolean(kpiChart?.displayOptions.showDetailedLegend),
    isDataLabelVisible: Boolean(kpiChart?.displayOptions.showDataLabels),
    isDataLabelDisabled: isLoading,
    isDetailedLegendDisabled: isLoading || !isDetailedLegendEnabled,
    toggleDetailLegend,
    toggleDataLabel,
    pinToDashboard,
  };
};
