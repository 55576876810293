import { z } from "zod";
import { TFunction } from 'i18next';

export const ProblemSolutionSchema = (t: TFunction<'kaizen'>) => z.object({
    descriptionLength: z.number({
        message: t(
          'submissionModificationView.common.submissionProblem.errors.problemRequired',
        ),
      }).min(5,{
        message: t(
            'submissionModificationView.common.submissionProblem.errors.problemTooShort',{
                charLength: 5
            },
          )
      }).max(5000,{
        message: t('submissionModificationView.common.submissionProblem.errors.problemTooLong',{
            charLength: 5000
        })
      })
})