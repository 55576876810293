import { useTranslation } from 'react-i18next';

import { Add } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';

import { Button } from '@core/components';
import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context';

import { theme } from '@/theme';

export const EmptyDashboard = () => {
  const { t } = useTranslation('dashboard');

  const { addOnEmpty } = useDashboardContext();

  return (
    <Container>
      <Typography variant={'h6'}>{t('empty.userDashboard')}</Typography>
      <Typography
        sx={{
          color: theme.palette.grey[500],
        }}
        variant={'bodyMedium'}
      >
        {t('empty.addWidget')}
      </Typography>
      <Button onClick={() => void addOnEmpty()} startIcon={<Add />}>
        {t('empty.button')}
      </Button>
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: 300,
  gap: 10,
});
