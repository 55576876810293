import { useEffect, useMemo } from 'react';

import { useCompanyId } from '@core/hooks';
import { checkSystemRolesPermission } from '@core/utils';

import {
  SystemRoles,
  useFindEflowPermissionsLazyQuery,
} from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

import { useEflowPermissionsInput, useEflowPermissionsOutput } from './types';

export const useEflowPermissions = (
  props: useEflowPermissionsInput,
): useEflowPermissionsOutput => {
  const { companyId } = useCompanyId();

  const { userUuid, userGroups } = useUserStore();

  useEffect(() => {
    if (!props.eFlowId) return;

    void query({
      variables: {
        input: {
          id: props.eFlowId,
          organizationId: companyId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eFlowId, companyId]);

  const [query, { data }] = useFindEflowPermissionsLazyQuery({
    nextFetchPolicy: 'cache-first',
  });

  const isPermittedBySystem = useMemo(() => {
    return checkSystemRolesPermission(userGroups, [
      SystemRoles.Root,
      SystemRoles.SystemAdmin,
      SystemRoles.KeyUser,
      SystemRoles.Administrator,
    ]);
  }, [userGroups]);

  const isAnalizeCreator = useMemo(() => {
    if (!data?.findOneEFlow?.createdBy) return false;

    return data?.findOneEFlow?.createdBy?.id === userUuid;
  }, [data?.findOneEFlow, userUuid]);

  const isWorkGroupMember = useMemo(() => {
    if (!data?.findOneEFlow?.workGroupUsers) return false;

    const userIndex = data?.findOneEFlow.workGroupUsers.findIndex(
      (workGroup) => workGroup?.id === userUuid,
    );

    return userIndex !== -1;
  }, [data?.findOneEFlow, userUuid]);

  const isAnalizeLeader = useMemo(() => {
    if (!data?.findOneEFlow?.analyzeLeaders) return false;

    const userIndex = data?.findOneEFlow.analyzeLeaders.findIndex(
      (analizeLeader) => analizeLeader?.id === userUuid,
    );

    return userIndex !== -1;
  }, [data?.findOneEFlow, userUuid]);

  return {
    isPermittedBySystem,
    isAnalizeCreator,
    isWorkGroupMember,
    isAnalizeLeader,
    canModifyAnalize:
      isPermittedBySystem || isAnalizeCreator || isAnalizeLeader,
    isPartOfAnalize:
      isAnalizeCreator ||
      isAnalizeLeader ||
      isWorkGroupMember ||
      isPermittedBySystem,
  };
};
