import { DocumentNode } from 'graphql/language';

import { deepClone } from '@mui/x-data-grid/internals';

import { client } from '@/apollo.ts';

/**
 * Function to read query
 * @param query DocumentNode
 * @param eFlowId string
 * @param organizationId  string
 */
export const readQuery = (
  query: DocumentNode,
  eFlowId: string,
  organizationId: string,
) => {
  const results = client.readQuery({
    query,
    variables: {
      input: {
        id: eFlowId,
        organizationId,
      },
    },
  });

  if (results === null) {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      findOneEFlow: {
        asIsSteps: [],
        toBeSteps: [],
        id: eFlowId,
        organizationId,
        __typename: 'EFlow',
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return
  return deepClone(results);
};
