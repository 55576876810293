import { Box, MenuItem, styled } from '@mui/material';

import { MenuItemsTypes } from './MenuItems.types';

export const MenuItems = <T,>(props: MenuItemsTypes<T>) => {
  return (
    <ItemsContainer>
      {props.options.map((option, index) => (
        <MenuItem key={index} onClick={() => props.handleMenuItemClick(option)}>
          {props.menuItemBody(option, index)}
        </MenuItem>
      ))}
    </ItemsContainer>
  );
};

const ItemsContainer = styled(Box)({
  maxHeight: 300,
  overflowY: 'auto',
});
