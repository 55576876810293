import {
  Autocomplete,
  Box,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { theme } from '@/theme';

export const SettingsSectionContainer = styled(Box)({
  display: 'flex',
  minWidth: '312px',
  maxWidth: '744px',
  paddingBottom: '24px',
  paddingTop: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  alignSelf: 'stretch',
  borderBottom: `1px solid var(--neutrals-gray-200, ${theme.palette.grey[200]})`,
  background: `var(--neutrals-white, ${theme.palette.common.white})`,
  position: 'relative',
});

export const SectionTitleRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  minHeight: '40px',
  width: '100%',
});

export const EditButton = styled('button')({
  display: 'flex',
  height: '40px',
  padding: '0px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.editing': {
    opacity: 0.5,
  },
});

export const Row = styled(Box)({
  display: 'flex',
  padding: '8px 0px',
  alignItems: 'center',
  gap: '30px',
  alignSelf: 'stretch',
  flex: 1,
  maxWidth: 432,
});

export const TypographyLabel = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  width: '140px',
  gap: '8px',
  color: theme.palette.grey[600],
});

export const CustomTextField = styled(TextField)({
  width: '100%',
  maxWidth: 432,
});

export const CustomAutocomplete = styled(Autocomplete)({
  width: '100%',
  maxWidth: 432,
});

export const FormContainer = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const FlexEndBox = styled(Box)({
  justifyContent: 'flex-end',
  alignContent: 'center',
});
