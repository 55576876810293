import { useTranslation } from 'react-i18next';

import {
  OrganizationStructure,
  WorkstationsTable,
} from '@organizations/components';

import { Box, styled } from '@mui/material';

import { TabViewWrapper } from '@core/components';

import { UnitAdmin } from './UnitAdmin/UnitAdmin';

export const ESchemaSettings = () => {
  const { t } = useTranslation();

  return (
    <CustomLayout>
      <TabViewWrapper
        tabs={[
          {
            tabName: t(
              'organizationSettings.organizationSettingTabNames.unitAdmin',
            ),
            tabComponent: <UnitAdmin />,
          },
          {
            tabName: t(
              'organizationSettings.organizationSettingTabNames.organizationStructure',
            ),
            tabComponent: <OrganizationStructure />,
          },
          {
            tabName: t(
              'organizationSettings.organizationSettingTabNames.workStations',
            ),
            tabComponent: <WorkstationsTable />,
          },
        ]}
      />
    </CustomLayout>
  );
};

const CustomLayout = styled(Box)({
  margin: '-30px -24px',
  overflowY: 'auto',
  overflowX: 'hidden',
});
