import { captureException } from '@sentry/react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  FindSingleOrganizationDocument,
  FindSingleOrganizationModulesDocument,
  FindSingleOrganizationModulesQuery,
  FindSingleOrganizationQuery,
  OrganizationModules,
  useFindSingleOrganizationModulesQuery,
  useUpdateOrganizationModulesMutation,
} from '@/__generated__/graphql';
import { client } from '@/apollo';
import { useCompanyId } from '@/core/hooks';
import { useOrganizationStore } from '@/stores';

import getModulesData from './../OrganizationModulesData';

export const useOrganizationModules = () => {
  const { companyName } = useParams();
  const { companyId } = useCompanyId();

  const [modules, setModules] = useState<OrganizationModules[]>([]);
  const [moduleToInactive, setModuleToInactive] = useState<
    OrganizationModules | undefined
  >();

  const { setModules: setGlobalModules } = useOrganizationStore();

  const { data, loading: isLoading } = useFindSingleOrganizationModulesQuery({
    variables: {
      input: {
        url: companyName!,
        id: companyId,
      },
    },
  });

  const [updateOrganizationModulesMutation] =
    useUpdateOrganizationModulesMutation();

  const allowedModules = useMemo(() => {
    return getModulesData.map((item) => ({
      ...item,
      isActive: modules.includes(item.moduleName),
    }));
  }, [modules]);

  useEffect(() => {
    if (data?.findSingleOrganization.modules) {
      setModules(data.findSingleOrganization.modules);
    }
  }, [data]);

  const handleCheckboxClick = (moduleName: OrganizationModules) => {
    if (modules.includes(moduleName)) setModuleToInactive(moduleName);
    else handleUpdateModules([...modules, moduleName]);
  };

  const handleUpdateModules = (newModules: OrganizationModules[]) => {
    try {
      void updateOrganizationModulesMutation({
        variables: {
          input: {
            id: companyId,
            modules: newModules,
          },
        },
        update: () => updateCache(newModules),
      });
    } catch (error) {
      captureException(error);
    }
  };

  const updateCache = (newModules: OrganizationModules[]) => {
    const resFindOrganizationModules = client.readQuery({
      query: FindSingleOrganizationModulesDocument,
      variables: {
        input: {
          url: companyName!,
          id: companyId,
        },
      },
    }) as FindSingleOrganizationModulesQuery;

    const resFindOrganization = client.readQuery({
      query: FindSingleOrganizationDocument,
      variables: {
        input: {
          url: companyName!,
        },
      },
    }) as FindSingleOrganizationQuery;

    client.writeQuery({
      query: FindSingleOrganizationModulesDocument,
      variables: {
        input: {
          url: companyName!,
          id: companyId,
        },
      },
      data: {
        findSingleOrganization: {
          ...resFindOrganizationModules.findSingleOrganization,
          modules: newModules,
        },
      },
    });

    client.writeQuery({
      query: FindSingleOrganizationDocument,
      variables: {
        input: {
          url: companyName!,
        },
      },
      data: {
        findSingleOrganization: {
          ...resFindOrganization.findSingleOrganization,
          modules: newModules,
        },
      },
    });

    setGlobalModules(newModules);
  };

  return {
    handleCheckboxClick,
    allowedModules,
    isLoading,
    moduleToInactive,
    setModuleToInactive,
    handleUpdateModules,
    modules,
  };
};
