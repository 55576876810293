import { FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { Box } from '@mui/material';

import { ChartWithBalanceProps } from '@core/components/Statistica/ChartWithBalance/ChartWithBalance.types.ts';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
);

export const ChartWithBalance: FC<ChartWithBalanceProps> = ({
  data,
  balanceLine,
  xRange,
  yRange,
  yAxisValues,
  width = '100%',
  height = 400,
  xLabel,
  yLabel,
  balanceLineLabel,
}) => {
  const xValues = useMemo(() => data.map((__, index) => index + 1), [data]);
  const valueA = useMemo(() => data.map((point) => point[0]), [data]);
  const valueB = useMemo(() => data.map((point) => point[1]), [data]);

  const chartData = useMemo(() => {
    return {
      labels: xValues,
      datasets: [
        {
          data: valueA,
          backgroundColor: 'blue',
          barThickness: 15,
          animation: {
            duration: 0,
          },
        },
        {
          data: valueB,
          backgroundColor: 'orange',
          barThickness: 15,
          animation: {
            duration: 0,
          },
        },
        ...(balanceLine !== undefined
          ? [
              {
                label: balanceLineLabel,
                data: xValues.map(() => balanceLine),
                borderColor: 'grey',
                borderWidth: 2,
                type: 'line' as const,
                fill: false,
                pointRadius: 0,
              },
            ]
          : []),
      ],
    };
  }, [xValues, valueA, valueB, balanceLine, balanceLineLabel]);

  const options = {
    scales: {
      x: {
        display: true,
        title: {
          display: !!xLabel,
          text: xLabel,
        },
        ...(xRange && {
          min: xRange[0],
          max: xRange[1],
        }),
      },
      y: {
        display: true,
        title: {
          display: !!yLabel,
          text: yLabel,
        },
        ...(yRange && {
          min: yRange[0],
          max: yRange[1],
        }),
        ...(yAxisValues && {
          ticks: {
            callback: (value: number) =>
              yAxisValues.includes(value) ? value : '',
          },
        }),
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        enabled: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Box style={{ width, height }}>
      <Bar
        data={chartData as ChartData<'bar'>}
        options={options as ChartOptions<'bar'>}
      />
    </Box>
  );
};
