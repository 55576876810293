import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddLocalEmployeeModalProps } from '@e-flow/pages/newAnalysis/new-analysis-modules/WorkGroupModule/Modals/AddLocalEmployeeModal/AddEmployeeModal.types.ts';

import { Typography } from '@mui/material';

import {
  CharsCountingInput,
  InfoModalContent,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { theme } from '@/theme';

export const AddLocalEmployeeModal = ({
  close,
  addEmployee,
}: AddLocalEmployeeModalProps) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  const [text, setText] = useState<string>('');
  const [error, setError] = useState<string | undefined>();

  return (
    <ModalContainer
      closeModal={close}
      width={'528px'}
      headerText={t('newAnalysis.groups.addLocalEmployeeTitle')}
    >
      <InfoModalContent
        contentText={
          <CharsCountingInput
            label={t('newAnalysis.groups.nameAndSurname')}
            maxInputLength={40}
            onChange={(e) => setText(e.target.value)}
            size="small"
            fullWidth
            helperText={error}
          />
        }
      >
        <TextButton
          text={commonT('common.cancel')}
          width="auto"
          onClick={close}
        />
        <PrimaryButton
          variant="contained"
          onClick={() => {
            if (text.length >= 2) {
              addEmployee(text);
              close();
            } else {
              setError(t('newAnalysis.groups.addLocalEmployeeMinLength'));
            }
          }}
          sx={{
            bgcolor: theme.palette.primary[600],
          }}
        >
          <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
            {commonT('common.add')}
          </Typography>
        </PrimaryButton>
      </InfoModalContent>
    </ModalContainer>
  );
};
