/**
 *
 * @param values array of values to display on Piechart
 * @returns array of value
 * @description it calculate whitespace to display custom Piechart and return array which conatins that space
 */

export const calculateValues = (values: number[]) => {
  const summary = values.reduce(
    (prevValue, currentValue) => prevValue + currentValue,
    0,
  );
  const firstValue = (summary * 20) / 80;
  return [...values, firstValue];
};
