import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';

import { TextField, Typography, styled, useTheme } from '@mui/material';

import { ModalContainer } from '@/core/components';

import type { WorkstationModalProps } from './WorkstationModal.types';
import { WorkstationNameSchema } from './WorkstationModals.schema';

export const WorkstationModal = ({
  closeModal,
  workstations,
  setWorkstations,
}: WorkstationModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    defaultValues: {
      workstationName: '',
    },
    resolver: zodResolver(
      WorkstationNameSchema(
        t('error.requiredField'),
        t('organizationSettings.workstationMaxLength'),
      ),
    ),
  });

  const onSubmit: SubmitHandler<{
    workstationName: string;
  }> = async (data) => {
    if (workstations.includes(data.workstationName)) {
      setError('workstationName', {
        message: t('organizationSettings.workstationExists'),
      });
      return;
    }
    try {
      await setWorkstations(
        [...workstations, data.workstationName],
        t('organizationSettings.addedWorkstation'),
      );
      reset();
      closeModal();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <ModalContainer
        closeModal={closeModal}
        headerText={t('organizationSettings.addWorkstation')}
        height="auto"
        sx={{ maxWidth: '528px' }}
        width="100%"
      >
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <CustomForm onSubmit={handleSubmit(onSubmit)}>
          <CustomTextField
            autoFocus
            {...register('workstationName')}
            fullWidth
            size="small"
            label={t('organizationSettings.workstationName')}
            error={!!errors.workstationName}
            helperText={errors.workstationName?.message}
          />

          <CustomStack>
            <PrimaryButton
              onClick={() => {
                closeModal();
                reset();
              }}
            >
              <Typography>{t('common.cancel')}</Typography>
            </PrimaryButton>
            <PrimaryButton
              data-testid="addButtonModal"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
              type="submit"
            >
              <Typography>
                {t('organizationSettings.addWorkstation')}
              </Typography>
            </PrimaryButton>
          </CustomStack>
        </CustomForm>
      </ModalContainer>
    </>
  );
};

const CustomTextField = styled(TextField)({
  width: '100%',
  margin: '0 24px',
  maxWidth: '480px',
});

const CustomForm = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const CustomStack = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 24px 24px 24px',
  gap: '12px',
  flexDirection: 'row',
});
