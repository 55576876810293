import { captureException } from '@sentry/react';
import { Trans, useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Typography, styled } from '@mui/material';

import { useHandleUnitAdmin } from '@core/hooks';

import {
  CustomModal,
  InfoModalContent,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { theme } from '@/theme';

import { DeleteUnitAdminModalProps } from './types';

export const DeleteUnitAdminModal = (props: DeleteUnitAdminModalProps) => {
  const { t } = useTranslation();
  const { removeUnitAdmin } = useHandleUnitAdmin();

  const handleRemovePermission = async () => {
    try {
      const result = await removeUnitAdmin(props.row.id);
      if (result.data) {
        props.setSnackBar(
          t('organizationSettings.unitAdminSection.deleteConfirmation'),
        );
        props.removeUser(result.data.updateEmployee.id);
      }
    } catch (_err) {
      captureException(_err);
    } finally {
      props.closeModal();
    }
  };

  return (
    <CustomModal display={props.open}>
      <ModalContainer
        width="528px"
        closeModal={props.closeModal}
        headerText={t('organizationSettings.unitAdminSection.deleteModalTitle')}
      >
        <InfoModalContent
          contentText={
            <Trans
              values={{
                permission: t(
                  'organizationSettings.unitAdminSection.cellAdmin',
                ),
                cell: props.row.organizationUnit.name,
                employee: `${props.row.firstName} ${props.row.lastName}`,
              }}
              i18nKey={
                'organizationSettings.unitAdminSection.deleteModalContent'
              }
            />
          }
        >
          <TextButton
            text={t('common.cancel')}
            width="66px"
            onClick={props.closeModal}
          />
          <RedButton
            variant="contained"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleRemovePermission}
          >
            <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
              {t('organizationSettings.unitAdminSection.deleteModalTitle')}
            </Typography>
          </RedButton>
        </InfoModalContent>
      </ModalContainer>
    </CustomModal>
  );
};

const RedButton = styled(PrimaryButton)({
  backgroundColor: theme.palette.error[600],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    backgroundColor: theme.palette.error[700],
  },
});
