import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SynchronizeEflowStepsButton } from '@e-flow/components/SychnonizeEflowStepsButton/SynchronizeEflowStepsButton.tsx';
import { useEflowPermissions } from '@e-flow/hooks';

import { Stack } from '@mui/material';

import { Button } from '@/core/components';
import { theme } from '@/theme';

import { ActionButtonsProps } from './Navbar.types';

export const ActionButtons = (props: ActionButtonsProps) => {
  const { t } = useTranslation('eflow');

  const navigate = useNavigate();

  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: props.eFlowId,
  });

  if (!props.showAddAnalyzeButton && canModifyAnalize)
    return (
      <>
        <Stack direction="row" spacing={2}>
          <SynchronizeEflowStepsButton />
          <Button onClick={() => navigate('operations/AS_IS')}>
            {t('eflow.begin')}
          </Button>
        </Stack>
      </>
    );

  return (
    <Stack direction="row" spacing={2}>
      <SynchronizeEflowStepsButton />

      <Button
        fontSize={14}
        backgroundColor={theme.palette.grey[200]}
        typographySx={{
          color: theme.palette.text.primary,
        }}
        textVariant="bodyMedium"
        onClick={() => navigate('report')}
      >
        {t('eflow.raport')}
      </Button>

      {canModifyAnalize && (
        <Button
          backgroundColor={theme.palette.grey[200]}
          fontSize={14}
          typographySx={{
            color: theme.palette.text.primary,
          }}
          onClick={() => navigate('operations/AS_IS')}
          textVariant="bodyMedium"
        >
          {t('eflow.continueAsIs')}
        </Button>
      )}
    </Stack>
  );
};
