import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { styled } from '@mui/styles';

import { EmployeeAvatar, ShortenTypography } from '@/core/components';
import { theme } from '@/theme';

import { SubmissionDetailsProps } from '../../SubmissionDetails.types';
import { DetailRow, EmployeeDetailRow } from '../styles/styles';

export const SubmissionAssignedEmployeeSection = (
  props: SubmissionDetailsProps,
) => {
  const { t } = useTranslation('kaizen');

  return (
    <DetailRow>
      <DetailRowLabel>{t('submissionDetails.assignedEmployee')}</DetailRowLabel>
      <EmployeeDetailRow>
        {props.assignedContractor ? (
          <>
            <EmployeeAvatar
              name={props.assignedContractor.firstName}
              imageUrl={props.assignedContractor.profilePicture}
            />
            <ShortenTypography maxLength={20} textAlign="end">
              {props.assignedContractor.firstName +
                ' ' +
                props.assignedContractor.lastName}
            </ShortenTypography>
          </>
        ) : (
          '-'
        )}
      </EmployeeDetailRow>
    </DetailRow>
  );
};

export const DetailRowLabel = styled(Typography)({
  color: theme.palette.grey[700],
});
