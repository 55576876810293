import {
  readQuery,
  writeQuery,
} from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/Cache';

import { useCompanyId } from '@core/hooks';

import { KaizenSettlementPeriodFragment } from '@/__generated__/graphql.ts';

export const useUpdateSettlementPeriodCache = () => {
  const { companyId } = useCompanyId();

  const addNewSettlementPeriod = (
    newSettlementPeriod: KaizenSettlementPeriodFragment,
  ) => {
    const queryResult = readQuery(companyId);

    if (newSettlementPeriod.isActive) {
      _deactivateAllPeriods(
        queryResult.findAllSettlementPeriods
          .settlementPeriods as KaizenSettlementPeriodFragment[],
      );
    }

    queryResult.findAllSettlementPeriods.settlementPeriods.push(
      newSettlementPeriod,
    );

    writeQuery(queryResult, companyId);
  };

  const removeSettlementPeriod = (settlementPeriodId: string) => {
    const queryResult = readQuery(companyId);

    const index =
      queryResult.findAllSettlementPeriods.settlementPeriods.findIndex(
        (settlementPeriod: KaizenSettlementPeriodFragment) => {
          return settlementPeriod.id === settlementPeriodId;
        },
      );

    queryResult.findAllSettlementPeriods.settlementPeriods.splice(index, 1);

    writeQuery(queryResult, companyId);
  };

  const updateSettlementPeriod = (
    updatedSettlementPeriod: KaizenSettlementPeriodFragment,
  ) => {
    const queryResult = readQuery(companyId);

    if (updatedSettlementPeriod.isActive) {
      _deactivateAllPeriods(
        queryResult.findAllSettlementPeriods
          .settlementPeriods as KaizenSettlementPeriodFragment[],
      );
    }

    const index =
      queryResult.findAllSettlementPeriods.settlementPeriods.findIndex(
        (settlementPeriod: KaizenSettlementPeriodFragment) => {
          return settlementPeriod.id === updatedSettlementPeriod.id;
        },
      );

    queryResult.findAllSettlementPeriods.settlementPeriods[index] =
      updatedSettlementPeriod;

    writeQuery(queryResult, companyId);
  };

  const _deactivateAllPeriods = (
    settlementPeriods: KaizenSettlementPeriodFragment[],
  ) => {
    settlementPeriods.forEach(
      (settlementPeriod: KaizenSettlementPeriodFragment) => {
        settlementPeriod.isActive = false;
      },
    );
  };

  return {
    addNewSettlementPeriod,
    updateSettlementPeriod,
    removeSettlementPeriod,
  };
};
