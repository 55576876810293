import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DeleteOutline,
  PersonOutline,
  SwapVertOutlined,
} from '@mui/icons-material';
import { Menu } from '@mui/material';

import { checkSystemRolesPermission } from '@core/utils';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { CustomMenuItem } from '@/core/components/index.ts';
import { useOrganizationStore, useUserStore } from '@/stores';
import { userIsPermitted } from '@/utils';

import { MenuProps } from './types';

export const EmployeesTableMenu = ({
  anchorEl,
  open,
  handleClose,
  setDeleteModalOpen,
  setChangeCellModalOpen,
  employeeId,
}: MenuProps) => {
  const { t } = useTranslation();

  const { userGroups, organizationId } = useUserStore();
  const { organization } = useOrganizationStore();
  const { companyName } = useParams();
  const navigate = useNavigate();

  const AdminOptions = [
    {
      text: t('common.delete'),
      onClick: () => setDeleteModalOpen(),
      icon: <DeleteOutline />,
    },
    {
      text: t('employees.changeCell'),

      onClick: () => setChangeCellModalOpen(),
      icon: <SwapVertOutlined />,
    },
  ];
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      {userIsPermitted(userGroups, organizationId!, organization[companyName!])
        ? AdminOptions.map((option) => (
            <CustomMenuItem key={option.text} {...option} />
          ))
        : checkSystemRolesPermission(userGroups, [SystemRoles.UnitAdmin]) && (
            <CustomMenuItem
              text={t('employees.changeCell')}
              link="account-settings"
              onClick={() => setChangeCellModalOpen()}
              icon={<SwapVertOutlined />}
            />
          )}
      <CustomMenuItem
        text={t('employees.showProfile')}
        onClick={() => {
          navigate(`../employees/${employeeId}`);
        }}
        icon={<PersonOutline />}
      />
    </Menu>
  );
};
