import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusLabel } from '@kaizen/components';
import { ChangeSubmissionStatusSelectProps } from '@kaizen/components/ChangeSubmissionStatusSeelct/ChangeSubmissionStatusSelect.types.ts';
import {
  useChangeKaizenSubmissionStatus,
  useSortedStatuses,
} from '@kaizen/hooks';

import { MenuItem, Select } from '@mui/material';

import { useSnackbarContext } from '@core/contexts';

import { KaizenStatuses } from '@/__generated__/graphql.ts';

export const ChangeSubmissionStatusSelect = ({
  submissionId,
  width,
  defaultStatus,
}: ChangeSubmissionStatusSelectProps) => {
  const { t: kaizenT } = useTranslation('kaizen');

  const [selectedStatus, setSelectedStatus] = useState<KaizenStatuses | null>(
    null,
  );

  const { statuses } = useSortedStatuses();

  const { openSnackbar } = useSnackbarContext();

  const { changeKaizenSubmissionStatus } = useChangeKaizenSubmissionStatus();

  const handleStatusChange = async (status: KaizenStatuses) => {
    if (await changeKaizenSubmissionStatus(submissionId, status)) {
      openSnackbar(kaizenT('updateStatus.successMessage'));
    } else {
      openSnackbar(kaizenT('updateStatus.errorMessage'));
    }
  };

  useEffect(() => {
    if (!selectedStatus) return;
    if (selectedStatus === defaultStatus) return;

    void handleStatusChange(selectedStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  useEffect(() => {
    if (!defaultStatus) return;
    setSelectedStatus(defaultStatus);
  }, [defaultStatus]);

  return (
    <Select
      size={'small'}
      sx={{ width }}
      value={selectedStatus}
      onChange={(event) => {
        const value = event.target.value;
        setSelectedStatus(value as KaizenStatuses);
      }}
      renderValue={() => <span>{kaizenT('updateStatus.changeStatus')}</span>}
    >
      {statuses.map((status) => (
        <MenuItem
          key={status}
          value={status}
          disabled={defaultStatus === status}
        >
          <StatusLabel variant={status} />
        </MenuItem>
      ))}
    </Select>
  );
};
