import { useState } from 'react';

export const useDrawerStatus = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return {
    isDrawerOpen,
    handleOpenDrawer: () => setIsDrawerOpen(true),
    handleCloseDrawer: () => setIsDrawerOpen(false),
  };
};
