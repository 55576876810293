import { EFlowStepTypes } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';

import { useIndexDBType } from '@core/hooks';

import { EflowStepFragmentFragment } from '@/__generated__/graphql.ts';

/**
 * Synchronize the eFlow step - update the position in the flow and save it in the indexDB
 * @param step {EFlowStep} - the step to be synchronized
 * @param positionInFlow {number} - the position in the flow
 * @param indexDbUpdate {useIndexDBType['saveElement']} - the function to update the element in the indexDB
 * @param indexDbSave {useIndexDBType['updateElement']} - the function to save the element in the indexDB
 * @param isAlreadySaved {boolean} - if the step is already saved in the indexDB. If so, the `indexDbUpdate` will be used, otherwise `indexDbSave` will be used
 * @param indexDbRemove
 * @param currentKyeToReplaceFromOfflineToOnline {string} - the key to replace from offline to online - used when the step is saved offline and then online
 *
 * @returns Promise<void>
 */
export const SynchronizeEflowStep = async (
  step: EflowStepFragmentFragment,
  positionInFlow: number,
  isAlreadySaved: boolean,
  indexDbUpdate: useIndexDBType<EFlowStepTypes>['updateElement'],
  indexDbSave: useIndexDBType<EFlowStepTypes>['saveElement'],
  indexDbRemove: useIndexDBType<EFlowStepTypes>['removeElement'],
  currentKyeToReplaceFromOfflineToOnline?: string,
): Promise<void | string> => {
  if (currentKyeToReplaceFromOfflineToOnline) {
    await indexDbRemove(currentKyeToReplaceFromOfflineToOnline);
    return indexDbSave(
      {
        ...step,
        stepNumber: positionInFlow,
      } as EFlowStepTypes,
      step.id,
    );
  }

  if (isAlreadySaved) {
    return indexDbUpdate(step.id, {
      ...step,
      stepNumber: positionInFlow,
    } as unknown as EFlowStepTypes);
  }

  return indexDbSave(
    {
      ...step,
      stepNumber: positionInFlow,
    } as EFlowStepTypes,
    step.id,
  );
};
