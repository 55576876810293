import { useEffect, useState } from 'react';
import { useIndexDB } from '@core/hooks';
import { EFlowStepTypes, NumeredEFlowStep } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';
import { useEflowStepsOutput } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/useEflowStepsOutput.types.ts';
import { AsIsToBeNamesEnum, CreateEflowStepInput } from '@/__generated__/graphql.ts';
import { useEflowStepsInit } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useEflowStepsInit/useEflowStepsInit.tsx';
import {
  useCreateEflowStep
} from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useCreateEflowStep/useCreateEflowStep.tsx';
import {
  useControlEflowStep
} from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useControllEflowStep/useControlEflowStep.tsx';
import {
  useRemoveEflowSteps
} from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useRemoveEflowStep/useRemoveEflowStep.tsx';
import {
  useUpdateEflowStep
} from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useUpdateEflowStep/useUpdateEflowStep.tsx';
import { isEqual } from 'lodash';
import { useGlobalRedirects } from '@/core/redirects';

/**
 * When this hook was created only I and God knew what was going on here.
 * Now only God knows.
 * People who failed here (if you did also add one): 2
 */
export const useEflowSteps = (): useEflowStepsOutput => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [eFlowId, setEflowId] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [analizeType, setAnalizeType] = useState<AsIsToBeNamesEnum>(AsIsToBeNamesEnum.AsIs);

  const [hasInitializedEmptyStep, setHasInitializedEmptyStep] = useState<boolean>(false);
  const [hasFlowStepSynchronized, setHasFlowStepSynchronized] = useState<boolean>(false);

  const { returnToThisFlow } = useGlobalRedirects();

  const {
    isReady: isEflowStepsDBReady,
    init,
    saveElement: saveEflowStepToDatabase,
    updateElement: updateEflowStepElement,
    data: allSavedEflowSteps,
    removeElement: removeEflowStep,
    reHydrate: reHydrateEflowSteps,
    reIndexRecords: reIndexEflowSteps
  } = useIndexDB<EFlowStepTypes>();


  // custom hooks
  const {
    isLoading: isSynchronizing,
    synchronizeEflowSteps: handleSynchronization,
    initEflowStepsDb
  } = useEflowStepsInit({
    analizeType: analizeType,
    eFlowId,
    organizationId,
    init,
    reHydrate: reHydrateEflowSteps,
    saveElement: saveEflowStepToDatabase,
    updateElement: updateEflowStepElement,
    data: allSavedEflowSteps,
    isReady: isEflowStepsDBReady,
    removeElement: removeEflowStep
  });

  const { save: saveEflowStep } = useCreateEflowStep({
    analizeType: analizeType,
    eFlowId,
    organizationId,
    removeElement: removeEflowStep,
    updateElement: updateEflowStepElement,
    saveElement: saveEflowStepToDatabase
  });


  const {
    files,
    currentEflowStep,
    currentEflowStepId,
    hasStepBeenUpdated,
    hasFilesBeenChanged,
    validate,
    updateEflowFiles,
    addNewEmptyStep,
    changeCurrentEflowStep: changeEflowStepWithoutSave,
    updateCurrentEflowStep,
    setPointingLastEflowStep,
    removeAlreadyUploadedFiles
  } = useControlEflowStep({
    data: allSavedEflowSteps,
    saveElement: saveEflowStepToDatabase,
    reIndexRecords: reIndexEflowSteps,
    updateElement: updateEflowStepElement,
    eFlowId: eFlowId,
    organizationId: organizationId,
    reHydrate: reHydrateEflowSteps
  });


  const { update } = useUpdateEflowStep({
    organizationId: organizationId,
    eFlowId: eFlowId,
    analizeType: analizeType,
    updateElement: updateEflowStepElement
  });


  const {
    removeStep
  } = useRemoveEflowSteps({
    eFlowId: eFlowId,
    organizationId: organizationId,
    analyzeType: analizeType
  });


  /**
   * Handle fetching of eFlow
   */
  useEffect(() => {
    void (async () => {
      if (eFlowId && organizationId) {
        setIsLoading(true);
        await initEflowStepsDb();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eFlowId, organizationId]);


  //synchronize steps - if the last step is not completed, set it as current, otherwise add a new one
  useEffect(() => {
    void (async () => {
      if (eFlowId && organizationId && isEflowStepsDBReady) {
        setIsLoading(true);
        await handleSynchronization();
        setHasFlowStepSynchronized(true);
        setIsLoading(false);
        setIsReady(true);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eFlowId, organizationId, isEflowStepsDBReady]);

  useEffect(() => {
    void (async () => {
      if (isEqual(allSavedEflowSteps, {}) && hasFlowStepSynchronized && !hasInitializedEmptyStep) {
        setHasInitializedEmptyStep(true);
        await addNewEmptyStep();
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSavedEflowSteps, hasFlowStepSynchronized, hasInitializedEmptyStep]);


  /**
   * Changes the current eFlow step
   * @param stepId {string} - stepId
   * @returns {Promise<void>}
   */
  const changeCurrentEflowStep = async (stepId: string): Promise<void> => {
    await changeEflowStepWithoutSave(stepId, async () => await save(false));
    await reHydrateEflowSteps();
  };


  /**
   * Removes an Eflow step online and offline
   */
  const deleteEflowStepOnlineAndOffline = async () => {
    if (!currentEflowStep || !currentEflowStepId) return;

    setHasInitializedEmptyStep(true); //removes bug with infinite adding steps on remove

    await removeStep({
      eFlowStep: currentEflowStep as CreateEflowStepInput,
      eFlowStepsId: [currentEflowStepId],
      reHydrate: reHydrateEflowSteps,
      reIndexRecords: reIndexEflowSteps,
      removeElement: removeEflowStep
    });


    if (allSavedEflowSteps && Object.keys(allSavedEflowSteps).length === 0) {
      returnToThisFlow();
    }

    setPointingLastEflowStep();
  };


  /**
   * Load hook with eFlowId and companyId
   * @param eFlowId {string} - eFlowId
   * @param companyId {string} - companyId
   * @param analizeType {AsIsToBeNamesEnum} - analizeType
   */
  const loadHook: useEflowStepsOutput['loadHook'] = (eFlowId: string, companyId: string, analizeType: AsIsToBeNamesEnum) => {
    if (!eFlowId || !companyId) {
      throw new Error(
        `eFlowId and companyId are required. received: eFlowId: ${eFlowId}, companyId: ${companyId}`
      );
    }
    setEflowId(eFlowId);
    setOrganizationId(companyId);
    setAnalizeType(analizeType);
  };


  const save = async (withNextStep: boolean = true) => {

    if (!currentEflowStep || !currentEflowStepId) return;
    
    if (hasStepBeenUpdated || hasFilesBeenChanged)
      if ('id' in currentEflowStep) {
        //if steps contain id inside its body, it means that it is already saved in the database;
        //therefore, we need to update it
        await update({
          eFlowStep: currentEflowStep,
          currentEflowStepId: currentEflowStepId,
          updateElement: updateEflowStepElement,
          organizationId: organizationId,
          files: files
        });
      } else {
        const eflowStep = currentEflowStep as NumeredEFlowStep;
        await  saveEflowStep(
          {
            ...eflowStep
          } as CreateEflowStepInput, files,
          currentEflowStepId
        );
      }


    if (withNextStep) {
      await addNewEmptyStep();
    }

  };

  return <useEflowStepsOutput>{
    isReady,
    isLoading: isLoading || isSynchronizing,
    currentEflowStep,
    currentEflowStepId,
    allEflowSteps: allSavedEflowSteps,
    save,
    validate,
    loadHook,
    removeStep: deleteEflowStepOnlineAndOffline,
    removeAlreadyUploadedFiles,
    updateEflowFiles,
    updateCurrentEflowStep,
    changeCurrentEflowStep
  };
};