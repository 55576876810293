import {
  DashboardWidgetDto,
  SingleWidgetInput,
} from '@/__generated__/graphql.ts';

export const RemoveWidgets = (
  widgetId: string,
  currentWidgets?: DashboardWidgetDto[],
): SingleWidgetInput[] => {
  if (!currentWidgets) {
    return [];
  }

  return currentWidgets
    .filter((widget) => widget.layoutKey !== widgetId)
    .map((widget) => ({
      layoutKey: widget.layoutKey,
      layoutModel: widget.layoutModel,
    }));
};
