import { ReactNode, createContext, useContext } from 'react';

import { useReportModal } from '@kpi/hooks';

type ReportModalContextProps = ReturnType<typeof useReportModal>;

const ReportModalContext = createContext<ReportModalContextProps | undefined>(
  undefined,
);

export const ReportModalProvider = ({
  children,
  close,
}: {
  children: ReactNode;
  close: () => void;
}) => {
  const ReportModalHookValue = useReportModal(close);

  return (
    <ReportModalContext.Provider value={ReportModalHookValue}>
      {children}
    </ReportModalContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useReportModalContext = () => {
  const context = useContext(ReportModalContext);

  if (!context) {
    throw new Error(
      'useReportModalContext must be used within a ReportModalProvider',
    );
  }

  return context;
};
