import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { IBoxModalProps, ModalContainerProps } from './ModalContainer.types';

export const ModalContainer = ({
  width,
  height,
  children,
  closeModal,
  headerText,
  sx,
  headerPadding = '20px 16px 16px 24px',
  headerComponent,
}: ModalContainerProps) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh', minWidth: width }}
    >
      <ModalContent theme={theme} width={width} height={height} sx={sx}>
        {/* header of Modal*/}
        <Stack
          padding={headerPadding}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <HeaderContainer>
            {headerComponent}
            <Typography variant="h4" data-testid="headerText">
              {headerText}
            </Typography>
          </HeaderContainer>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box>{children}</Box>
      </ModalContent>
    </Stack>
  );
};

const ModalContent = styled(Box)<IBoxModalProps>(
  ({ theme, width, height }) => ({
    borderRadius: 10,
    width: width,
    height: height,
    zIndex: 3,
    maxHeight: '90vh',
    background: theme.palette.common.white,
    minWidth: '300px',
    position: 'relative',
    overflow: 'auto',
  }),
);

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});
