import { captureException } from '@sentry/react';

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import {
  FindSingleOrganizationDocument,
  OrganizationModules,
  Query,
} from '@/__generated__/graphql';
import { client } from '@/apollo';
import { OrganizationStore } from '@/stores';

export const useOrganizationStore = create<OrganizationStore>()(
  persist(
    (set) => ({
      organization: {},
      organizationModules: [],
      keyUsers: [],
      logo: '',
      organisationName: '',
      setOrganization: async (url): Promise<void | string> => {
        try {
          const res = await fetchData(url);

          if (!res?.findSingleOrganization) return;

          const {
            modules,
            name: organisationName,
            keyUsers,
            logo,
          } = res.findSingleOrganization;

          set((state) => {
            if (state.organization[url])
              return {
                organization: { ...state.organization },
                organizationModules: modules,
                organisationName,
                keyUsers: keyUsers || [],
                logo,
              };

            const newState = {
              ...state.organization,
              [url]: res.findSingleOrganization.id,
            };
            return {
              organization: newState,
              organizationModules: modules,
              organisationName,
              keyUsers: keyUsers || [],
              logo,
            };
          });
        } catch (err) {
          return 'MissingOrganization';
        }
      },
      setModules: (modules: OrganizationModules[]) => {
        set(() => ({ organizationModules: modules }));
      },
      resetStorage: () => {
        set(() => ({ organization: {} }));
        localStorage.removeItem('organization-storage');
      },
    }),
    {
      name: 'organization-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

const fetchData = async (url: string) => {
  try {
    const { data, error } = await client.query<Query>({
      query: FindSingleOrganizationDocument,
      variables: { input: { url } },
      fetchPolicy: 'network-only',
    });

    if (error) return;
    return data;
  } catch (err) {
    captureException(err);
    return;
  }
};
