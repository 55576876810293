import { Children, Fragment } from 'react';

import { Box, Divider, styled } from '@mui/material';

import { theme } from '@/theme';

import { ModalContainerProps } from './ModalContainerProps.types.ts';

export const ModalWithDividers = (props: ModalContainerProps) => {
  const filteredChildren = Children.toArray(props.children).filter(
    (child) => !!child,
  );

  return (
    <Container>
      {filteredChildren.map((child, index) => (
        <Fragment key={index}>
          {child}

          {index < filteredChildren.length - 1 && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                height: '100%',
              }}
            />
          )}
        </Fragment>
      ))}
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  background: theme.palette.common.white,
  padding: 10,
  borderRadius: 10,
});
