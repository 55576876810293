/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { AvatarMenu } from '@/core/components/AvatarMenu/AvatarMenu';
import { NavbarMainContainer, NavbarUserMenu } from '@/core/styles/Navbar';

export const Navbar = () => {
  const { t } = useTranslation('AI');

  const params = useParams();

  return (
    <NavbarMainContainer>
      <NavbarLinks>
        <NavbarApps
          sx={{
            width: 200,
          }}
          to={`/${params.companyName ? params.companyName : ''}`}
        >
          <img
            style={{
              width: 200,
              height: '40px',
              display: 'block',
            }}
            src="/assets/digital-factory-ai-logo.svg"
            alt="Apps"
          />
        </NavbarApps>

        <NavbarNavigation>
          {/* Use the custom NavLink component */}
          {/*production*/}
          <NavbarNavLink
            to="production"
            className={({ isActive }) => {
              return isActive ? 'active' : '';
            }}
          >
            {t('navbar.production')}
            {/*sales*/}
          </NavbarNavLink>
          <NavbarNavLink
            to="sales"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t('navbar.sales')}
          </NavbarNavLink>
        </NavbarNavigation>
      </NavbarLinks>

      <NavbarUserMenu>
        <AvatarMenu />
      </NavbarUserMenu>
    </NavbarMainContainer>
  );
};

const NavbarLinks = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const NavbarApps = styled(Link)({
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 8,
});

const NavbarNavigation = styled('div')({
  marginLeft: 48,
  display: 'flex',
  columnGap: 32,
});

const NavbarNavLink = styled(NavLink)(({ theme }) => ({
  color: `var(--text-text-primary, ${theme.palette.text.primary})`,
  fontFamily: 'Inter',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  textDecoration: 'none',
  borderRadius: 'var(--radius-basic, 8px)',
  display: 'flex',
  padding: '6px 12px',
  alignItems: 'center',
  gap: '4px',
  '&.active': {
    background: `var(--primary-primary-50, ${theme.palette.primary[50]})`,
  },
}));
