import { useTranslation } from 'react-i18next';

import { useCalculateReport } from '@kaizen/pages/reports/hooks';

import { Stack } from '@mui/material';

import { StatisticBlock } from '@core/components';

import { useKaizenReportContext } from '../../context/kaizenReport.context';

export const StatisticBlockGroup = () => {
  const { isLoading, report } = useKaizenReportContext();

  const { t } = useTranslation('kaizen');
  const { t: commonT } = useTranslation();

  const {
    percentageOfAcceptedSubmissions,
    percentageOfImplementedSubmissions,
  } = useCalculateReport(report);

  return (
    <Stack flexDirection={'row'} gap={3} flexWrap={'wrap'}>
      <StatisticBlock
        title={`${report?.totalNumberOfSubmissions || 0}`}
        subtitle={t('report.totalNumberOdSubmissions')}
        isLoading={isLoading}
      />
      <StatisticBlock
        title={`${percentageOfAcceptedSubmissions}%`}
        subtitle={t('report.acceptedSubmissions')}
        isLoading={isLoading}
      />
      <StatisticBlock
        title={`${percentageOfImplementedSubmissions}%`}
        subtitle={t('report.implementedSubmissions')}
        isLoading={isLoading}
      />
      <StatisticBlock
        title={`${report?.avgProcessingTime || 0} ${commonT('common.days')}`}
        subtitle={t('report.avgProcessingTime')}
        isLoading={isLoading}
      />
      <StatisticBlock
        title={`${report?.totalParticipantsNumber || 0}`}
        subtitle={t('report.totalParticipants')}
        isLoading={isLoading}
      />
    </Stack>
  );
};
