import { Clear } from '@mui/icons-material';
import { Box, IconButton, Stack, styled } from '@mui/material';

import { OperationIcon } from '@/pages/e-flow/components';

import { OperationRowProps } from './OperationsModule.types';

export const OperationRow = (props: OperationRowProps) => {
  return (
    <RowStack>
      <ImageContainer>
        <OperationIcon operation={props.operation.operationType} />
      </ImageContainer>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>{props.operation.name}</Box>
        {props.handleDelete && (
          <IconButton sx={{ width: '40px' }} onClick={props.handleDelete}>
            <Clear />
          </IconButton>
        )}
      </Stack>
    </RowStack>
  );
};

const ImageContainer = styled(Box)({
  width: '64px',
  height: '56px',
  padding: '0 16px 0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const RowStack = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
});
