import React from 'react';

import { StatusLabel } from '@kaizen/components/StatusLabel/StatusLabel.tsx';

import { TableCell, TableRow } from '@mui/material';

import { KaizenStatuses } from '@/__generated__/graphql.ts';

import { RowProps } from './Points.types.ts';

export const PointsRow: React.FC<RowProps> = ({ status, points }) => {
  return (
    <TableRow key={status}>
      <TableCell>
        <StatusLabel variant={status as unknown as KaizenStatuses} />
      </TableCell>
      <TableCell>{points}</TableCell>
    </TableRow>
  );
};
