import { Box, Typography, styled } from '@mui/material';

import { Button, CustomModal, ModalContainer } from '@core/components';
import { InfoModalProps } from '@core/components/Modals/InfoModal/InfoModal.types.ts';

export const InformationModal = (props: InfoModalProps) => {
  return (
    <CustomModal display={props.display}>
      <ModalContainer
        closeModal={props.closeModal}
        headerText={props.headerText}
        sx={{ ...props.sx, minWidth: '500px' }}
      >
        <ModalBody>
          <CustomText variant="bodyRegular" sx={{ fontSize: 16 }}>
            {props.content}
          </CustomText>
          <ButtonContainer>
            <Button variant={'contained'} onClick={props.onConfirm}>
              {props.confirmText}
            </Button>
          </ButtonContainer>
        </ModalBody>
      </ModalContainer>
    </CustomModal>
  );
};

const ModalBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '0 20px 20px',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
});

const CustomText = styled(Typography)({
  marginBottom: '20px',
  whiteSpace: 'pre-wrap',
});
