import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, TextFieldProps } from '@mui/material';

import { GlobalSystemRoles } from '@core/auth/GlobalSystemRoles.ts';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { CustomAutocomplete } from '@/core/components/UserEdit/style.ts';
import { useUserStore } from '@/stores';
import { getLowerRoles } from '@/utils';

type PermissionSelectProps = {
  /**
   * Register function from useForm hook
   */
  register: TextFieldProps;
  /**
   * Default value for the select
   */
  defaultValue?: SystemRoles;
  /**
   * Organization id that user that is being edited belongs to
   */
  organizationId?: string;
};

export const PermissionSelect = (props: PermissionSelectProps) => {
  const [permissions, setPermissions] =
    useState<SystemRoles[]>(GlobalSystemRoles);

  const { userGroups, organizationId } = useUserStore();

  useEffect(() => {
    //Defines user permissions based on user groups
    const possiblePermissions = getLowerRoles(userGroups);

    if (organizationId !== props.organizationId)
      setPermissions(
        possiblePermissions.filter(
          (permission) =>
            permission !== SystemRoles.KeyUser &&
            permission !== SystemRoles.SystemAdmin,
        ),
      );
    else setPermissions(possiblePermissions);
  }, [organizationId, props.organizationId, userGroups]);

  const { t } = useTranslation();

  return (
    <CustomAutocomplete
      disabled={permissions.length === 0}
      size="small"
      options={permissions}
      defaultValue={props.defaultValue}
      noOptionsText={t('error.noResult')}
      renderInput={(params) => (
        <TextField
          {...props.register}
          {...params}
          label={t('systemSettings.permissionTypes')}
          sx={{ width: '120%' }}
        />
      )}
    />
  );
};
