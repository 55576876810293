import { useEffect, useState } from 'react';

import { NotificationsLimit } from '@core/constants';
import { useCompanyId, useTablePagination } from '@core/hooks';

import { useFindAllNotificationsLazyQuery } from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

export const useFetchNotifications = () => {
  const { cursor, setCursor } = useTablePagination();

  const [count, setCount] = useState(0);

  const { companyId } = useCompanyId();
  const { userUuid } = useUserStore();

  const [fetchAllNotifications, { data }] = useFindAllNotificationsLazyQuery();

  useEffect(() => {
    if (!companyId || !userUuid) return;

    void (async () => {
      await fetchAllNotifications({
        variables: {
          input: {
            userId: userUuid,
            cursor,
            limit: NotificationsLimit,
            organizationId: companyId,
          },
        },
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, companyId, userUuid]);

  return {
    count,
    cursor,
    total: data?.findNotifications.total || 0,
    notifications: data?.findNotifications.items || [],
    setCount,
    setCursor,
  };
};
