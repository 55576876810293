import { z } from 'zod';
import { OperationTypesEnum } from '@/__generated__/graphql.ts';

export const FlowStepSchema = () =>
  z.object({
    id: z.string().optional(),
    operationName: z.string().min(5).max(250),
    operation: z.object({
      name: z.string().min(1),
      operationType: z.nativeEnum(OperationTypesEnum),
    }),
  });
