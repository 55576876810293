import { useCallback, useState } from 'react';

/**
 * @description This hook is used to manage the snackbar state
 */

export const useSnackbar = () => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const openSnackbar = useCallback((message: string) => {
    setSnackBarMessage(message);
    setIsSnackBarOpen(true);
  }, []);

  const closeSnackbar = useCallback(() => {
    setIsSnackBarOpen(false);
    setSnackBarMessage('');
  }, []);

  return {
    isSnackBarOpen,
    snackBarMessage,
    openSnackbar,
    closeSnackbar,
  };
};
