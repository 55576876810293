import { useCommentCategories } from '@e-flow/components/CommentModule/hooks';
import { useHandleEflowCommentChange } from '@e-flow/components/CommentModule/hooks/useHandleEflowCommentChange/useHandleEflowCommentChange.tsx';

import {
  UseCreateEflowCommentInput,
  UseCreateEflowCommentType,
} from './useCreateEflowComment.type';

export const useCreateEflowComment = (
  props: UseCreateEflowCommentInput,
): UseCreateEflowCommentType => {
  const handleEflowLiveUpdate = useHandleEflowCommentChange();
  const commentsCategory = useCommentCategories(props);

  return {
    ...commentsCategory,
    ...handleEflowLiveUpdate,
  };
};
