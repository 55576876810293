import { useTranslation } from 'react-i18next';

import { ReportSections } from '..';
import { Comment } from './Comment';
import { CommentsProps } from './types';

export const Comments = (props: CommentsProps) => {
  const { t } = useTranslation('eflow');

  return (
    <ReportSections
      title={t('newAnalysis.comments.title')}
      isLoading={props.isLoading}
      flexDirection="column"
    >
      {props.comments.map((comment, index) => (
        <Comment key={index} data={comment} />
      ))}
    </ReportSections>
  );
};
