import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AsIsFlowDashboard } from '@e-flow/pages/flowDashboard/Dashboards/AsIs/AsIsFlowDashboard.tsx';
import { ToBeFlowDashboard } from '@e-flow/pages/flowDashboard/Dashboards/ToBe/ToBeFlowDashboard.tsx';
import { Navbar } from '@e-flow/pages/flowDashboard/components';
import { useMainFlowDashboard } from '@e-flow/pages/flowDashboard/hooks/useMainFlowDashboard.tsx';
import { useNetworkState } from '@uidotdev/usehooks';

import { Box, styled } from '@mui/material';

import { useSplitScreen } from '@core/hooks';
import { useFlowRedirects } from '@core/redirects';

import {
  Close,
  FullScreenLoading,
  LoadingModal,
  ResizableDivider,
  SplitScreenButton,
  YouAreOffline,
} from '@/core/components';
import { theme } from '@/theme';

import { AsIsFlowDashboardButton } from '../../components';
import { useCopied } from './hooks';

export const MainFlowDashboard = () => {
  const { t: eFlowTf } = useTranslation('eflow');

  const { flowId } = useParams();

  const { online: isOnline } = useNetworkState();

  const {
    isFirstScreenDisplayed,
    isSecondScreenDisplayed,
    areBothScreensDisplayed,
    dividerPosition,
    resizableDividerProps,
    setDisplaySecondScreen,
    setDisplayFirstScreen,
    setDisplayBothScreens,
  } = useSplitScreen();

  const { redirectToAsIsFlow, redirectToToBeFlow } = useFlowRedirects();

  const { isLoading, eFlow } = useMainFlowDashboard();

  const { t } = useTranslation('eflow');

  const [asIsEflowStepCount, setAsIsEflowStepCount] = useState<number>(0);
  const [toBeEflowStepCount, setToBeEflowStepCount] = useState<number>(0);

  const { isCopied, isCopyModalOpen, setIsCopyModalOpen, setCopied } =
    useCopied(toBeEflowStepCount);

  if (!isOnline)
    return (
      <>
        <Navbar
          title={eFlow?.name || ''}
          status={eFlow?.status}
          showAddAnalyzeButton={asIsEflowStepCount !== 0}
        />
        <YouAreOffline
          title={eFlowTf('eflow.table.offline.text')}
          subtitle={eFlowTf('eflow.table.offline.subtitle')}
          buttonPrimaryText={eFlowTf('eflow.table.offline.buttonAsIs')}
          onButtonPrimaryClick={redirectToAsIsFlow}
          buttonSecondaryText={eFlowTf('eflow.table.offline.buttonToBe')}
          onButtonSecondaryClick={redirectToToBeFlow}
        />
      </>
    );

  if (isLoading) return <FullScreenLoading />;

  return (
    <>
      <Navbar
        title={eFlow?.name || ''}
        status={eFlow?.status}
        showAddAnalyzeButton={asIsEflowStepCount !== 0}
      />
      <Content>
        {/** Display As Is DashboardTabs */}
        {isFirstScreenDisplayed && (
          <AsIsFlowDashboard
            eFlowId={flowId as string}
            parameters={eFlow?.parameters || []}
            toBeStepsCount={toBeEflowStepCount}
            setStepsCount={setAsIsEflowStepCount}
            setCopied={setCopied}
            actionButton={
              asIsEflowStepCount !== 0 && (
                <>
                  <AsIsFlowDashboardButton
                    flowId={flowId as string}
                    setDisplayBothScreens={setDisplayBothScreens}
                  />
                </>
              )
            }
          />
        )}

        {isSecondScreenDisplayed && (
          <ToBeFlowDashboard
            parameters={eFlow?.parameters || []}
            eFlowId={flowId!}
            asIsStepsCount={asIsEflowStepCount}
            setStepsCount={setToBeEflowStepCount}
            setCopied={setCopied}
            actionButton={
              <SplitScreenButton
                textVariant="bodyMedium"
                onClick={setDisplayBothScreens}
                backgroundColor={theme.palette.grey[100]}
                textColor={theme.palette.common.black}
                fontSize={14}
                text={t('common.asIs')}
              />
            }
          />
        )}
        {/** Display To Be DashboardTabs */}
        {areBothScreensDisplayed && (
          <DividedScreen>
            <DividedScreenCell sx={{ width: dividerPosition }}>
              <AsIsFlowDashboard
                parameters={eFlow?.parameters || []}
                eFlowId={flowId!}
                setStepsCount={setAsIsEflowStepCount}
                setCopied={setCopied}
                actionButton={
                  <Close
                    wrapperProps={{
                      title: '',
                      placement: 'bottom',
                      onClick: setDisplaySecondScreen,
                    }}
                  />
                }
              />
            </DividedScreenCell>
            <ResizableDivider {...resizableDividerProps} />
            <DividedScreenCell
              sx={{
                width: `calc(100vw - ${dividerPosition}px)`,
              }}
            >
              <ToBeFlowDashboard
                parameters={eFlow?.parameters || []}
                eFlowId={flowId!}
                setStepsCount={setToBeEflowStepCount}
                asIsStepsCount={asIsEflowStepCount}
                setCopied={setCopied}
                actionButton={
                  <Close
                    wrapperProps={{
                      title: '',
                      placement: 'bottom',
                      onClick: setDisplayFirstScreen,
                    }}
                  />
                }
              />
            </DividedScreenCell>
          </DividedScreen>
        )}
      </Content>
      <LoadingModal
        isLoading={isCopied}
        withTimeout={true}
        onTimeoutText={t('copy.tooLongCopyTime')}
        doneText={t('copy.done')}
        text={t('eflow.table.copyingOperationsSuccess')}
        headerText={t('eflow.table.copyingOperations')}
        closeModal={() => {
          setIsCopyModalOpen(false);
        }}
        open={isCopyModalOpen}
        onTimeoutButtonAction={() => {
          window.location.reload();
        }}
        onTimeoutButtonLabel={t('copy.closeAndSync')}
        timeout={15000}
      />
    </>
  );
};

const Content = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: 'calc(100vh - 57px)',
});

const DividedScreen = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
});

const DividedScreenCell = styled(Box)({
  minHeight: 'calc(100vh - 57px)',
  height: 'calc(100vh - 57px)',
  maxHeight: 'calc(100vh - 57px)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '50%',
});
