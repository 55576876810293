import { useTranslation } from 'react-i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Menu } from '@mui/material';

import { CustomMenuItem } from '@/core/components';

import { UserRowMenuProps } from './UserRow.types';

export const UserRowMenu = (props: UserRowMenuProps) => {
  const { t } = useTranslation('permissions');
  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.handleClose}
    >
      <CustomMenuItem
        text={t('permissionModal.removeAccess')}
        onClick={props.setDeleteModalOpen}
        icon={<DeleteOutlineIcon />}
      />
    </Menu>
  );
};
