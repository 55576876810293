/* eslint-disable @typescript-eslint/no-misused-promises */
import { ReactNode } from 'react';

import { useAddKpiRateContext } from '@kpi/contexts';
import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';

import { styled } from '@mui/material';

export const MainContainer = ({ children }: { children: ReactNode }) => {
  const {
    useManageKpiModalState: { handleSubmit },
    useAddKpiRateNetwork: { submitData },
    useUpdateKpiRateNetwork: { submitUpdateData },
  } = useHandleAddKpiRateContext();

  const { isUpdateModal } = useAddKpiRateContext();

  return (
    <MainContainerForm
      onSubmit={
        isUpdateModal
          ? handleSubmit(submitUpdateData)
          : handleSubmit(submitData)
      }
      style={isUpdateModal ? { paddingTop: '0' } : {}}
    >
      {children}
    </MainContainerForm>
  );
};

const MainContainerForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: 20,
  overflow: 'auto',
  maxHeight: '700px',
});
