import {
  KaizenStatuses,
  KaizenSubmissionNumberDto,
  KaizenSubmissionProcessingTimeDto,
} from '@/__generated__/graphql';

export const calculateTotalSubmissionAccepted = (
  statuses: KaizenSubmissionNumberDto,
) => {
  return (
    statuses.ACCEPTED +
    statuses.IMPLEMENTED +
    statuses.IN_PROGRESS +
    statuses.IMPLEMENTATION_ACCEPTED +
    statuses.IMPLEMENTATION_REJECTED +
    statuses.TODO
  );
};
export const legendPieChart: Array<
  keyof Omit<KaizenSubmissionNumberDto, '__typename'>
> = [
  KaizenStatuses.Adjustment,
  KaizenStatuses.SupervisorAssessment,
  KaizenStatuses.CommissionAssessment,
  KaizenStatuses.Accepted,
  KaizenStatuses.Todo,
  KaizenStatuses.InProgress,
  KaizenStatuses.ImplementationAccepted,
  KaizenStatuses.ImplementationRejected,
  KaizenStatuses.Implemented,
];

export const legendBarChart: Array<
  keyof Omit<KaizenSubmissionProcessingTimeDto, '__typename'>
> = [
  KaizenStatuses.Adjustment,
  KaizenStatuses.SupervisorAssessment,
  KaizenStatuses.CommissionAssessment,
  KaizenStatuses.Accepted,
  KaizenStatuses.Todo,
  KaizenStatuses.InProgress,
  KaizenStatuses.ImplementationRejected,
];
