import { Dispatch, SetStateAction } from 'react';

import { EFlow, EflowStepFragmentFragment } from '@/__generated__/graphql';

import { Row } from './EflowTable/EflowTable.types';

//TODO @bryndalski - fix redundant variable && unit tests would be nice
export const getRows = (steps: EFlow['asIsSteps'] | EFlow['toBeSteps']) => {
  return steps!.map((step, index) => {
    if (!step) return;

    const parameters: { [key: string]: number | string } = {};
    if (step.parameters)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys((step as EflowStepFragmentFragment).parameters).forEach(
        (key) => {
          parameters[key] = step.parameters[key].value;
        },
      );

    return {
      id: step.id,
      index: index + 1,
      operation: step.operation.operationType,
      operationName: step.operationName,
      ...parameters,
      attachments: step.files?.length || 0,
      comments: step.comments?.length || 0,
      isActive: step.isActive,
    };
  });
};

// Function to process the row update
export const processRowUpdate = (
  newRow: Row,
  setRows: Dispatch<SetStateAction<Row[]>>,
) => {
  // Make the HTTP request to save in the backend
  setRows((prevRows) => {
    const newRows = prevRows.map((row) => {
      // Update the row
      if (row.id === newRow.id) return newRow;

      return row;
    });
    // Return the new rows
    return newRows;
  });
  return newRow;
};
