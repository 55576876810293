import { useEffect, useMemo } from 'react';
import { UseFormRegister, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { useNetworkState } from '@uidotdev/usehooks';

import { EFlowStepCommentDto, Maybe } from '@/__generated__/graphql';
import { CommentSchema } from '@/core/components';
import { useCompanyId, useUpdateComment } from '@/core/hooks';
import { useUserStore } from '@/stores';

import { useCommentCategories } from '../CommentModule/hooks';
import { EditCommentType } from './EditComment.type';

export const useEditComment = (props: EditCommentType) => {
  const { t } = useTranslation('eflow');

  const { companyId } = useCompanyId();
  const { userUuid } = useUserStore();

  const { commentsCategory, selectedSymbol, setSelectedSymbol } =
    useCommentCategories(props);

  const { updateComment } = useUpdateComment(
    props.commentId,
    companyId,
    userUuid,
  );

  const { online: isOnline } = useNetworkState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      content: props.commentContent,
      commentCategory: commentsCategory.find(
        (commentType) =>
          commentType.categoryName === props.commentCategory.split(' - ')[1],
      )!.symbol,
    },
    resolver: zodResolver(CommentSchema(t)),
  });

  const registerContent = () => register('content');
  const watchCategory = watch('commentCategory');

  useMemo(() => {
    setSelectedSymbol(watchCategory || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCategory]);

  useEffect(() => {
    if (!isOnline) props.onCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  const setCommentsCategory = (selectedSymbol: string) => {
    setSelectedSymbol(selectedSymbol);
    setValue('commentCategory', selectedSymbol);
  };

  const submitForm = async (data: {
    commentCategory: string;
    content: Maybe<string> | undefined;
  }) => {
    try {
      if (props.commentId !== undefined)
        await updateComment(
          `(${data.commentCategory}) - ${commentsCategory.find((commentCategory) => commentCategory.symbol === data.commentCategory)?.categoryName}`,
          data.content as string,
        );
      props.onEdit({
        content: data.content as string,
        commentCategory: data.commentCategory,
        id: props.commentId,
        createdAt: props.createdAt,
      });
      props.handleCloseForm();
    } catch (e) {
      throw new Error(e as string);
    }
  };

  return {
    selectedSymbol,
    isContentError: !!errors.content,
    isCategoryError: !!errors.commentCategory,
    contentErrorMessage: errors.content?.message as string,
    handleSubmit,
    setSelectedSymbol: setCommentsCategory,
    registerContent:
      registerContent as unknown as () => UseFormRegister<EFlowStepCommentDto>,
    commentsCategory,
    submitForm,
  };
};
