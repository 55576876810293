import { useEffect, useState } from 'react';

import { useFetchSettlementPeriods } from '../../module-settings/SettlementPeriods/hooks/useSettlemetPeriod/Functions';
import { PeriodProps } from './SumbissionSelect.types';

export const useSubmissionSelect = (multiselect: boolean) => {
  const { settlementPeriods, isLoading } = useFetchSettlementPeriods(true);

  const [data, setData] = useState<PeriodProps[]>([]);

  const [selectedPeriods, setSelectedPeriods] = useState<
    PeriodProps[] | PeriodProps
  >([]);

  const onChange = (value: PeriodProps | PeriodProps[] | null) => {
    if (!value) setSelectedPeriods([]);
    else setSelectedPeriods(value);
  };

  useEffect(() => {
    if (!multiselect) {
      setSelectedPeriods({ name: '', value: '' });
    }

    const periods: PeriodProps[] = settlementPeriods.map((period) => {
      const returnValue = {
        value: period.id,
        name: period.periodName,
      };

      if (period.isActive)
        setSelectedPeriods(multiselect ? [returnValue] : returnValue);

      return returnValue;
    });

    setData(periods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementPeriods]);

  return {
    onChange,
    data,
    selectedPeriods,
    isLoading,
  };
};
