import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeeRoles } from '@core/helpers';

import { DashboardTypesEnum } from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

export const useDashboardTabs = () => {
  const { t } = useTranslation('kpi');
  const { userGroups } = useUserStore();

  const createTabs = useCallback(() => {
    const tabs = [];

    tabs.push({
      tabName: t('dashboards.names.organization'),
      dashboardType: DashboardTypesEnum.KpiOrganizationDashboard,
    });

    if (!EmployeeRoles.isUser(userGroups)) {
      tabs.push({
        tabName: t('dashboards.names.organizationUnit'),
        dashboardType: DashboardTypesEnum.KpiOrganizationUnitDashboard,
      });
    }

    tabs.push({
      tabName: t('dashboards.names.employee'),
      dashboardType: DashboardTypesEnum.KpiEmployeeDashboard,
    });

    return tabs;
  }, [t, userGroups]);

  return { createTabs };
};
