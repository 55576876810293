import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ParameterSelectTypes } from '@e-flow/components/Selects/ParameterSelect/ParameterSelect.types.ts';
import { SystemParameterKeysEnum } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum.ts';

import { OptionsSelect } from '@core/components';

import { OperationTypesEnum, ParameterTypes } from '@/__generated__/graphql.ts';

export const ParameterSelect = (props: ParameterSelectTypes) => {
  const { t } = useTranslation('eflow');

  const translateParameters = useMemo(() => {
    return props.items?.map((parameter) => {
      let parameterName = parameter.name;

      if (parameter.type === ParameterTypes.System) {
        parameterName = t(
          `newAnalysis.parameters.systemParams.${parameter.name as SystemParameterKeysEnum}`,
        );
      }
      return {
        rawName: parameter.name,
        name: parameterName,
        unit: parameter.unit,
      };
    });
  }, [props.items, t]);

  return (
    <OptionsSelect
      items={translateParameters}
      valueKey={'rawName'}
      labelKey={'name'}
      value={props.value || ''}
      name={t('reports.balanceChart.parameters')}
      onChange={(event) =>
        props.onChange(event.target.value as OperationTypesEnum)
      }
    />
  );
};
