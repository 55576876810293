import { useTranslation } from 'react-i18next';

import { Box, styled } from '@mui/material';

import { SearchTextField } from '@/core/components';

import { SubmissionSelect } from '../../components';
import { useKaizenRankingFiltersContext } from './contexts/useKaizenRankingFilters.context';

export const KaizenRankingFilters = () => {
  const { t } = useTranslation('kaizen');

  const { setSearch, setPeriodsIds, search } = useKaizenRankingFiltersContext();

  return (
    <FilterContainer>
      <SearchTextField
        placeholder={t('commission.searchEmployee')}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <SubmissionSelect
        width="300px"
        onSelect={(periodIds) => setPeriodsIds(periodIds)}
      />
    </FilterContainer>
  );
};

const FilterContainer = styled(Box)({
  gap: '16px',
  display: 'flex',
});
