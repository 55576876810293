import { useTranslation } from 'react-i18next';

import { RowStack } from '@organizations/styles';

import { InputAdornment, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { ButtonSection } from './ButtonSection';
import { useSingleKpiReportContext } from './context';

export const ReportSingleKpiContent = () => {
  const { t } = useTranslation('kpi');

  const { data, date, setDate, entryValue, setEntryValue } =
    useSingleKpiReportContext();

  return (
    <Stack gap="24px" padding={'0 24px 24px 24px'}>
      <TextField disabled size="small" value={data?.rateName} />

      <RowStack gap="16px">
        <DatePicker
          slotProps={{
            textField: { size: 'small' },
          }}
          sx={{ width: '35%' }}
          views={['month']}
          value={date}
          label={t('report.month')}
          onChange={(date) => {
            setDate(date);
          }}
        />

        <TextField
          sx={{ width: '65%' }}
          size="small"
          value={entryValue}
          onChange={(e) => {
            setEntryValue(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {data?.unit.unitShortcut}
              </InputAdornment>
            ),
          }}
        />
      </RowStack>
      <ButtonSection />
    </Stack>
  );
};
