import { TableRow } from '@mui/material';

import { StatisticRowTypes } from '@core/components/Statistica/StatisticTable/StatisticRow/StatisticRow.types.ts';

import { useStatisticCells } from './useStatisticCells.tsx';

export const StatisticRow = ({
  displayRowUnit = false,
  cellContentAlignment = 'right',
  isLoading = false,
  withBorder = true,
  ...props
}: StatisticRowTypes) => {
  const { renderCells } = useStatisticCells();

  return (
    <TableRow>
      {renderCells({
        displayRowUnit,
        cellContentAlignment,
        isLoading,
        withBorder,
        ...props,
      })}
    </TableRow>
  );
};
