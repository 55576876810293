import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Theme,
} from '@mui/material';
import { styled } from '@mui/styles';

import { useCustomDataGrid } from '@core/components/CustomDataGrid/hooks/useCustomDataGrid.tsx';
import { CustomDataGridTypes } from '@core/components/CustomDataGrid/types';

export const CustomDataGrid = <T,>(props: CustomDataGridTypes<T>) => {
  const { addStickyFirstRow, addStickyLastRow } = useCustomDataGrid(props);

  const DataGridConfig = (
    <>
      <StyledDataGridContainer>
        <TableContainer sx={{ maxHeight: '80%', ...props.containerStyles }}>
          <Table
            stickyHeader={props.stickyHeader}
            sx={
              {
                ...addStickyLastRow,
                ...addStickyFirstRow,
              } as SxProps<Theme>
            }
          >
            <TableHead>{props.header}</TableHead>
            <TableBody>
              {props.rowData?.map((element, index) =>
                props.row(element, index, props.hideColumns),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledDataGridContainer>
    </>
  );

  if (props.infiniteScroll) {
    return (
      <InfiniteScroll {...props.infiniteScrollProps}>
        {DataGridConfig}
      </InfiniteScroll>
    );
  }

  return DataGridConfig;
};

const StyledDataGridContainer = styled(Box)({
  width: '100%',
  height: '100%',
});
