import {
  handleCommentsOnClient,
  handleCommentsOnServer,
} from '@e-flow/components/CommentModule/hooks/functions/HandleCommentCheck.ts';

import { UseSetInReportType } from './useSetInReport.type';

export const useSetInReport = (hookProps: UseSetInReportType) => {
  const setChecked = (props: {
    commentId?: string;
    commentIndex: number;
    isChecked: boolean;
  }) => {
    if (props.commentId && hookProps.commentModels) {
      _setCheckedForCommentOnServer(props.commentId, props.isChecked);
    }
    _setCheckedForCommentOnClient(props.commentIndex, props.isChecked);
  };

  /**
   * Function used to set comments which are already sent to the server
   * as checked in the report
   */
  function _setCheckedForCommentOnServer(
    commentId: string,
    isChecked: boolean,
  ) {
    const newEflowReportComments = handleCommentsOnServer(
      {
        commentId: commentId,
        showInReport: isChecked,
      },
      hookProps.commentModels!,
    );

    hookProps.updateCommentModels(newEflowReportComments);
  }

  /**
   * Function used to set comments which are not sent to the server
   * as checked in the report
   */
  function _setCheckedForCommentOnClient(
    commentIndex: number,
    showInReport: boolean,
  ) {
    const updatedClientComments = handleCommentsOnClient(
      hookProps.currentComments,
      commentIndex,
      showInReport,
    );

    hookProps.onFlowStepChange(updatedClientComments);
  }

  return {
    setIsInTheReport: setChecked,
  };
};
