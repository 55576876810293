import { ReactNode } from 'react';

import styled from '@emotion/styled';

import { TableCell } from '@mui/material';

import { theme } from '@/theme';

type KpiTableCellProps = {
  children: ReactNode;
  textAlignment?: 'left' | 'right' | 'center';
  variant?: 'dark' | 'light';
  width?: string;
};

export const KpiTableCell = (props: KpiTableCellProps) => {
  if (props.variant === 'dark') {
    return (
      <StyledKpiCell
        sx={{
          backgroundColor: theme.palette.grey[50],
          textAlign: props.textAlignment ? props.textAlignment : 'left',

          ...(props.width && { width: props.width }),
        }}
      >
        {props.children}
      </StyledKpiCell>
    );
  }

  return (
    <StyledKpiCell
      sx={{
        backgroundColor: 'white',
        textAlign: props.textAlignment ? props.textAlignment : 'left',
        ...(props.width && { width: props.width }),
      }}
    >
      {props.children}
    </StyledKpiCell>
  );
};

const StyledKpiCell = styled(TableCell)({
  height: '30px',
  maxHeight: '30px',
  minWidth: '100px',
});
