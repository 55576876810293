import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';

import { IconWrapper } from '@core/components/Icons/Wrapper/IconWrapper.tsx';
import { IconType } from '@core/components/Icons/Wrapper/type.ts';

export const Close = (props: Omit<IconType, 'wrapperProps["title"]'>) => {
  const { t } = useTranslation();

  return (
    <IconWrapper
      {...props.wrapperProps}
      title={t('common.close')}
      icon={<CloseIcon {...props.iconProps} />}
    />
  );
};
