import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

import { ReportPieChartProps } from './ReportPieChart.types';
import { useReportPieData } from './useReportPieData';

export const ReportPieChart = (props: ReportPieChartProps) => {
  const { valueSum, legendData } = useReportPieData(props.values);

  if (props.isLoading) {
    return (
      <StyledCard sx={{ width: props.width ?? '50%' }}>
        <CardContent>
          <Skeleton />
        </CardContent>
      </StyledCard>
    );
  }

  if (props.values.length === 0) {
    return (
      <StyledCard>
        <CardContent>
          <Typography variant="h4">{props.title}</Typography>
          <Typography variant="h6">{props.emptyMessage}</Typography>
        </CardContent>
      </StyledCard>
    );
  }

  return (
    <StyledCard sx={{ width: props.width, ...(props.cardStyle || {}) }}>
      {props.title && <Typography variant="h4">{props.title}</Typography>}

      <Stack justifyContent={'center'} alignItems={'center'}>
        {props.subtitle && (
          <Stack textAlign={'center'} position={'absolute'}>
            <Typography variant="bodyRegular">{props.subtitle}</Typography>
          </Stack>
        )}

        <PieChart
          series={[
            {
              data: props.values,
              innerRadius: 80,
              cx: '75%',
            },
          ]}
          width={300}
          height={300}
          slotProps={{ legend: { hidden: true } }}
        >
          <StyledText x="50%" y="50%" fontSize={'32px'}>
            {valueSum}
          </StyledText>
        </PieChart>
      </Stack>

      <Stack display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
        {legendData.map((item, index) => {
          return (
            <Stack
              key={index + item.name}
              display={'flex'}
              flexDirection={'row'}
              margin={'7px'}
            >
              <StyledBox sx={{ backgroundColor: item.color }} />
              <Typography variant="bodyRegular">{item.name}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  gap: '16px',
  padding: '24px',
  borderRadius: '8px',
  width: '49%',
  alignItems: 'start',
});

const StyledBox = styled(Box)({
  width: '20px',
  height: '20px',
  marginRight: '5px',
  borderRadius: '5px',
});

const StyledText = styled('text')({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontFamily: 'Inter',
  fontWeight: 600,
  lineHeight: '40px',
});
