import { formatKey } from '@core/hooks/useRecharts/functions/FormatKey.ts';

import { ExtractChartKeyType } from '../types';

export const FormatChartValues = (
  props: ExtractChartKeyType,
  hasTarget: boolean,
  dataRange?: number[],
): { [key: string]: number | string }[] => {
  const chartValues: { [key: string]: number | string }[] =
    dataRange?.map((value) => ({ name: value })) || [];

  props.forEach((chartEntry) => {
    const singleChartEntry: { [key: string]: number | string } = {
      name: '',
    };

    for (const key in chartEntry) {
      if (key === 'name') {
        singleChartEntry['name'] = chartEntry[key] as unknown as number;
        continue;
      }

      singleChartEntry[formatKey(key, 'value')] = chartEntry[key].value;
      if (hasTarget)
        singleChartEntry[formatKey(key, 'target')] = chartEntry[key]
          .target as number;
    }

    if (dataRange) {
      if (dataRange.includes(singleChartEntry.name as unknown as number))
        if ('name' in singleChartEntry)
          chartValues[(singleChartEntry['name'] as number) - 1] =
            singleChartEntry;
    } else chartValues.push(singleChartEntry);
  });

  return chartValues;
};
