import { Route, Routes } from 'react-router-dom';

import { onError } from '@apollo/client/link/error';
import { KpiRoutes } from '@kpi/routes';
import { CompanyRoutes } from '@organizations/index.ts';

import { UserSettings } from '@core/components/userSettings';
import { FlexCenter } from '@core/styles';

import { ErrorContent, ErrorModal } from '@/core/components';
import { AIRoutes } from '@/pages/AI/routes/AIRoutes.tsx';
import { LoggedOut } from '@/pages/auth/pages';
import { ForgotPasswordRoutes, LoginRoutes } from '@/pages/auth/routes';
import { EFlowRoutes } from '@/pages/e-flow';
import { KaizenRoutes } from '@/pages/kaizen';
import { SystemOrganizationRoutes } from '@/pages/organizations';
import { ProtectedRoute } from '@/router';
import { IuseErrorStore, useErrorStore } from '@/stores';

import { LoggedRoutesContextProvider } from './core/contexts/LoggedRoutesContextProvider/LoggedRoutesContextProvider';

export default function App() {
  const { setError, errorCode } = useErrorStore((state: IuseErrorStore) => {
    return {
      setError: state.setError,
      errorCode: state.errorCode,
      errorUuid: state.errorUuid,
    };
  });

  onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      setError('500', graphQLErrors[0].message);
      location.href = '/error';
    }
  });

  return (
    <>
      {errorCode && errorCode.startsWith('5') && <ErrorModal />}
      <Routes>
        <Route
          path="/logged-out"
          index
          element={
            <ProtectedRoute mustBeLoggedIn={false} isSystemRoute={true}>
              <LoggedOut />
            </ProtectedRoute>
          }
        />
        <Route
          path="/error/:errorCode"
          element={
            <FlexCenter sx={{ height: '100vh' }}>
              <ErrorContent />
            </FlexCenter>
          }
        />
        {/*Login routes*/}
        <Route path={'/login/*'} element={<LoginRoutes />} />
        {/*Forget password routes*/}
        <Route path={'/forgot-password/*'} element={<ForgotPasswordRoutes />} />

        {/*<ForgotPasswordRoutes />*/}
        <Route element={<LoggedRoutesContextProvider />}>
          <Route
            path="/organizations/*"
            element={<SystemOrganizationRoutes />}
          />
          <Route path="/users/me" element={<UserSettings />} />
          <Route path={':companyName/AI/*'} element={<AIRoutes />} />
          {/*/!*User organization routes*!/*/}
          {/*e-schema routes*/}
          <Route path="/:companyName/e-flow/*" element={<EFlowRoutes />} />

          {/*feature-kaizen*/}
          <Route path="/:companyName/kaizen/*" element={<KaizenRoutes />} />
          <Route path="/:companyName/kpi/*" element={<KpiRoutes />} />
          {/*/!*this is for company only, so it is protected, but no roles are required*!/*/}
          <Route path="/*" element={<CompanyRoutes />} />
        </Route>
        {/*define fallback route*/}
        <Route path="/" element={<LoginRoutes />} />
      </Routes>
    </>
  );
}
