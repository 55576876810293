import { useEffect, useState } from 'react';

import { useLoadSteps } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useEflowStepsInit/Functions/LoadSteps/UseLoadSteps.tsx';
import { SynchronizeEflowStep } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useEflowStepsInit/Functions/SynchronizeEflowStep/SynchronizeEflowStep.ts';
import { UseEflowStepsInitTypes } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useEflowStepsInit/useEflowStepsInitTypes.ts';
import { useNetworkState } from '@uidotdev/usehooks';

import { SortArrayBasedOnStringArray } from '@/utils/SortArrayBasedOnStringArray.ts';

export const useEflowStepsInit = (props: UseEflowStepsInitTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { online: isOnline } = useNetworkState();

  const { getSteps } = useLoadSteps({
    analizeType: props.analizeType,
    organizationId: props.organizationId,
    eFlowId: props.eFlowId,
  });

  useEffect(() => {
    if (props.isReady) {
      void synchronizeEflowSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isReady]);

  /**
   * Handle initialization of an Eflow steps database
   */
  const initEflowStepsDb = async () => {
    setIsLoading(true);

    await props.init(props.analizeType, props.eFlowId, 'stepNumber');
    setIsLoading(false);
  };

  /**
   * Handle Eflow steps synchronization
   * @returns Promise<void>
   */
  const synchronizeEflowSteps = async () => {
    if (isOnline) {
      setIsLoading(true);
      const { steps, stepsIds } = await getSteps();

      await _removeSteps(stepsIds);

      if (steps) {
        const orderedSteps = SortArrayBasedOnStringArray(stepsIds, steps, 'id');

        await Promise.allSettled(
          orderedSteps.map(async (step, index) => {
            const key = _getOfflineKey(step.id, step.createdAt);

            return await SynchronizeEflowStep(
              step,
              index,
              !!props.data[step.id],
              props.updateElement,
              props.saveElement,
              props.removeElement,
              key,
            );
          }),
        );
      }
      await props.reHydrate();
    }
    setIsLoading(false);
  };

  const _getOfflineKey = (
    stepId: string,
    stepCreatedTime: string,
  ): string | undefined => {
    if (props.data[stepId]) return;

    for (const [localStepId, stepData] of Object.entries(props.data)) {
      if (
        new Date(stepData.createdAt as Date).getTime() ===
        new Date(stepCreatedTime).getTime()
      ) {
        return localStepId;
      }
    }

    return;
  };

  const _removeSteps = async (currentStepsIds: string[]) => {
    if (!props.data) return;

    const removePromiseQueue = [];

    for (const [key, eFlowStep] of Object.entries(props.data)) {
      if ('__typename' in eFlowStep && 'id' in eFlowStep) {
        if (!currentStepsIds.includes(eFlowStep.id)) {
          removePromiseQueue.push(props.removeElement(key));
        }
      }
    }

    await Promise.all(removePromiseQueue);
  };

  return {
    isLoading,
    synchronizeEflowSteps,
    initEflowStepsDb,
  };
};
