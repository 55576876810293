import { PeriodValueEnum } from '@kpi/common/periodValues.enum';

import { HeadCell } from '..';

export const filterMonthsByPeriod = (
  yearPeriodValue: PeriodValueEnum,
  monthArray: number[] | HeadCell[],
) => {
  switch (yearPeriodValue) {
    case PeriodValueEnum.YEAR:
      return monthArray;

    case PeriodValueEnum.Q1:
      return monthArray.slice(0, 3);

    case PeriodValueEnum.Q2:
      return monthArray.slice(3, 6);

    case PeriodValueEnum.Q3:
      return monthArray.slice(6, 9);

    case PeriodValueEnum.Q4:
      return monthArray.slice(9, 12);

    case PeriodValueEnum.MONTH: {
      const actualMonth = new Date().getMonth();
      return [monthArray[actualMonth]];
    }

    default:
      return monthArray;
  }
};
