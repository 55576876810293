import { FetchResult } from '@apollo/client';
import { TFunction } from 'i18next';
import { DeleteOrganizationUnitMutation } from '@/__generated__/graphql';
import { OnDeleteError, OnUnitDeleted, OpenSnackbar } from './types';

/**
 *
 * @param response response object
 * @param onDeleteError function to check type of error
 * @param openSnackbar function to open snackbar and pass message
 * @param onUnitDeleted action after removing id and remove id from tree
 * @param nodeId id of deleted node
 * @param t translation
 */
export const processUnitDeletedResult = (
  response: FetchResult<DeleteOrganizationUnitMutation>,
  onDeleteError: OnDeleteError,
  openSnackbar: OpenSnackbar,
  onUnitDeleted: OnUnitDeleted,
  nodeId: string,
  t: TFunction,
) => {
  if (response.data) {
    const { deleteOrganizationUnit: actionState } = response.data;
    if (!actionState.success) {
      if (
        actionState.children?.employees &&
        actionState.children?.employees > 0
      ) {
        onDeleteError('employee');
      } else if (
        actionState.children?.organizationUnits &&
        actionState.children?.organizationUnits > 0
      ) {
        onDeleteError('organizationUnit');
      }
    } else {
      openSnackbar(t('organizationSettings.deleteCellSuccess'));
      onUnitDeleted(nodeId);
    }
  }
};
