import { useDashboardFilters } from '@kpi/pages/Dashboard/hooks/useDashboardFilters.tsx';
import { useDashboardPermissions } from '@kpi/pages/Dashboard/hooks/useDashboardPermissions.tsx';

import { useCompanyId } from '@core/hooks';

import { DashboardTypesEnum } from '@/__generated__/graphql.ts';

export const useDashboard = (dashboardType: DashboardTypesEnum) => {
  const permissions = useDashboardPermissions(dashboardType);
  const filters = useDashboardFilters(dashboardType);

  const { companyId } = useCompanyId();

  return {
    ...filters,
    companyId,
    ...permissions,
  };
};
