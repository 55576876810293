import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { CustomBoxChip } from '@core/components';

import { KpiSettlementPeriodFragment } from '@/__generated__/graphql.ts';

import SettlementPeriodMenu from '../SettlementPeriodMenu/SettlementPeriodMenu';

export const SettlementPeriodsTableRow = (
  props: KpiSettlementPeriodFragment,
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t: settlementPeriods } = useTranslation('settlementPeriods');

  return (
    <>
      <CustomTableRow>
        <TableCell>
          <CustomStack>
            <FlexRow>
              <Typography>{props.periodName}</Typography>
              <CustomBoxChip
                label={props.settlementPeriodYear || '---'}
                color="info"
                sx={{
                  height: '95%',
                }}
              />
              {props.isActive && (
                <CustomBoxChip
                  label={settlementPeriods('settlementPeriod.active')}
                  color="success"
                  sx={{
                    height: '95%',
                  }}
                />
              )}
            </FlexRow>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </CustomStack>
        </TableCell>
      </CustomTableRow>
      <SettlementPeriodMenu
        anchorEl={anchorEl}
        isOpen={!!anchorEl}
        handleClose={() => setAnchorEl(null)}
        settlementPeriod={props}
      />
    </>
  );
};

const CustomTableRow = styled(TableRow)({
  cursor: 'pointer',
  padding: '0px',
  margin: '0px',
  maxHeight: '20px',
});

const CustomStack = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '0px',
  padding: '0px',
  maxHeight: '20px',
});

const FlexRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 15,
});
