import { useTranslation } from 'react-i18next';

import { Divider, Stack, Typography } from '@mui/material';

import { SubmissionDetailsProps } from './SubmissionDetails.types';
import {
  SubmissionAssignedEmployeeSection,
  SubmissionAuthorSection,
  SubmissionDatesSection,
  SubmissionIdCellSection,
  SubmissionPointsSection,
  SubmissionSPSection,
  SubmissionStatusSection,
} from './SubmissionDetailsTable';
import {
  DetailRow,
  DetailsContainer,
} from './SubmissionDetailsTable/styles/styles';

export const SubmissionDetails = (props: SubmissionDetailsProps) => {
  const { t } = useTranslation('kaizen');
  return (
    <DetailsContainer>
      <Divider orientation="vertical" flexItem />
      <Stack spacing={4}>
        <DetailRow>
          <Typography variant="h3">{t('submissionDetails.details')}</Typography>
        </DetailRow>
        <Stack>
          <SubmissionStatusSection {...props} />
          <SubmissionAuthorSection {...props} />
          <SubmissionAssignedEmployeeSection {...props} />
        </Stack>
        <Stack>
          <SubmissionIdCellSection {...props} />
          <SubmissionSPSection {...props} />
          <SubmissionPointsSection {...props} />
        </Stack>
        <Stack>
          <SubmissionDatesSection {...props} />
        </Stack>
      </Stack>
    </DetailsContainer>
  );
};
