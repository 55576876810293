import { useCallback, useEffect, useMemo, useState } from 'react';

import { uniqBy } from 'lodash';
import { useDebounceValue } from 'usehooks-ts';

import { useCompanyId } from '@core/hooks';

import {
  Employee,
  useGetEmployeesForMultiSelectLazyQuery,
} from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

import { EmployeeMenuProps } from './EmployeeMenu.types';

export const useEmployeeMenu = (props: Omit<EmployeeMenuProps, 'display'>) => {
  const [fetchedEmployees, setFetchedEmployees] = useState<Employee[]>([]);
  // TODO: implement multiselect
  // const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

  const [inputValue, setInputValue] = useState('');
  const [limit] = useState<number>(20);
  const [cursor, setCursor] = useState<number>(0);

  const { userUuid } = useUserStore();
  const { companyId } = useCompanyId();

  const [bouncingInputSearch, setBouncingInputSearch] =
    useDebounceValue<string>('', 500);

  // TODO: implement multiselect
  // const [displayedUsers, setDisplayedUsers] = useState<
  //   GetEmployeesForMultiSelectQuery['getEmployees']['items']
  // >([]);

  const [loadEmployees, { loading: isLoading, data }] =
    useGetEmployeesForMultiSelectLazyQuery();

  useMemo(() => {
    setBouncingInputSearch(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useMemo(() => {
    setFetchedEmployees([]);
    setCursor(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bouncingInputSearch]);

  useEffect(() => {
    if (data) {
      setFetchedEmployees((previous) => {
        const temp = uniqBy(
          [...previous, ...(data.getEmployees.items as Employee[])],
          'id',
        );
        return temp;
      });
    }
  }, [data]);

  useEffect(() => {
    void loadEmployees({
      variables: {
        input: {
          organisationId: companyId,
          ...(bouncingInputSearch ? { search: bouncingInputSearch } : {}),
          limit,
          cursor,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bouncingInputSearch, companyId]);

  const userAlreadyIncluded = useCallback(
    (id: string) => {
      if (props.includedIds === undefined) return false;

      return id === userUuid || props.includedIds.includes(id);
    },
    [props.includedIds, userUuid],
  );

  return {
    fetchedEmployees,
    isLoading,
    inputValue,
    setInputValue,
    loadEmployees,
    userAlreadyIncluded,
  };
};
