import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { ErrorContent } from '@/core/components';
import { FlexCenter } from '@/core/styles';
import { PasswordConfirmation, SendMailScreen } from '@/pages/auth/pages';
import ResetPassword from '@/pages/auth/pages/ResetPassword/ResetPassword.tsx';
import { ProtectedRoute } from '@/router';

export const ForgotPasswordRoutes = () => {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        element={<ProtectedRoute mustBeLoggedIn={false} isSystemRoute={true} />}
      >
        <Route index path="email" element={<SendMailScreen />} />
        <Route path="set-new-password" element={<ResetPassword />} />
        <Route
          path="password-confirmation"
          element={
            <PasswordConfirmation
              description={t('forgotPassword.passwordSetUp')}
              buttonLabel={t('common.login')}
              route="login"
            />
          }
        />
        <Route
          path="*"
          element={
            <FlexCenter sx={{ height: '100vh' }}>
              <ErrorContent errorCode="404" />
            </FlexCenter>
          }
        />
      </Route>
    </Routes>
  );
};
