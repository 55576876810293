import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Menu } from '@mui/material';

import { useKaizenRedirects } from '@core/redirects';

import { CustomMenuItem } from '@/core/components';
import { useUserStore } from '@/stores';

import { SubmissionRowMenuProps } from './SubmissionRow.types';

export const SubmissionRowMenu = (props: SubmissionRowMenuProps) => {
  const { t: commonT } = useTranslation();

  const { userUuid } = useUserStore();

  const { redirectToSingleSubmission, redirectToUpdateSubmission } =
    useKaizenRedirects();

  const isAuthor = useCallback(
    () => userUuid === props.creatorId,
    [userUuid, props.creatorId],
  );

  return (
    <Menu
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      onClick={props.handleClose}
    >
      {isAuthor() && (
        <CustomMenuItem
          text={commonT('common.delete')}
          icon={<DeleteOutline />}
          onClick={() => props.handleRemove(props.submissionId)}
          disabled={!props.isModificationsEnabled}
        />
      )}
      {isAuthor() && (
        <CustomMenuItem
          text={commonT('common.edit')}
          icon={<EditOutlined />}
          onClick={() => void redirectToUpdateSubmission(props.submissionId)}
          disabled={!props.isModificationsEnabled}
        />
      )}
      <CustomMenuItem
        text={commonT('common.view')}
        icon={<OpenInNewIcon />}
        onClick={() => void redirectToSingleSubmission(props.submissionId)}
      />
    </Menu>
  );
};
