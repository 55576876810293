import { TFunction } from 'i18next';
import { z } from 'zod';

export const SubmissionNameSchema = (t: TFunction<'kaizen'>) =>
  z.object({
    name: z
      .string({
        message: t(
          'submissionModificationView.common.submissionName.errors.nameRequired',
        ),
      })
      .min(3, {
        message: t(
          'submissionModificationView.common.submissionName.errors.nameTooShort',
          {
            charLength: 3,
          },
        ),
      })
      .max(128, {
        message: t(
          'submissionModificationView.common.submissionName.errors.nameTooLong',
          {
            charLength: 128,
          },
        ),
      }),
  });
