import { z } from 'zod';
import { OperationSchema, OperationTypesEnum } from '@/__generated__/graphql';

export const AddOperationSchema = (
  fieldRequired: string,
  operationExists: string,
  operations: OperationSchema[],
) =>
  z
    .object({
      operationType: z.string().min(1, fieldRequired),
      name: z.string().min(1, fieldRequired),
    })
    .refine(
      (data) => {
        // Check if the operation name already exists in the operations array
        return !operations.some(
          (operation) =>
            operation.name === data.name &&
            operation.operationType ===
              (data.operationType as OperationTypesEnum),
        );
      },
      { message: operationExists, path: ['name'] },
    );
