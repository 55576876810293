import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Box, Typography, styled } from '@mui/material';

import { Button } from '@core/components';

import { theme } from '@/theme';

import { YouAreOfflineProps } from './YouAreOffline.types.ts';

export const YouAreOffline = (props: YouAreOfflineProps) => {
  return (
    <Container>
      <ContentContainer>
        <StyledCloudOffIcon />
        <StyledTitle variant={'h3'}>{props.title}</StyledTitle>
        <StyledSubtitle variant={'h6'}>{props.subtitle}</StyledSubtitle>
        <ButtonContainer>
          <Button onClick={props.onButtonPrimaryClick}>
            {props.buttonPrimaryText}
          </Button>
          <Button onClick={props.onButtonSecondaryClick}>
            {props.buttonSecondaryText}
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

const StyledTitle = styled(Typography)({
  color: theme.palette.grey[800],
});

const StyledSubtitle = styled(Typography)({
  color: theme.palette.grey[500],
});

const StyledCloudOffIcon = styled(CloudOffIcon)({
  color: theme.palette.primary[500],
  fontSize: '4rem',
});

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '1rem',
  width: '100%',
  height: '100%',
  marginTop: '1rem',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '1.5rem',
  margin: '1.5rem',
  height: '100%',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
});
