import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ChangeEmployeeCellModal,
  DeleteUserModal,
  StructureEmployeesTableRefTypes,
  TableHeader,
} from '@e-schema/components';

import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';

import {
  GetEmployeesForStructureQuery,
  useGetEmployeesForStructureLazyQuery,
} from '@/__generated__/graphql';
import { CustomSnackBar, TablePaginationWrapper } from '@/core/components';
import { useTablePagination } from '@/core/hooks';
import { FlexCenter } from '@/core/styles';
import { useOrganizationStore } from '@/stores';
import { Unpacked } from '@/types/unpacked';

import { EmployeeRow } from './EmployeeRow';
import { IEmployeeStructureTableProps } from './types';

export const EmployeeStructureTable = forwardRef<
  StructureEmployeesTableRefTypes,
  IEmployeeStructureTableProps
>(({ unitId }, ref) => {
  const { t } = useTranslation();
  const { rowsPerPage, cursor, setRowsPerPage, setCursor } =
    useTablePagination();

  const [rows, setRows] = useState<
    GetEmployeesForStructureQuery['getEmployees']['items']
  >([]);
  const [snackbar, setSnackbar] = useState<string | null>(null);
  const [total, setTotal] = useState<number>(0);

  const [employeeToDelete, setEmployeeToDelete] = useState<Unpacked<
    GetEmployeesForStructureQuery['getEmployees']['items'][0]
  > | null>(null);
  const [employeeToChangeCell, setEmployeeToChangeCell] = useState<
    string | null
  >(null);

  const { companyName } = useParams();
  const { organization } = useOrganizationStore();

  const [getEmployeesQuery, { data, loading: isLoading, refetch }] =
    useGetEmployeesForStructureLazyQuery();

  useEffect(() => {
    if (!unitId) return;
    void refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId, cursor, rowsPerPage, organization, companyName]);

  useImperativeHandle(ref, () => ({
    addNewUser(newEmployee) {
      if (cursor === 0) setRows((prev) => [newEmployee, ...prev]);
      setTotal((prev) => prev + 1);
    },
  }));

  const getEmployees = async () => {
    if (!unitId) return;
    await getEmployeesQuery({
      variables: {
        getEmployeesInput: {
          organisationId: organization[companyName!],
          organisationUnitIds: [unitId],
          limit: rowsPerPage,
          cursor,
        },
      },
      pollInterval: 5000,
    });
  };

  useEffect(() => {
    if (!unitId) return;
    void getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unitId,
    cursor,
    rowsPerPage,
    getEmployeesQuery,
    organization,
    companyName,
  ]);

  useEffect(() => {
    if (data) {
      setRows(data.getEmployees.items || []);
      setTotal(data.getEmployees.total || 0);
    }
  }, [data]);

  /**
   * Remove user from table
   * @param id
   */
  const removeUserFromTable = (id: string) => {
    setRows(() => rows.filter((employee) => employee.id !== id));
  };

  return (
    <>
      {employeeToDelete && (
        <DeleteUserModal
          {...employeeToDelete}
          open={true}
          closeModal={() => setEmployeeToDelete(null)}
          setSnackBar={(message: string) => {
            setSnackbar(message);
          }}
          setDeleteUserOperationStatus={(wasDeleted: boolean) => {
            if (wasDeleted) {
              removeUserFromTable(employeeToDelete.id);
              setTotal((prev) => prev - 1);
            }
          }}
        />
      )}

      {employeeToChangeCell && (
        <ChangeEmployeeCellModal
          open={true}
          closeModal={() => {
            setEmployeeToChangeCell(null);
          }}
          setSnackBar={(message: string) => {
            setSnackbar(message);
          }}
          changeEmployeeId={employeeToChangeCell}
        />
      )}
      <TableContainer>
        <TablePaginationWrapper
          total={total}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={(newValue) => setRowsPerPage(newValue)}
          cursor={cursor}
          setCursor={(newValue) => setCursor(newValue)}
        >
          <Table size="small">
            <TableHeader />
            <TableBody>
              {rows.map((row) => (
                <EmployeeRow
                  row={row}
                  key={row.id}
                  setEmployeeToDelete={(toDelete) =>
                    setEmployeeToDelete(toDelete)
                  }
                  setEmployeeToChangeCell={(employeeId) =>
                    setEmployeeToChangeCell(employeeId)
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TablePaginationWrapper>
      </TableContainer>

      {isLoading && (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      )}
      {rows.length < 1 && (
        <FlexCenter sx={{ padding: 20 }}>
          {t('employees.noEmployeesInUnit')}
        </FlexCenter>
      )}
      <CustomSnackBar
        open={!!snackbar}
        message={`${snackbar}`}
        close={() => setSnackbar(null)}
      />
    </>
  );
});
