import { formatKey } from '@core/hooks/useRecharts/functions/FormatKey.ts';
import { ExtractChartKeyType } from '@core/hooks/useRecharts/types';

export function FormatVerticalBarChart(
  props: ExtractChartKeyType,
  hasTarget: boolean,
  selectedMonths: number[],
  showDataAsPercent: boolean,
) {
  const accumulatedValues = accumulateValuesAndTargets(
    props,
    showDataAsPercent ? true : hasTarget,
    selectedMonths,
    showDataAsPercent,
  );

  if (showDataAsPercent) {
    return {
      processedValues: [accumulatedValues],
      accumulatedValues,
    };
  }

  const processedValues = processAccumulatedValues(
    accumulatedValues,
    showDataAsPercent,
  );
  return {
    processedValues,
    accumulatedValues,
  };
}

function accumulateValuesAndTargets(
  props: ExtractChartKeyType,
  hasTarget: boolean,
  selectedMonths: number[],
  showDataAsPercent: boolean,
): { [key: string]: number } {
  const transformedChartValues = props.reduce(
    (chartValues, currentValue) => {
      const currentKeys = Object.keys(currentValue);

      currentKeys.forEach((key) => {
        if (key === 'name') {
          return chartValues;
        }

        const formattedTargetKey = formatKey(key, 'target');
        const formattedKey = formatKey(key, 'value');

        if (hasTarget) {
          chartValues[formattedTargetKey] =
            (chartValues[formattedTargetKey] ?? 0) +
            (currentValue[key]?.target ?? 0);
        }

        if (selectedMonths.includes(+currentValue.name)) {
          chartValues[formattedKey] =
            (chartValues[formattedKey] ?? 0) + currentValue[key].value;
        }
      });

      return chartValues;
    },

    {} as { [key: string]: number },
  );

  if (showDataAsPercent) {
    transformChartValuesToPercent(transformedChartValues);
  }

  return transformedChartValues;
}

function processAccumulatedValues(
  accumulatedValues: {
    [key: string]: number;
  },
  isPercentage: boolean,
): { [key: string]: number | string }[] {
  const valuesAfterValueTargetSubtraction = Object.keys(
    accumulatedValues,
  ).reduce(
    (acc: { [key: string]: number | string }, key: string) => {
      const { valueKey, targetKey } = _extractValues(
        accumulatedValues,
        acc,
        key,
      );

      if (isPercentage && acc[targetKey] !== 0) {
        acc[valueKey] = (Number(acc[valueKey]) / Number(acc[targetKey])) * 100;
        acc[targetKey] = 100 - Number(acc[valueKey]);
      }

      return acc;
    },
    {} as { [key: string]: number | string },
  );

  return [valuesAfterValueTargetSubtraction];
}

/**
 *
 * @param accumulatedValues
 * @param prevValue
 * @param key
 * @returns it should ectrat value from object about value and target to calulcate chart
 */
function _extractValues(
  accumulatedValues: {
    [key: string]: number;
  },
  prevValue: { [key: string]: number | string },
  key: string,
) {
  if (key.endsWith('_target')) {
    return prevValue;
  }
  const pureKey = key.replace('_value', '');

  const targetKey = formatKey(pureKey, 'target');
  const target = accumulatedValues[targetKey] || 0;

  const valueKey = formatKey(pureKey, 'value');
  const value = accumulatedValues[valueKey] || 0;

  if (target > value) {
    prevValue[valueKey] = value;
    prevValue[targetKey] = target - value;
  } else {
    prevValue[valueKey] = value;
    prevValue[targetKey] = 0;
  }

  return { targetKey, valueKey };
}

function transformChartValuesToPercent(transformedChartValues: {
  [key: string]: number;
}) {
  Object.keys(transformedChartValues).forEach((key) => {
    if (key.endsWith('_target')) {
      return;
    }

    const keyWithoutValue = key.replace('_value', '');

    const valueKey = formatKey(keyWithoutValue, 'value');
    const targetKey = formatKey(keyWithoutValue, 'target');

    if (transformedChartValues[targetKey] === 0) {
      transformedChartValues[valueKey] = 0;
      transformedChartValues[targetKey] = 100;
    } else {
      transformedChartValues[valueKey] =
        Math.round(
          (transformedChartValues[valueKey] /
            transformedChartValues[targetKey]) *
            100 *
            100,
        ) / 100;

      transformedChartValues[targetKey] =
        Math.round((100 - transformedChartValues[valueKey]) * 100) / 100;
    }
  });
}
