import { useTranslation } from 'react-i18next';

import { useComment } from '@e-flow/hooks/useComment/useComment.tsx';

import { Checkbox, MenuItem, styled } from '@mui/material';

import { CommentComponent } from '@/core/components/CommentComponent/CommentComponent';
import { SingleCommentTypes } from '@/pages/e-flow/components/SingleComment/SingleComment.types';

import { EditCommentForm } from '..';

export const SingleComment = (props: SingleCommentTypes) => {
  const { t } = useTranslation('eflow');

  const {
    canEdit,
    menuAnchorEl,
    handleCloseMenu,
    setMenuAnchorEl,
    handleStartEdit,
    handleCloseEdit,
    isBeeingEdited,
  } = useComment(props);

  if (!isBeeingEdited)
    return (
      <CommentComponent
        commentProps={props}
        menuAnchorEl={menuAnchorEl}
        canEdit={canEdit}
        handleCloseMenu={handleCloseMenu}
        additionalMenuItems={
          <CheckboxMenuItem disableRipple>
            <StyledCheckbox
              checked={props.showInReport}
              onChange={(e) => {
                props.setIsInTheReport(e.target.checked);
              }}
            />
            {t('newAnalysis.comments.showInRaport')}
          </CheckboxMenuItem>
        }
        handleEdit={handleStartEdit}
        isLoading={false}
        setMenuAnchorEl={setMenuAnchorEl}
        handleDelete={() => props.onRemove()}
      />
    );
  else
    return (
      <EditCommentForm
        onEdit={props.onEdit}
        createdAt={props.createdAt}
        commentContent={props.content}
        commentCategory={props.commentCategory!}
        commentTypes={
          props.commentTypes as { categoryName: string; symbol: string }[]
        }
        addCommentToReport={props.showInReport}
        handleCloseForm={handleCloseEdit}
        onCancel={handleCloseEdit}
        commentId={props.id}
      />
    );
};

const CheckboxMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
  alignItems: 'center',
  padding: '10px 16px',
});

const StyledCheckbox = styled(Checkbox)({
  padding: 0,
  marginRight: '10px',
});
