import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Divider,
  FormControlLabel,
  Menu,
  Switch,
  Typography,
  styled,
} from '@mui/material';

import { theme } from '@/theme';

import { OptionsToDisplay } from '../types';
import { OtherColumnsSection } from './OtherColumnsSection';
import { ParametersSection } from './ParametersSection';
import { TableDisplayMenuProps } from './TableDisplayMenu.types';

export const TableDisplayMenu = (props: TableDisplayMenuProps) => {
  const { t } = useTranslation('eflow');

  const [parametersOptions, setParametersOptions] = useState<OptionsToDisplay>(
    {},
  );
  const [otherOptions, setOtherOptions] = useState<OptionsToDisplay>({
    operation: true,
    operationName: true,
    attachments: true,
    comments: true,
    nonActive: true,
  });

  useEffect(() => {
    if (!props.parameters) return;
    const options: OptionsToDisplay = {};

    props.parameters.forEach((parameter) => (options[parameter.name] = true));
    setParametersOptions(options);
  }, [props.parameters]);

  useEffect(() => {
    handleParametersChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersOptions, otherOptions]);

  const handleParametersChange = useCallback(() => {
    props.onChange({ ...parametersOptions, ...otherOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersOptions, otherOptions]);

  return (
    <CustomMenu
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.handleClose}
    >
      {/** Section for parrameters trigger */}
      <SectionContainer>
        <Typography color={theme.palette.grey[600]}>
          {t('newAnalysis.parameters.title')}
        </Typography>
        <ParametersSection
          parameters={props.parameters}
          parametersOptions={parametersOptions}
          setParametersOptions={setParametersOptions}
        />
      </SectionContainer>
      <Divider />
      <SectionContainer>
        <Typography color={theme.palette.grey[600]}>
          {t('eflow.otherColumns')}
        </Typography>
        <OtherColumnsSection
          otherOptions={otherOptions}
          setOtherOptions={setOtherOptions}
        />
      </SectionContainer>
      <Divider />
      <SectionContainer>
        <Typography color={theme.palette.grey[600]}>
          {t('eflow.otherColumns')}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={otherOptions['nonActive']}
              onChange={(e) =>
                setOtherOptions({
                  ...otherOptions,
                  nonActive: e.target.checked,
                })
              }
            />
          }
          label={
            <Typography variant="bodyRegular">
              {t('eflow.table.showNonActive')}
            </Typography>
          }
        />
      </SectionContainer>
    </CustomMenu>
  );
};

const CustomMenu = styled(Menu)({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& .MuiPaper-root': {
    minWidth: '300px',
  },
});

const SectionContainer = styled('div')({
  padding: '8px 12px 10px 12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
});
