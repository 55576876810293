import { Tooltip } from '@mui/material';

import { DisabledButton } from '@core/components';
import { DisabledTooltipButtonProps } from '@core/components/Buttons/DisabledTooltipButton/DisabledTooltioButton.types.ts';

export const DisabledTooltipButton = ({
  title,
  tooltip,
}: DisabledTooltipButtonProps) => {
  return (
    <Tooltip title={tooltip}>
      <DisabledButton disabled>{title}</DisabledButton>
    </Tooltip>
  );
};
