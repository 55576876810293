import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useSubmissionBuilderReducer } from '@kaizen/hooks/useSubmissionBuilder/Reducers/SubmissionBuilderReducer.tsx';
import { ProblemView, SolutionView } from '@kaizen/views';
import { SubmissionNameView } from '@kaizen/views/SubmissionNameView/SubmissionNameView.tsx';
import { SummaryModule } from '@kaizen/views/SummaryView/SummaryModule.tsx';

import { useCompanyId, useSnackbar } from '@core/hooks';

import {
  Employee,
  FileModel,
  KaizenStatuses,
  useFindDefaultSubmissionInfoLazyQuery,
  useUpdateKaizenSubmissionMutation,
} from '@/__generated__/graphql';
import { useKaizenRedirects } from '@/core/redirects';
import { SummaryButtonType } from '@/pages/kaizen/common';

export const useUpdateSubmission = () => {
  const { t } = useTranslation('kaizen');
  const { companyId } = useCompanyId();
  const { submissionId } = useParams();
  const { redirectToSingleSubmission, redirectToSubmissions } =
    useKaizenRedirects();

  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonType, setButtonType] = useState<SummaryButtonType>();

  const [updateKaizenSubmission] = useUpdateKaizenSubmissionMutation();
  const [getSubmission, { data: submissionData }] =
    useFindDefaultSubmissionInfoLazyQuery();

  const { isSnackBarOpen, snackBarMessage, openSnackbar, closeSnackbar } =
    useSnackbar();

  const steps = [
    t('submissionModificationView.common.steps.submissionName'),
    t('submissionModificationView.common.steps.problem'),
    t('submissionModificationView.common.steps.solution'),
    t('submissionModificationView.common.steps.summary'),
  ];

  const handleStepBack = () =>
    setCurrentStep((currentValue) => (currentValue > 0 ? currentValue - 1 : 0));

  const handleStepNext = () =>
    setCurrentStep((currentValue) =>
      currentValue < steps.length ? currentValue + 1 : currentValue,
    );

  //IMPORTANT BORING STUFF
  const {
    submissionName,
    problem,
    solution,
    authors,
    setSubmissionName,
    setProblem,
    setSolution,
    setAuthors,
  } = useSubmissionBuilderReducer();

  useEffect(() => {
    try {
      const res = submissionData?.findSingleSubmission;
      if (res) {
        setSubmissionName(res.name);
        setProblem(
          res.problem.description,
          res.problem.files as Array<FileModel>,
        );
        setSolution(
          res.solution.description,
          res.solution.files as Array<FileModel>,
        );
        setAuthors((res.authors as Employee[]) || []);
      }
    } catch (error) {
      openSnackbar(
        t('submissionModificationView.common.errors.gettingDataError'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionData]);

  useEffect(() => {
    if (companyId)
      void getSubmission({
        variables: {
          input: {
            organizationId: companyId,
            submissionId: submissionId!,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const getServerFiles = (files: Array<FileModel | File>) => {
    return files
      .filter((file) => !(file instanceof File))
      .map((file) => ({
        organizationId: (file as FileModel).organizationId,
        fileId: (file as FileModel).id,
      }));
  };

  const updateSubmission = async () => {
    try {
      setIsLoading(true);

      const problemFiles = getServerFiles(problem.files);
      const solutionFiles = getServerFiles(solution.files);

      const response = await updateKaizenSubmission({
        variables: {
          input: {
            name: submissionName,
            problem: { description: problem.description },
            solution: { description: solution.description },
            coAuthors: authors.map((author) => ({
              id: author.id,
              organizationId: companyId,
            })),
            organizationId: companyId,
            id: submissionId!,
            problemFiles,
            solutionFiles,
          },
          problemFiles: [
            ...problem.files.filter((file) => file instanceof File),
          ],
          solutionFiles: [
            ...solution.files.filter((file) => file instanceof File),
          ],
        },
      });

      setIsLoading(false);

      if (response.data) {
        return response.data.updateKaizenSubmission;
      }
    } catch (error) {
      return null;
    }
  };

  const handleSubmitAndSend = async () => {
    setButtonType(SummaryButtonType.SubmitAndSend);
    const res = await updateSubmission();

    if (res) redirectToSingleSubmission(res.id);
    else {
      openSnackbar(t('submissionModificationView.common.errors.submitError'));
    }
  };

  const handleSubmit = async () => {
    setButtonType(SummaryButtonType.Submit);
    const res = await updateSubmission();
    if (res) redirectToSubmissions();
    else {
      openSnackbar(t('submissionModificationView.common.errors.submitError'));
    }
  };

  const renderStepChange = useCallback(() => {
    switch (currentStep) {
      case 1:
        return (
          <SubmissionNameView
            submissionName={submissionName}
            onMoveToNextStep={(currentSubmissionName) => {
              setSubmissionName(currentSubmissionName);
              handleStepNext();
            }}
          />
        );
      case 2:
        return (
          <ProblemView
            defaultValue={problem}
            onMoveToNextStep={(description, files) => {
              setProblem(description, files);
              handleStepNext();
            }}
            onStepBack={(description, files) => {
              setProblem(description, files);
              handleStepBack();
            }}
          />
        );
      case 3:
        return (
          <SolutionView
            defaultValue={solution}
            onMoveToNextStep={(description, files) => {
              setSolution(description, files);
              handleStepNext();
            }}
            onStepBack={(description, files) => {
              setSolution(description, files);
              handleStepBack();
            }}
          />
        );
      case 4:
        return (
          <SummaryModule
            buttonType={buttonType}
            isLoading={isLoading}
            summaryName={submissionName}
            onSubmitAndSend={() => void handleSubmitAndSend()}
            onSubmit={() => void handleSubmit()}
            onPreviousStep={() => handleStepBack()}
            onChangeSubmissionsAuthors={(newAuthors) => setAuthors(newAuthors)}
            authors={authors}
            submissionAuthors={authors}
            isDraft={
              submissionData?.findSingleSubmission.status ===
              KaizenStatuses.Draft
            }
            isUpdate
            submissionKey={submissionData?.findSingleSubmission.submissionKey}
          />
        );
    }
    return <div />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleStepNext, currentStep, solution, problem, submissionName]);

  return {
    steps,
    currentStep,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
    renderStepChange,
  };
};
