import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalHeader } from '@kpi/modals/HandleKpiChartModal/components/ModalHeader/ModalHerader.tsx';
import { useCreateChartContext } from '@kpi/modals/HandleKpiChartModal/context';

import { Alert } from '@mui/material';

import { CustomModal, ModalContainer } from '@core/components';

import { CreateChartView } from './CreateChartView';
import { PickKpiRateView } from './PickKpiRateView';

export const HandleKpiCreateChartModal = () => {
  const {
    modalMode,
    isSettingsView,
    isSelectKpisView,
    isAddKpiModalOpen,
    areSelectedKpisValid,
    closeModal,
    modalTitleKey,
    setSettingsView,
  } = useCreateChartContext();

  const { t } = useTranslation('kpi');

  useEffect(() => {
    if (isAddKpiModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    setSettingsView();
    return () => {
      document.body.style.overflow = 'auto';
    };
    // eslint-disable-next-line
  }, [isAddKpiModalOpen]);

  return (
    <CustomModal display={isAddKpiModalOpen}>
      <ModalContainer
        closeModal={closeModal}
        headerText={modalTitleKey(modalMode)}
        headerComponent={<ModalHeader />}
        sx={{ minHeight: '516px', padding: '24px' }}
      >
        {!areSelectedKpisValid && (
          <Alert severity={'error'}>{t('errors.unitOrTargetMismatch')}</Alert>
        )}
        {isSettingsView && <CreateChartView />}
        {isSelectKpisView && <PickKpiRateView />}
      </ModalContainer>
    </CustomModal>
  );
};
