import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useKaizenSettlementPeriodContext } from '@kaizen/module-settings/SettlementPeriods/contexts';
import { useAddSettlementPeriod } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/Functions/useAddSettlementPeriod.ts';

import { CheckBoxWithTextModal } from '@core/components';
import { CheckBoxWithTextModalDefaultTypes } from '@core/components/Modals/CheckBoxWithTextModal/CheckBoxWithTextModal.types.ts';
import { SettlementPeriodErrorsEnum } from '@core/enums';

export const CreateSettlementPeriodModal = () => {
  const { t: settlementPeriods } = useTranslation('settlementPeriods');
  const { t: commonT } = useTranslation();

  const [isValidationError, setValidationError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] =
    useState<SettlementPeriodErrorsEnum | null>(null);

  const { validate, addSettlementPeriod } = useAddSettlementPeriod();

  const {
    isSettlementPeriodModalOpen,
    closeCreateSettlementPeriodModal,
    open: openSnackbar,
  } = useKaizenSettlementPeriodContext();

  return (
    <CheckBoxWithTextModal
      isOpen={isSettlementPeriodModalOpen}
      closeModal={() => {
        setErrorMessage(null);
        closeCreateSettlementPeriodModal();
      }}
      headerText={settlementPeriods('addSettlementPeriodModal.headerText')}
      placeholder={settlementPeriods('addSettlementPeriodModal.placeholder')}
      checkboxLabel={settlementPeriods(
        'addSettlementPeriodModal.activateAfterCreation',
      )}
      submitButton={commonT('common.add')}
      isError={isValidationError}
      errorInputMessage={
        errorMessage &&
        settlementPeriods(`addSettlementPeriodModal.${errorMessage}`)
      }
      onResponse={async function (response: CheckBoxWithTextModalDefaultTypes) {
        const errorMessage = validate(response.text, isValidationError);

        if (errorMessage) {
          setValidationError(true);
          setErrorMessage(errorMessage);
          return false;
        }

        setValidationError(false);

        if (await addSettlementPeriod(response.text, response.checkbox)) {
          openSnackbar(
            'success',
            settlementPeriods('addSettlementPeriodModal.successMessage'),
          );
          closeCreateSettlementPeriodModal();
          return true;
        }

        openSnackbar(
          'error',
          settlementPeriods(
            'addSettlementPeriodModal.settlementPeriodAddError',
          ),
        );
        closeCreateSettlementPeriodModal();
        return false;
      }}
    />
  );
};
