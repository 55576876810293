import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, TextField, Typography } from '@mui/material';

import { useModalContext, useSnackbarContext } from '@core/contexts';

import {
  KaizenSubmissionFragment,
  useSendToAdjustmentMutation,
} from '@/__generated__/graphql';
import { useUpdateSingleSubmissionCache } from '@/pages/kaizen/hooks';
import { useUserStore } from '@/stores';

export const useAdjustmentSubmission = (submissionId: string) => {
  const { t: commonT } = useTranslation();
  const { t: kaizenT } = useTranslation('kaizen');
  const { openSnackbar } = useSnackbarContext();
  const { organizationId, userUuid } = useUserStore();
  const {
    handleOpenModal,
    handleCloseModal,
    setButtonText,
    setContent,
    setHeaderText,
    setOnSubmit,
  } = useModalContext();

  const [justification, setJustification] = useState('');
  const [error, setError] = useState('');

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const [sendToAdjustmentMutation, { loading: isLoading }] =
    useSendToAdjustmentMutation();

  // Move modalContent inside useEffect and memoize it
  useEffect(() => {
    const modalContent = (
      <Stack flexDirection="column" gap="12px">
        <Typography>{kaizenT('adjustment.adjustmentInfo')}</Typography>
        <TextField
          value={justification}
          onChange={(event) => {
            setJustification(event.target.value);
            setError('');
          }}
          size="small"
          error={!!error}
          helperText={error}
          label={kaizenT('adjustment.justify')}
        />
      </Stack>
    );

    setContent(modalContent);
  }, [justification, error, kaizenT, setContent, setError, setJustification]);

  const sendToAdjustment = useCallback(
    async () => {
      if (!justification) {
        setError(commonT('error.requiredField'));
        return false;
      }

      if (!organizationId) return false;

      setJustification('');
      try {
        const res = await sendToAdjustmentMutation({
          variables: {
            input: {
              submissionId: submissionId,
              organizationId,
              commentModel: {
                content: justification,
                organizationId,
                createdByModel: {
                  id: userUuid,
                  organizationId,
                },
              },
            },
          },
        });
        handleCloseModal();
        if (res.data?.sendToAdjustment) {
          updateSingleSubmissionCache(
            res.data.sendToAdjustment as KaizenSubmissionFragment,
          );
          openSnackbar(kaizenT('updateStatus.successMessage'));
          return true;
        }
      } catch (e) {
        captureException(e);
      }
      openSnackbar(kaizenT('updateStatus.errorMessage'));
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationId, submissionId, justification, commonT, userUuid], // Added justification to dependencies
  );

  useEffect(() => {
    setOnSubmit(() => () => void sendToAdjustment());
    setHeaderText(kaizenT('adjustment.sendSubmissionToAdjustment'));
    setButtonText(kaizenT('adjustment.send'));
  }, [sendToAdjustment, setOnSubmit, setHeaderText, setButtonText, kaizenT]);

  return {
    sendToAdjustment,
    justification,
    setJustification,
    error,
    isLoading,
    onClick: handleOpenModal,
    closeModal: handleCloseModal,
  };
};
