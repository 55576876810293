import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { deepClone } from '@mui/x-data-grid/internals';

import {
  AsIsToBeNamesEnum,
  FindOneEflowStepQuery,
  useFindOneEflowStepLazyQuery,
  useGetAsIsToBeIdsQuery,
} from '@/__generated__/graphql';
import { useOrganizationStore } from '@/stores';

import { StepModalViewsContext } from '../../context';
import { useDetailsComments } from '../useDetailsComments/useDetailsComments';

export const useControlDetailsView = (
  eFlowId: string,
  stepId: string,
  type: AsIsToBeNamesEnum,
) => {
  const [ids, setIds] = useState<string[]>([]);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [stepData, setStepData] = useState<
    FindOneEflowStepQuery['findOneEflowStep'] | undefined
  >();
  const [isAsIs] = useState<boolean>(type === AsIsToBeNamesEnum.AsIs);

  const { setAsIsStepId, setToBeStepId } = useContext(StepModalViewsContext);

  const { companyName } = useParams();
  const { organization } = useOrganizationStore();

  const organizationId = organization[companyName!];

  const { updateComments, updatePinnedComments } = useDetailsComments({
    stepId,
    eFlowId,
    organizationId,
    stepData,
    setStepData,
  });

  const [fetchStep, { loading: isLoading, data }] =
    useFindOneEflowStepLazyQuery({
      variables: {
        input: {
          id: stepId,
          eFlowId,
        },
      },
    });

  const asIsToBeIds = useGetAsIsToBeIdsQuery({
    variables: {
      input: {
        organizationId,
        id: eFlowId,
      },
    },
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (!asIsToBeIds.data) return;
    if (type === AsIsToBeNamesEnum.AsIs)
      setIds(asIsToBeIds.data.findOneEFlow.asIsState || []);
    else setIds(asIsToBeIds.data.findOneEFlow.toBeState || []);
  }, [asIsToBeIds.data, type]);

  useEffect(() => {
    if (!ids.length || !stepId) return;
    setStepIndex(ids.findIndex((id) => id === stepId));
  }, [ids, stepId]);

  useEffect(() => {
    void fetchStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  useEffect(() => {
    if (!data) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setStepData(deepClone(data.findOneEflowStep));
  }, [data]);

  {
    /**
    Go to previous step
    */
  }
  const handleSetPreviousStep = () => {
    if (stepIndex < 1) return;
    if (type === AsIsToBeNamesEnum.AsIs) setAsIsStepId(ids[stepIndex - 1]);
    else setToBeStepId(ids[stepIndex - 1]);
  };

  {
    /**
    Go to next step
    */
  }
  const handleSetNextStep = () => {
    if (stepIndex >= ids.length - 1) return;
    if (type === AsIsToBeNamesEnum.AsIs) setAsIsStepId(ids[stepIndex + 1]);
    else setToBeStepId(ids[stepIndex + 1]);
  };

  /**
   * Set this elfow step id if it is AsIs or ToBe
   */
  const handleSetFlowStepId = (id: string, stepType?: AsIsToBeNamesEnum) => {
    if (stepType && stepType === AsIsToBeNamesEnum.AsIs) setAsIsStepId(id);
    else if (stepType && stepType === AsIsToBeNamesEnum.ToBe) setToBeStepId(id);
    else if (isAsIs) setAsIsStepId(id);
    else setToBeStepId(id);
  };

  return {
    ids,
    isLoading,
    stepData,
    handleSetPreviousStep,
    handleSetNextStep,
    stepIndex: stepIndex || stepIndex === 0 ? stepIndex + 1 : undefined,
    updateComments,
    updatePinnedComments,
    handleSetFlowStepId,
    isAsIs,
  };
};
