import type { MouseEvent } from 'react';
import { useState } from 'react';

import { IconButton, Skeleton } from '@mui/material';

import { EmployeeAvatar } from '../index.ts';
import { CustomMenu } from './CustomMenu.tsx';
import { useAvatarMenu } from './UseAvatarMenu.tsx';

export const AvatarMenu = () => {
  const { isLoading, name, profilePicture } = useAvatarMenu();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        {isLoading ? (
          <Skeleton variant="circular" />
        ) : (
          <EmployeeAvatar name={name} imageUrl={profilePicture} />
        )}
      </IconButton>
      <CustomMenu anchorEl={anchorEl} open={isOpen} handleClose={handleClose} />
    </>
  );
};
