import { FiberManualRecord } from '@mui/icons-material';
import { Stack, Typography, styled } from '@mui/material';

import { toDdMmYyyy } from '@/core/hooks/useTimestamp/Functions/toDdMmYyyy';
import { theme } from '@/theme';

import { NotificationHeaderProps } from './NotificationHeader.types';

//TODO add module name
export const NotificationHeader = (props: NotificationHeaderProps) => {
  return (
    <TitleContainer>
      <Typography variant="bodyMedium">{props.title}</Typography>
      <InfoContent>
        <DateTypography>{toDdMmYyyy(String(props.sentAt))}</DateTypography>
        {!props.isOpened && (
          <FiberManualRecord fontSize={'small'} color="primary" />
        )}
      </InfoContent>
    </TitleContainer>
  );
};

const DateTypography = styled(Typography)({
  color: theme.palette.grey[700],
});

const TitleContainer = styled(Stack)({
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const InfoContent = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});
