import { useTranslation } from 'react-i18next';

import { Box, Typography, styled } from '@mui/material';

import { palette } from '@/theme/pallete';

export const NoRecordsScreen = () => {
  const { t } = useTranslation('kaizen');

  return (
    <Container>
      <CustomStyledHeaderTitle>
        {t('ranking.noDataInfo')}
      </CustomStyledHeaderTitle>
    </Container>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '50px',
});
const CustomStyledHeaderTitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '24px',
  lineHeight: '24px',
  fontWeight: 500,
  color: palette.text.primary,
  marginLeft: '16px',
  marginRight: '16px',
});
