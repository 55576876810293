/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';

import { Stack, Typography } from '@mui/material';

import {
  CharsCountingInput,
  CustomModal,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { RowStack } from '@/pages/organizations/styles';
import { theme } from '@/theme';

import { AddCategorySchema } from './AddComments.schema';
import { AddCategoryModalProps } from './CommentsModule.types';
import { useBasicComments } from './useBasicComments';

export const AddCommentModal = (props: AddCategoryModalProps) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation('translation');
  const basicComments = useBasicComments();
  const defaultValues = {
    symbol: '',
    categoryName: '',
  };

  const [symbols, setSymbols] = useState<string[]>(
    basicComments.map((comment) => comment.symbol),
  );
  const [comments, setComments] = useState<string[]>(
    basicComments.map((comment) => comment.categoryName),
  );

  useEffect(() => {
    props.comments.forEach((comment) => {
      setSymbols((prev) => [...prev, comment.symbol]);
      setComments((prev) => [...prev, comment.categoryName]);
    });
  }, [props.comments]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: zodResolver(AddCategorySchema(t, symbols, comments)),
  });

  const onSubmit = (data: { symbol: string; categoryName: string }) => {
    props.setSnackBar(t('newAnalysis.comments.setSnackBarMessage'));
    props.addComment(data);
    props.close();
  };

  const watchSymbol = watch('symbol');

  useEffect(() => {
    if (watchSymbol) {
      setValue('symbol', watchSymbol.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSymbol]);

  return (
    <CustomModal display={props.open}>
      <ModalContainer
        width="528px"
        closeModal={props.close}
        headerText={t('newAnalysis.comments.modalTitle')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack padding={'24px 16px'} gap="16px">
            <RowStack gap={'14px'} position={'relative'} paddingBottom={'10px'}>
              <CharsCountingInput
                maxInputLength={4}
                {...register('symbol')}
                error={!!errors.symbol}
                helperText={errors.symbol?.message}
                label={commonT('common.symbol')}
                required
                sx={{ width: '128px' }}
                size="small"
              />
              <CharsCountingInput
                {...register('categoryName')}
                required
                helperText={errors.categoryName?.message}
                error={!!errors.categoryName}
                maxInputLength={40}
                sx={{ width: '100%' }}
                label={t('newAnalysis.comments.categoryName')}
              />
            </RowStack>

            <RowStack justifyContent={'end'}>
              <TextButton
                text={commonT('common.cancel')}
                width="66px"
                onClick={props.close}
              />
              <PrimaryButton
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary[600],
                }}
                type="submit"
              >
                <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
                  {commonT('common.add')}
                </Typography>
              </PrimaryButton>
            </RowStack>
          </Stack>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};
