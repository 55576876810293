import { useTranslation } from 'react-i18next';

import { OperationSchema, OperationTypesEnum } from '@/__generated__/graphql';

export const useOpearationsOptions = (): OperationSchema[] => {
  const { t } = useTranslation('eflow');
  return [
    {
      operationType: OperationTypesEnum.StorageInspection,
      name: t('newAnalysis.operations.operationsOptions.inspectionStorage'),
    },
    {
      operationType: OperationTypesEnum.OperationInspection,
      name: t('newAnalysis.operations.operationsOptions.inspectionOperation'),
    },
    {
      operationType: OperationTypesEnum.OperationStorage,
      name: t('newAnalysis.operations.operationsOptions.operationStorage'),
    },
    {
      operationType: OperationTypesEnum.InspectionTransport,
      name: t('newAnalysis.operations.operationsOptions.inspectionTransport'),
    },
    {
      operationType: OperationTypesEnum.OperationTransport,
      name: t('newAnalysis.operations.operationsOptions.operationTransport'),
    },
  ];
};
