import { useEffect } from 'react';

import { useGetEmployeeLazyQuery } from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

export const useGetCurrentUserDetails = () => {
  const { organizationId, userUuid } = useUserStore();

  const [query, { loading: isLoading, data }] = useGetEmployeeLazyQuery({
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!organizationId || !userUuid) {
      return;
    }
    void query({
      variables: {
        input: {
          organizationId: organizationId,
          id: userUuid,
        },
      },
    });
  }, [organizationId, userUuid, query]);

  return { isLoading, user: data?.getEmployee };
};
