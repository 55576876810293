import { RenderTree } from '@/types/treeView';

/**
 * @description
 * When you click element on {ChechboxTree}, you get the path from root to this element.
 * Finds a path from root to a current node element.
 * @param node
 * tree element
 * @param {string|undefined} targetId
 * @returns {string|null} Path to the node with the given id or null if not found.
 */
export function findPathById(
  node: RenderTree,
  targetId: string | undefined,
): string | null {
  if (!node) return null;
  if (!targetId) return null;
  if (node.id === targetId) {
    return node.name;
  }

  if (node.children) {
    for (const child of node.children) {
      const path = findPathById(child, targetId);
      if (path !== null) {
        return node.name + ' / ' + path;
      }
    }
  }

  return null;
}
