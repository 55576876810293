import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Typography } from '@mui/material';

import { InfoModalContent, ModalContainer } from '@/core/components';
import { theme } from '@/theme';

import { DeleteCellModalProps } from './DeleteCellModal.types';

export const DeleteCellErrorModal = ({ closeModal }: DeleteCellModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalContainer
      closeModal={closeModal}
      width={'528px'}
      headerText={t('structure.removeCellErrorTitle')}
    >
      <InfoModalContent contentText={t('structure.removeCellErrorMessage')}>
        <PrimaryButton
          variant="contained"
          onClick={closeModal}
          sx={{
            bgcolor: theme.palette.primary[600],
          }}
        >
          <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
            {t('common.ok')}
          </Typography>
        </PrimaryButton>
      </InfoModalContent>
    </ModalContainer>
  );
};
