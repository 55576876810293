import {
  KaizenSubmissionStatusChangeButton,
  SentToImplementationAcceptedButton,
  SetAsInProgressButton,
} from '@kaizen/components';

import { KaizenStatuses } from '@/__generated__/graphql.ts';

export const useRenderButtonForSubmissionContractor = () => {
  const renderButtonForSubmissionContractor = (
    submissionStatus: KaizenStatuses,
    submissionId: string,
    isPrivilegedUser: boolean,
  ) => {
    let componentToRender = (
      <SetAsInProgressButton submissionId={submissionId} />
    );

    switch (submissionStatus) {
      case KaizenStatuses.Todo:
        componentToRender = (
          <SetAsInProgressButton submissionId={submissionId} />
        );
        break;

      case KaizenStatuses.InProgress:
        componentToRender = (
          <SentToImplementationAcceptedButton submissionId={submissionId} />
        );
        break;
      default:
        return null;
    }

    if (isPrivilegedUser) {
      return (
        <KaizenSubmissionStatusChangeButton
          withCustomButton={true}
          customButton={componentToRender}
          submissionId={submissionId}
          selectedStatus={submissionStatus}
          color={'primary'}
        />
      );
    }

    return componentToRender;
  };

  return {
    renderButtonForSubmissionContractor,
  };
};
