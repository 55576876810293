import { useTranslation } from 'react-i18next';

import { Box, Typography, styled } from '@mui/material';

import { toDdMmYyyy } from '@/core/hooks/useTimestamp/Functions/toDdMmYyyy';
import { theme } from '@/theme';

import { ReportHeaderProps } from './ReportHeader.types';

export const ReportHeader = (props: ReportHeaderProps) => {
  const { t } = useTranslation('eflow');

  return (
    <ReportHeaderContainer>
      <Typography
        variant="h1"
        fontSize="34px"
        fontWeight={600}
        color={theme.palette.text.primary}
      >
        {props.eflowData.name}
      </Typography>
      <Typography variant="bodyRegular">
        {props.eflowData.description}
      </Typography>
      <Row>
        <Typography variant="bodyRegular">
          {t('dashboard.startDate')}:{' '}
          {props.eflowData.startDate
            ? toDdMmYyyy(props.eflowData.startDate)
            : '-'}
        </Typography>

        {props.eflowData.analyzeLeaders?.length ? (
          <Typography variant="bodyRegular">
            {t('common.analyzeLeaders')}:{' '}
            {props.eflowData.analyzeLeaders
              .map((leader) => leader.firstName + ' ' + leader.lastName)
              .join(', ')}
          </Typography>
        ) : null}
        <Typography variant="bodyRegular">
          {t('newAnalysis.groups.title')}:{' '}
          {props.eflowData.workGroupUsers
            .map((user) => user.nameAndSurname)
            .join(', ')}
        </Typography>
      </Row>
    </ReportHeaderContainer>
  );
};

const ReportHeaderContainer = styled(Box)({
  color: theme.palette.grey[800],
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '12px',
});

const Row = styled(Box)({
  display: 'flex',
  gap: '24px',
  width: '100%',
  flexWrap: 'wrap',
  marginTop: '12px',
});
