import { ReactNode, createContext, useContext, useState } from 'react';

import { DashboardFragment } from '@/__generated__/graphql.ts';

type DashboardContextType = {
  dashboard: DashboardFragment | undefined;
  setDashboard: React.Dispatch<
    React.SetStateAction<DashboardFragment | undefined>
  >;
};

const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined,
);

export const DashboardStateDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [dashboard, setDashboard] = useState<DashboardFragment | undefined>();

  return (
    <DashboardContext.Provider value={{ dashboard, setDashboard }}>
      {children}
    </DashboardContext.Provider>
  );
};
// eslint-disable-next-line react-refresh/only-export-components
export const useDashboardStateDataContext = (): DashboardContextType => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error(
      'useDashboardStateDataContext must be used within a DashboardStateDataProvider',
    );
  }
  return context;
};
