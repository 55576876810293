export enum KaizenEditableStatuses {
  ACCEPTED = 'ACCEPTED',
  COMMISSION_ASSESSMENT = 'COMMISSION_ASSESSMENT',
  IMPLEMENTED = 'IMPLEMENTED',
  SUPERVISOR_ASSESSMENT = 'SUPERVISOR_ASSESSMENT',
  TODO = 'TODO',
}

export const KaizenEditableStatusesOrder = [
  KaizenEditableStatuses.SUPERVISOR_ASSESSMENT,
  KaizenEditableStatuses.COMMISSION_ASSESSMENT,
  KaizenEditableStatuses.ACCEPTED,
  KaizenEditableStatuses.TODO,
  KaizenEditableStatuses.IMPLEMENTED,
];
