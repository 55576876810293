import { captureException } from '@sentry/react';

import { IDBPDatabase, openDB } from 'idb';

/**
 * @description Class used to handle IndexDB
 * @version 1.0
 * @class IdbHandler
 * @description because indexDB requires a singleton pattern,
 * and we use it in multiple places, we need to have a single instance of the database.
 * Also, we use multiple databases, so we need to have multiple singletons
 */
export class IdbHandler {
  private static instances: Map<string, IDBPDatabase> = new Map();

  static async crateNoStoreConnection(
    databaseNameInput: string,
  ): Promise<IDBPDatabase> {
    const instanceKey = IdbHandler._factorKey(databaseNameInput);

    if (!IdbHandler.instances.has(instanceKey)) {
      const instance = await openDB(databaseNameInput, undefined, {
        blocked(currentVersion: number, blockedVersion: number | null) {
          captureException(
            new Error(`IndexDB blocked: ${currentVersion} ${blockedVersion} `),
          );
        },
      });

      IdbHandler.instances.set(instanceKey, instance);

      return instance;
    }
    return IdbHandler.instances.get(instanceKey) as IDBPDatabase;
  }

  static async createConnection<T>(
    objectStoreNameInput: string,
    databaseNameInput: string,
    indexNameInput?: keyof T,
  ): Promise<IDBPDatabase> {
    const instanceKey = IdbHandler._factorKey(databaseNameInput);

    if (!IdbHandler.instances.has(instanceKey)) {
      const instance = await openDB(databaseNameInput, undefined, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(objectStoreNameInput)) {
            const objectStore = db.createObjectStore(objectStoreNameInput);
            if (indexNameInput)
              objectStore.createIndex(
                indexNameInput as string,
                indexNameInput as string,
                {},
              );
          }
        },
        blocked(currentVersion: number, blockedVersion: number | null) {
          captureException(
            new Error(`IndexDB blocked: ${currentVersion} ${blockedVersion} `),
          );
        },
      });

      IdbHandler.instances.set(instanceKey, instance);

      return instance;
    }
    return IdbHandler.instances.get(instanceKey) as IDBPDatabase;
  }

  private static _factorKey(databaseNameInput: string = 'ecpsDB') {
    return `${databaseNameInput}`;
  }
}
