import { useTranslation } from 'react-i18next';

import { MoveToAddSubmissionButton } from '@kaizen/components';
import { KaizenSubmissionTableHandler } from '@kaizen/components/SubmissionTable/KaizenSubmissionTableHandler.tsx';

import { Box, Stack, Typography, styled } from '@mui/material';

export const Dashboard = () => {
  const { t } = useTranslation('kaizen');

  return (
    <Stack padding={'24px'} gap="20px">
      <TitleContainer>
        <Typography variant="h3">{t('dashboard.title')}</Typography>
        <MoveToAddSubmissionButton />
      </TitleContainer>
      <KaizenSubmissionTableHandler />
    </Stack>
  );
};

const TitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
});
