import { RefObject, useCallback, useMemo } from 'react';

import { shouldRenderLegend } from '@kpi/utils/should-render-legend.ts';

import { Stack } from '@mui/material';

import { RechartLegendElement } from '@core/components/Statistica/RechartChartLegend/RechartLegendElement';
import { ChartInput } from '@core/components/Statistica/types/ChartInput.types.ts';
import { useResponsiveGetMonthName } from '@core/hooks';
import {
  FormatChartLegend,
  FormatChartValues,
  FormatStackedChartWithTargetValues,
} from '@core/hooks/useRecharts/functions';
import { MonthRange } from '@core/utils';

import { ExtractChartKeyType } from './types';

export const useRecharts = <T extends HTMLElement>(
  props: ChartInput,
  chartContainerRef: RefObject<T>,
) => {
  const { responsiveGetMonthName } =
    useResponsiveGetMonthName(chartContainerRef);

  const chartValues = useMemo(
    () =>
      FormatChartValues(
        props.dataSet as ExtractChartKeyType,
        !!props.displayOptions.showTarget,
        props.monthsRange || MonthRange.months,
      ),
    [props.dataSet, props.displayOptions.showTarget, props.monthsRange],
  );

  const groupedBarChartValues = useMemo(
    () =>
      FormatStackedChartWithTargetValues(
        props.dataSet as ExtractChartKeyType,
        !!props.displayOptions.showTarget,
        props.monthsRange || MonthRange.months,
      ),
    [props.dataSet, props.displayOptions.showTarget, props.monthsRange],
  );

  const chartLegend = useMemo(
    () =>
      FormatChartLegend(
        props.dataSet as ExtractChartKeyType,
        !!props.displayOptions.showTarget,
        props.chartType,
      ),
    [props.dataSet, props.displayOptions.showTarget, props.chartType],
  );

  //move to useRechartLegend
  const toMonthLegend = (name: number | string): string => {
    if (!props.transformToMonthLegend) {
      return name.toString();
    } else return responsiveGetMonthName(Number(name)) as string;
  };

  const renderChartLegend = useCallback(() => {
    if (
      shouldRenderLegend(
        props.displayOptions.legendType,
        props.displayOptions.showDetailedLegend,
      )
    ) {
      return null;
    }

    return (
      <Stack sx={{ flexDirection: 'row', gap: 1, flexWrap: 'wrap', margin: 3 }}>
        {chartLegend.map((element) => (
          <RechartLegendElement key={element.dataKey} {...element} />
        ))}
      </Stack>
    );
  }, [
    chartLegend,
    props.displayOptions.legendType,
    props.displayOptions.showDetailedLegend,
  ]);
  //eom

  return {
    chartValues,
    chartLegend,
    toMonthLegend,
    renderChartLegend,
    groupedBarChartValues,
  };
};
