import { max, min } from 'lodash';
import { GetAsIsToBeEflowParamsQuery } from '@/__generated__/graphql.ts';
import { CalculateDataSeriesType } from './CalculateDataSeries.types';

/**
 * Calculate the data series, and min max value for the balance chart based on provided parameter
 *
 * @param props {GetAsIsToBeEflowParamsQuery} - The input props for the hook
 * @param parameterName {string} - The name of the parameter
 *
 * @example
 * const dataSeries = calculateDataSeries(props, 'parameterName');
 *
 * @returns {CalculateDataSeriesType} - The output of the hook
 *
 */
export const calculateDataSeries = (
  props: Pick<GetAsIsToBeEflowParamsQuery, 'findOneEFlow'>,
  parameterName: string,
): CalculateDataSeriesType => {
  const eFlowData = props?.findOneEFlow;
  let minimumValue = null,
    maximumValue = null;

  const asIsSteps = eFlowData?.asIsSteps || [];
  const toBeSteps = eFlowData?.toBeSteps || [];

  if (!eFlowData || (asIsSteps.length === 0 && toBeSteps.length === 0)) {
    return {
      dataset: [],
      minimumValue: 0,
      maximumValue: 0,
    };
  }

  const lastIndex = Math.max(asIsSteps.length, toBeSteps.length);
  const dataset: [number, number][] = [];

  for (let i = 0; i < lastIndex; i++) {
    const datasetRecord: [number, number] = [0, 0];

    if (asIsSteps[i]?.parameters?.[parameterName]?.value) {
      datasetRecord[0] = asIsSteps[i].parameters[parameterName].value;
    }

    if (toBeSteps[i]?.parameters?.[parameterName]?.value !== undefined) {
      datasetRecord[1] = toBeSteps[i]?.parameters[parameterName].value;
    }

    const comparedMin = min(datasetRecord)!;
    const comparedMax = max(datasetRecord)!;

    if (minimumValue === null || comparedMin < minimumValue) {
      minimumValue = comparedMin;
    }

    if (maximumValue === null || comparedMax > maximumValue) {
      maximumValue = comparedMax;
    }

    dataset.push(datasetRecord);
  }

  return {
    dataset,
    minimumValue: minimumValue as number,
    maximumValue: maximumValue as number,
  };
};
