import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CommonTableRow,
  TitleTypography,
  ValueTypography,
} from '@kaizen/views/SummaryView/components/Rows/CommonTableRow.tsx';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Box, IconButton, styled } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';

import { EmployeeAvatar } from '@core/components';
import { EmployeeMenu } from '@core/components/Menus/EmployeeMenu/EmployeeMenu.tsx';

import { useUserStore } from '@/stores';

import { SubmissionAuthorsProps } from './rows.types';

export const SubmissionAuthors = (props: SubmissionAuthorsProps) => {
  const { t } = useTranslation('kaizen');

  const { name, lastName, profilePicture, userUuid } = useUserStore();

  const inludedIds = useMemo(
    () => [...props.authors.map((author) => author.id), userUuid],
    [props.authors, userUuid],
  );

  return (
    <CommonTableRow>
      <TitleTypography variant="bodyRegular">
        {t('submissionModificationView.summary.table.submissionAuthor')}
      </TitleTypography>
      <ValueTypography
        variant="bodyRegular"
        sx={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <Row>
          <EmployeeAvatar imageUrl={profilePicture} name={name} />{' '}
          {`${name} ${lastName}`}
          <EmployeeMenu
            onChange={(e) =>
              props.onChangeSubmissionsAuthors([...props.authors, e])
            }
            includedIds={inludedIds}
            fireElement={<PersonAddAlt1Icon />}
          />
        </Row>
        {props.authors.map((employee) => (
          <Row key={employee.id}>
            <EmployeeAvatar
              imageUrl={employee.profilePicture}
              name={employee.firstName}
            />{' '}
            {`${employee.firstName} ${employee.lastName}`}
            <IconButton
              onClick={() =>
                props.onChangeSubmissionsAuthors(
                  props.authors.filter((author) => author.id !== employee.id),
                )
              }
            >
              <ClearIcon />
            </IconButton>
          </Row>
        ))}
      </ValueTypography>
    </CommonTableRow>
  );
};

const Row = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 6,
});
