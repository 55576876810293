import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { KaizenCommissionRow } from '../KaizenCommissionRow/KaizenCommissionRow';
import { KaizenCommissionTableProps } from './KaizenCommissionTable.types';

export function KaizenCommissionTable({
  data,
  onRemove,
  onUpdate,
}: KaizenCommissionTableProps) {
  const { t } = useTranslation('kaizen');

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('commission.role')}</TableCell>
            <TableCell>{t('commission.employee')}</TableCell>
            <TableCell>{t('commission.email')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <KaizenCommissionRow
              row={row}
              onRemove={onRemove}
              onUpdate={onUpdate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
