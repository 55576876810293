import { AnalizeLeaderInput, InputMaybe } from '@/__generated__/graphql';

/**
 *
 * @param analyzeLeadersIds analyzeleaders list
 * @returns form of date to send to the mutation
 */
export const getAnalizeLeadersIds = (
  analyzeLeadersIds?: InputMaybe<AnalizeLeaderInput[]> | undefined,
) =>
  analyzeLeadersIds?.map((user) => ({
    id: user.id,
    organizationId: user.organizationId,
  }));
