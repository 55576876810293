import { captureException } from '@sentry/react';
import { Dispatch, SetStateAction } from 'react';
import { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';
import { transformFromLayout } from '@core/modules/DashbordModule/functions';

import {
  DashboardFragment,
  DashboardWidgetDto,
  useUpdateDashboardLayoutMutation,
} from '@/__generated__/graphql.ts';

export const useDashboardLayout = (
  setDashboard: Dispatch<SetStateAction<DashboardFragment | undefined>>,
  dashboard?: DashboardFragment,
) => {
  const [updateLayoutMutation] = useUpdateDashboardLayoutMutation();

  const { t } = useTranslation('dashboard');

  const { companyId } = useCompanyId();

  const { openSnackbar } = useSnackbarContext();

  const updateLayout = async (layouts: Layout[]) => {
    if (!dashboard) return;

    if (dashboard.widgets.length !== layouts.length) {
      return;
    }

    try {
      const { data } = await updateLayoutMutation({
        variables: {
          input: {
            dashboardId: dashboard?.id,
            organizationId: companyId,
            widgets: transformFromLayout(layouts),
          },
        },
      });

      if (data) {
        setDashboard(data.updateDashboardLayout);
      }
    } catch (e) {
      openSnackbar(t('updateDashboard.error'));
      captureException(e);
    }
  };

  const transformToLayout = (layout: DashboardWidgetDto[]): Layout[] => {
    return layout.map((widget) => {
      return {
        ...widget.layoutModel,
        i: widget.layoutKey,
      } as Layout;
    });
  };

  return {
    updateLayout,
    transformToLayout,
  };
};
