import { captureException } from '@sentry/react';
import { useContext } from 'react';

import { EflowStepSyncContext } from '@e-flow/hooks/useSynchronizedFlows';
import { SynchronizedFlowsActionTypes } from '@e-flow/hooks/useSynchronizedFlows/SynchronizedFlowsAction.enum.ts';
import { useUpdateEflowStepCache } from '@e-flow/pages/flowDashboard/hooks/useCacheUpdate/hooks';
import { formatCommentsBeforeSave } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/Functions/formatCommentsBeforeSave.ts';
import { EFlowStepTypes } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';
import { useNetworkState } from '@uidotdev/usehooks';
import { omit, pick } from 'lodash';

import {
  AddEflowCommentInput,
  EflowStepFragmentFragment,
  FileModel,
  OperationInput,
  S3FileInput,
  useUpdateEflowStepMutation,
} from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

import { StepProgressContext } from '../../../context/index.ts';
import {
  UpdateOnline,
  useUpdateEflowStepParams,
  useUpdateEflowStepReturnTypes,
} from './useUpdateEflowStep.types.ts';

export const useUpdateEflowStep = (
  props: useUpdateEflowStepParams,
): useUpdateEflowStepReturnTypes => {
  const { setStepProgressValue, setStepProgressSnackbarOpen } =
    useContext(StepProgressContext);

  const { pushToSynchronize } = useContext(EflowStepSyncContext);

  const [updateMutation] = useUpdateEflowStepMutation();

  const { online: isOnline } = useNetworkState();

  const { userUuid, organizationId: userOrganizationId } = useUserStore();

  const { updateEflowStep } = useUpdateEflowStepCache(
    props.eFlowId,
    props.organizationId,
  );

  const updateOnline: useUpdateEflowStepReturnTypes['updateOnline'] = async (
    params,
  ) => {
    setStepProgressValue(0);
    setStepProgressSnackbarOpen(true);
    let lastResponse: EflowStepFragmentFragment | undefined = undefined;

    const newFiles = params.files.filter(() =>
      (params.eFlowStep as EflowStepFragmentFragment).files?.every(
        (currentFile) => 'id' in currentFile,
      ),
    );

    if (newFiles.length > 0)
      for (let i = 0; i < newFiles.length; i++) {
        const currentFiles =
          i === 0
            ? _createFilesChangeSet(
                (params.eFlowStep as EflowStepFragmentFragment).files,
              )
            : undefined;
        lastResponse = await _updateOnline(params, currentFiles, [newFiles[i]]);
        setStepProgressValue((i / newFiles.length) * 100);
      }
    else
      lastResponse = await _updateOnline(
        params,
        _createFilesChangeSet(
          (params.eFlowStep as EflowStepFragmentFragment).files,
        ),
        [],
      );

    if (lastResponse) {
      updateEflowStep(
        params.eFlowStep.analizeType,
        lastResponse,
        params.eFlowStep.stepNumber,
      );

      await props.updateElement(
        params.currentEflowStepId,
        lastResponse as EFlowStepTypes,
      );
    }
    setStepProgressSnackbarOpen(false);

    return undefined;
  };

  const updateOffline: useUpdateEflowStepReturnTypes['updateOffline'] = async (
    params,
  ) => {
    await Promise.all([
      await params.updateElement(params.currentEflowStepId, params.eFlowStep),
      await pushToSynchronize(
        params.eFlowStep.eFlowId,
        params.currentEflowStepId,
        SynchronizedFlowsActionTypes.UPDATE,
        props.analizeType,
      ),
    ]);
  };

  const update: useUpdateEflowStepReturnTypes['update'] = async (eFlowStep) => {
    if (isOnline) {
      await updateOnline(eFlowStep);
    } else {
      await updateOffline(eFlowStep);
    }
  };

  const _updateOnline = async (
    params: UpdateOnline,
    currentFiles: S3FileInput[] | undefined,
    file: File[],
  ) => {
    try {
      const { data } = await updateMutation({
        variables: {
          input: {
            id: params.currentEflowStepId,
            organizationId: params.organizationId,
            eFlowId: params.eFlowStep.eFlowId,
            operation: omit(params.eFlowStep.operation, [
              '__typename',
            ]) as OperationInput,
            operationName: params.eFlowStep.operationName,
            parameters: params.eFlowStep.parameters,
            ..._handleUpdateComments(params.eFlowStep),
            currentFiles,
          },
          ...(file.length > 0 && { files: file }),
        },
      });

      return data?.updateEflowStep as EflowStepFragmentFragment;
    } catch (error) {
      captureException(error);
      void pushToSynchronize(
        params.eFlowStep.eFlowId,
        params.currentEflowStepId,
        SynchronizedFlowsActionTypes.UPDATE,
        props.analizeType,
      );
    }
  };

  function _handleUpdateComments(eFlowStep: EFlowStepTypes) {
    const newComments: AddEflowCommentInput[] = [];

    if (eFlowStep.comments) {
      newComments.push(
        ...formatCommentsBeforeSave(
          props.organizationId,
          {
            id: userUuid,
            organizationId: userOrganizationId!,
          },
          eFlowStep.comments,
        ),
      );
    }

    if ('commentModels' in eFlowStep) {
      return {
        newComments,
        commentModels: eFlowStep.commentModels?.map((commentModel) =>
          pick(commentModel, ['commentId', 'showInReport']),
        ),
      };
    }

    return { newComments };
  }

  function _createFilesChangeSet(
    files?: Partial<FileModel>[] | null,
  ): S3FileInput[] {
    if (!files) return [];

    const mappedFiles = files.map((file) => ({
      fileId: file.id!,
    }));

    return mappedFiles as S3FileInput[];
  }

  return {
    isLoading: false,
    update,
    updateOnline,
    updateOffline,
  };
};
