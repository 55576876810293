import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { RowStack } from '@organizations/styles';

import { CircularProgress, Typography, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { EmployeeRoles } from '@core/helpers';

import {
  GetEmployeesQuery,
  SystemRoles,
  useGetEmployeesLazyQuery,
} from '@/__generated__/graphql';
import { CustomSnackBar, NoDataInfo } from '@/core/components';
import { FlexCenter } from '@/core/styles';
import { useUserStore } from '@/stores';
import { useOrganizationStore } from '@/stores/useOrganizationStore/useOrganizationStore';
import { theme } from '@/theme';

import { AddAdminModal } from './AddAdminModal';
import { DeletePermissionModal } from './DeletePermissionModal';
import { columns } from './column';
import { AddEmployeeType } from './types';

export const GlobalPermissions = () => {
  const { companyName: companyUrl } = useParams();
  const { organization } = useOrganizationStore();
  const { t } = useTranslation();
  const { userGroups } = useUserStore();
  const isUserPermited = [
    SystemRoles.SystemAdmin,
    SystemRoles.Root,
    SystemRoles.KeyUser,
  ].some((role) => userGroups.includes(role));

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState('');
  const [employees, setEmployees] = useState<
    GetEmployeesQuery['getEmployees']['items']
  >([]);
  const [selectedRow, setSelectedRow] = useState<string>();

  const setSnackBar = (message: string) => {
    setIsShowSnackbar(true);
    setSnackBarMessage(message);
  };
  const addUser = (employee: AddEmployeeType) => {
    setEmployees((prev) => [...prev, employee]);
  };
  const removeUser = (id: string) => {
    setEmployees((currentEmployees) =>
      currentEmployees.filter((employee) => employee.id != id),
    );
  };

  const [query, { data, loading: isLoading }] = useGetEmployeesLazyQuery();

  useEffect(() => {
    if (organization[companyUrl!])
      void (async () => {
        await query({
          variables: {
            getEmployeesInput: {
              organisationId: organization[companyUrl!],
              limit: 20,
              systemRoles: [SystemRoles.Administrator],
            },
          },
        });
      })();
  }, [companyUrl, organization, query]);

  useEffect(() => {
    if (data) {
      setEmployees(data.getEmployees.items);
    }
  }, [data]);

  return (
    <>
      <RowStack justifyContent={'space-between'}>
        <Typography variant="h3">
          {t('organizationSettings.globalPermissions')}
        </Typography>
        {isUserPermited ? (
          <PrimaryButton
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary[600],
            }}
            onClick={() => setIsAddModalVisible(true)}
          >
            <Typography variant="bodyRegular">
              {t('organizationSettings.addAdmin')}
            </Typography>
          </PrimaryButton>
        ) : null}
      </RowStack>
      {isLoading ? (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      ) : (
        <>
          {employees.length === 0 ? (
            <NoDataInfo
              onClick={() => setIsAddModalVisible(true)}
              buttonText={t('organizationSettings.addAdmin')}
              headerText={t('organizationSettings.NoAdmins')}
              infoText={t('organizationSettings.PressButtonToAddAdmin')}
            />
          ) : (
            <CustomDataGrid
              rowHeight={40}
              columnHeaderHeight={40}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              rows={employees.map((employee) => {
                return {
                  id: employee.id,
                  employee: `${employee.firstName} ${employee.lastName}`,
                  permissionType: EmployeeRoles.getSystemRoles(
                    employee.systemRole,
                  ),
                  email: employee.email,
                };
              })}
              columns={columns({
                t,
                showModal: () => setIsDeleteModalVisible(true),
                isUserPermited,
              })}
              hideFooterSelectedRowCount
              onRowSelectionModelChange={(id) =>
                setSelectedRow(id[0] as string)
              }
            />
          )}
        </>
      )}
      <AddAdminModal
        open={isAddModalVisible}
        closeModal={() => setIsAddModalVisible(false)}
        setSnackBar={setSnackBar}
        addUser={addUser}
      />

      {isDeleteModalVisible && (
        <DeletePermissionModal
          open={isDeleteModalVisible}
          {...employees.find((employee) => employee.id === selectedRow)!}
          closeModal={() => setIsDeleteModalVisible(false)}
          setSnackBar={setSnackBar}
          removeUser={removeUser}
        />
      )}
      <CustomSnackBar
        open={isShowSnackbar}
        message={snackbarMessage}
        close={() => setIsShowSnackbar(false)}
      />
    </>
  );
};

const CustomDataGrid = styled(DataGrid)({
  border: 'none',
  borderRadius: 0,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  minHeight: '150px',
});
