import { useTranslation } from 'react-i18next';

import { Box, Typography, styled } from '@mui/material';

import { KaizenRankingFilters } from './Filters';
import { RankingTable } from './RankingTable';
import { KaizenRankingFiltersProvider } from './contexts/useKaizenRankingFilters.context';

export const Ranking = () => {
  const { t } = useTranslation('kaizen');

  return (
    <MainContainer>
      <KaizenRankingFiltersProvider>
        <Typography variant="h3">{t('ranking.title')}</Typography>
        <KaizenRankingFilters />
        <RankingTable />
      </KaizenRankingFiltersProvider>
    </MainContainer>
  );
};

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '32px 24px',
});
