import { forwardRef, useEffect, useState } from 'react';

import { omit } from 'lodash';

import { DragIndicator, MoreVert } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';

import { IconWrapper } from '@core/components/Icons/Wrapper/IconWrapper';
import { useMenu } from '@core/hooks';
import { WidgetMenu } from '@core/modules/DashbordModule/components';
import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context';
import { DashboardWidgetProvider } from '@core/modules/DashbordModule/contexts/DashboardWidget.context.tsx';
import {
  useDashboardWidget,
  useWidgetMenuPermission,
} from '@core/modules/DashbordModule/hooks';

import { theme } from '@/theme';

import { DashboardComponentWrapperProps } from './DashboardComponentWrapper.type';

export const DashboardComponentWrapper = forwardRef(
  (props: DashboardComponentWrapperProps, ref) => {
    const [isDragging, setIsDragging] = useState(false);

    const contextHook = useDashboardWidget();
    const { createDashboardData } = useDashboardContext();

    const { isUserPermittedToEdit } = useWidgetMenuPermission(
      createDashboardData.type,
    );

    const { anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose } = useMenu();

    useEffect(() => {
      return () => {
        handleMenuClose();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.widget.isHidden]);

    if (props.widget.isHidden) {
      return null;
    }

    return (
      <MainComponentContainer
        {...omit(props, ['children', 'title'])}
        ref={ref}
        key={props.key}
      >
        <TitleRow>
          {isUserPermittedToEdit && (
            <Box>
              <Box
                onMouseDown={() => setIsDragging(true)}
                onMouseUp={() => setIsDragging(false)}
                style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                className="drag-icon"
              >
                <DragIndicator fontSize={'small'} />
              </Box>
            </Box>
          )}
          <Typography variant="h6" sx={{ margin: 'auto' }}>
            {contextHook.widgetTitle}
          </Typography>
          {isUserPermittedToEdit && (
            <IconWrapper
              icon={<MoreVert fontSize={'small'} />}
              title={'options'}
              onClick={handleMenuOpen}
            />
          )}

          <WidgetMenu
            isMenuOpen={isMenuOpen}
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            widgetData={props.widget}
            widgetContent={contextHook.widgetContent}
            setWidgetContent={contextHook.setWidgetContent}
          />
        </TitleRow>
        <Box
          sx={{
            width: '100%',
            height: 'calc(100% - 50px)',
            margin: 0,
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DashboardWidgetProvider contextHook={contextHook}>
            {props.children}
          </DashboardWidgetProvider>
        </Box>
      </MainComponentContainer>
    );
  },
);

const MainComponentContainer = styled(Box)({
  padding: '16px',
  position: 'relative',
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '16px',
});

const TitleRow = styled(Box)({
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: '16px',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});
