import { captureException } from '@sentry/react';
import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId, useModal } from '@core/hooks';
import { RemoveWidgets } from '@core/modules/DashbordModule/functions/removeWidgets.ts';

import {
  DashboardFragment,
  useUpdateDashboardLayoutMutation,
} from '@/__generated__/graphql.ts';

export const useRemoveItem = (
  setDashboard: Dispatch<SetStateAction<DashboardFragment | undefined>>,
  dashboard?: DashboardFragment,
) => {
  const [itemToRemove, setItemToRemove] = useState<string>('');

  const { isModalVisible, setIsModalVisible, setModalContent, modalContent } =
    useModal();

  const [updateLayoutMutation] = useUpdateDashboardLayoutMutation();

  const { t } = useTranslation('dashboard');

  const { companyId } = useCompanyId();

  const { openSnackbar } = useSnackbarContext();

  const openRemoveModal = (itemLayoutKeyId: string, chartName: string) => {
    setItemToRemove(itemLayoutKeyId);
    setModalContent(
      <Trans ns={'charts'} i18nKey="removeChart" values={{ chartName }} />,
    );
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setItemToRemove('');
  };

  const removeItem = async () => {
    try {
      const { data } = await updateLayoutMutation({
        variables: {
          input: {
            dashboardId: dashboard!.id,
            organizationId: companyId,
            widgets: RemoveWidgets(itemToRemove, dashboard?.widgets),
          },
        },
      });
      if (data) {
        setDashboard(data.updateDashboardLayout);
      }
    } catch (error) {
      openSnackbar(t('updateDashboard.error'));

      captureException(error);
    } finally {
      setIsModalVisible(false);
      setItemToRemove('');
    }
  };

  return {
    removeModalContent: modalContent,
    isRemoveModalVisible: isModalVisible,
    removeItem,
    openRemoveModal,
    closeRemoveModal: closeModal,
  };
};
