/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const AddAdminodalScheme = () => {
  const { t } = useTranslation();
  return z.object({
    systemKeyUserUUID: z.string().min(1, t('login.requiredField')),
  });
};
