/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';

import { SubmissionManagementCommonTypes } from '@kaizen/components/Buttons/SubmissionManagementCommon/SubmissionManagementCommon.types.ts';
import { useSetAsInProgress } from '@kaizen/hooks';

import { Typography } from '@mui/material';

import { LoadingButton } from '@core/components';
import { useSnackbarContext } from '@core/contexts';

export const SetAsInProgressButton = ({
  submissionId,
}: SubmissionManagementCommonTypes) => {
  const { t: kaizenT } = useTranslation('kaizen');

  const { openSnackbar } = useSnackbarContext();

  const { sendToInProgress, isLoading } = useSetAsInProgress();

  const sendToInProgressHandler = async () => {
    if (await sendToInProgress(submissionId))
      openSnackbar(kaizenT('updateStatus.successMessage'));
    else openSnackbar(kaizenT('updateStatus.errorMessage'));
  };

  return (
    <LoadingButton isLoading={isLoading} onClick={sendToInProgressHandler}>
      <Typography>{kaizenT('updateStatus.startImplementation')}</Typography>
    </LoadingButton>
  );
};
