import { Avatar, Typography, styled } from '@mui/material';

import { UserForUserSwitchProps } from '@/core/components/UserSelect/UserSelectItem.type.ts';
import { theme } from '@/theme';

/**
 * Component is rendered for each user in the list
 * @param props{UserForUserSwitchProps} user data
 */
export const UserSelectItem = (props: UserForUserSwitchProps) => {
  return (
    <OptionContainer>
      <Avatar sx={{ mr: 2 }}>{props.firstName[0]}</Avatar>
      <OptionInfoContainer>
        <Typography variant="bodyRegular">
          {props.firstName} {props.lastName}
        </Typography>
        <Typography variant="captionRegular" color="textSecondary">
          {props.email}
        </Typography>
      </OptionInfoContainer>
    </OptionContainer>
  );
};

const OptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  padding: '10px 0px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ':hover': {
    backgroundColor: `var(--neutrals-gray-200, ${theme.palette.grey[100]})`,
  },
});

const OptionInfoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
