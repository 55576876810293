import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OperationSelectRow } from '@e-flow/pages/flowSteps/components/OperationSelectRow/OperationSelectRow.tsx';
import { OperationModuleTypes } from '@e-flow/pages/flowSteps/flowStepsModules/OperationsModule/OperationsModule.types.ts';
import { useBasicOperations } from '@e-flow/pages/newAnalysis/new-analysis-modules';

import { Alert } from '@mui/material';

import { OperationTypesEnum } from '@/__generated__/graphql.ts';

import { ModuleContainer } from '../../components';

/**
 * OperationsModule
 * @constructor
 */
export const OperationsModule = (props: OperationModuleTypes) => {
  const { t } = useTranslation('eflow');
  const basicOperations = useBasicOperations();

  const [selectedOperation, setSelectedOperation] = useState<string | null>(
    props.selectedOperation,
  );
  const [operations, setOperations] = useState([
    ...(props.operations || []),
    ...basicOperations,
  ]);

  useEffect(() => {
    setOperations([...basicOperations, ...(props.operations || [])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.operations]);

  useEffect(() => {
    if (props.selectedOperation === selectedOperation) return;
    props.onFlowStepChange({
      operation: {
        name: selectedOperation || '',
        operationType: operations.find(
          (operation) => operation.name === selectedOperation,
        )?.operationType as OperationTypesEnum,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperation]);

  useEffect(() => {
    setSelectedOperation(props.selectedOperation);
  }, [props.selectedOperation]);

  return (
    <ModuleContainer title={t('flowSteps.operationType')}>
      {operations.map((operation, index) => (
        <OperationSelectRow
          selected={selectedOperation === operation.name}
          setSelectedOperation={setSelectedOperation}
          {...operation}
          key={index}
        />
      ))}

      {props.isError && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('flowSteps.operationRequired')}
        </Alert>
      )}
    </ModuleContainer>
  );
};
