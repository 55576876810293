import { useTranslation } from 'react-i18next';

import { SubmissionSelect } from '@kaizen/components';
import { useKaizenReportContext } from '@kaizen/pages/reports/context/kaizenReport.context.tsx';

import { Box, Typography, styled } from '@mui/material';

export const PageTitle = () => {
  const { t } = useTranslation('kaizen');

  const { setSettlementPeriodId } = useKaizenReportContext();

  return (
    <TitleContainer>
      <Typography variant="h3">{t('dashboard.title')}</Typography>
      <SubmissionSelect
        multiselect={false}
        width="300px"
        onSelect={(periodIds) => setSettlementPeriodId(periodIds[0])}
      />
    </TitleContainer>
  );
};

const TitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginBottom: 15,
});
