import { omit } from 'lodash';

import { Button } from '@core/components';
import { LightButtonTypes } from '@core/components/Buttons/LightButton/LightButton.types.ts';

import { theme } from '@/theme';

export const LightButton = (props: LightButtonTypes) => {
  return (
    <Button
      {...omit(props, 'children')}
      backgroundColor={theme.palette.grey[200]}
      textColor={theme.palette.common.black}
    >
      {props.children}
    </Button>
  );
};
