/**
 * Sorts an array of objects based on the order of a string array
 * @param orderArray {string[]} - The array of strings to order by
 * @param arrayToOrder {T[]} - The array of objects to order
 * @param key {keyof T} - The key to order by
 * @constructor {T[]} - The ordered array
 *
 * @example
 * SortArrayBasedOnStringArray(
 *  ['a', 'b', 'c'],
 *  [{ id: 'b' }, { id: 'c' }, { id: 'a' }],
 *  'id'
 *  )
 *  // Output: [{ id: 'a' }, { id: 'b' }, { id: 'c' }]
 */
export function SortArrayBasedOnStringArray<T>(
  orderArray: string[],
  arrayToOrder: T[],
  key: keyof T,
) {
  const orderedArray = new Array(orderArray.length);

  for (const item of arrayToOrder) {
    const index = orderArray.indexOf(item?.[key] as string);
    orderedArray[index] = item;
  }

  return orderedArray as T[];
}
