import { StatisticTableTypes } from '@e-flow/pages/flowReport/components/SybmolicMap/components/SymbolicRow/SybmbolicRow.types.ts';

import { EFlow } from '@/__generated__/graphql.ts';

/**
 * Merge an array of asIs and toBe operations.
 * If a step is not active, it will be null
 * @returns {StatisticTableTypes['operations']} - merged array of operations
 * @param params {Pick<EFlow, 'asIsSteps' | 'toBeSteps'>} - asIs and toBe steps from EFlow.
 */
export const mergeSymbols = (params: {
  asIsSteps?: Partial<EFlow['asIsSteps']>;
  toBeSteps?: Partial<EFlow['toBeSteps']>;
}): StatisticTableTypes['operations'] => {
  const mergedArray: StatisticTableTypes['operations'] = [];

  const { asIsSteps = [], toBeSteps = [] } = params;

  if (!asIsSteps || !toBeSteps) return mergedArray;

  const filteredAsIsSteps = asIsSteps.filter((step) => step?.isActive);
  const filteredToBeSteps = toBeSteps.filter((step) => step?.isActive);

  const length = Math.max(filteredToBeSteps.length, filteredAsIsSteps.length);

  for (let i = 0; i < length; i++) {
    const asIs = filteredAsIsSteps[i]?.isActive
      ? filteredAsIsSteps[i]?.operation?.operationType
      : undefined;

    const toBe = filteredToBeSteps[i]?.isActive
      ? filteredToBeSteps[i]?.operation?.operationType
      : undefined;

    mergedArray.push({ asIs, toBe });
  }

  return mergedArray;
};
