import { FC } from 'react';

import { Box, Typography, styled, useTheme } from '@mui/material';

import { RoleCellProps } from './RoleCell.types';

export const RoleCell: FC<RoleCellProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Container
      style={{
        backgroundColor: theme.palette.primary[100],
      }}
    >
      <Typography
        variant="labelSmRegular"
        fontWeight={400}
        style={{
          color: theme.palette.primary[900],
        }}
      >
        {children}
      </Typography>
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  height: '24px',
  padding: ' 4px 8px 4px 8px',
  alignItems: 'center',
  borderRadius: '8px',
});
