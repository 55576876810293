import { omit } from 'lodash';

import { Button } from '@mui/material';

import { DisabledButtonProps } from './DisabledButton.types';

export const DisabledButton = (props: DisabledButtonProps) => {
  return (
    <Button
      sx={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:disabled': {
          backgroundColor: props.backgroundDisabled,
        },
      }}
      {...omit(props, ['children', 'backgroundDisabled'])}
    >
      {props.children}
    </Button>
  );
};
