import { useCallback, useState } from 'react';

import { Dayjs } from 'dayjs';
import { useDebounceValue } from 'usehooks-ts';

import {
  GetEmployeesForMultiSelectQuery,
  KaizenStatuses,
} from '@/__generated__/graphql';

export const useKaizenDashboardFilters = () => {
  const [searchInputValue, setSearchInputValueRaw] = useState('');
  const [searchDateValue, setSearchDateValue] = useState<Dayjs | null>(null);
  const [searchStatuses, setSearchStatuses] = useState<KaizenStatuses[]>([]);
  const [submissionPeriodId, setSubmissionPeriodId] = useState<string[]>([]);
  const [isAssignedToMe, setIsAssignedToMe] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<
    GetEmployeesForMultiSelectQuery['getEmployees']['items']
  >([]);

  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(
    '',
    500,
  );

  const setSearchInputValue = useCallback((value: string) => {
    setSearchInputValueRaw(value);
  }, []);

  const areFiltersDefault = useCallback(() => {
    return (
      searchInputValue === '' &&
      searchDateValue === null &&
      searchStatuses.length === 0 &&
      submissionPeriodId.length === 0 &&
      !isAssignedToMe &&
      selectedEmployees.length === 0
    );
  }, [
    searchInputValue,
    searchDateValue,
    searchStatuses,
    submissionPeriodId,
    isAssignedToMe,
    selectedEmployees,
  ]);

  return {
    searchInputValue,
    searchDateValue,
    searchStatuses,
    submissionPeriodId,
    isAssignedToMe,
    setSearchInputValue,
    debouncedSearchValue,
    selectedEmployees,
    areFiltersDefault: areFiltersDefault(),
    setSearchDateValue,
    setSearchStatuses,
    setSubmissionPeriodId,
    setAssignedToMe: setIsAssignedToMe,
    setDebouncedSearchValue,
    setSelectedEmployees,
  };
};
