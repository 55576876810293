import { useSettlementPeriod } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod.tsx';

import { useCompanyId } from '@core/hooks';

import {
  FindAllSubmissionsDocument,
  FindSingleSubmissionInfoDocument,
  KaizenSubmissionFragment,
} from '@/__generated__/graphql.ts';
import { client } from '@/apollo';

export const useUpdateSubmissionCache = () => {
  const { companyId } = useCompanyId();

  const { currentActivePeriod } = useSettlementPeriod();

  const basicVariableSet = {
    input: {
      organizationId: companyId,
      limit: 10,
      cursor: 0,
      settlementPeriodId: [currentActivePeriod?.id],
    },
  };

  const addNewSubmission = (newSubmission: KaizenSubmissionFragment) => {
    const data = client.readQuery({
      query: FindAllSubmissionsDocument,
      variables: basicVariableSet,
    });

    if (!data) {
      client.writeQuery({
        query: FindAllSubmissionsDocument,
        variables: basicVariableSet,
        data: {
          findAllKaizenSubmissions: {
            items: [newSubmission],
            total: 1,
          },
        },
      });
      return;
    }

    client.writeQuery({
      query: FindAllSubmissionsDocument,
      variables: basicVariableSet,
      data: {
        findAllKaizenSubmissions: {
          items: [newSubmission, ...data.findAllKaizenSubmissions.items],
          total: data.findAllKaizenSubmissions.total + 1,
        },
      },
    });
  };

  const updateExistingSubmission = (
    updatedSubmission: KaizenSubmissionFragment,
  ) => {
    // Update the submission on the server
    const { findAllKaizenSubmissions } = client.readQuery({
      query: FindAllSubmissionsDocument,
      variables: basicVariableSet,
    });

    findAllKaizenSubmissions.items = findAllKaizenSubmissions.items.map(
      (submission: KaizenSubmissionFragment) =>
        submission.id === updatedSubmission.id ? updatedSubmission : submission,
    );

    client.writeQuery({
      query: FindAllSubmissionsDocument,
      variables: basicVariableSet,
      data: {
        findAllKaizenSubmissions: {
          ...findAllKaizenSubmissions,
        },
      },
    });
  };

  const createNewSubmission = (newSubmission: KaizenSubmissionFragment) => {
    const variables = {
      input: {
        organizationId: companyId,
        submissionId: newSubmission.id,
      },
    };

    const data = client.readQuery({
      query: FindSingleSubmissionInfoDocument,
      variables,
    });

    if (!data) {
      client.writeQuery({
        query: FindSingleSubmissionInfoDocument,
        variables,
        data: {
          findSingleSubmission: {
            ...newSubmission,
          },
        },
      });
      return;
    }

    client.writeQuery({
      query: FindSingleSubmissionInfoDocument,
      variables,
      data: {
        findSingleSubmission: {
          ...newSubmission,
        },
      },
    });
  };

  return {
    addNewSubmission,
    updateExistingSubmission,
    createNewSubmission,
  };
};
