import { captureException } from '@sentry/react';
import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { fetchAuthSession } from '@aws-amplify/auth';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';
import { Kind, OperationTypeNode } from 'graphql/language';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
}) as unknown as ApolloLink;

const socketLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT!,
    retryAttempts: 10,
    connectionParams: async () => {
      try {
        const { tokens } = await fetchAuthSession();
        const token = tokens?.idToken;
        return {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          authorization: token ? `Bearer ${token}` : '',
        };
      } catch (error) {
        captureException(error);
        return {};
      }
    },
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === Kind.OPERATION_DEFINITION &&
      definition.operation === OperationTypeNode.SUBSCRIPTION
    );
  },
  socketLink, // web socket connection for subscriptions
  uploadLink, // http connection for query and mutation
);

const authLink = setContext(async (_, { headers }) => {
  const { tokens } = await fetchAuthSession();
  const token = tokens?.idToken;
  return {
    headers: {
      ...headers,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      authorization: token ? `Bearer ${token}` : '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Apollo-Require-Preflight': true,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache({}),
});
