import {
  KpiRateFragment,
  KpiRateFragmentDoc,
  ReportKpiRateEntryInput,
} from '@/__generated__/graphql';
import { client } from '@/apollo';

export const useManageCacheKpiReport = () => {
  const readFragment = (rateId: string) => {
    const cacheId = `KpiRateDto:${rateId}`;

    return client.readFragment({
      fragment: KpiRateFragmentDoc,
      id: cacheId,
    }) as KpiRateFragment;
  };

  const writeFragment = (rateId: string, updatedFragment: KpiRateFragment) => {
    const cacheId = `KpiRateDto:${rateId}`;

    client.writeFragment({
      fragment: KpiRateFragmentDoc,
      id: cacheId,
      data: updatedFragment,
    });
  };

  const updateEntriesCache = (input: ReportKpiRateEntryInput) => {
    _updateEntriesCache(input);
  };

  const _updateEntriesCache = (input: ReportKpiRateEntryInput) => {
    for (const entry of input.entries) {
      const readValue = readFragment(entry.rateId);
      if (readValue.id) {
        const readEntries = {
          ...readValue.entries,
          [`${entry.month}`]: {
            modifiedAt: new Date().toISOString(),
            entry: entry.entry,
          },
        };

        const updatedFragment = {
          ...readValue,
          entries: readEntries,
        };

        writeFragment(entry.rateId, updatedFragment);
      }
    }
  };

  return {
    updateEntriesCache,
  };
};
