import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { Button } from '@core/components';
import { DoneModalProps } from '@core/components/Modals/LoadingModal/LoadingModal.types.ts';

export const DoneModal = (props: DoneModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DoneModalContainer>
        <Typography variant="bodyMedium">{props.doneText}</Typography>
      </DoneModalContainer>

      <Stack alignItems={'end'}>
        <Button
          variant="contained"
          onClick={props.closeModal}
          color={'success'}
          type="submit"
          sx={{
            width: 20,
          }}
        >
          <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
            {t('common.ok')}
          </Typography>
        </Button>
      </Stack>
    </>
  );
};

const DoneModalContainer = styled(Box)({
  display: 'flex',
  height: '100%',
  justifyContent: 'start',
  minHeight: '50px',
  width: '100%',
  flexDirection: 'column',
});
