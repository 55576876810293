/* eslint-disable @typescript-eslint/no-misused-promises */
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useKaizenSettlementPeriodContext } from '@kaizen/module-settings/SettlementPeriods/contexts';
import { useSettlementPeriod } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod.tsx';

import { styled } from '@mui/system';

import { InfoModal } from '@core/components';

export const ActivateSettlementPeriodModal = () => {
  const { t: kaizenT } = useTranslation('kaizen');

  const { currentActivePeriod, activateSettlementPeriod } =
    useSettlementPeriod();

  const {
    isActiveSettlementPeriodModalOpen,
    closeActivateSettlementPeriodModal,
    settlementPeriodActiveModalValues,
    open: openSnackbar,
  } = useKaizenSettlementPeriodContext();

  const activatePeriod = async () => {
    if (await activateSettlementPeriod(settlementPeriodActiveModalValues!.id)) {
      openSnackbar(
        'success',
        kaizenT('activateSettlementPeriodModal.successMessage'),
      );
    } else {
      openSnackbar(
        'error',
        kaizenT('activateSettlementPeriodModal.settlementPeriodActivateError'),
      );
    }

    closeActivateSettlementPeriodModal();
    return;
  };

  const formatMessageContent = useCallback(() => {
    return (
      <TextPreWrap>
        <Trans
          t={kaizenT}
          i18nKey="activateSettlementPeriodModal.contentText"
          values={{
            currentPeriodName: currentActivePeriod?.periodName,
            newPeriodName: settlementPeriodActiveModalValues?.periodName,
          }}
        />
      </TextPreWrap>
    );
    //eslint-disable-next-line
  }, [
    settlementPeriodActiveModalValues?.periodName,
    currentActivePeriod?.periodName,
  ]);

  return (
    <InfoModal
      display={isActiveSettlementPeriodModalOpen}
      content={formatMessageContent()}
      onConfirm={activatePeriod}
      confirmText={kaizenT('activateSettlementPeriodModal.activate')}
      closeModal={closeActivateSettlementPeriodModal}
      headerText={kaizenT('activateSettlementPeriodModal.headerText')}
    />
  );
};

const TextPreWrap = styled('span')({
  whiteSpace: 'pre-wrap',
});
