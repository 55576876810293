/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useEflowPermissions } from '@e-flow/hooks';
import { CloneOperationsModal } from '@e-flow/pages/flowDashboard/modals/AddOperationsToToBe/AddOperationsToToBe.tsx';

import {
  Add,
  AddAPhotoOutlined,
  AddCommentOutlined,
  ContentCopy,
  DeleteOutline,
  North,
  South,
  ToggleOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Divider, Menu, MenuItem, Typography, styled } from '@mui/material';

import { AsIsToBeNamesEnum } from '@/__generated__/graphql';
import { useCompanyId } from '@/core/hooks';

import { useRemoveEflowSteps } from '../../../flowSteps/hooks/useEflowSteps/useRemoveEflowStep/useRemoveEflowStep';
import { StepDetails } from '../../modals/StepDetails/StepDetails';
import { RowMenuProps } from './RowMenu.types';
import { useRowMenu } from './useRowMenu';

export const RowMenu = (props: RowMenuProps) => {
  const { t } = useTranslation('eflow');
  const { flowId } = useParams();

  const { companyId } = useCompanyId();

  const [isStepDetailsVisible, setIsStepDetailsVisible] = useState(false);

  const {
    toBeCount,
    isModalVisible,
    selectedModalOption,
    addToToBe,
    moveStepPosition,
    setIsModalVisible,
    changeEflowStepStatus,
    setSelectedModalOption,
    setDesiredEflowStartPosition,
  } = useRowMenu(props);

  const { removeOnlineSteps } = useRemoveEflowSteps({
    eFlowId: props.eFlowId!,
    organizationId: companyId,
    analyzeType: props.analizeType,
  });

  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: props.eFlowId,
  });

  const { t: common } = useTranslation();

  const options = [
    {
      icon: <North />,
      label: t('eflow.table.rowMenu.moveUp'),
      disabled: !canModifyAnalize || props.analizeStep.rowIndex === 0,
      onClick: async () => {
        await moveStepPosition(-1);
        props.handleClose();
      },
    },
    {
      icon: <South />,
      label: t('eflow.table.rowMenu.moveDown'),
      disabled:
        !canModifyAnalize ||
        props.analizeStep.rowIndex === props.stepsCount - 1,

      onClick: async () => {
        await moveStepPosition(1);
        props.handleClose();
      },
    },
    {
      icon: <DeleteOutline />,
      label: t('eflow.table.rowMenu.delete'),
      disabled: !canModifyAnalize,

      onClick: async () => {
        await removeOnlineSteps([props.analizeStep.id], props.analizeType);
        props.handleClose();
      },
    },
    {
      icon: <ToggleOffOutlined />,
      label: t(
        `eflow.table.rowMenu.${props.analizeStep.isActive ? 'deactivate' : 'activate'}`,
      ),
      disabled: !canModifyAnalize,

      onClick: async () => {
        await changeEflowStepStatus(props.analizeStep.isActive);
      },
    },
    {
      icon: <ContentCopy />,
      label: t('eflow.table.rowMenu.duplicate'),
      disabled: true,
    },
  ];

  const addOptions = [
    {
      icon: <AddCommentOutlined />,
      label: t('eflow.table.rowMenu.addComment'),
      disabled: true,
    },
    {
      icon: <AddAPhotoOutlined />,
      label: t('eflow.table.rowMenu.addAttachment'),
      disabled: true,
    },
    {
      icon: <Add />,
      label: t('eflow.table.rowMenu.addOperationBelow'),
      disabled: true,
    },
  ];

  return (
    <>
      <Menu
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
      >
        {props.analizeType === AsIsToBeNamesEnum.AsIs && (
          <CustomMenuItem
            disabled={!canModifyAnalize}
            onClick={() => {
              setIsModalVisible(true);
              props.handleClose();
            }}
          >
            <Add />
            <Typography variant="bodyRegular">
              {t('eflow.table.rowMenu.addToToBe')}
            </Typography>
          </CustomMenuItem>
        )}

        {props.analizeType === AsIsToBeNamesEnum.AsIs && <Divider />}

        {options.map((option, index) => (
          <CustomMenuItem
            disabled={option.disabled}
            key={index}
            onClick={async () => {
              option?.onClick && (await option.onClick());
              props.handleClose();
            }}
          >
            {option.icon}
            <Typography variant="bodyRegular">{option.label}</Typography>
          </CustomMenuItem>
        ))}
        <Divider />
        {addOptions.map((option, index) => (
          <CustomMenuItem key={index} disabled={option.disabled}>
            {option.icon}
            <Typography variant="bodyRegular">{option.label}</Typography>
          </CustomMenuItem>
        ))}
        <Divider />
        <CustomMenuItem
          onClick={() => {
            setIsStepDetailsVisible(true);
            props.handleClose();
          }}
        >
          <VisibilityOutlined />
          <Typography variant="bodyRegular">
            {t('eflow.table.rowMenu.details')}
          </Typography>
        </CustomMenuItem>
      </Menu>

      <StepDetails
        display={isStepDetailsVisible}
        eFlowId={flowId!}
        organizationId={companyId}
        closeModal={() => {
          setIsStepDetailsVisible(false);
        }}
        initialView={props.analizeType}
        initialStepId={props.analizeStep.id}
      />

      <CloneOperationsModal
        setSelectedOption={(value) => setSelectedModalOption(value as string)}
        allOperationLength={toBeCount}
        selectedOption={selectedModalOption}
        buttonPrimary={{
          onClick: () => {
            setIsModalVisible(false);
            props.handleClose();
          },
          text: common('common.cancel'),
        }}
        buttonSecondary={{
          onClick: () => {
            void addToToBe();
            setIsModalVisible(false);
            props.handleClose();
            props.setCopied(1);
          },
          text: common('common.add'),
        }}
        closeModal={() => setIsModalVisible(false)}
        display={isModalVisible}
        setDesiredEflowStartPosition={setDesiredEflowStartPosition}
      />
    </>
  );
};

const CustomMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
});
