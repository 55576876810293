import { RenderEflowStepsTypes } from '@e-flow/pages/flowSteps/Callbacks/RenderEflowSteps/RenderEflowSteps.types.ts';
import { OperationKey } from '@e-flow/pages/flowSteps/components';

export const RenderEflowSteps = ({
  allEflowSteps,
  currentEflowStepId,
  currentEflowStep,
  changeCurrentEflowStep,
}: RenderEflowStepsTypes) => {
  const eFlowStepsKeys: string[] = Object.keys(allEflowSteps);

  return eFlowStepsKeys.map((operation, index) => (
    <OperationKey
      isSelected={currentEflowStepId === operation}
      operationNumber={index + 1}
      onClick={() => {
        void changeCurrentEflowStep(operation);
      }}
      operationText={
        currentEflowStepId === operation
          ? currentEflowStep?.operationName
          : allEflowSteps[operation].operationName
      }
      key={operation + index}
    />
  ));
};
