import { useCallback, useEffect, useMemo, useState } from 'react';

import { Employee } from '@/__generated__/graphql';

import { UserTableProps } from './UserTable.types';

export const useUserTable = (props: UserTableProps) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const selectAllRows = () => {
    if (!employees) return;
    const newSelectedRows = employees.map((employee) => employee.id);
    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    props.onSelectedRowsChange && props.onSelectedRowsChange(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRows([]);
    setEmployees(
      props.employees.filter((employee) => !determineDisabledRow(employee)) ||
        [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employees]);

  const isDetermineChecked = useMemo(() => {
    if (!employees || employees.length <= 0) return false;
    return selectedRows.length === employees.length;
  }, [selectedRows, employees]);

  const determineIndeterminate = useCallback(() => {
    return selectedRows.length > 0 && selectedRows.length < employees.length;
  }, [selectedRows, employees]);

  const determineDisabledRow = useCallback(
    (employee: Employee) => {
      return props.shouldDisableRow && props.shouldDisableRow(employee);
    },
    [props],
  );

  const determineSelected = useCallback(
    (employee: Employee) => {
      return (
        selectedRows.includes(employee.id) || !!props.itemSelected?.(employee)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows],
  );

  const handleCheck = (employee: Employee) => {
    props.onItemSelect && props.onItemSelect(employee);
    if (selectedRows.includes(employee.id)) {
      const newSelectedRows = selectedRows.filter((employeeId) => {
        return employeeId !== employee.id;
      });
      setSelectedRows([...newSelectedRows]);
    } else {
      setSelectedRows([...selectedRows, employee.id]);
    }
  };

  const getData = (employee: Employee) =>
    props.cells.map((cell) => {
      if (cell.content) return cell.content(employee);
      return employee[cell.key as keyof typeof employee] as string;
    });

  return {
    selectAllRows,
    isDetermineChecked: isDetermineChecked,
    isDetermineIndeterminate: determineIndeterminate(),
    determineDisabledRow,
    determineSelected,
    handleCheck,
    getData,
    setSelectedRows,
    selectedRows,
  };
};
