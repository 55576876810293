import React, { useRef } from 'react';

import { toNumber } from 'lodash';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { VerticalBarChartLabel } from '@core/components/Statistica/Labels/VerticalBarChartLabel/VerticalBarChartLabel.tsx';
import { RechartVerticalBarchartCustomTooltip } from '@core/components/Statistica/Tooltips/RechartBarChartVerticalTooltip/RechartVerticalBarchartCustomTooltip.tsx';
import { ChartInput } from '@core/components/Statistica/types/ChartInput.types.ts';
import { useRechartVerticalChart } from '@core/hooks/useRecharts/useRechartVerticalChart.tsx';
import { useRecharts } from '@core/hooks/useRecharts/useRecharts.tsx';
import { setUnitShortcuts } from '@core/utils';

export const VerticalBarChart: React.FC<ChartInput> = (props) => {
  const ref = useRef(null);

  const { chartLegend, toMonthLegend, renderChartLegend } = useRecharts(
    props,
    ref,
  );

  const {
    formatVerticalBarChartData: { processedValues, accumulatedValues },
  } = useRechartVerticalChart(props);

  return (
    <ResponsiveContainer width="100%" height="100%" ref={ref}>
      <ComposedChart
        layout="vertical"
        data={processedValues}
        style={{
          margin: 10,
          padding: 10,
          alignItems: 'center',
          maxHeight: '100%',
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          type="number"
          tickFormatter={(value: string | number) =>
            setUnitShortcuts(props, value)
          }
        />
        <YAxis
          dataKey="name"
          type={'category'}
          interval={0}
          tickFormatter={(value) => toMonthLegend(+value)}
        />
        <Tooltip
          content={(tooltipProps) => (
            <RechartVerticalBarchartCustomTooltip
              active={tooltipProps.active}
              payload={tooltipProps.payload as unknown as never}
              label={tooltipProps.label}
              untransformedChartValues={accumulatedValues}
              showDataAsPercentage={props.displayOptions.showDataAsPercent}
              showTarget={props.displayOptions.showTarget}
            />
          )}
        />
        <Legend content={<>{renderChartLegend()}</>} />
        {chartLegend.map((entry) => (
          <Bar
            dataKey={entry.dataKey as string}
            key={entry.dataKey}
            name={entry.name}
            fill={entry.fill}
            stackId={entry.stackId}
            barSize={35}
          >
            {props.displayOptions.showDataLabels &&
              entry.dataKey?.endsWith('_value') && (
                <LabelList
                  dataKey={entry.dataKey}
                  position={'outside'}
                  content={(props) => (
                    <VerticalBarChartLabel
                      x={toNumber(props?.x)}
                      y={toNumber(props?.y)}
                      width={toNumber(props?.width)}
                      height={toNumber(props?.height)}
                      dataKey={entry.dataKey as string}
                      untransformedChartValues={accumulatedValues}
                      value={props.value as number}
                    />
                  )}
                />
              )}
          </Bar>
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
