import type { MouseEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useEflowPermissions } from '@e-flow/hooks';

import {
  Add,
  DeleteOutline,
  FilterList,
  ViewColumnOutlined,
} from '@mui/icons-material';
import { Box, IconButton, Typography, styled } from '@mui/material';

import { Button } from '@core/components';

import { theme } from '@/theme';

import { OptionsToDisplay, TableDisplayMenu } from '..';
import { AsIsHeaderProps } from './AsIsHeader.types';

export const AsIsHeader = (props: AsIsHeaderProps) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenDisplayFilter = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: props.eFlowId,
  });

  const handleChangeColumnsToDisplay = (options: OptionsToDisplay) => {
    const columnsNotToDisplay = Object.keys(options).filter(
      (key) => !options[key],
    );
    props.handleChangeColumnsToDisplay(columnsNotToDisplay);
  };

  return (
    <>
      <Main>
        <Typography>{props.title}</Typography>

        <Buttons>
          {props.removeOperations.isVisible && canModifyAnalize && (
            <Button
              onClick={props.removeOperations.onClick}
              startIcon={<DeleteOutline />}
              variant={'text'}
              sx={{
                color: theme.palette.text.primary,
              }}
              typographySx={{
                color: theme.palette.text.primary,
              }}
            >
              {commonT('common.delete')}
            </Button>
          )}
          {props.addToToBe.isVisible && canModifyAnalize && (
            <Button
              onClick={props.addToToBe.onClick}
              startIcon={<Add />}
              variant={'text'}
              sx={{
                color: theme.palette.text.primary,
              }}
              typographySx={{
                color: theme.palette.text.primary,
              }}
            >
              {t('eflow.table.addToToBe')}
            </Button>
          )}
          <ButtonsSection>
            {canModifyAnalize && (
              <IconButton
                title={t('eflow.addOperation')}
                onClick={() => navigate('operations/AS_IS')}
              >
                <Add
                  color="inherit"
                  style={{
                    color: theme.palette.text.primary,
                  }}
                />
              </IconButton>
            )}
            <IconButton disabled>
              <FilterList
                color="inherit"
                style={{
                  color: theme.palette.grey[500],
                }}
              />
            </IconButton>

            <IconButton onClick={handleOpenDisplayFilter}>
              <ViewColumnOutlined
                color="inherit"
                style={{
                  color: theme.palette.text.primary,
                }}
              />
            </IconButton>
          </ButtonsSection>
          <ButtonsSection>{props.currentActionButtons}</ButtonsSection>
        </Buttons>
      </Main>
      <TableDisplayMenu
        anchorEl={anchorEl}
        open={isOpen}
        handleClose={handleClose}
        parameters={props.parameters}
        onChange={handleChangeColumnsToDisplay}
      />
    </>
  );
};

const Main = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const Buttons = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
});

const ButtonsSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '6px',
});
