import { useState } from 'react';

export const useUserRow = (isSelected: boolean) => {
  const [isChecked, setIsChecked] = useState(isSelected);
  const handleCheck = (onCheck?: () => void) => {
    setIsChecked(!isChecked);
    if (onCheck) onCheck();
  };
  return { handleCheck, isChecked };
};
