import { ModuleContainerTypes } from '@e-flow/pages/flowSteps/components/ModuleContainer/ModuleContainer.types.ts';

import { Typography, styled } from '@mui/material';

export const ModuleContainer = ({
  titleVariant = 'h3',
  ...props
}: ModuleContainerTypes) => {
  return (
    <>
      <MainModuleContainer>
        <Typography variant={titleVariant}>{props.title}</Typography>
        {props.children}
      </MainModuleContainer>
    </>
  );
};

const MainModuleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '24px',
  width: '100%',
});
