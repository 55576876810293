import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SystemParameterKeysEnum } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum.ts';

import AddIcon from '@mui/icons-material/Add';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import {
  ParameterObject,
  ParameterTypes,
  ParameterUnitsTypes,
} from '@/__generated__/graphql';
import { CustomSnackBar, SearchTextField } from '@/core/components';
import { useIsMobile } from '@/core/hooks';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { RowStack } from '@/pages/organizations/styles';
import { theme } from '@/theme';

import {
  AddParameterModal,
  ParameterTable,
  WrongSearchInfo,
} from './Components';
import { ParameterModuleProps } from './ParameterModule.types';
import { MenuProps, UnitData, systemParams } from './SystemParams';

export const ParameterModule = ({
  stepBack,
  moveNext,
  setMainAnalysisPart,
  currentFormValues,
  setAddedParameters,
  addedParameters,
}: ParameterModuleProps) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation('translation');
  const [paramArray, setParamArray] = useState<ParameterObject[]>([
    ...systemParams,
    ...addedParameters,
  ]);
  const [searchedParams, setSearchedParams] = useState<ParameterObject[]>(
    currentFormValues.parameters || [],
  );
  const [selectedParams, setSelectedParams] = useState<ParameterObject[]>(
    currentFormValues.parameters || [],
  );
  const [search, setSearch] = useState<{
    name: string;
    unit: string[];
  }>({
    name: '',
    unit: [],
  });
  const [isAddParamModalVisible, setIsAddParamModalVisible] = useState(false);
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState('');

  const isMobile = useIsMobile();

  useEffect(() => {
    if (
      currentFormValues.parameters?.length &&
      currentFormValues.parameters.length > paramArray.length
    ) {
      setParamArray(currentFormValues.parameters);
    }
  }, [paramArray, currentFormValues]);

  useEffect(() => {
    const { name, unit } = search;
    if (search || unit.length > 0)
      setSearchedParams(
        paramArray.filter((param) => {
          const paramTranslatedName: string =
            param.type === ParameterTypes.System
              ? t(
                  `newAnalysis.parameters.systemParams.${param.name as SystemParameterKeysEnum}`,
                )
              : param.name;
          if (
            (name
              ? paramTranslatedName.toLowerCase().includes(name.toLowerCase())
              : true) &&
            (unit.length > 0 ? unit.includes(param.unit) : true)
          )
            return param;
        }),
      );
    else {
      setSearchedParams(paramArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, paramArray]);

  const setSnackBar = (message: string) => {
    setIsShowSnackbar(true);
    setSnackBarMessage(message);
  };

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSearch({
      ...search,
      unit: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handleSubmit = (callback: () => void) => {
    setMainAnalysisPart({
      parameters: selectedParams,
    });
    callback();
  };

  return (
    <>
      <Container>
        <Typography variant="h3">
          {t('newAnalysis.parameters.title')}
        </Typography>

        {/* filter section */}
        <RowStack justifyContent={'space-between'}>
          <Stack
            gap={'16px'}
            marginLeft={'-10px'}
            width={'80%'}
            direction={!isMobile ? 'row' : 'column'}
          >
            <SearchTextField
              value={search.name}
              placeholder={commonT('employees.search')}
              onChange={(event) => {
                setSearch({
                  ...search,
                  name: event.target.value,
                });
              }}
              limit={255}
            />
            <FormControl sx={{ width: '130px' }} size="small">
              <InputLabel id="demo-multiple-checkbox-label">
                {t('newAnalysis.parameters.unit')}
              </InputLabel>
              <Select
                multiple
                label={t('newAnalysis.parameters.unit')}
                value={search.unit}
                renderValue={(selectedUnitName) =>
                  selectedUnitName
                    .map((unitName: string) =>
                      t(
                        `newAnalysis.parameters.params.${unitName as ParameterUnitsTypes}`,
                      ),
                    )
                    .join(', ')
                }
                MenuProps={MenuProps}
                onChange={handleChange}
              >
                {UnitData.map((parameterUnit) => (
                  <MenuItem key={parameterUnit} value={parameterUnit}>
                    <Checkbox
                      checked={search.unit.includes(parameterUnit as string)}
                      size="small"
                    />
                    <Typography>
                      {t(`newAnalysis.parameters.params.${parameterUnit}`)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <PrimaryButton
            sx={{
              color: theme.palette.common.black,
            }}
            onClick={() => setIsAddParamModalVisible(true)}
          >
            <AddIcon fontSize="small" sx={{ marginRight: '5px' }} />
            <Typography variant="bodyMedium" sx={{ fontSize: '14px' }}>
              {t('newAnalysis.parameters.addUnit.button')}
            </Typography>
          </PrimaryButton>
        </RowStack>
        {/* Table section */}
        <ParameterTable
          paramArray={searchedParams}
          selectedParams={selectedParams}
          setSelectedParams={setSelectedParams}
        />
        {paramArray.length === 0 && <WrongSearchInfo />}

        {/* button section */}
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="start"
          gap="12px"
        >
          <PrimaryButton
            sx={{
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.common.black,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
              },
            }}
            onClick={() => handleSubmit(stepBack)}
          >
            <Typography variant="bodyRegular">
              {commonT('organization.back')}
            </Typography>
          </PrimaryButton>
          <PrimaryButton
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary[600],
            }}
            disabled={selectedParams.length == 0}
            onClick={() => handleSubmit(moveNext)}
          >
            <Typography variant="bodyRegular">
              {commonT('common.continue')}
            </Typography>
          </PrimaryButton>
        </Stack>
      </Container>
      {isAddParamModalVisible && (
        <AddParameterModal
          open={isAddParamModalVisible}
          addParameter={(newParam) => {
            setParamArray((prev) => [...prev, newParam]);
            setSelectedParams((prev) => [...prev, newParam]);
            setAddedParameters((prev) => [...prev, newParam]);
          }}
          close={() => setIsAddParamModalVisible(false)}
          paramArray={paramArray}
          setSnackBar={setSnackBar}
        />
      )}
      <CustomSnackBar
        open={isShowSnackbar}
        close={() => setIsShowSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
};

const Container = styled(Stack)({
  maxWidth: '728px',
  padding: '24px 0px',
  gap: '24px',
});
