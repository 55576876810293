import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { AuthScreenWrapper } from '@core/components';
import { InfoReroute } from '@core/components/InfoReroute';
import { InfoRerouteProps } from '@core/components/InfoReroute/InfoReroute.types.ts';

import { useLoginStatusStore } from '@/stores';

type PasswordConfirmationProps = Pick<
  InfoRerouteProps,
  'description' | 'buttonLabel'
> & {
  /**
   * Route to redirect to.
   */
  route?: string;
};

export const PasswordConfirmation = (props: PasswordConfirmationProps) => {
  const { t } = useTranslation();
  const { status, setStatus } = useLoginStatusStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (status) setStatus('CONFIRM_SIGN_UP');
    else navigate('/login');
  }, [navigate, setStatus, status]);

  return (
    <AuthScreenWrapper gap="16px">
      <InfoReroute
        title={t('passwordConfirmation.title')}
        icon={<CheckCircleOutlineIcon />}
        {...props}
        route={props.route}
      />
    </AuthScreenWrapper>
  );
};
