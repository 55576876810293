import { Trans, useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Typography } from '@mui/material';

import {
  CustomModal,
  InfoModalContent,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { theme } from '@/theme';

import {
  WorkstationModalProps,
  WorkstationToDeleteProps,
} from './WorkstationModal.types';

export const DeleteWorkstation = ({
  closeModal,
  workstations,
  setWorkstations,
  workstationToDelete,
}: WorkstationModalProps & WorkstationToDeleteProps) => {
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const filteredWorkstations = workstations.filter(
      (workstation) => workstation !== workstationToDelete,
    );
    await setWorkstations(
      filteredWorkstations,

      t('organizationSettings.deletedWorkstation'),
    );
    closeModal();
  };

  return (
    <CustomModal display={true}>
      <ModalContainer
        closeModal={closeModal}
        width={'528px'}
        headerText={t('organizationSettings.deleteWorkstation')}
      >
        <InfoModalContent
          contentText={
            <Trans
              i18nKey="organizationSettings.deleteWorkstationMessage"
              values={{
                workstation: workstationToDelete,
              }}
            />
          }
        >
          <TextButton
            text={t('common.cancel')}
            width="66px"
            onClick={closeModal}
          />
          <PrimaryButton
            variant="contained"
            sx={{
              backgroundColor: theme.palette.error[600],
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&:hover': {
                backgroundColor: theme.palette.error[700],
              },
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => handleSubmit()}
          >
            <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
              {t('common.delete')}
            </Typography>
          </PrimaryButton>
        </InfoModalContent>
      </ModalContainer>
    </CustomModal>
  );
};
