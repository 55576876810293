import {
  EFlowStepCommentDto,
  EflowReportComment,
} from '@/__generated__/graphql.ts';

import { LocalCommentType } from '../../types';

/**
 * Change value of single comment `showInReport` property
 * @param commentId
 * @param comments
 */
export const handleRemoveCommentsSavedOnServer = (
  commentId: string,
  comments?: EflowReportComment[],
): EflowReportComment[] => {
  if (!comments) {
    throw new Error('Comments are required');
  }
  return comments.filter((comment) => comment.commentId !== commentId);
};

/**
 * Updates comments for clinet side
 * @param currentComments
 * @param commentIndex
 */
export const handleRemoveCommentsSavedOnClient = (
  currentComments: (EFlowStepCommentDto | LocalCommentType)[],
  commentIndex: number,
): (EFlowStepCommentDto | LocalCommentType)[] => {
  if (!currentComments) {
    throw new Error('Comments are required');
  }

  delete currentComments[commentIndex];
  return currentComments;
};
