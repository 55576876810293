import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddOperationsToBeOptionsEnum } from '@e-flow/pages/flowDashboard/modals/AddOperationsToToBe/AddOperationsToBe.options.enum.ts';
import { AddOperationsToBeTypes } from '@e-flow/pages/flowDashboard/modals/AddOperationsToToBe/AddOperationsToBe.types.ts';

import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
} from '@mui/material';

import { Button, CustomModal, ModalContainer } from '@core/components';

import { theme } from '@/theme';

export const CloneOperationsModal = (props: AddOperationsToBeTypes) => {
  const { t } = useTranslation('eflow');

  const [selectedOption, setSelectedOption] = useState<number>(0);

  const renderSelectOptions = useCallback(() => {
    const menuOptions = [];
    for (let i = 1; i <= props.allOperationLength; i++) {
      menuOptions.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return menuOptions;
  }, [props.allOperationLength]);

  useEffect(() => {
    props.setDesiredEflowStartPosition(
      props.selectedOption === AddOperationsToBeOptionsEnum.ADD_TO_BOTTOM
        ? props.allOperationLength
        : selectedOption,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, props.allOperationLength]);

  return (
    <CustomModal display={props.display}>
      <ModalContainer
        closeModal={props.closeModal}
        headerText={t('eflow.table.toBe.addOperationsToToBeMap')}
        sx={{ ...props.sx, minWidth: '500px' }}
      >
        <FormControl>
          <StyledRadioGroup
            value={props.selectedOption}
            onChange={(event) => props.setSelectedOption(event.target.value)}
          >
            <FormControlLabel
              value={AddOperationsToBeOptionsEnum.ADD_TO_BOTTOM}
              control={<Radio />}
              label={t('eflow.table.toBe.addAtEndOfList')}
            />

            <RowWithInput>
              <FormControlLabel
                disabled={props.allOperationLength === 0}
                value={AddOperationsToBeOptionsEnum.ADD_AFTER}
                control={<Radio />}
                label={t('eflow.table.toBe.addAfter')}
              />

              <CustomSelect
                disabled={
                  props.selectedOption ===
                  AddOperationsToBeOptionsEnum.ADD_TO_BOTTOM
                }
                value={selectedOption}
                onChange={(event) =>
                  setSelectedOption(event.target.value as number)
                }
              >
                {renderSelectOptions()}
              </CustomSelect>
            </RowWithInput>
          </StyledRadioGroup>
        </FormControl>

        <ButtonContainer>
          {/*cancel button*/}
          <Button
            variant={'text'}
            onClick={props.buttonPrimary.onClick}
            disabled={props.buttonPrimary.disabled || false}
            sx={{
              color: theme.palette.grey[600],
            }}
          >
            {props.buttonPrimary.text}
          </Button>

          {/*submit button*/}
          <Button
            variant={'contained'}
            onClick={props.buttonSecondary.onClick}
            disabled={props.buttonSecondary.disabled || false}
          >
            {props.buttonSecondary.text}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </CustomModal>
  );
};

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '22px',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px',
  gap: '16px',
});

const RowWithInput = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
});

const CustomSelect = styled(Select)({
  width: '60px',
  height: '40px',
});
