/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';

import { EmptyResults } from '@e-flow/components/EmptyResult/EmptyResults.tsx';
import { FlowStatusFilter } from '@e-flow/components/FlowStatusFilter/FlowStatusFilter.tsx';
import { LoadingView } from '@e-flow/components/Loading/LoadingView.tsx';
import { Dayjs } from 'dayjs';
import { sortBy, uniqBy } from 'lodash';
import { useDebounceValue } from 'usehooks-ts';

import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Navbar } from '@core/components/Navbar/Navbar.tsx';

import {
  EFlow,
  EFlowStatuses,
  FindAllEFlowsQuery,
  GetEmployeesForMultiSelectQuery,
  OrganizationModules,
  SystemRoles,
  useFindAllEFlowsLazyQuery,
} from '@/__generated__/graphql';
import { Button, SearchTextField, UserMultiSelect } from '@/core/components';
import { useGlobalRedirects } from '@/core/redirects';
import { FlexCenter } from '@/core/styles';
import { useOrganizationStore, useUserStore } from '@/stores';
import { AllStatuses } from '@/types';

import { AnalizeCard } from '../../components/AnalizeCard/AnalizeCard';
import { useEflowPermission } from '../../hooks';
import { FindAllEflowsQueryBuilder } from './FindAllEflowsQueryBuilder';

export const Dashboard = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(
    '',
    500,
  );
  const [status, setStatus] = useState<EFlowStatuses>();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [cursor, setCursor] = useState<number>(0);
  const [analysis, setAnalysis] = useState<
    FindAllEFlowsQuery['findAllEFlows']['items']
  >([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedEmployees, setSelectedEmployees] = useState<
    GetEmployeesForMultiSelectQuery['getEmployees']['items']
  >([]);

  const { t } = useTranslation('eflow');

  const { t: commonT } = useTranslation();

  const { createNewAnalysisPath } = useGlobalRedirects();

  const { canCreateNewEflow } = useEflowPermission();

  const { companyName } = useParams();

  const { organization } = useOrganizationStore();
  const { userUuid, userGroups } = useUserStore();

  const [findAllFlows, { data, loading: isLoading }] =
    useFindAllEFlowsLazyQuery();

  useEffect(() => {
    setAnalysis([]);
  }, [debouncedSearchValue, status, startDate, selectedEmployees]);

  useEffect(
    () => {
      const builder = new FindAllEflowsQueryBuilder()
        .addSearch(debouncedSearchValue)
        .addStatuses(status)
        .addStartDate(startDate)
        .addEflowMembersIds(selectedEmployees)
        .addUserId(userUuid, userGroups);
      void findAllFlows({
        variables: {
          input: {
            organizationId: organization[companyName!],
            cursor,
            limit: 15,
            ...builder.build(),
          },
        },
        pollInterval: 10000,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      debouncedSearchValue,
      status,
      cursor,
      startDate,
      organization,
      companyName,
      userUuid,
      userGroups,
      selectedEmployees,
    ],
  );

  useEffect(() => {
    if (isLoading) return;
    data?.findAllEFlows.total && setTotal(data.findAllEFlows.total);
    data?.findAllEFlows.items &&
      setAnalysis(
        sortBy(
          uniqBy(
            [...data.findAllEFlows.items, ...analysis],
            'id',
          ) as Array<EFlow>,
          'startDate',
          'name',
        ).reverse(),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  return (
    <>
      <MainPageContainer>
        <Navbar variant={OrganizationModules.EFlowAnalize} />
        <MainContentContainer id="main-container">
          <TopPanel>
            <Typography variant="h3">{t('dashboard.analysis')}</Typography>
            <Tooltip title={canCreateNewEflow ? '' : commonT('error.noAccess')}>
              <span>
                <Button
                  disabled={!canCreateNewEflow}
                  variant={'contained'}
                  onClick={() => {
                    window.open(createNewAnalysisPath, '_blank');
                  }}
                >
                  {t('dashboard.createAnalysis')}
                </Button>
              </span>
            </Tooltip>
          </TopPanel>
          <InputPanel>
            <SearchTextField
              placeholder={t('dashboard.searchInputLabel')}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setDebouncedSearchValue(e.target.value);
              }}
            />
            <UserMultiSelect
              label={t('dashboard.employee')}
              onSelectedUsers={(
                users: GetEmployeesForMultiSelectQuery['getEmployees']['items'],
              ) => setSelectedEmployees(users)}
              selectedUsers={[...selectedEmployees]}
              employeeParams={{
                organisationId: organization[companyName!],
                systemRoles: [
                  SystemRoles.SystemAdmin,
                  SystemRoles.KeyUser,
                  SystemRoles.Root,
                  SystemRoles.EflowSpectator,
                  SystemRoles.EflowCreator,
                ],
              }}
            />
            <FlowStatusFilter
              sx={{ width: '200px' }}
              value={status}
              onChange={(event) => {
                // TODO: @bryndalski, switch to generic, when we change it to be generic we will worry how to handle this
                if (event.target.value === AllStatuses.All)
                  setStatus('' as EFlowStatuses);
                else setStatus(event.target.value as EFlowStatuses);
              }}
              variant={'standard'}
              size={'small'}
              placeholder={t('dashboard.status')}
            />
            <DatePicker
              label={t('dashboard.startDate')}
              value={startDate}
              onChange={(date) => setStartDate(date)}
              format={'DD/MM/YYYY'}
              slotProps={{
                textField: { size: 'small' },
                field: { clearable: true },
              }}
            />
          </InputPanel>
          {isLoading && analysis.length === 0 ? (
            <LoadingView />
          ) : analysis.length === 0 ? (
            <EmptyResults />
          ) : (
            <InfiniteScroll
              next={() => setCursor((prev) => prev + 16)}
              hasMore={analysis.length < total}
              loader={
                <FlexCenter sx={{ padding: '12px' }}>
                  <CircularProgress />
                </FlexCenter>
              }
              dataLength={analysis.length}
              scrollableTarget="main-container"
            >
              <CardsContainer>
                {analysis.map((analize) => (
                  <AnalizeCard key={analize.id} {...analize} />
                ))}
              </CardsContainer>
            </InfiniteScroll>
          )}
        </MainContentContainer>
      </MainPageContainer>
    </>
  );
};

const MainPageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
  overflow: 'hidden',
});

const MainContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '16px',
  overflowY: 'auto',
});

const CardsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: '16px',
  flexBasis: '100%',
  maxWidth: '100vw',

  '> *': {
    flexBasis: '100%', // for mobile: 1 row
    minWidth: '300px', // Card should have a min width of 300px

    '@media (min-width: 700px)': {
      flexBasis: '48%', // for tablet: 2 rows
    },

    '@media (min-width: 1050px)': {
      flexBasis: '32%', // for pc: n rows
    },
  },
});

const TopPanel = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const InputPanel = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  alignItems: 'center',
});
