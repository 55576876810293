import { SystemParameterKeysEnum } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum.ts';
import {
  ParameterObject,
  ParameterTypes,
  ParameterUnitsTypes,
} from '@/__generated__/graphql';

export const systemParams: ParameterObject[] = [
  {
    name: SystemParameterKeysEnum.TIME,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.TimeHhMmSs,
  },
  {
    name: SystemParameterKeysEnum.TIME_MINIMAL,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.TimeHhMmSs,
  },
  {
    name: SystemParameterKeysEnum.TIME_MAXIMAL,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.TimeHhMmSs,
  },
  {
    name: SystemParameterKeysEnum.TPZ,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.DistanceM,
  },
  {
    name: SystemParameterKeysEnum.TJ,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.TimeHhMmSs,
  },
  {
    name: SystemParameterKeysEnum.AREA,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.AreaM2,
  },
  {
    name: SystemParameterKeysEnum.OPERATOR_NUMBERS,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.VolumeUnits,
  },
  {
    name: SystemParameterKeysEnum.QUANTITY,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.VolumeUnits,
  },
  {
    name: SystemParameterKeysEnum.DISTANCE,
    type: ParameterTypes.System,
    unit: ParameterUnitsTypes.DistanceM,
  },
];

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 40 * 4.5,
      marginLeft: '64px',
    },
  },
};

export const UnitData: ParameterUnitsTypes[] =
  Object.values(ParameterUnitsTypes);
