import { SummaryTableTypes } from '@kaizen/views/SummaryView/components/SummaryTable/SummaryTable.types.ts';

import { Divider, Table, styled } from '@mui/material';

import {
  OrganizationUnitRow,
  SubmissionAuthors,
  SubmissionCreatedAt,
  SubmissionName,
  SubmitionId,
} from '../Rows';

export const SummaryTable = (props: SummaryTableTypes) => {
  return (
    <CustomTable>
      <SubmissionName name={props.submissionName} />
      <Divider />
      <OrganizationUnitRow />
      <Divider />
      <SubmissionAuthors
        onChangeSubmissionsAuthors={props.onChangeSubmissionsAuthors}
        authors={props.authors}
      />
      <Divider />
      <SubmitionId id={props.submissionKey} />
      <Divider />
      <SubmissionCreatedAt createdAt={props.createdAt} />
      <Divider />
    </CustomTable>
  );
};

const CustomTable = styled(Table)({
  maxWidth: '768px',
});
