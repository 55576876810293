import { SystemRoles } from '@/__generated__/graphql.ts';

/**
 * @description: Function return array of lower roles based on user groups
 * @returns GQLSystemRoles[]
 * @param userGroups
 */
export const getLowerRoles = (userGroups: SystemRoles[]) => {
  if (userGroups.includes(SystemRoles.Root))
    return [
      SystemRoles.SystemAdmin,
      SystemRoles.Administrator,
      SystemRoles.KeyUser,
      SystemRoles.UnitAdmin,
      SystemRoles.User,
    ];
  else if (userGroups.includes(SystemRoles.SystemAdmin))
    return [
      SystemRoles.KeyUser,
      SystemRoles.Administrator,
      SystemRoles.UnitAdmin,
      SystemRoles.User,
    ];
  else if (userGroups.includes(SystemRoles.KeyUser))
    return [SystemRoles.Administrator, SystemRoles.UnitAdmin, SystemRoles.User];
  else if (userGroups.includes(SystemRoles.Administrator))
    return [SystemRoles.UnitAdmin, SystemRoles.User];
  else if (userGroups.includes(SystemRoles.UnitAdmin))
    return [SystemRoles.User];
  else return [];
};
