/* eslint-disable @typescript-eslint/naming-convention */
export const palette = {
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  primary: {
    50: '#EEF6FF',
    100: '#D9EAFF',
    200: '#BCDBFF',
    300: '#8EC5FF',
    400: '#59A4FF',
    500: '#3B86FF',
    600: '#1B60F5',
    700: '#133BE1',
    800: '#173CB6',
    900: '#19378F',
  },
  text: {
    primary: '#121212',
    secondary: '#333333',
  },
  error: {
    50: '#FFF1F1',
    100: '#FFDFDF',
    200: '#FFC5C5',
    300: '#FF9D9D',
    400: '#FF6565',
    500: '#FF3535',
    600: '#EE2B2B',
    700: '#C70E0E',
    800: '#A41010',
    900: '#881414',
  },
  warning: {
    50: '#FEF8EC',
    100: '#FCEDCA',
    200: '#F9D893',
    300: '#F5C162',
    400: '#F2A844',
    500: '#E98633',
    600: '#CD6227',
    700: '#A9451E',
    800: '#89351A',
    900: '#712D17',
  },
  success: {
    50: '#F3FDE9',
    100: '#E4F8D2',
    200: '#CAF2AA',
    300: '#AAE87C',
    400: '#98DE67',
    500: '#72C042',
    600: '#589932',
    700: '#437426',
    800: '#375D22',
    900: '#304F1F',
  },
  grey: {
    25: '#F9FAFB',
    50: '#F6F7F8',
    100: '#EBECEE',
    200: '#DCDFE1',
    300: '#C8CDD0',
    400: '#A7AEB3',
    500: '#9199A0',
    600: '#808790',
    700: '#737982',
    800: '#61656C',
    900: '#505358',
  },
};

export type CustomPallete = typeof palette;
