import { captureException } from '@sentry/react';

import { useUpdateSingleSubmissionCache } from '@kaizen/hooks';

import {
  KaizenSubmissionFragment,
  useSetKaizenSubmissionImplementationAcceptedStatusMutation,
} from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

export const useSetAsImplementationAccepted = () => {
  const { organizationId } = useUserStore();

  const [
    setKaizenSubmissionImplementationAcceptedStatusMutation,
    { loading: isLoading },
  ] = useSetKaizenSubmissionImplementationAcceptedStatusMutation();

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const setImplementationAsAccepted = async (submission: string) => {
    if (!organizationId) return false;

    try {
      const result =
        await setKaizenSubmissionImplementationAcceptedStatusMutation({
          variables: {
            input: {
              submissionId: submission,
              organizationId: organizationId,
            },
          },
        });
      if (result.data?.setKaizenSubmissionImplementationAcceptedStatus) {
        updateSingleSubmissionCache(
          result.data
            .setKaizenSubmissionImplementationAcceptedStatus as KaizenSubmissionFragment,
        );
        return true;
      }
    } catch (error) {
      captureException(error);
      return false;
    }
  };
  return {
    isLoading,
    setImplementationAsAccepted,
  };
};
