import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useOrganizationStore } from '@/stores';

/**
 * Hook to get the company id from the organization store
 */
export const useCompanyId = () => {
  const [companyId, setCompanyId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { companyName } = useParams();

  const { organization } = useOrganizationStore();

  useEffect(() => {
    if (!companyName) return;

    setIsLoading(false);
    setCompanyId(organization[companyName]);
  }, [companyName, organization]);

  return {
    isLoading,
    companyId,
  };
};
