import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useKpiDashboardContext } from '@kpi/pages/Dashboard/contexts/KpiDashbaordContext';

import { Box, Card, CircularProgress, styled } from '@mui/material';

import {
  LightButton,
  LoadingButton,
  RechartChart,
  TextButton,
} from '@core/components';

import { useUserDashboardContext } from '@/pages/home/pages/Dashboard/contexts/UserDashboardContext';
import { theme } from '@/theme';

import { useFindKpiChart } from '../../hooks/useFindKpiChart';
import { usePinChart } from '../../hooks/usePinChart';

type Props = {
  chartId?: string;
  setSelectedChart: Dispatch<SetStateAction<string | undefined>>;
};

export const KpiChartPreview = ({ chartId, setSelectedChart }: Props) => {
  const { t } = useTranslation('charts');

  const { t: commonT } = useTranslation();

  const { isLoading, chart } = useFindKpiChart(chartId);

  const { pinChartToUserDashboard, isLoading: isPinChartLoading } =
    usePinChart();

  const { selectedMonth } = useKpiDashboardContext();

  const { closeWidgetSidebar } = useUserDashboardContext();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!chart) {
    return <div>{t('chartNotFound')}</div>;
  }

  return (
    <MainContainer>
      <StyledCard>
        <RechartChart {...chart} selectedMonth={selectedMonth || 0} />
      </StyledCard>

      <ButtonSection>
        <TextButton
          width="100px"
          disabled={isPinChartLoading}
          text={commonT('common.cancel')}
          onClick={() => closeWidgetSidebar()}
        />
        <LightButton
          disabled={isPinChartLoading}
          onClick={() => setSelectedChart(undefined)}
        >
          {commonT('common.back')}
        </LightButton>
        <LoadingButton
          isLoading={isPinChartLoading}
          onClick={() => void pinChartToUserDashboard(chartId)}
        >
          {commonT('common.add')}
        </LoadingButton>
      </ButtonSection>
    </MainContainer>
  );
};

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '24px',
  overflow: 'auto',
  maxHeight: '700px',
  width: '700px',
  height: '700px',
});

const StyledCard = styled(Card)({
  gap: '16px',
  padding: '24px',
  borderRadius: '8px',
  cursor: 'pointer',
  // eslint-disable-next-line
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  width: '100%',
  height: '80%',
});

const ButtonSection = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 10,
});
