import { omit } from 'lodash';

import { IconButton, Tooltip } from '@mui/material';

import { IconWrapperProps } from '@core/components/Icons/Wrapper/IconWrapper.types.ts';

export const IconWrapper = (props: IconWrapperProps) => {
  return (
    <Tooltip {...omit(props, ['icon'])}>
      <IconButton disabled={props.disabled}>{props.icon}</IconButton>
    </Tooltip>
  );
};
