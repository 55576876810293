import { useReportModalContext } from '@kpi/contexts';

import { CircularProgress, Table, TableContainer } from '@mui/material';

import { FlexCenter } from '@core/styles';

import { TableColumns } from './TableColumns';
import { ReportModalTableRow } from './TableRow';

export const ReportDataGrid = () => {
  const { data, isLoading } = useReportModalContext();

  if (isLoading) {
    return (
      <FlexCenter>
        <CircularProgress size={30} />
      </FlexCenter>
    );
  }

  return (
    <TableContainer sx={{ height: '296px', overflowY: 'auto' }}>
      <Table size="small">
        <TableColumns />
        {data.map((item) => (
          <ReportModalTableRow
            key={item.id}
            id={item.id}
            rateName={item.rateName}
            entry={item.entry}
            shortcut={item.shortcut}
          />
        ))}
      </Table>
    </TableContainer>
  );
};
