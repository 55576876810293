import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { WrongSearchInfo } from '@e-flow/components';

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';

import { TablePaginationWrapper } from '@core/components';

import {
  Employee,
  useGetEmployeesForOrganizationLazyQuery,
} from '@/__generated__/graphql.ts';
import { useCompanyId, useFetchEmployees } from '@/core/hooks';
import { FlexCenter } from '@/core/styles';
import { theme } from '@/theme';

import { ModalEmployeesTableProps, SelectedRowsTypes } from '../types';
import { ModalEmployeeTableHeader } from './ModalEmployeeTableHeader';
import { ModalEmployeeTableRow } from './ModalEmployeeTableRow';

export const ModalEmployeesTable = ({
  selectedRows,
  setSelectedRows,
  search,
  organizationUnit,
  disabledEmployees,
}: ModalEmployeesTableProps) => {
  const { t } = useTranslation('eflow');

  const getEmployeesQuery = useGetEmployeesForOrganizationLazyQuery();

  const { companyId } = useCompanyId();

  const {
    isLoading,
    employees: rows,
    totalEmployees,
    rowsPerPage,
    cursor,
    setRowsPerPage,
    setCursor,
    setSearch,
    setOrganizationUnit,
  } = useFetchEmployees(companyId, getEmployeesQuery);

  useEffect(() => {
    setSearch(search);
    setOrganizationUnit(organizationUnit);
  }, [search, organizationUnit, setSearch, setOrganizationUnit]);

  const handleNewSelectedRow = (row: Employee) => {
    const newSelectedRows = { ...selectedRows };
    if (newSelectedRows[row.id]) {
      delete newSelectedRows[row.id];
    } else {
      newSelectedRows[row.id] = row;
    }
    setSelectedRows(newSelectedRows);
  };

  return (
    <>
      <TableContainer
        sx={{
          height: rows!.length !== 0 ? '85%' : '100px',
        }}
      >
        <TablePaginationWrapper
          total={totalEmployees || 0}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={(newValue) => setRowsPerPage(newValue)}
          cursor={cursor}
          setCursor={(newValue) => setCursor(newValue)}
        >
          {isLoading && (
            <FlexCenter
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              <CircularProgress />
            </FlexCenter>
          )}
          {!isLoading && (
            <Table size="small" style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <CustomTableRow>
                  <TableCell height={'40px'} width={'40px'}>
                    <Checkbox
                      checked={rows!.some((row) => selectedRows[row.id!])}
                      onChange={(_e, checked) => {
                        setSelectedRows((prev) => {
                          const newSelectedRows: SelectedRowsTypes = {
                            ...prev,
                          };
                          if (checked) {
                            rows!.forEach((row) => {
                              if (
                                !disabledEmployees?.find(
                                  (employee) => employee === row.id,
                                )
                              )
                                newSelectedRows[row.id!] = row as Employee;
                            });
                          } else {
                            rows!.forEach((row) => {
                              delete newSelectedRows[row.id!];
                            });
                          }
                          return newSelectedRows;
                        });
                      }}
                      checkedIcon={<IndeterminateCheckBoxIcon />}
                    />
                  </TableCell>
                  <ModalEmployeeTableHeader />
                </CustomTableRow>
              </TableHead>

              <TableBody>
                {rows!.length !== 0 &&
                  rows!.map((row) => (
                    <ModalEmployeeTableRow
                      key={row.id}
                      row={row as Employee}
                      isChecked={!!selectedRows[row.id!]}
                      onRowSelected={handleNewSelectedRow}
                      disabled={
                        !!disabledEmployees?.find((member) => member === row.id)
                      }
                    />
                  ))}
              </TableBody>
            </Table>
          )}
        </TablePaginationWrapper>
      </TableContainer>
      {rows!.length === 0 && !isLoading && (
        <WrongSearchInfo
          header={t('newAnalysis.groups.noEmployees')}
          text={t('newAnalysis.groups.changeSearch')}
        />
      )}
    </>
  );
};

const CustomTableRow = styled(TableRow)({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.common.white,
  zIndex: 1,
});
