import { useTranslation } from 'react-i18next';

import { Table, TableCell, TableRow } from '@mui/material';

import { FileTable } from '@core/components/Files/FileTable/FileTable.types.ts';
import { FileTableRow } from '@core/components/Files/FileTable/FleTableRow/FileTableRow.tsx';

export const FilesTable = (props: FileTable) => {
  const { t: commonT } = useTranslation();

  if (props.files.length === 0) {
    return null;
  }

  return (
    <>
      <Table>
        <TableRow>
          <TableCell>{commonT('common.fileName')}</TableCell>
          <TableCell />
        </TableRow>
        {props.files.map((file, idx) => (
          <FileTableRow
            key={idx}
            file={file}
            onFileRemove={props.onFileRemove}
          />
        ))}
      </Table>
    </>
  );
};
