import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { RowStack } from '@organizations/styles';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { Stack } from '@mui/system';

import {
  FindAllOrganizationsQuery,
  useFindAllOrganizationsLazyQuery,
  useRemoveKeyUserFromOrganizationMutation,
} from '@/__generated__/graphql';
import { TitleContainer } from '@/core/components';
import { FlexCenter } from '@/core/styles';
import { theme } from '@/theme';

import { AssignOrganization } from './AssignOrganizationModal';

export const UserOrganzations = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isNewOrganizationBeeingAsssigned, setIsNewOrganizationBeeingAssigned] =
    useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [organizations, setOrganizations] = useState<
    FindAllOrganizationsQuery['findAllOrganizations']['items']
  >([]);

  const [getKeyUserOrganizations, { data, loading: isLoading }] =
    useFindAllOrganizationsLazyQuery({
      variables: {
        paginationInput: {
          limit: 20,
          cursor: 0,
          keyUserId: id!,
        },
      },
    });
  const [removeKeyUserFromOrganization] =
    useRemoveKeyUserFromOrganizationMutation();

  useEffect(() => {
    void getKeyUserOrganizations();
  }, [getKeyUserOrganizations]);

  useEffect(() => {
    if (data?.findAllOrganizations.items) {
      setOrganizations(data.findAllOrganizations.items);
    }
  }, [data]);

  const handleRemoveKeyUserFromOrganization = async (
    organizationId: string,
    keyUserId: string,
  ) => {
    try {
      const res = await removeKeyUserFromOrganization({
        variables: {
          input: {
            organizationId,
            userId: keyUserId,
          },
        },
      });
      if (res.data?.removeKeyUserFromOrganization) {
        setSnackbarMessage(
          t('systemSettings.keyuserWasRemovedFromOrganization'),
        );
        setOrganizations(
          organizations.filter((org) => org.id !== organizationId),
        );
      }
    } catch (error) {
      setSnackbarMessage(t('error.errorAppearance'));
    }
  };

  return (
    <>
      {isNewOrganizationBeeingAsssigned && (
        <AssignOrganization
          closeModal={() => {
            setIsNewOrganizationBeeingAssigned(false);
          }}
        />
      )}
      <Stack width="100%">
        <RowStack justifyContent="space-between">
          <TitleContainer
            title={t('systemSettings.userOrganizations')}
          ></TitleContainer>
          <PrimaryButton
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary[600],
            }}
            onClick={() => setIsNewOrganizationBeeingAssigned(true)}
          >
            <Typography>{t('systemSettings.assignOrganization')}</Typography>
          </PrimaryButton>
        </RowStack>

        <OrganizationNameTypography>
          {t('systemSettings.organizationName')}
        </OrganizationNameTypography>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
          }}
        ></Box>
        {isLoading && (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        )}

        {organizations && organizations.length > 0 ? (
          organizations.map((organization) => (
            <React.Fragment key={organization.id}>
              <RowStackContainer>
                <OrganizationNameDisplay>
                  <img src="/assets/logo-small.svg" alt="logo" />
                  <Typography variant="bodyRegular">
                    {organization.name}
                  </Typography>
                </OrganizationNameDisplay>
                <Tooltip
                  title={t('systemSettings.removeAssignment')}
                  arrow
                  placement="top"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={() =>
                    handleRemoveKeyUserFromOrganization(organization.id, id!)
                  }
                >
                  <IconButton>
                    <CloseIcon htmlColor="grey" />
                  </IconButton>
                </Tooltip>
              </RowStackContainer>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  width: '100%',
                }}
              />
            </React.Fragment>
          ))
        ) : (
          <FailureTypography>{t('common.noData')}</FailureTypography>
        )}
      </Stack>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={4000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

const OrganizationNameTypography = styled(Typography)({
  fontWeight: '550',
  fontSize: '14px',
  marginLeft: '2%',
  marginBottom: '5px',
  marginTop: '32px',
});
const FailureTypography = styled(Typography)({
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'GrayText',
});

const RowStackContainer = styled(RowStack)({
  justifyContent: 'space-between',
  padding: '2px 2px 2px 15px',
});

const OrganizationNameDisplay = styled(Typography)({
  fontWeight: 300,
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',
});
