import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  styled,
} from '@mui/material';

import { ChartLegendType, RateSumTypes } from '@/__generated__/graphql';

import { useCreateChartContext } from '../../context';

export const Options = () => {
  const { t } = useTranslation('kpi');

  const {
    currentOptions,
    availableOptions,
    isAddIndicatorsChecked,
    setAddIndicator,
    handleOptionChange,
    setIsAddIndicatorsChecked,
    setLegendType,
  } = useCreateChartContext();

  return (
    <Stack gap="10px" justifyContent="flex-start">
      <Typography variant="h6">{t('charts.chartSettings')}</Typography>
      <Typography variant="bodyRegular">
        {t('charts.selectKpiCommunicate')}
      </Typography>
      <FormControlLabel
        disabled={!availableOptions.ascendingOrder}
        control={
          <Switch
            checked={currentOptions.ascendingOrder}
            onChange={() => handleOptionChange('ascendingOrder')}
          />
        }
        label={t('charts.showInAscendingOrder')}
      />
      <FormControlLabel
        disabled={!availableOptions.dataAsPercentage}
        control={
          <Switch
            checked={currentOptions.dataAsPercentage}
            onChange={() => handleOptionChange('dataAsPercentage')}
          />
        }
        label={t('charts.changeDataToPercent')}
      />
      <FormControlLabel
        disabled={!availableOptions.showTarget}
        control={
          <Switch
            checked={currentOptions.showTarget}
            onChange={() => {
              handleOptionChange('showTarget');
            }}
          />
        }
        label={t('charts.isTargetShown')}
      />
      <CustomRow>
        <FormControlLabel
          disabled={!availableOptions.sumIndicators}
          control={
            <Switch
              checked={isAddIndicatorsChecked}
              defaultChecked={isAddIndicatorsChecked}
              onChange={(_event, checked) => {
                setIsAddIndicatorsChecked(checked);
                setAddIndicator(undefined);
              }}
            />
          }
          label={t('charts.summKpis')}
        />
        <Select
          disabled={!isAddIndicatorsChecked}
          value={currentOptions.sumIndicators}
          onChange={(e) => {
            setAddIndicator(e.target.value as RateSumTypes);
            switch (e.target.value) {
              case RateSumTypes.SumAll:
                setLegendType(ChartLegendType.NoLegend);
                break;
              case RateSumTypes.SumDistinctName:
                setLegendType(ChartLegendType.VariableLegend);
                break;
              case RateSumTypes.SumOrganizationUnit:
                setLegendType(ChartLegendType.OrganizationUnitLegend);
                break;
              case RateSumTypes.SumEmployee:
                setLegendType(ChartLegendType.EmployeeLegend);
                break;
            }
          }}
          size="small"
        >
          <MenuItem value={RateSumTypes.SumAll}>
            {t(`charts.sumBy.${RateSumTypes.SumAll}`)}
          </MenuItem>
          <MenuItem value={RateSumTypes.SumDistinctName}>
            {t(`charts.sumBy.${RateSumTypes.SumDistinctName}`)}
          </MenuItem>
          <MenuItem value={RateSumTypes.SumOrganizationUnit}>
            {t(`charts.sumBy.${RateSumTypes.SumOrganizationUnit}`)}
          </MenuItem>
          <MenuItem value={RateSumTypes.SumEmployee}>
            {t(`charts.sumBy.${RateSumTypes.SumEmployee}`)}
          </MenuItem>
        </Select>
      </CustomRow>
    </Stack>
  );
};

const CustomRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
});
