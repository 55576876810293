import { useTranslation } from 'react-i18next';

import { useEflowStepChange } from '@e-flow/pages/newAnalysis/hooks/useEflowStepChange.tsx';

import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from '@mui/material';

import { CustomSnackBar, SimpleNavbar } from '@/core/components';
import { useIsMobile } from '@/core/hooks';
import { theme } from '@/theme';

export const NewAnalysis = () => {
  const { t } = useTranslation('eflow');
  const isMobile = useIsMobile();

  const {
    renderStepChange,
    currentStep,
    steps,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
  } = useEflowStepChange();

  return (
    <>
      <SimpleNavbar
        title={t('newAnalysis.newAnalysis')}
        action={() => window.close()}
      />
      <Stack
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        gap="24px"
      >
        {!isMobile ? (
          <SideBox>
            <SideBoxContent>
              <Stepper orientation="vertical" activeStep={currentStep - 1}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      <Typography variant="bodyRegular">{label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </SideBoxContent>
          </SideBox>
        ) : (
          <TopBox>
            <Stepper activeStep={currentStep - 1} connector={<Connector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Typography variant="bodyRegular">{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </TopBox>
        )}
        {/* Analysis form */}
        <FormContainer>{renderStepChange()}</FormContainer>
      </Stack>
      <CustomSnackBar
        open={isSnackBarOpen}
        close={closeSnackbar}
        message={snackBarMessage}
      />
    </>
  );
};

const SideBox = styled(Box)({
  width: '264px',
  height: 'calc(100vh - 56px)',
  borderRight: '1px solid var(--neutrals-gray-200, #dcdfe1)',
});

const SideBoxContent = styled(Box)({
  width: '264px',
  height: '100%',
  padding: '16px 24px 8px 24px',
});

const TopBox = styled(Box)({
  width: '100%',
  height: '64px',
  borderBottom: '1px solid var(--neutrals-gray-200, #dcdfe1)',
  overflowX: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 24px',
});

const Connector = styled('div')({
  width: '20px',
  height: '1px',
  background: theme.palette.grey[500],
});

const FormContainer = styled(Box)({
  width: '100%',
  padding: '24px',
  gap: '24px',
});
