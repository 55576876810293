import { useEffect, useMemo } from 'react';

import { useGetEmployeeProfilePictureNameAndSurnameLazyQuery } from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

export const useAvatarMenu = () => {
  const {
    name,
    lastName,
    profilePicture,
    setProfilePicture,
    userUuid,
    organizationId,
    setName,
    setLastName,
  } = useUserStore();

  const [getUserProfilePicture, { data, loading: isLoading }] =
    useGetEmployeeProfilePictureNameAndSurnameLazyQuery();

  useEffect(() => {
    if (!userUuid) return;
    void getUserProfilePicture({
      variables: {
        input: {
          id: userUuid,
          organizationId: organizationId!,
        },
      },
    });
    // eslint-disable-next-line
  }, [organizationId, userUuid]);

  useMemo(() => {
    if (data?.getEmployee) {
      const { firstName, lastName, profilePicture } = data.getEmployee;

      setProfilePicture(_getProfilePicture(profilePicture));
      setName(_getName(firstName));
      setLastName(_getSurname(lastName));
    }

    return null;
    // eslint-disable-next-line
  }, [data?.getEmployee]);

  function _getProfilePicture(profilePicture?: string | null) {
    if (!profilePicture) return '';
    return profilePicture;
  }

  function _getName(newName?: string) {
    if (!newName) return name;
    return newName;
  }

  function _getSurname(newLastName?: string) {
    if (!newLastName) return lastName;
    return newLastName;
  }

  return {
    isLoading,
    name,
    profilePicture,
  };
};
