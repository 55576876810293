import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ReportSections } from '@e-flow/pages/flowReport/components';
import { SymbolicMapTypes } from '@e-flow/pages/flowReport/components/SybmolicMap/components/SymbolicMaps.types.ts';
import { SymbolicRow } from '@e-flow/pages/flowReport/components/SybmolicMap/components/SymbolicRow/SymbolicRow.tsx';
import { mergeSymbols } from '@e-flow/pages/flowReport/components/SybmolicMap/logic/MergeSymbols.ts';
import { chunk } from 'lodash';

import { FullScreenLoading } from '@core/components';
import useResize from '@core/hooks/useResize/useResize.tsx';

import {
  EFlow,
  OperationTypesEnum,
  useGetAsIsToBeOperationSymbolsLazyQuery,
} from '@/__generated__/graphql.ts';
import { useOrganizationStore } from '@/stores';

export const SymbolicMap = (props: SymbolicMapTypes) => {
  const { t } = useTranslation('eflow');

  const [query, { data, loading: isLoading }] =
    useGetAsIsToBeOperationSymbolsLazyQuery();

  const { organization } = useOrganizationStore();
  const { companyName } = useParams();

  const containerRef = useRef<HTMLDivElement>(null);

  const { width } = useResize<HTMLDivElement>(containerRef);

  useEffect(() => {
    if (!props.eflowId) return;

    void (async () => {
      await query({
        variables: {
          input: {
            id: props.eflowId,
            organizationId: organization[companyName!],
          },
        },
      });
    })();
  }, [props.eflowId, query, organization, companyName]);

  const formatElements = useMemo(() => {
    const symbols = mergeSymbols({
      asIsSteps: data?.findOneEFlow?.asIsSteps as Partial<EFlow['asIsSteps']>,
      toBeSteps: data?.findOneEFlow?.toBeSteps as Partial<EFlow['toBeSteps']>,
    });

    if ('asIs' in symbols) {
      symbols.asIs = (
        symbols.asIs as (OperationTypesEnum | undefined)[]
      ).filter((el) => el !== undefined);
    }

    if ('toBe' in symbols) {
      symbols.toBe = (
        symbols.toBe as (OperationTypesEnum | undefined)[]
      ).filter((el) => el !== undefined);
    }

    return symbols;
  }, [data?.findOneEFlow?.asIsSteps, data?.findOneEFlow?.toBeSteps]);

  const splitFormatElements = useMemo(() => {
    const elementNumberPerRow = Math.floor(width / 40) - 3 || 1; // 40 is width of cell, 3 is reserved for text -> 2*40 (width of text cell) + (scaling cell = 40)

    return chunk(formatElements, elementNumberPerRow);
  }, [formatElements, width]);

  const renderSymbolicRows = useCallback(() => {
    let sumOfIndexes = 1;

    const symbolicRows: ReactNode[] = [];

    splitFormatElements.forEach((element, index) => {
      symbolicRows.push(
        <SymbolicRow
          key={index}
          operations={element}
          startCount={sumOfIndexes}
          endCount={(sumOfIndexes += element.length - 1)}
        />,
      );

      sumOfIndexes += 1; //1 index is booked for text "asIs" and "toBe" second for scaling (thats why 2()
    });

    return symbolicRows;
  }, [splitFormatElements]);

  return (
    <ReportSections ref={containerRef} title={t('reports.symbolicMap')}>
      {isLoading ? <FullScreenLoading /> : renderSymbolicRows()}
    </ReportSections>
  );
};
