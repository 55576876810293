import { useCompanyId } from '@core/hooks';
import { useHandleRoleUpdate } from '@core/hooks/useHandleRoleUpdate/useHandleRoleUpdate.ts';

import {
  SystemRoles,
  useGetEmployeeSystemRolesLazyQuery,
  useUpdateEmployeeWithUnitDataMutation,
} from '@/__generated__/graphql.ts';

export const useHandleUnitAdmin = () => {
  const { createSystemRolesChangeSet } = useHandleRoleUpdate();

  const [updateEmployee] = useUpdateEmployeeWithUnitDataMutation();
  const [getEmployeeSystemRoles] = useGetEmployeeSystemRolesLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const { companyId } = useCompanyId();

  const addUnitAdmin = async (
    userId: string,
    organizationUnitGuid?: string,
  ) => {
    const employeeSystemRoles = await _findEmployeeRoles(userId);

    return updateEmployee({
      variables: {
        input: {
          id: userId,
          organizationId: companyId,
          systemRole: createSystemRolesChangeSet(
            SystemRoles.UnitAdmin,
            employeeSystemRoles,
          ),
          ...(organizationUnitGuid && { organizationUnitGuid }),
        },
      },
    });
  };

  const removeUnitAdmin = async (userId: string) => {
    const employeeSystemRoles = await _findEmployeeRoles(userId);

    return updateEmployee({
      variables: {
        input: {
          id: userId,
          organizationId: companyId,
          systemRole: createSystemRolesChangeSet(
            SystemRoles.User,
            employeeSystemRoles,
          ),
        },
      },
    });
  };

  async function _findEmployeeRoles(employeeId: string) {
    const { data } = await getEmployeeSystemRoles({
      variables: {
        input: {
          id: employeeId,
          organizationId: companyId,
        },
      },
    });

    if (!data?.getEmployee?.systemRole) return [];

    return data?.getEmployee?.systemRole;
  }

  return {
    addUnitAdmin,
    removeUnitAdmin,
  };
};
