import styled from '@emotion/styled';

import { Box, Stack, TableRow, Typography } from '@mui/material';

import { theme } from '@/theme';

export const DetailRow = styled(TableRow)({
  display: 'flex',
  gap: '20px',
  padding: '8px 0',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const DetailRowLabel = styled(Typography)({
  color: theme.palette.grey[700],
});

export const EmployeeDetailRow = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  whiteSpace: 'nowrap',
  flexDirection: 'row',
  textAlign: 'left',
});

//can't align details

export const DetailsContainer = styled(Box)({
  maxWidth: '600px',
  padding: '20px',
  display: 'flex',
  flexDirection: 'row',
  gap: '30px',
});
