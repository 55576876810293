import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounceValue } from 'usehooks-ts';

import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  styled,
} from '@mui/material';

import {
  Employee,
  GetKeyUsersQuery,
  useGetKeyUsersLazyQuery,
} from '@/__generated__/graphql.ts';
import { UserSelectTypes } from '@/core/components';
import { UserSelectItem } from '@/core/components/UserSelect/UserSelectItem.tsx';
import { Unpacked } from '@/types/unpacked.ts';

export const UserSelect = ({
  organizationId,
  systemRoles,
  onChange,
  width,
  error,
  helperText,
  placeholder,
  onSelectedUser,
  disabledUsers,
  organisationUnitIds,
  disabled,
  defaultUser,
  flag,
  onlyAvailableUnits,
}: UserSelectTypes) => {
  const { t } = useTranslation();
  const [search, setSearch] = useDebounceValue('', 500);
  const [limit] = useState<number>(10);
  const [cursor, setCursor] = useState<number>(0);
  const [keyUsers, setKeyUsers] = useState<
    GetKeyUsersQuery['getEmployees']['items']
  >([]);

  const [selectedUser, setSelectedUser] = useState<
    Unpacked<GetKeyUsersQuery['getEmployees']['items']> | undefined
  >(undefined);

  const [loadKeyUsers, { loading: isLoading, data }] =
    useGetKeyUsersLazyQuery();

  /**
   * Function to set the keyUsers state when data is loaded
   */
  useMemo(() => {
    if (!data) return;

    const filteredUser = Array.from(data.getEmployees.items).filter(
      (user) =>
        !disabledUsers?.find((disableUser) => disableUser.id === user.id),
    );

    setKeyUsers(filteredUser);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   * Effect to filter selected user if `organisationUnitIds` changes and `onlyAvailableUnits` is true
   */
  useEffect(() => {
    if (onlyAvailableUnits && selectedUser && organisationUnitIds) {
      const isUserInAvailableUnits =
        selectedUser &&
        organisationUnitIds.includes(selectedUser.organizationUnitGuid);

      if (!isUserInAvailableUnits) {
        setSelectedUser(undefined);
        onSelectedUser && onSelectedUser(undefined);
        setSearch('');
        onChange('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitIds]);

  // Find key user when search has changed
  useEffect(() => {
    void (async () => {
      await loadUsers();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, loadKeyUsers, organisationUnitIds]);

  const loadUsers = useCallback(async () => {
    await loadKeyUsers({
      variables: {
        getEmployeesInput: {
          organisationId: organizationId,
          ...(organisationUnitIds && {
            organisationUnitIds,
          }),
          ...(systemRoles && { systemRoles }),
          limit,
          cursor,
          search,
        },
      },
    });

    if (data && data?.getEmployees.total > cursor + limit)
      setCursor(cursor + limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cursor,
    limit,
    loadKeyUsers,
    organizationId,
    organisationUnitIds,
    systemRoles,
    search,
  ]);

  useEffect(() => {
    if (defaultUser && flag) {
      setEmployee(defaultUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultUser]);

  const setEmployee = (val: Employee) => {
    setSelectedUser(val);
    onSelectedUser && onSelectedUser(val);
    onChange(val.id);
  };

  return (
    <CustomAutocomplete
      key={selectedUser?.id}
      loading={isLoading}
      loadingText={<CircularProgress />}
      id="tags-standard"
      sx={{ width: width ?? '280px' }}
      options={keyUsers || []}
      disabled={disabled}
      getOptionLabel={(option) =>
        `${(option as Employee).firstName} ${(option as Employee).lastName}`
      }
      value={selectedUser}
      // Pagination
      ListboxProps={{
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onScroll: async (event: SyntheticEvent) => {
          const listBoxNode = event.currentTarget;
          if (
            listBoxNode.scrollTop + listBoxNode.clientHeight ===
              listBoxNode.scrollHeight &&
            data &&
            data?.getEmployees.total > cursor + limit
          ) {
            await loadUsers();
          }
        },
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <UserSelectItem
            {...(option as Unpacked<GetKeyUsersQuery['getEmployees']['items']>)}
          />
        </Box>
      )}
      onInputChange={(_e, val) => setSearch(val)}
      autoComplete
      onChange={(_e, val) => {
        if ((val as Employee) !== null) {
          setEmployee(val as Employee);
        } else {
          setSelectedUser(undefined);
          onSelectedUser && onSelectedUser(undefined);
          onChange('');
        }
      }}
      includeInputInList
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onOpen={loadUsers}
      // Selected option display
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            size="small"
            label={placeholder ? placeholder : t('common.selectUser')}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedUser && (
                <InputAdornment position="start">
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      fontSize: '12px',
                    }}
                  >
                    {selectedUser?.firstName[0]}
                  </Avatar>
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};

const CustomAutocomplete = styled(Autocomplete)({
  width: '232px',
  height: '40px',
  div: {
    div: {
      maxHeight: '40px',
      overflow: 'hidden',
    },
  },
});
