import { Employee, SystemRoles } from '@/__generated__/graphql';
import { OrganizationNode } from '@/core/types';

/**
 *
 * @param {OrganizatioNode} source whole tree
 * @returns tree with sorted members by systemrole
 */
export const getTreeWithSortedMembers = (
  source: OrganizationNode,
): OrganizationNode | null => {
  if (!source) return null;

  const newNode = { ...source };
  newNode.children = [];
  newNode.members = sortMembers(newNode.members);

  // Process the children nodes if any.
  if (source.children) {
    for (const child of source.children) {
      const processedChild = getTreeWithSortedMembers(child);
      if (processedChild) {
        newNode.children.push({
          ...processedChild,
          members: sortMembers(processedChild.members),
        });
      }
    }
  }

  return newNode;
};

/**
 *
 * @param {Employee[]} members members of single unit
 * @returns sorted members of unit by systemrole
 */

const sortMembers = (members: Employee[] | undefined) => {
  if (!members) return undefined;

  const unitAdminArray: Employee[] = [];
  const userArray: Employee[] = [];

  for (const member of members) {
    if (member.systemRole?.includes(SystemRoles.UnitAdmin))
      unitAdminArray.push(member);
    else userArray.push(member);
  }

  return [...unitAdminArray, ...userArray];
};
