import { useCallback } from 'react';

import { Box, styled } from '@mui/material';

import { NodeId } from '@/types';

import { TreeDetailsInterface } from './OrganizationTree.types';
import { RenderedContent } from './RenderedContent';
import { useTransformContent } from './useTransformContent';

export const TransformContent = ({
  selectedNodes,
  details,
}: {
  selectedNodes: NodeId[];
  details: TreeDetailsInterface;
}) => {
  const { organizationUnits, t, isLoading, error } = useTransformContent(
    details,
    selectedNodes,
  );

  const renderedContent = useCallback(
    () => (
      <RenderedContent
        isLoading={isLoading}
        error={error}
        organizationUnits={organizationUnits}
        t={t}
        details={details}
      />
    ),
    [isLoading, error, organizationUnits, t, details],
  );

  return <Container>{renderedContent()}</Container>;
};

const Container = styled(Box)({
  background: 'transparent',
  padding: '135px',
  width: '100%',
  height: 'auto',
});
