import { useTranslation } from 'react-i18next';

import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';
import { RowStack } from '@organizations/styles';

import { TextField } from '@mui/material';

import { AddNewActiveUnitProps } from './types';

export const AddNewActiveUnit = ({
  isCreateNewUnitActive,
}: AddNewActiveUnitProps) => {
  const { t } = useTranslation('kpi');

  const {
    useManageKpiModalState: { errors, register },
  } = useHandleAddKpiRateContext();

  if (!isCreateNewUnitActive) return <></>;

  return (
    <RowStack gap="16px" paddingTop={1}>
      <TextField
        size="small"
        sx={{ width: '100%' }}
        inputProps={{ maxLength: 50 }}
        {...register('unit.unitName')}
        label={t('addKpi.cellName')}
        error={!!errors.unit?.unitName?.message}
        helperText={errors.unit?.unitName?.message}
      />

      <TextField
        size="small"
        sx={{ width: '200px' }}
        inputProps={{ maxLength: 5 }}
        {...register('unit.unitShortcut')}
        label={t('addKpi.cellShortcut')}
        error={!!errors.unit?.unitShortcut?.message}
        helperText={errors.unit?.unitShortcut?.message}
      />
    </RowStack>
  );
};
