import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

import {
  GetCommissionMembersQuery,
  useAddCommissionMemberMutation,
  useGetCommissionMembersQuery,
  useRemoveCommissionMemberMutation,
  useSetCommissionLeaderMutation,
} from '@/__generated__/graphql';
import { useCompanyId } from '@/core/hooks';

import { CommissionMemberCacheHandler } from './CommissionMemberCacheHandler';
import { CommissionMember } from './types';

export const useCommission = () => {
  const { companyId } = useCompanyId();

  const [members, setMembers] = useState<
    GetCommissionMembersQuery['getCommissionMembers']['items']
  >([]);

  const [search, setSearch] = useState('');

  const [commissionMemberCacheHandler, setCommissionMemberCacheHandler] =
    useState(new CommissionMemberCacheHandler(companyId));

  const [addCommissionMemberMutation] = useAddCommissionMemberMutation();

  const [removeCommissionMemberMutation] = useRemoveCommissionMemberMutation();

  const [setCommissionLeaderMutation] = useSetCommissionLeaderMutation();

  const { data, loading: isLoading } = useGetCommissionMembersQuery({
    variables: { input: { organizationId: companyId } },
  });

  useEffect(() => {
    setCommissionMemberCacheHandler(
      new CommissionMemberCacheHandler(companyId),
    );
  }, [companyId]);

  useEffect(() => {
    if (data?.getCommissionMembers) setMembers(data.getCommissionMembers.items);
  }, [data]);

  const addCommissionMember = async (employee: CommissionMember) => {
    try {
      await addCommissionMemberMutation({
        variables: {
          input: {
            organizationId: companyId,
            employeeId: employee.id,
            isCommisionLeader: employee.isCommissionLeader,
          },
        },

        update: () => commissionMemberCacheHandler.add(employee),
      });
    } catch (ex) {
      captureException(ex);
    }
  };

  const setCommissionLeader = async (employeeId: string) => {
    try {
      await setCommissionLeaderMutation({
        variables: {
          input: {
            organizationId: companyId,
            employeeId,
          },
        },
        update: () => commissionMemberCacheHandler.updateRole(employeeId),
      });
    } catch (ex) {
      captureException(ex);
    }
  };

  const removeCommissionMember = async (employeeId: string) => {
    try {
      await removeCommissionMemberMutation({
        variables: {
          input: {
            organizationId: companyId,
            employeeId,
          },
        },
        update: () => commissionMemberCacheHandler.remove(employeeId),
      });
    } catch (ex) {
      captureException(ex);
    }
  };

  useEffect(() => {
    filterMembers();
    // eslint-disable-next-line
  }, [search]);

  const filterMembers = () => {
    if (!data?.getCommissionMembers) return;

    if (!search || !data?.getCommissionMembers) {
      setMembers(data?.getCommissionMembers.items);
      return;
    }

    setMembers(
      data.getCommissionMembers.items.filter((user) => {
        const userFirstName = user.firstName.toLowerCase();
        const userLastName = user.lastName.toLowerCase();
        const loweredFilterValie = search.toLowerCase();

        return (userFirstName + ' ' + userLastName).includes(
          loweredFilterValie,
        );
      }),
    );
  };

  return {
    members,
    isLoading,
    addCommissionMember,
    removeCommissionMember,
    setCommissionLeader,
    setSearch,
    search,
  };
};
