import { z } from 'zod';

/**
 * Validation schema for a login form
 * validates email and password
 * @param fieldRequired
 */
export const validationSchema = (fieldRequired: string) =>
  z.object({
    email: z
      .string({
        required_error: fieldRequired,
      })
      .email(fieldRequired),
    password: z.string({
      required_error: fieldRequired,
    }),
  });

export type ValidationSchema = {
  email: string;
  password: string;
};
