import { createSystemRolesChangeSet } from '@core/hooks/useHandleRoleUpdate/Functions/createSystemRolesChangeSet.ts';

import {
  SystemRoles,
  useUpdateEmployeeRoleMutation,
} from '@/__generated__/graphql.ts';

import { useCompanyId } from '..';

export const useHandleRoleUpdate = () => {
  const { companyId } = useCompanyId();

  const [updateEmployeeRolesMutation] = useUpdateEmployeeRoleMutation();

  const updateSystemRole = async (
    userId: string,
    newSystemRole: SystemRoles,
    currentUserRoles: SystemRoles[],
  ) => {
    const systemRolesChangeSet = createSystemRolesChangeSet(
      newSystemRole,
      currentUserRoles,
    );

    if (systemRolesChangeSet) {
      return await updateEmployeeRolesMutation({
        variables: {
          input: {
            id: userId,
            organizationId: companyId,
            systemRole: systemRolesChangeSet,
          },
        },
      });
    }
  };

  return {
    updateSystemRole,
    createSystemRolesChangeSet,
  };
};
