import { Avatar, Box, styled } from '@mui/material';

import { NamedAvatarTypes } from '@core/components/NamedAvatar/NamedAvatar.types.ts';

export const NamedAvatar = ({
  withNameNadSurname = false,
  ...params
}: NamedAvatarTypes) => {
  return (
    <StyledFlexRow>
      <StyledAvatar alt={params.lastName} src={params.profilePicture || ''}>
        {!params.profilePicture
          ? `${params.firstName[0]}${params.lastName[0]}`
          : null}
      </StyledAvatar>
      {withNameNadSurname && `${params.firstName}\xa0${params.lastName}`}
    </StyledFlexRow>
  );
};

const StyledAvatar = styled(Avatar)({
  width: '24px',
  height: '24px',
  fontSize: '12px',
});

const StyledFlexRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  alignItems: 'center',
});
