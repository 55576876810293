import { useTranslation } from 'react-i18next';

import { FormControlLabel, Switch, Typography } from '@mui/material';

import { OtherColumnsSectionProps } from './TableDisplayMenu.types';

export const OtherColumnsSection = (props: OtherColumnsSectionProps) => {
  const { t } = useTranslation('eflow');

  const options = [
    {
      name: 'operation',
      label: t('eflow.table.operationType'),
    },
    {
      name: 'operationName',
      label: t('eflow.table.operationName'),
    },
    {
      name: 'attachments',
      label: t('eflow.table.attachments'),
    },
    {
      name: 'comments',
      label: t('eflow.table.comments'),
    },
  ];

  return (
    <>
      {options.map((option) => (
        <FormControlLabel
          control={
            <Switch
              checked={props.otherOptions[option.name]}
              onChange={(e) =>
                props.setOtherOptions({
                  ...props.otherOptions,
                  [option.name]: e.target.checked,
                })
              }
            />
          }
          label={<Typography variant="bodyRegular">{option.label}</Typography>}
          key={option.name}
        />
      ))}
    </>
  );
};
