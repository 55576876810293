import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  ChangeEmployeeCellModal,
  DeleteUserModal,
  Status,
} from '@e-schema/components';
import { RowStack } from '@organizations/styles';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { CustomSnackBar, EmployeeAvatar } from '@/core/components';
import { useOrganizationStore } from '@/stores';
import { theme } from '@/theme';

import { EmployeesTableMenu } from './EmployeesTableMenu';
import type { EmployeesTableRowProps } from './EmployeesTableRow.types';

export const EmployeesTableRow = ({
  row,
  setSnackbar,
  setNewTotal,
  removeUserFromTable,
}: EmployeesTableRowProps) => {
  const { companyName } = useParams();
  const { organization } = useOrganizationStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteEmployeeModalOpened, setIsDeleteEmployeeModalOpened] =
    useState(false);
  const [isChangeCellModalOpened, setIsChangeCellModalOpened] = useState(false);
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState('');

  const setSnackBar = (message: string) => {
    setIsShowSnackbar(true);
    setSnackBarMessage(message);
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      {isDeleteEmployeeModalOpened && (
        <DeleteUserModal
          organizationId={organization[companyName!]}
          {...row}
          open={isDeleteEmployeeModalOpened}
          closeModal={() => setIsDeleteEmployeeModalOpened(false)}
          setSnackBar={(message: string) => {
            setSnackbar(message);
          }}
          setDeleteUserOperationStatus={(wasDeleted: boolean) => {
            if (wasDeleted) {
              removeUserFromTable();
              setNewTotal();
            }
          }}
        />
      )}

      {isChangeCellModalOpened && (
        <ChangeEmployeeCellModal
          open={true}
          closeModal={() => {
            setIsChangeCellModalOpened(false);
          }}
          changeEmployeeId={row.id}
          setSnackBar={setSnackBar}
        />
      )}
      <CustomTableRow>
        <ValuesCell height={'48px'}>
          <RowStack>
            <EmployeeAvatar
              name={row.firstName}
              imageUrl={row.profilePicture}
              size={24}
            />
            <CustomTopography marginLeft={2}>
              {row.firstName} {row.lastName}
            </CustomTopography>
          </RowStack>
        </ValuesCell>
        <ValuesCell>
          <CustomTopography>{row.email}</CustomTopography>
        </ValuesCell>
        <ValuesCell>
          <CustomTopography>{row.position}</CustomTopography>
        </ValuesCell>
        <ValuesCell>
          <CustomTopography>{row.organizationUnit.name}</CustomTopography>
        </ValuesCell>
        <ValuesCell>
          <Status status={row.state} />
        </ValuesCell>
        <ValuesCell>
          <CustomTopography>
            {row.createdAt && new Date(row.createdAt).toLocaleDateString()}
          </CustomTopography>
        </ValuesCell>
        <CustomTableCell>
          <IconButton
            aria-label="more"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
          <EmployeesTableMenu
            employeeId={row.id}
            setDeleteModalOpen={() => setIsDeleteEmployeeModalOpened(true)}
            setChangeCellModalOpen={() => setIsChangeCellModalOpened(true)}
            anchorEl={anchorEl}
            open={isMenuOpen}
            handleClose={() => setAnchorEl(null)}
          />
        </CustomTableCell>
      </CustomTableRow>
      <CustomSnackBar
        open={isShowSnackbar}
        close={() => setIsShowSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
};

const CustomTableRow = styled(TableRow)({
  transition: '0.2s ease all',
  cursor: 'pointer',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    background: theme.palette.grey[50],
  },
});

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});

const CustomTableCell = styled(TableCell)({
  align: 'right',
  height: '48px',
});

const ValuesCell = styled(TableCell)({
  align: 'left',
});
