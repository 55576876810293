import { FC } from 'react';

import {
  MenuItem as MenuWrapper,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { MenuItemProps } from './MenuItem.types';

export const MenuItem: FC<MenuItemProps> = ({
  children,
  handleClick,
  text,
  disabled = false,
}) => {
  return (
    <MenuWrapper disabled={disabled} onClick={handleClick}>
      <MenuItemStack>
        {children}
        <Typography fontSize={14} variant="bodyMedium">
          {text}
        </Typography>
      </MenuItemStack>
    </MenuWrapper>
  );
};

const MenuItemStack = styled(Stack)({
  flexDirection: 'row',
  gap: '16px',
  alignItems: 'center',
});
