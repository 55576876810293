import { useTranslation } from 'react-i18next';

import { DeleteOutline, SettingsOutlined } from '@mui/icons-material';
import { Menu } from '@mui/material';

import { checkSystemRolesPermission } from '@core/utils';

import { SystemRoles } from '@/__generated__/graphql.ts';
import {
  CustomMenuItem,
  LinkToOrganizationSettings,
} from '@/core/components/index.ts';
import { useUserStore } from '@/stores/index.ts';
import { theme } from '@/theme';

import { MenuProps } from './OrganizationComponents.types';

export const CustomMenu = ({
  anchorEl,
  open,
  handleClose,
  organization,
  handleDeleteOrganization,
}: MenuProps) => {
  const { t } = useTranslation();

  const { userGroups } = useUserStore();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      <LinkToOrganizationSettings organizationName={organization.url}>
        <CustomMenuItem
          text={t('organizationSettings.organizationSettings')}
          icon={
            <SettingsOutlined
              fontSize="small"
              sx={{
                color: theme.palette.grey[900],
              }}
            />
          }
        />
      </LinkToOrganizationSettings>
      {checkSystemRolesPermission(userGroups, [SystemRoles.Root]) &&
        organization.url !== 'luqam' && (
          <CustomMenuItem
            text={t('organization.deleteOrganization')}
            link="admin-panel"
            onClick={() =>
              handleDeleteOrganization(organization.id, organization.name)
            }
            icon={<DeleteOutline />}
          />
        )}
    </Menu>
  );
};
