import { useMemo } from 'react';

import { ChartInput } from '@core/components/Statistica/types';
import { FormatVerticalBarChart } from '@core/hooks/useRecharts/functions/FormatVerticalBarChart.ts';
import { ExtractChartKeyType } from '@core/hooks/useRecharts/types';

export const useRechartVerticalChart = (props: ChartInput) => {
  const formatVerticalBarChartData = useMemo(() => {
    if (props.displayOptions.showDataInAscendingOrder && props.monthsRange)
      return FormatVerticalBarChart(
        props.dataSet as ExtractChartKeyType,
        !!props.displayOptions.showTarget,
        props.monthsRange,
        !!props.displayOptions.showDataAsPercent,
      );

    return {
      processedValues: [],
      accumulatedValues: {},
    };
  }, [
    props.dataSet,
    props.displayOptions.showDataInAscendingOrder,
    props.displayOptions.showTarget,
    props.monthsRange,
    props.displayOptions.showDataAsPercent,
  ]);

  return {
    formatVerticalBarChartData,
  };
};
