import dayjs, { Dayjs } from 'dayjs';
import {
  EFlowStatuses,
  GetEmployeesForMultiSelectQuery,
  SystemRoles,
} from '@/__generated__/graphql';
import { checkSystemRolesPermission } from '@/core/utils';

export class FindAllEflowsQueryBuilder {
  private search?: string = undefined;
  private statuses?: EFlowStatuses[] = undefined;
  private startDate?: string = undefined;
  private eFlowMembersIds?: string[] = undefined;
  private userId?: string = undefined;

  constructor() {}

  addSearch(search: string): FindAllEflowsQueryBuilder {
    this.search = search;
    return this;
  }

  addStatuses(statuses?: EFlowStatuses): FindAllEflowsQueryBuilder {
    this.statuses = statuses ? [statuses] : undefined;
    return this;
  }

  addStartDate(startDate: Dayjs | null): FindAllEflowsQueryBuilder {
    if (!startDate) {
      this.startDate = undefined;
      return this;
    }

    this.startDate = dayjs(startDate).format('YYYY-MM-DD');
    return this;
  }

  addEflowMembersIds(
    selectedEmployees: GetEmployeesForMultiSelectQuery['getEmployees']['items'],
  ): FindAllEflowsQueryBuilder {
    this.eFlowMembersIds =
      selectedEmployees.length > 0
        ? selectedEmployees.map((employee) => employee.id)
        : undefined;
    return this;
  }

  addUserId(
    userId: string,
    userGroups: SystemRoles[],
  ): FindAllEflowsQueryBuilder {
    this.userId = checkSystemRolesPermission(userGroups, [
      SystemRoles.Administrator,
      SystemRoles.Root,
      SystemRoles.SystemAdmin,
    ])
      ? undefined
      : userId;

    return this;
  }

  build() {
    return {
      search: this.search,
      statuses: this.statuses,
      startDate: this.startDate,
      eFlowMembersIds: this.eFlowMembersIds,
      userId: this.userId,
    };
  }
}
