import { z } from 'zod';
import {
  CreateOrganisationInput,
  OrganizationModules,
} from '@/__generated__/graphql.ts';

export const AddOrganizationValidationSchema = (
  minLength: string,
  maxLength: string,
  invalidFormat: string,
) =>
  z.object({
    name: z.string().min(3, minLength).max(50, maxLength),
    url: z
      .string()
      .min(3, minLength)
      .max(50, maxLength)
      .regex(/^[a-zA-Z0-9_-]+$/, invalidFormat),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    systemKeyUserUUID: z.string().uuid().nullable(),
    modules: z.array(z.nativeEnum(OrganizationModules)).nonempty(),
  });

/**
 * Default values of the form
 */
export const AddOrganizationValidationSchemaDefaultValues: CreateOrganisationInput =
  {
    name: '',
    url: '',
    modules: [],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    systemKeyUserUUID: null,
  };
