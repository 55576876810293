import { useEffect, useState } from 'react';

import { TimeInputFormatTypes } from '@/core/components/inputs/TimeInput/types';
import { getTimeAsString, useTimeFormat } from '@/core/hooks';
import { theme } from '@/theme';

import { TimeCellProps } from './EflowTable.types';

export const TimeCell = (props: TimeCellProps) => {
  const [timeToDisplay, setTimeToDisplay] = useState<string>();

  const { time } = useTimeFormat({
    inputTime: props.value as number,
    timeFormat: props.format as TimeInputFormatTypes,
  });

  useEffect(() => {
    if (!props.value) return;
    if (props.value === '-') return setTimeToDisplay('-');

    setTimeToDisplay(getTimeAsString(time, props.format));
  }, [props.format, props.value, time]);

  if (!props.value)
    return (
      <span style={{ color: theme.palette.grey[500] }}>
        {props.placeholder}
      </span>
    );

  return <>{timeToDisplay}</>;
};
