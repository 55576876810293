/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusLabel } from '@kaizen/components';
import { KaizenSubmissionStatusChangeButtonTypes } from '@kaizen/components/Buttons/KaizenSubmissionStatusChangeButton/KaizenSubmissionStatusChangeButton.types.ts';
import {
  useChangeKaizenSubmissionStatus,
  useSortedStatuses,
} from '@kaizen/hooks';

import { MenuItem } from '@mui/material';

import { Button, SplitButton } from '@core/components';
import { useSnackbarContext } from '@core/contexts';

import { KaizenStatuses } from '@/__generated__/graphql.ts';

export const KaizenSubmissionStatusChangeButton = (
  props: KaizenSubmissionStatusChangeButtonTypes,
) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { t: kaizenT } = useTranslation('kaizen');

  const { openSnackbar } = useSnackbarContext();

  const { statusesWithNoDraft } = useSortedStatuses();

  const renderOptions = useMemo(() => {
    return statusesWithNoDraft;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { changeKaizenSubmissionStatus, isLoading } =
    useChangeKaizenSubmissionStatus();

  const handleStatusChange = async (status: KaizenStatuses) => {
    setIsMenuOpen(false);
    if (await changeKaizenSubmissionStatus(props.submissionId, status)) {
      openSnackbar(kaizenT('updateStatus.successMessage'));
    } else {
      openSnackbar(kaizenT('updateStatus.errorMessage'));
    }
  };

  return (
    <SplitButton
      isLight={props.isLight}
      color={props.color}
      isLoading={isLoading}
      mainButton={
        props.withCustomButton ? (
          props.customButton
        ) : (
          <Button color={props.color} onClick={props.onClick}>
            {props.buttonText}
          </Button>
        )
      }
      handleMainButtonClick={() => {
        setIsMenuOpen(!isMenuOpen);
      }}
      options={renderOptions}
      renderOptions={(option, index) => {
        return (
          <MenuItem
            onClick={() => handleStatusChange(option)}
            key={index}
            disabled={props.selectedStatus === option}
          >
            <StatusLabel key={index} variant={option} />
          </MenuItem>
        );
      }}
      isMenuOpen={isMenuOpen}
      setIsMenuOpen={setIsMenuOpen}
    />
  );
};
