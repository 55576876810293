import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { SectionWithTitle } from '@core/components';

import { DisplayParametersTypes } from './DisplayParametersTypes.types';
import { useDisplayParameters } from './useDisplayParameters';

export const DisplayParameters = (props: DisplayParametersTypes) => {
  const { t } = useTranslation('eflow');

  const { renderBody, parameterEntries } = useDisplayParameters(props);

  if (parameterEntries.length === 0 && !props.isLoading)
    return (
      <EmptyContainer>
        <Typography variant={'subtitle1'}>
          {t('common.noParameters')}
        </Typography>
      </EmptyContainer>
    );

  return (
    <SectionWithTitle isLoading={props.isLoading}>
      <TableContainer>{renderBody()}</TableContainer>
    </SectionWithTitle>
  );
};

const EmptyContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100px',
  width: '100%',
  color: 'rgba(0, 0, 0, 0.5)',
});

const TableContainer = styled(Box)({
  width: '100%',
  minHeight: '100px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});
