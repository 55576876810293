import { CircularProgress, Table, TableBody } from '@mui/material';

import { TablePaginationWrapper } from '@/core/components';
import { FlexCenter } from '@/core/styles';

import { useRanking } from '../hooks/useRanking';
import { NoRecordsScreen } from './NoRecordsScreen';
import { RankingTableRow } from './RankigTableRow';
import { RankingHeaders } from './RankingHeaders';

export const RankingTable = () => {
  const {
    total,
    cursor,
    rowsPerPage,
    setCursor,
    setRowsPerPage,
    isLoading,
    data,
  } = useRanking();

  return (
    <TablePaginationWrapper
      total={total}
      cursor={cursor}
      rowsPerPage={rowsPerPage}
      setCursor={(newValue) => setCursor(newValue)}
      setRowsPerPage={(newValue) => setRowsPerPage(newValue)}
    >
      {isLoading && (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      )}

      {!isLoading && (
        <>
          <Table size="small">
            <RankingHeaders />

            <TableBody>
              {data.map((item, index) => (
                <RankingTableRow
                  employee={item.employee}
                  submissions={item.submissionNumber}
                  points={item.totalPoints}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
          {!total && <NoRecordsScreen />}
        </>
      )}
    </TablePaginationWrapper>
  );
};
