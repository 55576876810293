import { useTranslation } from 'react-i18next';

import { SingleGoal } from '@kpi/modals/AddKpiRate/components';
import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';
import { RowStack } from '@organizations/styles';

import { Switch, Typography } from '@mui/material';

import { MonthNumberArray } from '@core/constants';

import { KpiRatingTargetType } from '@/__generated__/graphql.ts';

import { GoalValueTextField } from './components';

export const MonthlyGoalSection = () => {
  const { t } = useTranslation('kpi');

  const {
    useManageKpiModalState: { register, watch, getValues },
  } = useHandleAddKpiRateContext();

  return (
    <>
      <RowStack>
        <Switch
          {...register('hasTarget')}
          defaultChecked={getValues('hasTarget')}
        />
        <Typography variant="bodyRegular">{t('addKpi.monthlyGoal')}</Typography>
      </RowStack>

      <GoalValueTextField />

      {watch('targetType') === KpiRatingTargetType.MonthlyCustom &&
      watch('hasTarget') ? (
        <RowStack gap="16px" flexWrap={'wrap'}>
          {MonthNumberArray.map((month) => (
            <SingleGoal key={month} monthNumber={month} />
          ))}
        </RowStack>
      ) : null}
    </>
  );
};
