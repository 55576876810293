import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Fade, IconButton, useTheme } from '@mui/material';

import { AvatarMenu } from '@/core/components/AvatarMenu/AvatarMenu';
import { CustomTooltip } from '@/core/styles';
import {
  NavbarMainContainer,
  NavbarTitle,
  NavbarUserMenu,
} from '@/core/styles/Navbar';
import { useUserStore } from '@/stores';

export const Navbar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationUrl } = useUserStore();
  return (
    <NavbarMainContainer height="56px">
      <NavbarTitle
        sx={{ cursor: 'pointer' }}
        marginLeft="16px"
        onClick={() => navigate(`/${organizationUrl}`)}
      >
        <img src="/assets/logo-small.svg" alt="Logo" />
        <h2>{t('luqam.title')}</h2>
      </NavbarTitle>
      <NavbarUserMenu>
        <CustomTooltip
          title={t('organization.systemSettings')}
          placement="bottom-start"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <IconButton onClick={() => navigate('system-settings')}>
            <SettingsOutlinedIcon
              sx={{
                color: theme.palette.grey[900],
              }}
            />
          </IconButton>
        </CustomTooltip>
        <AvatarMenu />
      </NavbarUserMenu>
    </NavbarMainContainer>
  );
};
