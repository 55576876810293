import { useState } from 'react';

import { useDebounceValue } from 'usehooks-ts';

export const useRankingFilters = () => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebounceSearch] = useDebounceValue(search, 500);
  const [periodIds, setPeriodsIds] = useState<string[]>([]);

  return {
    setSearch: (value: string) => {
      setSearch(value);
      setDebounceSearch(value);
    },
    debouncedSearch,
    periodIds,
    setPeriodsIds,
    search,
  };
};
