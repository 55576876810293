import { useState } from 'react';

import { useBasicComments } from '@e-flow/pages/newAnalysis/new-analysis-modules';

import { EflowCommentModel } from '@/__generated__/graphql.ts';

import { UseCommentCategoriesType } from './useCommentCategories.type';

export const useCommentCategories = (props: UseCommentCategoriesType) => {
  const [commentsCategory] = useState<EflowCommentModel[]>([
    ...useBasicComments(),
    ...(props?.commentTypes || []),
  ]);

  const [selectedSymbol, setSelectedSymbol] = useState<string>('');

  return {
    selectedSymbol,
    commentsCategory,
    setSelectedSymbol: (selectedSymbol: string) =>
      setSelectedSymbol(selectedSymbol),
  };
};
