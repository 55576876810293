import { TFunction } from 'i18next';
import { z } from 'zod';

export const AddEmployeeModalValidationSchema = (t: TFunction) =>
  z.object({
    firstName: z
      .string()
      .min(1, t('error.requiredField'))
      .max(50, t('error.maxLength', { length: 50 })),
    lastName: z
      .string()
      .min(1, t('error.requiredField'))
      .max(50, t('error.maxLength', { length: 50 })),
    email: z
      .string()
      .min(1, t('error.requiredField'))
      .email(t('error.invalidEmail')),
    phone: z
      .string()
      .min(1, t('error.requiredField'))
      .regex(
        new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/),
        t('systemSettings.phoneNumberInvalid'),
      ),
    position: z.string().min(1, t('error.requiredField')),
    organizationUnitGuid: z.string().min(1, t('error.requiredField')),
  });