import styled from '@emotion/styled';
import { KpiRateType } from '@kpi/components/KpiRatesTable/KpiTable';
import { KpiTableCell } from '@kpi/components/KpiRatesTable/KpiTable/components/KpiTableCell.tsx';

import { Box, Typography } from '@mui/material';

type KpiUserCellProps = {
  assignee: KpiRateType['assigneeCell'];
};

export const KpiUserCell = (props: KpiUserCellProps) => {
  return (
    <KpiTableCell>
      <AvatarStyledCell>
        <Typography variant="bodyRegular" whiteSpace="nowrap">
          {props.assignee.name}
        </Typography>
      </AvatarStyledCell>
    </KpiTableCell>
  );
};

const AvatarStyledCell = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 5,
});
