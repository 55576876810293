import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { IUser } from '@/core/types';
import { useUserStoreTypes } from '@/stores/useUserStore/useUserStore.types.ts';
import { useUserStoreDefaultValues } from '@/stores/useUserStore/useUserStoreDefaultValues.ts';

export const useUserStore = create<useUserStoreTypes>()(
  persist(
    (set) => ({
      setUserGroups: (userGroup: SystemRoles[]) =>
        set(() => ({ userGroups: userGroup })),
      setUser: (user: Omit<IUser, 'userGroups'>) => set(() => ({ ...user })),
      setProfilePicture: (profilePicture) =>
        set(() => ({ profilePicture: profilePicture })),

      setMail: (email: string) => set(() => ({ email: email })),
      resetStorage: () =>
        set(() => ({
          ...useUserStoreDefaultValues,
        })),
      ...useUserStoreDefaultValues,
      setName: (name: string) => set(() => ({ name: name })),
      setLastName: (lastName: string) => set(() => ({ lastName: lastName })),
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
