import { useTranslation } from 'react-i18next';

import { EmployeeTableTypes } from '@e-flow/pages/newAnalysis/new-analysis-modules/WorkGroupModule/types/EmployeeTable.types.ts';

import { Close } from '@mui/icons-material';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { theme } from '@/theme';

export const EmployeeTable = ({
  rows,
  handleDeleteRow,
}: EmployeeTableTypes) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.id || index}>
              <TableCell width={'10%'}>
                <Typography variant="bodyRegular">{index + 1}</Typography>
              </TableCell>
              <TableCell width={'60%'}>
                <Typography variant="bodyRegular">
                  {row.nameAndSurname}
                </Typography>
              </TableCell>
              <TableCell width={'20%'}>
                <Typography variant="bodyRegular">
                  {row.id && t('productName')}
                </Typography>
              </TableCell>
              <TableCell width={'10%'}>
                <Button
                  onClick={() => {
                    handleDeleteRow(row, index);
                  }}
                >
                  <Close
                    sx={{
                      color: theme.palette.common.black,
                    }}
                  />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
