import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { useUserStore } from '@/stores';

import { WelcomeComponentProps } from './WelcomeComponent.types';

export const WelcomeComponent = ({
  displayWelcomeMessage = true,
}: WelcomeComponentProps) => {
  const { t } = useTranslation();
  const { name } = useUserStore();

  if (!displayWelcomeMessage) return <></>;

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Typography variant={'h3'} fontWeight={'500'}>
        {t('common.welcome')}, {name}!
      </Typography>
    </Box>
  );
};
