import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import {
  EmptyKpis,
  NewChartDataForm,
} from '@kpi/modals/HandleKpiChartModal/components';
import { useCreateChartContext } from '@kpi/modals/HandleKpiChartModal/context';
import { RowStack } from '@organizations/styles';

import { Divider, Typography, styled } from '@mui/material';

import {
  CharsCountingInput,
  SelectChartType,
  TextButton,
} from '@core/components';

import { theme } from '@/theme';

export const CreateChartView = () => {
  const { t: commonT } = useTranslation();
  const { t } = useTranslation('kpi');

  const {
    register,
    onSubmit,
    handleSubmit,
    areKpisSelected,
    selectedKpis,
    closeModal,
    chartType: selectedChart,
    handleSelectChart,
    errors,
    areSelectedKpisValid,
  } = useCreateChartContext();

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <MainContainer onSubmit={handleSubmit(onSubmit)}>
      <SelectChartType
        activeChart={selectedChart}
        onClick={handleSelectChart}
      />
      <CharsCountingInput
        {...register('name')}
        label={t('charts.appearingChartName')}
        maxInputLength={255}
        error={errors.name !== undefined}
        helperText={errors.name?.message}
      />

      <Divider sx={{ padding: 0 }} />

      {selectedKpis.length > 0 ? <NewChartDataForm /> : <EmptyKpis />}

      <RowStack justifyContent={'end'}>
        <TextButton
          text={commonT('common.cancel')}
          width="66px"
          onClick={closeModal}
        />
        <PrimaryButton
          disabled={!areKpisSelected || !areSelectedKpisValid}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary[600],
          }}
          type="submit"
        >
          <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
            {commonT('common.add')}
          </Typography>
        </PrimaryButton>
      </RowStack>
    </MainContainer>
  );
};

const MainContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '24px',
  overflow: 'auto',
  height: '100%',
});
