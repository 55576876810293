import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetKpis } from '@kpi/modals/HandleKpiChartModal/hooks';

import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';

import { TablePaginationWrapper } from '@core/components';
import { FlexCenter } from '@core/styles';

import { KpiRateDto } from '@/__generated__/graphql';

import { useCreateChartContext } from '../../context';
import { Filters } from './Filters';
import { KpiRateRow } from './KpiRateRow';

export const KpisRateTable = () => {
  const { t } = useTranslation('kpi');
  const { t: commonT } = useTranslation();

  const {
    kpiRates,
    rowsPerPage,
    total,
    setRowsPerPage,
    setCursor,
    cursor,
    search,
    isLoadingKpis,
    organizationUnitId,
    setSearchInput,
    setDebounceSearch,
    setOrganizationUnitId,
    setAssignedPersonId,
    setSelectedUnit,
    defaultOrganizationUnitData,
  } = useGetKpis();

  const { handleSelectAllKpis, tempSelectedKpisIds } = useCreateChartContext();

  const checkedCurrentRowsCount = useMemo(
    () => kpiRates.filter((kpi) => tempSelectedKpisIds.includes(kpi.id)).length,
    [kpiRates, tempSelectedKpisIds],
  );

  return (
    <>
      <Filters
        organizationUnitIds={organizationUnitId}
        search={search}
        setAssignedPersonId={setAssignedPersonId}
        setDebounceSearch={setDebounceSearch}
        organizationUnitId={organizationUnitId}
        setOrganizationUnitId={setOrganizationUnitId}
        setSearchInput={setSearchInput}
        setSelectedUnit={setSelectedUnit}
        defaultOrganizationUnitData={defaultOrganizationUnitData}
      />

      <TablePaginationWrapper
        total={total}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(value) => setRowsPerPage(value)}
        cursor={cursor}
        setCursor={(value) => {
          setCursor(value);
        }}
      >
        <TableContainer sx={{ maxWidth: '100%' }}>
          {!isLoadingKpis ? (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <TableHead>
                <TableCell>
                  <Checkbox
                    indeterminate={
                      checkedCurrentRowsCount > 0 &&
                      checkedCurrentRowsCount < rowsPerPage
                    }
                    checked={
                      (checkedCurrentRowsCount === rowsPerPage ||
                        checkedCurrentRowsCount === total) &&
                      total > 0
                    }
                    onChange={() =>
                      handleSelectAllKpis(kpiRates as KpiRateDto[])
                    }
                  />
                </TableCell>
                <TableCell>{t('filters.name')}</TableCell>
                <TableCell>
                  {t('charts.legend.ORGANIZATION_UNIT_LEGEND')}
                </TableCell>
                <TableCell>{t('filters.assignedPerson')}</TableCell>
                <TableCell>{commonT('common.unit')}</TableCell>
                <TableCell>{commonT('common.target')}</TableCell>
              </TableHead>
              <TableBody>
                {kpiRates.map((row) => (
                  <KpiRateRow key={row.id} {...row} />
                ))}
              </TableBody>
            </Table>
          ) : (
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          )}
        </TableContainer>
      </TablePaginationWrapper>
    </>
  );
};
