/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import type { Theme } from '@mui/material';
import { TFunction } from 'i18next';
import { EmployeeState } from '@/__generated__/graphql.ts';

export const getStatusData = (
  theme: Theme,
  status: string,
  t: TFunction<'translation', undefined>,
) => {
  switch (status) {
    case EmployeeState.Active:
      return { color: theme.palette.success[500], name: t('employees.active') };
    case EmployeeState.Inactive:
      return { color: theme.palette.error[500], name: t('employees.inActive') };
    case EmployeeState.Fired:
      return { color: theme.palette.grey[500], name: t('employees.fired') };
    default:
      return null;
  }
};
