import GraphInfoIconWrapper from '@kpi/common/GraphIconComponent/GraphInfoIconWrapper.tsx';

import { GetRelatedKpiChartsQuery } from '@/__generated__/graphql.ts';

const extractRelatedChartNames = (
  relatedChartNames: GetRelatedKpiChartsQuery['getRelatedKpiCharts']['relatedResources'],
): string[] => {
  return relatedChartNames?.length > 0
    ? relatedChartNames.map((chart) => chart.resourceName)
    : [];
};

export const GraphIcon = ({
  relatedChartNames,
}: {
  relatedChartNames: GetRelatedKpiChartsQuery['getRelatedKpiCharts']['relatedResources'];
}) => {
  const chartNames = extractRelatedChartNames(relatedChartNames);

  return <GraphInfoIconWrapper graphs={chartNames} />;
};
