import { useState } from 'react';

export const useProgress = () => {
  const [progress, setProgress] = useState(0);
  const [isProgressSnackbarOpen, setIsProgressSnackbarOpen] = useState(false);

  return {
    progress,
    setProgress: (value: number) => setProgress(value),
    isProgressSnackbarOpen,
    setIsProgressSnackbarOpen: (value: boolean) =>
      setIsProgressSnackbarOpen(value),
  };
};
