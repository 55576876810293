import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

import { useCompanyId, useModal } from '@core/hooks';

import { KpiRateDto, useGetKpiRateLazyQuery } from '@/__generated__/graphql.ts';

export const useReportSingleKpiModal = () => {
  const [kpiId, setKpiId] = useState<string>();
  const [kpi, setKpi] = useState<KpiRateDto | undefined>();
  const [isError, setIsError] = useState(false);
  const [reportDefaultDate, setReportDefaultDate] = useState<string>();

  const { companyId } = useCompanyId();

  const {
    isModalVisible: isReportModalVisible,
    setIsModalVisible: setIsReportModalVisible,
  } = useModal();

  const [getKpi, { loading: isLoading }] = useGetKpiRateLazyQuery({});

  useEffect(() => {
    if (!kpiId || !companyId || kpi) {
      return;
    }

    void (async () => {
      const { data, error } = await getKpi({
        variables: {
          input: {
            kpiId: kpiId,
            organizationId: companyId,
          },
        },
      });

      if (error) {
        captureException(error);
        setIsError(true);
      }

      if (data) setKpi(data?.getKpiRate as KpiRateDto);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiId, companyId, kpi]);

  const openReportModal = (id: string, kpi?: KpiRateDto) => {
    setKpiId(id);
    if (kpi) {
      setKpi(kpi);
    }
    setIsReportModalVisible(true);
  };

  const openReportModalWithDefaultDate = (id: string, defaultDate?: string) => {
    setKpiId(id);
    if (defaultDate) {
      setReportDefaultDate(defaultDate);
    }
    setIsReportModalVisible(true);
  };

  const closeReportModal = () => {
    setIsReportModalVisible(false);
    setKpiId(undefined);
    setKpi(undefined);
    setIsError(false);
  };

  return {
    reportDefaultDate,
    openReportModalWithDefaultDate,
    kpi,
    kpiId,
    isError,
    isLoading,
    isReportModalVisible,
    openReportModal,
    closeReportModal,
  };
};
