import { useMemo } from 'react';

import { SystemRoles } from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

export const useEflowPermission = () => {
  const { userGroups } = useUserStore();

  const canCreateNewEflow = useMemo(
    () =>
      [
        SystemRoles.Administrator,
        SystemRoles.EflowCreator,
        SystemRoles.KeyUser,
        SystemRoles.Root,
        SystemRoles.SystemAdmin,
      ].some((role) => userGroups.includes(role)),
    [userGroups],
  );

  return { canCreateNewEflow };
};
