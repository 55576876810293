import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/styles';

import { FileTableRowMenu } from '@core/components/Files/FileTable/FileTableRowMenu/FileTableRowMenu.tsx';
import { FilesTableRowType } from '@core/components/Files/FileTable/FleTableRow/FileTableRow.types.ts';
import { useFilePreview } from '@core/hooks';

export const FileTableRow = ({ file, onFileRemove }: FilesTableRowType) => {
  const { fileName, downloadFile } = useFilePreview(file);

  return (
    <TableRow>
      <TableCell>
        <NameCell>
          <InsertDriveFileIcon />
          {fileName}
        </NameCell>
      </TableCell>
      <TableRightCell>
        <FileTableRowMenu
          file={file}
          downloadEnabled={true}
          onDelete={() => onFileRemove && onFileRemove(file)}
          deleteEnabled={!!onFileRemove}
          onDownload={downloadFile}
        />
      </TableRightCell>
    </TableRow>
  );
};

const TableRightCell = styled(TableCell)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
  paddingLeft: '5px',
});

const NameCell = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 5,
});
