import styled from '@emotion/styled';

import { Box, Typography } from '@mui/material';

import { Employee } from '@/__generated__/graphql';
import { theme } from '@/theme';

import { EmployeeAvatar } from '../../EmployeeAvatar';

export const EmployeeMenuRow = (props: {
  option: Employee;
  disabled?: boolean;
}) => {
  const { option } = props;

  return props.disabled ? (
    <DisabledMenuItemContainer>
      <EmployeeAvatar
        name={`${option.firstName} ${option.lastName}`}
        imageUrl={option.profilePicture}
      />
      <NameContainer>
        <Typography variant="bodyRegular">
          {option.firstName} {option.lastName}
        </Typography>
        <EmailTypography variant="bodyRegular">{option.email}</EmailTypography>
      </NameContainer>
    </DisabledMenuItemContainer>
  ) : (
    <StyledMenuItemContainer>
      <EmployeeAvatar
        name={`${option.firstName} ${option.lastName}`}
        imageUrl={option.profilePicture}
      />
      <NameContainer>
        <Typography variant="bodyRegular">
          {option.firstName} {option.lastName}
        </Typography>
        <EmailTypography variant="bodyRegular">{option.email}</EmailTypography>
      </NameContainer>
    </StyledMenuItemContainer>
  );
};

const StyledMenuItemContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
});

const DisabledMenuItemContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
  opacity: 0.5,
  cursor: 'default',
});

const NameContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
});

const EmailTypography = styled(Typography)({
  color: theme.palette.grey[500],
  fontSize: '0.75rem',
});
