import { z } from 'zod';

/**
 * Schema for creating or editing a workstation
 */
export const WorkstationNameSchema = (
  requiredField: string,
  maxLength: string,
) =>
  z.object({
    workstationName: z.string().min(1, requiredField).max(30, maxLength),
  });
