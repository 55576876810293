import { useTranslation } from 'react-i18next';

import { ReportModalProvider } from '@kpi/contexts';

import { Button, CustomModal, ModalContainer } from '@core/components';
import { useModal } from '@core/hooks';

import { ReportModalContext } from './ReportModalContext';

export const ReportModal = () => {
  const { t } = useTranslation('kpi');

  const { isModalVisible, setIsModalVisible } = useModal();

  return (
    <>
      <Button textVariant="bodyRegular" onClick={() => setIsModalVisible(true)}>
        {t('navigation.raport')}
      </Button>

      <CustomModal display={isModalVisible}>
        <ModalContainer
          headerText={t('report.title')}
          closeModal={() => setIsModalVisible(false)}
        >
          <ReportModalProvider close={() => setIsModalVisible(false)}>
            <ReportModalContext />
          </ReportModalProvider>
        </ModalContainer>
      </CustomModal>
    </>
  );
};
