import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import { Menu } from '@mui/material';

import { CustomMenuItem } from '@/core/components';

import { useKpiSettlementPeriodContext } from '../../contexts';
import { SettlementPeriodsMenuProps } from '../SettlementPeriodRow/SettlementPeriods.types';

const SettlementPeriodMenu: FC<SettlementPeriodsMenuProps> = ({
  anchorEl,
  isOpen,
  handleClose,
  settlementPeriod,
}) => {
  const { t } = useTranslation();

  const { openUpdateSettlementPeriodModal, openActivateSettlementPeriodModal } =
    useKpiSettlementPeriodContext();

  const renderMenuItems = () => {
    const menuItems: ReactNode[] = [];

    menuItems.push(
      <CustomMenuItem
        text={t('common.edit')}
        onClick={() => {
          openUpdateSettlementPeriodModal(settlementPeriod);
        }}
        icon={<EditOutlinedIcon />}
      />,
    );

    if (!settlementPeriod.isActive) {
      menuItems.push(
        <CustomMenuItem
          text={t('common.activate')}
          onClick={() => {
            openActivateSettlementPeriodModal(settlementPeriod);
          }}
          icon={<ToggleOffOutlinedIcon />}
        />,
      );
    }

    return menuItems;
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      onClick={handleClose}
    >
      {renderMenuItems()}
    </Menu>
  );
};

export default SettlementPeriodMenu;
