import { Skeleton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

import { SkeletonButtonType } from './SceletonButton.types.ts';

export const SkeletonButtonWithTooltip = ({
  title,
  tooltip,
}: SkeletonButtonType) => {
  return (
    <Tooltip title={tooltip || title}>
      <FakeButton variant="rectangular">{title}</FakeButton>
    </Tooltip>
  );
};

const FakeButton = styled(Skeleton)(({ theme }) => ({
  width: 150,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    cursor: 'wait',
  },
}));
