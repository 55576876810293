import { omit } from 'lodash';

import { deepClone } from '@mui/x-data-grid/internals';

import {
  GetCommissionMembersDocument,
  GetCommissionMembersQuery,
} from '@/__generated__/graphql';
import { client } from '@/apollo';
import { CommissionRole } from '@/core/enums';

import { CommissionMember } from './types';

/**
 * this class has methods to handle cache in commission member. It has add, remove and update role methods which helps to update cache after server action
 */
export class CommissionMemberCacheHandler {
  private organizationId: string;

  constructor(organizationId: string) {
    this.organizationId = organizationId;
  }

  add = (newUser: CommissionMember) => {
    const data = this._getCommissionMembersFromCache();

    const updatedItems = [
      ...data.getCommissionMembers.items,
      {
        ...omit(newUser, ['isCommissionLeader', '__typename']),
        commissionRole: newUser.isCommissionLeader
          ? CommissionRole.COMMISSION_LEADER
          : CommissionRole.COMMISSION_MEMBER,
        __typename: 'CommissionMemberDto',
      },
    ];

    client.writeQuery({
      query: GetCommissionMembersDocument,
      variables: {
        input: {
          organizationId: this.organizationId,
        },
      },
      data: {
        getCommissionMembers: {
          items: updatedItems,
          __typename: data.getCommissionMembers.__typename,
          total: updatedItems.length,
        },
      },
    });
  };

  remove = (userId: string) => {
    const data = this._getCommissionMembersFromCache();

    const updatedItems = data.getCommissionMembers.items.filter(
      (member) => member.id !== userId,
    );

    client.writeQuery({
      query: GetCommissionMembersDocument,
      variables: {
        input: {
          organizationId: this.organizationId,
        },
      },
      data: {
        getCommissionMembers: {
          items: [...updatedItems],
          __typename: data.getCommissionMembers.__typename,
          total: updatedItems.length,
        },
      },
    });
  };

  updateRole = (userId: string) => {
    const data = this._getCommissionMembersFromCache();

    const updatedItems = data.getCommissionMembers.items.map((member) => {
      if (member.id === userId) {
        return { ...member, commissionRole: CommissionRole.COMMISSION_LEADER };
      }
      if (
        (member.commissionRole as CommissionRole) ===
        CommissionRole.COMMISSION_LEADER
      ) {
        return { ...member, commissionRole: CommissionRole.COMMISSION_MEMBER };
      }
      return member;
    });

    client.writeQuery({
      query: GetCommissionMembersDocument,
      variables: {
        input: {
          organizationId: this.organizationId,
        },
      },
      data: {
        getCommissionMembers: {
          items: [...updatedItems],
          __typename: data.getCommissionMembers.__typename,
          total: updatedItems.length,
        },
      },
    });
  };

  private _getCommissionMembersFromCache(): GetCommissionMembersQuery {
    const result = client.readQuery({
      query: GetCommissionMembersDocument,
      variables: { input: { organizationId: this.organizationId } },
    });

    if (!result) {
      return {
        getCommissionMembers: {
          items: [],
          total: 0,
          __typename: 'CommissionMembers',
        },
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return
    return deepClone(result);
  }
}
