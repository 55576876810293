import { useTranslation } from 'react-i18next';

import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

/**
 * This just renders a header for table inside SystemUsers, for code readability
 * @constructor
 */
export const SystemUsersTableHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell height={'40px'}>
          <CustomTopography fontWeight={500}>
            {t('systemSettings.user')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography fontWeight={500}>
            {t('systemSettings.email')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography fontWeight={500}>
            {t('systemSettings.permissionTypes')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography fontWeight={500}>
            {t('employees.status')}
          </CustomTopography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
