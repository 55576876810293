import { shouldRenderLegend } from '@kpi/utils/should-render-legend.ts';

import { Stack } from '@mui/material';

import { ColorBox } from '@core/components';
import { ChartInput } from '@core/components/Statistica/types';
import { useGetMonthName } from '@core/hooks';
import { getNameFromDataSet } from '@core/hooks/useRecharts/functions';
import { ExtractChartKeyType } from '@core/hooks/useRecharts/types';
import { FormatPieChartDataType } from '@core/hooks/useRecharts/types/FormatPieChartData.type.ts';

type PieChartLegendProps = {
  pieChartData: FormatPieChartDataType[];
  props: ChartInput;
};

export const PieChartLegend: React.FC<PieChartLegendProps> = ({
  pieChartData,
  props,
}) => {
  const { getMonthName } = useGetMonthName();

  if (
    shouldRenderLegend(
      props.displayOptions.legendType,
      props.displayOptions.showDetailedLegend,
    )
  ) {
    return null;
  }

  return (
    <Stack sx={{ flexDirection: 'column', gap: 1 }}>
      {props.displayOptions.showDataInAscendingOrder &&
        props.dataSourceSet.length === 1 && (
          <>{getNameFromDataSet(props.dataSet as ExtractChartKeyType)}</>
        )}
      <Stack sx={{ flexDirection: 'row', gap: 1, flexWrap: 'wrap', margin: 3 }}>
        {pieChartData.map((element, index) => (
          <Stack
            sx={{ flexDirection: 'row', gap: 1 }}
            key={element.name + index}
          >
            <ColorBox color={element.fill} variant="square" />
            {getMonthName(element.name)}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default PieChartLegend;
