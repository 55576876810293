/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * @bryndalski
 * TODO - refactor this file add to feature sprints
 */
import { Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid';

import { DeleteRow } from '@/core/components';

import { ColumnProps } from './types';

//render and export columns to dataGrid in GlobalPermission.tsx using provided theme and texts
export const columns = ({ t, showModal, isUserPermited }: ColumnProps) => {
  const fields = ['permissionType', 'employee', 'email'];
  const texts = [
    t('organizationSettings.permissionType'),
    t(`employee.employee`),
    t('employees.email'),
  ];
  const columns: GridColDef[] = fields.map((field, id) => {
    return {
      field: field,
      flex: 1,
      renderHeader: () => (
        <Typography variant="bodyMedium" fontSize={'14px'}>
          {texts[id]}
        </Typography>
      ),
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
      ) => <Typography variant="bodyRegular">{params.value}</Typography>,
    };
  });
  columns.push({
    field: 'more',
    headerName: '',
    width: 56,
    renderCell: () => {
      return <>{isUserPermited ? <DeleteRow showModal={showModal} /> : null}</>;
    },
  });

  return columns;
};
