import { useState } from 'react';

export const useAddKpiModal = () => {
  const [selectedChart, setSelectedChart] = useState<string>();

  return {
    isListKpiChartView: !selectedChart,
    isKpiChartPreview: !!selectedChart,
    selectedChart,
    setSelectedChart,
  };
};
