import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography, styled } from '@mui/material';

import { Button, SearchTextField, Snackbar } from '@/core/components';
import { useModal, useSnackbar } from '@/core/hooks';
import { AddMemberToCommissionModal } from '@/pages/kaizen/module-settings/Commission/modals';

import { KaizenCommissionTable } from '../KaizenCommissionTable';
import { useCommission } from '../hooks/useCommission/useCommission';

export const KaizenCommission = () => {
  const { t } = useTranslation('kaizen');

  const { members, search, setSearch } = useCommission();

  const { isModalVisible, setIsModalVisible } = useModal();

  const { openSnackbar, snackBarMessage, isSnackBarOpen, closeSnackbar } =
    useSnackbar();

  return (
    <Container>
      <HorizontalStack>
        <SearchTextField
          value={search}
          placeholder={t('commission.searchEmployee')}
          onChange={(newValue) => setSearch(newValue.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalVisible(true)}
        >
          <Typography>{t('commission.addMember')}</Typography>
        </Button>
      </HorizontalStack>

      <KaizenCommissionTable
        data={members}
        onRemove={() => openSnackbar(t('commission.employeeRemoved'))}
        onUpdate={() => openSnackbar(t('commission.leaderSelected'))}
      />

      <AddMemberToCommissionModal
        open={isModalVisible}
        members={members}
        openSnackBar={() => openSnackbar(t('commission.employeeAdded'))}
        closeModal={() => setIsModalVisible(false)}
      />
      <Snackbar
        onClose={() => closeSnackbar()}
        open={isSnackBarOpen}
        message={snackBarMessage}
      />
    </Container>
  );
};

const Container = styled(Box)({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const HorizontalStack = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
