import { useParams } from 'react-router-dom';

import { Avatar, AvatarGroup, Stack, Typography, styled } from '@mui/material';

import { useMenu } from '@core/hooks';
import { getUserInitials } from '@core/utils';

import { useOrganizationStore } from '@/stores';

import { KeyUsersPopover } from './KeyUsersPopover';
import { CompanyComponentProps } from './types';

export const CompanyComponent = ({
  displayCompanyName = true,
  displayKeyUsers = true,
}: CompanyComponentProps) => {
  const { companyName } = useParams();
  const { keyUsers, organisationName, logo } = useOrganizationStore();
  const { anchorEl, handleMenuOpen, isMenuOpen, handleMenuClose } = useMenu();

  if (!displayCompanyName && !displayKeyUsers) return <></>;

  if (!displayCompanyName)
    return (
      <>
        <Stack direction="row" gap="8px" alignItems="center">
          <CustomAvatarGroup max={3} onClick={(e) => handleMenuOpen(e)}>
            {keyUsers?.map((user) => (
              <Avatar
                key={user.id}
                alt={user.firstName}
                src={user.profilePicture ?? undefined}
                sx={{ width: 32, height: 32 }}
              >
                {getUserInitials(user)}
              </Avatar>
            ))}
          </CustomAvatarGroup>
        </Stack>
        <KeyUsersPopover
          anchorEl={anchorEl}
          isMenuOpen={isMenuOpen}
          handleMenuClose={handleMenuClose}
          keyUsers={keyUsers}
        />
      </>
    );

  if (!displayKeyUsers)
    return (
      <>
        <Stack direction="row" gap="8px" alignItems="center">
          <Avatar
            src={logo ?? undefined}
            alt="company logo"
            variant={logo ? 'rounded' : 'circular'}
          >
            {organisationName
              ? organisationName.charAt(0)
              : companyName!.charAt(0)}
          </Avatar>
          <Typography variant="h5">{organisationName}</Typography>
        </Stack>
      </>
    );

  return (
    <>
      <Stack direction="row" gap="8px" alignItems="center">
        <Avatar
          src={logo ?? undefined}
          alt="company logo"
          variant={logo ? 'rounded' : 'circular'}
        >
          {organisationName
            ? organisationName.charAt(0)
            : companyName!.charAt(0)}
        </Avatar>
        <Typography variant="h5">{organisationName}</Typography>
        <CustomAvatarGroup max={3} onClick={(e) => handleMenuOpen(e)}>
          {keyUsers?.map((user) => (
            <Avatar
              key={user.id}
              alt={user.firstName}
              src={user.profilePicture ?? undefined}
              sx={{ width: 32, height: 32 }}
            >
              {getUserInitials(user)}
            </Avatar>
          ))}
        </CustomAvatarGroup>
      </Stack>
      <KeyUsersPopover
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
        keyUsers={keyUsers}
      />
    </>
  );
};

const CustomAvatarGroup = styled(AvatarGroup)({
  cursor: 'pointer',
});
