import { Theme } from '@emotion/react';
import { useCallback } from 'react';

import { SxProps } from '@mui/material';

import { CustomDataGridTypes } from '@core/components/CustomDataGrid/types';

export const useCustomDataGrid = <T,>(props: CustomDataGridTypes<T>) => {
  const addStickyFirstRow = useCallback((): SxProps<Theme> => {
    return props.firstRowSticky
      ? {
          //eslint-disable-next-line
          '& tbody tr > *:first-child': {
            position: 'sticky',
            left: 0,
            zIndex: 0,
          },
        }
      : {};
  }, [props.firstRowSticky]);

  const addStickyLastRow = useCallback((): SxProps<Theme> => {
    return props.lastRowSticky
      ? {
          //eslint-disable-next-line
          '& tbody tr > *:last-child': {
            position: 'sticky',
            right: 0,
            zIndex: 0,
            width: '100px',
          },
        }
      : {};
  }, [props.lastRowSticky]);

  return {
    addStickyFirstRow: addStickyFirstRow(),
    addStickyLastRow: addStickyLastRow(),
  };
};
