import { useTranslation } from 'react-i18next';

import { TableCell, Typography, styled } from '@mui/material';

export const ModalEmployeeTableHeader = () => {
  const { t: commonT } = useTranslation();

  return (
    <>
      <TableCell height={'40px'}>
        <CustomTopography fontWeight={'500'}>
          {commonT('employee.employee')}
        </CustomTopography>
      </TableCell>
      <TableCell>
        <CustomTopography fontWeight={'500'}>
          {commonT('employees.email')}
        </CustomTopography>
      </TableCell>
      <TableCell>
        <CustomTopography fontWeight={'500'}>
          {commonT('employees.searchCell')}
        </CustomTopography>
      </TableCell>
    </>
  );
};

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
