import { MenuWithSearchTypes } from '@core/components/Menus/MenuWithSearch/MenuWithSearch.types.ts';

export const filterOptions = <T>(
  props: Pick<MenuWithSearchTypes<T>, 'searchKey' | 'options'>,
  searchValue: string,
) => {
  if (!searchValue) {
    return props.options;
  }

  if (!props.searchKey)
    switch (typeof props.options[0]) {
      case 'string':
        return props.options.filter((option) =>
          (option as string).toLowerCase().includes(searchValue.toLowerCase()),
        );
      case 'number':
        return props.options.filter((option) =>
          (option as number)
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()),
        );
      default:
        return [];
    }

  return props.options.filter((option) =>
    (option[props.searchKey!] as unknown as string)
      ?.toLowerCase()
      .includes(searchValue.toLowerCase()),
  );
};
