import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileModel } from '@/__generated__/graphql';
import { maxFileSizeInBytes, maxFileSizeInMegaBytes } from '@/core/constants';

import { AttachmentModuleProps } from '../../components/AttachmentsModule/types';

export const useAttachments = (props: AttachmentModuleProps) => {
  const { t } = useTranslation('eflow');

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [files, setFiles] = useState<(File | FileModel)[]>([]);

  useEffect(() => {
    if (props.alreadyUploadedFiles && props.alreadyUploadedFiles.length > 0) {
      setFiles(props.alreadyUploadedFiles || []);
      setFilesToUpload([]);
    }
  }, [props.alreadyUploadedFiles]);

  useEffect(() => {
    setFiles(props.alreadyUploadedFiles || []);
    setFilesToUpload([]);

    if (props.offlineFiles && props.offlineFiles.length > 0) {
      const transformedFiles = props.offlineFiles.map((file) => {
        return new File([file.body], file.name, { type: file.type });
      });

      setFiles((prevState) => [...transformedFiles, ...prevState]);
      setFilesToUpload((value) => [...transformedFiles, ...value]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentEflowStepId]);

  /**
   * Function to check if the sum of the files to be uploaded is bigger than the maxFileSizeInBytes
   * @param newFiles - Files to be uploaded
   * @returns true if the sum of the files is bigger than the maxFileSizeInBytes
   */
  const checkFilesSize = (newFiles: File[]) => {
    return (
      [...filesToUpload, ...newFiles]
        .map((file) => file.size)
        .reduce((accumulator, val) => accumulator + val, 0) > maxFileSizeInBytes
    );
  };

  /**
   * Funtion to check if there are duplicated files
   * @param newFiles - Files to be uploaded
   * @returns true if there are duplicated files
   */
  const checkForDuplicates = (newFiles: File[]) => {
    return files.find((file) =>
      newFiles.find(
        (newFile) =>
          newFile.name === (file as File).name ||
          newFile.name === (file as FileModel).fileName,
      ),
    );
  };

  const renameDuplicateFiles = (newFile: File) => {
    if (
      files.find(
        (file) =>
          (file as File).name === newFile.name ||
          (file as FileModel).fileName === newFile.name,
      )
    ) {
      const fileName = newFile.name.split('.');
      const newFileName = `${fileName[0]}_${new Date().getTime()}`;
      const newFileExtension = fileName[fileName.length - 1];
      const finalFileName = [newFileName, newFileExtension].join('.');

      return new File([newFile], finalFileName, { type: newFile.type });
    }

    return newFile;
  };

  const handleAddFiles = (newFiles: File[]) => {
    const copiedFiles = newFiles.map((file) => renameDuplicateFiles(file));

    if (checkForDuplicates(copiedFiles))
      return t('flowSteps.attachmentModule.duplicatedFiles');

    if (checkFilesSize(copiedFiles))
      return t('flowSteps.attachmentModule.maxSize', {
        maxSize: maxFileSizeInMegaBytes,
      });

    setFiles([...files, ...copiedFiles]);

    const newFilesToUpload = [...filesToUpload, ...copiedFiles].filter(
      (file) => file instanceof File,
    );

    setFilesToUpload(newFilesToUpload);

    if (props.onFlowStepChange) props.onFlowStepChange(newFilesToUpload);

    return '';
  };

  const handleDelete = (fileName: string, isAlreadyUploaded: boolean) => {
    if (isAlreadyUploaded && props.removeAlreadyUploadedFiles) {
      props.removeAlreadyUploadedFiles(fileName);
    }

    const newFilesAfterDelete = files.filter((file) => {
      if (file instanceof File) {
        return file.name !== fileName;
      } else {
        return file.fileName !== fileName;
      }
    });

    const filesToUploadViaNetworkBinary = filesToUpload.filter((file) => {
      if (file instanceof File) {
        return file.name !== fileName;
      }
    });

    if (props.onFlowStepChange)
      props.onFlowStepChange(filesToUploadViaNetworkBinary);

    setFiles(newFilesAfterDelete);

    setFilesToUpload(filesToUploadViaNetworkBinary);
  };

  return {
    filesToUpload,
    files,
    handleDelete,
    handleAddFiles,
  };
};
