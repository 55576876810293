import { Stack } from '@mui/material';

import { ColorBox } from '@core/components';
import { ChartLegendEntryTypes } from '@core/components/Statistica/types';

export const RechartLegendElement = (props: ChartLegendEntryTypes) => {
  if (props.isTarget) {
    return null;
  }

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <ColorBox color={props.fill} variant="square" />
      {props.name}
    </Stack>
  );
};
