import { useTranslation } from 'react-i18next';

import { SystemParameterKeysEnum } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum.ts';

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { ParameterObject, ParameterTypes } from '@/__generated__/graphql.ts';

import { ParamTableProps } from './ParameterTable.types';

export const ParameterTable = ({
  paramArray,
  selectedParams,
  setSelectedParams,
}: ParamTableProps) => {
  const { t } = useTranslation('eflow');

  const HandleCheckboxClick = (name: string) => {
    const param = paramArray.find((param) => param.name == name)!;
    if (selectedParams.includes(param)) {
      setSelectedParams(
        [...selectedParams].filter((param) => param.name != name),
      );
    } else {
      setSelectedParams([...selectedParams, param]);
    }
  };

  const isCheckboxChecked = (name: string) =>
    !!selectedParams.find((param) => param.name == name);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '48px' }}>
              <Checkbox
                checked={selectedParams.length > 0}
                onChange={() =>
                  setSelectedParams(selectedParams.length > 0 ? [] : paramArray)
                }
                checkedIcon={<IndeterminateCheckBoxIcon />}
              />
            </TableCell>
            <TableCell>
              <Typography variant="bodyMedium" sx={{ fontSize: '14px' }}>
                {t('newAnalysis.parameters.name')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyMedium" sx={{ fontSize: '14px' }}>
                {t('newAnalysis.parameters.unit')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {paramArray.length !== 0 && (
          <TableBody>
            {paramArray.map((param: ParameterObject) => (
              <TableRow key={param.name}>
                <TableCell
                  sx={{
                    width: '48px',
                    height: '40px',
                  }}
                >
                  <Checkbox
                    checked={isCheckboxChecked(param.name)}
                    onChange={() => {
                      HandleCheckboxClick(param.name);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="bodyRegular">
                    {param.type === ParameterTypes.Local
                      ? param.name
                      : t(
                          `newAnalysis.parameters.systemParams.${param.name as SystemParameterKeysEnum}`,
                        )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyRegular">
                    {t(`newAnalysis.parameters.params.${param.unit}`)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
