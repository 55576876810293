/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlowStepsDashboardProps } from '@e-flow/pages/flowDashboard/Dashboards/types.ts';
import { EflowTable, ToBeHeader } from '@e-flow/pages/flowDashboard/components';

import { Box, styled } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { FullScreenLoading, RemoveItemModal } from '@core/components';
import { useCompanyId } from '@core/hooks';

import {
  AsIsToBeNamesEnum,
  EflowStep,
  useFindEflowToBeStepsLazyQuery,
} from '@/__generated__/graphql.ts';

import { useRemoveEflowSteps } from '../../../flowSteps/hooks/useEflowSteps/useRemoveEflowStep/useRemoveEflowStep';
import { CloneToBeSteps } from '../../components/CloneToBeSteps/CloneToBeSteps';

export const ToBeFlowDashboard = (props: FlowStepsDashboardProps) => {
  const { t } = useTranslation('eflow');

  const { companyId } = useCompanyId();

  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);

  const { removeOnlineSteps } = useRemoveEflowSteps({
    eFlowId: props.eFlowId,
    organizationId: companyId,
    analyzeType: AsIsToBeNamesEnum.ToBe,
  });

  const [findToBeSteps, { data, loading: isLoading }] =
    useFindEflowToBeStepsLazyQuery({
      nextFetchPolicy: 'cache-only',
      initialFetchPolicy: 'cache-first',
    });

  const [cannotBeDisplayed, setCannotBeDisplayed] = useState<string[]>([]);

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  useEffect(() => {
    if (props.eFlowId && companyId) {
      void findToBeSteps({
        variables: {
          input: {
            id: props.eFlowId,
            organizationId: companyId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, props.eFlowId]);

  useEffect(() => {
    if (data)
      props.setStepsCount?.(
        (data?.findOneEFlow?.toBeSteps?.length as number) || 0,
      );
  }, [data, props, props.setStepsCount]);

  if (isLoading) return <FullScreenLoading />;

  if (data?.findOneEFlow?.toBeSteps?.length === 0) {
    return (
      <Content>
        <ToBeHeader
          eFlowId={props.eFlowId}
          title={t('eflow.toBe.title')}
          currentActionButtons={props.actionButton}
          parameters={props.parameters}
          removeOperations={{
            isVisible: selectedRows.length > 0,
            onClick: () => {
              setIsRemoveModalVisible(true);
            },
          }}
          handleChangeColumnsToDisplay={(columns) =>
            setCannotBeDisplayed(columns)
          }
        />
        <CloneToBeSteps
          eFlowId={props.eFlowId}
          setCopied={props.setCopied}
          numberOfAsIsSteps={props.asIsStepsCount}
        />
      </Content>
    );
  }

  return (
    <>
      <Content>
        <ToBeHeader
          eFlowId={props.eFlowId}
          title={t('eflow.toBe.title')}
          currentActionButtons={props.actionButton}
          parameters={props.parameters}
          removeOperations={{
            isVisible: selectedRows.length > 0,
            onClick: () => {
              setIsRemoveModalVisible(true);
            },
          }}
          handleChangeColumnsToDisplay={(columns) =>
            setCannotBeDisplayed(columns)
          }
        />
        <EflowTable
          tableType={AsIsToBeNamesEnum.ToBe}
          cannotBeDisplayed={cannotBeDisplayed}
          stepsToDisplay={(data?.findOneEFlow.toBeSteps as EflowStep[]) || []}
          parameters={props.parameters}
          onRowSelectionModelChange={setSelectedRows}
          eFlowId={data?.findOneEFlow.id}
        />
      </Content>
      <RemoveItemModal
        onRemove={async () => {
          await removeOnlineSteps(
            selectedRows as string[],
            AsIsToBeNamesEnum.ToBe,
          );
          setIsRemoveModalVisible(false);
        }}
        display={isRemoveModalVisible}
        closeModal={() => setIsRemoveModalVisible(false)}
        content={t('eflow.removeOperationsConfirmation')}
        headerText={t('eflow.removeOperations')}
      />
    </>
  );
};

const Content = styled(Box)({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  gap: '16px',
  height: '100%',
});
