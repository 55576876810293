import { SyntheticEvent } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import {
  CheckboxTree,
  Sidebar,
  TreeDetails,
  TreeViewContent,
} from '@e-schema/components';

import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, IconButton, Tab, Typography, styled } from '@mui/material';

import { TransformContent } from './OrganizationTree/TransformContent';
import { useOrganizationTree } from './OrganizationTree/useOrganizationTree';

export const OrganizationTree = () => {
  const {
    t,
    isVisible,
    handleChangeVisibility,
    handleTransform,
    tabValue,
    setTabValue,
    setSelectedNodes,
    selectedNodes,
    details,
    updateDetails,
    zoom,
  } = useOrganizationTree();

  return (
    <OrganizationTreePage>
      {isVisible && (
        <Box>
          <Sidebar>
            <CloseBox>
              <Typography variant="bodySemibold">
                {t('organizationTree.properties')}
              </Typography>
              <IconButton onClick={handleChangeVisibility}>
                <CloseIcon />
              </IconButton>
            </CloseBox>
            <TabContext value={tabValue}>
              <TabList
                onChange={(_event: SyntheticEvent, newValue: string) =>
                  setTabValue(newValue)
                }
              >
                <CustomTab label={t('organizationTree.cells')} value="cells" />
                <CustomTab
                  label={t('organizationTree.details')}
                  value="details"
                />
              </TabList>
              <CustomTabPanel value="cells">
                <CheckboxTree
                  setSelectedNodes={setSelectedNodes}
                  selectedNodes={selectedNodes}
                />
              </CustomTabPanel>
              <CustomTabPanel value="details">
                <TreeDetails
                  state={details}
                  updateTreeDetails={updateDetails}
                />
              </CustomTabPanel>
            </TabContext>
          </Sidebar>
        </Box>
      )}
      <OrganizationTreeBackground>
        <TransformWrapper
          disablePadding
          maxScale={10}
          smooth
          initialScale={1}
          minScale={0.1}
          onTransformed={(e) => handleTransform(e)}
        >
          <TreeViewContent
            isSideBarVisible={isVisible}
            changeVisibility={handleChangeVisibility}
            zoom={zoom}
          >
            <TransformContent selectedNodes={selectedNodes} details={details} />
          </TreeViewContent>
        </TransformWrapper>
      </OrganizationTreeBackground>
    </OrganizationTreePage>
  );
};

const OrganizationTreePage = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  height: 'calc(100% - 49px)',
  width: '100%',
});

const OrganizationTreeBackground = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  background: 'white',
  display: 'flex',
  overflow: 'hidden',
  margin: 'auto',
  boxSizing: 'border-box',
  cursor: 'grab',
  // eslint-disable-next-line
  '&:active': {
    cursor: 'grabbing',
  },
  // eslint-disable-next-line
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const CloseBox = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const CustomTab = styled(Tab)({
  textTransform: 'none',
});
const CustomTabPanel = styled(TabPanel)({
  padding: 0,
});
