import { Typography, styled } from '@mui/material';

import { theme } from '@/theme';

import { DisableLabelProps } from './types';

export const DisableLabel = (props: DisableLabelProps) => {
  return (
    <CustomTypography
      variant={props.variant}
      sx={{
        textDecoration: props.lineThrough ? 'line-through' : 'none',
        ...props.sx,
      }}
    >
      {props.children}
    </CustomTypography>
  );
};

const CustomTypography = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.grey[500],
  textTransform: 'none',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'default',
  userSelect: 'none',
});
