import { forwardRef } from 'react';

import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectChangeEvent,
  SxProps,
  Theme,
  Typography,
  styled,
} from '@mui/material';

import { SelectProps } from './Select.types';

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      backgroundColor,
      onChange,
      children,
      sx = {},
      selectProps,
      error,
      value,
    },
    ref,
  ) => {
    return (
      <FormControl
        sx={{
          m: 1,
          minWidth: 138,
          ...(sx as SxProps<Theme>),
        }}
        size="small"
      >
        <InputLabel id="position-select-label">
          <Typography fontSize={14} variant="bodyMedium">
            {name}
          </Typography>
        </InputLabel>
        <SelectCon
          disabled={selectProps?.disabled}
          labelId="position-select-label"
          id="position-select"
          label={name}
          style={{
            ...(backgroundColor && {
              backgroundColor,
            }),
          }}
          error={error}
          ref={ref}
          value={value}
          {...selectProps}
          onChange={(event: SelectChangeEvent<unknown>, child) => {
            if (onChange) {
              onChange(event, child);
            }
          }}
        >
          {children}
        </SelectCon>
      </FormControl>
    );
  },
);

const SelectCon = styled(MuiSelect)({
  borderRadius: 8,
});
