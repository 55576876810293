import { captureException } from '@sentry/react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { EflowStepSyncContext } from '@e-flow/hooks/useSynchronizedFlows';
import { SynchronizedFlowsActionTypes } from '@e-flow/hooks/useSynchronizedFlows/SynchronizedFlowsAction.enum.ts';
import { useUpdateEflowStepCache } from '@e-flow/pages/flowDashboard/hooks/useCacheUpdate/hooks';
import { toIndexDb } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/Functions';
import { formatCommentsBeforeSave } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/Functions/formatCommentsBeforeSave.ts';
import { EFlowStepTypes } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';
import { UseCreateEflowStepTypes } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useCreateEflowStep/UseCreateEflowStep.types.ts';
import { useNetworkState } from '@uidotdev/usehooks';
import { omit } from 'lodash';

import {
  CreateEflowStepInput,
  EflowStepFragmentFragment,
  useCreateEflowStepMutation,
  useUpdateEflowStepMutation,
} from '@/__generated__/graphql.ts';
import { IncrementDecrementOperationEnum } from '@/core/types';
import { useAsIsToBeCount } from '@/pages/e-flow/hooks';
import { useUserStore } from '@/stores';

import { StepProgressContext } from '../../../context';

export const useCreateEflowStep = (props: UseCreateEflowStepTypes) => {
  const { userUuid, organizationId: userOrganizationId } = useUserStore();
  const { flowId } = useParams();

  const { setStepProgressValue, setStepProgressSnackbarOpen } =
    useContext(StepProgressContext);

  const { pushToSynchronize } = useContext(EflowStepSyncContext);

  const [createEflowStep] = useCreateEflowStepMutation();
  const [updateMutation] = useUpdateEflowStepMutation();

  const { online: isOnline } = useNetworkState();

  const { updateCache } = useAsIsToBeCount({ flowId: flowId! });

  const { updateEflowStep } = useUpdateEflowStepCache(
    props.eFlowId,
    props.organizationId,
  );

  /**
   *  Save eFlow step offline and push to synchronize
   * @param eFlowToSave {CreateEflowStepInput}
   * @param files {File[]}
   * @param currentEflowStepId {string} - current eFlow step id
   * @private
   */
  const saveOffline = async (
    eFlowToSave: CreateEflowStepInput,
    files: File[],
    currentEflowStepId: string,
  ) => {
    await props.updateElement(
      currentEflowStepId,
      toIndexDb(eFlowToSave, files),
    );
    await pushToSynchronize(
      eFlowToSave.eFlowId,
      currentEflowStepId,
      SynchronizedFlowsActionTypes.CREATE,
      props.analizeType,
    );
  };

  /**
   * Sends eFlow step to be saved on server
   * @param eFlowToSave
   * @param files
   * @param currentEflowStepId
   * @private
   */
  const saveOnline = async (
    eFlowToSave: CreateEflowStepInput,
    files: File[],
    currentEflowStepId: string,
  ) => {
    setStepProgressValue(0);
    setStepProgressSnackbarOpen(true);
    let data: EflowStepFragmentFragment = {} as EflowStepFragmentFragment;
    data = await _saveWithSingleFile(eFlowToSave, files);
    if (files.length > 1) {
      data = (await _saveWithMultipleFiles(
        data,
        files,
        eFlowToSave.stepNumber || 0,
      )) as EflowStepFragmentFragment;
    }

    if (data) {
      const stepNumber = eFlowToSave.stepNumber || 0;

      updateEflowStep(props.analizeType, data, stepNumber);

      updateCache(
        [data.id],
        IncrementDecrementOperationEnum.Increment,
        props.analizeType,
        stepNumber,
      );

      await Promise.allSettled([
        props.saveElement(
          {
            ...data,
            stepNumber,
          } as EFlowStepTypes,
          data.id,
        ),
        props.removeElement(currentEflowStepId), // remove an old "local" version of a step
      ]);
    }

    setStepProgressValue(100);
    setStepProgressSnackbarOpen(false);
  };

  /**
   * Save current eflow step
   * @param eFlowStep {CreateEflowStepInput}
   * @param files {File[]} all files passed to eFlow
   * @param currentEflowStepId {string} - current eFlow step id
   */
  const save = async (
    eFlowStep: CreateEflowStepInput,
    files: File[],
    currentEflowStepId: string,
  ): Promise<string[] | void> => {
    const eFlowToSave: CreateEflowStepInput = {
      ...eFlowStep,
      createdAt: new Date(),
      eFlowId: props.eFlowId,
      organizationId: props.organizationId,
      comments: formatCommentsBeforeSave(
        props.organizationId,
        {
          id: userUuid,
          organizationId: userOrganizationId!,
        },
        eFlowStep.comments!,
      ),
    };

    if (isOnline) {
      await saveOnline(eFlowToSave, files, currentEflowStepId);
    } else {
      await saveOffline(eFlowToSave, files, currentEflowStepId);

      updateCache(
        [''],
        IncrementDecrementOperationEnum.Increment,
        props.analizeType,
      );
    }
  };

  async function _saveWithSingleFile(
    eFlowToSave: CreateEflowStepInput,
    files: File[],
  ) {
    const { data } = await createEflowStep({
      variables: {
        input: {
          ...omit(eFlowToSave, 'localFiles'),
          stepNumber: eFlowToSave.stepNumber || 0,
          analizeType: props.analizeType,
        },
        ...(files.length > 0 && { files: files[0] }),
      },
    });

    return data?.createEflowStep as EflowStepFragmentFragment;
  }

  async function _saveWithMultipleFiles(
    eFlowToSave: EflowStepFragmentFragment,
    files: File[],
    stepNumber: number,
  ) {
    let lastResponse: EflowStepFragmentFragment | undefined = undefined;

    const newFiles = files.slice(1);

    for (let i = 0; i < newFiles.length; i++) {
      // const currentFiles = i === 0 ? _createFilesChangeSet(files) : undefined;
      lastResponse = await _updateOnline(eFlowToSave, [newFiles[i]]);
      setStepProgressValue((i / newFiles.length) * 100);
    }
    if (lastResponse) {
      updateEflowStep(props.analizeType, lastResponse, stepNumber);

      await props.updateElement(eFlowToSave.id, lastResponse as EFlowStepTypes);
    }
    setStepProgressSnackbarOpen(false);
    return lastResponse;
  }

  const _updateOnline = async (
    params: EflowStepFragmentFragment,
    // currentFiles: S3FileInput[] | undefined,
    file: File[],
  ) => {
    try {
      const { data } = await updateMutation({
        variables: {
          input: {
            id: params.id,
            organizationId: props.organizationId,
            eFlowId: props.eFlowId,
            // currentFiles,
          },
          ...(file.length > 0 && { files: file }),
        },
      });

      return data?.updateEflowStep as EflowStepFragmentFragment;
    } catch (error) {
      captureException(error);
      void pushToSynchronize(
        props.eFlowId,
        params.id,
        SynchronizedFlowsActionTypes.UPDATE,
        props.analizeType,
      );
    }
  };

  // function _createFilesChangeSet(files?: File[] | null): S3FileInput {
  //   if (!files) return [];
  //
  //   const mappedFiles = files.map((file) => ({
  //     fileName: file.name,
  //   }));
  //
  //   return mappedFiles;
  // }

  return { save };
};
