/* eslint-disable @typescript-eslint/naming-convention */
export const organizationTreeColors: Record<number, string> = {
  1: '#19378F',
  2: '#197A8F',
  3: '#8F1919',
  4: '#1C8734',
  5: '#8F1961',
  6: '#19768F',
  7: '#8F6619',
  8: '#178252',
  9: '#727A15',
  10: '#19618F',
  11: '#157A68',
  12: '#8F1984',
  13: '#4C7A15',
  14: '#0A70DC',
  15: '#8F5919',
  16: '#19378F',
  17: '#7E6716',
  18: '#0F5723',
  19: '#8F1966',
  20: '#157A15',
};
