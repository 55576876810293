import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, styled, useMediaQuery, useTheme } from '@mui/material';

export const AiSidebar = ({
  setSidebar,
}: {
  setSidebar: (newUrl: string) => void;
}) => {
  const { t } = useTranslation('AI');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? null : (
    <StyledSidebar>
      <StyledLink to={''} onClick={() => setSidebar('/')}>
        {t('links.data')}
      </StyledLink>
      <StyledLink to="" onClick={() => setSidebar('/forecast')}>
        {t('links.predicted')}
      </StyledLink>
    </StyledSidebar>
  );
};

const StyledSidebar = styled(Box)`
  width: 200px;
  max-width: 200px;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid #e0e0e0;
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 10px;
  text-decoration: none;
  color: black;
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    background-color: #e0e0e0;
  }
`;
