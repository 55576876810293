import { CircularProgress } from '@mui/material';

import { FlexCenter } from '@/core/styles';

export const FullScreenLoading = () => {
  return (
    <FlexCenter sx={{ width: '100vw', height: '100vh' }}>
      <CircularProgress />
    </FlexCenter>
  );
};
