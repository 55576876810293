import { useEffect, useState } from 'react';

import { FlowStepSchema } from '@e-flow/pages/flowSteps/FlowStep.schema.ts';
import {
  EFlowStepTypes,
  EflowStepWithFiles,
} from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';
import { isEqual } from 'lodash';
import { v4 } from 'uuid';
import { ZodError } from 'zod';

import {
  CreateEflowStepInput,
  EflowStepFragmentFragment,
} from '@/__generated__/graphql.ts';

import {
  UseControlEflowStepTypes,
  UseControlEflowStepTypesReturn,
} from './UseControlEflowStepTypes.ts';

export const useControlEflowStep = (
  props: UseControlEflowStepTypes,
): UseControlEflowStepTypesReturn => {
  const [currentEflowStep, setCurrentEflowStep] = useState<
    EFlowStepTypes | NonNullable<unknown>
  >({});
  const [currentEflowStepId, setCurrentEflowStepId] = useState<string>('');
  const [hasStepBeenUpdated, setHasStepBeenUpdated] = useState<boolean>(false);

  //defines a temporary step. Use in update
  const [hasFilesBeenChanged, setHasFilesBeenChanged] =
    useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!currentEflowStepId) {
      setPointingLastEflowStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  /**
   * Checks if the step is valid and can be saved
   * @param params
   * @returns {boolean | string[]} - true when the step is valid, false when the step is invalid, string[] when the step is invalid and we know why
   */
  const validate: UseControlEflowStepTypesReturn['validate'] = (
    params: EFlowStepTypes,
  ): boolean | string[] => {
    try {
      FlowStepSchema().parse(params);
      return true;
    } catch (e) {
      if (e instanceof ZodError)
        return e.issues.map((issue) => issue.path.join('.'));
      return false;
    }
  };

  /**
   * Update eflow files
   * @param files
   */
  const updateEflowFiles: UseControlEflowStepTypesReturn['updateEflowFiles'] = (
    files: File[],
  ) => {
    setFiles(files);
    setHasFilesBeenChanged(true);
  };

  const removeAlreadyUploadedFiles = (fileName: string) => {
    const files = (currentEflowStep as EflowStepFragmentFragment).files;

    if (!files) return;

    const updatedFiles = files.filter(
      (file) => file.fileName !== fileName,
    ) as EflowStepFragmentFragment['files'];

    updateCurrentEflowStep({
      files: updatedFiles,
    });
    setFiles([]);
  };

  /**
   * Changes eFlow step
   * @returns {Promise<boolean|null>} - true when the step has been changed,
   * false when a step has not been changed, null when user aborts the change
   */
  const changeCurrentEflowStep: UseControlEflowStepTypesReturn['changeCurrentEflowStep'] =
    async (stepId: string, saveCallback): Promise<boolean> => {
      if (hasStepBeenUpdated || hasFilesBeenChanged) {
        //Defines that something happened to step and needs to be saved
        if (currentEflowStepId) await saveCallback();
      }

      if (stepId === currentEflowStepId) return true;

      setCurrentEflowStepId(stepId);
      //Before change, you need to save a current step to the local database, so it can be restored
      setCurrentEflowStep(props.data[stepId] as CreateEflowStepInput);
      setHasFilesBeenChanged(false);
      setHasStepBeenUpdated(false);
      setFiles([]);

      return true;
    };

  /**
   * Points to the current eFlow step. Use during loads
   * @private - internal use
   * @returns void
   *
   */
  const setPointingLastEflowStep = () => {
    const keysOfEflowSteps = Object.keys(props.data);
    if (keysOfEflowSteps.length > 0) {
      //If the record does not have it, this means it was not synchronized, therefore, it is draft
      const recordIndex = keysOfEflowSteps.at(-1);
      //We are sure that the record exists
      if (recordIndex) {
        setCurrentEflowStepId(recordIndex);
        setCurrentEflowStep(props.data[recordIndex]);
        setHasFilesBeenChanged(false);
        setHasStepBeenUpdated(false);
        return;
      }
    }
  };

  /**
   * Update current eflow step
   * @param value {CreateEflowStepInput} - the value to update
   * @returns void
   */
  const updateCurrentEflowStep: UseControlEflowStepTypesReturn['updateCurrentEflowStep'] =
    (value: Partial<CreateEflowStepInput>) => {
      setHasStepBeenUpdated(true);
      setCurrentEflowStep((prev) => ({
        ...prev,
        ...value,
      }));
      void props.updateElement(currentEflowStepId, value as EFlowStepTypes);
    };

  const getStepNumber = () => {
    if (!currentEflowStep || isEqual(currentEflowStep, {})) return 0;
    if (Object.keys(props.data).length === 0) return 0;

    return +(currentEflowStep as EFlowStepTypes).stepNumber + 1 || 0;
  };

  /**
   * Adds a new empty step.
   * Clears files and sets new id.
   * The New set will be saved in the database, marked as draft.
   * It won't be sent to server, or synchronized.
   * Until @save method is called
   * @private
   */
  const addNewEmptyStep: UseControlEflowStepTypesReturn['addNewEmptyStep'] =
    async () => {
      const stepNumber = getStepNumber();

      setFiles([]);
      setHasFilesBeenChanged(false);
      setHasStepBeenUpdated(false);
      const stepId = v4();
      setCurrentEflowStepId(stepId);

      setCurrentEflowStep({
        stepNumber,
        createdAt: new Date(),
        parameters: {},
        eFlowId: props.eFlowId,
        isActive: true,
      } as CreateEflowStepInput);

      await props.reIndexRecords(stepNumber, 'stepNumber', 1);

      await props.saveElement(
        { stepNumber } as EflowStepWithFiles,
        stepId,
        stepNumber,
      );

      await props.reHydrate();
    };

  return {
    files,
    hasStepBeenUpdated,
    hasFilesBeenChanged,
    currentEflowStep,
    currentEflowStepId,
    validate,
    updateEflowFiles,
    removeAlreadyUploadedFiles,
    addNewEmptyStep,
    updateCurrentEflowStep,
    changeCurrentEflowStep,
    setPointingLastEflowStep,
  };
};
