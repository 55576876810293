import { useMemo } from 'react';

export const useDisplayHeaders = (notDisplayedHeaders: string[]) => {
  const isOperationDisplayed = useMemo(() => {
    return !notDisplayedHeaders.includes('operation');
  }, [notDisplayedHeaders]);

  const isOperationNameDisplayed = useMemo(() => {
    return !notDisplayedHeaders.includes('operationName');
  }, [notDisplayedHeaders]);

  const areAttachmentsDisplayed = useMemo(() => {
    return !notDisplayedHeaders.includes('attachments');
  }, [notDisplayedHeaders]);

  const areCommentsDisplayed = useMemo(() => {
    return !notDisplayedHeaders.includes('comments');
  }, [notDisplayedHeaders]);

  const areOptionsDisplayed = useMemo(() => {
    return !notDisplayedHeaders.includes('options');
  }, [notDisplayedHeaders]);

  return {
    isOperationDisplayed,
    isOperationNameDisplayed,
    areAttachmentsDisplayed,
    areCommentsDisplayed,
    areOptionsDisplayed,
  };
};
