import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { Box } from '@mui/material';

import { theme } from '@/theme';

import { MainPageTileProps } from './MainPageTile.types';

export const MainPageTile = (props: MainPageTileProps) => {
  if (props.disabled) {
    return <DisabledContainer>{props.children}</DisabledContainer>;
  }

  return (
    <TilesLink to={props.to}>
      <Container>{props.children}</Container>
    </TilesLink>
  );
};

const Container = styled(Box)({
  display: 'flex',
  width: '240px',
  flexWrap: 'wrap',
  height: '240px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flexShrink: 0,
  borderRadius: 'var(--radius-basic, 8px)',
  border: '1px solid var(--neutrals-gray-200, #DCDFE1)',
  background: 'var(--primary-primary-200, #BCDBFF)',
});

const DisabledContainer = styled(Container)({
  background: theme.palette.grey[200],
  color: theme.palette.grey[600],
  minWidth: '240px',
});

const TilesLink = styled(Link)({
  textDecoration: 'none',
  color: '#121212',
});
