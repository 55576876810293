import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DeleteOrganizationModal,
  IPickedOrganization,
  OrganizationsRow,
} from '@organizations/pages/organizations/OrganizationComponents';
import { useDebounceValue } from 'usehooks-ts';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { NoDataInfo, TablePaginationWrapper } from '@core/components';
import { FlexCenter } from '@core/styles';
import { checkSystemRolesPermission } from '@core/utils';

import {
  FindAllOrganizationsQuery,
  SystemRoles,
  useFindAllOrganizationsLazyQuery,
} from '@/__generated__/graphql.ts';
import { useTablePagination } from '@/core/hooks';
import { useUserStore } from '@/stores';

import { AddOrganization } from '../addOrganization';
import { Navbar } from './Navbar';

export const Organizations = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isAddOrganizationVisible, setIsAddOrganizationVisible] =
    useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebounceSearch] = useDebounceValue(search, 500);
  const [isLoading, setIsLoading] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] =
    useState<IPickedOrganization | null>(null);
  const [rows, setRows] = useState<
    FindAllOrganizationsQuery['findAllOrganizations']['items']
  >([]);
  const [total, setTotal] = useState<number>(0);

  const { cursor, setCursor, rowsPerPage, setRowsPerPage } =
    useTablePagination();

  const [query, { data }] = useFindAllOrganizationsLazyQuery();

  const handleCloseDeleteModal = (success?: boolean) => {
    if (success)
      setRows(rows.filter((row) => row.id !== organizationToDelete?.id));
    setOrganizationToDelete(null);
  };

  const { userGroups, userUuid } = useUserStore();
  const isEmployeePermited = checkSystemRolesPermission(userGroups, [
    SystemRoles.Root,
    SystemRoles.SystemAdmin,
  ]);

  useEffect(() => {
    setIsLoading(true);
    void query({
      variables: {
        paginationInput: {
          limit: rowsPerPage,
          cursor,
          ...(debouncedSearch && {
            search: debouncedSearch,
          }),
          ...(!isEmployeePermited &&
            userGroups.includes(SystemRoles.KeyUser) && {
              keyUserId: userUuid,
            }),
        },
      },
      pollInterval: 10000,
    }).then(() => setIsLoading(false));
    //search is not a dependency because we want to fetch data only when a user stops typing ---> useDebounceValue
    // eslint-disable-next-line
  }, [cursor, rowsPerPage, debouncedSearch]);

  useEffect(() => {
    if (data) {
      setRows(data.findAllOrganizations.items || []);
    }
    if (
      data?.findAllOrganizations.total &&
      data?.findAllOrganizations.total !== total
    )
      setTotal(data.findAllOrganizations.total);
  }, [data, total]);

  const renderTableBody = useCallback(() => {
    if (isLoading || !(rows.length > 0)) return null;

    return rows.map((row, index) => (
      <OrganizationsRow
        row={row}
        setOrganizationToDelete={(organization) =>
          setOrganizationToDelete(organization)
        }
        key={`${row.url} ${index}`}
      />
    ));
  }, [rows, isLoading]);

  return (
    <>
      {organizationToDelete && (
        <DeleteOrganizationModal
          organization={organizationToDelete}
          open={true}
          closeModal={handleCloseDeleteModal}
        />
      )}
      {!isAddOrganizationVisible ? (
        <>
          <Navbar />
          <Stack padding="24px" gap="32px">
            <ListHeader>
              <Typography variant="h3">{t('organization.title')}</Typography>
              {isEmployeePermited ? (
                <PrimaryButton
                  variant="contained"
                  style={{
                    backgroundColor: theme.palette.primary[600],
                  }}
                  onClick={() => setIsAddOrganizationVisible(true)}
                >
                  <Typography
                    fontFamily="Inter"
                    fontWeight={500}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {t('organization.addOrganization')}
                  </Typography>
                </PrimaryButton>
              ) : null}
            </ListHeader>
            <FormControl sx={{ width: 328 }} size="small">
              <TextField
                size="small"
                placeholder={t('organization.search')}
                onChange={(event) => {
                  setSearch(event.target.value);
                  setDebounceSearch(event.target.value);
                  setCursor(0);
                }}
                inputProps={{ maxLength: 50 }}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <TableContainer>
              <TablePaginationWrapper
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={(newValue) => setRowsPerPage(newValue)}
                cursor={cursor}
                setCursor={(newValue) => setCursor(newValue)}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell height={'40px'}>
                        <CustomTopography fontWeight={500}>
                          {t('organization.organizationName')}
                        </CustomTopography>
                      </TableCell>
                      <TableCell>
                        <CustomTopography fontWeight={500}>
                          {t('organization.addData')}
                        </CustomTopography>
                      </TableCell>
                      <TableCell>
                        <CustomTopography fontWeight={500}>
                          {t('organization.modules')}
                        </CustomTopography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </TablePaginationWrapper>
            </TableContainer>
            {isLoading && (
              <FlexCenter>
                <CircularProgress />
              </FlexCenter>
            )}

            {rows.length === 0 && !isLoading && (
              <NoDataInfo
                onClick={() => setIsAddOrganizationVisible(true)}
                withButton={[SystemRoles.Root, SystemRoles.SystemAdmin].some(
                  (role) => userGroups.includes(role),
                )}
                headerText={t('organization.noOrganizations')}
                infoText={t('organization.pressButtonToAddOrg')}
                buttonText={t('organization.addOrganization')}
              />
            )}
          </Stack>
        </>
      ) : (
        <AddOrganization close={() => setIsAddOrganizationVisible(false)} />
      )}
    </>
  );
};

const ListHeader = styled(Box)({
  width: '100%',
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const PrimaryButton = styled(Button)({
  textTransform: 'none',
  borderRadius: 8,
  height: '40px',
  padding: '0 12px 0 12px',
});

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
