import type { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';
import { styled } from '@mui/system';

export const CustomBoxChip = (props: ChipProps) => {
  return <CustomChip {...props} />;
};

const CustomChip = styled(Chip)({
  borderRadius: '7px',
  padding: '2px',
  margin: 0,
  color: 'white',
});
