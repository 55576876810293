import { useTranslation } from 'react-i18next';

import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

export const RankingHeaders = () => {
  const { t } = useTranslation('kaizen');

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell height={'40px'}>
          <CustomTypography>{t('commission.employee')}</CustomTypography>
        </TableCell>
        <TableCell>
          <CustomTypography>{t('ranking.comissions')}</CustomTypography>
        </TableCell>
        <TableCell>
          <CustomTypography>{t('points.pointsHeader')}</CustomTypography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const CustomTypography = styled(Typography)({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
});
