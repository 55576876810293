/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlowStepsDashboardProps } from '@e-flow/pages/flowDashboard/Dashboards/types.ts';
import { AsIsHeader, EflowTable } from '@e-flow/pages/flowDashboard/components';
import { AddOperationsToBeOptionsEnum } from '@e-flow/pages/flowDashboard/modals/AddOperationsToToBe/AddOperationsToBe.options.enum.ts';
import { CloneOperationsModal } from '@e-flow/pages/flowDashboard/modals/AddOperationsToToBe/AddOperationsToToBe.tsx';

import { Box, Typography, styled } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { useCompanyId } from '@core/hooks';
import { useFlowRedirects } from '@core/redirects';

import {
  AsIsToBeNamesEnum,
  EflowStep,
  useCloneEflowStepsMutation,
  useFindEflowToBeStepsLazyQuery,
  useFindOneEflowAsIsDataLazyQuery,
} from '@/__generated__/graphql.ts';
import {
  FullScreenLoading,
  NoDataInfo,
  RemoveItemModal,
} from '@/core/components';
import { useEflowPermissions } from '@/pages/e-flow/hooks';

import { useRemoveEflowSteps } from '../../../flowSteps/hooks/useEflowSteps/useRemoveEflowStep/useRemoveEflowStep';

export const AsIsFlowDashboard = (props: FlowStepsDashboardProps) => {
  const { t } = useTranslation('eflow');
  const { t: common } = useTranslation();

  const { redirectToAsIsFlow } = useFlowRedirects();

  const { companyId } = useCompanyId();

  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);

  const [toBeStepsCount, setToBeStepsCount] = useState(0);

  const { removeOnlineSteps } = useRemoveEflowSteps({
    eFlowId: props.eFlowId,
    organizationId: companyId,
    analyzeType: AsIsToBeNamesEnum.AsIs,
  });

  const [desiredEflowStartPosition, setDesiredEflowStartPosition] =
    useState<number>(0);

  const [cannotBeDisplayed, setCannotBeDisplayed] = useState<string[]>([]);

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  //move to modal
  const [selectedModalOption, setSelectedModalOption] = useState<string>(
    AddOperationsToBeOptionsEnum.ADD_TO_BOTTOM,
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [findFlow, { data, loading: isLoading }] =
    useFindOneEflowAsIsDataLazyQuery({
      nextFetchPolicy: 'cache-only',
      initialFetchPolicy: 'cache-first',
    });

  const [findToBeEflow, { data: toBeData }] = useFindEflowToBeStepsLazyQuery({
    nextFetchPolicy: 'cache-only',
    initialFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!companyId || !props.eFlowId) return;
    void findFlow({
      variables: {
        input: {
          organizationId: companyId,
          id: props.eFlowId,
        },
      },
    });
    void findToBeEflow({
      variables: {
        input: {
          organizationId: companyId,
          id: props.eFlowId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eFlowId, companyId, findFlow]);

  useEffect(() => {
    props.setStepsCount?.(data?.findOneEFlow?.asIsSteps?.length || 0);
  }, [data, props]);

  useEffect(() => {
    setToBeStepsCount(toBeData?.findOneEFlow?.toBeSteps?.length || 0);
  }, [toBeData, props]);

  const [cloneOperationsMutation] = useCloneEflowStepsMutation();

  const handleCloneOperations = async () => {
    await cloneOperationsMutation({
      variables: {
        input: {
          eFlowId: props.eFlowId,
          organizationId: companyId,
          eFlowStepsIds: selectedRows as string[],
          desiredEflowStartPosition: desiredEflowStartPosition,
        },
      },
    });
  };

  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: props.eFlowId,
  });

  if (isLoading)
    return (
      <Content>
        <FullScreenLoading />;
      </Content>
    );

  if (data?.findOneEFlow?.asIsSteps?.length === 0) {
    return canModifyAnalize ? (
      <NoDataInfo
        onClick={redirectToAsIsFlow}
        headerText={t('eflow.table.noOperations')}
        infoText={t('eflow.table.clickButtonToBeginAnalysis')}
        buttonText={t('eflow.table.beginAnalysis')}
      />
    ) : (
      <Container>
        <Typography variant="bodyMedium" sx={{ fontSize: '24px' }}>
          {t('eflow.table.noOperations')}
        </Typography>
      </Container>
    );
  }

  return (
    <Content>
      <AsIsHeader
        eFlowId={props.eFlowId}
        title={t('eflow.asIs.title')}
        parameters={props.parameters}
        handleChangeColumnsToDisplay={(columns) =>
          setCannotBeDisplayed(columns)
        }
        currentActionButtons={props.actionButton}
        removeOperations={{
          isVisible: selectedRows.length > 0 && canModifyAnalize,
          onClick: () => {
            setIsRemoveModalVisible(true);
          },
        }}
        addToToBe={{
          isVisible: selectedRows.length > 0 && canModifyAnalize,
          onClick: () => {
            setIsModalVisible(true);
          },
        }}
      />
      <EflowTable
        tableType={AsIsToBeNamesEnum.AsIs}
        cannotBeDisplayed={cannotBeDisplayed}
        stepsToDisplay={(data?.findOneEFlow?.asIsSteps as EflowStep[]) || []}
        parameters={props.parameters}
        toBeStepsCount={toBeStepsCount}
        onRowSelectionModelChange={setSelectedRows}
        eFlowId={data?.findOneEFlow?.id}
      />
      {/*modals*/}
      <CloneOperationsModal
        setSelectedOption={(value) => setSelectedModalOption(value as string)}
        allOperationLength={toBeStepsCount}
        selectedOption={selectedModalOption}
        buttonPrimary={{
          onClick: () => {
            setIsModalVisible(false);
          },
          text: common('common.cancel'),
        }}
        buttonSecondary={{
          onClick: () => {
            void handleCloneOperations();
            props.setCopied(selectedRows.length);
            setIsModalVisible(false);
          },
          text: common('common.add'),
        }}
        closeModal={() => setIsModalVisible(false)}
        display={isModalVisible}
        setDesiredEflowStartPosition={setDesiredEflowStartPosition}
      />
      <RemoveItemModal
        onRemove={async () => {
          await removeOnlineSteps(
            selectedRows as string[],
            AsIsToBeNamesEnum.AsIs,
          );
          setIsRemoveModalVisible(false);
        }}
        display={isRemoveModalVisible}
        closeModal={() => setIsRemoveModalVisible(false)}
        content={t('eflow.removeOperationsConfirmation')}
        headerText={t('eflow.removeOperations')}
      />
    </Content>
  );
};

const Content = styled(Box)({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  gap: '16px',
  height: '100%',
});

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});
