import { Box } from '@mui/material';
import { styled } from '@mui/styles';

import { InfoIconWithTooltip } from '@core/components';

import { KpiTableCell } from './KpiTableCell';

type KpiNameCellProps = {
  kpiName: string;
  kpiDescription?: string;
};

export const KpiNameCell = ({ kpiDescription, kpiName }: KpiNameCellProps) => {
  if (!kpiDescription) {
    return <KpiTableCell variant={'light'}>{kpiName}</KpiTableCell>;
  }

  return (
    <KpiTableCell variant={'light'}>
      <WithInfoContainer>
        <Box>{kpiName}</Box>
        <InfoIconWithTooltip
          wrapperProps={{
            title: kpiDescription,
          }}
        />
      </WithInfoContainer>
    </KpiTableCell>
  );
};

const WithInfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 3,
  justifyContent: 'space-between',
});
