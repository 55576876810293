import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Slide,
  Snackbar,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { UserSettingsSideBar } from '@core/components/userSettings/UserSettingsSideBar/UserSettingsSideBar.tsx';

import {
  GetEmployeeQuery,
  useGetEmployeeLazyQuery,
} from '@/__generated__/graphql';
import {
  AvatarSection,
  PasswordsSection,
  UserSection,
} from '@/core/components';
import { UnitSection } from '@/core/components/UserEdit/UnitSection/UnitSection.tsx';
import {
  NavbarMainContainer,
  NavbarTitle,
  NavbarUserMenu,
} from '@/core/styles';
import { UserEditTypes } from '@/core/types';
import { useUserStore } from '@/stores';

export const UserSettings = () => {
  const { t } = useTranslation();

  const { organizationId, userUuid } = useUserStore();

  const [employee, setEmployee] = useState<GetEmployeeQuery['getEmployee']>();
  const [isSnapBarOpen, setIsSnapBarOpen] = useState(false);
  const [snapBarMessage, setSnapBarMessage] = useState<string>('');

  const [query, { data, loading: isLoading }] = useGetEmployeeLazyQuery({
    variables: {
      input: {
        id: userUuid,
        organizationId: organizationId!,
      },
    },
  });

  useEffect(() => {
    void query();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setEmployee(data.getEmployee);
    }
  }, [data]);

  useEffect(() => {
    setIsSnapBarOpen(snapBarMessage !== '');
  }, [snapBarMessage]);

  const [editPassword, setEditPassword] = useState<UserEditTypes>(
    UserEditTypes.None,
  );
  const [editAvatar, setEditAvatar] = useState<UserEditTypes>(
    UserEditTypes.None,
  );

  const navigate = useNavigate();

  return (
    <Container>
      <NavbarMainContainer height="56px">
        <NavbarUserMenu>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <CloseIcon />
          </IconButton>

          <NavbarTitle>
            <Typography variant="bodyRegular">
              {t('common.accountSettings')}
            </Typography>
          </NavbarTitle>
        </NavbarUserMenu>
      </NavbarMainContainer>
      <StyledStack>
        <UserSettingsSideBar isLoading={isLoading} employee={employee} />
        <Stack padding="32px 0" gap="32px" width={'100%'}>
          <>
            <Typography variant="h3">{t('common.accountSettings')}</Typography>
            <Stack>
              <UserSection
                employee={employee}
                isLoading={isLoading}
                setToastMessage={(message: string) => {
                  setSnapBarMessage(message);
                }}
                isReadOnly={true}
              />
              {/*password section*/}
              <PasswordsSection
                isLoading={isLoading}
                isVisible={userUuid === employee?.id}
                edit={editPassword}
                setEdit={setEditPassword}
              />
              {/*This is avatar section, due to the current scope of changes will be implmenented during feature sprints*/}
              <AvatarSection
                employee={employee}
                edit={editAvatar}
                setEdit={setEditAvatar}
                isLoading={isLoading}
              />

              <UnitSection
                employee={employee}
                isLoading={isLoading}
                setToastMessage={(message: string) => {
                  setSnapBarMessage(message);
                }}
                isReadOnly={true}
              />
            </Stack>
          </>
          <Snackbar
            open={isSnapBarOpen}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            TransitionComponent={(props) => <Slide {...props} direction="up" />}
            message={snapBarMessage}
            autoHideDuration={5000}
            onClose={() => {
              setSnapBarMessage('');
            }}
          />
        </Stack>
      </StyledStack>
    </Container>
  );
};

const Container = styled(Stack)({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 5,
  backgroundColor: 'white',
});

const StyledStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  padding: '0 24px 0 0 ',
  gap: '24px',
});
