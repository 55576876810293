import { useTranslation } from 'react-i18next';

import { FormControlLabel, Switch } from '@mui/material';

import { AssignedToMeSwitchProps } from './AssignedToMeSwitch.types';

export const AssignedToMeSwitch = ({
  value,
  label,
  disabled = false,
  required = false,
  onChange,
}: AssignedToMeSwitchProps) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      value={value}
      checked={value}
      required={required}
      disabled={disabled}
      onChange={(_value, checked) => onChange(checked)}
      control={<Switch />}
      label={label ? label : t('AssignedToMeSwitch.assignedToMe')}
    />
  );
};
