import { useTranslation } from 'react-i18next';

import { OperationIcon } from '@e-flow/components';

import { Box, styled } from '@mui/material';

import { theme } from '@/theme';

import { StatisticTableTypes } from './SybmolicCell.types';

export const SymbolicCell = (props: StatisticTableTypes) => {
  const { t } = useTranslation('eflow');

  if (props.isLabel) {
    return (
      <Container>
        <AsIsLabelCell>
          <span>{t('reports.asIs')}</span>
        </AsIsLabelCell>
        <ToBeLabelCell>
          <span>{t('reports.toBe')}</span>
        </ToBeLabelCell>
      </Container>
    );
  }

  return (
    <Container>
      <AsIsCell>
        <OperationIcon operation={props?.asIs} />
      </AsIsCell>
      <ToBeCell>
        <OperationIcon operation={props?.toBe} />
      </ToBeCell>
    </Container>
  );
};

const Cell = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  width: 40,
  height: 40,
  borderRight: 'solid .5px',
});

const AsIsCell = styled(Cell)({
  borderColor: theme.palette.grey[300],
  borderLeft: 'solid .5px',
});

const LabelCell = styled(Cell)({
  borderColor: theme.palette.grey[300],
  width: 80,
  height: 40,
});

const AsIsLabelCell = styled(LabelCell)({
  borderColor: theme.palette.grey[300],
});

const ToBeLabelCell = styled(LabelCell)({
  borderColor: theme.palette.grey[300],
  borderTop: 'solid .5px',
});

const ToBeCell = styled(Cell)({
  borderColor: theme.palette.grey[300],
  borderTop: 'solid .5px',
  borderLeft: 'solid .5px',
});

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
