import { useEffect, useState } from 'react';

import { useCompanyId } from '@core/hooks';

import { useFindChartLazyQuery } from '@/__generated__/graphql';

import { useHandleChartVisibilityContext } from '../context/useHandleChartVisibilityContext';

export const useChartRow = (chartId: string) => {
  const { companyId } = useCompanyId();

  const { handleCheck } = useHandleChartVisibilityContext();

  const [findChart, { data, loading: isLoading }] = useFindChartLazyQuery();

  const [isDisabled, setIsDisabled] = useState(false);

  const getChartName = async () => {
    if (!companyId) return;
    await findChart({
      variables: { input: { organizationId: companyId, id: chartId } },
      fetchPolicy: 'cache-first',
    });
  };

  const handleChange = async (layoutKey: string, isChecked: boolean) => {
    setIsDisabled(true);
    await handleCheck(layoutKey, isChecked);
    setIsDisabled(false);
  };

  useEffect(() => {
    if (!companyId) return;
    void getChartName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return {
    chartName: data?.findChart.chartName,
    isLoading,
    isDisabled,
    handleChange,
  };
};
