import { AsIsToBeNamesEnum } from '@/__generated__/graphql.ts';

import { useLoadAsIsSteps } from './LoadAsIsSteps';
import { AnalizeSteps, LoadStepsTypes } from './LoadSteps.types';
import { useLoadToBeSteps } from './LoadToBeSteps';

/**
 * Load the steps of the analysis
 * @param analizeType  The type of analysis
 * @param organizationId The organization id
 * @param eFlowId The eFlow id
 *
 * @returns {AnalizeSteps} steps of the analysis requested
 * @description facade for `LoadAsIsSteps` and `LoadToBeSteps`.
 *
 */
export const useLoadSteps = ({
  analizeType,
  organizationId,
  eFlowId,
}: LoadStepsTypes): AnalizeSteps => {
  const { getSteps: getAsIsSteps } = useLoadAsIsSteps({
    eFlowId,
    organizationId,
  });

  const { getSteps: getToBeSteps } = useLoadToBeSteps({
    eFlowId,
    organizationId,
  });

  if (analizeType === AsIsToBeNamesEnum.AsIs) {
    return { getSteps: getAsIsSteps };
  }
  return { getSteps: getToBeSteps };
};
