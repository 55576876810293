import { useEffect, useState } from 'react';

import { useDebounceValue } from 'usehooks-ts';

import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import { useCompanyId, useTablePagination } from '@core/hooks';

import { ChartTypes, useListKpiChartsLazyQuery } from '@/__generated__/graphql';
import { useUserDashboardContext } from '@/pages/home/pages/Dashboard/contexts/UserDashboardContext';

export const useKpiChartsList = () => {
  const { companyId } = useCompanyId();

  const { rowsPerPage, setRowsPerPage, cursor, setCursor } =
    useTablePagination();

  const [search, setSearch] = useState<string>('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const [bouncingInputSearch, setBouncingInputSearch] =
    useDebounceValue<string>('', 500);

  const [listKpiCharts, { data, loading: isLoading }] =
    useListKpiChartsLazyQuery();

  const { activeKpiSettlentPeriod } = useUserDashboardContext();

  useEffect(() => {
    setBouncingInputSearch(search);
    //eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (!companyId || !activeKpiSettlentPeriod) return;

    void listKpiCharts({
      variables: {
        input: {
          organizationId: companyId,
          settlementPeriodId: activeKpiSettlentPeriod,
          limit: rowsPerPage,
          cursor: cursor,
          ...(bouncingInputSearch
            ? { chartName: bouncingInputSearch }
            : undefined),
        },
      },
    });
    // eslint-disable-next-line
  }, [
    companyId,
    activeKpiSettlentPeriod,
    rowsPerPage,
    cursor,
    bouncingInputSearch,
  ]);

  const setIcon = (chartType: ChartTypes) => {
    switch (chartType) {
      case ChartTypes.BarChart:
        return <BarChartIcon />;
      case ChartTypes.PieChart:
        return <PieChartIcon />;
      case ChartTypes.LineChart:
        return <ShowChartIcon />;
    }
  };

  return {
    setIcon,
    search,
    handleSearch,
    rowsPerPage,
    setRowsPerPage,
    cursor,
    setCursor,
    kpiChartsList: data?.listKpiCharts?.items || [],
    total: data?.listKpiCharts?.total || 0,
    isLoading,
  };
};
