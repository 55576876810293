/* eslint-disable @typescript-eslint/no-misused-promises */
import { Trans, useTranslation } from 'react-i18next';

import { useFindSubmissionNumberForPeriod } from '@kaizen/hooks';
import { useKaizenSettlementPeriodContext } from '@kaizen/module-settings/SettlementPeriods/contexts';
import { useRemoveSettlementPeriod } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/Functions';

import { styled } from '@mui/system';

import { LoadingModal, RemoveItemModal } from '@core/components';

export const RemoveSettlementPeriodWarningModal = () => {
  const {
    isRemoveSettlementPeriodModalOpen,
    settlementPeriodRemoveModalValues,
    closeRemoveSettlementPeriodModal,
    open: openSnackbar,
  } = useKaizenSettlementPeriodContext();

  const { t } = useTranslation('kaizen');

  const { removeSettlementPeriod } = useRemoveSettlementPeriod();

  const { isLoading, totalNumberOfSubmissions } =
    useFindSubmissionNumberForPeriod(settlementPeriodRemoveModalValues?.id);

  const removeSettlementPeriodHandler = async () => {
    if (await removeSettlementPeriod(settlementPeriodRemoveModalValues!.id)) {
      openSnackbar('success', t('deleteSettlementPeriod.successMessage'));
    } else {
      openSnackbar(
        'error',
        t('deleteSettlementPeriod.errorDeleteSettlementPeriod'),
      );
    }

    closeRemoveSettlementPeriodModal();
    return;
  };

  if (isLoading) {
    return (
      <LoadingModal
        closeModal={closeRemoveSettlementPeriodModal}
        isLoading={true}
        text={''}
        open={isRemoveSettlementPeriodModalOpen}
        doneText={''}
        withTimeout={false}
      />
    );
  }

  if (totalNumberOfSubmissions === 0) {
    return (
      <RemoveItemModal
        closeModal={closeRemoveSettlementPeriodModal}
        content={
          <TextPreWrap>
            <Trans
              t={t}
              i18nKey="deleteSettlementPeriod.deleteDefaultModalContent"
              values={{
                settlementPeriod: settlementPeriodRemoveModalValues?.periodName,
              }}
            />
          </TextPreWrap>
        }
        headerText={t('deleteSettlementPeriod.modalHeader')}
        display={isRemoveSettlementPeriodModalOpen}
        onRemove={removeSettlementPeriodHandler}
      />
    );
  }

  return (
    <RemoveItemModal
      closeModal={closeRemoveSettlementPeriodModal}
      content={
        <TextPreWrap>
          <Trans
            t={t}
            i18nKey="deleteSettlementPeriod.areYouSureModalContent"
            values={{
              settlementPeriod: settlementPeriodRemoveModalValues?.periodName,
              submissionsCount: totalNumberOfSubmissions,
            }}
          />
        </TextPreWrap>
      }
      deleteButtonText={t('deleteSettlementPeriod.deleteButtonCustomText')}
      headerText={t('deleteSettlementPeriod.modalHeader')}
      display={isRemoveSettlementPeriodModalOpen}
      onRemove={removeSettlementPeriodHandler}
    />
  );
};

const TextPreWrap = styled('span')({
  whiteSpace: 'pre-wrap',
});
