import { useEffect, useState } from 'react';

import {
  AsIsToBeNamesEnum,
  EflowStepFragmentFragment,
  useFindEflowToBeStepsLazyQuery,
  useFindOneEflowAsIsDataLazyQuery,
} from '@/__generated__/graphql.ts';

import { useOperationsSelectReturn } from './useOperationsSelect.types';

export const useOperationsSelect = (): useOperationsSelectReturn => {
  const [organizationId, setOrganizationId] = useState<string>('');
  const [eFlowId, setEflowId] = useState<string>('');
  const [analizeType, setAnalizeType] = useState<AsIsToBeNamesEnum>();

  const [data, setData] = useState<EflowStepFragmentFragment[]>([]);

  useEffect(() => {
    if (!organizationId || !eFlowId || !analizeType) return;

    void getSteps(analizeType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, eFlowId, analizeType]);

  const [findAsIs, { loading: isAsIsLoading }] =
    useFindOneEflowAsIsDataLazyQuery({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
    });

  const [findToBe, { loading: isToBeLoading }] = useFindEflowToBeStepsLazyQuery(
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
    },
  );

  const init: useOperationsSelectReturn['init'] = (
    organizationId,
    eFlowId,
    analizeType,
  ) => {
    setEflowId(eFlowId);
    setOrganizationId(organizationId);
    setAnalizeType(analizeType);
  };

  const getSteps: useOperationsSelectReturn['getSteps'] = async (
    analizeType: AsIsToBeNamesEnum,
  ) => {
    const payload = {
      variables: {
        input: {
          id: eFlowId,
          organizationId: organizationId,
        },
      },
    };
    if (analizeType === AsIsToBeNamesEnum.AsIs) {
      const { data } = await findAsIs(payload);
      setData(
        (data?.findOneEFlow?.asIsSteps as EflowStepFragmentFragment[]) || [],
      );
    } else {
      const { data } = await findToBe(payload);
      setData(
        (data?.findOneEFlow.toBeSteps as EflowStepFragmentFragment[]) || [],
      );
    }
  };

  return {
    data,
    isLoading: isAsIsLoading || isToBeLoading,
    init,
    getSteps,
  };
};
