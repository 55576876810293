import { useTranslation } from 'react-i18next';

import { KpiRatesTable } from '@kpi/components';
import { useAddKpiRateContext } from '@kpi/contexts';

import { Box, Stack, styled } from '@mui/material';

import { Button } from '@core/components';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { checkSystemRolesPermission } from '@/core/utils/checkPermission/checkPermission';
import { useUserStore } from '@/stores/useUserStore/useUserStore';

export const Indicators = () => {
  const { t } = useTranslation('kpi');
  const { openAddKpiModal } = useAddKpiRateContext();
  const { userGroups } = useUserStore();

  return (
    <MainPageContainer id="main-kpi-container">
      <TitleRow>
        <Box />
        {!checkSystemRolesPermission(userGroups, [SystemRoles.User]) && (
          <Button onClick={() => openAddKpiModal()}>{t('addKpi.title')}</Button>
        )}
      </TitleRow>

      <KpiRatesTable />
    </MainPageContainer>
  );
};

const MainPageContainer = styled(Stack)({
  padding: '20px',
  gap: '20px',
});

const TitleRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
