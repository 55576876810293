import { Route, Routes } from 'react-router-dom';

import { GlobalPermissions } from '@organizations/components/GlobalPermissions';
import { Modules } from '@organizations/pages/organizationSettings';
import { OrganizationUrlWrapper } from '@router/components/OrganizationUrlWrapper';

import { PermissionsModule } from '@core/modules/PermissionModule';

import { ModuleNamesEnum, SystemRoles } from '@/__generated__/graphql.ts';
import { ErrorContent } from '@/core/components';
import { FlexCenter } from '@/core/styles';
import { ESchemaRoutes } from '@/pages/e-schema';
import { Home } from '@/pages/home';
import {
  ESchemaSettings,
  OrganizationData,
  OrganizationSettings,
} from '@/pages/organizations/pages/organizationSettings';
import { ProtectedRoute } from '@/router';

export const CompanyRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            redirectOnForbidden={true}
            redirectOnUnauthorized={true}
          />
        }
      >
        <Route path="/:companyName" element={<OrganizationUrlWrapper />}>
          {/*// this path is responsible for rendering READ ONLY INFO ABOUT CURRENT LOGGED USER*/}

          <Route index element={<Home />} />
          <Route path="e-schema/*" element={<ESchemaRoutes />} />
          {/*Organization Settings*/}
          <Route
            element={
              <ProtectedRoute
                allowedGroups={[
                  SystemRoles.Root,
                  SystemRoles.SystemAdmin,
                  SystemRoles.KeyUser,
                  SystemRoles.Administrator,
                ]}
                isSystemRoute={true}
              />
            }
          >
            <Route
              path="organization-settings"
              element={<OrganizationSettings />}
            >
              <Route path="data" element={<OrganizationData />} />
              <Route
                path="global-permissions"
                element={<GlobalPermissions />}
              />
              <Route path="modules" element={<Modules />} />
              <Route path="e-schema" element={<ESchemaSettings />} />

              <Route
                path="e-flow"
                element={
                  <PermissionsModule
                    moduleName={ModuleNamesEnum.Eflow}
                    possibleRoles={[SystemRoles.EflowCreator]}
                  />
                }
              />

              <Route
                path="kaizen"
                element={
                  <PermissionsModule
                    moduleName={ModuleNamesEnum.Kaizen}
                    possibleRoles={[SystemRoles.KaizenMember]}
                  />
                }
              />
              <Route
                path="kpi"
                element={
                  <PermissionsModule
                    moduleName={ModuleNamesEnum.Kpi}
                    possibleRoles={[SystemRoles.KpiMember]}
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <FlexCenter sx={{ height: '100vh' }}>
                <ErrorContent errorCode="404" />
              </FlexCenter>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};
