import { t } from 'i18next';
import { z } from 'zod';

export const deleteOrganizationSchema = (name: string) =>
  z.object({
    organizationName: z
      .string()
      .min(1, t('login.requiredField'))
      .refine((data: string) => data === name, {
        message: t('organization.organizationNameError'),
      }),
  });
