import { IUser } from '@/core/types';

/**
 * Default values for useUserStore
 */
export const useUserStoreDefaultValues: IUser = {
  email: '',
  lastName: '',
  name: '',
  phone: '',
  userGroups: [],
  organizationUrl: '',
  organizationId: '',
  userUuid: '',
  profilePicture: null,
};
