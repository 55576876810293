import { useTranslation } from 'react-i18next';

import { UnitSelect } from '@kpi/components';
import { RowStack } from '@organizations/styles';

import {
  OrganizationUnitSelect,
  SearchTextField,
  UserSelect,
} from '@core/components';
import { useCompanyId, useGetCurrentUserDetails } from '@core/hooks';

import { CreateChartContextType } from './types';

export const Filters = ({
  search,
  setSearchInput,
  organizationUnitIds,
  setDebounceSearch,
  setOrganizationUnitId,
  setAssignedPersonId,
  setSelectedUnit,
  defaultOrganizationUnitData,
}: CreateChartContextType) => {
  const { t } = useTranslation('kpi');
  const { companyId } = useCompanyId();

  const { isLoading, user } = useGetCurrentUserDetails();

  return (
    <RowStack gap={'16px'}>
      <SearchTextField
        placeholder={t('filters.name')}
        value={search}
        onChange={(e) => {
          setSearchInput(e.target.value);
          setDebounceSearch(e.target.value);
        }}
      />
      <OrganizationUnitSelect
        isLoading={isLoading}
        tags={1}
        multiple={true}
        onChange={(organizationUnitId) =>
          setOrganizationUnitId(organizationUnitId as string[])
        }
        defaultValue={defaultOrganizationUnitData}
        organizationUnitId={user?.organizationUnitGuid}
      />
      {/*//TODO - implement logic with default filter criteria. Check - permissions and kpi filters inside dashboard*/}
      <UserSelect
        placeholder={t('filters.assignedPerson')}
        organizationId={companyId}
        organisationUnitIds={organizationUnitIds}
        onChange={(id) => setAssignedPersonId(id)}
        width="219px"
        onlyAvailableUnits
      />
      <UnitSelect
        onChange={(unit) => setSelectedUnit(unit)}
        sx={{ width: '138px' }}
      />
    </RowStack>
  );
};
