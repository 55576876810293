import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCalculateReport } from '@kaizen/pages/reports/hooks';

import { Box, styled } from '@mui/material';

import { BarChart } from '@core/components';
import { ReportPieChart } from '@core/components/Statistica/ReportPieChart/ReportPieChart';

import { KaizenWidgetPreview } from '../KaizenWidgetPreview/KaizenWidgetPreview';
import { KaizenPreviewWidgetType } from '../KaizenWidgetPreview/KaizenWidgetPreview.enum';
import { useKaizenReport } from '../hooks/useKaizenReport';

export const KaizenSidebarView = () => {
  const { kaizenReport, isLoading } = useKaizenReport();

  const [isOpen, setIsOpen] = useState(false);

  const [previewType, setPreviewType] = useState<KaizenPreviewWidgetType>(
    KaizenPreviewWidgetType.BAR_CHART,
  );

  const openModal = (type: KaizenPreviewWidgetType) => {
    setIsOpen(true);
    setPreviewType(type);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const { t } = useTranslation('kaizen');

  const { formatPieChartData, formatBarChartData } =
    useCalculateReport(kaizenReport);

  return (
    <Container>
      <Box onClick={() => openModal(KaizenPreviewWidgetType.PIE_CHART)}>
        <ReportPieChart
          values={formatPieChartData}
          isLoading={isLoading}
          title={t('report.submissionCount')}
          emptyMessage={t('report.noData')}
          width={'100%'}
        />
      </Box>
      <Box onClick={() => openModal(KaizenPreviewWidgetType.BAR_CHART)}>
        <BarChart
          isLoading={isLoading}
          series={formatBarChartData}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: 0,
            },
          }}
          grid={{ horizontal: true }}
          bottomAxis={null}
          xAxis={[{ scaleType: 'band', data: [t('report.submissionStatus')] }]}
          cardSx={{
            padding: 0,
            border: 'none',
            margin: 0,
            boxShadow: 'none',
            maxHeight: 'fit-content',
            gap: 0,
            backgroundColor: 'transparent',
          }}
          fixedWidth={500}
        />
      </Box>
      <KaizenWidgetPreview
        isOpen={isOpen}
        closeModal={closeModal}
        formatBarChartData={formatBarChartData}
        formatPiechart={formatPieChartData}
        previewType={previewType}
      />
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: 15,
});
