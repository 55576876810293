/* eslint-disable @typescript-eslint/no-misused-promises */
import { captureException } from '@sentry/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box, Typography, styled, useTheme } from '@mui/material';

import { useDeleteSingleOrganizationMutation } from '@/__generated__/graphql';
import { CustomModal, ModalContainer } from '@/core/components';
import { CustomTextField } from '@/core/styles';

import { DeleteOrganizationModalProps } from './OrganizationComponents.types';
import { deleteOrganizationSchema } from './schema';

export const DeleteOrganizationModal = ({
  open,
  closeModal,
  organization,
}: DeleteOrganizationModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [deleteOrganization] = useDeleteSingleOrganizationMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { organizationName: '' },
    resolver: zodResolver(deleteOrganizationSchema(organization.name)),
  });

  const handleDeleteOrganization: SubmitHandler<{
    organizationName: string;
  }> = async () => {
    try {
      const res = await deleteOrganization({
        variables: {
          input: {
            id: organization.id,
          },
        },
      });
      if (res.data?.removeOrganisation.success) closeModal(true);
      else closeModal();
    } catch (e) {
      captureException(e);
    }
  };

  return (
    <CustomModal display={open}>
      <ModalContainer
        sx={{ width: '528px' }}
        closeModal={closeModal}
        headerText={t('organization.deleteOrganization')}
      >
        <form onSubmit={handleSubmit(handleDeleteOrganization)}>
          <Content>
            <Typography fontSize={14} style={{ userSelect: 'none' }}>
              <Trans
                i18nKey="organization.deleteWarning"
                values={{
                  name: organization.name,
                }}
              ></Trans>
            </Typography>
            <CustomTextField
              label={t('organization.organizationName')}
              size="small"
              fullWidth
              error={!!errors.organizationName}
              helperText={errors.organizationName?.message}
              {...register('organizationName')}
            />
          </Content>

          <FormContainer>
            <PrimaryButton
              sx={{
                color: theme.palette.common.black,
              }}
              onClick={() => closeModal()}
            >
              <Typography fontSize={14}>{t('common.cancel')}</Typography>
            </PrimaryButton>

            <PrimaryButton
              data-testid="addButtonModal"
              variant="contained"
              sx={{
                color: theme.palette.common.white,
                backgroundColor: theme.palette.error[600],
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ':hover': {
                  backgroundColor: theme.palette.error[700],
                },
              }}
              type="submit"
            >
              <Typography fontSize={14}>
                {t('organization.deleteOrganization')}
              </Typography>
            </PrimaryButton>
          </FormContainer>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};

const Content = styled(Box)({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'center',
});
const FormContainer = styled(Box)({
  padding: '16px 24px 24px 24px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
  flexDirection: 'row',
});
