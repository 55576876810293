import { omit } from 'lodash';

import SplitscreenIcon from '@mui/icons-material/Splitscreen';

import { SplitScreenButtonTypes } from '@core/components/Buttons/SplitScreenButton/SplitScreenButton.types.ts';

import { theme } from '@/theme';

import { Button } from '../../Button/Button';

export const SplitScreenButton = (props: SplitScreenButtonTypes) => {
  return (
    <Button
      textVariant="bodyMedium"
      backgroundColor={theme.palette.common.white}
      textColor={theme.palette.common.black}
      {...omit(props, 'text')}
    >
      <SplitscreenIcon
        sx={{
          transform: 'rotate(90deg)',
        }}
      />
      {props.text}
    </Button>
  );
};
