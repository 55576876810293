import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { confirmSignIn } from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box, Typography, styled, useTheme } from '@mui/material';

import {
  AuthScreenWrapper,
  BackButton,
  Button,
  PasswordTextField,
} from '@/core/components';
import { useLoginStatusStore } from '@/stores';
import { newPasswordValidationSchema } from '@/utils';

export const SetNewPassword = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [values] = useState({
    password: '',
    repeatPassword: '',
  });
  const navigate = useNavigate();
  const { status } = useLoginStatusStore();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: values,
    values,
    resolver: zodResolver(
      newPasswordValidationSchema({
        passwordRequired: t('login.passwordRequired'),
        passwordDonMatch: t('errors.passwordsDontMatch'),
        passwordInvalid: t('login.passwordValidation'),
      }),
    ),
  });

  useEffect(() => {
    if (status !== 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED')
      navigate('/login');
  }, [navigate, status]);

  const onSubmit: SubmitHandler<typeof values> = useCallback(
    async (data) => {
      await confirmSignIn({
        challengeResponse: data.password,
      });

      navigate('/login/password-confirmation');
    },
    [navigate],
  );

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <AuthScreenWrapper gap="32px">
        <TitleContainer>
          <BackButton href="/login" />
          <Typography variant="h3">{t('login.setUpNewPassword')}</Typography>
          <Typography
            variant="bodyRegular"
            color={theme.palette.text.secondary}
          >
            {t('login.setUpNewPasswordDescription')}
          </Typography>
        </TitleContainer>

        <PasswordTextField
          error={!!errors.password}
          label={t('common.password')}
          helperText={t('login.passwordRequirements')}
          {...register('password')}
        />

        <PasswordTextField
          error={!!errors.repeatPassword}
          helperText={errors.repeatPassword?.message}
          label={t('common.repeatPassword')}
          {...register('repeatPassword')}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '100%' }}
          type="submit"
        >
          {t('login.setUpNewPassword')}
        </Button>
      </AuthScreenWrapper>
    </form>
  );
};

const TitleContainer = styled(Box)({
  marginTop: '-16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
});
