import { useTranslation } from 'react-i18next';
import { EflowCommentModel } from '@/__generated__/graphql';

export const useBasicComments = (): EflowCommentModel[] => {
  const { t } = useTranslation('eflow');
  return [
    { categoryName: t('newAnalysis.comments.categories.Q'), symbol: 'Q' },
    { categoryName: t('newAnalysis.comments.categories.D'), symbol: 'D' },
    { categoryName: t('newAnalysis.comments.categories.C'), symbol: 'C' },
    { categoryName: t('newAnalysis.comments.categories.I'), symbol: 'I' },
    { categoryName: t('newAnalysis.comments.categories.S'), symbol: 'S' },
  ];
};
