import { useTranslation } from 'react-i18next';

import { Fade } from '@mui/material';

import { AvatarMenu } from '@core/components';
import { NotificationsComponent } from '@core/modules/Notifications/components/NotificationsComponent';
import { CustomTooltip, NavbarUserMenu } from '@core/styles';

export const NavbarUserMenuComponent = () => {
  const { t } = useTranslation();

  return (
    <NavbarUserMenu>
      <CustomTooltip
        title={t('notification.title')}
        placement="bottom-start"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
      >
        <NotificationsComponent />
      </CustomTooltip>

      <AvatarMenu />
    </NavbarUserMenu>
  );
};
