import { useCallback, useEffect, useState } from 'react';

import { PeriodValueEnum } from '@kpi/common/periodValues.enum';
import { useFetchSettlementPeriods } from '@kpi/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/Functions';
import { useDebounceValue } from 'usehooks-ts';

import { useCompanyId, useGetCurrentUserDetails } from '@core/hooks';

import {
  UnitKpiRateInput,
  useFindOrganizationSubunitsLazyQuery,
} from '@/__generated__/graphql';

export const useKpiDashboardFilters = () => {
  const { settlementPeriods } = useFetchSettlementPeriods();
  const { user, isLoading } = useGetCurrentUserDetails();

  const [search, setSearch] = useState('');
  const [isOrganizationUnitLoading, setIsOrganizationUnitLoading] =
    useState(true);
  const [assignedPersonId, setAssignedPersonId] = useState('');
  const [organizationUnitId, setOrganizationUnitId] = useState<string[]>([]);
  const [organizationUnitName, setOrganizationUnitName] = useState<string>('');
  const [organizationUnitValue, setOrganizationUnitValue] = useState<
    { id: string; name: string }[]
  >([]);
  const [selectedUnit, setSelectedUnit] = useState<
    UnitKpiRateInput | undefined
  >();
  const { companyId } = useCompanyId();
  const [yearPeriodValue, setYearPeriodValue] = useState(PeriodValueEnum.YEAR);
  const [periodId, setPeriodId] = useState('');

  const [debounceSearch, setDebounceSearch] = useDebounceValue('', 500);

  const [isFirstTime, setFirstTime] = useState(true);

  const [loadOrganizationUnits, { data }] =
    useFindOrganizationSubunitsLazyQuery();

  const loadData = async () => {
    if (!companyId) return;

    await loadOrganizationUnits({
      variables: {
        input: {
          organizationId: companyId,
          ...(user?.organizationUnitGuid && {
            organizationUnitId: user.organizationUnitGuid,
          }),
        },
      },
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setIsOrganizationUnitLoading(true);
      void loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, isLoading]);

  useEffect(() => {
    if (data) {
      if (
        data?.findOrganizationSubunits.rootUnit.id ===
        user?.organizationUnitGuid
      ) {
        setOrganizationUnitId([user?.organizationUnitGuid]);
        setOrganizationUnitName(data?.findOrganizationSubunits.rootUnit.name);
        setOrganizationUnitValue([
          {
            id: user?.organizationUnitGuid,
            name: data?.findOrganizationSubunits.rootUnit.name,
          },
        ]);
        setIsOrganizationUnitLoading(false);
      } else {
        data?.findOrganizationSubunits.subunits.forEach((unit) => {
          if (unit.id === user?.organizationUnitGuid) {
            setOrganizationUnitId([user?.organizationUnitGuid]);
            setOrganizationUnitName(unit.name);
            setOrganizationUnitValue([
              { id: user?.organizationUnitGuid, name: unit.name },
            ]);
            setIsOrganizationUnitLoading(false);
          }
        });
      }
    }
  }, [data, user?.organizationUnitGuid]);

  useEffect(() => {
    const activePeriodId = settlementPeriods.find(
      (period) => period.isActive,
    )?.id;
    if (activePeriodId) setPeriodId(activePeriodId);
  }, [settlementPeriods]);

  const setSearchInput = useCallback((value: string) => {
    setSearch(value);
  }, []);

  useEffect(() => {
    if (user && isFirstTime) setFirstTime(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUnitId]);

  return {
    isOrganizationUnitLoading,
    organizationUnitValue,
    debounceSearch,
    search,
    assignedPersonId,
    organizationUnitId,
    organizationUnitName,
    selectedUnit,
    yearPeriodValue,
    settlementPeriods,
    periodId,
    setSearchInput,
    setAssignedPersonId,
    setDebounceSearch,
    setOrganizationUnitId,
    setSelectedUnit,
    setYearPeriodValue,
    setPeriodId,
    isFirstTime,
  };
};
