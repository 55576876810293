import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FindAllSubmissionsInput,
  useFindAllSubmissionsLazyQuery,
} from '@/__generated__/graphql';
import { RemoveItemModal } from '@/core/components';
import { useSnackbarContext } from '@/core/contexts';
import { useCompanyId, useTablePagination } from '@/core/hooks';
import { FindAllSubmissionsQueryBuilder } from '@/pages/kaizen/pages/dashboard/builders/FinaAllSubmissionsQueryBuilder';

import { useKaizenDashboardFiltersContext } from '../Contexts/useKaizenSubmissionTable.context';
import { useRemoveKaizenSubmission } from './useRemoveKaizenSubmission';

export const useSubmissionTable = () => {
  const { t } = useTranslation('kaizen');
  const { t: commonT } = useTranslation();

  const { cursor, setCursor, rowsPerPage, setRowsPerPage } =
    useTablePagination();

  const { openSnackbar } = useSnackbarContext();

  const [currentQueryInput, setCurrentQueryInput] =
    useState<FindAllSubmissionsInput>();

  const {
    isModalVisible,
    handleCancel,
    handleClickRemove,
    handleRemoveSubmission,
  } = useRemoveKaizenSubmission();

  const { companyId } = useCompanyId();

  const {
    areFiltersDefault,
    searchDateValue,
    searchStatuses,
    submissionPeriodId,
    isAssignedToMe,
    debouncedSearchValue,
    selectedEmployees,
  } = useKaizenDashboardFiltersContext();

  const [findAllSubmissions, { data, loading: isLoading }] =
    useFindAllSubmissionsLazyQuery();

  useEffect(() => {
    setCursor(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchDateValue,
    searchStatuses,
    submissionPeriodId,
    isAssignedToMe,
    debouncedSearchValue,
    selectedEmployees,
  ]);

  useEffect(() => {
    if (!companyId) return;

    const kaizenSubmissionBuilder = new FindAllSubmissionsQueryBuilder()
      .addCreated(searchDateValue)
      .addIsAssignedToMe(isAssignedToMe)
      .addSearch(debouncedSearchValue)
      .addSettlementPeriodIds(submissionPeriodId)
      .addParticipants(selectedEmployees)
      .addStatuses(searchStatuses);

    const input = {
      organizationId: companyId,
      limit: rowsPerPage,
      cursor,
      ...kaizenSubmissionBuilder.build(),
    };

    setCurrentQueryInput(input);

    void findAllSubmissions({
      variables: {
        input,
      },
      pollInterval: 15000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyId,
    submissionPeriodId,
    debouncedSearchValue,
    searchDateValue,
    isAssignedToMe,
    searchStatuses,
    selectedEmployees,
    rowsPerPage,
    cursor,
  ]);

  return {
    rows: data?.findAllKaizenSubmissions.items || [],
    total: data?.findAllKaizenSubmissions.total || 0,
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,
    isLoading,
    handleClickRemove,
    areFiltersDefault,
    removeModal: () => (
      <RemoveItemModal
        headerText={t('dashboard.removeSubmission')}
        content={t('dashboard.areYouSureToRemoveSubmission')}
        onRemove={() => {
          if (!currentQueryInput)
            return openSnackbar(commonT('common.somethingWentWrong'));
          void handleRemoveSubmission(currentQueryInput);
          handleCancel();
        }}
        closeModal={() => {
          handleCancel();
        }}
        display={isModalVisible}
      />
    ),
  };
};
