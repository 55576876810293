import { useTranslation } from 'react-i18next';

import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

export const TableColumns = () => {
  const { t } = useTranslation('kpi');

  return (
    <TableHead>
      <TableRow>
        <TableCell width={'45%'}>
          <Typography variant="bodyMedium" fontSize={'14px'}>
            {t('report.rate')}
          </Typography>
        </TableCell>
        <TableCell width={'40%'}>
          <Typography variant="bodyMedium" fontSize={'14px'}>
            {t('report.monthValue')}
          </Typography>
        </TableCell>
        <TableCell width={'15%'}>
          <Typography variant="bodyMedium" fontSize={'14px'}>
            {t('report.unit')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
