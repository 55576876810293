import { TFunction } from 'i18next';
import { z } from 'zod';


/**
 * User section schema for zed validation
 * @param t
 * @constructor
 */
export const UserSectionSchema = (t: TFunction) =>
  z.object({
    email: z
      .string()
      .min(1, t('common.fillTheField'))
      .email(t('login.emailValidation')),
    firstName: z
      .string()
      .min(1, t('common.fillTheField'))
      .max(50, t('common.maxCharacters', { count: 50 })),
    lastName: z.string().min(1, t('common.fillTheField')),
    phone: z
      .string()
      .min(1, t('common.fillTheField'))
      .regex(
        new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/),
        t('systemSettings.phoneNumberInvalid'),
      ),
  });