import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { useCheckboxLogic } from './hooks';
import { ModulesTableProps } from './types';

export const ModulesTable = (props: ModulesTableProps) => {
  const { t } = useTranslation();

  const { TooltipMessage, CheckboxDisabled } = useCheckboxLogic();

  return (
    <TableContainer sx={{ width: '768px' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell height={'40px'}>
              <Typography variant="bodyRegular" fontWeight={500}>
                {t('organization.moduleName')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        {props.isLoading ? (
          <Skeleton width={'100%'} height={300} />
        ) : (
          <TableBody>
            {props.modules.map((row) => (
              <TableRow key={row.moduleName}>
                <TableCell align="left" height="48px" width={'48px'}>
                  <Tooltip title={TooltipMessage(row.isActive, row.moduleName)}>
                    <span>
                      <Checkbox
                        onClick={() =>
                          props.handleCheckboxClick(row.moduleName)
                        }
                        color="primary"
                        checked={!!row.isActive}
                        disabled={CheckboxDisabled(row.moduleName)}
                      />
                    </span>
                  </Tooltip>
                </TableCell>

                <TableCell align="left">
                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <img src={row.icon} height={'30px'} alt="logo"></img>
                    &nbsp;&nbsp;
                    <Typography variant="bodyRegular">
                      {t(`tiles.${row.moduleName}`)}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
