import { Box, Typography, styled } from '@mui/material';

import { theme } from '@/theme';

import { CommentProps } from './types';
import { useExtractedCommentValues } from './useExtractedCommentValues';

export const Comment = (props: CommentProps) => {
  const { createdBy, content, analyzeType, eflowStep, commentCategory } =
    useExtractedCommentValues(props.data);

  return (
    <CommentContainer>
      <CommentHeader>
        <Typography variant="captionRegular">
          {createdBy}, {analyzeType}, {commentCategory}, {eflowStep}
        </Typography>
      </CommentHeader>
      <Typography variant="bodyMedium">{content}</Typography>
    </CommentContainer>
  );
};

const CommentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: theme.palette.grey[50],
  marginBottom: '16px',
});

const CommentHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginBottom: '8px',
  color: theme.palette.grey[500],
});
