import { TreeNode } from 'react-organizational-chart';

import { OrganizationNode } from '@core/types';

import NodeCell from './NodeCell/NodeCell';
import { TreeDetailsInterface } from './OrganizationTree.types';

/**
 *
 * @param node specific node
 * @param level_id
 * @description it render single tree node
 */

export default function RenderNode(
  node: OrganizationNode,
  level_id: number,
  details: TreeDetailsInterface,
) {
  return (
    <TreeNode
      key={node.name}
      label={
        <NodeCell
          {...node}
          members={node.members}
          level_id={level_id}
          details={details}
        />
      }
    >
      {node.children?.map((child) => RenderNode(child, level_id + 1, details))}
    </TreeNode>
  );
}
