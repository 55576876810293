import { useTranslation } from 'react-i18next';

import { RowStack } from '@organizations/styles';

import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import { ChartTypes } from '@/__generated__/graphql';

import { ChartIconLayout } from '../ChartIconLayout';
import { SelectChartProps } from './SelectChartType.types';

export const SelectChartType = ({ activeChart, onClick }: SelectChartProps) => {
  const { t } = useTranslation('kpi');

  return (
    <RowStack gap="16px">
      <ChartIconLayout
        label={t('charts.bar')}
        icon={<BarChartIcon sx={{ height: '48px', width: '48px' }} />}
        onClick={() => onClick(ChartTypes.BarChart)}
        isActive={activeChart == ChartTypes.BarChart}
      />
      <ChartIconLayout
        label={t('charts.pie')}
        icon={<PieChartIcon sx={{ height: '48px', width: '48px' }} />}
        onClick={() => onClick(ChartTypes.PieChart)}
        isActive={activeChart == ChartTypes.PieChart}
      />
      <ChartIconLayout
        label={t('charts.line')}
        icon={<ShowChartIcon sx={{ height: '48px', width: '48px' }} />}
        onClick={() => onClick(ChartTypes.LineChart)}
        isActive={activeChart == ChartTypes.LineChart}
      />
    </RowStack>
  );
};
