import { OrganizationNode } from '@core/types';

/**
 * @description it has specific node and push his id to array and try fo find all children ids and push it too
 * @param {OrganizationNode} node
 * @param {number[]} idList
 * @returns all ids of given node and his children
 */

export function getAllIds(
  node: OrganizationNode,
  idList: number[] = [],
): number[] {
  idList.push(node.id);
  if (node.children) {
    node.children.forEach((child) => getAllIds(child, idList));
  }
  return idList;
}
