import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { SubmissionDetailsProps } from '../../SubmissionDetails.types';
import { DetailRow, DetailRowLabel } from '../styles/styles';

export const SubmissionIdCellSection = (props: SubmissionDetailsProps) => {
  const { t } = useTranslation('kaizen');
  return (
    <>
      <DetailRow>
        <DetailRowLabel>{t('submissionDetails.id')}</DetailRowLabel>
        <Typography>{props.id}</Typography>
      </DetailRow>
      <DetailRow>
        <DetailRowLabel>{t('submissionDetails.cell')}</DetailRowLabel>
        <Typography textAlign="end">{props.organizationUnit.name}</Typography>
      </DetailRow>
    </>
  );
};
