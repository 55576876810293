import { EflowReportCommentDto } from '@/__generated__/graphql';

export const useExtractedCommentValues = (data: EflowReportCommentDto) => {
  return {
    createdBy: `${data.comment.createdBy.firstName} ${data.comment.createdBy.lastName}`,
    analyzeType: data.analizeType,
    commentCategory: data.comment.commentCategory,
    eflowStep: `${data.stepNumber}. ${data.operationName}`,
    content: data.comment.content,
  };
};
