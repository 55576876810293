import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { Button } from '@core/components';

import { theme } from '@/theme';

import { PreviousButtonTypes } from './PreviousButton.types.ts';

export const PreviousButton = (props: PreviousButtonTypes) => {
  const { t: tCommon } = useTranslation();

  return (
    <Button
      variant={'contained'}
      backgroundColor={theme.palette.grey[200]}
      textColor={theme.palette.text.primary}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <Typography variant="bodyRegular">{tCommon('common.back')}</Typography>
    </Button>
  );
};
