/* eslint-disable @typescript-eslint/no-misused-promises */
import { captureException } from '@sentry/react';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AddUnitAdminModalDefault,
  AddUnitAdminModalDefaultTypes,
} from '@organizations/pages/organizationSettings/content/UnitAdmin/AddUnitAdminModalDefaultTypes.ts';

import { Stack, Typography } from '@mui/material';

import { useCompanyId, useHandleUnitAdmin } from '@core/hooks';

import { SystemRoles } from '@/__generated__/graphql';
import {
  CustomModal,
  InfoModalContent,
  ModalContainer,
  SelectCellModal,
  TextButton,
  UserSelect,
} from '@/core/components';
import { theme } from '@/theme';
import { TreeTarget } from '@/types';

import { AddUnitAdminModalScheme } from './AddUnitAdminModal.scheme';
import { AddUnitAdminProps } from './types';

export const AddUnitAdminModal = ({
  open,
  closeModal,
  addUser,
  setSnackBar,
}: AddUnitAdminProps) => {
  const { t } = useTranslation();

  const { companyId } = useCompanyId();

  const { addUnitAdmin } = useHandleUnitAdmin();

  const [cell, setCell] = useState<TreeTarget>({
    targetId: '',
    path: '',
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddUnitAdminModalDefaultTypes>({
    defaultValues: AddUnitAdminModalDefault,
    resolver: zodResolver(AddUnitAdminModalScheme()),
  });

  useMemo(() => {
    setValue('organizationUnitGuid', cell.targetId);
  }, [cell.targetId, setValue]);

  const onSubmit = async (data: AddUnitAdminModalDefaultTypes) => {
    try {
      const result = await addUnitAdmin(data.userId, cell.targetId);
      if (result.data) {
        setSnackBar(
          t('organizationSettings.unitAdminSection.addUnitAdminConfirmation'),
        );
        addUser(result.data.updateEmployee);
      }
    } catch (error) {
      captureException(error);
    } finally {
      closeModal();
    }
  };

  return (
    <CustomModal display={open}>
      <ModalContainer
        closeModal={closeModal}
        width="528px"
        headerText={t('organizationSettings.unitAdminSection.addUnitAdmin')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <InfoModalContent
            contentText={
              <Stack gap="16px">
                <UserSelect
                  organizationId={companyId}
                  onChange={(userId: string) => {
                    setValue('userId', userId);
                  }}
                  width="100%"
                  systemRoles={[SystemRoles.User]}
                  error={!!errors.userId}
                  helperText={errors.userId?.message}
                />

                <SelectCellModal
                  width={'100%'}
                  value={cell.path}
                  label={t('organizationSettings.unitAdminSection.cell')}
                  setValue={(value: TreeTarget) => {
                    setCell(value);
                  }}
                  error={!!errors.organizationUnitGuid}
                  helperText={errors.organizationUnitGuid?.message}
                />
              </Stack>
            }
          >
            <TextButton
              text={t('common.cancel')}
              width="66px"
              onClick={closeModal}
            />
            <PrimaryButton
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
              type="submit"
            >
              <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
                {t('organizationSettings.unitAdminSection.addNewPermission')}
              </Typography>
            </PrimaryButton>
          </InfoModalContent>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};
