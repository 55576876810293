import { captureException } from '@sentry/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useUpdateSingleSubmissionCache } from '@kaizen/hooks';

import { Stack, Typography } from '@mui/material';

import { useModalContext, useSnackbarContext } from '@core/contexts';

import {
  KaizenSubmissionFragment,
  useAssignSubmissionToRealizationMutation,
} from '@/__generated__/graphql';
import { UserSelect } from '@/core/components';
import { useCompanyId } from '@/core/hooks';

export const useAssignToRealization = () => {
  const { companyId } = useCompanyId();

  const { t: commonT } = useTranslation();
  const { t: kaizenT } = useTranslation('kaizen');

  const { openSnackbar } = useSnackbarContext();

  const params = useParams();

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const [assignSubmissionToRealization, { loading: isLoading }] =
    useAssignSubmissionToRealizationMutation();

  const [selectedUser, setSelectedUser] = useState('');
  const [error, setError] = useState('');

  const {
    setOnSubmit,
    setHeaderText,
    setButtonText,
    setContent,
    handleOpenModal,
  } = useModalContext();

  const modalContext = useMemo(() => {
    return (
      <Stack flexDirection="column" gap="24px">
        <Typography>{kaizenT('realizationModal.description')}</Typography>
        <UserSelect
          error={!!error}
          helperText={error}
          organizationId={companyId}
          onChange={(value) => setSelectedUser(value)}
          placeholder={kaizenT('realizationModal.assignedPerson')}
          width="100%"
        />
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, error]);

  const assignToSubmission = useCallback(async () => {
    if (!selectedUser) {
      setError(commonT('error.requiredField'));
      return false;
    }
    if (!companyId) return false;

    try {
      const { data } = await assignSubmissionToRealization({
        variables: {
          input: {
            organizationId: companyId,
            assigneeId: selectedUser,
            submissionId: params.submissionId!,
          },
        },
      });

      if (data?.addKaizenSubmissionToRealisation) {
        updateSingleSubmissionCache(
          data?.addKaizenSubmissionToRealisation as KaizenSubmissionFragment,
        );

        openSnackbar(kaizenT('updateStatus.successMessage'));
      } else {
        openSnackbar(kaizenT('updateStatus.errorMessage'));
      }
    } catch (e) {
      captureException(e);
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.submissionId, companyId, selectedUser]);

  useEffect(() => {
    setOnSubmit(() => () => void assignToSubmission());
    setHeaderText(kaizenT('realizationModal.title'));
    setContent(modalContext);
    setButtonText(kaizenT('realizationModal.send'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignToSubmission, modalContext]);

  return {
    assignToSubmission,
    isLoading,
    setSelectedUser,
    error,
    handleOpenModal,
  };
};
