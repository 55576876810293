import { useCallback } from 'react';

import { formatKey } from '@core/hooks/useRecharts/functions';

import { RechartVerticalBarChartType } from './RechartVerticalBarChartType.ts';

type ReturnValueEntry = {
  name: string;
  fill: string;
  value: number;
  valueTarget: number;
};

export const useRechartVerticalTooltip = (
  props: RechartVerticalBarChartType,
) => {
  const getLegendInfo = useCallback(() => {
    const { active: isActive, payload } = props;

    if (!isActive || !payload) {
      return [];
    }

    const returnValues: { [key: string]: ReturnValueEntry } = {};

    payload.forEach((payloadEntry) => {
      const isTarget = payloadEntry.dataKey.endsWith('_target');

      if (isTarget) {
        return;
      }

      const key = isTarget
        ? payloadEntry.dataKey.slice(0, -7) //payloadEntry.dataKey.replace('_target', '')
        : payloadEntry.dataKey.slice(0, -6); //payloadEntry.dataKey.replace('_value', '');

      const value = props.untransformedChartValues[formatKey(key, 'value')];

      const targetValue = props.showDataAsPercentage
        ? 100
        : props.untransformedChartValues[formatKey(key, 'target')];

      returnValues[key] = {
        name: payloadEntry.name,
        fill: payloadEntry.fill,
        value: value as number,
        valueTarget: targetValue as number,
      };
    });

    return Object.values(returnValues);
  }, [props]);

  const formatTextWithPercentLabel = (entry: string | number) => {
    if (props.showDataAsPercentage) {
      return `${entry}%`;
    }

    return entry;
  };

  return { getLegendInfo, formatTextWithPercentLabel };
};
