import { useManageKaizenSubmissionFlowButtons } from '@kaizen/hooks';

import { KaizenSubmissionFragment } from '@/__generated__/graphql.ts';

import { ButtonsContainer } from '../styles/styles';

export const ButtonsSection = ({
  submission,
}: {
  submission: KaizenSubmissionFragment;
}) => {
  const { submissionButtons } = useManageKaizenSubmissionFlowButtons(
    submission.status,
    submission.id,
    submission.createdBy.id,
    submission.assignedContractor?.id,
  );

  return <ButtonsContainer>{submissionButtons}</ButtonsContainer>;
};
