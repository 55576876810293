import { ReactNode, createContext, useContext } from 'react';

import { useRankingFilters } from '../hooks/useRankingFilters';

type KaizenDashboardFiltersType = ReturnType<typeof useRankingFilters>;

export const KaizenDashboardFiltersContext =
  createContext<KaizenDashboardFiltersType | null>(null);

export const KaizenRankingFiltersProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const kaizenRankingFilters = useRankingFilters();

  return (
    <KaizenDashboardFiltersContext.Provider value={kaizenRankingFilters}>
      {children}
    </KaizenDashboardFiltersContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useKaizenRankingFiltersContext = () => {
  const context = useContext(KaizenDashboardFiltersContext);

  if (!context) {
    throw new Error(
      'useKaizenDashboardFiltersContext must be used within a KaizenDashboardFiltersProvider',
    );
  }

  return context;
};
