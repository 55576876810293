import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@/core/components';

import { useAssignToRealization } from './hooks/useAssignToRealization';

export const SendToImplementationButton = () => {
  const { t } = useTranslation('kaizen');

  const { isLoading, handleOpenModal } = useAssignToRealization();

  return (
    <>
      <LoadingButton
        variant="contained"
        onClick={handleOpenModal}
        isLoading={isLoading}
      >
        {t('buttons.sendToRealization')}
      </LoadingButton>
    </>
  );
};
