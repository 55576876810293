import { CreateEmployeeInput, SystemRoles } from '@/__generated__/graphql.ts';

export const UserDefaultValues: CreateEmployeeInput = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  organizationId: '',
  organizationUnitGuid: '',
  position: '',
  systemRole: [SystemRoles.KeyUser],
};
