import { Ref, forwardRef } from 'react';

import { Card, Typography } from '@mui/material';

import { ChartWrapperProps } from './ChartWrapper.types';

export const ChartWrapper = forwardRef<HTMLDivElement, ChartWrapperProps>(
  ({ title, children, sx }, ref: Ref<HTMLDivElement>) => {
    return (
      <Card
        ref={ref}
        sx={{
          padding: '24px',
          borderRadius: '8px',
          flex: 1,
          gap: '16px',
          ...(sx || {}),
        }}
      >
        {title && <Typography variant="h4">{title}</Typography>}
        {children}
      </Card>
    );
  },
);
