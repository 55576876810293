import { t } from 'i18next';

import { Download } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import { Button, CustomModal, ModalContainer } from '@/core/components';
import { shortenTitle } from '@/core/utils';

import { OtherPreviewProps } from './types';

export const OtherPreview = (props: OtherPreviewProps) => {
  return (
    <CustomModal display={true}>
      <ModalContainer
        headerText={shortenTitle(props.fileName, 20)}
        closeModal={props.onClose}
        headerPadding="10px 10px 0 10px"
      >
        <Stack padding="10px" gap="10px">
          <Typography>{t('common.cannotDisplayFile')} </Typography>
          <Button
            textVariant="bodyRegular"
            backgroundColor="primary"
            typographySx={{ color: 'white' }}
            onClick={() => {
              props.downloadFile();
              props.onClose();
            }}
            startIcon={<Download />}
            type="button"
          >
            {t('common.download')}
          </Button>
        </Stack>
      </ModalContainer>
    </CustomModal>
  );
};
