/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';

import { SubmissionManagementCommonTypes } from '@kaizen/components/Buttons/SubmissionManagementCommon/SubmissionManagementCommon.types.ts';
import { useSetAsImplementationAccepted } from '@kaizen/hooks';

import { Typography } from '@mui/material';

import { LoadingButton } from '@core/components';
import { useSnackbarContext } from '@core/contexts';

export const SentToImplementationAcceptedButton = ({
  submissionId,
}: SubmissionManagementCommonTypes) => {
  const { t } = useTranslation();
  const { t: kaizenT } = useTranslation('kaizen');

  const { openSnackbar } = useSnackbarContext();

  const { setImplementationAsAccepted, isLoading } =
    useSetAsImplementationAccepted();

  const setImplementationAsAcceptedHandler = async () => {
    if (await setImplementationAsAccepted(submissionId))
      openSnackbar(kaizenT('updateStatus.successMessage'));
    else openSnackbar(kaizenT('updateStatus.errorMessage'));
  };

  return (
    <LoadingButton
      isLoading={isLoading}
      onClick={setImplementationAsAcceptedHandler}
    >
      <Typography>{t('common.finish')}</Typography>
    </LoadingButton>
  );
};
