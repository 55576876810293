import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { useKaizenSettlementPeriodContext } from '@kaizen/module-settings/SettlementPeriods/contexts';

import { Box } from '@mui/material';

import { NoDataInfo } from '@core/components';

export const NoSettlementPeriods = () => {
  const { t: kaizenT } = useTranslation('kaizen');

  const { openCreateSettlementPeriodModal } =
    useKaizenSettlementPeriodContext();

  return (
    <Container>
      <NoDataInfo
        onClick={openCreateSettlementPeriodModal}
        headerText={kaizenT('settlementPeriod.noSettlementPeriods')}
        infoText={kaizenT('settlementPeriod.noSettlementPeriodsInfo')}
        buttonText={kaizenT('settlementPeriod.addSettlementPeriod')}
      />
    </Container>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '50px',
});
