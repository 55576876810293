import { OrganizationNode } from '@core/types';
import { NodeId } from '@/types';

/**
 * Build a checkbox node path, using selected notes and source tree.
 * @param {OrganizationNode} source
 * root tree
 * @param {NodeId[]} selectedNodes list of selected nodes in CheckBoxTree
 *
 * @returns {OrganizationNode|null} Path to the node with the given id or null if not found.
 */
export function buildSelectedTree(
  source: OrganizationNode,
  selectedNodes: NodeId[],
): OrganizationNode | null {
  if (selectedNodes.length === 0) return source;

  if (!source) return null;

  const newNode = { ...source };
  newNode.children = [];

  // Process the children nodes if any.
  if (source.children) {
    for (const child of source.children) {
      const processedChild = buildSelectedTree(child, selectedNodes);
      if (processedChild) {
        newNode.children.push(processedChild);
      }
    }
  }

  // If the node or any of its children are in the selectedNodes list, then we return the newNode.
  if (selectedNodes.includes(source.id) || newNode.children.length > 0) {
    return newNode;
  }

  return null;
}
