import { useTranslation } from 'react-i18next';
import { usePDF } from 'react-to-pdf';

import { useEflowPermissions } from '@e-flow/hooks';
import {
  Comments,
  CreatingReport,
  Navbar,
  OperationDuration,
  ParametersTable,
  ReportHeader,
  ReportSections,
} from '@e-flow/pages/flowReport/components';
import { BalanceChart } from '@e-flow/pages/flowReport/components/BalanceChart/BalanceChart.tsx';
import { OperationNumber } from '@e-flow/pages/flowReport/components/OperationNumber/OperationNumber.tsx';
import { SymbolicMap } from '@e-flow/pages/flowReport/components/SybmolicMap/SymbolicMap.tsx';
import { sortBy } from 'lodash';

import { Box } from '@mui/material';

import {
  EflowReportCommentDto,
  OperationTypesEnum,
} from '@/__generated__/graphql.ts';
import { ErrorContent, FullScreenLoading } from '@/core/components';
import { FlexCenter } from '@/core/styles';

import { useFlowReport } from './hook';

export const FlowReport = () => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { toPDF, targetRef } = usePDF({
    filename: 'page.pdf',
  });
  const { t } = useTranslation('eflow');

  const {
    isLoading,
    error,
    flowId,
    sendQuery,
    calculateAllOperations,
    reportData,
    eflowData,
    createReport,
    isNewReportBeeingCreated,
    setIsNewReportBeeingCreated,
  } = useFlowReport();

  const { isPartOfAnalize } = useEflowPermissions({
    eFlowId: flowId!,
  });

  if (isLoading || isNewReportBeeingCreated)
    return (
      <>
        <Navbar isLoading={isLoading} />
        <FullScreenLoading />
      </>
    );

  if (!isLoading && error?.message?.includes('REPORT_NOT_FOUND'))
    return (
      <>
        <Navbar isLoading={true} />
        <CreatingReport eflowId={flowId!} fetchQuery={sendQuery} />
      </>
    );

  if (!isPartOfAnalize) {
    return (
      <FlexCenter sx={{ height: '100vh' }}>
        <ErrorContent errorCode={'403'} displayCode={'403'} />
      </FlexCenter>
    );
  }

  if (!reportData || !eflowData)
    return (
      <>
        <Navbar
          isLoading={false}
          title={eflowData ? eflowData.name : ''}
          handleRefresh={() =>
            void (async () => {
              await createReport();
              // Temporary solution to show the loading screen
              setIsNewReportBeeingCreated(true);
              setTimeout(() => {
                setIsNewReportBeeingCreated(false);
              }, 10000);
            })()
          }
        />
        <FlexCenter sx={{ height: 'calc(100vh - 58px)' }}>
          {t('newAnalysis.errors.creatingReport')}
        </FlexCenter>
      </>
    );

  return (
    <>
      <Navbar
        isLoading={isLoading}
        handleExport={() => toPDF()}
        status={eflowData.status}
        title={eflowData.name}
        handleRefresh={() =>
          void (async () => {
            await createReport();
            // Temporary solution to show the loading screen
            setIsNewReportBeeingCreated(true);
            setTimeout(() => {
              setIsNewReportBeeingCreated(false);
            }, 10000);
          })()
        }
      />

      <Box ref={targetRef}>
        <ReportHeader eflowData={eflowData} />

        <Box>
          <ReportSections>
            <OperationNumber
              isLoading={isLoading}
              operationsWithAddedValue={
                reportData.operations[OperationTypesEnum.AddedValue] || {
                  asIs: 0,
                  toBe: 0,
                }
              }
              totalOperations={calculateAllOperations}
            />

            {reportData.parameters['TIME'] && (
              <OperationDuration
                isLoading={isLoading}
                type={reportData.parameters['TIME'].type}
                unit={reportData.parameters['TIME'].unit}
                asIs={{
                  totalTime: reportData.parameters['TIME']?.asIs,
                  addedValueDuration:
                    reportData.operationTypesSummary[
                      OperationTypesEnum.AddedValue
                    ]?.['TIME'].asIs || 0,
                }}
                toBe={{
                  totalTime: reportData.parameters['TIME']?.toBe,
                  addedValueDuration:
                    reportData.operationTypesSummary[
                      OperationTypesEnum.AddedValue
                    ]?.['TIME']?.toBe || 0,
                }}
              />
            )}

            <ParametersTable params={reportData.parameters} />
          </ReportSections>
          <BalanceChart eFlowId={flowId!} />
          <SymbolicMap eflowId={flowId!} />
          <Comments
            flowId={flowId!}
            isLoading={isLoading}
            comments={
              (sortBy(
                reportData.comments,
                (comment) => new Date(comment.comment.createdAt),
              ) as EflowReportCommentDto[]) || []
            }
          />
        </Box>
      </Box>
    </>
  );
};
