import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  FindEflowDataQuery,
  FindSingleReportQuery,
  useCreateReportMutation,
  useFindEflowDataLazyQuery,
  useFindSingleReportLazyQuery,
} from '@/__generated__/graphql';
import { useOrganizationStore } from '@/stores';

import { CalculateAllOperations } from '../Logic';
import { CalculateAllOperationsTypes } from '../Logic/CalculateAllOperations/CalculateAlloperations.types';

export const useFlowReport = () => {
  const [isNewReportBeeingCreated, setIsNewReportBeeingCreated] =
    useState(false);

  const { flowId, companyName } = useParams();

  const { organization } = useOrganizationStore();

  const [findQuery, { data, loading: isLoading, error, refetch }] =
    useFindSingleReportLazyQuery();

  const [findEflowDataQuery, { data: findEflowData }] =
    useFindEflowDataLazyQuery();

  const [generateReport] = useCreateReportMutation();

  const calculateAllOperations = useMemo(
    () =>
      CalculateAllOperations(
        data?.findOneEflowReport.operations as CalculateAllOperationsTypes,
      ),
    [data?.findOneEflowReport.operations],
  );

  const sendQuery = () => {
    if (!flowId) return;

    void findQuery({
      variables: {
        input: {
          eFlowId: flowId,
          organizationId: organization[companyName!],
          version: 1,
        },
      },
      pollInterval: 10000,
      fetchPolicy: 'network-only',
    });
    void findEflowDataQuery({
      variables: {
        input: {
          id: flowId,
          organizationId: organization[companyName!],
        },
      },
      pollInterval: 10000,
    });
  };

  const createReport = async () => {
    await generateReport({
      variables: {
        input: {
          organizationId: organization[companyName!],
          eFlowId: flowId!,
          version: 1,
        },
      },
    });
  };

  useEffect(() => {
    sendQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    reportData:
      data?.findOneEflowReport as FindSingleReportQuery['findOneEflowReport'],
    eflowData:
      findEflowData?.findOneEFlow as FindEflowDataQuery['findOneEFlow'],
    refetchSingleReport: refetch,
    isLoading,
    error,
    flowId,
    sendQuery,
    calculateAllOperations,
    createReport,
    setIsNewReportBeeingCreated,
    isNewReportBeeingCreated,
  };
};
