import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { useProblemSolutionProps } from '@kaizen/views/ProblemSolutionView/hooks/useProblemSolution.types.ts';

import { FileModel } from '@/__generated__/graphql';

import { ProblemSolutionSchema } from '../ProblemSolution.schema';

export const useProblemSolution = (props: useProblemSolutionProps) => {
  const [description, setDescription] = useState<string>('');
  const [files, setFiles] = useState<Array<File | FileModel>>([]);

  const { t: kaizenT } = useTranslation('kaizen');

  const getRtfDescriptionLength = (rtfDescription: string) => {
    return JSON.parse(rtfDescription).blocks.reduce(
      (acc: number, block: { text: string }) => acc + block.text.length,
      0,
    ) as number;
  };

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      descriptionLength:
        props.defaultValue.description === ''
          ? 0
          : getRtfDescriptionLength(props.defaultValue.description),
    },
    resolver: zodResolver(ProblemSolutionSchema(kaizenT)),
  });

  useEffect(() => {
    setDescription(props.defaultValue.description);
    setFiles(props.defaultValue.files);
    setValue(
      'descriptionLength',
      props.defaultValue.description === ''
        ? 0
        : getRtfDescriptionLength(props.defaultValue.description),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  const addFiles = (newFiles: File[]) => {
    setFiles((currentFiles) => [...currentFiles, ...newFiles]);
  };

  const onSubmit = () => {
    props.onNextStep(description, files);
  };

  const onPreviousStep = () => {
    props.onPreviousStep(description, files);
  };

  const removeFile = (file: File | FileModel) => {
    setFiles((currentFiles) => currentFiles.filter((f) => f !== file));
  };

  return {
    files,
    setFiles,
    isError: !!errors.descriptionLength,
    errorMessage: errors.descriptionLength?.message,
    onSubmit: handleSubmit(onSubmit),
    addFiles,
    removeFile,
    onPreviousStep,
    setDescription,
    setValue,
    getRtfDescriptionLength,
  };
};
