import { useGetEflowAsIsStepsLazyQuery } from '@/__generated__/graphql.ts';

import { AnalizeSteps, LoadAnalizeSteps } from './LoadSteps.types';

/**
 * Load the to be step steps of the analysis
 * @param organizationId {string} The organization id
 * @param eFlowId {string} The eFlow id
 */
export const useLoadAsIsSteps = ({
  organizationId,
  eFlowId,
}: LoadAnalizeSteps): AnalizeSteps => {
  const [findAllEflowSteps] = useGetEflowAsIsStepsLazyQuery();

  const getSteps = async () => {
    const { data } = await findAllEflowSteps({
      variables: {
        input: {
          id: eFlowId,
          organizationId,
        },
      },
      returnPartialData: true,
      fetchPolicy: 'cache-and-network',
    });

    return {
      steps: data?.findOneEFlow?.asIsSteps || [],
      stepsIds: data?.findOneEFlow?.asIsState || [],
    };
  };

  return {
    getSteps: getSteps,
  };
};
