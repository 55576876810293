import { useEffect, useState } from 'react';

import { omit } from 'lodash';

import type { MenuItemProps } from '@mui/material';
import { MenuItem } from '@mui/material';

import { EmployeeState } from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

type EclipseMenuItemTypes = {
  badgeColor?: string;
} & MenuItemProps;

export const EclipseMenuItem = (props: EclipseMenuItemTypes) => {
  const [badgeColor, setBadgeColor] = useState<string>(
    props.badgeColor || theme.palette.success[500],
  );

  useEffect(() => {
    switch (props.value) {
      case EmployeeState.Active:
        setBadgeColor(theme.palette.success[500]);
        break;
      case EmployeeState.Inactive:
        setBadgeColor(theme.palette.error[500]);
        break;
      case EmployeeState.Fired:
        setBadgeColor(theme.palette.grey[500]);
        break;
    }
  }, [props.value]);

  return (
    <MenuItem {...omit(props, 'badgeColor')}>
      <span
        style={{
          color: badgeColor,
          marginRight: '12px',
        }}
      >
        &#9679;
      </span>
      {props.children}
    </MenuItem>
  );
};
