import { useNavigate, useParams } from 'react-router-dom';

export const useKaizenRedirects = () => {
  const { companyName } = useParams();
  const navigate = useNavigate();

  const submissionsPath = `/${companyName}/kaizen/submissions`;
  const rankingPath = `/${companyName}/kaizen/ranking`;
  const reportPath = `/${companyName}/kaizen/report`;

  const redirectToSingleSubmission = (id: string) => {
    navigate(`/${companyName!}/kaizen/submissions/${id}`);
  };

  const navigateToSubmissionsDashboard = () => navigate(submissionsPath);

  const navigateToAddSubmission = () =>
    navigate(`/${companyName}/kaizen/add-submission`);

  const redirectToSubmissions = () => {
    navigate(submissionsPath);
  };

  const redirectToUpdateSubmission = (id: string) => {
    navigate(`/${companyName}/kaizen/update-submission/${id}`);
  };

  return {
    submissionsPath,
    rankingPath,
    reportPath,
    redirectToSubmissions,
    navigateToAddSubmission,
    redirectToUpdateSubmission,
    redirectToSingleSubmission,
    navigateToSubmissionsDashboard,
  };
};
