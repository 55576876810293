import { OrganizationNode } from '@core/types';
import { bfsSearch } from '@core/utils';
import { GetCheckboxOrganizationUnitStructureQuery } from '@/__generated__/graphql';
import { findAndAddChild } from './findAndAddChild';
import { findAndDeleteChild } from './findAndDeleteChild';


type Tree =
  GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure'];
/**
 *
 * @param newUnit updated Child
 * @returns whole tree with edited node
 * @description function find node then remove from old parent and move it to new parent
 *
 * @example
 * @param tree {id: 0, name: 'zarzad', children: [{id:1, parentGuid: 1, name: 'podzarzad'},{id:2, parentGuid: 0, name: 'podzarzad2'}]}
 * @param newUnit {id:2, parentGuid: 1, name: 'podzarzad2'}
 *
 * @returns wholeTree {id: 0, name: 'zarzad', children: [{id:1, parentGuid: 1, name: 'newpodzarzad', children: [{id:2, parentGuid: 0, name: 'podzarzad2'}]}]}
 */

export const findAndChangeCell = (tree: Tree, newUnit: Tree) => {
  const oldChild = bfsSearch([tree as unknown as OrganizationNode], newUnit.id);

  const treeWithoutOldUnit = findAndDeleteChild(tree, newUnit.id);

  return treeWithoutOldUnit
    ? findAndAddChild(treeWithoutOldUnit, {
        ...newUnit,
        children: oldChild?.children,
      })
    : tree;
};