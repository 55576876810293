import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, styled } from '@mui/material';

import { CustomSnackBar, StepsTabViewWrapper } from '@/core/components';
import { useKaizenRedirects } from '@/core/redirects';

import { useUpdateSubmission } from './hooks/useUpdateSubmission';

export const UpdateSubmission = () => {
  const { t } = useTranslation('kaizen');

  const {
    renderStepChange,
    currentStep,
    steps,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
  } = useUpdateSubmission();

  const { redirectToSingleSubmission } = useKaizenRedirects();

  const { submissionId } = useParams();

  return (
    <>
      <StepsTabViewWrapper
        title={t('submissionModificationView.newSubmission')}
        currentStep={currentStep}
        steps={steps}
        navbarAction={() => redirectToSingleSubmission(submissionId!)}
      >
        <FormContainer>{renderStepChange()}</FormContainer>
      </StepsTabViewWrapper>
      <CustomSnackBar
        open={isSnackBarOpen}
        close={closeSnackbar}
        message={snackBarMessage}
      />
    </>
  );
};

const FormContainer = styled(Box)({
  width: '100%',
  padding: '24px',
  gap: '24px',
});
