import { captureEvent } from '@sentry/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { RowStack } from '@organizations/styles/style.ts';
import { z } from 'zod';

import {
  CircularProgress,
  Skeleton,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import { checkSystemRolesPermission } from '@core/utils';

import { useUpdateOrganizationNameMutation } from '@/__generated__/graphql';
import { SystemRoles } from '@/__generated__/graphql.ts';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee.ts';
import { useUserStore } from '@/stores';
import { theme } from '@/theme';

import { OrganizationNameProps } from './Organization.types';
import { CardStack } from './style';

export const OrganizationNameSection = (props: OrganizationNameProps) => {
  const { t } = useTranslation();
  const { userGroups } = useUserStore();

  const [organizationNameValues, setOrganizationNameValues] = useState<{
    name: string;
    id: string;
  }>(props.organization);
  const [isBeingEdited, setIsBeingEdited] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: organizationNameValues?.name,
    },
    resolver: zodResolver(
      z.object({
        name: z
          .string()
          .min(3, t('errors.minLength.3'))
          .max(50, t('errors.maxLength.50'))
          .refine((value) => value.trim() !== props.organization.name, {
            message: t('organizationSettings.cannotChangeNameToTheSameName'),
          }),
      }),
    ),
  });

  const [updateOrganizationName, { loading: isUpdateLoading }] =
    useUpdateOrganizationNameMutation();

  const onSubmit = async (data: { name: string }) => {
    try {
      const res = await updateOrganizationName({
        variables: {
          input: {
            id: props.organization.id,
            name: data?.name,
          },
        },
      });

      if (res.data?.updateOrganisation)
        setOrganizationNameValues({
          id: organizationNameValues.id,
          name: res.data?.updateOrganisation.name,
        });

      setIsBeingEdited(false);
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = JSON.parse(e.message);
        if (errorMessage?.errorMessage) {
          setError('name', {
            type: 'custom',
            message: t('organizationSettings.organizationNameTaken'),
          });
        }
      }
      captureEvent(e as Error);
    }
  };

  if (!props.organization) return null;
  return (
    <CardStack>
      <RowStack>
        {!isBeingEdited ? (
          <>
            <Typography
              textAlign="left"
              variant="bodyRegular"
              width="160px"
              color={theme.palette.grey[900]}
            >
              {t('organization.organizationName')}
            </Typography>
            {props.isLoading ? (
              <Skeleton width={373} height={40} />
            ) : (
              <Typography variant="bodyRegular" textAlign="left" width="373px">
                {organizationNameValues?.name}
              </Typography>
            )}
          </>
        ) : (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap="24px">
              <Stack flexDirection="row" alignItems="center">
                <Typography
                  textAlign={'left'}
                  variant="bodyRegular"
                  width={'160px'}
                  color={theme.palette.grey[900]}
                >
                  {t('organization.organizationName')}
                </Typography>
                <CustomTextField
                  {...register('name')}
                  size="small"
                  variant="outlined"
                  label={t('organization.organizationName')}
                  defaultValue={organizationNameValues?.name}
                  error={!!errors?.name}
                  helperText={
                    <span
                      style={{
                        position: 'absolute',
                      }}
                    >
                      {errors?.name?.message}
                    </span>
                  }
                />
              </Stack>
              <Stack flexDirection="row" justifyContent="flex-end">
                <PrimaryButton
                  onClick={() => {
                    setIsBeingEdited(false);
                  }}
                >
                  <Typography
                    variant="bodyMedium"
                    fontSize="14px"
                    color={theme.palette.common.black}
                  >
                    {t('common.cancel')}
                  </Typography>
                </PrimaryButton>
                {!isUpdateLoading ? (
                  <PrimaryButton type="submit" variant="contained">
                    <Typography variant="bodyMedium" fontSize="14px">
                      {t('common.save')}
                    </Typography>
                  </PrimaryButton>
                ) : (
                  <CircularProgress />
                )}
              </Stack>
            </Stack>
          </form>
        )}
      </RowStack>
      {checkSystemRolesPermission(userGroups, [
        SystemRoles.SystemAdmin,
        SystemRoles.Root,
      ]) &&
        !isBeingEdited && (
          <PrimaryButton onClick={() => setIsBeingEdited(true)}>
            <Typography
              variant="bodyMedium"
              fontSize="14px"
              color={theme.palette.common.black}
            >
              {t('common.edit')}
            </Typography>
          </PrimaryButton>
        )}
    </CardStack>
  );
};

const CustomTextField = styled(TextField)({
  width: '440px',
});
