import { Divider } from '@mui/material';

import { KpisTable } from './KpisTable';
import { Legend } from './Legend';
import { Options } from './Options';

export const NewChartDataForm = () => {
  return (
    <>
      <KpisTable />
      <Divider />
      <Options />
      <Divider />
      <Legend />
    </>
  );
};
