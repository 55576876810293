import { PickColor } from '@core/hooks/useRecharts/functions/PickColor.ts';
import { ExtractChartKeyType } from '@core/hooks/useRecharts/types';
import { FormatPieChartDataType } from '@core/hooks/useRecharts/types/FormatPieChartData.type.ts';

import { ChartTypes } from '@/__generated__/graphql.ts';

import { getName } from '.';

export function FormatPiechartInAscendingOrdersWithMultiple(
  props: ExtractChartKeyType,
  monthsSet: number[],
  isPercent: boolean,
) {
  const usedColors: string[] = [];

  let summary = 0;

  const sumOfIndicators = props.reduce(
    (previousValue: { [key: string]: number }, currentValue) => {
      if (!monthsSet.includes(+currentValue.name)) {
        return previousValue;
      }

      const newValue = { ...previousValue };

      for (const key in currentValue) {
        if (key === 'name' || key === 'target') {
          continue;
        }

        if (!newValue[key]) {
          newValue[key] = currentValue[key].value;
        } else {
          newValue[key] += currentValue[key].value;
        }

        summary += currentValue[key].value;
      }

      return newValue;
    },
    {} as { [key: string]: number },
  );

  return Object.keys(sumOfIndicators).map((key) => {
    if (isPercent && summary === 0) return null;

    const value = isPercent
      ? Number(((sumOfIndicators[key] / summary) * 100).toFixed(2))
      : sumOfIndicators[key];

    return {
      name: getName(key),
      value,
      fill: PickColor.pickColor(usedColors, ChartTypes.PieChart),
    };
  }) as FormatPieChartDataType[];
}
