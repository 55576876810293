import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CloneToBeStepProps } from '@e-flow/pages/flowDashboard/components/CloneToBeSteps/CloneToBeSteps.types.ts';
import {
  CloneToBeStepsModalOptions,
  CloneToBeStepsModalOptionsEnum,
} from '@e-flow/pages/flowDashboard/components/CloneToBeSteps/CloneToBeStepsModalOptions.tsx';

import {
  RadioModal,
  SingleRadioModalOption,
  TextWithButtons,
} from '@core/components';

import { useCloneEflowStepsMutation } from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

export const CloneToBeSteps = (props: CloneToBeStepProps) => {
  const { t } = useTranslation('eflow');
  const { t: common } = useTranslation();

  const { companyId } = useCompanyId();

  const navigate = useNavigate();

  const [isCloneModalVisible, setIsCloneModalVisible] =
    useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string | number>(
    CloneToBeStepsModalOptionsEnum.CopyOperation,
  );
  const [modalOptions] = useState<SingleRadioModalOption[]>(
    CloneToBeStepsModalOptions(t),
  );

  const [copyOperations] = useCloneEflowStepsMutation();

  const copyAllOperations = async () => {
    await copyOperations({
      variables: {
        input: {
          eFlowId: props.eFlowId,
          organizationId: companyId,
          cloneWholeAnalysis: true,
          desiredEflowStartPosition: 0,
          ...(selectedOptions !==
            CloneToBeStepsModalOptionsEnum.CopyOperationCommentsAndAttachments && {
            propertiesToOmit: ['commentModels', 'files'],
          }),
        },
      },
    });
  };

  return (
    <>
      <TextWithButtons
        title={t('eflow.table.toBe.noOperations')}
        subtitle={t('eflow.table.toBe.beginCreation')}
        primaryButton={{
          text: t('eflow.addOperation'),
          onClick: () => navigate('operations/TO_BE'),
        }}
        secondaryButton={{
          text: t('eflow.table.toBe.copyAll'),

          onClick: () => setIsCloneModalVisible(true),
        }}
      />
      <RadioModal
        display={isCloneModalVisible}
        setSelectedOption={setSelectedOptions}
        selectedOption={selectedOptions}
        options={modalOptions}
        buttonPrimary={{
          onClick: () => {
            setIsCloneModalVisible(false);
          },
          text: common('common.cancel'),
        }}
        buttonSecondary={{
          text: common('common.copy'),
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick: async () => {
            await copyAllOperations();
            props.setCopied(props.numberOfAsIsSteps || 0);
            setIsCloneModalVisible(false);
          },
        }}
        closeModal={() => setIsCloneModalVisible(false)}
        headerText={t('eflow.table.toBe.copyAll')}
      />
    </>
  );
};
