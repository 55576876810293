/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, styled } from '@mui/material';

import { useHandleButtonClick } from '@/core/hooks/useHandleButtonClick/useHandleButtonClick';
import { theme } from '@/theme';

import { Button } from '..';
import { BoxContentProps } from './BoxContent.types.ts';

export const BoxContent: FC<BoxContentProps> = ({
  shadow,
  onNavigateBack,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const handleButtonClick = useHandleButtonClick(onNavigateBack);

  return (
    <Content style={!shadow ? { boxShadow: 'none' } : {}}>
      <Row>
        <img src="/assets/ecps-logo.svg" alt={'Ecps-logo'} />
      </Row>
      <Row>
        <Typography variant={'h3'} color={theme.palette.text.secondary}>
          {errorMessage}
        </Typography>
      </Row>
      <Row>
        <Button variant="contained" color="primary" onClick={handleButtonClick}>
          {t('common.return')}
        </Button>
      </Row>
    </Content>
  );
};

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  width: '100%',
  maxWidth: '528px',
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  gap: '32px',
  boxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
}));

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '16px',
}));
