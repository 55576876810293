/* eslint-disable @typescript-eslint/no-misused-promises */
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddUserModalScheme } from '@e-schema/components/AddUserModal/AddUserModal.scheme.ts';
import { AddUserModalTypes } from '@e-schema/components/AddUserModal/AddUserModal.types.ts';
import { UserDefaultValues } from '@e-schema/components/AddUserModal/UserDefaultValues.ts';
import { PrimaryButton } from '@e-schema/styles/Employee.ts';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Autocomplete,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';

import {
  CreateEmployeeInput,
  NewCreatedSystemEmployeeFragmentDoc,
  SystemRoles,
  useAddUserMutationMutation,
} from '@/__generated__/graphql.ts';
import {
  ModalContainer,
  PhoneTextField,
  SelectCellModal,
} from '@/core/components';
import { useUserStore } from '@/stores';
import type { TreeTarget } from '@/types';

import { SelectPositionInput } from '..';

export const UserModal = ({ closeModal }: AddUserModalTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { userGroups, organizationId } = useUserStore();
  const [permissions, setPermissions] = useState<SystemRoles[]>([]);
  const [tempCell, setTempCell] = useState<TreeTarget>({
    targetId: '',
    path: '',
  });

  const [createUser] = useAddUserMutationMutation();

  useEffect(() => {
    const permissions: SystemRoles[] = [SystemRoles.KeyUser];
    if (userGroups.includes(SystemRoles.Root)) {
      permissions.push(SystemRoles.Administrator);
    }
    setPermissions(permissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: UserDefaultValues,
    resolver: zodResolver(AddUserModalScheme(t, permissions)),
  });

  const onSubmit = async (data: CreateEmployeeInput) => {
    const systemRole = data.systemRole?.includes(SystemRoles.Administrator)
      ? data.systemRole
      : [SystemRoles.KeyUser];
    try {
      await createUser({
        variables: {
          createEmployeeInput: {
            ...data,
            systemRole:
              permissions.length === 1 ? [SystemRoles.KeyUser] : systemRole,
            organizationUnitGuid: tempCell.targetId,
            organizationId: organizationId!,
          },
        },
        update(cache, { data }) {
          //modify ger user cache to add new user
          cache.modify({
            fields: {
              getEmployees(existingEmployees) {
                const newEmployeeRef = cache.writeFragment({
                  data: data?.createEmployee,
                  fragment: NewCreatedSystemEmployeeFragmentDoc,
                });

                return {
                  total: existingEmployees.total + 1,
                  items: [newEmployeeRef, ...existingEmployees.items],
                };
              },
            },
          });
        },
      });
      closeModal();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <ModalContainer
        width="528px"
        closeModal={closeModal}
        headerText={t('systemSettings.addSystemUser')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack padding="24px">
            <Stack gap="24px">
              <Stack justifyContent="space-between" direction="row">
                {/*name*/}
                <TextField
                  {...register('firstName')}
                  sx={{ flex: '0.49' }}
                  size="small"
                  label={t('employees.name')}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
                {/*surname*/}
                <TextField
                  {...register('lastName')}
                  sx={{ flex: '0.49' }}
                  size="small"
                  label={t('employees.lastName')}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              </Stack>
              {/*email*/}
              <TextField
                fullWidth
                {...register('email')}
                size="small"
                label={t('systemSettings.email')}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              {/*phone*/}
              <PhoneTextField
                error={!!errors.phone}
                helperText={errors.phone?.message}
                defaultCountry={'pl'}
                field={{
                  ...register('phone'),
                  value: getValues('phone'),
                  onBlur: () => {},
                  onChange: (e: string) => {
                    setValue('phone', e);
                  },
                }}
                sx={{ width: '0.49' }}
                size="small"
                label={t('systemSettings.phoneNumber')}
              />
              {/*permissions*/}
              <Tooltip
                title={
                  permissions.length === 1 && (
                    <TooltipText>
                      {t('systemSettings.rolesToolTipMessage')}
                    </TooltipText>
                  )
                }
                placement="top"
              >
                <Autocomplete
                  size="small"
                  sx={{ width: '0.49' }}
                  defaultValue={permissions[0]}
                  disabled={permissions.length === 1}
                  options={permissions}
                  getOptionLabel={(option) => option.replaceAll('_', ' ')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register('systemRole')}
                      defaultValue={
                        permissions.length === 1 ? permissions[0] : ''
                      }
                      label={t('systemSettings.permissionTypes')}
                      error={!!errors.systemRole}
                      helperText={errors.systemRole?.message}
                    />
                  )}
                />
              </Tooltip>
              {/*cell*/}
              <SelectCellModal
                value={tempCell.path}
                setValue={(value: TreeTarget) => {
                  setTempCell(value);
                  setValue('organizationUnitGuid', value.targetId);
                }}
                label={t('employees.cell')}
                error={!!errors.organizationUnitGuid}
                helperText={errors.organizationUnitGuid?.message}
              />
              <SelectPositionInput
                organizationId={organizationId}
                {...register('position')}
                error={!!errors.position}
                helperText={errors.position?.message}
              />
            </Stack>
          </Stack>
          {/*buttons*/}
          <StyledStack>
            <PrimaryButton
              sx={{
                color: theme.palette.common.black,
              }}
              onClick={closeModal}
            >
              <Typography>{t('common.cancel')}</Typography>
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
            >
              <Typography>{t('systemSettings.addUser')}</Typography>
            </PrimaryButton>
          </StyledStack>
        </form>
      </ModalContainer>
    </>
  );
};

const StyledStack = styled(Stack)`
  padding: 16px 24px 24px 24px;
  justify-content: flex-end;
  gap: 12px;
  flex-direction: row;
`;

const TooltipText = styled(Typography)({
  fontFamily: 'Inter',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '15px',
});
