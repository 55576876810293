import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography, styled, useTheme } from '@mui/material';

import { NavbarMainContainer } from '@/core/styles';

import type { IProps } from './SimpleNavbar.types';

export const SimpleNavbar = ({ action, title }: IProps) => {
  const theme = useTheme();
  return (
    <NavbarMainContainer
      height="56px"
      sx={{
        paddingLeft: '24px',
        alignContent: 'center',
      }}
    >
      <Nav>
        {action && (
          <IconButton onClick={action}>
            <CloseIcon
              sx={{
                color: theme.palette.grey[900],
              }}
            />
          </IconButton>
        )}
        <Typography variant="bodyRegular" fontSize={14}>
          {title}
        </Typography>
      </Nav>
      <div />
    </NavbarMainContainer>
  );
};

const Nav = styled(Box)({
  display: 'flex',

  alignItems: 'center',
  gap: '16px',

  alignSelf: 'stretch',
});
