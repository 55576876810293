import { OrganizationModules } from '@/__generated__/graphql';

export const getModuleUrl = (moduleName: OrganizationModules) => {
  const link = moduleName.split(/(?=[A-Z])/);
  let newLink = link[0];
  if (newLink == 'E') {
    newLink += '-' + link[1];
  }

  return newLink.toLowerCase();
};

export const sortModules = (modules: OrganizationModules[]) => {
  const schemaModule = modules.find(
    (module) => module === OrganizationModules.ESchema,
  );

  if (!schemaModule) return modules;

  const filteredModules = modules.filter(
    (module) => module !== OrganizationModules.ESchema,
  );

  return [schemaModule, ...filteredModules];
};
