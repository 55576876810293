import { useCallback } from 'react';

import { formatKey } from '@core/hooks/useRecharts/functions';

import { RechartBarChartType } from './RechartBarChart.type';

type ReturnValueEntry = {
  name: string;
  fill: string;
  value: number;
  valueTarget: number;
};

export const useRechartCustomTooltip = (props: RechartBarChartType<never>) => {
  const extractPreviousData = useCallback(() => {
    const { active: isActive, payload, label } = props;

    if (!isActive || !payload) {
      return [];
    }

    if (!props.untransformedChartValues) {
      return [];
    }

    const extractionDataSet = props.untransformedChartValues.find(
      (element) => +element.name === label,
    );

    if (!extractionDataSet) {
      return [];
    }

    const returnValues: { [key: string]: ReturnValueEntry } = {};
    payload.forEach(
      (payloadEntry: { dataKey: string; fill: string; name: string }) => {
        const isTarget = payloadEntry.dataKey.endsWith('_target');

        const key = isTarget
          ? payloadEntry.dataKey.slice(0, -7) //payloadEntry.dataKey.replace('_target', '')
          : payloadEntry.dataKey.slice(0, -6); //payloadEntry.dataKey.replace('_value', '');

        if (extractionDataSet[key]) {
          return;
        }

        const value = extractionDataSet[formatKey(key, 'value')];
        const targetValue = extractionDataSet[formatKey(key, 'target')];

        returnValues[key] = {
          name: payloadEntry.name,
          fill:
            !isTarget || !returnValues[key]
              ? payloadEntry.fill
              : returnValues[key].fill,
          value: value as number,
          valueTarget: targetValue as number,
        };
      },
    );

    return Object.values(returnValues);
  }, [props]);

  return { extractPreviousData };
};
