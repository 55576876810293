import { FC } from 'react';

import { Box } from '@mui/material';

import { ColorBox as ColorBoxType } from './ColorBox.types';

export const ColorBox: FC<ColorBoxType> = ({ variant, color }) => {
  const styles = {
    backgroundColor: color,
    width: '20px',
    height: '20px',
    borderRadius: variant === 'circle' ? '50%' : '20%',
  };

  return <Box sx={styles}></Box>;
};
