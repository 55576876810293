import { useTranslation } from 'react-i18next';

import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

export const SubmissionTableHeader = () => {
  const { t } = useTranslation('kaizen');

  return (
    <TableHead>
      <TableRow>
        <TableCell height={'40px'}>
          <CustomTopography>{t('dashboard.tableHeaders.id')}</CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>
            {t('dashboard.tableHeaders.title')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>
            {t('dashboard.tableHeaders.author')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>
            {t('dashboard.tableHeaders.created')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>
            {t('dashboard.tableHeaders.updated')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>
            {t('dashboard.tableHeaders.status')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>
            {t('dashboard.tableHeaders.assignedPerson')}
          </CustomTopography>
        </TableCell>
        <TableCell>
          <CustomTopography>
            {t('dashboard.tableHeaders.period')}
          </CustomTopography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
});
