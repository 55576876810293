import {
  AddEflowCommentInput,
  EFlowStepCommentDto,
} from '@/__generated__/graphql.ts';

export function formatCommentsBeforeSave(
  organizationId: string,
  createdByModel: { id: string; organizationId: string },
  comments?: (EFlowStepCommentDto | AddEflowCommentInput)[],
): AddEflowCommentInput[] {
  if (!comments) {
    return [];
  }

  const formattedComments = comments.map((comment) => {
    if ('id' in comment) {
      //this means this comment is already saved in the database. Therefore, we do not need to save it again
      return null;
    }

    return {
      commentCategory: comment.commentCategory,
      content: comment.content,
      showInReport: comment.showInReport,
      createdByModel,
      organizationId,
    };
  });

  return formattedComments.filter(
    (comment) => comment !== null,
  ) as AddEflowCommentInput[];
}
