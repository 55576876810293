import { useEffect } from 'react';

import { useFindKaizenSubmissionFlowLazyQuery } from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

export const useKaizenSubmissionFlow = () => {
  const { companyId } = useCompanyId();

  const [findKaizenSubmissionQuery, { data, loading: isQueryLoading }] =
    useFindKaizenSubmissionFlowLazyQuery({
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    });

  useEffect(() => {
    if (!companyId) return;

    void findKaizenSubmissionQuery({
      variables: {
        input: {
          organizationId: companyId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return {
    isLoading: isQueryLoading || !companyId,
    submissionFlow: data?.findKaizenModuleInfo.submissionFlow,
  };
};
