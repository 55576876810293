import { useEffect } from 'react';

import { uniqBy } from 'lodash';

import { useFetchNotifications } from '@core/modules/Notifications/hooks/useFetchNotifications.tsx';
import { useManageNotificationsState } from '@core/modules/Notifications/hooks/useManageNotificationsState.tsx';
import { useNotificationSubscription } from '@core/modules/Notifications/hooks/useNotificationSubscription.tsx';
import { NotificationType } from '@core/modules/Notifications/types/Notifications.types.ts';

export const useNotifications = () => {
  const {
    notifications,
    shouldDisplayDot,
    unOpenedNotifications,
    setNotifications,
    markNotificationAsRead,
  } = useManageNotificationsState();

  const { newNotification } = useNotificationSubscription();

  const {
    total,
    count,
    cursor,
    notifications: staticallyFetchedNotifications,
    setCount,
    setCursor,
  } = useFetchNotifications();

  /**
   * Handle merging of notifications during scroll fetching
   */
  useEffect(() => {
    setNotifications((prev) =>
      uniqBy(
        [...(staticallyFetchedNotifications as NotificationType[]), ...prev],
        'id',
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(staticallyFetchedNotifications)]);

  /**
   * Handle merging of notifications during subscription
   */
  useEffect(() => {
    if (newNotification) {
      setNotifications((prev) =>
        uniqBy([newNotification as NotificationType, ...prev], 'id'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotification]);

  return {
    total,
    count,
    cursor,
    notifications,
    shouldDisplayDot,
    unOpenedNotifications,
    setCount,
    setCursor,
    markNotificationAsRead,
  };
};
