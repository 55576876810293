import { useTranslation } from 'react-i18next';

import { isArray } from 'lodash';

import { Autocomplete, TextField, styled } from '@mui/material';

import { KaizenStatuses } from '@/__generated__/graphql';

import { useSortedStatuses } from '../../hooks';
import { StatusLabel } from '../StatusLabel';
import { StatusSelectProps } from './StatusSelect.types';

export const StatusSelect = ({
  width,
  onSelect,
  multiple = true,
}: StatusSelectProps) => {
  const { t } = useTranslation('kaizen');

  const { statuses } = useSortedStatuses();

  return (
    <CustomAutocomplete
      sx={{ width }}
      multiple={multiple}
      size="small"
      options={statuses}
      disableCloseOnSelect
      limitTags={1}
      getOptionLabel={(option) => t(`statusLabels.${option as KaizenStatuses}`)}
      getLimitTagsText={(more) => `+${more}`}
      onChange={(_e, values) => {
        if (!values) return;
        if (isArray(values)) onSelect(values as KaizenStatuses[]);
        else onSelect([values as KaizenStatuses]);
      }}
      renderOption={(props, option) => {
        const { ...optionProps } = props;
        return (
          <li {...optionProps}>
            <StatusLabel variant={option as KaizenStatuses} />
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={t('dashboard.filters.statusSelect')} />
      )}
    />
  );
};

const CustomAutocomplete = styled(Autocomplete)({
  div: {
    maxHeight: '40px',
  },
});
