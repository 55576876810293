import { ReactNode, createContext, useContext } from 'react';

import { HandleKpiCreateChartModal } from '@kpi/modals';

import { useCreateChart } from '../hooks';

type CreateChartContextProps = ReturnType<typeof useCreateChart>;

const CreateChartContext = createContext<CreateChartContextProps | undefined>(
  undefined,
);

export const HandleChartProvider = ({ children }: { children: ReactNode }) => {
  const CreateChartHookValue = useCreateChart();

  return (
    <CreateChartContext.Provider value={CreateChartHookValue}>
      {children}
      <HandleKpiCreateChartModal />
    </CreateChartContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useCreateChartContext = () => {
  const context = useContext(CreateChartContext);
  if (!context) {
    throw new Error(
      'useCreateChartContext must be used within a HandleChartProvider',
    );
  }
  return context;
};
