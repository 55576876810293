import { useTranslation } from 'react-i18next';

import {
  List,
  ListItemButton,
  Skeleton,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { EmployeeAvatar } from '@/core/components';

import { type UserSettingsSideBarProps } from './UserSettingsSideBar.types.ts';

export const UserSettingsSideBar = (props: UserSettingsSideBarProps) => {
  const { t } = useTranslation();
  if (props.isLoading)
    return (
      <SideBox>
        <RowStack paddingLeft="16px" justifyContent="flex-start" gap="16px">
          <Skeleton variant="circular" width={32} height={32} />
          <Stack flexDirection="column">
            <Skeleton variant="rectangular" height={30} width={200} />
          </Stack>
        </RowStack>
      </SideBox>
    );

  return (
    <SideBox>
      <RowStack paddingLeft="16px" justifyContent="flex-start" gap="16px">
        <EmployeeAvatar
          name={props.employee?.firstName || ''}
          imageUrl={props.employee?.profilePicture}
          size={32}
        />
        <Stack flexDirection="column">
          <Typography variant="bodyMedium">
            {props.employee?.firstName} {props.employee?.lastName}
          </Typography>
          <Typography variant="bodyRegular">{props.employee?.email}</Typography>
        </Stack>
      </RowStack>
      <List sx={{ width: '312px' }}>
        <CustomListItemButton>
          <Typography variant="bodyRegular">
            {t('accountSettings.generalSettings')}
          </Typography>
        </CustomListItemButton>
      </List>
    </SideBox>
  );
};

const SideBox = styled(Stack)({
  width: '360px',
  height: 'calc(100vh - 56px)',
  borderRight: '1px solid var(--neutrals-gray-200, #dcdfe1)',
  padding: '32px 24px',
  gap: '32px',
});
const CustomListItemButton = styled(ListItemButton)({
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const RowStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  height: 40,
});
