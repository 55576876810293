import { Layout } from 'react-grid-layout';

import { SingleWidgetInput } from '@/__generated__/graphql.ts';

export const transformFromLayout = (layout: Layout[]): SingleWidgetInput[] => {
  return layout.map((layoutItem) => {
    return {
      layoutKey: layoutItem.i,
      layoutModel: {
        x: layoutItem.x,
        y: layoutItem.y,
        w: layoutItem.w,
        h: layoutItem.h,
      },
    };
  });
};
