import { useTranslation } from 'react-i18next';

import {
  EmployeeAvatar,
  ShortenTypography,
  UsersIcons,
} from '@/core/components';

import { SubmissionDetailsProps } from '../../SubmissionDetails.types';
import { DetailRow, DetailRowLabel, EmployeeDetailRow } from '../styles/styles';

export const SubmissionAuthorSection = (props: SubmissionDetailsProps) => {
  const { t } = useTranslation('kaizen');

  const authors = [props.createdBy, ...(props.authors || [])];

  return (
    <DetailRow>
      <DetailRowLabel>{t('submissionDetails.submissionAuthor')}</DetailRowLabel>
      <EmployeeDetailRow>
        {authors?.length === 1 ? (
          <>
            <EmployeeAvatar
              name={props.createdBy.firstName}
              imageUrl={props.createdBy.profilePicture}
            />
            <ShortenTypography maxLength={20} textAlign="end">
              {props.createdBy.firstName + ' ' + props.createdBy.lastName}
            </ShortenTypography>
          </>
        ) : (
          <UsersIcons users={authors} />
        )}
      </EmployeeDetailRow>
    </DetailRow>
  );
};
