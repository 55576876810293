import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

import {
  PrepareRecordForSynchronization,
  SynchronizeEflowStepReaderReturnType,
} from '@e-flow/hooks/useSynchronizedFlows/Functions/PrepareRecordForSynchronization.ts';
import { RecordReader } from '@e-flow/hooks/useSynchronizedFlows/Functions/RecordReader.ts';
import { useSynchronizer } from '@e-flow/hooks/useSynchronizedFlows/Functions/Synchronizer.tsx';
import { SynchronizedFlowsActionTypes } from '@e-flow/hooks/useSynchronizedFlows/SynchronizedFlowsAction.enum.ts';
import { useNetworkState } from '@uidotdev/usehooks';
import { isEqual } from 'lodash';

import { useCompanyId, useIndexDB } from '@core/hooks';

import { AsIsToBeNamesEnum } from '@/__generated__/graphql.ts';

import {
  SyncStoreType,
  useSynchronizedFlowsOutput,
} from './useSynchronizedFlows.types.ts';

/**
 * This hook manages synchronization of eFlowSteps.
 * Please make sure it is called only ONCE in the application.
 * otherwise, it will synchronize the same record multiple times.
 * @hook useSynchronizedFlows
 * @description hook used to synchronize eFlowSteps
 * @param withSynchronization - defines if the synchronization should be done
 */
export const useSynchronizedFlows = (
  withSynchronization: boolean = false,
): useSynchronizedFlowsOutput => {
  const { companyId } = useCompanyId();

  const { online: isOnline, effectiveType } = useNetworkState();
  const { synchronizeStep } = useSynchronizer(companyId);

  const [isSyncInProgress, setIsSyncInProgress] = useState(false);
  const [canSynchronize, setCanSynchronize] = useState(false);
  const [isSynchronized, setIsSynchronized] = useState(false);
  const [syncingRecords, setSyncingRecords] = useState(new Set());
  const [isSyncCriticalError, setIsSyncCriticalError] = useState(false);

  const {
    isReady: isSyncDBReady,
    updateElement,
    data: recordsToSynchronize,
    removeElement,
  } = useIndexDB<SyncStoreType>('eFlowSync', 'eFlowSync');

  useEffect(() => {
    if (effectiveType === '2g' || effectiveType === 'slow-2g' || !isOnline) {
      setCanSynchronize(false);
      return;
    }

    if (
      !isOnline ||
      !withSynchronization ||
      isSyncInProgress ||
      companyId === ''
    )
      setCanSynchronize(false);
    else setCanSynchronize(true);
  }, [
    effectiveType,
    isOnline,
    recordsToSynchronize,
    withSynchronization,
    isSyncInProgress,
    companyId,
  ]);

  useEffect(() => {
    setIsSynchronized(Object.keys(recordsToSynchronize).length === 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(recordsToSynchronize)]);

  useEffect(() => {
    if (!canSynchronize || isSyncCriticalError) return;

    void (async () => {
      await reSynchronize();
    })();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSynchronize, isSyncCriticalError]);

  const reSynchronize: useSynchronizedFlowsOutput['reSynchronize'] =
    async () => {
      if (!canSynchronize || isSyncCriticalError) return;
      setIsSyncInProgress(true);
      try {
        for (const [eFlowIdToSync, value] of Object.entries(
          recordsToSynchronize,
        )) {
          const recordReader = new RecordReader(eFlowIdToSync, value);

          if (!recordReader) {
            setCanSynchronize(true);
            setIsSynchronized(false);
            setIsSyncInProgress(false);
          }

          const { asIs, toBe } = await recordReader.readEflowSteps();

          if (asIs.length !== 0) {
            await _synchronizeAnalizeSteps(
              asIs,
              AsIsToBeNamesEnum.AsIs,
              eFlowIdToSync,
            );
          }

          if (toBe.length !== 0) {
            await _synchronizeAnalizeSteps(
              toBe,
              AsIsToBeNamesEnum.ToBe,
              eFlowIdToSync,
            );
          }
        }
      } catch (e) {
        setIsSyncCriticalError(true);
        setCanSynchronize(false);
        captureException(e);
      } finally {
        setIsSyncInProgress(false);
      }
    };

  const pushToSynchronize: useSynchronizedFlowsOutput['pushToSynchronize'] =
    async (
      eFlowId: string,
      eFlowStepId: string,
      actionType: SynchronizedFlowsActionTypes,
      analizeType: AsIsToBeNamesEnum,
    ) => {
      await updateElement(eFlowId, {
        [eFlowStepId]: { actionType, analizeType },
      });
    };

  const _synchronizeAnalizeSteps = async (
    steps: SynchronizeEflowStepReaderReturnType[],
    analizeType: AsIsToBeNamesEnum,
    eFlowId: string,
  ) => {
    for (const step of steps) {
      if (step)
        await _synchronizeRecord(eFlowId, step.eFlowStepId, step, analizeType);
    }
  };

  const _synchronizeRecord = async (
    eFlowId: string,
    eFlowStepId: string,
    readStepFromIdb: Awaited<
      ReturnType<typeof PrepareRecordForSynchronization>
    >,
    analizeType: AsIsToBeNamesEnum,
  ) => {
    if (syncingRecords.has(eFlowStepId)) return;

    const response = await synchronizeStep(
      readStepFromIdb,
      eFlowId,
      analizeType,
    );

    if (response) {
      await _removeFromSynchDatabase(eFlowId, eFlowStepId);
    }

    _removeFromSyncQueue(eFlowStepId);
  };

  const _removeFromSynchDatabase = async (
    eFlowId: string,
    eFlowStepId: string,
  ) => {
    const newRecords = { ...recordsToSynchronize };
    delete newRecords[eFlowId][eFlowStepId];

    if (isEqual(newRecords[eFlowId], {})) {
      await removeElement(eFlowId);
    } else await updateElement(eFlowId, newRecords[eFlowId]);
  };

  const _removeFromSyncQueue = (eFlowStepId: string) => {
    setSyncingRecords((prev) => {
      const newSet = new Set([...prev]);
      newSet.delete(eFlowStepId);
      return newSet;
    });
  };

  return {
    isSynchronized,
    canSynchronize,
    isSyncInProgress,
    isSyncCriticalError,
    isReady: isSyncDBReady,

    reSynchronize,
    pushToSynchronize,
  };
};
