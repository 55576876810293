import { DashboardProvider } from '@core/modules/DashbordModule/contexts/DashboardModule.context';
import { DashboardStateDataProvider } from '@core/modules/DashbordModule/contexts/SetDashboard.context.tsx';

import { DashboardTypesEnum } from '@/__generated__/graphql';

import { HomeDashboard } from '../../HomeDashboard';
import { useUserDashboardContext } from '../../contexts/UserDashboardContext';
import { EmptyDashboard } from '../EmpyDashboard/EmptyDashboard';

export const DashboardContainer = () => {
  const { openWidgetSidebar, companyId, id, organizationUnitId } =
    useUserDashboardContext();
  return (
    <DashboardStateDataProvider>
      <DashboardProvider
        dashboardType={DashboardTypesEnum.UserDashboard}
        dashboardData={{
          type: DashboardTypesEnum.UserDashboard,
          organizationUnitId,
          organizationId: companyId,
          employeeId: id,
        }}
        emptyDashboardComponent={<EmptyDashboard />}
        addOnEmpty={openWidgetSidebar}
      >
        <HomeDashboard />
      </DashboardProvider>
    </DashboardStateDataProvider>
  );
};
