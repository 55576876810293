import { FC } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { KaizenStatuses } from '@/__generated__/graphql.ts';
import { NumberInput } from '@/core/components';
import { StatusLabel } from '@/pages/kaizen/components';

import { EditPointsModalTableRowProps } from './EditPointsModal.types';

export const EditPointsModalTableRow: FC<EditPointsModalTableRowProps> = ({
  status,
  pointsValue,
  setValue,
}) => {
  return (
    <TableRow>
      <TableCell>
        <StatusLabel variant={status as unknown as KaizenStatuses} />
      </TableCell>
      <TableCell>
        <NumberInput
          key={status}
          maxVal={1000}
          onChange={(value) => {
            setValue(status, Number(value));
          }}
          defaultValue={pointsValue}
        />
      </TableCell>
    </TableRow>
  );
};
