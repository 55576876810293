import { useTranslation } from 'react-i18next';

import { GraphIcon } from '@kpi/common/GraphIconComponent/GraphIcon.tsx';
import { useReportSingleKpiModalContext } from '@kpi/contexts/useReportSingleKpiModalContext.tsx';
import { useAddKpiModalContext } from '@kpi/hooks';

import { MoreVert } from '@mui/icons-material';
import { Box, TableRow } from '@mui/material';

import { RemoveItemModal } from '@core/components';
import { IconWrapper } from '@core/components/Icons/Wrapper/IconWrapper';
import { MonthNumberArray } from '@core/constants';
import { useMenu, useModal } from '@core/hooks';

import { useKpiDashboardFiltersContext } from '../context';
import { KpiRateType } from './KpiTable.types';
import { RowMenu } from './RowMenu';
import {
  KpiNameCell,
  KpiTableCell,
  KpiUserCell,
  MonthCellsGroup,
} from './components';
import { filterMonthsByPeriod } from './functions/filter-months';
import { useKpiActions } from './hooks';

export const KpiTableRow = (props: KpiRateType) => {
  const { t: commonT } = useTranslation();
  const { t } = useTranslation('kpi');

  const {
    isModalVisible: isDeleteModalVisible,
    setIsModalVisible: setIsDeleteModalVisible,
  } = useModal();

  const { openReportModal } = useReportSingleKpiModalContext();

  const handleMenuControls = useMenu();
  const { deleteKpiRateToast } = useKpiActions(setIsDeleteModalVisible);
  const { yearPeriodValue } = useKpiDashboardFiltersContext();

  const { relatedChartNames, openAddKpiModal } = useAddKpiModalContext();

  return (
    <>
      <TableRow tabIndex={-1} hover role="checkbox" key={props.id}>
        <KpiNameCell
          kpiName={props.nameCell}
          kpiDescription={props.descriptionCell}
        />
        <KpiTableCell>{props.orgUnitCell}</KpiTableCell>
        <KpiUserCell assignee={props.assigneeCell} />
        {props.data &&
          (
            filterMonthsByPeriod(yearPeriodValue, MonthNumberArray) as number[]
          ).map((monthKey, index) => (
            <MonthCellsGroup
              data={props.data}
              monthKey={monthKey}
              key={props.id + index}
              unit={props.unit}
            />
          ))}
        <KpiTableCell variant={'light'} width={'30px'}>
          <IconWrapper
            sx={{ cursor: 'pointer', backgroundColor: 'white' }}
            icon={<MoreVert />}
            onClick={(e) => handleMenuControls.handleMenuOpen(e)}
            title={commonT('common.more')}
          />
        </KpiTableCell>
        <RowMenu
          {...handleMenuControls}
          kpi={props.kpi}
          openDeleteModal={() => {
            setIsDeleteModalVisible(true);
            openAddKpiModal(props.kpi);
          }}
          openReportModal={() => {
            openReportModal(props.id, props.kpi);
          }}
        />
      </TableRow>

      {/* modal section */}
      <RemoveItemModal
        onRemove={() => {
          void deleteKpiRateToast(props.id);
        }}
        content={
          <Box sx={{ margin: '10px' }}>
            {t('menu.removeKpiCommunicatPt1')}
            {relatedChartNames?.length > 0 ? (
              <>
                {t('menu.removeKpiCommunicatPt2')}
                <GraphIcon relatedChartNames={relatedChartNames} />
                {t('menu.removeKpiCommunicatPt3')}
              </>
            ) : null}
          </Box>
        }
        headerText={t('menu.removeKpiTitle')}
        closeModal={() => setIsDeleteModalVisible(false)}
        display={isDeleteModalVisible}
      />
    </>
  );
};
