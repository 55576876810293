import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Alert, Stack, Typography, styled } from '@mui/material';

import { useUpdateEmployeeMutation } from '@/__generated__/graphql.ts';
import { CustomModal, ModalContainer } from '@/core/components';
import { useOrganizationStore } from '@/stores';
import { TreeTarget } from '@/types';

import { FilterTreeView } from '..';
import { ChangeParentCellModalProps } from './types';

export const ChangeEmployeeCellModal = (props: ChangeParentCellModalProps) => {
  const { t } = useTranslation();
  const { companyName } = useParams();

  const { organization } = useOrganizationStore();

  const [selectedCell, setSelectedCell] = useState<TreeTarget>({
    path: '',
    targetId: '',
  });

  const [changeEmployeeCellMutation] = useUpdateEmployeeMutation();

  const handleChangeCell = () => {
    try {
      void changeEmployeeCellMutation({
        variables: {
          input: {
            id: props.changeEmployeeId,
            organizationId: organization[companyName as string],
            organizationUnitGuid: selectedCell.targetId,
          },
        },
      });
      if (props.setSnackBar) props.setSnackBar(t('structure.cellChanged'));
    } catch (e) {
      Sentry.captureException(e);
      if (props.setSnackBar) props.setSnackBar(t('structure.changeCellError'));
    } finally {
      props.closeModal(true);
    }
  };

  return (
    <CustomModal display={props.open}>
      <ModalContainer
        width="528px"
        height={'500px'}
        closeModal={props.closeModal}
        headerText={t('employees.changeCell')}
      >
        <Stack padding="0 24px 24px 24px" height="350px" gap="24px">
          <Alert severity="info">{t('employees.changeCellInfo')}</Alert>
          <FilterTreeView
            handleSelect={(treeTarget) => setSelectedCell(treeTarget)}
          />
        </Stack>
        <StyledStack>
          <PrimaryButton onClick={() => props.closeModal()}>
            {t('common.cancel')}
          </PrimaryButton>
          <PrimaryButton
            variant="contained"
            disabled={selectedCell.targetId === ''}
            onClick={() => handleChangeCell()}
          >
            <Typography fontSize={14}>{t('common.save')}</Typography>
          </PrimaryButton>
        </StyledStack>
      </ModalContainer>
    </CustomModal>
  );
};

const StyledStack = styled(Stack)({
  padding: '16px 24px 24px 24px',
  justifyContent: 'flex-end',
  gap: '12px',
  flexDirection: 'row',
});
