/**
 * CalculatePages left to display in the table
 * @param rowsPerPage - rows per page
 * @param previousRowsPerPage - previous rows per page
 * @param total - total rows
 * @param page - current page
 *
 * @returns number - new selected page
 *
 * @description Calculate current selected page
 *
 * @example
 *  CalculatePages(10, 10, 100, 1) => 9
 *  CalculatePages(10, 10, 100, 2) => 8
 *
 */
export const CalculatePages = (
  rowsPerPage: number,
  previousRowsPerPage: number | undefined,
  total: number,
  page: number,
) => {
  if (total === 0 || page === 0) return 0;

  const rowsBefore = previousRowsPerPage ?? rowsPerPage;
  const firstElement = page * rowsBefore + 1;
  const newPage = Math.floor(firstElement / rowsPerPage);

  return newPage;
};
