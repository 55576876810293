import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UnitSelect } from '@kpi/components/UnitSelect';
import { RowStack } from '@organizations/styles';

import {
  OrganizationUnitSelect,
  SearchTextField,
  UserSelect,
} from '@core/components';
import { useCompanyId, useGetCurrentUserDetails } from '@core/hooks';
import { checkSystemRolesPermission } from '@core/utils';

import { Employee, SystemRoles } from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

import { PeriodSelect } from '../../SettlementPeriodSelect';
import { useKpiDashboardFiltersContext } from '../context';
import { YearPeriodSelect } from './YearPeriodSelect';

export const KpiFilters = () => {
  const { t } = useTranslation('kpi');
  const { companyId } = useCompanyId();

  const { userGroups } = useUserStore();

  const {
    organizationUnitId,
    isOrganizationUnitLoading,
    setSearchInput,
    search,
    setAssignedPersonId,
    setDebounceSearch,
    setOrganizationUnitId,
    setSelectedUnit,
    yearPeriodValue,
    setYearPeriodValue,
    periodId,
    setPeriodId,
    organizationUnitValue,
    isFirstTime,
  } = useKpiDashboardFiltersContext();

  const { isLoading, user } = useGetCurrentUserDetails();

  const isUser = checkSystemRolesPermission(userGroups, [SystemRoles.User]);

  useEffect(() => {
    if (isUser && user) {
      setAssignedPersonId(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (
    !(
      (organizationUnitValue &&
        organizationUnitValue.length > 0 &&
        organizationUnitValue[0].id !== undefined) ||
      (!isLoading && user === undefined)
    )
  ) {
    return null;
  }

  return (
    <RowStack justifyContent={'space-between'} flexWrap={'wrap'} gap={'10px'}>
      <RowStack gap={'16px'}>
        <SearchTextField
          placeholder={t('filters.name')}
          value={search}
          onChange={(e) => {
            setSearchInput(e.target.value);
            setDebounceSearch(e.target.value);
          }}
        />

        <OrganizationUnitSelect
          isLoading={isOrganizationUnitLoading}
          tags={1}
          multiple={true}
          onChange={(organizationUnitId) =>
            setOrganizationUnitId(organizationUnitId as string[])
          }
          organizationUnitId={user?.organizationUnitGuid}
          {...(organizationUnitValue?.[0]?.id && {
            defaultValue: organizationUnitValue,
          })}
          disabled={isLoading || isUser}
          getOnlySubunits={checkSystemRolesPermission(
            [SystemRoles.User],
            userGroups,
          )}
        />

        <UserSelect
          key={organizationUnitId?.[0] || ''}
          placeholder={t('filters.assignedPerson')}
          organizationId={companyId}
          organisationUnitIds={organizationUnitId}
          onChange={(id) => setAssignedPersonId(id)}
          width="320px"
          defaultUser={user as Employee}
          flag={isFirstTime}
          disabled={isLoading || !organizationUnitId?.length || isUser}
        />

        <UnitSelect
          onChange={(unit) => setSelectedUnit(unit)}
          periodId={periodId}
          sx={{ width: '170px' }}
        />
      </RowStack>

      <RowStack gap={'16px'}>
        <YearPeriodSelect
          value={yearPeriodValue}
          onChange={(period) => setYearPeriodValue(period)}
          width={'123px'}
        />
        <PeriodSelect
          value={periodId}
          width="103px"
          onChange={(periodId) => setPeriodId(periodId)}
        />
      </RowStack>
    </RowStack>
  );
};
