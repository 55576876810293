import { z } from 'zod';

export const phoneValidationSchema = (
  phoneRequired: string,
  phoneInvalid: string,
) =>
  z
    .string()
    .min(11, phoneRequired)
    .regex(
      /^[+][0-9]{2}[\s]?[0-9]{3}[\s]?[0-9]{3}[\s]?[0-9]{3}$/,
      phoneInvalid,
    );
