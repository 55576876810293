import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRejectSubmission } from '@kaizen/hooks';

import { useSnackbarContext } from '@core/contexts';
import { useModal } from '@core/hooks';

export const useRejectButton = () => {
  const { t: kaizenT } = useTranslation('kaizen');

  const { rejectSubmission, isLoading } = useRejectSubmission();

  const { openSnackbar } = useSnackbarContext();

  const { isModalVisible, setIsModalVisible } = useModal();

  const [justification, setJustification] = useState('');
  const [error, setError] = useState('');

  const handleReject = async (submissionId: string) => {
    if (!justification) {
      setError(kaizenT('rejectSubmission.rejectRequired'));
      return;
    }

    setIsModalVisible(false);
    const isSuccess = await rejectSubmission(submissionId, justification);

    if (isSuccess) openSnackbar(kaizenT('updateStatus.successMessage'));
    else openSnackbar(kaizenT('updateStatus.errorMessage'));
  };

  return {
    isLoading,
    isModalVisible,
    setIsModalVisible,
    justification,
    setJustification,
    error,
    setError,
    handleReject,
  };
};
