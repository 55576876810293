import { useTranslation } from 'react-i18next';

import { ProblemSolutionViewTypes } from '@kaizen/views/ProblemSolutionView/ProblemSolutionView.types.ts';
import { ProblemSolutionView } from '@kaizen/views/ProblemSolutionView/components/ProblemSolutionView.tsx';

export const SolutionView = (props: ProblemSolutionViewTypes) => {
  const { t: kaizenT } = useTranslation('kaizen');

  return (
    <ProblemSolutionView
      title={kaizenT('submissionModificationView.solution.name')}
      subtitle={kaizenT('submissionModificationView.solution.label')}
      defaultValue={props.defaultValue}
      onNextStep={props.onMoveToNextStep}
      onPreviousStep={props.onStepBack}
    />
  );
};
