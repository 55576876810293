import ReactCodeInput from 'react-code-input';

import { Alert } from '@mui/material';
import { Stack } from '@mui/system';

import { CodeInputTypes } from '@/core/components/CodeInput/CodeInput.types.ts';
import { theme } from '@/theme';

import './CodeInput.styles.scss';

export const CodeInput = (props: CodeInputTypes) => {
  return (
    <Stack
      sx={{
        width: '100%',
        gap: '16px',
        ...props.styles,
      }}
    >
      <ReactCodeInput
        isValid={!props.errorMessage}
        value={props.value}
        type="number"
        fields={6}
        className={'codeInput'}
        inputMode={'numeric'}
        inputStyleInvalid={{
          borderBottomColor: theme.palette.error[600],
        }}
        {...props}
      />
      {props.errorMessage && (
        <Alert
          style={{
            borderRadius: '8px',
          }}
          severity="error"
        >
          {props.errorMessage}
        </Alert>
      )}
    </Stack>
  );
};
