import { Stack, Typography, styled } from '@mui/material';

export const WrongSearchInfo = ({
  header,
  text,
}: {
  header: string;
  text: string;
}) => {
  return (
    <Container>
      <Typography variant="h4">{header}</Typography>
      <Typography variant="bodyRegular">{text}</Typography>
    </Container>
  );
};
const Container = styled(Stack)({
  padding: '64px 0px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
