import { Tooltip } from '@mui/material';

import { useKaizenRedirects } from '@/core/redirects';
import { shortenTitle } from '@/core/utils';

import { CustomCell, CustomTopography } from './styles';

export const TitleCell = ({
  text,
  submissionId,
}: {
  text: string;
  submissionId: string;
}) => {
  const { redirectToSingleSubmission } = useKaizenRedirects();

  return (
    <CustomCell>
      <Tooltip
        sx={{ cursor: 'pointer' }}
        title={text}
        placement="bottom-start"
        onClick={() => void redirectToSingleSubmission(submissionId)}
      >
        <CustomTopography>{shortenTitle(text, 30)}</CustomTopography>
      </Tooltip>
    </CustomCell>
  );
};
