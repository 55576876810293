import {
  AddEflowCommentInput,
  EFlowStepCommentDto,
  EflowReportComment,
  FindOneEflowStepQuery,
  UpdateEflowStepInput,
  useUpdateEflowStepMutation,
} from '@/__generated__/graphql';
import { formatCommentsBeforeSave } from '@/pages/e-flow/pages/flowSteps/hooks/useEflowSteps/Functions/formatCommentsBeforeSave';
import { useUserStore } from '@/stores';

import { formatCommentModels } from './Functions';
import { UseDetailsProps } from './useDetailsComments.types';

export const useDetailsComments = ({
  stepId,
  eFlowId,
  organizationId,
  stepData,
  setStepData,
}: UseDetailsProps) => {
  const [updateEflowStep] = useUpdateEflowStepMutation();
  const { userUuid } = useUserStore();

  const sendUpdateEflowMutation = async (
    comment: Pick<UpdateEflowStepInput, 'newComments' | 'commentModels'>,
  ) => {
    const res = await updateEflowStep({
      variables: {
        input: {
          id: stepId,
          eFlowId,
          organizationId,
          ...comment,
        },
      },
    });
    setStepData(res.data?.updateEflowStep);
  };

  {
    /** 
        Update comments onChange
        there is blocked trigger when there is no new comments
        */
  }
  const updateComments = (
    comments: (AddEflowCommentInput | EFlowStepCommentDto)[],
  ) => {
    const newComments = formatCommentsBeforeSave(
      organizationId,
      { id: userUuid, organizationId },
      comments,
    );

    if (!newComments.length) return;
    void sendUpdateEflowMutation({
      newComments,
    });
  };

  {
    /**
        Funtion to update comments shown in report
        */
  }

  const updatePinnedComments = (commentModels: EflowReportComment[]) => {
    const comments: EFlowStepCommentDto[] = (
      stepData?.comments as unknown as EFlowStepCommentDto[]
    ).map((comment) => {
      const linkedCommentModel = commentModels.find(
        (model) => model.commentId === comment.id,
      );
      if (!linkedCommentModel) return comment;
      return {
        ...comment,
        showInReport: linkedCommentModel.showInReport,
      };
    });
    void sendUpdateEflowMutation({
      commentModels: formatCommentModels(commentModels),
    });
    setStepData({
      ...(stepData as FindOneEflowStepQuery['findOneEflowStep']),
      comments,
    });
  };
  return {
    updateComments,
    updatePinnedComments,
  };
};
