import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Snackbar, Typography, useTheme } from '@mui/material';

import { CustomModal } from '@/core/components';

import { EmployeeModal } from './EmployeeModal';
import { AddEmployeeModalProps } from './EmployeeModal.types';

export const AddEmployeeModal = ({
  organizationUnitCell,
  onEmployeeAdded,
}: AddEmployeeModalProps) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(
    null,
  );

  return (
    <>
      <PrimaryButton
        data-testid="addButton"
        variant="contained"
        style={{
          backgroundColor: theme.palette.primary[600],
        }}
        onClick={() => setIsShowing(true)}
      >
        <Typography>{t('employees.addEmployee')}</Typography>
      </PrimaryButton>
      <CustomModal display={isShowing}>
        <EmployeeModal
          onEmployeeAdded={onEmployeeAdded}
          closeModal={() => setIsShowing(false)}
          data-testid="employeModal"
          organizationUnitCell={organizationUnitCell}
          openSnackbar={(message: string) => setSnackbarMessage(message)}
        />
      </CustomModal>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => {
          setSnackbarMessage(null);
        }}
        message={snackbarMessage}
      />
    </>
  );
};
