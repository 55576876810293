import { Stack, styled } from '@mui/material';

import { ICustomModalProps } from '.';

export const CustomModal = ({ children, display }: ICustomModalProps) => {
  return (
    <>
      {display ? (
        <ContainerBox>
          <ShadowBox></ShadowBox>
          <ContentBox>{children}</ContentBox>
        </ContainerBox>
      ) : null}
    </>
  );
};

const ContainerBox = styled(Stack)({
  position: 'fixed',
  zIndex: 10,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ShadowBox = styled(Stack)({
  position: 'absolute',
  background: 'black',
  opacity: 0.4,
  width: '100%',
  height: '100%',
});

const ContentBox = styled(Stack)({
  position: 'sticky',
  width: '100%',
  maxWidth: '90vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '90vh',
});
