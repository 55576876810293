import { Outlet } from 'react-router-dom';

import { ReportSingleKpiModalProvider } from '@kpi/contexts/useReportSingleKpiModalContext.tsx';

import { NotificationsProvider } from '@core/modules/Notifications/context/NotificationsContext';

export const LoggedRoutesContextProvider = () => {
  return (
    <ReportSingleKpiModalProvider>
      <NotificationsProvider>
        <Outlet />
      </NotificationsProvider>
    </ReportSingleKpiModalProvider>
  );
};
