import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { StatisticTableTypes } from '@core/components/Statistica/StatisticTable/StatisticTable/StatisticTable.types.ts';

export const StatisticTable = (props: StatisticTableTypes) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {props.tableHeaderTitles.map((label, index) => (
            <TableCell key={index} align={props.headerAlignment}>
              {label ? label : ''}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{props.children}</TableBody>
    </Table>
  );
};
