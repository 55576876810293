import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteCellErrorModal } from '@e-schema/components';
import {
  AddCellModal,
  ChangeCellModal,
  DeleteCellModal,
  EditCellModal,
  MenuItem,
} from '@organizations/components';

import {
  AddOutlined,
  DeleteOutline,
  MoreVertOutlined,
  SwapVertOutlined,
} from '@mui/icons-material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {
  Box,
  IconButton,
  Menu,
  Modal,
  Snackbar,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import { RenderTree } from '@/types';

import { RenderTreeItemProps } from './types';

const RenderTreeItem: FC<RenderTreeItemProps> = ({
  nodes,
  level = 0,
  handleUnitAdded,
  handleUnitDeleted,
  handleUnitEdited,
  handleUnitChanged,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isAddCellModalVisible, setIsAddCellModalVisible] = useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [isDeleteCellModalVisible, setIsDeleteCellModalVisible] =
    useState(false);

  const [isDeleteErrorCellModalVisible, setIsDeleteErrorCellModalVisible] =
    useState(false);

  const [isChangeCellModalVisible, setIsChangeCellModalVisible] =
    useState(false);

  const [isEditCellModalVisible, setIsEditCellModalVisible] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [deleteCellErrorMessage, setDeleteCellErrorMessage] = useState('');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openSnackbar = (message: string) => {
    setIsSnackbarOpen(true);
    setSnackbarMessage(message);
  };

  return (
    <TreeItem
      key={nodes.id}
      itemId={nodes.id}
      style={{ width: '100%' }}
      label={
        <Box
          onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            event.stopPropagation();
          }}
        >
          <Modal open={isAddCellModalVisible}>
            <AddCellModal
              headerText={t('systemSettings.addOrganizationUnit')}
              closeModal={() => setIsAddCellModalVisible(false)}
              nodeId={nodes.id}
              onUnitAdded={(unit) => handleUnitAdded(unit)}
              openSnackbar={openSnackbar}
            />
          </Modal>
          <Modal open={isDeleteCellModalVisible}>
            <DeleteCellModal
              closeModal={() => setIsDeleteCellModalVisible(false)}
              nodeId={nodes.id}
              name={nodes.name}
              onUnitDeleted={handleUnitDeleted}
              openSnackbar={openSnackbar}
              onDeleteError={(type) => {
                setIsDeleteErrorCellModalVisible(true);
                setDeleteCellErrorMessage(
                  t(
                    type === 'organizationUnit'
                      ? 'structure.removeCellModalText'
                      : 'organizationSettings.removeCellEmployeeModalText',
                  ),
                );
              }}
            />
          </Modal>
          <Modal open={isDeleteErrorCellModalVisible}>
            <DeleteCellErrorModal
              closeModal={() => setIsDeleteErrorCellModalVisible(false)}
              errorMessage={deleteCellErrorMessage}
            />
          </Modal>
          <Modal open={isEditCellModalVisible}>
            <EditCellModal
              closeModal={() => setIsEditCellModalVisible(false)}
              openSnackbar={openSnackbar}
              nodeId={nodes.id}
              onUnitEdited={(unit) => handleUnitEdited(unit)}
            />
          </Modal>
          <Modal open={isChangeCellModalVisible}>
            <ChangeCellModal
              nodeId={nodes.id}
              closeModal={() => setIsChangeCellModalVisible(false)}
              openSnackbar={openSnackbar}
              onUnitChanged={(unit) => handleUnitChanged(unit)}
            />
          </Modal>
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={3000}
            onClose={() => {
              setIsSnackbarOpen(false);
            }}
            message={snackbarMessage}
          />
          <MainStack>
            <TableContentTypography variant="bodyRegular">
              {nodes.name}
            </TableContentTypography>
            <Stack gap="8px" flexDirection="row" alignItems="center">
              <TableContentTypography variant="bodyRegular">
                N-{level}
              </TableContentTypography>
              <IconButton onClick={handleClick}>
                <MoreVertOutlined />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  handleClick={() => {
                    setIsEditCellModalVisible(true);
                    handleClose();
                  }}
                  text={t('common.edit')}
                >
                  <ModeEditOutlineOutlinedIcon />
                </MenuItem>
                <MenuItem
                  handleClick={() => {
                    setIsChangeCellModalVisible(true);
                    handleClose();
                  }}
                  text={t('organizationSettings.changeMainCell')}
                  disabled={level === 0}
                >
                  <SwapVertOutlined />
                </MenuItem>
                <MenuItem
                  handleClick={() => {
                    setIsDeleteCellModalVisible(true);
                    handleClose();
                  }}
                  text={t('common.delete')}
                >
                  <DeleteOutline />
                </MenuItem>
                <hr />
                <MenuItem
                  handleClick={() => {
                    setIsAddCellModalVisible(true);
                    handleClose();
                  }}
                  text={t('systemSettings.addOrganizationUnit')}
                >
                  <AddOutlined />
                </MenuItem>
              </Menu>
            </Stack>
          </MainStack>
        </Box>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node: RenderTree) => (
            <RenderTreeItem
              handleUnitAdded={handleUnitAdded}
              key={node.id}
              nodes={node}
              level={level + 1}
              handleUnitDeleted={handleUnitDeleted}
              handleUnitEdited={handleUnitEdited}
              handleUnitChanged={handleUnitChanged}
            />
          ))
        : null}
    </TreeItem>
  );
};

const MainStack = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const TableContentTypography = styled(Typography)({
  fontSize: '14px',
  lineHeight: '28px',
});
export default RenderTreeItem;
