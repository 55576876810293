import { useMemo } from 'react';

import { OperationTypesEnum } from '@/__generated__/graphql';
import { CachePhotos } from '@/core/components/CachePhotos/CachePhotos';

import ADDED_VALUE from '../../images/operations/ADDED_VALUE.svg';
import INSPECTION from '../../images/operations/INSPECTION.svg';
import INSPECTION_TRANSPORT from '../../images/operations/INSPECTION_TRANSPORT.svg';
import OPERATION from '../../images/operations/OPERATION.svg';
import OPERATION_INSPECTION from '../../images/operations/OPERATION_INSPECTION.svg';
import OPERATION_STORAGE from '../../images/operations/OPERATION_STORAGE.svg';
import OPERATION_TRANSPORT from '../../images/operations/OPERATION_TRANSPORT.svg';
import STORAGE from '../../images/operations/STORAGE.svg';
import STORAGE_INSPECTION from '../../images/operations/STORAGE_INSPECTION.svg';
import TRANSPORT from '../../images/operations/TRANSPORT.svg';
import { OperationIconProps } from './OperationIcon.types';

export const OperationIcon = ({
  operation,
  width = '24px',
  height = '24px',
}: OperationIconProps) => {
  const iconToRender = useMemo(() => {
    switch (operation) {
      case OperationTypesEnum.AddedValue:
        return ADDED_VALUE;
      case OperationTypesEnum.Operation:
        return OPERATION;
      case OperationTypesEnum.Inspection:
        return INSPECTION;
      case OperationTypesEnum.InspectionTransport:
        return INSPECTION_TRANSPORT;
      case OperationTypesEnum.OperationInspection:
        return OPERATION_INSPECTION;
      case OperationTypesEnum.OperationStorage:
        return OPERATION_STORAGE;
      case OperationTypesEnum.OperationTransport:
        return OPERATION_TRANSPORT;
      case OperationTypesEnum.Storage:
        return STORAGE;
      case OperationTypesEnum.Transport:
        return TRANSPORT;
      case OperationTypesEnum.StorageInspection:
        return STORAGE_INSPECTION;
      default:
        return null;
    }
  }, [operation]);
  if (iconToRender)
    return (
      <>
        <CachePhotos imageUrls={[iconToRender]} />
        <img
          src={iconToRender}
          alt="Added Value"
          width={width}
          height={height}
        />
      </>
    );

  return null;
};
