/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';

import { useReportModalContext } from '@kpi/contexts';

import { Stack, Typography, styled } from '@mui/material';

import { Button, TextButton } from '@core/components';
import { useReportDataValidation } from '@core/utils';

export const ButtonSection = () => {
  const { t } = useTranslation('kpi');
  const { t: commonT } = useTranslation();
  const { onSubmit, close } = useReportModalContext();
  const { isValid } = useReportDataValidation();

  return (
    <CustomButtonsContainer>
      <TextButton
        text={commonT('common.cancel')}
        width="auto"
        onClick={close}
      />
      <Button variant="contained" onClick={onSubmit} disabled={!isValid}>
        <Typography>{t('report.title')}</Typography>
      </Button>
    </CustomButtonsContainer>
  );
};

const CustomButtonsContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '12px',
});
