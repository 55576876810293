import { Close } from '@mui/icons-material';
import { IconButton, Slide, Snackbar } from '@mui/material';

import { CustomSnackBarProps } from './CustomSnackBar.types';

export const CustomSnackBar = ({
  open,
  message,
  close,
}: CustomSnackBarProps) => {
  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        message={message}
        onClose={close}
        autoHideDuration={5000}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={close}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};
