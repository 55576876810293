import { FC } from 'react';

import { Apps as AppsIcon } from '@mui/icons-material';
import { Box, IconButton, Menu } from '@mui/material';

import { NavbarMenuItems } from '@core/components/NavbarMenuItems';
import { useMenu } from '@core/hooks';

export const NavbarMenu: FC = () => {
  const { anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose } = useMenu();

  return (
    <Box>
      <IconButton
        aria-label="menu"
        onClick={handleMenuOpen}
        size={'large'}
        sx={{
          margin: 0,
          padding: 0,
        }}
      >
        <AppsIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        <NavbarMenuItems />
      </Menu>
    </Box>
  );
};
