import React, { FC, useRef } from 'react';

import { toNumber } from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { BarChartLabel } from '@core/components/Statistica/Labels/BarChartLabel/BarChartLabel.tsx';
import { ChartInput } from '@core/components/Statistica/types/ChartInput.types.ts';
import { useRecharts } from '@core/hooks';
import { setUnitShortcuts } from '@core/utils';

import { EmptyDataset } from '../EmptyDataset/EmptyDataset';
import { RechartBarchartCustomTooltip } from '../Tooltips';

export const RechartBarChart: FC<ChartInput> = (props) => {
  const ref = useRef(null);

  const {
    chartValues,
    chartLegend,
    groupedBarChartValues,
    toMonthLegend,
    renderChartLegend,
  } = useRecharts(props, ref);

  if (props.dataSet.length === 0) {
    return <EmptyDataset />;
  }

  return (
    <ResponsiveContainer width={'100%'} height={'100%'} ref={ref}>
      <BarChart
        stackOffset="sign"
        data={groupedBarChartValues}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          type={'category'}
          interval={0}
          tickFormatter={(value) => toMonthLegend(+value)}
        />
        <YAxis
          tickFormatter={(value: string | number) =>
            setUnitShortcuts(props, value)
          }
        />
        <Tooltip
          cursor={{ fill: 'rgba(0,0,0,0)' }}
          content={(props) => (
            <RechartBarchartCustomTooltip
              active={props.active}
              payload={props.payload as unknown as never}
              label={props.label}
              untransformedChartValues={chartValues}
            />
          )}
        />
        <Legend content={<>{renderChartLegend()}</>} />

        {chartLegend.map((entry) => (
          <Bar
            dataKey={entry.dataKey as string}
            key={entry.dataKey}
            name={entry.name}
            fill={entry.fill}
            {...(entry.stackId && { stackId: entry.stackId })}
            barSize={20}
            {...(entry.isTarget && { opacity: 0.5 })}
          >
            {props.displayOptions.showDataLabels && (
              <LabelList
                dataKey={entry.dataKey}
                position="top"
                content={(contentProps) => (
                  <BarChartLabel
                    x={toNumber(contentProps?.x)}
                    y={toNumber(contentProps?.y)}
                    width={toNumber(contentProps?.width)}
                    height={toNumber(contentProps?.height)}
                    dataKey={entry.dataKey as string}
                    value={contentProps.value as number}
                    unit={props.additionalInfo?.unitShortcut}
                  />
                )}
              />
            )}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
