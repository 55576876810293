import { useTranslation } from 'react-i18next';

import {
  CommonTableRow,
  TitleTypography,
  ValueTypography,
} from './CommonTableRow';

export const SubmitionId = (props: { id?: string }) => {
  const { t } = useTranslation('kaizen');

  return (
    <CommonTableRow>
      <TitleTypography variant="bodyRegular">
        {t('submissionModificationView.summary.table.id')}
      </TitleTypography>
      <ValueTypography variant="bodyRegular">{props.id || '-'}</ValueTypography>
    </CommonTableRow>
  );
};
