import { SyntheticEvent } from 'react';

import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Tooltip,
} from '@mui/material';

import { CheckboxProps } from './Checkbox.types';

export const Checkbox = (props: CheckboxProps) => {
  return (
    <Tooltip title={props.checkboxTooltip || ''} placement={'bottom-start'}>
      <FormControlLabel
        control={
          <MuiCheckbox checked={props.value} disabled={props.disabled} />
        }
        label={props.label}
        onChange={(
          _event: SyntheticEvent<Element, Event>,
          checked: boolean,
        ) => {
          if (props.onChange) props.onChange(_event, checked);
        }}
      />
    </Tooltip>
  );
};
