import { captureException } from '@sentry/react';

import { useUpdateSettlementPeriodCache } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/Functions/useUpdateSettlementPeriodCache.ts';

import { useUpdateSettlementPeriodMutation } from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

export const useUpdateSettlementPeriod = () => {
  const { updateSettlementPeriod: updateSettlementPeriodQuery } =
    useUpdateSettlementPeriodCache();

  const { companyId } = useCompanyId();

  const [updateSettlementPeriodMutation] = useUpdateSettlementPeriodMutation();

  const updateSettlementPeriod = async (
    id: string,
    settlementPeriodName: string,
    isActive: boolean,
  ) => {
    try {
      const { data } = await updateSettlementPeriodMutation({
        variables: {
          input: {
            id: id,
            periodName: settlementPeriodName,
            ...(isActive && { isActive: isActive }),
            organizationId: companyId,
          },
        },
      });

      if (data?.updateSettlementPeriod) {
        updateSettlementPeriodQuery(data.updateSettlementPeriod);
        return true;
      }

      return false;
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  const activateSettlementPeriod = async (id: string) => {
    try {
      const { data } = await updateSettlementPeriodMutation({
        variables: {
          input: {
            id: id,
            isActive: true,
            organizationId: companyId,
          },
        },
      });

      if (data?.updateSettlementPeriod) {
        updateSettlementPeriodQuery(data.updateSettlementPeriod);
        return true;
      }

      return false;
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  return {
    updateSettlementPeriod,
    activateSettlementPeriod,
  };
};
