import { KaizenStatuses } from '@/__generated__/graphql.ts';

export const statusColors: Record<KaizenStatuses, string> = {
  ACCEPTED: '#42526E',
  SUPERVISOR_ASSESSMENT: '#197A8F',
  ADJUSTMENT: '#D7A74F',
  COMMISSION_ASSESSMENT: '#791579',
  DRAFT: '#EBECEE',
  IMPLEMENTATION_ACCEPTED: '#4A71C6',
  IMPLEMENTATION_REJECTED: '#DA7D6C',
  IMPLEMENTED: '#148158',
  IN_PROGRESS: '#D0438F',
  REJECTED: '#AB2B33',
  TODO: '#1FA6A8'
};