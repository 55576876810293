import { useMemo } from 'react';

import { checkSystemRolesPermission } from '@core/utils';

import { DashboardTypesEnum, SystemRoles } from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

export const useDashboardPermissions = (dashboardType: DashboardTypesEnum) => {
  const { userGroups } = useUserStore();

  const canChangeSearchParams = useMemo(() => {
    return !checkSystemRolesPermission(userGroups, [SystemRoles.User]);
  }, [userGroups]);

  const canAddChart = useMemo(() => {
    if (dashboardType === DashboardTypesEnum.KpiOrganizationDashboard) {
      return !checkSystemRolesPermission(userGroups, [
        SystemRoles.User,
        SystemRoles.UnitAdmin,
      ]);
    }

    if (dashboardType === DashboardTypesEnum.KpiEmployeeDashboard) {
      return true;
    }

    if (dashboardType === DashboardTypesEnum.KpiOrganizationUnitDashboard) {
      return !checkSystemRolesPermission(userGroups, [SystemRoles.User]);
    }
  }, [userGroups, dashboardType]);

  const canPinToDashboard = useMemo(() => {
    if (dashboardType === DashboardTypesEnum.KpiOrganizationDashboard) {
      return false;
    }

    return !checkSystemRolesPermission(userGroups, [SystemRoles.User]);
  }, [userGroups, dashboardType]);

  const canHideCharts = useMemo(() => {
    if (dashboardType === DashboardTypesEnum.KpiEmployeeDashboard) {
      return true;
    }
    return !checkSystemRolesPermission(userGroups, [SystemRoles.User]);
  }, [userGroups, dashboardType]);

  return {
    canHideCharts,
    canAddChart,
    canPinToDashboard,
    canChangeSearchParams,
    isOrganizationDashboard:
      dashboardType === DashboardTypesEnum.KpiOrganizationDashboard,
    isOrganizationUnitDashboard:
      dashboardType === DashboardTypesEnum.KpiOrganizationUnitDashboard,
    isEmployeeDashboard:
      dashboardType === DashboardTypesEnum.KpiEmployeeDashboard,
  };
};
