import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomMenuItem } from '@core/components/CustomMenuItem';

import { MenuItemProps } from './MenuItems.types';
import { variants } from './variants';

export const MenuItem: FC<MenuItemProps> = ({
  disabled = false,
  onClick,
  variant,
}) => {
  const { t } = useTranslation();

  const properties = useMemo(() => variants(t)[variant], [t, variant]);
  return (
    <CustomMenuItem
      disabled={disabled}
      onClick={onClick}
      icon={properties.icon}
      text={properties.text}
    />
  );
};
