import { useCallback } from 'react';

import { PickColor, formatKey } from '@core/hooks/useRecharts/functions';

interface VerticalBarChartLabelProps {
  x: number;
  y: number;
  value: number;
  dataKey: string;
  width: number;
  height: number;
  untransformedChartValues: Record<string, number>;
  unit?: string;
}

export const VerticalBarChartLabel = (props: VerticalBarChartLabelProps) => {
  const { x, y, width, height, dataKey, untransformedChartValues } = props;

  const formattedValues = useCallback(() => {
    const isTarget = dataKey.endsWith('_target');

    const key = isTarget
      ? dataKey.slice(0, -7) //payloadEntry.dataKey.replace('_target', '')
      : dataKey.slice(0, -6); //payloadEntry.dataKey.replace('_value', '');

    const value = untransformedChartValues[formatKey(key, 'value')];
    const targetValue = untransformedChartValues[formatKey(key, 'target')];

    if (value >= targetValue && !isTarget) {
      return null;
    }

    return {
      value,
      valueTarget: targetValue,
      fill: untransformedChartValues,
    };
  }, [dataKey, untransformedChartValues]);

  const values = formattedValues();

  if (!values) {
    return null;
  }

  const { value } = values;

  return (
    <g>
      <text
        x={x + width - width * (1.5 / 100)} //calculations are just static. Only meaningful thing is: x + width which chalculates position of bar
        y={y + height / 1.75}
        fill={PickColor.targetColor}
        fontSize={15}
        textAnchor="end"
      >
        {`${value}`}
      </text>
    </g>
  );
};
