import { useTranslation } from 'react-i18next';

import { ModuleContainer } from '@e-flow/pages/flowSteps/components';

import { FilesDropDownInput } from '@/core/components';

import { useAttachments } from '../../hooks';
import { AttachmentsDisplay } from './AttachmentsDisplay';
import { AttachmentModuleProps } from './types';

export const AttachmentsModule = ({
  titleVariant = 'h3',
  ...props
}: AttachmentModuleProps) => {
  const { t } = useTranslation('eflow');

  const { files, handleDelete, handleAddFiles } = useAttachments(props);

  return (
    <ModuleContainer
      title={`${t('flowSteps.attachmentModule.title')} ${props.showNumberOfAttachments ? ` (${props.alreadyUploadedFiles ? props.alreadyUploadedFiles.length : 0})` : ''}`}
      titleVariant={titleVariant}
    >
      {props.onFlowStepChange && (
        <FilesDropDownInput onChange={(newFiles) => handleAddFiles(newFiles)} />
      )}
      <AttachmentsDisplay
        files={files}
        handleDelete={props.removeAlreadyUploadedFiles && handleDelete}
      />
    </ModuleContainer>
  );
};
