import { StatusLabel } from '@kaizen/components';
import { CommentType } from '@kaizen/components/Comment/KazienCommentTypes.ts';

import { Box, Stack, styled } from '@mui/material';

import { NamedAvatar } from '@core/components';

import { KaizenStatuses } from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

export const KaizenComment = (props: CommentType) => {
  return (
    <CommentContainer>
      {/** Comment title 📝 */}
      <CommentTitleStack>
        <Row>
          <NamedAvatar
            firstName={props.createdBy.firstName}
            lastName={props.createdBy.lastName}
            profilePicture={props.createdBy.profilePicture}
            withNameNadSurname={true}
          />
          <StatusLabel
            variant={
              (props.commentCategory as KaizenStatuses) || KaizenStatuses.Todo
            }
          />
        </Row>
      </CommentTitleStack>
      {/** Comment content 📄 */}
      <CommentContent>{props.content}</CommentContent>
    </CommentContainer>
  );
};

const CommentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  borderRadius: 10,
  width: '100%',
});

const CommentContent = styled(Box)({
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.primary,
  fontWeight: 300,
  marginTop: '5px',
  marginBottom: '10px',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  hyphens: 'auto',
  backgroundColor: theme.palette.grey[50],
  width: '100%',
  borderRadius: 24,
  padding: 18,
});

const CommentTitleStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '5px',
  paddingLeft: '18px',
  alignItems: 'center',
});

const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  alignItems: 'center',
});
