import { PickColor } from '@core/hooks/useRecharts/functions/PickColor.ts';
import { ExtractChartKeyType } from '@core/hooks/useRecharts/types';
import { FormatPieChartDataType } from '@core/hooks/useRecharts/types/FormatPieChartData.type.ts';

import { ChartTypes } from '@/__generated__/graphql';

export function FormatPieChartWithAscendingOrderOnSingleDataSet(
  props: ExtractChartKeyType,
  monthsSet: number[],
): FormatPieChartDataType[] {
  const usedColors: string[] = [];
  return props.reduce(
    (previousValue: FormatPieChartDataType[], currentValue) => {
      const valueKey = Object.keys(currentValue).filter(
        (key) => key !== 'name',
      )[0];

      const name = (currentValue as unknown as { name: string })?.name;

      if (!monthsSet.includes(+name)) {
        return previousValue;
      }

      previousValue.push({
        name,
        value: currentValue[valueKey].value,
        fill: PickColor.pickColor(usedColors, ChartTypes.PieChart),
      });

      return previousValue;
    },
    [] as FormatPieChartDataType[],
  );
}
