import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  useGetKpis,
  useManageChartOptions,
  useSelectKpis,
} from '@kpi/modals/HandleKpiChartModal/hooks';
import { z } from 'zod';

import {
  ChartFragment,
  ChartLegendType,
  ChartTypes,
} from '@/__generated__/graphql.ts';

export const useHandleChartValues = () => {
  const [chartType, setChartType] = useState<ChartTypes>(ChartTypes.BarChart);
  const [legendType, setLegendType] = useState<ChartLegendType>(
    ChartLegendType.NoLegend,
  );
  const [chartId, setChartId] = useState<string | undefined>();

  const { t: chartsT } = useTranslation('charts');

  const getKpisHookValues = useGetKpis();

  const selectKpisHookValues = useSelectKpis();

  const manageChartOptionHookValues = useManageChartOptions(
    selectKpisHookValues.selectedKpis,
    chartType,
  );

  const {
    register,
    handleSubmit,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(
      z.object({
        name: z.string().min(5, chartsT('chartNameTooShort')),
      }),
    ),
  });

  const reset = () => {
    selectKpisHookValues.reset();
    manageChartOptionHookValues.reset();
    getKpisHookValues.reset();
    setChartType(ChartTypes.BarChart);
    setLegendType(ChartLegendType.NoLegend);
    resetForm();
  };

  const setValues = (chart: ChartFragment) => {
    setLegendType(chart.displayOptions.legendType);
    setChartType(chart.chartType);

    manageChartOptionHookValues.setCurrentOptions({
      ascendingOrder: !!chart.displayOptions.showDataInAscendingOrder,
      dataAsPercentage: !!chart.displayOptions.showDataAsPercent,
      showTarget: !!chart.displayOptions.showTarget,
      sumIndicators: chart.displayOptions.sumIndicators,
    });

    setValue('name', chart.chartName);
    setChartId(chart.id);
  };

  const networkKpiChartValues = useCallback(() => {
    return {
      kpiIds: selectKpisHookValues.selectedKpis.map((kpi) => kpi.id),
      displayOptions: {
        showDataInAscendingOrder:
          manageChartOptionHookValues.currentOptions.ascendingOrder,
        showDataAsPercent:
          manageChartOptionHookValues.currentOptions.dataAsPercentage,
        showTarget: manageChartOptionHookValues.currentOptions.showTarget,
        ...(manageChartOptionHookValues.currentOptions.sumIndicators && {
          sumIndicators:
            manageChartOptionHookValues.currentOptions.sumIndicators,
        }),

        legendType,
      },
      chartType,
    };
  }, [
    selectKpisHookValues.selectedKpis,
    manageChartOptionHookValues.currentOptions.ascendingOrder,
    manageChartOptionHookValues.currentOptions.dataAsPercentage,
    manageChartOptionHookValues.currentOptions.showTarget,
    manageChartOptionHookValues.currentOptions.sumIndicators,
    legendType,
    chartType,
  ]);

  return {
    chartId,
    register,
    chartType,
    legendType,
    handleSubmit,
    networkKpiChartValues: networkKpiChartValues(),
    setValue,
    setValues,
    setChartType,
    setLegendType,
    ...manageChartOptionHookValues,
    ...selectKpisHookValues,
    reset,
    errors,
    handleSelectChart: (chartType: ChartTypes) => setChartType(chartType),
  };
};
