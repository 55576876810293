import { useState } from 'react';

import { CloseOutlined } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField } from '@mui/material';

import { getLastStructureElement } from '@core/utils';

import { CustomModal } from '@/core/components';
import { theme } from '@/theme';
import { TreeTarget } from '@/types';

import { CellModal } from './CellModal';
import { SelectCellModalProps } from './SelectCellModal.types';

export const SelectCellModal = ({
  value,
  setValue,
  label,
  width = 'auto',
  disabled = false,
  organizationUnit,
  onClear,
  error,
  helperText,
}: SelectCellModalProps) => {
  const [isEmployeeCellModal, setIsEmployeeCellModal] = useState(false);
  const [tempValue, setTempValue] = useState<TreeTarget>(
    organizationUnit || {
      targetId: '',
      path: '',
    },
  );

  const cursor = disabled ? 'not-allowed' : 'pointer';

  // close modal
  const closeModal = () => {
    setIsEmployeeCellModal(false);
  };

  //confirm a selected option and close modal
  const handleConfirm = () => {
    setValue(tempValue);
    closeModal();
  };

  //select option form TreeView
  const handleSelectedItems = (treeTarget: TreeTarget) => {
    setTempValue(treeTarget);
  };

  return (
    <>
      <TextField
        label={label}
        disabled={disabled}
        size="small"
        error={error}
        helperText={helperText}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              {value && onClear ? (
                <CloseOutlined
                  onClick={() => {
                    onClear();
                    setIsEmployeeCellModal(false);
                  }}
                  sx={{
                    cursor,
                    color: theme.palette.text.secondary,
                  }}
                />
              ) : (
                <ArrowDropDownIcon
                  onClick={() => setIsEmployeeCellModal(!disabled)}
                  sx={{ cursor }}
                />
              )}
            </>
          ),
        }}
        value={getLastStructureElement(value || organizationUnit?.path || '')}
        sx={{ width, input: { cursor } }}
        onFocus={() => setIsEmployeeCellModal(!disabled)}
        onClick={() => !disabled && setIsEmployeeCellModal(true)}
      />

      <CustomModal display={isEmployeeCellModal}>
        <CellModal
          handleConfirm={handleConfirm}
          handleSelect={handleSelectedItems}
          handleCloseModal={closeModal}
        />
      </CustomModal>
    </>
  );
};
