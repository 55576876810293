import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useFindSingleSubmissionInfoLazyQuery } from '@/__generated__/graphql';
import { useCompanyId } from '@/core/hooks';

export const useMainSubmissionDashboard = () => {
  const { companyId } = useCompanyId();

  const { submissionId } = useParams();

  const [getSubmission, { data: submissionData, loading: isLoading, error }] =
    useFindSingleSubmissionInfoLazyQuery();

  useEffect(() => {
    if (!(companyId && submissionId)) return;
    void getSubmission({
      variables: {
        input: {
          submissionId,
          organizationId: companyId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, submissionId]);

  return {
    submissionData: submissionData?.findSingleSubmission,
    isLoading,
    error,
  };
};
