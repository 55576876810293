import { useCallback } from 'react';

interface BarChartLabelProps {
  x: number;
  y: number;
  value: number;
  dataKey: string;
  width: number;
  height: number;
  unit?: string;
}

export const BarChartLabel = (props: BarChartLabelProps) => {
  const { x, y, width, dataKey, value } = props;

  const isTarget = useCallback(() => {
    return dataKey.endsWith('_target');
  }, [dataKey]);

  if (isTarget()) {
    return null;
  }

  return (
    <g>
      <text
        x={x + width / 1.5} //calculations are just static. Only meaningful thing is: x + width which chalculates position of bar
        y={y + width / 1.3}
        fill={'#000'}
        fontSize={15}
        textAnchor="left"
        transform={`rotate(-90, ${x}, ${y})`}
      >
        {`${typeof value === 'number' ? value : ''}`}
      </text>
    </g>
  );
};
