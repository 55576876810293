import { useTranslation } from 'react-i18next';

import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';
import { RowStack } from '@organizations/styles';

import { InputAdornment, Switch, TextField, Typography } from '@mui/material';

import { KpiRatingTargetType } from '@/__generated__/graphql.ts';

export const GoalValueTextField = () => {
  const { t } = useTranslation('kpi');

  const {
    useManageKpiModalState: {
      register,
      errors,
      watch,
      toggleTargetType,
      getValues,
    },
  } = useHandleAddKpiRateContext();

  if (!watch('hasTarget')) return null;

  return (
    <>
      <RowStack>
        {watch('targetType') === KpiRatingTargetType.MonthlyCustom ? (
          <TextField
            disabled={true}
            label={t('addKpi.goal')}
            size="small"
            inputMode={'numeric'}
            sx={{ width: '140px', maxHeight: '40px' }}
            {...register(`targetData.1`, {
              setValueAs: (value) => {
                const result = parseFloat(value as string);

                if (isNaN(result)) {
                  return null;
                }

                return result;
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {watch('unit.unitShortcut')}
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            disabled={watch('targetType') === KpiRatingTargetType.MonthlyCustom}
            label={t('addKpi.goal')}
            size="small"
            inputMode={'numeric'}
            sx={{ width: '140px', maxHeight: '40px' }}
            {...register(`targetData.1`, {
              setValueAs: (value) => {
                const result = parseFloat(value as string);

                if (isNaN(result)) {
                  return null;
                }

                return result;
              },
            })}
            defaultValue={getValues('targetData.1')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {watch('unit.unitShortcut')}
                </InputAdornment>
              ),
            }}
            error={
              watch('targetType') !== KpiRatingTargetType.MonthlyCustom &&
              !!errors.targetData?.root
            }
            helperText={
              watch('targetType') !== KpiRatingTargetType.MonthlyCustom &&
              !!errors.targetData?.root && (
                <span>
                  {typeof errors.targetData?.root.message === 'string' &&
                    errors.targetData?.root.message}
                </span>
              )
            }
          />
        )}
        <Switch
          onChange={toggleTargetType}
          checked={watch('targetType') === KpiRatingTargetType.MonthlyCustom}
          defaultChecked={
            watch('targetType') === KpiRatingTargetType.MonthlyCustom
          }
        />
        <Typography variant="bodyRegular">
          {t('addKpi.editMonthlyGoal')}
        </Typography>
      </RowStack>
    </>
  );
};
