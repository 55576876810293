import { TFunction } from 'i18next';

import { Employee } from '@/__generated__/graphql';
import { EmployeeAvatar } from '@/core/components';
import { Row } from '@/core/components/UserEdit/style';

export const getCells = (t: TFunction) => {
  return [
    {
      key: 'employee',
      label: t('employees.title'),
      content: (employee: Employee) => (
        <Row>
          <EmployeeAvatar
            imageUrl={employee.profilePicture || ''}
            name={employee.firstName}
          />
          <span>
            {employee.firstName} {employee.lastName}
          </span>
        </Row>
      ),
    },
    {
      key: 'email',
      label: t('employees.email'),
    },
    {
      key: 'organizationUnit',
      label: t('employees.cell'),
      content: (employee: Employee) => employee.organizationUnit?.name ?? '',
    },
    {
      key: 'menu',
      label: '',
    },
  ];
};
