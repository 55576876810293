import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableRow, styled } from '@mui/material';

import {
  ParameterObject,
  ParameterTypes,
  ParameterUnitsTypes,
} from '@/__generated__/graphql';
import {
  StatisticRowTypes,
  useStatisticCells,
} from '@/core/components/Statistica/StatisticTable/StatisticRow';
import { convertTime, getTimeAsString } from '@/core/hooks';
import { isTimeParameter } from '@/core/utils';
import { SystemParameterKeysEnum } from '@/pages/e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum';
import { theme } from '@/theme';

import { DisplayParametersTypes } from './DisplayParametersTypes.types';
import { DisplayParametersObjects } from './Functions/DisplayParameters';

export const useDisplayParameters = (props: DisplayParametersTypes) => {
  const { t } = useTranslation('eflow');

  const parameterEntries = useMemo(
    () => DisplayParametersObjects(props.parameters),
    [props.parameters],
  );

  const { renderCells } = useStatisticCells();

  const getValue = (
    details: ParameterObject & {
      value: number;
    },
  ) => {
    if (details.value === null || details.value === undefined) return '';

    if (isTimeParameter(details.unit))
      return `${getTimeAsString(convertTime(details.value, details.unit), details.unit)}`;

    return `${details.value}`;
  };

  const renderBody = () => {
    const defaultParams: Pick<
      StatisticRowTypes,
      | 'displayRowUnit'
      | 'withBorder'
      | 'isLoading'
      | 'cellContentAlignment'
      | 'labelColor'
    > = {
      displayRowUnit: true,
      withBorder: false,
      isLoading: false,
      cellContentAlignment: 'left',
      labelColor: theme.palette.grey[500],
    };
    const leftTable: JSX.Element[] = [];
    const rightTable: JSX.Element[] = [];

    parameterEntries.forEach((entry, i) => {
      const cell = (
        <TableRow key={i}>
          {renderCells({
            ...defaultParams,
            rowValues: [getValue(entry[1])],
            rowType: entry[1].unit as unknown as ParameterUnitsTypes,
            rowLabel:
              entry[1].type === ParameterTypes.Local
                ? entry[0]
                : t(
                    `newAnalysis.parameters.systemParams.${entry[0] as SystemParameterKeysEnum}`,
                  ),
          })}
        </TableRow>
      );

      if (i % 2 === 0) {
        leftTable.push(cell);
      } else {
        rightTable.push(cell);
      }
    });

    return (
      <>
        <CustomTable>
          <TableBody>{leftTable}</TableBody>
        </CustomTable>
        <CustomTable>
          <TableBody>{rightTable}</TableBody>
        </CustomTable>
      </>
    );
  };
  return { renderBody, parameterEntries };
};

// eslint-disable-next-line react-refresh/only-export-components
const CustomTable = styled(Table)({
  width: '300px',
});
