import { useTranslation } from 'react-i18next';

import { Box, Divider } from '@mui/material';
import { styled } from '@mui/styles';

import { useGetMonthName } from '@core/hooks';

import { RechartBarChartType } from './RechartBarChart.type';
import { useRechartCustomTooltip } from './useRechartCustomTooltip';

export const RechartBarchartCustomTooltip = (
  props: RechartBarChartType<never>,
) => {
  const { t } = useTranslation('charts');

  const { active: isActive, payload, label } = props;
  const { getMonthName } = useGetMonthName();

  const { extractPreviousData } = useRechartCustomTooltip(props);

  if (!isActive || !payload) {
    return null;
  }

  return (
    <LegendContainer>
      <Box>{getMonthName(label)}</Box>
      <Divider />
      <Box>
        {extractPreviousData().map((entry, index) => (
          <Box key={index} sx={{ color: entry.fill }}>
            {entry.name}: {typeof entry.value === 'number' ? entry.value : '-'}
            {typeof entry.valueTarget === 'number'
              ? t('chartTooltip.target', { target: entry.valueTarget })
              : ''}
          </Box>
        ))}
      </Box>
    </LegendContainer>
  );
};

const LegendContainer = styled(Box)({
  backgroundColor: 'white',
  padding: '10px',
});
