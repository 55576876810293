import { useTranslation } from 'react-i18next';

import { GraphIcon } from '@kpi/common/GraphIconComponent/GraphIcon';
import { useAddKpiRateContext } from '@kpi/contexts';
import { HandleAddKpiRateProvider } from '@kpi/modals/AddKpiRate/context';

import { Alert, Box, Stack, Typography, styled } from '@mui/material';

import { CustomModal, ModalContainer } from '@core/components';

import { AddKpiRateProps } from './AddKpiRate.types';
import { NumberSelect } from './components';
import {
  ButtonsSection,
  MainContainer,
  MonthlyGoalSection,
  NameAndDescriptionSection,
  OrganizationUnitAndEmployeeSection,
  UnitSection,
} from './sections';

export const AddKpiRate = ({ display, close }: AddKpiRateProps) => {
  const { t } = useTranslation('kpi');

  const { isUpdateModal, relatedChartNames } = useAddKpiRateContext();

  return (
    <CustomModal display={display}>
      <HandleAddKpiRateProvider>
        <ModalContainer
          closeModal={close}
          headerText={
            isUpdateModal ? t('addKpi.updateTitle') : t('addKpi.title')
          }
          width="528px"
          sx={{ minHeight: '516px' }}
        >
          <MainContainer>
            {isUpdateModal && relatedChartNames?.length > 0 ? (
              <Alert component="span" severity="warning">
                {t('addKpi.updateWarningPt1')}
                <GraphIcon relatedChartNames={relatedChartNames} />
                {t('addKpi.updateWarningPt2')}
              </Alert>
            ) : (
              <Spacer />
            )}
            <NameAndDescriptionSection />
            <OrganizationUnitAndEmployeeSection />
            <UnitSection />
            <SelectContainer>
              <Typography>{t('addKpi.reportRequiredFirstPart')} </Typography>
              <NumberSelect />
              <Typography> {t('addKpi.reportRequiredSecondPart')}</Typography>
            </SelectContainer>
            <MonthlyGoalSection />
            <ButtonsSection close={close} />
          </MainContainer>
        </ModalContainer>
      </HandleAddKpiRateProvider>
    </CustomModal>
  );
};

const SelectContainer = styled(Stack)({
  flexDirection: 'row',
  gap: '30px',
  height: '48px',
  alignItems: 'center',
  padding: '8.5px',
});

const Spacer = styled(Box)({
  marginTop: '-25px',
});
