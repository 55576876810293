import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModuleNamesEnum,
  SystemRoles,
  useGetEmployeesForOrganizationLazyQuery,
} from '@/__generated__/graphql';
import {
  useCompanyId,
  useFetchEmployees,
  useModal,
  useSnackbar,
} from '@/core/hooks';

import { PermissionsModuleContext } from '../context';
import { useUpdatePermission } from '../hooks';

export const useUsers = () => {
  const { t: permissionT } = useTranslation('permissions');

  const { companyId } = useCompanyId();

  const context = useContext(PermissionsModuleContext);

  const getEmployees = useGetEmployeesForOrganizationLazyQuery();

  const { isSnackBarOpen, snackBarMessage, closeSnackbar, openSnackbar } =
    useSnackbar();

  const { setIsModalVisible, setModalContent, isModalVisible, modalContent } =
    useModal();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [usersToRemove, setUsersToRemove] = useState<string[]>([]);

  const {
    isLoading,
    employees,
    totalEmployees,
    search,
    setSearch,
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,
    organizationUnit,
    setOrganizationUnit,
    setSystemRoles,
  } = useFetchEmployees(companyId, getEmployees, context?.possibleRoles);

  const { removePermissions } = useUpdatePermission({
    roleName: context?.possibleRoles[0] || SystemRoles.EflowCreator,
    moduleName: context?.moduleName || ModuleNamesEnum.Eflow,
    organizationId: context?.organizationId || '',
  });

  const handleRemovePermission = async (userIds: string[]) => {
    try {
      await removePermissions(userIds);
      setIsModalVisible(false);
      openSnackbar(permissionT('flow.users.removeAccessSucces'));
      setUsersToRemove([]);
    } catch (_ex) {
      setIsModalVisible(false);
      openSnackbar(permissionT('flow.users.removeAccessFail'));
    }
  };

  const handleRemovePermissionButtonClick = () => {
    setUsersToRemove([...selectedRows]);
    setModalContent(permissionT('flow.users.removeAccessModal'));
    setIsModalVisible(true);
  };

  return {
    isLoading,
    employees,
    totalEmployees,
    search,
    setSearch,
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,
    organizationUnit,
    setOrganizationUnit,
    selectedRows,
    setSelectedRows,
    handleRemovePermission,
    handleRemovePermissionButtonClick,
    usersToRemove,
    setUsersToRemove,
    isModalVisible,
    modalContent,
    setIsModalVisible,
    setModalContent,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
    setSystemRoles,
    openSnackbar,
  };
};
