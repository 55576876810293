import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee.ts';
import { omit } from 'lodash';

import { Skeleton, Typography, styled } from '@mui/material';

import { UserEditPropertyProps } from '@/core/components/UserEdit/UserEditProp/userEditProperty.types.ts';
import {
  CustomTextField,
  Row,
  TypographyLabel,
} from '@/core/components/UserEdit/style.ts';
import { theme } from '@/theme';

export const UserEditProperty = forwardRef(
  (props: UserEditPropertyProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();

    if (props.isLoading) {
      return (
        <Row>
          <Skeleton width={450} />
        </Row>
      );
    }

    return (
      <Row>
        <TypographyLabel variant="bodyRegular" fontSize={14}>
          {props.propertyName}
        </TypographyLabel>
        {props.edit ? (
          <>
            <CustomTextField
              size="small"
              sx={{ minWidth: '75%' }}
              ref={ref}
              InputProps={{
                sx: { borderRadius: '8px' },
              }}
              {...omit(
                props,
                'propertyName',
                'propertyValue',
                'edit',
                'isLoading',
                'isEditButtonVisible',
                'onEdit',
              )}
            />
          </>
        ) : (
          <Typography variant="bodyRegular" fontSize={14}>
            {props.propertyValue}
          </Typography>
        )}
        {props.isEditButtonVisible && !props.edit && (
          <EditRowButton onClick={() => props.onEdit && props.onEdit()}>
            {t('common.edit')}
          </EditRowButton>
        )}
      </Row>
    );
  },
);

const EditRowButton = styled(PrimaryButton)({
  color: theme.palette.text.primary,
  padding: 0,
  position: 'absolute',
  right: 0,
});
