import { useEffect, useRef } from 'react';

export const usePrevious = (value: number): number | undefined => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value as unknown as undefined;
  });

  return ref.current;
};
