import { SystemRoles } from '@/__generated__/graphql.ts';

/**
 * Defines global system roles inside ECPS
 * those roles are used for defining user permissions
 */
export const GlobalSystemRoles: SystemRoles[] = [
  SystemRoles.SystemAdmin,
  SystemRoles.KeyUser,
  SystemRoles.User,
  SystemRoles.Root,
  SystemRoles.Administrator,
  SystemRoles.UnitAdmin,
];
