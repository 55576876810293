import { Employee } from '@/__generated__/graphql';

/**
 *
 * @param name name of the current user
 * @param lastName of the current user
 * @param createdBy user which created analize
 * @returns formated string
 */

export const formatCreatedBy = (
  name: string,
  lastName: string,
  createdBy?: Pick<Employee, 'firstName' | 'lastName' | 'profilePicture'>,
) => {
  return `${
    createdBy
      ? `${createdBy.firstName} ${createdBy.lastName}`
      : `${name} ${lastName}`
  }`;
};
