import { useTranslation } from 'react-i18next';

import { SubmissionDetails } from '@kaizen/components';
import { KaizenComment } from '@kaizen/components/Comment/KaizenComment.tsx';
import { useSettlementPeriod } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod.tsx';

import { Stack } from '@mui/material';

import { BoxContent } from '@core/components/BoxContent/BoxContent.tsx';

import {
  Employee,
  FileModel,
  OrganizationUnit,
} from '@/__generated__/graphql.ts';
import {
  AccordionViewer,
  FilesTable,
  FullScreenLoading,
  RichTextFieldViewer,
} from '@/core/components';

import { useMainSubmissionDashboard } from './hooks/useMainSubmissionDashboard';
import { TopPanelSection } from './sections/TopPanelSection';
import {
  MainContentContainer,
  MainPageContainer,
  SubmissionDetailsContainer,
} from './styles/styles';

export const MainSubmissionDashboard = () => {
  const { t } = useTranslation('kaizen');

  const { submissionData, isLoading, error } = useMainSubmissionDashboard();
  const { settlementsPeriodMap } = useSettlementPeriod();

  if (
    error &&
    JSON.parse(error?.message).errorMessage === 'SUBMISSION_NOT_FOUND'
  )
    return (
      <Stack alignItems="center" marginTop={'13%'}>
        <BoxContent
          shadow={false}
          errorMessage={t(
            'submissionModificationView.common.errors.noSubmissionError',
          )}
        />
      </Stack>
    );

  if (isLoading || !submissionData) return <FullScreenLoading />;

  return (
    <MainPageContainer>
      {/*<SubmissionOverviewNavbar />*/}
      <MainContentContainer>
        <TopPanelSection
          title={submissionData?.name}
          submission={submissionData}
        />
        <Stack flexDirection="row" justifyContent={'space-between'}>
          <Stack height="100%" width="55%">
            <AccordionViewer
              title={t('mainSubmissionDashboard.problemDescription')}
            >
              <RichTextFieldViewer
                content={submissionData?.problem.description || ''}
              />
              <FilesTable
                files={(submissionData?.problem.files as FileModel[]) || []}
              />
            </AccordionViewer>
            <AccordionViewer
              title={t('mainSubmissionDashboard.solutionDescription')}
            >
              <RichTextFieldViewer
                content={submissionData?.solution.description || ''}
              />
              <FilesTable
                files={(submissionData?.solution.files as FileModel[]) || []}
              />
            </AccordionViewer>
          </Stack>
          <SubmissionDetailsContainer>
            <SubmissionDetails
              periodName={
                settlementsPeriodMap[submissionData.settlementPeriodId] || '-'
              }
              status={submissionData?.status}
              id={submissionData?.submissionKey}
              createdAt={submissionData?.createdAt}
              updatedAt={submissionData?.updatedAt}
              createdBy={submissionData?.createdBy as Employee}
              assignedPoints={submissionData?.assignedPoints || 0}
              organizationUnit={
                submissionData?.organizationUnit as OrganizationUnit
              }
              authors={submissionData?.authors as Employee[]}
              assignedContractor={
                submissionData?.assignedContractor as Employee
              }
            />
          </SubmissionDetailsContainer>
        </Stack>
        <Stack>
          {submissionData?.comments?.map((comment) => (
            <KaizenComment
              key={comment.id}
              createdBy={
                comment.createdBy as {
                  firstName: string;
                  lastName: string;
                  profilePicture: string;
                  id: string;
                }
              }
              createdAt={comment.createdAt}
              content={comment.content}
              commentCategory={comment.commentCategory || ''}
            />
          ))}
        </Stack>
      </MainContentContainer>
    </MainPageContainer>
  );
};
