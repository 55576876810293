import { TFunction } from 'i18next';
import { z } from 'zod';
import { SystemRoles } from '@/__generated__/graphql.ts';
import { emailValidationSchema, phoneValidationSchema } from '@/utils';

/**
 * Schema used for validating the form in AddUserModal component
 * @param t t function which is used for translation
 * @param permissions permissions of the user
 */
export const AddUserModalScheme = (t: TFunction, permissions: SystemRoles[]) =>
  z.object({
    firstName: z.string().min(1, t('login.requiredField')),
    lastName: z.string().min(1, t('login.requiredField')),
    phone: phoneValidationSchema(
      t('login.requiredField'),
      t('systemSettings.phoneNumberInvalid'),
    ),
    email: emailValidationSchema(
      t('login.requiredField'),
      t('systemSettings.emailInvalid'),
    ),
    position: z.string().min(1, t('login.requiredField')),
    systemRole:
      permissions.length == 2
        ? z.string().min(1, t('login.requiredField'))
        : z.string(),
    organizationUnitGuid: z.string().min(1, t('login.requiredField')),
  });
