import { useState } from 'react';
import { UseFormRegister, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { eflowCommentsDefaultValue } from '@e-flow/components/CommentModule/hooks/useHandleEflowCommentChange/eFlowCommentsDefaultValue.ts';
import { zodResolver } from '@hookform/resolvers/zod';

import { CommentSchema } from '@core/components';

import { EFlowStepCommentDto } from '@/__generated__/graphql.ts';

import { UseHandleEflowCommentChangeType } from './useHandleEflowCommentChange.type';

export const useHandleEflowCommentChange =
  (): UseHandleEflowCommentChangeType => {
    const { t } = useTranslation('eflow');

    const [selectedSymbol, setSelectedSymbol] = useState<string>('');
    const [isInReport, setIsInReport] = useState<boolean>(false);

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
    } = useForm<EFlowStepCommentDto>({
      defaultValues: eflowCommentsDefaultValue,
      resolver: zodResolver(CommentSchema(t)),
    });

    const registerContent = () => register('content');

    const setCheckboxValue = (value: boolean) => {
      setIsInReport(value);
      setValue('showInReport', value);
    };

    const setCommentsCategory = (selectedSymbol: string) => {
      setSelectedSymbol(selectedSymbol);
      setValue('commentCategory', selectedSymbol);
    };

    const clearForm = () => {
      reset({
        ...eflowCommentsDefaultValue,
      });

      setSelectedSymbol('');
      setCheckboxValue(false);
    };

    return {
      isInReport,
      selectedSymbol,
      isContentError: !!errors.content,
      isCategoryError: !!errors.commentCategory,
      contentErrorMessage: errors.content?.message as string,
      clearForm,
      handleSubmit,
      setIsInReport: setCheckboxValue,
      setSelectedSymbol: setCommentsCategory,
      registerContent:
        registerContent as unknown as () => UseFormRegister<EFlowStepCommentDto>,
    };
  };
