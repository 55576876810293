import { FindKaizenModuleInfoDocument } from '@/__generated__/graphql';
import { client } from '@/apollo';
import { Scoring } from '@/pages/kaizen/module-settings/Points/Points.types';

export const writeQuery = (data: Scoring, organizationId: string) => {
  client.writeQuery({
    query: FindKaizenModuleInfoDocument,
    variables: {
      input: {
        organizationId: organizationId,
      },
    },
    data: {
      findKaizenModuleInfo: {
        __typename: 'KaizenModule',
        scoringSet: {
          scoring: data,
          __typename: 'KaizenScoring',
        },
      },
    },
  });
};
