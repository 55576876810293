import { ModalBodyTypes } from '@e-flow/pages/flowDashboard/modals/StepDetails/components/ModalBody/ModalBodyTypes.types.ts';
import { ModalHeader } from '@e-flow/pages/flowDashboard/modals/StepDetails/components/ModalHeader/ModalHeader.tsx';

import { Box, styled } from '@mui/material';

export const ModalBody = (props: ModalBodyTypes) => {
  return (
    <Container>
      <ModalHeader
        organizationId={props.organizationId}
        analizeType={props.analizeType}
        eFlowId={props.eFlowId}
        stepId={props.stepId}
      />
      {props.children}
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  borderRadius: 10,
  background: '#fff',
  overflow: 'auto',
  height: '90vh',
  width: 'calc(90vw/2)',
  gap: 10,
});
