import { ReactNode, useState } from 'react';

export const useBasicModal = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [headerText, setHeaderText] = useState('');
  const [content, setContent] = useState<string | ReactNode | JSX.Element>();
  const [buttonText, setButtonText] = useState('');
  const [onSubmit, setOnSubmit] = useState<() => void>(() => {});

  const handleOpenModal = () => {
    document.body.style.overflow = 'hidden';
    setIsDisplayed(true);
  };

  const handleCloseModal = () => {
    document.body.style.overflow = 'auto';
    setIsDisplayed(false);
  };

  return {
    display: isDisplayed,
    handleOpenModal,
    handleCloseModal,
    setIsDisplayed,
    setHeaderText,
    setContent,
    setButtonText,
    headerText,
    isDisplayed,
    content,
    buttonText,
    closeModal: handleCloseModal,
    onSubmit: () => {
      onSubmit();
      handleCloseModal();
    },
    setOnSubmit,
  };
};
