import { HTMLAttributeAnchorTarget } from 'react';

import { useGlobalRedirects } from '@core/redirects';

import { OrganizationModules } from '@/__generated__/graphql';

export const useRoutes = () => {
  const {
    eFlowPath,
    eSchemaPath,
    kaizenPath,
    kpiPath,
    modulesDashboardPath,
    opexityPath,
    auditPath,
    digitalTwinPath,
    timesPath,
    aiPath,
  } = useGlobalRedirects();

  const getMainModulePath = (moduleName: OrganizationModules) => {
    switch (moduleName) {
      case OrganizationModules.ESchema:
        return eSchemaPath;
      case OrganizationModules.EFlowAnalize:
        return eFlowPath;
      case OrganizationModules.Kaizen:
        return kaizenPath;
      case OrganizationModules.Kpi:
        return kpiPath;
      case OrganizationModules.Opexity:
        return opexityPath;
      case OrganizationModules.Audit5s:
        return auditPath;
      case OrganizationModules.DigitalTwin:
        return digitalTwinPath;
      case OrganizationModules.TiMes:
        return timesPath;
      case OrganizationModules.Ai:
        return aiPath;
      default:
        return modulesDashboardPath;
    }
  };

  const getTarget = (
    moduleName: OrganizationModules,
  ): HTMLAttributeAnchorTarget => {
    switch (moduleName) {
      case OrganizationModules.Opexity:
      case OrganizationModules.Audit5s:
      case OrganizationModules.TiMes:
      case OrganizationModules.Ai:
      case OrganizationModules.DigitalTwin:
        return '_blank';
      default:
        return '_self';
    }
  };

  return { getMainModulePath, getTarget };
};
