import { ReactNode, createContext } from 'react';

import { useSynchronizedFlows } from '@e-flow/hooks';
import { useSynchronizedFlowsOutput } from '@e-flow/hooks/useSynchronizedFlows/useSynchronizedFlows.types.ts';

export const EflowStepSyncContext = createContext<useSynchronizedFlowsOutput>(
  null as unknown as useSynchronizedFlowsOutput,
);

interface SynchronizeProviderProps {
  children: ReactNode;
}

export const SynchronizeEflowStepsProvider = ({
  children,
}: SynchronizeProviderProps) => {
  const values = useSynchronizedFlows(true);

  return (
    <EflowStepSyncContext.Provider value={values}>
      {children}
    </EflowStepSyncContext.Provider>
  );
};
