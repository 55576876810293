import { ReactNode, createContext, useContext } from 'react';

import { pick } from 'lodash';

import { useBasicModal } from '@core/hooks';

import { BasicModal } from '@/core/components';

type ModalContextType = ReturnType<typeof useBasicModal>;

const ModalContext = createContext<ModalContextType | undefined>(undefined);

// eslint-disable-next-line react-refresh/only-export-components
export const useModalContext = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const modalControls = useBasicModal();

  return (
    <ModalContext.Provider value={modalControls}>
      {children}
      <BasicModal
        {...pick(modalControls, [
          'display',
          'closeModal',
          'buttonText',
          'onSubmit',
          'content',
          'headerText',
        ])}
      />
    </ModalContext.Provider>
  );
};
