import { useEffect, useState } from 'react';

import { DocumentNode } from 'graphql/language';
import { difference } from 'lodash';

import {
  AsIsToBeNamesEnum,
  EflowStepFragmentFragment,
  FindEflowToBeStepsDocument,
  GetEflowAsIsStepsDocument,
  useGetAsIsToBeIdsLazyQuery,
  useSubscribeEflowChangeSubscription,
  useSubscribeEflowStepsChangeSubscription,
} from '@/__generated__/graphql.ts';

import {
  useGetDocumentType,
  useMoveEflowStepRecordInCache,
  useRemoveEflowStepFromCache,
  useUpdateEflowStepCache,
} from './useCacheUpdate/hooks';

interface UseLiveUpdateProps {
  eFlowId: string;
  organizationId: string;
}

export const useLiveUpdate = () => {
  const [eFlowId, setEflowId] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string>('');

  const [asIsStepsIds, setAsIsStepsIds] = useState<string[]>([]);
  const [toBeStepsIds, setToBeStepsIds] = useState<string[]>([]);

  const { updateEflowStep } = useUpdateEflowStepCache(eFlowId, organizationId);
  const { removeRecordsFromCache } = useRemoveEflowStepFromCache(
    eFlowId,
    organizationId,
  );
  const { handleMoveSteps } = useMoveEflowStepRecordInCache(
    eFlowId,
    organizationId,
  );
  const { getRecordState } = useGetDocumentType();

  const { data: stepsIds } = useSubscribeEflowChangeSubscription({
    variables: {
      input: {
        id: eFlowId,
        organizationId,
      },
    },
  });

  const { data: updatedEflowStep } = useSubscribeEflowStepsChangeSubscription({
    variables: {
      input: {
        eFlowId,
      },
    },
  });

  const [findInitialData] = useGetAsIsToBeIdsLazyQuery();

  useEffect(() => {
    if (!eFlowId || !organizationId) return;

    void (async () => {
      const { data } = await findInitialData({
        variables: {
          input: {
            organizationId: organizationId,
            id: eFlowId,
          },
        },
      });

      if (!data) return;

      setToBeStepsIds((data.findOneEFlow.toBeState as string[]) || []);
      setAsIsStepsIds((data.findOneEFlow.asIsState as string[]) || []);
    })();
  }, [eFlowId, findInitialData, organizationId]);

  //Updates single step
  useEffect(() => {
    if (!updatedEflowStep) return;

    addNewRecordToCache(
      updatedEflowStep.eFlowStepChanged as EflowStepFragmentFragment,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedEflowStep?.eFlowStepChanged]);

  //Updates eflow - as is
  useEffect(() => {
    handleRecordUseEffect(AsIsToBeNamesEnum.AsIs, GetEflowAsIsStepsDocument);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsIds?.eFlowChanged.asIsState]);

  // Updates eflow - to be
  useEffect(() => {
    handleRecordUseEffect(AsIsToBeNamesEnum.ToBe, FindEflowToBeStepsDocument);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsIds?.eFlowChanged.toBeState]);

  /**
   * Handles the record changes in live update
   * @param analizeType - AsIs or ToBe
   * @param document - document to update
   *
   * @description Responsible for updating the order and removing steps from cache
   */
  const handleRecordUseEffect = (
    analizeType: AsIsToBeNamesEnum,
    document: DocumentNode,
  ) => {
    if (!stepsIds?.eFlowChanged) return;

    const analizeState = getRecordState(analizeType);

    const localPreviousStepsIds =
      analizeType === AsIsToBeNamesEnum.AsIs ? asIsStepsIds : toBeStepsIds;

    const recordsToRemove = difference(
      localPreviousStepsIds,
      stepsIds.eFlowChanged[analizeState]!,
    );

    if (recordsToRemove.length > 0) {
      removeRecordsFromCache(document, recordsToRemove, analizeType);
    } else {
      handleMoveSteps(
        document,
        stepsIds.eFlowChanged[analizeState]!,
        analizeType,
      );
    }

    if (analizeType === AsIsToBeNamesEnum.AsIs)
      setAsIsStepsIds(stepsIds.eFlowChanged[analizeState]!);
    else setToBeStepsIds(stepsIds.eFlowChanged[analizeState]!);
  };

  /**
   * Adds new record to cache in live update
   * @param eFlowStep
   */
  const addNewRecordToCache = (eFlowStep: EflowStepFragmentFragment) => {
    const indexOfToBeStep = toBeStepsIds?.findIndex(
      (id) => id === eFlowStep.id,
    );
    const indexOfAsIsStep = asIsStepsIds?.findIndex(
      (id) => id === eFlowStep.id,
    );

    if (indexOfToBeStep !== -1) {
      updateEflowStep(
        AsIsToBeNamesEnum.ToBe,
        eFlowStep,
        indexOfToBeStep,
        toBeStepsIds,
      );
    }

    if (indexOfAsIsStep !== -1) {
      updateEflowStep(
        AsIsToBeNamesEnum.AsIs,
        eFlowStep,
        indexOfAsIsStep,
        asIsStepsIds,
      );
    }
  };

  ///Public

  /**
   * Initializes the hook, sets the eFlowId and organizationId
   * @param props {UseLiveUpdateProps} - eFlowId and organizationId
   * @returns void
   *
   * @description after hook is initialized, it will listen for changes in the eFlow and its steps
   */
  const init = (props: UseLiveUpdateProps) => {
    setEflowId(props.eFlowId);
    setOrganizationId(props.organizationId);
  };

  return {
    init,
  };
};
