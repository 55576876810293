import { Close } from '@mui/icons-material';
import { IconButton, Snackbar as MuiSnackbar, Slide } from '@mui/material';

import { theme } from '@/theme';

import { SnackbarProps } from './Snackbar.types';

export const Snackbar = ({
  open,
  onClose,
  message,
  horizontal = 'center',
  vertical = 'bottom',
}: SnackbarProps) => {
  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
      message={message}
      autoHideDuration={5000}
      action={
        <IconButton
          size="small"
          onClick={() => onClose()}
          sx={{
            color: theme.palette.common.white,
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
};
