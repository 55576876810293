import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { ErrorContent } from '@/core/components';
import { FlexCenter } from '@/core/styles';
import { ProtectedRoute } from '@/router';
import { useUserStore } from '@/stores';

import { Login, PasswordConfirmation, SetNewPassword } from '../pages';

export const LoginRoutes = () => {
  const { organizationUrl } = useUserStore();

  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        element={<ProtectedRoute mustBeLoggedIn={false} isSystemRoute={true} />}
      >
        <Route index element={<Login />} />

        <Route path="set-new-password" element={<SetNewPassword />} />
        <Route
          path="password-confirmation"
          element={
            <PasswordConfirmation
              description={t('passwordConfirmation.description')}
              buttonLabel={t('common.continue')}
              route={organizationUrl}
            />
          }
        />
        <Route
          path="*"
          element={
            <FlexCenter sx={{ height: '100vh' }}>
              <ErrorContent errorCode="404" />
            </FlexCenter>
          }
        />
      </Route>
    </Routes>
  );
};
