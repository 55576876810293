import { captureException } from '@sentry/react';

import { useUpdateSingleSubmissionCache } from '@kaizen/hooks';

import {
  KaizenSubmissionFragment,
  useAcceptSubmissionImplementationMutation,
} from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

export const useAcceptSubmissionImplementation = () => {
  const { companyId } = useCompanyId();

  const [acceptKaizenSubmissionImplementationMutation, { loading: isLoading }] =
    useAcceptSubmissionImplementationMutation();

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const acceptKaizenSubmissionImplementation = async (submissionId: string) => {
    try {
      const result = await acceptKaizenSubmissionImplementationMutation({
        variables: {
          input: {
            submissionId,
            organizationId: companyId,
          },
        },
      });
      if (result.data?.setKaizenSubmissionImplementedStatus) {
        updateSingleSubmissionCache(
          result.data
            .setKaizenSubmissionImplementedStatus as KaizenSubmissionFragment,
        );
        return true;
      }
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  return {
    isLoading,
    acceptKaizenSubmissionImplementation,
  };
};
