import { useEffect } from 'react';

import { OperationIcon } from '@e-flow/components';
import { useOperationsSelect } from '@e-flow/hooks';
import { PickOptionMenuTypes } from '@e-flow/pages/flowDashboard/components/Menus/PickOptions/PickOptionMenu.types.ts';

import { Box, styled } from '@mui/material';

import { Button, MenuWithSearch } from '@core/components';

import { theme } from '@/theme';

export const PickOptionMenu = ({
  display = true,
  ...props
}: PickOptionMenuTypes) => {
  const { isLoading, data, init } = useOperationsSelect();

  useEffect(() => {
    init(props.organizationId, props.eFlowId, props.analizeType);
  }, [init, props.eFlowId, props.organizationId, props.analizeType]);

  if (!display) return null;
  return (
    <MenuWithSearch
      isLoading={isLoading}
      fireElement={
        <Button
          variant="text"
          startIcon={props.icon}
          textColor={theme.palette.grey[800]}
        >
          {props.text}
        </Button>
      }
      searchKey={'operationName'}
      options={data}
      menuProps={{
        sx: {
          maxHeight: 300,
        },
      }}
      onSelect={(item) => props.onChange(item.id)}
      menuItemBody={(option, index) => (
        <StyledMenuItemContainer>
          {index + 1}.
          <OperationIcon operation={option.operation.operationType} />
          {option.operationName}
        </StyledMenuItemContainer>
      )}
    />
  );
};

const StyledMenuItemContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
});
