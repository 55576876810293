import { useTranslation } from 'react-i18next';

export const useGetMonthName = () => {
  const { t } = useTranslation();

  const getMonthName = (month?: number | string | undefined) => {
    if (!month) {
      return '';
    }

    switch (month) {
      case 1:
        return t(`months.1`);
      case 2:
        return t(`months.2`);
      case 3:
        return t(`months.3`);
      case 4:
        return t(`months.4`);
      case 5:
        return t(`months.5`);
      case 6:
        return t(`months.6`);
      case 7:
        return t(`months.7`);
      case 8:
        return t(`months.8`);
      case 9:
        return t(`months.9`);
      case 10:
        return t(`months.10`);
      case 11:
        return t(`months.11`);
      case 12:
        return t(`months.12`);
      default:
        return month;
    }
  };

  return { getMonthName };
};
