import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerWithTabsProps } from '../DrawerWithTabs.types';
import { MainTab } from '../components/MainTab';
import { NotificationTab } from '../components/NotificationTab';
import { TabWrapper } from '../components/TabWrapper';
import { TabsDefaultOptions } from './TabsDefaultOptions';

export const useDrawerWithTabs = (props: DrawerWithTabsProps) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<TabsDefaultOptions | string>(
    TabsDefaultOptions.MAIN,
  );

  useEffect(() => {
    if (props.isOpen) {
      setCurrentTab('main');
    }
  }, [props.isOpen]);

  const handleOpenTab = (tabKey: string) => setCurrentTab(tabKey);

  const renderTab = useCallback(() => {
    switch (currentTab as TabsDefaultOptions) {
      case TabsDefaultOptions.MAIN:
        return (
          <TabWrapper closeDrawer={props.handleClose} title={props.title}>
            {props.contentAboveTabs}
            <MainTab {...props} handleOpenTab={handleOpenTab} />
            {props.contentBelowTabs}
          </TabWrapper>
        );
      case TabsDefaultOptions.NOTIFICATIONS:
        return (
          <TabWrapper
            title={t('notification.title')}
            handleGoBack={handleGoBack}
            closeDrawer={props.handleClose}
          >
            <NotificationTab />
          </TabWrapper>
        );
      default: {
        const selectedTab = props.tabs?.find((tab) => tab.key === currentTab);

        if (!selectedTab) return <></>;

        return (
          <TabWrapper
            title={selectedTab.title}
            handleGoBack={handleGoBack}
            closeDrawer={props.handleClose}
          >
            {selectedTab.content}
          </TabWrapper>
        );
      }
    }
    //eslint-disable-next-line
  }, [currentTab, props]);

  const handleGoBack = () => setCurrentTab('main');

  return {
    renderTab,
    currentTab,
    handleGoBack,
  };
};
