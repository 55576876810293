import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { isGreaterThanZero } from '@core/ComponentPropTypes/IsGreaterThanZero.ts';

import { CustomTextField } from '@/core/styles';

import { CharsCountingInputProps } from './CharCountingInput.types.ts';

export const CharsCountingInput = forwardRef(
  (
    props: CharsCountingInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { maxInputLength, defaultText, ...rest } = props;
    const [currentInputLength, setCurrentInputLength] = useState(0);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      if (defaultText !== undefined) setCurrentInputLength(defaultText.length);
    }, [defaultText]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      setCurrentInputLength(inputValue.length);
    };

    return (
      <Box sx={{ height: '60px', ...props.sx }}>
        <CustomTextField
          size="small"
          fullWidth
          maxRows={3}
          inputProps={{
            maxLength: maxInputLength,
          }}
          {...rest}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          onInput={handleInputChange}
          ref={ref}
        />
        {isFocused && rest.error == false && (
          <Box textAlign="right" color={props.error ? 'red' : 'gray'}>
            {`${currentInputLength}/${maxInputLength}`}
          </Box>
        )}
      </Box>
    );
  },
);

CharsCountingInput.propTypes = {
  maxInputLength: isGreaterThanZero,
};
