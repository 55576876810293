import { useState } from 'react';

import { useCompanyId } from '@core/hooks';

import {
  KpiRateDto,
  useGetRelatedKpiChartsLazyQuery,
} from '@/__generated__/graphql.ts';

export const useAddKpiModalContext = () => {
  const [isAddKpiModelOpen, setIsKpiModalOpen] = useState(false);
  const [kpiRate, setKpiRate] = useState<KpiRateDto | undefined>(undefined);

  const [isUpdateModal, setIsUpdateModal] = useState(false);

  const { companyId } = useCompanyId();

  const [getRelatedKpiCharts, { data }] = useGetRelatedKpiChartsLazyQuery();

  const openAddKpiModal = (kpi?: KpiRateDto) => {
    setIsUpdateModal(!!kpi);
    setKpiRate(kpi);
    setIsKpiModalOpen(true);

    if (kpi) {
      void getRelatedKpiCharts({
        variables: {
          input: {
            organizationId: companyId,
            kpiId: kpi.id,
          },
        },
      });
    }
  };

  return {
    kpiRate,
    isAddKpiModelOpen,
    relatedChartsValue: data?.getRelatedKpiCharts.totalRelatedResources,
    isUpdateModal,
    openAddKpiModal,
    relatedChartNames: data?.getRelatedKpiCharts.relatedResources || [],
    closeAddKpiModal: () => setIsKpiModalOpen(false),
  };
};
