import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography, styled } from '@mui/material';

import { SearchTextField, TablePaginationWrapper } from '@core/components';

import { useKpiChartsList } from '../../hooks/useKpiChartsList';
import { TableContent } from './TableContent';

type Props = {
  setSelectedChart: Dispatch<SetStateAction<string | undefined>>;
};

export const KpiChartList = ({ setSelectedChart }: Props) => {
  const { t: commonT } = useTranslation();
  const { t } = useTranslation('kpi');

  const {
    setIcon,
    search,
    handleSearch,
    rowsPerPage,
    setRowsPerPage,
    cursor,
    setCursor,
    kpiChartsList,
    total,
    isLoading,
  } = useKpiChartsList();

  return (
    <MainContainer>
      <SearchTextField
        inputStyles={{ width: '620px' }}
        onChange={(event) => {
          handleSearch(event);
        }}
        placeholder={commonT('organization.search')}
        value={search}
      />
      <TableContainer>
        <Typography>{t('charts.chartName')}</Typography>
        <Divider />
        <TablePaginationWrapper
          total={total}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={(newValue) => setRowsPerPage(newValue)}
          cursor={cursor}
          setCursor={(newValue) => setCursor(newValue)}
        >
          <TableContent
            kpiChartsList={kpiChartsList}
            isLoading={isLoading}
            setIcon={setIcon}
            setSelectedChart={setSelectedChart}
          />
        </TablePaginationWrapper>
      </TableContainer>
    </MainContainer>
  );
};

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '24px',
  overflow: 'auto',
  maxHeight: '700px',
});

const TableContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
