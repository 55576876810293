import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@mui/material';

import { EflowStatusProps } from './EflowStatus.types';
import { getStatusData } from './utils';

export const EflowStatus: FC<EflowStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statusData = useMemo(() => getStatusData(status, t), [status]);

  return (
    <Chip
      size={'small'}
      label={statusData?.name}
      style={{
        backgroundColor: statusData?.color,
        color: statusData?.textColor,
      }}
      sx={{
        borderRadius: '7px',
        margin: 0,
      }}
    />
  );
};
