import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useKaizenSettlementPeriodContext } from '@kaizen/module-settings/SettlementPeriods/contexts';
import { useSettlementPeriod } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod.tsx';

import { CheckBoxWithTextModal } from '@core/components';
import { CheckBoxWithTextModalDefaultTypes } from '@core/components/Modals/CheckBoxWithTextModal/CheckBoxWithTextModal.types.ts';

export const UpdateSettlementPeriodModal = () => {
  const {
    isUpdateSettlementPeriodModalOpen,
    settlementPeriodUpdateModalValues,
    closeUpdateSettlementPeriodModal,
    open: openSnackbar,
  } = useKaizenSettlementPeriodContext();

  const [isValidationError, setValidationError] = useState<boolean>();

  const { validate, updateSettlementPeriod } = useSettlementPeriod();

  const { t: kaizenT } = useTranslation('kaizen');
  const { t: commonT } = useTranslation();

  return (
    <CheckBoxWithTextModal
      isOpen={isUpdateSettlementPeriodModalOpen}
      closeModal={closeUpdateSettlementPeriodModal}
      headerText={kaizenT('updateSettlementPeriodModal.headerText')}
      placeholder={kaizenT('updateSettlementPeriodModal.placeholder')}
      checkboxLabel={kaizenT('updateSettlementPeriodModal.activateAfterUpdate')}
      submitButton={commonT('common.saveChanges')}
      checkboxTooltip={kaizenT(
        `updateSettlementPeriodModal.${settlementPeriodUpdateModalValues?.isActive ? 'activateAfterUpdateTooltip' : 'activateAfterUpdateTooltipWhenEnable'}`,
      )}
      errorInputMessage={kaizenT('updateSettlementPeriodModal.errorMessage')}
      isError={isValidationError}
      defaultValues={{
        text: settlementPeriodUpdateModalValues?.periodName || '',
        checkbox: settlementPeriodUpdateModalValues?.isActive || false,
      }}
      disableCheckbox={settlementPeriodUpdateModalValues?.isActive}
      onResponse={async function (response: CheckBoxWithTextModalDefaultTypes) {
        setValidationError(false);
        if (!validate(response.text, !!isValidationError)) {
          setValidationError(true);
          return;
        }

        if (
          await updateSettlementPeriod(
            settlementPeriodUpdateModalValues!.id,
            response.text,
            response.checkbox,
          )
        ) {
          if (response.checkbox) {
            openSnackbar(
              'success',
              kaizenT('updateSettlementPeriodModal.successAndActivatedMessage'),
            );
          } else
            openSnackbar(
              'success',
              kaizenT('updateSettlementPeriodModal.successMessage'),
            );

          closeUpdateSettlementPeriodModal();
          return;
        }

        openSnackbar(
          'error',
          kaizenT('updateSettlementPeriodModal.settlementPeriodAddError'),
        );
        closeUpdateSettlementPeriodModal();
        return;
      }}
    />
  );
};
