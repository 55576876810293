import { Stack, styled } from '@mui/material';

export const CardStack = styled(Stack)({
  minHeight: '88px',
  width: '616px',
  borderBottom: '1px solid #DCDFE1',
  padding: '24px 0px',
  gap: '24px',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
