import { Link, createSearchParams } from 'react-router-dom';

import { LinkProps } from './LinkToOrganizationSettings.types';

export const LinkToOrganizationSettings = ({
  children,
  organizationName,
}: LinkProps) => {
  return (
    <Link
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
      to={{
        pathname: `/${organizationName}/organization-settings/data`,
        search: createSearchParams({
          backPath: window.location.pathname,
        }).toString(),
      }}
    >
      {children}
    </Link>
  );
};
