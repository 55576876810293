import { captureException } from '@sentry/react';

import { useUpdateSingleSubmissionCache } from '@kaizen/hooks';

import {
  KaizenSubmissionFragment,
  useSetKaizenSubmissionInProgressStatusMutation,
} from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

export const useSetAsInProgress = () => {
  const { organizationId } = useUserStore();

  const [setKaizenSubmissionInProgressStatusMutation, { loading: isLoading }] =
    useSetKaizenSubmissionInProgressStatusMutation();

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const sendToInProgress = async (submission: string) => {
    if (!organizationId) return false;

    try {
      const result = await setKaizenSubmissionInProgressStatusMutation({
        variables: {
          input: {
            submissionId: submission,
            organizationId: organizationId,
          },
        },
      });

      if (result.data?.setKaizenSubmissionInProgressStatus) {
        updateSingleSubmissionCache(
          result.data
            .setKaizenSubmissionInProgressStatus as KaizenSubmissionFragment,
        );
        return true;
      }
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  return {
    isLoading,
    sendToInProgress,
  };
};
