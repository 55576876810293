import { useEffect } from 'react';

import { useChartNetworking } from '@kpi/modals/HandleKpiChartModal/hooks/useChartNetworking/useChartNetworking.tsx';
import { useKpiChartModal } from '@kpi/modals/HandleKpiChartModal/hooks/useKpiChartModal/useKpiChartModal.tsx';

import { CreateKpiChartInput, KpiRateDto } from '@/__generated__/graphql.ts';

import { useHandleChartValues } from '.';
import { useReadKpis } from './useReadKpis/useReadKpis';

export const useCreateChart = () => {
  const createChartFormHookValues = useHandleChartValues();

  const readKpis = useReadKpis();

  useEffect(() => {
    if (readKpis.kpis && readKpis.kpis?.length > 0)
      createChartFormHookValues.setSelectedKpis(
        (readKpis.kpis as unknown as KpiRateDto[]) || [],
      );
    //eslint-disable-next-line
  }, [readKpis.kpis]);

  const kpiModal = useKpiChartModal({
    setValues: createChartFormHookValues.setValues,
    getKpis: readKpis.getKpis,
  });

  const { onSubmit } = useChartNetworking({
    chartMode: kpiModal.modalMode,
    dashboardId: kpiModal.dashboardId,
    inputValues: createChartFormHookValues.networkKpiChartValues as Pick<
      CreateKpiChartInput,
      'kpiIds' | 'displayOptions' | 'chartType'
    >,
    close: kpiModal.closeModal,
    resetValues: createChartFormHookValues.reset,
    chartId: createChartFormHookValues.chartId,
  });

  function closeAddChartModal() {
    createChartFormHookValues.reset();
    createChartFormHookValues.setSelectedKpis([]);
    kpiModal.closeModal();
  }

  return {
    ...kpiModal,
    onSubmit,
    closeModal: closeAddChartModal,
    ...createChartFormHookValues,
    ...readKpis,
  };
};
