import { useTranslation } from 'react-i18next';

import { ButtonsSection } from '@kaizen/pages/single-submission-view/sections/ButtonsSection.tsx';

import { BackButton } from '@/core/components';
import { useKaizenRedirects } from '@/core/redirects';

import { ButtonsContainer, CustomTypography, TopPanel } from '../styles/styles';
import { TopPanelSectionProps } from './types';

export const TopPanelSection = (props: TopPanelSectionProps) => {
  const { t } = useTranslation('kaizen');

  const { submissionsPath } = useKaizenRedirects();

  return (
    <>
      <ButtonsContainer>
        <BackButton
          href={submissionsPath}
          label={t('sumbissionNavbarOptions.submissions')}
        />
      </ButtonsContainer>
      <TopPanel>
        <CustomTypography variant="h3">{props.title}</CustomTypography>
        <ButtonsSection submission={props.submission} />
      </TopPanel>
    </>
  );
};
