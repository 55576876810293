import { Unit } from 'convert-units';
import { ParameterUnitsTypes } from '@/__generated__/graphql.ts';

export const convertUnitType = (
  incomingUnitType: ParameterUnitsTypes,
): Unit | undefined => {
  switch (incomingUnitType) {
    case ParameterUnitsTypes.AreaCm2:
      return 'cm2';
    case ParameterUnitsTypes.AreaM2:
      return 'm2';
    case ParameterUnitsTypes.DistanceCm:
      return 'cm';
    case ParameterUnitsTypes.DistanceM:
      return 'm';
    case ParameterUnitsTypes.DistanceMm:
      return 'mm';
    case ParameterUnitsTypes.MassG:
      return 'g';
    case ParameterUnitsTypes.MassKg:
      return 'kg';
    case ParameterUnitsTypes.TimeD:
      return 'd';
    case ParameterUnitsTypes.TimeH:
      return 'h';
    case ParameterUnitsTypes.TimeHhMm:
      return 'min'; // Assuming hh:mm format should be converted to minutes
    case ParameterUnitsTypes.TimeHhMmSs:
      return 's'; // Assuming hh:mm:ss format should be converted to seconds
    case ParameterUnitsTypes.TimeMin:
      return 'min';
    case ParameterUnitsTypes.TimeMmSs:
      return 's'; // Assuming mm:ss format should be converted to seconds
    case ParameterUnitsTypes.TimeS:
      return 's';
    case ParameterUnitsTypes.VolumeL:
      return 'l';
    case ParameterUnitsTypes.VolumeM3:
      return 'm3';
    case ParameterUnitsTypes.VolumeUnits:
      return undefined;

    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown unit type: ${incomingUnitType}`);
  }
};
