import { useTranslation } from 'react-i18next';

import { useAddKpiRateContext } from '@kpi/contexts';

import {
  DeleteOutline,
  EditOutlined,
  SummarizeOutlined,
} from '@mui/icons-material';
import { Menu } from '@mui/material';

import { CustomMenuItem } from '@core/components';
import type { UseMenuType } from '@core/hooks';

import { KpiRateDto } from '@/__generated__/graphql';

export const RowMenu = (
  props: UseMenuType & {
    openReportModal: () => void;
    openDeleteModal: () => void;
    kpi: KpiRateDto;
  },
) => {
  const { t } = useTranslation('kpi');
  const { t: commonT } = useTranslation();

  const { openAddKpiModal } = useAddKpiRateContext();

  return (
    <Menu
      open={!!props.isMenuOpen}
      onClose={props.handleMenuClose}
      anchorEl={props.anchorEl}
      onClick={props.handleMenuClose}
    >
      <CustomMenuItem
        text={t('table.menu.raport')}
        icon={<SummarizeOutlined />}
        onClick={props.openReportModal}
      />

      <CustomMenuItem
        text={commonT('common.delete')}
        icon={<DeleteOutline />}
        onClick={props.openDeleteModal}
      />

      <CustomMenuItem
        text={commonT('common.edit')}
        icon={<EditOutlined />}
        onClick={() => openAddKpiModal(props.kpi)}
      />
    </Menu>
  );
};
