import { Outlet } from 'react-router-dom';

import { ReportModal } from '@kpi/components/ReportModal';
import { useKpiTabs } from '@kpi/constant';

import { Navbar } from '@core/components/Navbar/Navbar.tsx';

import { OrganizationModules } from '@/__generated__/graphql.ts';

export const KpiLayout = () => {
  const { kpiNavbarTabs } = useKpiTabs();
  return (
    <div style={{ height: '100vh', maxHeight: '100vh' }}>
      <Navbar
        tabs={kpiNavbarTabs.tabs}
        variant={OrganizationModules.Kpi}
        additionalItems={<ReportModal />}
      />
      <Outlet />
    </div>
  );
};
