import { useOutletContext } from 'react-router-dom';

import { styled } from '@mui/material';

export const Dashboard = () => {
  const sidebar = useOutletContext();

  return (
    <HugeIframe
      src={`http://10.70.10.53:8501${(sidebar as string) || ''}`}
    ></HugeIframe>
  );
};

const HugeIframe = styled('iframe')`
  width: calc(100vw - 200px);
  height: 100%;
  border: none;
`;
