/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Typography, styled } from '@mui/material';

import { useRoutes } from '@core/hooks';

import { theme } from '@/theme';

import { IconModuleProps } from './IconModule.types';

export const IconModule: FC<IconModuleProps> = ({ variant }) => {
  const { getMainModulePath, getTarget } = useRoutes();

  const { t } = useTranslation();

  return (
    <Container to={getMainModulePath(variant)} target={getTarget(variant)}>
      <Box
        component="img"
        src={`/assets/${variant.toLowerCase()}-icon-module.svg`}
      />
      <Typography textAlign="center" variant={'bodyRegular'}>
        {t(`tiles.${variant}`)}
      </Typography>
    </Container>
  );
};

const Container = styled(Link)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '5px',
  color: `${theme.palette.text.primary}`,
  textDecoration: 'none',
  width: '108px',
  borderRadius: '4px',
  height: '108px',
  border: `1px solid ${theme.palette.grey[200]}`,
  cursor: 'pointer',

  '& img': {
    width: '40px',
    height: '40px',
  },
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});
