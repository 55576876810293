import { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import {
  useTimeFormatProps,
  useTimeFormatType,
  useTimeReturnType,
} from '@core/hooks/useTimeFormat/useTimeFormat.types.ts';

import { convertTime as convertTimeMethod } from './convertTime';

dayjs.extend(duration);

/**
 * useTimeFormat
 * @param inputTime {number|null}- Basic time in seconds
 * @param timeFormat {ParameterUnitsTypes} - Time format
 * @returns {useTimeFormatType} time
 */
export const useTimeFormat = ({
  inputTime,
  timeFormat,
}: useTimeFormatProps): useTimeReturnType => {
  const [timeInSeconds, setTimeInSeconds] = useState<number>(inputTime);

  const [time, setTime] = useState<useTimeFormatType>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const convertTime = useMemo(() => {
    return convertTimeMethod(timeInSeconds, timeFormat);
  }, [timeInSeconds, timeFormat]);

  useEffect(() => {
    setTime(convertTime);
  }, [convertTime, timeInSeconds, timeFormat]);

  return {
    time,
    setTimeInSeconds,
    rawSeconds: timeInSeconds,
  };
};
