import { useTranslation } from 'react-i18next';

import {
  RechartBarChart,
  RechartLineChart,
  RechartPieChart,
  VerticalBarChart,
} from '@core/components';
import { RechartChartType } from '@core/components/Statistica/types/RechartChart.types.ts';
import { MonthRange } from '@core/utils';

import { ChartTypes } from '@/__generated__/graphql.ts';

export const RechartChart = (props: RechartChartType) => {
  const { t } = useTranslation('charts');

  if (props.isError) {
    return <span>{t('chartCreationError')}</span>;
  }

  if (props.chartType === ChartTypes.BarChart) {
    if (props.displayOptions.showDataInAscendingOrder) {
      return (
        <VerticalBarChart
          {...props}
          transformToMonthLegend={true}
          monthsRange={MonthRange.getRangeToSelectedMonth(
            props.selectedMonth + 1,
          )}
        />
      );
    }

    return (
      <RechartBarChart
        {...props}
        transformToMonthLegend={true}
        monthsRange={MonthRange.months}
      />
    );
  }

  if (props.chartType === ChartTypes.LineChart) {
    return (
      <RechartLineChart
        {...props}
        transformToMonthLegend={true}
        lineWidth={2}
      />
    );
  }

  if (props.chartType === ChartTypes.PieChart) {
    return (
      <RechartPieChart
        {...props}
        selectedMonth={props.selectedMonth}
        monthsRange={MonthRange.getRangeToSelectedMonth(
          props.selectedMonth + 1,
        )}
      />
    );
  }

  return <span>{t('notImplemented')}</span>;
};
