import { useTranslation } from 'react-i18next';

import { Box, Menu, Typography, styled } from '@mui/material';

import { NotificationsButton } from '@core/modules';
import { NewNotificationTab } from '@core/modules/Notifications/components/Tabs/NewNotificationTab.tsx';
import { NotificationTab } from '@core/modules/Notifications/components/Tabs/NotificationTab.tsx';
import { useNotificationsContext } from '@core/modules/Notifications/context/NotificationsContext.tsx';

import { TabViewWrapper } from '@/core/components';
import { useMenu } from '@/core/hooks';

import { getTabName } from '../utils/formatNewNotifications';

export const NotificationsComponent = () => {
  const { t } = useTranslation();

  const { anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose } = useMenu();

  const { shouldDisplayDot, unOpenedNotifications } = useNotificationsContext();

  return (
    <>
      <NotificationsButton
        handleMenuOpen={handleMenuOpen}
        shouldDisplayDot={shouldDisplayDot}
      />

      <CustomMenu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
      >
        <Container id="main-container">
          <CustomTypography variant="h4">
            {t('notification.title')}
          </CustomTypography>

          <TabViewWrapper
            tabs={[
              {
                tabName: t('notification.allNotifications'),
                tabComponent: <NotificationTab />,
              },
              {
                tabName: getTabName(
                  t('notification.newNotifications'),
                  unOpenedNotifications.length,
                ),
                tabComponent: <NewNotificationTab />,
              },
            ]}
          />
        </Container>
      </CustomMenu>
    </>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0 0 0',
  minWidth: '500px',
  maxHeight: 'calc(100vh - 100px)',
  overflowY: 'auto',
});

const CustomTypography = styled(Typography)({
  marginBottom: '20px',
  marginLeft: '20px',
});

const CustomMenu = styled(Menu)({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '> div': {
    overflow: 'hidden',
  },
});
