import { useTranslation } from 'react-i18next';

import { useCreateChartContext } from '@kpi/modals/HandleKpiChartModal/context';

import { ArrowBack } from '@mui/icons-material';

import { IconWrapper } from '@core/components/Icons/Wrapper/IconWrapper.tsx';

export const ModalHeader = () => {
  const { t: commonT } = useTranslation();

  const { isSelectKpisView, setSettingsView } = useCreateChartContext();

  if (!isSelectKpisView) return null;

  return (
    <IconWrapper
      icon={<ArrowBack />}
      onClick={() => setSettingsView()}
      title={commonT('common.back')}
    />
  );
};
