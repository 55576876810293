import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OperationIcon } from '@e-flow/components';
import {
  SelectEditInputCell,
  TimeEditInputCell,
} from '@e-flow/pages/flowDashboard/components';
import { TimeCell } from '@e-flow/pages/flowDashboard/components/EflowTable/TimeCell.tsx';
import { SystemParameterKeysEnum } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum.ts';

import {
  ModeCommentOutlined,
  MoreVert,
  PhotoOutlined,
} from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid';

import { TimeInputFormatTypes } from '@core/components/inputs/TimeInput/types';
import { FlexCenter } from '@core/styles';

import {
  EFlow,
  ParameterObject,
  ParameterTypes,
  ParameterUnitsTypes,
} from '@/__generated__/graphql.ts';
import { convertTime, getTimeAsString } from '@/core/hooks';
import { isTimeParameter } from '@/core/utils';
import { theme } from '@/theme';

import { useDisplayHeaders } from '../../../hooks';
import { HandleOpenMenu } from '../EflowTable.types';

export const useEflowHeader = (
  parameters: EFlow['parameters'],
  handleMouseOpen: HandleOpenMenu,
  summedParams: { [key: string]: number },
  notDisplayedHeaders: string[],
) => {
  const { t } = useTranslation('eflow');

  const formatHeaderName = (
    headerName: string,
    parameter: ParameterObject,
    isTime: boolean = false,
  ) => {
    let headerNameOutput = headerName;

    if (!summedParams[parameter.name]) return headerNameOutput;
    if (isTime)
      headerNameOutput += ` (${getTimeAsString(convertTime(summedParams[parameter.name], parameter.unit), parameter.unit)})`;
    else headerNameOutput += ` (${summedParams[parameter.name]})`;
    return headerNameOutput;
  };

  const {
    isOperationDisplayed,
    isOperationNameDisplayed,
    areAttachmentsDisplayed,
    areCommentsDisplayed,
    areOptionsDisplayed,
  } = useDisplayHeaders(notDisplayedHeaders);

  const renderTimeInput = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
    paramUnit: ParameterUnitsTypes,
  ) => {
    return (
      <TimeEditInputCell
        format={paramUnit as TimeInputFormatTypes}
        {...params}
      />
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createParameterMap = () => {
    if (!parameters) return [];

    return parameters.map((parameter) => {
      if (notDisplayedHeaders.includes(parameter.name)) return;

      const systemParam =
        parameter.type === ParameterTypes.System
          ? t(
              `newAnalysis.parameters.systemParams.${parameter.name as SystemParameterKeysEnum}`,
            )
          : parameter.name;

      const paramUnit =
        parameter.type === ParameterTypes.System
          ? t(`newAnalysis.parameters.params.${parameter.unit}`)
          : parameter.name;

      return isTimeParameter(parameter.unit)
        ? renderTimeParam(parameter, `${systemParam} - ${paramUnit}`)
        : {
            field: parameter.name,
            headerName: formatHeaderName(
              `${systemParam} - ${t(`newAnalysis.parameters.params.${parameter.unit}`)}`,
              parameter,
            ),
            // editable: true, TODO: enable when backend is ready
            flex: 1,
            renderCell: (params: { value?: number | string }) =>
              params.value || (
                <span style={{ color: theme.palette.grey[500], flex: 1 }}>
                  {systemParam}
                </span>
              ),
          };
    });
  };

  const renderTimeParam = (parameter: ParameterObject, headerName: string) => ({
    field: parameter.name,
    headerName: formatHeaderName(headerName, parameter, true),
    // editable: true, TODO: enable when backend is ready
    flex: 1,
    renderEditCell: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
    ) => renderTimeInput(params, parameter.unit),
    renderCell: (params: { value?: number | string }) => {
      return (
        <TimeCell
          {...params}
          format={parameter.unit}
          placeholder={headerName}
        />
      );
    },
  });

  const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  return useCallback(() => {
    return [
      { field: 'index', headerName: '', editable: false, width: 50 },
      isOperationDisplayed && {
        field: 'operation',
        headerName: '',
        renderEditCell: renderSelectEditInputCell,
        // When I try to put anything else here, eslint complains
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderCell: (params: any) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          params.value && (
            <FlexCenter>
              <OperationIcon operation={params.value} />
            </FlexCenter>
          ),
        // editable: true, TODO: enable when backend is ready
        width: 20,
      },
      isOperationNameDisplayed && {
        field: 'operationName',
        headerName: t('eflow.table.operationName'),
        // editable: true, TODO: enable when backend is ready
        flex: 1,
      },
      //custom

      ...createParameterMap(),

      // per system
      areAttachmentsDisplayed && {
        field: 'attachments',
        headerName: '',
        renderCell: (params: { value?: number }) => (
          <FlexCenter>
            <IconButton>
              <Badge color="primary" badgeContent={params.value}>
                <PhotoOutlined />
              </Badge>
            </IconButton>
          </FlexCenter>
        ),
        editable: false,
        width: 20,
      },

      areCommentsDisplayed && {
        field: 'comments',
        headerName: '',
        renderCell: (params: { value?: number }) => (
          <FlexCenter>
            <IconButton>
              <Badge color="primary" badgeContent={params.value}>
                <ModeCommentOutlined />
              </Badge>
            </IconButton>
          </FlexCenter>
        ),
        editable: false,
        width: 20,
      },
      areOptionsDisplayed && {
        field: 'options',
        headerName: '',
        renderCell: (params: GridRenderCellParams) => (
          <FlexCenter>
            <IconButton
              onClick={(e) => {
                handleMouseOpen(
                  e as unknown as MouseEvent,
                  String(params.id),
                  Number(params.row.index) - 1,
                  params.row.isActive as boolean,
                );
              }}
            >
              <MoreVert />
            </IconButton>
          </FlexCenter>
        ),
        editable: false,
        width: 20,
      },
    ].filter((columnHead) => columnHead) as GridColDef[];
  }, [
    areAttachmentsDisplayed,
    areCommentsDisplayed,
    areOptionsDisplayed,
    createParameterMap,
    handleMouseOpen,
    isOperationDisplayed,
    isOperationNameDisplayed,
    t,
  ]);
};
