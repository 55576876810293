/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { CommissionRole } from '@/core/enums';
import { useModal } from '@/core/hooks';
import { DeleteCommissionMemberModal } from '@/pages/kaizen/module-settings/Commission/modals';
import { DeleteLeaderInfoModal } from '@/pages/kaizen/module-settings/Commission/modals/DeleteLeaderInfoModal/DeleteLeaderInfoModal';

import { useCommission } from '../hooks/useCommission/useCommission';
import { KaizenCommissionRowProps } from './KaizenCommissionRow.types';
import { useCommissionRole } from './useCommissionRole';

export const KaizenCommissionRow = ({
  row,
  onRemove,
  onUpdate,
}: KaizenCommissionRowProps) => {
  const { t } = useTranslation('kaizen');

  const { getCommissionRole } = useCommissionRole();

  const { removeCommissionMember, setCommissionLeader } = useCommission();

  const { isModalVisible, setIsModalVisible } = useModal();

  const {
    isModalVisible: isLeaderModalVisible,
    setIsModalVisible: setIsLeaderModalVisible,
  } = useModal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  return (
    <>
      <RowTable key={row.id}>
        <TableCell component="th" scope="row">
          {getCommissionRole(row.commissionRole)}
        </TableCell>
        <TableCell>
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell key="menu">
          <IconButton
            aria-label="more"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                (row.commissionRole as CommissionRole) ===
                CommissionRole.COMMISSION_MEMBER
                  ? setIsModalVisible(true)
                  : setIsLeaderModalVisible(true);
                setAnchorEl(null);
              }}
            >
              <Typography>{t('commission.removeFromCommission')}</Typography>
            </MenuItem>
            {(row.commissionRole as CommissionRole) ===
              CommissionRole.COMMISSION_MEMBER && (
              <MenuItem
                onClick={async () => {
                  void (await setCommissionLeader(row.id));
                  onUpdate();
                  setAnchorEl(null);
                }}
              >
                <Typography>{t('commission.setAsLeader')}</Typography>
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      </RowTable>
      <DeleteCommissionMemberModal
        open={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        remove={async () => {
          await removeCommissionMember(row.id);
          onRemove();
          setIsModalVisible(false);
        }}
      />
      <DeleteLeaderInfoModal
        open={isLeaderModalVisible}
        closeModal={() => setIsLeaderModalVisible(false)}
      />
    </>
  );
};

const RowTable = styled(TableRow)({
  //eslint-disable-next-line
  '&:last-child td, &:last-child th': { border: 0 },
});
