import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { IconType } from '@core/components/Icons/Wrapper/type.ts';

import { IconWrapper } from './Wrapper/IconWrapper.tsx';

export const NavigateNext = (props: IconType) => {
  return (
    <IconWrapper
      {...props.wrapperProps}
      icon={<NavigateNextIcon {...props.iconProps} />}
    />
  );
};
