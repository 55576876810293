import { useSubscribeNotificationsSubscription } from '@/__generated__/graphql.ts';

export const useNotificationSubscription = () => {
  const { data } = useSubscribeNotificationsSubscription({
    shouldResubscribe: true,
  });

  return {
    newNotification: data?.notifications,
  };
};
