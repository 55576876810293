import { ReactNode, createContext, useContext } from 'react';

import { FilePreview } from '@/core/components';
import { useFilePreviewView } from '@/core/hooks';

type FilePreviewContextType = ReturnType<typeof useFilePreviewView>;

const FilePreviewContext = createContext<FilePreviewContextType | undefined>(
  undefined,
);

// eslint-disable-next-line react-refresh/only-export-components
export const useFilePreviewContext = (): FilePreviewContextType => {
  const context = useContext(FilePreviewContext);
  if (!context) {
    throw new Error(
      'useSnackbarContext must be used within a SnackbarProvider',
    );
  }
  return context;
};

export const FilePreviewProvider = ({ children }: { children: ReactNode }) => {
  const previewControls = useFilePreviewView();

  return (
    <FilePreviewContext.Provider value={previewControls}>
      {children}
      {!!previewControls.file && (
        <FilePreview
          file={previewControls.file}
          onClose={previewControls.closePreview}
        />
      )}
    </FilePreviewContext.Provider>
  );
};
