import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { useTimestamp } from '@core/hooks';

import { SubmissionDetailsProps } from '../../SubmissionDetails.types';
import { DetailRow, DetailRowLabel } from '../styles/styles';

export const SubmissionDatesSection = (props: SubmissionDetailsProps) => {
  const { t } = useTranslation('kaizen');

  const { toDdMmYyyy } = useTimestamp();

  return (
    <>
      <DetailRow>
        <DetailRowLabel>{t('submissionDetails.createdAt')}</DetailRowLabel>
        <Typography>{toDdMmYyyy(props.createdAt)}</Typography>
      </DetailRow>
      <DetailRow>
        <DetailRowLabel>{t('submissionDetails.updatedAt')}</DetailRowLabel>
        <Typography>{toDdMmYyyy(props.updatedAt)}</Typography>
      </DetailRow>
    </>
  );
};
