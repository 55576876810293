import { CustomModal, ModalContainer } from '@core/components';

import { useAddKpiModal } from './hooks/useAddKpiModal';
import { AddKpiModalProps } from './types/AddKpiModal.types';
import { KpiChartList } from './views/KpiChartList/KpiChartList';
import { KpiChartPreview } from './views/KpiChartPreview/KpiChartPreview';

export const AddKpiChartModal = ({ isOpen, closeModal }: AddKpiModalProps) => {
  const {
    isKpiChartPreview,
    isListKpiChartView,
    setSelectedChart,
    selectedChart,
  } = useAddKpiModal();

  return (
    <CustomModal display={isOpen}>
      <ModalContainer
        closeModal={closeModal}
        sx={{ minHeight: '516px', minWidth: '680px' }}
        headerText="WYBIERZ WYKRES KPI"
      >
        {isListKpiChartView && (
          <KpiChartList setSelectedChart={setSelectedChart} />
        )}
        {isKpiChartPreview && (
          <KpiChartPreview
            chartId={selectedChart}
            setSelectedChart={setSelectedChart}
          />
        )}
      </ModalContainer>
    </CustomModal>
  );
};
