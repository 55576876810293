import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { IconType } from '@core/components/Icons/Wrapper/type.ts';

import { IconWrapper } from './Wrapper/IconWrapper.tsx';

export const Delete = (props: IconType) => {
  return (
    <IconWrapper
      {...props.wrapperProps}
      icon={<DeleteOutlineIcon {...props.iconProps} />}
    />
  );
};
