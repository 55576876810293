import { FC } from 'react';
import { NavLink as RNavLink } from 'react-router-dom';

import { Typography, styled } from '@mui/material';

import { theme } from '@/theme';

import { IMenuNavLinkProps } from '.';

export const MenuNavLink: FC<IMenuNavLinkProps> = ({ title, to }) => {
  const TypographyNavlink = (isActive: boolean, text: string) => (
    <Typography variant={isActive ? 'bodyMedium' : 'bodyRegular'} fontSize={14}>
      {text}
    </Typography>
  );

  return (
    <>
      <CustomNavlink
        to={to}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        {({ isActive }) => TypographyNavlink(isActive, title)}
      </CustomNavlink>
    </>
  );
};

const CustomNavlink = styled(RNavLink)({
  display: 'flex',
  padding: '10px 12px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  width: '100%',
  lineHeight: '20px',
  textDecoration: 'none',
  borderRadius: 'var(--radius-basic, 8px)',
  color: theme.palette.text.primary,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.active': {
    background: `var(--primary-primary-50, ${theme.palette.primary[50]})`,
    fontWeight: 500,
  },
});
