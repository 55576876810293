import React from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchUserAttributes } from '@aws-amplify/auth';

import { Box, Button, styled, useTheme } from '@mui/material';

import { CCognitoUser } from '@core/providers/CognitoUser.ts';

import { useUserStore } from '@/stores';

import { InfoRerouteProps } from './InfoReroute.types';

export const InfoReroute: React.FC<InfoRerouteProps> = ({
  title,
  icon,
  description,
  buttonLabel,
  route,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setUser } = useUserStore();

  const redirectRoute = async () => {
    if (!route) {
      const user = CCognitoUser.transformCognitoAttributes(
        await fetchUserAttributes(),
      );
      setUser({ ...user });
      navigate(`/${user.organizationUrl}`);
    } else {
      navigate(route);
    }
  };

  return (
    <>
      <HeaderContainer>
        {icon}
        <CustomHeader>{title}</CustomHeader>
      </HeaderContainer>
      <CustomDescription>{description}</CustomDescription>
      <RerouteButton
        type="submit"
        variant="contained"
        size="medium"
        style={{
          color: theme.palette.common.white,
        }}
        fullWidth
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={redirectRoute}
      >
        {buttonLabel}
      </RerouteButton>
    </>
  );
};

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  svg: {
    fontSize: '32px',
  },
});

const CustomHeader = styled('h2')({
  margin: 0,
  fontSize: '24px',
  paddingTop: '2px',
});

const CustomDescription = styled('p')({
  margin: 0,
  marginBottom: '16px',
});

const RerouteButton = styled(Button)({
  fontFamily: 'Inter',
  fontWeight: '500',
  height: '40px',
  lineHeight: '20px',
  textTransform: 'none',
});
