import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { zodResolver } from '@hookform/resolvers/zod';

import { TextField, Typography, styled, useTheme } from '@mui/material';

import { CustomModal, ModalContainer } from '@/core/components';

import type {
  WorkstationModalProps,
  WorkstationToEditProps,
} from './WorkstationModal.types';
import { WorkstationNameSchema } from './WorkstationModals.schema';

export const EditWorkstationModal = ({
  closeModal,
  setWorkstations,
  workstations,
  workstationToEdit,
}: WorkstationModalProps & WorkstationToEditProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    defaultValues: {
      workstationName: workstationToEdit,
    },
    resolver: zodResolver(
      WorkstationNameSchema(
        t('error.requiredField'),
        t('organizationSettings.workstationMaxLength'),
      ),
    ),
  });

  const onSubmit: SubmitHandler<{
    workstationName: string;
  }> = async (data) => {
    if (workstations.includes(data.workstationName)) {
      setError('workstationName', {
        message: t('organizationSettings.workstationExists'),
      });
      return;
    }
    const newWorkstations = [...workstations];
    newWorkstations[newWorkstations.indexOf(workstationToEdit)] =
      data.workstationName;
    await setWorkstations([...newWorkstations], t('common.changesWereSaved'));
    reset();
    closeModal();
  };

  return (
    <>
      <CustomModal display={true}>
        <ModalContainer
          closeModal={closeModal}
          headerText={t('organizationSettings.editWorkstation')}
          height="auto"
          sx={{ maxWidth: '528px' }}
          width="100%"
        >
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <CustomTextField
              {...register('workstationName')}
              fullWidth
              size="small"
              label={t('organizationSettings.workstationName')}
              error={!!errors.workstationName}
              helperText={errors.workstationName?.message}
            />

            <CustomStack>
              <PrimaryButton onClick={closeModal}>
                <Typography>{t('common.cancel')}</Typography>
              </PrimaryButton>
              <PrimaryButton
                data-testid="addButtonModal"
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary[600],
                }}
                type="submit"
              >
                <Typography>{t('common.save')}</Typography>
              </PrimaryButton>
            </CustomStack>
          </CustomForm>
        </ModalContainer>
      </CustomModal>
    </>
  );
};

const CustomTextField = styled(TextField)({
  width: '100%',
  margin: '0 24px',
  maxWidth: '480px',
});

const CustomForm = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const CustomStack = styled('div')({
  padding: '16px 24px 24px 24px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
  flexDirection: 'row',
});
