import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { AddOrganizationTypes } from '@organizations/pages/addOrganization/AddOrganization.types.ts';
import {
  AddOrganizationValidationSchema,
  AddOrganizationValidationSchemaDefaultValues,
} from '@organizations/pages/addOrganization/AddOrgaznizationValidationSchema.ts';
import { Modules } from '@organizations/pages/addOrganization/Modules/Modules.tsx';
import * as Sentry from '@sentry/browser';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from '@mui/material';

import {
  CreateOrganisationInput,
  useCreateOrganisationMutation,
} from '@/__generated__/graphql';
import {
  NavbarMainContainer,
  NavbarTitle,
  NavbarUserMenu,
} from '@/core/styles/Navbar';

import { AddNewOrganization } from './AddNewOrganization/AddNewOrganization';

export const AddOrganization = ({ close }: AddOrganizationTypes) => {
  const { t } = useTranslation();

  const [createOrganization, { loading: isLoading }] =
    useCreateOrganisationMutation();

  const steps = [
    t('organization.organizationData'),
    t('organization.selectModules'),
  ];
  const [step, setStep] = useState(0);

  /**
   * Handling of the form
   * @param defaultValues
   * @returns {void}
   */
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: AddOrganizationValidationSchemaDefaultValues,
    resolver: zodResolver(
      AddOrganizationValidationSchema(
        t('errors.minLength.3'),
        t('errors.maxLength.50'),
        t('error.invalidUrlFormat'),
      ),
    ),
  });

  const [logo, setLogo] = useState<File>();

  useEffect(() => {
    if (errors.name || errors.url) {
      setStep(0);
    }
  }, [errors, errors.name, errors.url]);
  /**
   * Handling of the form submits and sending of the data to the backend
   * @param data
   */
  const onSubmit = async (data: CreateOrganisationInput) => {
    try {
      await createOrganization({
        variables: {
          input: {
            ...data,
          },
          file: logo,
        },
      });
      close();
    } catch (error) {
      if (error instanceof Error) {
        const errorValue = JSON.parse(error.message);
        if (errorValue?.errorMessage.includes('ORGANIZATION_NAME_TAKEN')) {
          setError('name', {
            type: 'name_taken',
            message: t('organizationSettings.organizationNameTaken'),
          });
        } else if (errorValue?.errorMessage.includes('URL_TAKEN')) {
          setError('url', {
            type: 'url_taken',
            message: t('systemSettings.organizationUrlTaken'),
          });
        } else Sentry.captureException(error);
      } else Sentry.captureException(error);
    }
  };

  return (
    <>
      {/*Header navbar*/}
      <NavbarMainContainer height="56px">
        <NavbarUserMenu>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>

          <NavbarTitle>
            <Typography variant="bodyRegular">
              {t('organization.addOrganization')}
            </Typography>
          </NavbarTitle>
        </NavbarUserMenu>
      </NavbarMainContainer>
      {/*Organization Side panel*/}
      <Stack display="flex" flexDirection="row">
        <SideBox>
          <SideBoxContent>
            <Stepper orientation="vertical" activeStep={step}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <Typography variant="bodyRegular">{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </SideBoxContent>
        </SideBox>
        {/* Organization form */}
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack width="528px" padding="24px" gap="32px">
            {step === 0 ? (
              // First step of the form
              <AddNewOrganization
                register={register}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                logo={logo}
                setLogo={(file: File | undefined) => setLogo(file)}
                nextStep={() => setStep((prevState) => prevState + 1)}
              />
            ) : (
              // Second step of the form
              <Modules
                register={register}
                setValue={setValue}
                stepBack={() => setStep((prevState) => prevState - 1)}
                isLoading={isLoading}
              />
            )}
          </Stack>
        </form>
      </Stack>
    </>
  );
};

const SideBox = styled(Box)({
  width: '264px',
  height: 'calc(100vh - 56px)',
  borderRight: '1px solid var(--neutrals-gray-200, #dcdfe1)',
});

const SideBoxContent = styled(Box)({
  width: '264px',
  height: '100%',
  padding: '16px 24px 8px 24px',
});
