import { EmployeeState, SystemRoles } from '@/__generated__/graphql';
import { TreeTarget } from '@/types';

type RequestParams = {
  search?: string;
  positions?: string[];
  systemRoles?: SystemRoles[];
  organisationUnitIds?: string[];
  employeeState?: EmployeeState[];
};

export class RequestBuilder {
  private debounceSearch: string = '';
  private position: string[] = [];
  private systemRoles: SystemRoles[] = [];
  private organizationUnit: TreeTarget = {
    targetId: '',
    path: '',
  };
  private employeeState: EmployeeState[] = [];

  constructor() {}
  addDebounceSearch(search: string) {
    this.debounceSearch = search;
  }

  addPosition(position: (string | null)[]) {
    this.position = position.filter((pos) => pos !== null);
  }

  addSystemRoles(systemRoles: SystemRoles[]) {
    this.systemRoles = systemRoles;
  }

  addOrganizationUnit(organizationUnit: TreeTarget) {
    this.organizationUnit = organizationUnit;
  }

  addEmployeeState(employeeState: EmployeeState[]) {
    this.employeeState = employeeState;
  }

  build(): RequestParams {
    return {
      ...(this.debounceSearch && { search: this.debounceSearch }),
      ...(this.position.length > 0 && { positions: [...this.position] }),
      ...(this.systemRoles && { systemRoles: this.systemRoles }),
      ...(this.organizationUnit.targetId && {
        organisationUnitIds: [this.organizationUnit.targetId],
      }),
      ...(this.employeeState.length > 0 && {
        employeeState: [...this.employeeState],
      }),
    };
  }
}
