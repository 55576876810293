import { allowedExtensionsForKaizen } from '@kaizen/views/ProblemSolutionView/components/ALLOWED_EXTENSIONS.ts';
import { useProblemSolution } from '@kaizen/views/ProblemSolutionView/hooks/useProblemSolution.tsx';

import { Typography, styled } from '@mui/material';

import { RtfTextEditor } from '@core/components/TextEditors/RtfTextEditor/RtfTextEditor.tsx';

import {
  ErrorWrapper,
  FilesDropDownInput,
  FilesTable,
  StepFormViewWrapper,
} from '@/core/components';

import { ProblemSolutionViewTypes } from './ProblemSolutionView.types.ts';

export const ProblemSolutionView = (props: ProblemSolutionViewTypes) => {
  const {
    files,
    removeFile,
    setDescription,
    addFiles,
    onSubmit,
    onPreviousStep,
    setValue,
    getRtfDescriptionLength,
    isError,
    errorMessage,
  } = useProblemSolution({
    onNextStep: props.onNextStep,
    onPreviousStep: props.onPreviousStep,
    defaultValue: props.defaultValue,
  });

  return (
    <>
      <StepFormViewWrapper
        title={props.title}
        subtitle={props.subtitle}
        withPreviousStep={true}
        withNextStep={true}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={onSubmit}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onNextStep={onSubmit}
        onPreviousStep={onPreviousStep}
        withMiddleButton={false}
      >
        <FormSection>
          <ErrorWrapper errorMessage={errorMessage!} showError={isError}>
            <RtfTextEditor
              maxLength={5000}
              defaultValue={props.defaultValue.description}
              onChange={(rtfDescription) => {
                setDescription(rtfDescription);
                setValue(
                  'descriptionLength',
                  getRtfDescriptionLength(rtfDescription),
                );
              }}
            />
          </ErrorWrapper>

          <FilesTable files={files} onFileRemove={removeFile} />
          <Typography variant="bodyRegular"></Typography>
          <FilesDropDownInput
            allowedExtensions={allowedExtensionsForKaizen}
            onChange={(newFiles: File[]) => {
              addFiles(newFiles);
              return '';
            }}
          />
        </FormSection>
      </StepFormViewWrapper>
    </>
  );
};

const FormSection = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});
