import { SystemRoles } from '@/__generated__/graphql.ts';

/**
 * Function to check if user is permitted to do some action
 * @returns true if user is permitted for common admin actions
 * @param userGroups
 * @param userOrganizationId
 * @param organizationId
 */
export const userIsPermitted = (
  userGroups: SystemRoles[],
  userOrganizationId: string,
  organizationId: string,
) => {
  if (
    userGroups.includes(SystemRoles.Root) ||
    userGroups.includes(SystemRoles.SystemAdmin)
  )
    return true;
  if (
    userGroups.includes(SystemRoles.Administrator) &&
    userOrganizationId === organizationId
  )
    return true;
  if (
    userGroups.includes(SystemRoles.KeyUser) &&
    userOrganizationId !== organizationId
  )
    return true;
  return false;
};
