import { useState } from 'react';

import { DashboardWidgetContentType } from '@core/modules/DashbordModule/types';

export const useDashboardWidget = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState<string | undefined>();
  const [widgetContent, setWidgetContent] =
    useState<DashboardWidgetContentType>();

  return {
    isLoading,
    widgetTitle,
    widgetContent,
    setIsLoading,
    setWidgetTitle,
    setWidgetContent,
  };
};
