import { Box, Skeleton, Typography, styled } from '@mui/material';

import { SectionWithTitleTypes } from '@core/components/Sections/SectionWithTitle/SectionWithTitle.types.ts';

export const SectionWithTitle = (props: SectionWithTitleTypes) => {
  if (props.isLoading) {
    return (
      <SectionContainer component={'section'}>
        {props.title && <Typography variant={'h6'}>{props.title}</Typography>}
        <Skeleton variant="rectangular" width={'100%'} height={118} />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer component="section">
      {props.title && <Typography variant={'h6'}>{props.title}</Typography>}
      {props.children}
    </SectionContainer>
  );
};

const SectionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
}) as typeof Box;
