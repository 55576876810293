import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, styled } from '@mui/material';

import { LineChartWithDummyData } from '@core/components';

import { theme } from '@/theme';

import { AddKpiChartModal } from '../../modals/AddKpiModal/AddKpiModal';

export const KpiChartSidebarView = () => {
  const { t } = useTranslation('dashboard');

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <StyledCard
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <LineChartWithDummyData height="500px" title={t('kpi.chart')} />
      </StyledCard>
      <AddKpiChartModal isOpen={isOpen} closeModal={handleClose} />
    </>
  );
};

const StyledCard = styled(Card)({
  gap: '16px',
  padding: '24px',
  borderRadius: '8px',
  cursor: 'pointer',
  // eslint-disable-next-line
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
});
