import { SystemRoles } from '@/__generated__/graphql.ts';

/**
 * Create a change set for system roles
 * @param newSystemRole {SystemRoles} - new system role which should be added
 * @param oldSystemRoles {SystemRoles[]} - old system roles
 *
 * @returns {SystemRoles[]} - new system roles
 *
 * @example
 * createSystemRolesChangeSet(SystemRoles.UnitAdmin, [SystemRoles.User, SystemRoles.EFlowSpectator]);
 * // Returns [SystemRoles.EFlowSpectator, SystemRoles.User]
 */
export function createSystemRolesChangeSet(
  newSystemRole: SystemRoles,
  oldSystemRoles: SystemRoles[],
): SystemRoles[] {
  const userRolesWithoutSystemRoles = oldSystemRoles.filter(
    (role) =>
      ![
        SystemRoles.User,
        SystemRoles.Administrator,
        SystemRoles.Root,
        SystemRoles.SystemAdmin,
        SystemRoles.KeyUser,
        SystemRoles.UnitAdmin,
      ].includes(role),
  );

  return [...userRolesWithoutSystemRoles, newSystemRole];
}
