import { useTranslation } from 'react-i18next';

import { ProgressSnackbar } from '@/core/components';
import { useProgress } from '@/core/hooks';

import { FlowStepsContent } from './FlowStepsContent.tsx';
import { StepProgressContext } from './context/index.ts';

export const FlowSteps = () => {
  const { t } = useTranslation('eflow');

  const progress = useProgress();

  return (
    <StepProgressContext.Provider
      value={{
        stepProgressValue: progress.progress,
        setStepProgressValue: progress.setProgress,
        stepProgressSnackbarOpen: progress.isProgressSnackbarOpen,
        setStepProgressSnackbarOpen: progress.setIsProgressSnackbarOpen,
      }}
    >
      <FlowStepsContent />
      <ProgressSnackbar
        open={progress.isProgressSnackbarOpen}
        progress={progress.progress}
        text={t('flowSteps.updateInProgress')}
      />
    </StepProgressContext.Provider>
  );
};
