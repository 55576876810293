import {
  Box,
  LinearProgress,
  Snackbar,
  Typography,
  styled,
} from '@mui/material';

import { ProgressSnackbarProps } from '@core/components/Snackbars/ProgressSnackbar/ProgrssSnackbar.types.ts';

export const ProgressSnackbar = (props: ProgressSnackbarProps) => {
  return (
    <Snackbar open={props.open} onClose={props.onClose}>
      <StyledSnackbarContent>
        {props.text}
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={props.progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.progress,
              )}%`}</Typography>
            </Box>
          </Box>
        </Box>
      </StyledSnackbarContent>
    </Snackbar>
  );
};

const StyledSnackbarContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: 300,
  background: 'white',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  padding: 5,
  paddingTop: 10,
  borderRadius: 5,
});
