import { useTranslation } from 'react-i18next';

import { Stack, styled } from '@mui/material';

import { Button } from '@core/components';

import { useHandleChartVisibilityContext } from './context/useHandleChartVisibilityContext';

export const ButtonSection = () => {
  const { t: commonT } = useTranslation();

  const { close } = useHandleChartVisibilityContext();

  return (
    <CustomButtonsContainer>
      <Button
        variant="contained"
        onClick={() => {
          close();
        }}
      >
        {commonT('common.save')}
      </Button>
    </CustomButtonsContainer>
  );
};

const CustomButtonsContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '12px',
});
