import { useEffect, useState } from 'react';

import { KaizenSettlementPeriodFragment } from '@/__generated__/graphql.ts';

import {
  useAddSettlementPeriod,
  useFetchSettlementPeriods,
  useRemoveSettlementPeriod,
  useUpdateSettlementPeriod,
} from './Functions';

export const useSettlementPeriod = () => {
  const settlementPeriods = useFetchSettlementPeriods();
  const updateSettlementPeriod = useUpdateSettlementPeriod();
  const createSettlementPeriod = useAddSettlementPeriod();
  const removeSettlementPeriod = useRemoveSettlementPeriod();

  const [currentActivePeriod, setCurrentActivePeriod] =
    useState<KaizenSettlementPeriodFragment>();

  useEffect(() => {
    if (settlementPeriods.settlementPeriods) {
      const activePeriod = settlementPeriods.settlementPeriods.find(
        (period) => period.isActive,
      );

      if (activePeriod) {
        setCurrentActivePeriod(activePeriod);
      }
    }
  }, [settlementPeriods.settlementPeriods]);

  return {
    currentActivePeriod,
    ...createSettlementPeriod,
    ...settlementPeriods,
    ...updateSettlementPeriod,
    ...removeSettlementPeriod,
  };
};
