import { useTranslation } from 'react-i18next';

import { Box, Typography, styled } from '@mui/material';

import { Button, CustomModal, ModalContainer } from '@core/components';
import { RemoveItemModalTypes } from '@core/components/Modals/RemoveItemModal/RemoveItemModal.types.ts';

import { theme } from '@/theme';

export const RemoveItemModal = (props: RemoveItemModalTypes) => {
  const { t } = useTranslation();

  return (
    <CustomModal display={props.display}>
      <ModalContainer
        closeModal={props.closeModal}
        headerText={props.headerText}
        sx={{ ...props.sx, minWidth: '500px' }}
      >
        <ModalBody>
          {props.content instanceof String ? (
            <CustomText
              variant="bodyRegular"
              sx={{ fontSize: 16 }}
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          ) : (
            <CustomText variant="bodyRegular" sx={{ fontSize: 16 }}>
              {' '}
              {props.content}
            </CustomText>
          )}
          <ButtonContainer>
            <Button
              variant={'text'}
              onClick={props.closeModal}
              disabled={false}
              typographySx={{
                color: theme.palette.common.black,
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              color={'error'}
              variant={'contained'}
              onClick={props.onRemove}
            >
              {props.deleteButtonText
                ? props.deleteButtonText
                : t('common.delete')}
            </Button>
          </ButtonContainer>
        </ModalBody>
      </ModalContainer>
    </CustomModal>
  );
};

const ModalBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '0 20px 20px',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
});

const CustomText = styled(Typography)({
  marginBottom: '20px',
  whiteSpace: 'pre-wrap',
});
