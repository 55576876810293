import { SyntheticEvent, useEffect, useState } from 'react';

import { useDebounceValue } from 'usehooks-ts';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import {
  GetEmployeesForMultiSelectQuery,
  useGetEmployeesForMultiSelectLazyQuery,
} from '@/__generated__/graphql';
import { Unpacked } from '@/types/unpacked';

import { UserSelectItem } from '../UserSelect/UserSelectItem';
import { UserMultiSelectTypes } from './UserMultiSelect.types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const UserMultiSelect = ({
  width = '340px',
  limitTags = 1,
  ...props
}: UserMultiSelectTypes) => {
  const [limit] = useState<number>(20);
  const [cursor, setCursor] = useState<number>(0);

  const [bouncingInputSearch, setBouncingInputSearch] = useDebounceValue<
    string | null
  >(null, 500);
  const [inputValue, setInputValue] = useState('');

  const [displayedUsers, setDisplayedUsers] = useState<
    GetEmployeesForMultiSelectQuery['getEmployees']['items']
  >([]);

  const [loadEmployees, { loading: isLoading }] =
    useGetEmployeesForMultiSelectLazyQuery();

  useEffect(() => {
    if (bouncingInputSearch === null) return;
    setCursor(0);
    setDisplayedUsers([]);
    void (async () => {
      await loadUsers();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bouncingInputSearch]);

  const loadUsers = async () => {
    const { data } = await loadEmployees({
      variables: {
        input: {
          ...props.employeeParams,
          limit,
          cursor,
          ...(bouncingInputSearch ? { search: bouncingInputSearch } : {}),
        },
      },
    });

    if (data) {
      setDisplayedUsers((p) => [...p, ...data.getEmployees.items]);
    }
    if (data && data?.getEmployees.total > cursor + limit)
      setCursor(cursor + limit);
  };

  return (
    <Autocomplete
      isOptionEqualToValue={(
        option: Unpacked<
          GetEmployeesForMultiSelectQuery['getEmployees']['items']
        >,
        value: Unpacked<
          GetEmployeesForMultiSelectQuery['getEmployees']['items']
        >,
      ) => option.id === value.id}
      disableClearable={true}
      limitTags={limitTags}
      multiple
      size={'small'}
      loading={isLoading}
      sx={{ width: width }}
      loadingText={<CircularProgress />}
      options={displayedUsers}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      disableCloseOnSelect
      value={props.selectedUsers}
      onOpen={() => {
        if (displayedUsers.length === 0) void loadUsers();
      }}
      onChange={(_e, employee) => {
        props.onSelectedUsers(employee);
      }}
      onInputChange={(_e, search, reason) => {
        if (reason === 'input') {
          setInputValue(search);
          setBouncingInputSearch(search);
        }
      }}
      ListboxProps={{
        onScroll: (event: SyntheticEvent) => {
          const listNode = event.currentTarget;
          if (listNode.scrollTop >= listNode.scrollHeight * 0.7) {
            setCursor((prevCursor) => prevCursor + limit);
            void loadUsers();
          }
        },
      }}
      renderOption={(
        liOptions,
        option: Unpacked<
          GetEmployeesForMultiSelectQuery['getEmployees']['items']
        >,
        { selected },
      ) => (
        <li {...liOptions} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <UserSelectItem {...option} />
        </li>
      )}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          value={inputValue}
          sx={{ borderRadius: 'radius-basic' }}
          label={props.label || ''}
        />
      )}
    />
  );
};
