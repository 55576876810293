import { useParams } from 'react-router-dom';

import { useCommentCategories } from '@e-flow/components/CommentModule/hooks';
import { cloneDeep } from 'lodash';

import {
  AddEflowCommentInput,
  EFlowStepCommentDto,
  FindOneEflowStepDocument,
  FindOneEflowStepQuery,
} from '@/__generated__/graphql.ts';
import { client } from '@/apollo';
import { useUserStore } from '@/stores';

import { LocalCommentType } from '../../types';
import { UseCommentModuleType } from './useCommentModule.type';

export const useCommentModule = (props: UseCommentModuleType) => {
  const { userUuid, organizationId } = useUserStore();
  const { commentsCategory } = useCommentCategories(props);

  const { flowId } = useParams();

  const onSubmit = (data: EFlowStepCommentDto) => {
    props.onFlowStepChange([
      ...(props.currentComments || []),
      _createNewComment(data),
    ]);
  };

  const onEdit = (data: {
    id?: string;
    content: string;
    commentCategory: string;
    createdAt: string;
  }) => {
    const updatedComments = props.currentComments?.map((comment) => {
      if ('id' in comment && comment.id === data.id) {
        return _updateComment(comment, data);
      } else if (comment.createdAt === data.createdAt) {
        return _updateComment(comment as EFlowStepCommentDto, data);
      }
      return comment;
    });

    props.onFlowStepChange((updatedComments as EFlowStepCommentDto[]) || []);

    if (!props.stepId) return;
    const result: FindOneEflowStepQuery = cloneDeep(
      client.readQuery({
        query: FindOneEflowStepDocument,
        variables: {
          input: {
            id: props.stepId,
            eFlowId: flowId!,
          },
        },
      }) as FindOneEflowStepQuery,
    );

    if (!result) return;

    result.findOneEflowStep.comments = result.findOneEflowStep.comments!.map(
      (comment) => {
        if ('id' in comment && comment.id === data.id) {
          return _updateComment(comment as EFlowStepCommentDto, data);
        } else if (comment.createdAt === data.createdAt) {
          return _updateComment(comment as EFlowStepCommentDto, data);
        }
        return comment;
      },
    );

    client.writeQuery({
      query: FindOneEflowStepDocument,
      variables: {
        input: {
          id: props.stepId,
          flowId: flowId!,
          organizationId,
        },
      },
      data: result,
    });
  };

  function _createNewComment(
    data: Pick<
      AddEflowCommentInput,
      'content' | 'showInReport' | 'commentCategory'
    >,
  ): LocalCommentType {
    return {
      ...data,
      commentCategory: _createCommentCategory(data.commentCategory!),
      organizationId: organizationId!,
      createdByModel: {
        id: userUuid,
        organizationId: organizationId!,
      },
      createdAt: new Date().getTime().toString(),
    };
  }

  function _updateComment(
    comment: EFlowStepCommentDto,
    data: Pick<EFlowStepCommentDto, 'content' | 'commentCategory'>,
  ) {
    return {
      ...comment,
      commentCategory: _createCommentCategory(data.commentCategory!),
      content: data.content,
    };
  }

  function _createCommentCategory(selectedSymbol?: string) {
    if (!props.commentTypes || !selectedSymbol) {
      throw new Error('Comment types are not defined');
    }
    const commentCategory = commentsCategory.find(
      (category) => category.symbol === selectedSymbol,
    );

    if (!commentCategory) {
      return '';
    }

    return `(${commentCategory.symbol}) - ${commentCategory.categoryName}`;
  }

  return {
    onSubmit,
    onEdit,
  };
};
