import { useFindOneEflowQuery } from '@/__generated__/graphql';

export const useCurrentEflow = (eflowId: string, organizationId: string) => {
  const { data } = useFindOneEflowQuery({
    variables: {
      input: {
        id: eflowId,
        organizationId,
      },
    },
  });

  return {
    flow: data?.findOneEFlow,
  };
};
