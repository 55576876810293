import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { TablePaginationWrapper } from '@/core/components';

import { UnitAdminRow } from './UnitAdminRow';
import { UnitAdminTableProps } from './types';

export const UnitAdminTable = ({
  showModal,
  rows,
  total,
  rowsPerPage,
  setRowsPerPage,
  cursor,
  setCursor,
}: UnitAdminTableProps) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <TablePaginationWrapper
        total={total}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(newValue: number) => setRowsPerPage(newValue)}
        cursor={cursor}
        setCursor={(newValue: number) => setCursor(newValue)}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell height={'40px'}>
                <CustomTypography fontWeight={500}>
                  {t('organizationSettings.unitAdminSection.cell')}
                </CustomTypography>
              </TableCell>
              <TableCell>
                <CustomTypography fontWeight={500}>
                  {t('employee.employee')}
                </CustomTypography>
              </TableCell>
              <TableCell>
                <CustomTypography fontWeight={500}>
                  {t('employees.email')}
                </CustomTypography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0
              ? rows.map((row) => (
                  <UnitAdminRow
                    key={row.id}
                    row={row}
                    isUserPermitted
                    showModal={showModal}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </TablePaginationWrapper>
    </TableContainer>
  );
};

const CustomTypography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
