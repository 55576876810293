import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Status } from '@e-schema/components';
import { RowStack } from '@organizations/styles';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores/index.ts';
import { theme } from '@/theme';

import type { SystemUsersTableRowProps } from './SystemUserTableRow.types.ts';

export const SystemUsersTableRow = (props: SystemUsersTableRowProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userGroups = useUserStore((state) => state.userGroups);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState(-1);
  const [systemGroupsToDelete, setSystemGroupsToDelete] = useState<
    SystemRoles[]
  >([]);
  const systemGroupsCanBeDeleted = useMemo(() => {
    let tempSystemGroupsToDelete: SystemRoles[] = [];

    if (userGroups.includes(SystemRoles.Root))
      tempSystemGroupsToDelete = [SystemRoles.SystemAdmin, SystemRoles.KeyUser];
    else if (userGroups.includes(SystemRoles.SystemAdmin)) {
      tempSystemGroupsToDelete = [SystemRoles.KeyUser];
    }
    return tempSystemGroupsToDelete;
  }, [userGroups]);

  useEffect(() => {
    setSystemGroupsToDelete(systemGroupsCanBeDeleted);
  }, [systemGroupsCanBeDeleted, userGroups]);

  return (
    <>
      <CustomTableRow
        onClick={() =>
          navigate(
            `/organizations/system-settings/system-users/${props.row.id}/basic-information`,
          )
        }
      >
        <TableCell align="left" height={'48px'}>
          <RowStack>
            <StyledAvatar>{props.row.firstName.charAt(0)}</StyledAvatar>
            <CustomTopography>
              {props.row.firstName} {props.row.lastName}
            </CustomTopography>
          </RowStack>
        </TableCell>
        <TableCell align="left">
          <CustomTopography>{props.row.email}</CustomTopography>
        </TableCell>
        <TableCell align="left">
          <CustomTopography>{props.row.systemRole}</CustomTopography>
        </TableCell>
        <TableCell align="left">
          <Status status={props.row.state} />
        </TableCell>

        <TableCell align="right">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setOpenMenu(props.id);
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </CustomTableRow>
      <Menu
        id={props.id.toString()}
        keepMounted
        anchorEl={anchorEl}
        open={props.id === openMenu}
        onClose={(e: MouseEvent) => {
          e.stopPropagation();

          setOpenMenu(-1);
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setOpenMenu(-1);
            props.handleOpenDeleteModal(props.id);
          }}
          disabled={
            !props.row.systemRole.some((role) =>
              systemGroupsToDelete.includes(role),
            )
          }
        >
          {t('common.delete')}
        </MenuItem>
      </Menu>
    </>
  );
};

const CustomTableRow = styled(TableRow)({
  transition: '0.2s ease all',
  cursor: 'pointer',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    background: theme.palette.grey[50],
  },
});

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});

const StyledAvatar = styled(Avatar)({
  width: '24px',
  height: '24px',
  fontSize: '12px',
  lineHeight: '24px',
  marginRight: '7px',
  backgroundColor: theme.palette.primary[600],
});
