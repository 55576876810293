import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography, styled } from '@mui/material';

import {
  Button,
  CustomModal,
  ModalContainer,
  TextButton,
} from '@core/components';

import { ModalProps } from './BasicModal.type';

export const BasicModal = ({
  closeModal,
  display,
  buttonText,
  onSubmit,
  content,
  headerText,
}: ModalProps) => {
  const { t: commonT } = useTranslation();

  return (
    <CustomModal display={display}>
      <ModalContainer
        width="600px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        closeModal={closeModal}
        headerText={headerText}
      >
        <ContentContainer>{content}</ContentContainer>

        <CustomButtonsContainer>
          <TextButton
            text={commonT('common.cancel')}
            width="auto"
            onClick={closeModal}
          />
          <Button variant="contained" onClick={onSubmit}>
            <Typography>{buttonText}</Typography>
          </Button>
        </CustomButtonsContainer>
      </ModalContainer>
    </CustomModal>
  );
};

const ContentContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  padding: '24px',
});

const CustomButtonsContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '12px',
  padding: '24px',
});
