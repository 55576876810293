import { useEffect, useMemo } from 'react';

import { useCompanyId } from '@core/hooks';

import { useFindAllKpiSettlementPeriodsLazyQuery } from '@/__generated__/graphql.ts';

export const useFetchSettlementPeriods = () => {
  const { companyId } = useCompanyId();

  const [query, { data, loading: isLoading }] =
    useFindAllKpiSettlementPeriodsLazyQuery({
      nextFetchPolicy: 'cache-first',
    });

  useEffect(() => {
    if (!companyId) return;

    void query({
      variables: {
        input: {
          organizationId: companyId,
        },
      },
    });
  }, [companyId, query]);

  const settlementsPeriodMap = useMemo(() => {
    const settlementPeriodMap: { [key: string]: string } = {};
    data?.findAllKpiSettlementPeriods?.settlementPeriods.forEach((period) => {
      settlementPeriodMap[period.id] = period.periodName;
    });
    return settlementPeriodMap;
  }, [data]);

  return {
    isLoading,
    settlementPeriods:
      data?.findAllKpiSettlementPeriods?.settlementPeriods || [],
    settlementsPeriodMap,
  };
};
