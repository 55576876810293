import pl_AI from './AI/pl.json';
import pl_charts from './charts/pl.json';
import pl_dashboard from './dashboard/pl.json';
import pl_flow from './e-flow/pl.json';
import pl_kaizen from './kaizen/pl.json';
import pl_kpi from './kpi/pl.json';
import pl_navbar from './navbar/pl.json';
import pl_permissions from './permissions/pl.json';
import pl from './pl.json';
import pl_settlementPeriods from './settlementPeriods/pl.json';

export const resources = {
  pl: {
    translation: pl,
    eflow: pl_flow,
    AI: pl_AI,
    kaizen: pl_kaizen,
    permissions: pl_permissions,
    kpi: pl_kpi,
    settlementPeriods: pl_settlementPeriods,
    dashboard: pl_dashboard,
    charts: pl_charts,
    navbar: pl_navbar,
  },
};
