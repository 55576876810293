import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { signOut } from '@aws-amplify/auth';

import { useOrganizationStore, useUserStore } from '@/stores';

export const useHandleButtonClick = (onNavigateBack?: () => void) => {
  const userStore = useUserStore();
  const organizationStore = useOrganizationStore();
  const navigate = useNavigate();

  return useCallback(async (): Promise<void> => {
    const location = window.location.href.split('/');
    const backPath = location.slice(3, location.length - 1).join('/');

    if (
      userStore.organizationUrl !== '' ||
      location.includes('organizations')
    ) {
      onNavigateBack ? onNavigateBack() : navigate(`/${backPath}`);
    } else {
      await signOut();
      organizationStore.resetStorage();
      userStore.resetStorage();
      window.location.href = '/login';
    }
  }, [userStore, organizationStore, navigate, onNavigateBack]);
};
