import { useEffect, useState } from 'react';

import { useReportModalContext } from '@kpi/contexts';

import { validateEntry } from '@core/utils/validationUtils/validationUtils.ts';

export const useReportDataValidation = () => {
  const { data } = useReportModalContext();
  const [isValid, setIsValid] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const ValidateEntryValues = () => {
      const isValid = data.every((item) => validateEntry(item.entry));
      setIsValid(isValid);
      setHasChanges(true);
    };

    ValidateEntryValues();
  }, [data]);

  return { isValid, hasChanges };
};
