import { useNavigate, useParams } from 'react-router-dom';

import { useEflowPermissions } from '@e-flow/hooks';

import {
  Box,
  CircularProgress,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from '@mui/material';

import { useFlowRedirects } from '@core/redirects';
import { FlexCenter } from '@core/styles';

import { CustomSnackBar, ErrorContent, SimpleNavbar } from '@/core/components';
import { useIsMobile } from '@/core/hooks';
import { theme } from '@/theme';

import { useUpdateAnalysis } from './useUpdateAnalysis';

export const UpdateAnalysis = () => {
  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const { flowId } = useParams();

  const {
    newAnalysis,
    isLoading,
    steps,
    renderStepChange,
    isShowSnackbar,
    setIsShowSnackbar,
    snackbarMessage,
    step,
  } = useUpdateAnalysis();

  const { redirectToSingleFlow } = useFlowRedirects();

  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: flowId,
  });

  if (!canModifyAnalize) {
    return (
      <FlexCenter sx={{ height: '100vh' }}>
        <ErrorContent
          errorCode={'403-no-access-to-analize'}
          displayCode={'403-no-access-to-analize'}
          onNavigateBack={redirectToSingleFlow}
        />
      </FlexCenter>
    );
  }

  return (
    <>
      <SimpleNavbar title={newAnalysis.name} action={() => navigate(-1)} />
      <Stack
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        gap="24px"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {!isMobile ? (
              <SideBox>
                <SideBoxContent>
                  <Stepper orientation="vertical" activeStep={step - 1}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>
                          <Typography variant="bodyRegular">{label}</Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </SideBoxContent>
              </SideBox>
            ) : (
              <TopBox>
                <Stepper activeStep={step - 1} connector={<Connector />}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Typography variant="bodyRegular">{label}</Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </TopBox>
            )}
          </>
        )}

        {/* Analysis form */}
        <FormContainer>{renderStepChange()}</FormContainer>
      </Stack>
      <CustomSnackBar
        open={isShowSnackbar}
        close={() => setIsShowSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
};

const SideBox = styled(Box)({
  width: '264px',
  height: 'calc(100vh - 56px)',
  borderRight: '1px solid var(--neutrals-gray-200, #dcdfe1)',
});

const SideBoxContent = styled(Box)({
  width: '264px',
  height: '100%',
  padding: '16px 24px 8px 24px',
});

const TopBox = styled(Box)({
  width: '100%',
  height: '64px',
  borderBottom: '1px solid var(--neutrals-gray-200, #dcdfe1)',
  overflowX: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 24px',
});

const Connector = styled('div')({
  width: '20px',
  height: '1px',
  background: theme.palette.grey[500],
});

const FormContainer = styled(Box)({
  width: '100%',
  padding: '24px',
  gap: '24px',
});
