import { useReducer } from 'react';

enum ActionTypes {
  DISPLAY_FIRST_SCREEN = 'DISPLAY_FIRST_SCREEN',
  DISPLAY_SECOND_SCREEN = 'DISPLAY_SECOND_SCREEN',
  DISPLAY_BOTH_SCREENS = 'DISPLAY_BOTH_SCREENS',
}

type State = {
  isFirstScreenDisplayed: boolean;
  isSecondScreenDisplayed: boolean;
  areBothScreensDisplayed: boolean;
};

type Action =
  | { type: ActionTypes.DISPLAY_FIRST_SCREEN }
  | { type: ActionTypes.DISPLAY_SECOND_SCREEN }
  | { type: ActionTypes.DISPLAY_BOTH_SCREENS };

const defaultState: State = {
  isFirstScreenDisplayed: true,
  isSecondScreenDisplayed: false,
  areBothScreensDisplayed: false,
};

const reducer = (prevState: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.DISPLAY_FIRST_SCREEN:
      return {
        isFirstScreenDisplayed: true,
        isSecondScreenDisplayed: false,
        areBothScreensDisplayed: false,
      };
    case ActionTypes.DISPLAY_SECOND_SCREEN:
      return {
        isFirstScreenDisplayed: false,
        isSecondScreenDisplayed: true,
        areBothScreensDisplayed: false,
      };
    case ActionTypes.DISPLAY_BOTH_SCREENS:
      return {
        isFirstScreenDisplayed: false,
        isSecondScreenDisplayed: false,
        areBothScreensDisplayed: true,
      };
    default:
      return prevState;
  }
};

export const useDisplaySplitScreenReducer = (): {
  isFirstScreenDisplayed: boolean;
  isSecondScreenDisplayed: boolean;
  areBothScreensDisplayed: boolean;
  setDisplayFirstScreen: () => void;
  setDisplaySecondScreen: () => void;
  setDisplayBothScreens: () => void;
} => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const setDisplayFirstScreen = () =>
    dispatch({ type: ActionTypes.DISPLAY_FIRST_SCREEN });
  const setDisplaySecondScreen = () =>
    dispatch({ type: ActionTypes.DISPLAY_SECOND_SCREEN });
  const setDisplayBothScreens = () =>
    dispatch({ type: ActionTypes.DISPLAY_BOTH_SCREENS });

  return {
    ...state,
    setDisplayFirstScreen,
    setDisplaySecondScreen,
    setDisplayBothScreens,
  };
};
