import { useTranslation } from 'react-i18next';

import { KpiChartWidget } from '@core/modules/DashbordModule/components/widgets/Chart/KpiChartWidget.tsx';

import {
  DashboardWidgetDto,
  SupportedWidgetsEnum,
} from '@/__generated__/graphql.ts';

import { KaizenSubmissionNumberChartWidget } from './Chart/KaizenSubmissionNumberChartWidget.tsx';
import { KaizenSubmissionProcessingTimeChartWidget } from './Chart/KaizenSubmissionProcessingTimeChartWidget.tsx';

export const Widget = (props: DashboardWidgetDto) => {
  const { t } = useTranslation('dashboard');

  if (
    props.widgetType ===
    SupportedWidgetsEnum.KaizenSubmissionProcessingTimeChart
  )
    return <KaizenSubmissionProcessingTimeChartWidget />;

  if (props.widgetType === SupportedWidgetsEnum.KaizenSubmissionNumberChart) {
    return <KaizenSubmissionNumberChartWidget />;
  }

  if (props.widgetType === SupportedWidgetsEnum.KpiChart) {
    return <KpiChartWidget {...props} />;
  }
  return <span>{t('widgets.unsupported')}</span>;
};
