import { useCallback } from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from '@mui/material';

import { Button, CustomModal, ModalContainer } from '@core/components';
import { RadioModalTypes } from '@core/components/Modals/RadioModal/RadioModal.types.ts';

import { theme } from '@/theme';

export const RadioModal = (props: RadioModalTypes) => {
  const renderRadios = useCallback(() => {
    return (
      <>
        {props.options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </>
    );
  }, [props.options]);

  return (
    <CustomModal display={props.display}>
      <ModalContainer
        closeModal={props.closeModal}
        headerText={props.headerText}
        sx={{ ...props.sx, minWidth: '500px' }}
      >
        <FormControl>
          <StyledRadioGroup
            value={props.selectedOption}
            onChange={(event) => props.setSelectedOption(event.target.value)}
          >
            {renderRadios()}
          </StyledRadioGroup>
        </FormControl>
        <ButtonContainer>
          <Button
            variant={'text'}
            onClick={props.buttonPrimary.onClick}
            textColor={theme.palette.common.black}
            disabled={props.buttonPrimary.disabled || false}
            sx={{
              color: theme.palette.grey[600],
            }}
          >
            {props.buttonPrimary.text}
          </Button>

          <Button
            variant={'contained'}
            onClick={props.buttonSecondary.onClick}
            disabled={props.buttonSecondary.disabled || false}
          >
            {props.buttonSecondary.text}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </CustomModal>
  );
};

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '22px',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  padding: '16px',
});
