import { useTranslation } from 'react-i18next';

import { Skeleton, Stack, Typography } from '@mui/material';

import { Button } from '@core/components';

import { useCreateChartContext } from '../../context';

export const EmptyKpis = () => {
  const { t } = useTranslation('kpi');

  const { setSelectKpisView, handleOpenSelectKpiView, isLoading } =
    useCreateChartContext();

  if (isLoading) {
    return <Skeleton variant="rectangular" width={'100%'} height={60} />;
  }

  return (
    <Stack gap="10px" justifyContent="flex-start">
      <Typography variant="h6">{t('charts.kpi')}</Typography>
      <Typography variant="bodyRegular">
        {t('charts.selectKpiCommunicate')}
      </Typography>
      <Button
        sx={{ alignSelf: 'flex-start', mt: '10px' }}
        type="button"
        onClick={() => {
          setSelectKpisView();
          handleOpenSelectKpiView();
        }}
      >
        {t('charts.selectKpi')}
      </Button>
    </Stack>
  );
};
