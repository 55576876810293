import { TableCell, TableRow, Typography, styled } from '@mui/material';

import { DeleteRow } from '@/core/components';

import { UnitAdminRowProps } from './types';

export const UnitAdminRow = (props: UnitAdminRowProps) => {
  const { row } = props;

  return (
    <TableRow>
      <CustomTableCell>
        <Typography variant="bodyRegular">
          {row.organizationUnit.name}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="bodyRegular">{`${row.firstName} ${row.lastName}`}</Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="bodyRegular">{row.email}</Typography>
      </CustomTableCell>
      <TableCell>
        {props.isUserPermitted ? (
          <DeleteRow showModal={() => props.showModal(props.row.id)} />
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const CustomTableCell = styled(TableCell)({
  width: '32%',
});
