import { useTranslation } from 'react-i18next';

import { useKpiDashboardContext } from '@kpi/pages/Dashboard/contexts/KpiDashbaordContext.tsx';

import { CircularProgress } from '@mui/material';

import { RechartChart } from '@core/components';
import { useKpiChartWidget } from '@core/modules/DashbordModule/hooks/WidgetHooks';

import { DashboardWidgetDto } from '@/__generated__/graphql.ts';

export const KpiChartWidget = (props: DashboardWidgetDto) => {
  const { t } = useTranslation('charts');

  const { isLoading, chart } = useKpiChartWidget(props);

  const { selectedMonth } = useKpiDashboardContext();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!chart) {
    return <div>{t('chartNotFound')}</div>;
  }

  return <RechartChart {...chart} selectedMonth={selectedMonth || 0} />;
};
