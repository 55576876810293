import {
  Checkbox,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { theme } from '@/theme';

import { ModalEmployeeTableRowTypes } from '../types';

export const ModalEmployeeTableRow = ({
  row,
  onRowSelected,
  isChecked,
  disabled = false,
}: ModalEmployeeTableRowTypes) => {
  return (
    <CustomTableRow>
      <ValuesCell>
        <Checkbox
          disabled={disabled}
          checked={isChecked}
          onChange={() => {
            onRowSelected(row);
          }}
        />
      </ValuesCell>
      <ValuesCell height={'48px'}>
        <CustomTopography>
          {row.firstName} {row.lastName}
        </CustomTopography>
      </ValuesCell>
      <ValuesCell>
        <CustomTopography>{row.email}</CustomTopography>
      </ValuesCell>
      <ValuesCell>
        <CustomTopography>{row.organizationUnit.name}</CustomTopography>
      </ValuesCell>
    </CustomTableRow>
  );
};

const ValuesCell = styled(TableCell)({
  align: 'left',
});

const CustomTableRow = styled(TableRow)({
  transition: '0.2s ease all',
  cursor: 'pointer',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    background: theme.palette.grey[50],
  },
});

const CustomTopography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
});
