import dayjs, { Dayjs } from 'dayjs';

import {
  GetEmployeesForMultiSelectQuery,
  KaizenStatuses,
} from '@/__generated__/graphql';

export class FindAllSubmissionsQueryBuilder {
  private search?: string = undefined;
  private statuses?: KaizenStatuses[] = undefined;
  private createdAt?: string = undefined;
  private settlementPeriodIds: string[] = [];
  private isAssignedToMe?: boolean = undefined;
  private participantIds?: string[] = undefined;

  constructor() {}

  addSearch(search: string): FindAllSubmissionsQueryBuilder {
    this.search = search === '' ? undefined : search;
    return this;
  }

  addStatuses(statuses?: KaizenStatuses[]): FindAllSubmissionsQueryBuilder {
    this.statuses = statuses && statuses.length > 0 ? statuses : undefined;
    return this;
  }

  addCreated(createdAt: Dayjs | null): FindAllSubmissionsQueryBuilder {
    if (!createdAt) {
      this.createdAt = undefined;
      return this;
    }

    this.createdAt = dayjs(createdAt).format('YYYY-MM-DD');
    return this;
  }

  addSettlementPeriodIds(periodIds: string[]): FindAllSubmissionsQueryBuilder {
    this.settlementPeriodIds = periodIds;
    return this;
  }

  addIsAssignedToMe(isAssignedToMe: boolean): FindAllSubmissionsQueryBuilder {
    this.isAssignedToMe = isAssignedToMe ? isAssignedToMe : undefined;
    return this;
  }

  addParticipants(
    participants: GetEmployeesForMultiSelectQuery['getEmployees']['items'],
  ): FindAllSubmissionsQueryBuilder {
    this.participantIds =
      participants.length > 0
        ? participants.map((employee) => employee.id)
        : undefined;
    return this;
  }

  build() {
    return {
      name: this.search,
      statuses: this.statuses,
      createdAt: this.createdAt,
      assignedToMe: this.isAssignedToMe,
      settlementPeriodId: this.settlementPeriodIds,
      submissionParticipantsIds: this.participantIds,
    };
  }
}
