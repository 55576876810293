import React from 'react';
import { useParams } from 'react-router-dom';

import { NavbarMenu } from '@core/components';
import { NavbarTypes } from '@core/components/Navbar/Navbar.types.ts';
import { NavbarTabs } from '@core/components/Navbar/NavbarTabs.tsx';
import { NavbarUserMenuComponent } from '@core/components/NavbarCore/NavbarUserMenuComponent.tsx';
import { getNavbarModuleIcons } from '@core/constants';
import {
  NavBarMenuIconContainer,
  NavbarApps,
  NavbarLogo,
  NavbarMainContainer,
  NavbarNavigation,
} from '@core/styles';

export const Navbar = ({ tabs, variant, additionalItems }: NavbarTypes) => {
  const { companyName } = useParams();

  return (
    <NavbarMainContainer>
      <NavBarMenuIconContainer>
        <NavbarMenu />
        <NavbarApps to={`/${companyName!}`}>
          <NavbarLogo
            src={getNavbarModuleIcons(variant)}
            alt={variant ? variant : 'Digital Factory'}
          />
        </NavbarApps>
      </NavBarMenuIconContainer>
      <NavbarNavigation>
        <NavbarTabs tabs={tabs} variant={variant} />
        {additionalItems}
      </NavbarNavigation>
      <NavbarUserMenuComponent />
    </NavbarMainContainer>
  );
};
