import { useCallback, useEffect, useState } from 'react';

import { Box, Stack, Typography, styled, useTheme } from '@mui/material';

import { DoneModal } from '@core/components/Modals/LoadingModal/Components/Done/Done.tsx';
import { InProgress } from '@core/components/Modals/LoadingModal/Components/InProgress/InProgress.tsx';
import { OnTooLong } from '@core/components/Modals/LoadingModal/Components/OnTooLong/OnTooLong.tsx';

import { CustomModal } from '../../';
import {
  IBoxModalProps,
  ModalContainerProps,
  ModalWithTimeoutProps,
} from './LoadingModal.types';

export const LoadingModal = ({
  width = '510px',
  height,
  isLoading,
  headerText,
  sx,
  text,
  closeModal,
  open = true,
  ...props
}: ModalContainerProps) => {
  const [isLoadingTooLong, setIsLoadingTooLong] = useState(false);
  const [timeoutReference, setTimeoutReference] = useState<NodeJS.Timeout>();

  const theme = useTheme();

  useEffect(() => {
    if (!props.withTimeout) return;

    let timeoutId: NodeJS.Timeout | undefined;

    if (isLoading && timeoutReference) clearTimeout(timeoutReference);

    if (isLoading && props.withTimeout) {
      timeoutId = setTimeout(() => {
        setIsLoadingTooLong(true);
      }, props.timeout) as unknown as NodeJS.Timeout;
      setTimeoutReference(timeoutId);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutReference || timeoutId);
        setIsLoadingTooLong(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    props.withTimeout,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (props as unknown as ModalWithTimeoutProps)?.timeout,
  ]);

  const mainContent = useCallback(() => {
    if (isLoading && !isLoadingTooLong) return <InProgress />;

    if (isLoading && isLoadingTooLong && props.withTimeout)
      return <OnTooLong {...props} closeModal={closeModal} />;

    return <DoneModal doneText={props.doneText} closeModal={closeModal} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLoadingTooLong, props, text]);

  return (
    <CustomModal display={open}>
      <Container sx={{ minWidth: width }}>
        <ModalContent theme={theme} width={width} height={height} sx={sx}>
          {/* header of Modal*/}
          <StackContent>
            <Typography variant="h4" data-testid="headerText">
              {headerText}
            </Typography>
          </StackContent>
          <BoxContent>
            <Stack>{mainContent()}</Stack>
          </BoxContent>
        </ModalContent>
      </Container>
    </CustomModal>
  );
};

const ModalContent = styled(Box)<IBoxModalProps>(
  ({ theme, width, height }) => ({
    borderRadius: 10,
    width: width,
    height: height,
    zIndex: 3,
    background: theme.palette.common.white,
    minWidth: '300px',
  }),
);

const BoxContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0 16px 16px 24px',
});

const Container = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

const StackContent = styled(Stack)(() => ({
  padding: '20px 16px 16px 24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
}));
