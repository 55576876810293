/**
 * Converts a timestamp to a string in the format 'dd/mm/yyyy'.
 * @param timestamp string | number
 * @throws Error if the timestamp is invalid.
 * @returns string in the format 'dd/mm/yyyy'.
 *
 * @example
 * toDdMmYyyy(1620000000000); // '02/05/2021'
 */
export function toDdMmYyyy(timestamp: string | number) {
  const date =
    String(new Date(timestamp)) !== 'Invalid Date'
      ? new Date(timestamp)
      : new Date(Number(timestamp));

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
