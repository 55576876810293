import { CircularProgress, Stack, Table, styled } from '@mui/material';

import { TablePaginationWrapper } from '@/core/components';

import { NoSubmissionView } from '../Views/NoDataView';
import { useSubmissionTable } from '../hooks/useSubmissionTable';
import { SubmissionRow } from './SubmissionRow/SubmissionRow';
import { SubmissionTableFilters } from './SubmissionTableFilters';
import { SubmissionTableHeader } from './SubmissionTableHeader';

export const SubmissionTable = () => {
  const {
    rows,
    removeModal,
    areFiltersDefault,
    total,
    setCursor,
    cursor,
    setRowsPerPage,
    rowsPerPage,
    handleClickRemove,
    isLoading,
  } = useSubmissionTable();

  if (rows.length && areFiltersDefault) {
    return <NoSubmissionView />;
  }

  return (
    <>
      {removeModal()}
      <Container>
        <SubmissionTableFilters />
      </Container>
      <TablePaginationWrapper
        total={total || 0}
        rowsPerPage={rowsPerPage}
        cursor={cursor}
        setCursor={(cursorValue) => setCursor(cursorValue)}
        setRowsPerPage={(rowsPerPageValue) => setRowsPerPage(rowsPerPageValue)}
      >
        <Table>
          <SubmissionTableHeader />
          {rows.map((submission) => (
            <SubmissionRow row={submission} handleRemove={handleClickRemove} />
          ))}
        </Table>
      </TablePaginationWrapper>
      {isLoading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
    </>
  );
};

const Container = styled(Stack)({
  flexDirection: 'row',
  marginBottom: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const LoadingContainer = styled(Stack)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});
