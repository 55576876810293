import { ReactNode, createContext, useContext } from 'react';

import { useKaizenReport } from '@/pages/kaizen/pages/reports/hooks/useKaizenReport';

type KaizenReportContextProps = ReturnType<typeof useKaizenReport>;

const KaizenReportContext = createContext<KaizenReportContextProps | undefined>(
  undefined,
);

export const KaizenReportProvider = ({ children }: { children: ReactNode }) => {
  const kaizenReportHookValue = useKaizenReport();

  return (
    <KaizenReportContext.Provider value={kaizenReportHookValue}>
      {children}
    </KaizenReportContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useKaizenReportContext = () => {
  const context = useContext(KaizenReportContext);
  if (context === undefined) {
    throw new Error(
      'useKaizenReportContext must be used within a KaizenReportProvider',
    );
  }
  return context;
};
