import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { EmployeeAvatar } from '@/core/components';
import { EmployeeRoles } from '@/core/helpers';

import type { NodeCellUserProps } from './NodeCell.types';

export const NodeCellUser: FC<NodeCellUserProps> = ({
  user,
  isAvatar,
  isPosition,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      {isAvatar && (
        <EmployeeAvatar
          name={user.firstName || ''}
          imageUrl={user.profilePicture}
          size={32}
        />
      )}
      <Typography
        whiteSpace="nowrap"
        fontWeight={EmployeeRoles.isUnitAdmin(user.systemRole) ? 800 : 400}
      >
        {user.firstName} {user.lastName}
        {isPosition && `, ${user.position}`}
      </Typography>
    </Box>
  );
};
