import { captureException } from '@sentry/react';

import { useUpdateSingleSubmissionCache } from '@kaizen/hooks';

import {
  KaizenSubmissionFragment,
  useRejectSingleSubmissionMutation,
} from '@/__generated__/graphql';
import { useCompanyId } from '@/core/hooks';
import { useUserStore } from '@/stores';

export const useRejectSubmission = () => {
  const { companyId } = useCompanyId();

  const { userUuid, organizationId } = useUserStore();

  const [sendRejectSingleSubmission, { loading: isLoading }] =
    useRejectSingleSubmissionMutation();

  const { updateSingleSubmissionCache } = useUpdateSingleSubmissionCache();

  const rejectSubmission = async (
    submissionId: string,
    rejectionReason: string,
  ) => {
    try {
      const result = await sendRejectSingleSubmission({
        variables: {
          input: {
            submissionId,
            organizationId: companyId,
            commentModel: {
              content: rejectionReason,
              organizationId: companyId,
              createdByModel: {
                organizationId: organizationId!,
                id: userUuid,
              },
            },
          },
        },
      });

      if (result.data?.rejectSingleSubmission) {
        updateSingleSubmissionCache(
          result.data.rejectSingleSubmission as KaizenSubmissionFragment,
        );
        return true;
      }
    } catch (errors) {
      captureException(errors);

      return false;
    }
  };

  return {
    isLoading,
    rejectSubmission,
  };
};
