/* eslint-disable @typescript-eslint/no-misused-promises */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EflowStepSyncContext } from '@e-flow/hooks/useSynchronizedFlows';
import { useNetworkState } from '@uidotdev/usehooks';

import { CloudDone, CloudOff } from '@mui/icons-material';
import { Alert, AlertTitle, Snackbar } from '@mui/material';

import { Button, DisabledButton, LoadingButton } from '@core/components';

import { theme } from '@/theme';

export const SynchronizeEflowStepsButton = () => {
  const { t } = useTranslation('eflow');

  const {
    reSynchronize,
    isSyncInProgress,
    isSyncCriticalError,
    isSynchronized,
  } = useContext(EflowStepSyncContext);

  const { online: isOnline } = useNetworkState();

  if (!isOnline) {
    return (
      <Button variant={'contained'} color={'error'}>
        <CloudOff
          sx={{
            color: theme.palette.common.white,
          }}
        />
      </Button>
    );
  }

  if (isSynchronized) {
    return (
      <DisabledButton variant={'contained'} color={'success'}>
        <CloudDone
          sx={{
            color: theme.palette.common.white,
          }}
        />
      </DisabledButton>
    );
  }

  if (isSyncCriticalError) {
    return (
      <>
        <Button variant={'contained'} color={'error'}>
          <CloudOff
            sx={{
              color: theme.palette.common.white,
            }}
          />
        </Button>
        <Snackbar open={true} autoHideDuration={6000}>
          <Alert severity="error">
            <AlertTitle>{t('synchronize.criticalErrorTitle')}</AlertTitle>
            {t('synchronize.criticalErrorDescription')}
          </Alert>
        </Snackbar>
      </>
    );
  }

  return (
    <LoadingButton
      fontSize={14}
      backgroundColor={theme.palette.grey[200]}
      typographySx={{
        color: theme.palette.text.primary,
      }}
      textVariant="bodyMedium"
      isLoading={isSyncInProgress}
      variant={isSyncInProgress ? 'outlined' : 'contained'}
      onClick={async () => reSynchronize()}
    >
      {t('synchronize.saveOfflineStepsOnline')}
    </LoadingButton>
  );
};
