import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';

import { MenuItem, Select, Typography } from '@mui/material';

import { monthDayNumber } from '@core/constants';

export const NumberSelect = () => {
  const {
    useManageKpiModalState: { register, getValues },
  } = useHandleAddKpiRateContext();
  return (
    <Select
      size="small"
      sx={{ marginTop: '-7px', width: '100px' }}
      defaultValue={getValues('dueToDateTimeout')}
      {...register('dueToDateTimeout')}
    >
      {monthDayNumber.map((number) => (
        <MenuItem value={number} key={number}>
          <Typography variant="bodyRegular">{number}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
