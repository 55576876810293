import { useTranslation } from 'react-i18next';

import { disableLoadingButtonWhenSubmitting } from '@kaizen/utils/disableLoadingButtonWhenSubmitting.ts';
import { SummaryViewProps } from '@kaizen/views/SummaryView/SummaryViewProps.types.ts';

import { StepFormViewWrapper } from '@/core/components';
import { theme } from '@/theme';

import { SummaryButtonType } from '../../common';
import { SummaryTable } from './components/SummaryTable/SummaryTable';

export const SummaryModule = (props: SummaryViewProps) => {
  const { t } = useTranslation('kaizen');

  const getMiddleButtonText = () => {
    if (props.isUpdate && props.isDraft)
      return t('submissionModificationView.summary.buttons.draftUpdate');
    else if (props.isUpdate && !props.isDraft)
      return t('submissionModificationView.summary.buttons.update');
    else if (!props.isUpdate && props.isDraft)
      return t('submissionModificationView.summary.buttons.draftSave');
    else return t('submissionModificationView.summary.buttons.save');
  };

  const getNextButtonText = () => {
    if (props.isUpdate)
      return t('submissionModificationView.summary.buttons.updateAndSend');
    else return t('submissionModificationView.summary.buttons.submitAndSend');
  };
  return (
    <StepFormViewWrapper
      buttonType={props.buttonType}
      isLoading={props.isLoading}
      middleButtonDisabled={disableLoadingButtonWhenSubmitting(
        !!props.isLoading,
        SummaryButtonType.SubmitAndSend,
        props.buttonType,
      )}
      nextStepDisabled={disableLoadingButtonWhenSubmitting(
        !!props.isLoading,
        SummaryButtonType.Submit,
        props.buttonType,
      )}
      title={t('submissionModificationView.summary.name')}
      withPreviousStep={true}
      withNextStep={true}
      withMiddleButton={true}
      onMiddleButton={() => props.onSubmit()}
      onNextStep={() => props.onSubmitAndSend()}
      onPreviousStep={() => props.onPreviousStep()}
      middleButtonText={getMiddleButtonText()}
      middleButtonStyles={{
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
      }}
      nextButtonText={getNextButtonText()}
    >
      <SummaryTable
        submissionName={props.summaryName}
        createdAt={new Date()}
        submissionKey={props.submissionKey}
        onChangeSubmissionsAuthors={props.onChangeSubmissionsAuthors}
        authors={props.authors}
      />
    </StepFormViewWrapper>
  );
};
