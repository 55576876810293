import { FC, useMemo } from 'react';
import { Pie } from 'react-chartjs-2';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

import { styled } from '@mui/styles';

import { ChartColors } from '@core/components/Statistica/PieChart/ColorsVariants.ts';
import { PieChartTypes } from '@core/components/Statistica/PieChart/PieChart.types.ts';

import { theme } from '@/theme';

import { calculateValues } from './utils';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart: FC<PieChartTypes> = (props) => {
  const chartValues = useMemo(
    () => calculateValues(props.values),
    [props.values],
  );

  const data = {
    datasets: [
      {
        data: chartValues,
        backgroundColor: ChartColors[props.variant],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '85%',
    rotation: 216,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
  };

  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        position: 'relative',
      }}
    >
      {!isNaN(props.title?.replace('%', '') as unknown as number) && (
        <Pie data={data} options={options} />
      )}
      {props.title && (
        <StyledText
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none',
            textAlign: 'center',
            fontSize: '12px',
          }}
        >
          {props.title}
        </StyledText>
      )}
    </div>
  );
};

const StyledText = styled('text')({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
});
