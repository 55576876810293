import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';

import { SettlementPeriodsTableRow } from '../SettlementPeriodRow/SettlementPeriodsTableRow';
import { SettlementPeriodTableProps } from './SettlementPeriodTable.types';

export const SettlementPeriodsTable = ({
  settlementPeriods,
}: SettlementPeriodTableProps) => {
  const { t } = useTranslation('settlementPeriods');

  return (
    <TableContainer>
      <Table size="medium">
        <TableHead>
          <TableCell>
            <b>{t('settlementPeriod.name')}</b>
          </TableCell>
        </TableHead>
        <TableBody>
          {settlementPeriods.map((settlementPeriod) => (
            <SettlementPeriodsTableRow
              {...settlementPeriod}
              key={settlementPeriod.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
