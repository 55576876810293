import { useTranslation } from 'react-i18next';

import { useReportModalContext } from '@kpi/contexts';
import { RowStack } from '@organizations/styles';

import { Switch, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

export const SettingSection = () => {
  const { t } = useTranslation('kpi');

  const { isSwitchOn, setIsSwitchOn, date, setDate } = useReportModalContext();

  return (
    <RowStack gap="24px">
      <DatePicker
        slotProps={{
          textField: { size: 'small' },
        }}
        sx={{ width: '190px' }}
        views={['month']}
        value={date}
        label={t('report.month')}
        onChange={(date) => {
          setDate(date);
        }}
      />

      <RowStack>
        <Switch value={isSwitchOn} onChange={setIsSwitchOn} />
        <Typography variant="bodyRegular">{t('report.switchText')}</Typography>
      </RowStack>
    </RowStack>
  );
};
