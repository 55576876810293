import { Outlet } from 'react-router-dom';

import { useKaizenTabs } from '@kaizen/constant/UseKaizenTabs.tsx';
import { KaizenSettlementPeriodProvider } from '@kaizen/contexts';

import { Navbar } from '@core/components/Navbar/Navbar';

import { OrganizationModules } from '@/__generated__/graphql.ts';

export const KaizenLayout = () => {
  const { kaizenNavbarTabs } = useKaizenTabs();
  return (
    <KaizenSettlementPeriodProvider>
      <div style={{ height: '100vh' }}>
        <Navbar
          tabs={kaizenNavbarTabs.tabs}
          variant={OrganizationModules.Kaizen}
        />
        <Outlet />
      </div>
    </KaizenSettlementPeriodProvider>
  );
};
