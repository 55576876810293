import { CheckIfUserCanEnterParams } from '@router/components/ProtectedRoute/CheckAllowedGroups.types.ts';

import { SystemRoles } from '@/__generated__/graphql.ts';

import { ProtectedRouteTypes } from './ProtectedRoute.types';

const LUQAM_ORGANIZATION = 'luqam';

/**
 * Check if user is allowed to enter
 * @param allowedGroups array of allowed roles into endpoint
 * @param mustBeLoggedIn defines if the user must be logged in or logged out to access the route.
 * @param organizationName
 * @returns {Promise<boolean>} A promise that resolves to true if the user can enter and false otherwise.
 *
 * @example
 * // Allow any authenticated user to enter
 * const canEnter = await CheckIfUserCanEnter({});
 * console.log(canEnter); // Outputs: true if the user is authenticated, false otherwise
 *
 * @example
 * // Allow only authenticated users in group1 or group2 to enter
 * const canEnter = await CheckIfUserCanEnter({ allowedGroups: ['group1', 'group2'] });
 * console.log(canEnter); // Outputs: true if the user is in group1 or group2, false otherwise
 *
 * @example
 * // Allow only unauthenticated users to enter
 * const canEnter = await CheckIfUserCanEnter({ mustBeLoggedIn: false });
 * console.log(canEnter); // Outputs: true if the user is not authenticated, false otherwise
 *
 * @example
 * // Allow only authenticated users to enter
 * const canEnter = await CheckIfUserCanEnter({ mustBeLoggedIn: true });
 * console.log(canEnter); // Outputs: true if the user is authenticated, false otherwise
 */
export const CheckIfUserCanEnter = ({
  allowedGroups = Object.values(SystemRoles),
  mustBeLoggedIn = true,
  organizationUrlFromRoute,
  organizationUrlFromUser,
  organization,
  isSystemRoute = false,
  userGroups,
  organizationModules = [],
  currentModule,
}: CheckIfUserCanEnterParams): ProtectedRouteTypes => {
  if (!mustBeLoggedIn && organizationUrlFromUser)
    return ProtectedRouteTypes.Unauthorized;

  //Check if user is not logged in
  if (!organizationUrlFromUser && !mustBeLoggedIn)
    return ProtectedRouteTypes.Allowed;

  //Check if organization has access to this module
  if (currentModule && !organizationModules.includes(currentModule))
    return ProtectedRouteTypes.Forbidden;

  //All groups are allowed
  if (!userGroups) return ProtectedRouteTypes.Forbidden;
  //Check if route is system route and user must not be logged in --> for example, login page
  if (isSystemRoute && !mustBeLoggedIn) return ProtectedRouteTypes.Allowed;

  //Check if a user tries to enter a system route
  if (isSystemRoute) {
    if (
      userGroups.some((group) =>
        [
          SystemRoles.Root,
          SystemRoles.SystemAdmin,
          SystemRoles.KeyUser,
          SystemRoles.Administrator, //DANGER
        ].includes(group),
      )
    )
      return ProtectedRouteTypes.Allowed;
    return ProtectedRouteTypes.Forbidden;
  }

  if (
    organizationUrlFromUser === LUQAM_ORGANIZATION &&
    userGroups.some((group) =>
      [
        SystemRoles.Root,
        SystemRoles.SystemAdmin,
        SystemRoles.KeyUser,
        SystemRoles.Administrator,
      ].includes(group),
    )
  ) {
    if (
      organizationUrlFromRoute !== null &&
      !organization[organizationUrlFromRoute!]
    ) {
      return ProtectedRouteTypes.MissingResource;
    } else {
      //root-only route
      if (
        allowedGroups?.length === 1 &&
        allowedGroups[0] === SystemRoles.Root &&
        !userGroups.includes(SystemRoles.Root)
      )
        return ProtectedRouteTypes.Forbidden;

      return userGroups &&
        userGroups.some((group: SystemRoles) => allowedGroups.includes(group))
        ? ProtectedRouteTypes.Allowed
        : ProtectedRouteTypes.Forbidden;
    }
  }

  //This applies for normal users, not luqam
  if (organizationUrlFromUser !== organizationUrlFromRoute)
    return ProtectedRouteTypes.ResourceOutsideOrganization;

  //User has userGroups
  return userGroups &&
    userGroups.some((group: SystemRoles) => allowedGroups.includes(group))
    ? ProtectedRouteTypes.Allowed
    : ProtectedRouteTypes.Forbidden;
};
