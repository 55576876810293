/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, styled } from '@mui/material';

import {
  AsIsToBeNamesEnum,
  EflowStep,
  EflowStepFragmentFragment,
} from '@/__generated__/graphql';
import {
  FullScreenLoading,
  NetworkIndicatorBar,
  RemoveItemModal,
  SimpleNavbar,
} from '@/core/components';
import { useIsMobile } from '@/core/hooks';
import { useFlowRedirects } from '@/core/redirects';
import { Sidebar } from '@/pages/e-schema/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { useOrganizationStore } from '@/stores';

import { AttachmentsModule, CommentModule } from '../../components';
import { RenderEflowSteps } from './Callbacks/RenderEflowSteps/RenderEflowSteps';
import { RenderEflowStepsTypes } from './Callbacks/RenderEflowSteps/RenderEflowSteps.types';
import { ErrorDialog } from './components/ErrorDialog/ErrorDialog';
import { EFlowName, OperationsModule } from './flowStepsModules';
import { Parameters } from './flowStepsModules/parameters/Parameters';
import { useEflow } from './hooks/useEflow/useEflow';
import {
  EFlowStepTypes,
  EflowStepWithFiles,
  NumeredEFlowStep,
} from './hooks/useEflowSteps/types/eFlowStep.types';
import { useEflowSteps } from './hooks/useEflowSteps/useEflowSteps';

export const FlowStepsContent = () => {
  const {
    isLoading,
    currentEflowStepId,
    currentEflowStep,
    allEflowSteps,
    save,
    validate,
    loadHook,
    removeStep,
    updateEflowFiles,
    updateCurrentEflowStep,
    changeCurrentEflowStep,
    removeAlreadyUploadedFiles,
  } = useEflowSteps();

  const { init: initEflowHook, eFlow, isLoading: isEflowLoading } = useEflow();

  const { t } = useTranslation('eflow');

  const isMobile = useIsMobile();

  const { companyName, flowId, analizeType } = useParams();

  const { organization } = useOrganizationStore();

  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isRemoveStepModalVisible, setIsRemoveStepModalVisible] =
    useState(false);
  const [isNetworkOperationInProgress, setIsNetworkOperationInProgress] =
    useState(false);

  const { redirectToSingleFlow } = useFlowRedirects();

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      companyName &&
      flowId &&
      [AsIsToBeNamesEnum.AsIs, AsIsToBeNamesEnum.ToBe].includes(
        analizeType as AsIsToBeNamesEnum,
      )
    ) {
      loadHook(
        flowId,
        organization[companyName],
        analizeType as AsIsToBeNamesEnum,
      );
      initEflowHook({
        organizationId: organization[companyName],
        eFlowId: flowId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, flowId]);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const saveEflowStep = async (withNextStep: boolean = true) => {
    setIsNetworkOperationInProgress(true);
    const validationResult = validate(currentEflowStep as NumeredEFlowStep);
    if (validationResult !== true) {
      setIsErrorDialogOpen(true);
      setIsErrorDisplayed(true);

      setIsNetworkOperationInProgress(false);

      return false;
    } else {
      setIsErrorDisplayed(false);
      await save(withNextStep);
    }
    setIsNetworkOperationInProgress(false);
    return true;
  };

  if (isEflowLoading || isLoading) {
    return <FullScreenLoading />;
  }

  const changeCurrentEflowStepWithValidation: RenderEflowStepsTypes['changeCurrentEflowStep'] =
    async (stepId) => {
      const validationResult = validate(
        currentEflowStep as unknown as EFlowStepTypes,
      );
      if (validationResult !== true) {
        setIsErrorDialogOpen(true);
        setIsErrorDisplayed(true);
      } else await changeCurrentEflowStep(stepId);
    };

  return (
    <>
      <SimpleNavbar
        title={currentEflowStep?.operationName || 'Flow Steps'}
        action={async () => {
          if (await saveEflowStep(false)) redirectToSingleFlow();
        }}
      />
      <NetworkIndicatorBar />

      <Main>
        {!isMobile && (
          <Sidebar withPadding={false} resize={false} withGap={false}>
            {RenderEflowSteps({
              allEflowSteps,
              currentEflowStepId,
              currentEflowStep,
              changeCurrentEflowStep: changeCurrentEflowStepWithValidation,
            })}
          </Sidebar>
        )}

        <OperationContent ref={containerRef}>
          <WidthLimiter>
            {/** here will be content of operation ✅ */}
            <EFlowName
              operationName={currentEflowStep?.operationName || ''}
              onFlowStepChange={updateCurrentEflowStep}
              isError={isErrorDisplayed}
            />
            {/*/!*operation ✅*!/*/}
            <OperationsModule
              selectedOperation={currentEflowStep?.operation?.name}
              operations={eFlow?.operations}
              onFlowStepChange={updateCurrentEflowStep}
              isError={isErrorDisplayed}
            />
            {/*parameters ✅*/}
            <Parameters
              parameters={eFlow?.parameters}
              parameterValues={currentEflowStep?.parameters || {}}
              onFlowStepChange={updateCurrentEflowStep}
            />
            {/*/!*Comments ✅*!/*/}
            <CommentModule
              commentTypes={eFlow?.commentTypes}
              onFlowStepChange={(comments) =>
                updateCurrentEflowStep({
                  comments: comments,
                })
              }
              updateCommentModels={(commentModels) =>
                updateCurrentEflowStep({
                  commentModels: commentModels,
                })
              }
              addCommentToReport={true}
              currentComments={(currentEflowStep as EflowStep)?.comments || []}
              commentModels={
                (currentEflowStep as EflowStep)?.commentModels || []
              }
            />
            {/*attachments ✅*/}
            <AttachmentsModule
              alreadyUploadedFiles={
                ((currentEflowStep as EflowStepFragmentFragment)?.files ||
                  []) as EflowStep['files']
              }
              onFlowStepChange={updateEflowFiles}
              currentEflowStepId={currentEflowStepId}
              removeAlreadyUploadedFiles={removeAlreadyUploadedFiles}
              offlineFiles={
                (currentEflowStep as EflowStepWithFiles)?.localFiles || []
              }
            />
            <ButtonSection>
              <PrimaryButton
                variant={'contained'}
                disabled={isNetworkOperationInProgress}
                color={'error'}
                onClick={() => {
                  setIsRemoveStepModalVisible(true);
                }}
              >
                {t('flowSteps.deleteStep')}
              </PrimaryButton>{' '}
              <PrimaryButton
                variant={'contained'}
                disabled={isNetworkOperationInProgress}
                onClick={async () => {
                  if (await saveEflowStep(false)) redirectToSingleFlow();
                }}
              >
                {t('flowSteps.saveAndExit')}
              </PrimaryButton>
              <PrimaryButton
                variant={'contained'}
                type={'button'}
                disabled={isNetworkOperationInProgress}
                onClick={async () => {
                  await saveEflowStep(true);
                  scrollToTop();
                }}
              >
                {t('flowSteps.nextOperation')}
              </PrimaryButton>
            </ButtonSection>
          </WidthLimiter>
        </OperationContent>
      </Main>
      <ErrorDialog
        headerText={t('flowSteps.validationErrors.title')}
        closeModal={() => {
          setIsErrorDialogOpen(false);
        }}
        display={isErrorDialogOpen}
        onRemove={async () => {
          await removeStep();
          setIsErrorDisplayed(false);
          setIsErrorDialogOpen(false);
          window.scrollTo(0, 0);
        }}
      />
      <RemoveItemModal
        display={isRemoveStepModalVisible}
        closeModal={() => {
          setIsRemoveStepModalVisible(false);
        }}
        onRemove={async () => {
          await removeStep();
          setIsErrorDisplayed(false);
          setIsRemoveStepModalVisible(false);
          scrollToTop();
        }}
        headerText={t('flowSteps.deleteStep')}
        content={
          <Trans
            i18nKey={'modals.removeItemModal.removeItemSubtitle'}
            values={{
              itemName:
                currentEflowStep?.operationName ||
                t('flowSteps.noNameOperation'),
            }}
          />
        }
      />
    </>
  );
};

const ButtonSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  gap: '8px',
});

const Main = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100vw',
  height: 'calc(100vh - 64px)',
});

const OperationContent = styled(Box)({
  position: 'relative',

  width: '100%',
  height: '100%',
  padding: '24px',
  overflowY: 'auto',
});

const WidthLimiter = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  maxWidth: '700px',
  gap: '24px',
});
