import { SettlementPeriodErrorsEnum } from '@core/enums';
import { useCompanyId } from '@core/hooks';
import { normalizeString } from '@core/utils';

import { useCreateKpiSettlementPeriodMutation } from '@/__generated__/graphql.ts';

import { useFetchSettlementPeriods } from './useFetchSettlementPeriod';
import { useUpdateSettlementPeriodCache } from './useUpdateSettlementPeriodCache';

export const useAddSettlementPeriod = () => {
  const { addNewSettlementPeriod } = useUpdateSettlementPeriodCache();
  const { companyId } = useCompanyId();
  const [addSettlementPeriodMutation] = useCreateKpiSettlementPeriodMutation();
  const { settlementPeriods } = useFetchSettlementPeriods();

  const isDuplicateSettlementPeriodName = (
    settlementPeriodName: string,
  ): boolean => {
    const normalizedNewName = normalizeString(settlementPeriodName);

    return !!settlementPeriods.find(
      (period) => normalizeString(period.periodName) === normalizedNewName,
    );
  };

  const validate = (settlementPeriodName: string, hadError: boolean) => {
    if (hadError && !settlementPeriodName) {
      return SettlementPeriodErrorsEnum.CHANGE_SOMETHING;
    }

    if (isDuplicateSettlementPeriodName(settlementPeriodName)) {
      return SettlementPeriodErrorsEnum.ERROR_UNIQUE_NAME;
    }

    if (!settlementPeriodName || normalizeString(settlementPeriodName) === '') {
      return SettlementPeriodErrorsEnum.ERROR_EMPTY_NAME;
    }

    if (normalizeString(settlementPeriodName).includes('ARCHIVED')) {
      return SettlementPeriodErrorsEnum.ERROR_FORBIDDEN_NAME;
    }

    if (
      settlementPeriodName.length > 50 ||
      (normalizeString(settlementPeriodName).length > 0 &&
        normalizeString(settlementPeriodName).length < 3)
    ) {
      return SettlementPeriodErrorsEnum.ERROR_MESSAGE;
    }

    return null;
  };

  const addSettlementPeriod = async (
    settlementPeriodName: string,
    isActive: boolean,
    cloneKpis: boolean,
    newKpiSettlementPeriodYear: number,
  ) => {
    const { data } = await addSettlementPeriodMutation({
      variables: {
        input: {
          periodName: settlementPeriodName,
          isActive: isActive || false,
          organizationId: companyId,
          cloneKpis: cloneKpis,
          newKpiSettlementPeriodYear,
        },
      },
    });

    if (data?.createKpiSettlementPeriod) {
      addNewSettlementPeriod(data.createKpiSettlementPeriod);
      return true;
    }

    return false;
  };

  return {
    addSettlementPeriod,
    validate,
  };
};
