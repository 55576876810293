import { useTranslation } from 'react-i18next';

import { disableLoadingButtonWhenSubmitting } from '@kaizen/utils/disableLoadingButtonWhenSubmitting.ts';

import { Box, Typography, styled } from '@mui/material';

import { LoadingButton, PreviousButton } from '@core/components';
import { StepFormViewWrapperTypes } from '@core/components/wrappers/StepFormViewWrapper/StepFormViewWrapper.types.ts';

import { SummaryButtonType } from '@/pages/kaizen/common';

export const StepFormViewWrapper = (props: StepFormViewWrapperTypes) => {
  const { t: tCommon } = useTranslation();

  const consent = (
    <>
      <Typography variant="h3">{props.title}</Typography>
      <Typography variant="bodyRegular">{props.subtitle}</Typography>
      <ChildrenWrapper>{props.children}</ChildrenWrapper>
      <StyledStack>
        {props.withPreviousStep && (
          <PreviousButton
            onClick={props.onPreviousStep}
            disabled={props.previousStepDisabled}
          />
        )}
        {props.withMiddleButton && (
          <LoadingButton
            isLoading={disableLoadingButtonWhenSubmitting(
              !!props.isLoading,
              SummaryButtonType.Submit,
              props.buttonType,
            )}
            textVariant="bodyRegular"
            backgroundColor={
              props.middleButtonStyles?.backgroundColor
                ? props.middleButtonStyles?.backgroundColor
                : 'primary'
            }
            typographySx={{
              color: props.middleButtonStyles?.color || 'white',
            }}
            onClick={props.onMiddleButton}
            disabled={props.middleButtonDisabled}
          >
            {props.middleButtonText}
          </LoadingButton>
        )}
        {props.withNextStep && (
          <LoadingButton
            isLoading={disableLoadingButtonWhenSubmitting(
              !!props.isLoading,
              SummaryButtonType.SubmitAndSend,
              props.buttonType,
            )}
            onClick={props.onNextStep}
            disabled={props.nextStepDisabled}
          >
            <Typography variant="bodyRegular">
              {props.nextButtonText || tCommon('common.continue')}
            </Typography>
          </LoadingButton>
        )}
      </StyledStack>
    </>
  );

  if (props.onSubmit) {
    return <CustomForm onSubmit={props.onSubmit}>{consent}</CustomForm>;
  }

  return <CustomBox>{consent}</CustomBox>;
};

const ChildrenWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxHeight: 'calc( 100vh - 350px )',
  },
}));

const CustomForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxWidth: '768px',
});

const CustomBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxWidth: '768px',
});

const StyledStack = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  gap: 10,
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    bottom: 0,
    width: '90%',
    justifyContent: 'flex-end',
    paddingBottom: '10px',
    paddingTop: '10px',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& > :first-child': {
      flex: 1,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& > :last-child': {
      flex: 2,
    },
  },
}));
