import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
} from '@mui/material';

import { AccordionViewerProps } from './AccordionViewer.types';
import { useAccordionViewer } from './hook/useAccordionViewer';

export const AccordionViewer = ({ title, children }: AccordionViewerProps) => {
  const { isExpanded, handleChange } = useAccordionViewer();

  return (
    <Accordion
      expanded={isExpanded}
      elevation={0}
      sx={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:before': {
          display: 'none',
        },
      }}
    >
      <CustomAccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        onClick={handleChange}
      >
        <CustomTypography variant="h4">{title}</CustomTypography>
      </CustomAccordionSummary>
      <CustomAccordionDetails>{children}</CustomAccordionDetails>
    </Accordion>
  );
};

const CustomAccordionSummary = styled(AccordionSummary)({
  display: 'flex',
  flexDirection: 'row-reverse',
});

const CustomTypography = styled(Typography)({
  marginLeft: '5px',
});

const CustomAccordionDetails = styled(AccordionDetails)({
  marginLeft: '30px',
  div: {
    position: 'relative',
  },
});
