import { EflowReportComment } from '@/__generated__/graphql';

export const formatCommentModels = (commentModels: EflowReportComment[]) => {
  return commentModels.map((commentModel) => {
    return {
      commentId: commentModel.commentId,
      showInReport: commentModel.showInReport,
    };
  });
};
