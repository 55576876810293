import styled from '@emotion/styled';
import { SingleTab } from '@kpi/pages/Dashboard/components/SingleTab.tsx';
import { useDashboardTabs } from '@kpi/pages/Dashboard/hooks';

import { Box } from '@mui/material';

import { TabViewWrapper } from '@core/components';

export const DashboardTabs = () => {
  const { createTabs } = useDashboardTabs();

  return (
    <DashboardContainer>
      <TabViewWrapper
        tabs={createTabs().map((tab, index) => ({
          key: index,
          tabName: tab.tabName,
          tabComponent: <SingleTab dashboardType={tab.dashboardType} />,
        }))}
      />
    </DashboardContainer>
  );
};

const DashboardContainer = styled(Box)({
  padding: '16px 0 0 0',
});
