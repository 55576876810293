/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { TFunction } from 'i18next';
import { EFlowStatuses } from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

export const getStatusData = (
  status: string,
  t: TFunction<'translation', undefined>,
) => {
  switch (status) {
    case EFlowStatuses.Completed:
      return {
        color: theme.palette.success[100],
        textColor: theme.palette.common.black,
        name: t('statuses.completed'),
      };
    case EFlowStatuses.InProgress:
      return {
        color: theme.palette.primary[400],
        textColor: theme.palette.common.white,
        name: t('statuses.inProgress'),
      };
    case EFlowStatuses.New:
      return {
        color: theme.palette.primary[100],
        name: t('statuses.new'),
        textColor: theme.palette.common.black,
      };
    default:
      return null;
  }
};
