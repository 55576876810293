import { useCompanyId } from '@core/hooks';

import { useFindManyKpisLazyQuery } from '@/__generated__/graphql';

export const useReadKpis = () => {
  const { companyId } = useCompanyId();

  const [query, { data, loading: isLoading }] = useFindManyKpisLazyQuery();

  const getKpis = async (kpiRateIds: string[]) => {
    await query({
      variables: { input: { organizationId: companyId, kpiRateIds } },
      fetchPolicy: 'no-cache',
    });
  };

  return { isLoading, kpis: data?.findManyKpis.items, getKpis };
};
