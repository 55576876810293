import { useTranslation } from 'react-i18next';

import { Avatar, Stack, Typography } from '@mui/material';

import { useUserStore } from '@/stores';

import { CreatedByBadgeProps } from './CreatedByBadge.types';
import { formatCreatedBy } from './utils';

/**
 * Created by badge
 * @constructor
 */
export const CreatedByBadge = ({ createdBy }: CreatedByBadgeProps) => {
  const { name, lastName } = useUserStore();

  const { t } = useTranslation('eflow');

  return (
    <Stack gap="12px">
      <Typography variant="bodyMedium">
        {t('newAnalysis.settings.author')}
      </Typography>
      {/*define created by*/}
      <Stack flexDirection="row" alignItems="center">
        <Avatar sx={{ mr: 2 }}>{name[0]}</Avatar>
        <Typography>{formatCreatedBy(name, lastName, createdBy)}</Typography>
      </Stack>
    </Stack>
  );
};
