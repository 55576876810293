import { useTranslation } from 'react-i18next';

import { PeriodValueEnum } from '@kpi/common/periodValues.enum';

import { MenuItem, Select, Typography } from '@mui/material';

import { YearlyPeriodProps } from './types';

export const YearPeriodSelect = ({
  onChange,
  value,
  width,
}: YearlyPeriodProps) => {
  const { t } = useTranslation('kpi');

  return (
    <Select
      size="small"
      sx={{ width: width }}
      onChange={(e) => onChange(e.target.value as PeriodValueEnum)}
      value={value}
    >
      {Object.values(PeriodValueEnum).map((period) => (
        <MenuItem key={period} value={period}>
          <Typography variant="bodyRegular">
            {t(`filters.periodValues.${period}`)}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
