import { useTranslation } from 'react-i18next';

import { Box, Typography, styled } from '@mui/material';

export const NoNotifications = () => {
  const { t } = useTranslation();

  return (
    <NoNotificationsContainer>
      <NoNotificationsText>
        {t('notification.noNotifications')}
      </NoNotificationsText>
    </NoNotificationsContainer>
  );
};

const NoNotificationsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: '20px',
  textAlign: 'center',
  color: '#888',
});

const NoNotificationsText = styled(Typography)({
  fontSize: '18px',
  fontWeight: 'bold',
});
