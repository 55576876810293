import { captureException } from '@sentry/react';
import { Trans, useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { pick } from 'lodash';

import { Typography, styled } from '@mui/material';

import {
  SystemRoles,
  useUpdateEmployeeMutation,
} from '@/__generated__/graphql';
import {
  CustomModal,
  InfoModalContent,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { EmployeeRoles } from '@/core/helpers';
import { theme } from '@/theme';

import { DeletePermissionModalProps } from './types';

export const DeletePermissionModal = (props: DeletePermissionModalProps) => {
  const { t } = useTranslation();

  const [updateEmployee] = useUpdateEmployeeMutation();

  const handleRemovePermission = async () => {
    try {
      const result = await updateEmployee({
        variables: {
          input: {
            ...pick(props, 'organizationId', 'id'),
            systemRole: [SystemRoles.User],
          },
        },
      });
      if (result.data) {
        props.setSnackBar(
          t('organizationSettings.deletePermissionConfirmation'),
        );

        props.removeUser(result.data.updateEmployee.id);
      }
    } catch (_err) {
      captureException(_err);
    } finally {
      props.closeModal();
    }
  };
  return (
    <CustomModal display={props.open}>
      <ModalContainer
        width="528px"
        closeModal={props.closeModal}
        headerText={t('organizationSettings.deletePermission')}
      >
        <InfoModalContent
          contentText={
            <Trans
              values={{
                permission: EmployeeRoles.getSystemRoles(props.systemRole),
                employee: `${props.firstName} ${props.lastName}`,
              }}
              i18nKey={'organizationSettings.deletePermissionContent'}
            />
          }
        >
          <TextButton
            text={t('common.cancel')}
            width="66px"
            onClick={props.closeModal}
          />
          <RedButton
            variant="contained"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleRemovePermission}
          >
            <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
              {t('organizationSettings.deletePermission')}
            </Typography>
          </RedButton>
        </InfoModalContent>
      </ModalContainer>
    </CustomModal>
  );
};

const RedButton = styled(PrimaryButton)({
  backgroundColor: theme.palette.error[600],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    backgroundColor: theme.palette.error[700],
  },
});
