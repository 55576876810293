import { useTranslation } from 'react-i18next';

import { useAsIsToBeCount, useEflowPermissions } from '@e-flow/hooks';

import { Button, SplitScreenButton } from '@core/components';

import { theme } from '@/theme';

type AsIsFlowDashboardButtonProps = {
  flowId: string;
  setDisplayBothScreens: () => void;
};

export const AsIsFlowDashboardButton = (
  props: AsIsFlowDashboardButtonProps,
) => {
  const { t } = useTranslation('eflow');

  const { isToBeEmpty } = useAsIsToBeCount({
    flowId: props.flowId,
  });

  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: props.flowId,
  });

  if (isToBeEmpty)
    return (
      <Button
        disabled={!canModifyAnalize}
        textVariant="bodyMedium"
        onClick={props.setDisplayBothScreens}
        textColor={theme.palette.common.white}
        fontSize={14}
      >
        {t('eflow.beginToBe')}
      </Button>
    );

  return (
    <SplitScreenButton
      textVariant="bodyMedium"
      onClick={props.setDisplayBothScreens}
      backgroundColor={theme.palette.grey[100]}
      textColor={theme.palette.common.black}
      fontSize={14}
      text={t('common.toBe')}
    />
  );
};
