import { useEffect, useState } from 'react';

import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context';

import { DashboardDisplayOptions } from '../../types';

export const useSettingsSwitches = () => {
  const {
    dashboard,
    updateDisplayOptions,
    isDisplayOptionsLoading: isLoading,
  } = useDashboardContext();

  const [options, setOptions] = useState<DashboardDisplayOptions>({
    displayApps: true,
    displayCompanyName: true,
    displayConsultants: true,
    displayWelcomeMessage: true,
  });

  useEffect(() => {
    if (dashboard?.additionalInfo) {
      setOptions(dashboard.additionalInfo as DashboardDisplayOptions);
    }
  }, [dashboard]);

  const changeDisplayOptions = async (key: string, value: boolean) => {
    await updateDisplayOptions(key, value);
  };

  return { options, changeDisplayOptions, isLoading };
};
