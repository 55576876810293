import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useCompanyId } from '@core/hooks';

import { useMarkNotificationAsReadMutation } from '@/__generated__/graphql';
import { useSnackbarContext } from '@/core/contexts';
import { useUserStore } from '@/stores';

export const useChangeNotificationStatus = (isNotificationOpened: boolean) => {
  const { t } = useTranslation();

  const { companyId } = useCompanyId();
  const { userUuid } = useUserStore();

  const { openSnackbar } = useSnackbarContext();

  const [markAsReadMutation] = useMarkNotificationAsReadMutation();

  const handleMarkAsRead = useCallback(
    async (id: string) => {
      if (isNotificationOpened) {
        return;
      }

      try {
        await markAsReadMutation({
          variables: {
            input: {
              notificationId: id,
              organizationId: companyId,
              userId: userUuid,
            },
          },
        });

        openSnackbar(t('notification.nowIsRed'));
      } catch (error) {
        captureException(error);
        openSnackbar(t('common.somethingWentWrong'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, markAsReadMutation, userUuid, isNotificationOpened],
  );

  return {
    handleMarkAsRead,
  };
};
