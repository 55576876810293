import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddEmployeeModal, SelectPosition } from '@e-schema/components';
import { useUpdateEmployeesTableCache } from '@e-schema/hooks';

import { Close } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Slide,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import {
  Employee,
  EmployeeFragmentFragment,
  EmployeeState,
  GetEmployeesForOrganizationQuery,
  useGetEmployeesForOrganizationLazyQuery,
} from '@/__generated__/graphql';
import {
  SearchTextField,
  SelectCellModal,
  TablePaginationWrapper,
} from '@/core/components';
import { StatusSwitch } from '@/core/components/StatusSwitch/StatusSwitch';
import { useCompanyId, useFetchEmployees } from '@/core/hooks';
import { FlexCenter } from '@/core/styles';
import { useUserStore } from '@/stores';
import { userIsPermitted } from '@/utils';

import { EmployeesTableHeader } from './EmployeesTable/EmployeesTableHeader';
import { EmployeesTableRow } from './EmployeesTable/EmployeesTableRow';

export const Employees = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { companyId } = useCompanyId();
  const { updateEmployeesListCache } = useUpdateEmployeesTableCache();

  const { userGroups, organizationId } = useUserStore();

  const [snackbar, setSnackbar] = useState<string | null>(null);

  const [rows, setRows] = useState<
    GetEmployeesForOrganizationQuery['getEmployees']['items']
  >([]);
  const [total, setTotal] = useState<number>(0);

  const getEmployees = useGetEmployeesForOrganizationLazyQuery();

  const {
    isLoading,
    employees,
    totalEmployees,
    search,
    setSearch,
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,
    position,
    setPosition,
    organizationUnit,
    setOrganizationUnit,
    employeeState,
    setEmployeeState,
    input,
  } = useFetchEmployees(companyId, getEmployees);

  useEffect(() => {
    if (employees) {
      setRows((employees as Employee[]) || []);
      setTotal(totalEmployees || 0);
    }
  }, [employees, totalEmployees]);

  /**
   * Remove user from table
   * @param id
   */
  const removeUserFromTable = (id: string) => {
    setRows(() => rows.filter((employee) => employee.id !== id));
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <Typography color={theme.palette.text.primary} variant="h3">
          {t('employees.title')}
        </Typography>
        {userIsPermitted(userGroups, organizationId!, companyId) && (
          <AddEmployeeModal
            onEmployeeAdded={(employee) =>
              input &&
              updateEmployeesListCache(
                input,
                employee as EmployeeFragmentFragment,
                total,
              )
            }
          />
        )}
      </HeaderContainer>
      <Content>
        <SearchContainer>
          <SearchTextField
            onChange={(event) => {
              if (event.target.value.length < 255) {
                setSearch(event.target.value);
              }
            }}
            placeholder={t('organization.search')}
            value={search}
          />

          <SelectPosition
            value={position}
            handleChange={(value) => setPosition(value)}
            organizationId={companyId}
          />
          <SelectCellModal
            value={organizationUnit.path}
            width="1000px"
            setValue={(value) => setOrganizationUnit(value)}
            label={t('employees.searchCell')}
            onClear={() =>
              setOrganizationUnit({
                targetId: '',
                path: '',
              })
            }
          />
          <StatusSwitch
            value={employeeState}
            onChange={(event) =>
              setEmployeeState([event.target.value as EmployeeState])
            }
            variant={'standard'}
          />
        </SearchContainer>

        <TableContainer>
          <TablePaginationWrapper
            total={total}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(newValue: number) => setRowsPerPage(newValue)}
            cursor={cursor}
            setCursor={(newValue: number) => setCursor(newValue)}
          >
            {isLoading && (
              <FlexCenter>
                <CircularProgress />
              </FlexCenter>
            )}
            {!isLoading && (
              <Table size="small">
                <EmployeesTableHeader />

                <TableBody>
                  {employees!.map((row) => (
                    <EmployeesTableRow
                      setSnackbar={setSnackbar}
                      row={row as Employee}
                      key={row.id}
                      removeUserFromTable={() =>
                        removeUserFromTable(row.id as string)
                      }
                      setNewTotal={() => setTotal((prev) => prev - 1)}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </TablePaginationWrapper>
        </TableContainer>
      </Content>
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        message={snackbar}
        autoHideDuration={5000}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbar(null)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </MainContainer>
  );
};

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '32px 24px 40px 24px',
});

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const SearchContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  maxWidth: 1000,
  gap: '12px',
});
