import { useEffect, useMemo } from 'react';

import { useCompanyId } from '@core/hooks';

import { useGetAllSettlementPeriodsLazyQuery } from '@/__generated__/graphql.ts';

export const useFetchSettlementPeriods = (withArchive: boolean = false) => {
  const { companyId } = useCompanyId();

  const [query, { data, loading: isLoading }] =
    useGetAllSettlementPeriodsLazyQuery();

  useEffect(() => {
    if (!companyId) return;

    void query({
      variables: {
        input: {
          organizationId: companyId,
          withArchive,
        },
      },
    });
  }, [companyId, query, withArchive]);

  const settlementsPeriodMap = useMemo(() => {
    const settlementPeriodMap: { [key: string]: string } = {};
    data?.findAllSettlementPeriods?.settlementPeriods.forEach((period) => {
      settlementPeriodMap[period.id] = period.periodName;
    });
    return settlementPeriodMap;
  }, [data]);

  return {
    isLoading,
    settlementPeriods: data?.findAllSettlementPeriods?.settlementPeriods || [],
    settlementsPeriodMap,
  };
};
