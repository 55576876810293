import { useEffect, useReducer, useState } from 'react';

import {
  Action,
  BalanceChartControls,
} from '@e-flow/pages/flowReport/components/BalanceChart/hooks/useBalanceChart.types.ts';
import { useDebounceValue } from 'usehooks-ts';

import { ParameterUnitsTypes } from '@/__generated__/graphql.ts';

export const useBalanceChart = (): BalanceChartControls => {
  const [debouncedBalanceLineValue, setDebouncedBalanceLineValue] =
    useDebounceValue<number | undefined | string>(undefined, 500);

  const [debouncedOperationNumber, setDebouncedOperationNumber] =
    useDebounceValue<number>(0, 500);

  const [balanceLineValue, setBalanceLineValue] = useState<
    number | undefined | string
  >(undefined);
  const [operationNumber, setOperationNumber] = useState<number>(0);

  useEffect(() => {
    setDebouncedBalanceLineValue(balanceLineValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceLineValue]);

  useEffect(() => {
    setDebouncedOperationNumber(operationNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationNumber]);

  const reducer = (
    state: Partial<BalanceChartControls>,
    action: Action,
  ): Partial<BalanceChartControls> => {
    switch (action.type) {
      case 'SET_UNIT':
        return { ...state, unit: action.unit };
      case 'SET_PARAMETER':
        return {
          ...state,
          parameter: action.parameter,
        };
      case 'SET_OPERATION':
        return {
          ...state,
          operation: action.operation,
        };
      case 'RESET':
        return { ...state, operation: 'ALL' };
      default:
        return state;
    }
  };

  const [filterParams, dispatch] = useReducer(reducer, {});

  const resetFilters = () => {
    dispatch({ type: 'RESET' });
    setBalanceLineValue('');
    setOperationNumber(0);
  };

  const setParameter = (parameter: string, unit: ParameterUnitsTypes) => {
    dispatch({
      type: 'SET_PARAMETER',
      parameter,
    });
    if (
      [
        ParameterUnitsTypes.TimeHhMmSs,
        ParameterUnitsTypes.TimeHhMm,
        ParameterUnitsTypes.TimeMmSs,
        ParameterUnitsTypes.TimeHhMm,
      ].includes(unit)
    ) {
      dispatch({
        type: 'SET_UNIT',
        unit: ParameterUnitsTypes.TimeS,
      });
    } else dispatch({ type: 'SET_UNIT', unit });
  };

  return {
    ...filterParams,
    balanceLineValue,
    debouncedBalanceLineValue,
    operationNumber,
    debouncedOperationNumber,
    setUnit: (unit: ParameterUnitsTypes) => {
      dispatch({ type: 'SET_UNIT', unit });
    },
    setParameter,
    setOperation: (operation: string) => {
      dispatch({
        type: 'SET_OPERATION',
        operation,
      });
    },
    setBalanceLineValue,
    setOperationNumber,
    resetFilters,
  };
};
