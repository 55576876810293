/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';

import {
  AdjustmentButton,
  KaizenSubmissionStatusChangeButton,
  RejectButton,
  SendToImplementationButton,
} from '@kaizen/components';
import { ChangeSubmissionStatusSelect } from '@kaizen/components/ChangeSubmissionStatusSeelct/ChangeSubmissionStatusSelect.tsx';
import { useAcceptSubmission } from '@kaizen/hooks';

import { KaizenStatuses } from '@/__generated__/graphql.ts';

export const useRenderButtonForCommisionLeader = () => {
  const { t: kaizenT } = useTranslation('kaizen');

  const { acceptKaizenSubmission } = useAcceptSubmission();

  const renderButtonForCommissionLeader = (
    submissionStatus: KaizenStatuses,
    submissionId: string,
  ) => {
    switch (submissionStatus) {
      case KaizenStatuses.Adjustment:
      case KaizenStatuses.Rejected:
      case KaizenStatuses.SupervisorAssessment:
      case KaizenStatuses.Todo:
      case KaizenStatuses.InProgress:
      case KaizenStatuses.Implemented:
      case KaizenStatuses.ImplementationAccepted:
      case KaizenStatuses.Draft:
        return (
          <ChangeSubmissionStatusSelect
            submissionId={submissionId}
            defaultStatus={submissionStatus}
            width={200}
          />
        );

      case KaizenStatuses.CommissionAssessment:
        return (
          <>
            <RejectButton submissionId={submissionId} />
            <AdjustmentButton submissionId={submissionId} />
            <KaizenSubmissionStatusChangeButton
              withCustomButton={false}
              onClick={() => acceptKaizenSubmission(submissionId)}
              submissionId={submissionId}
              buttonText={kaizenT('updateStatus.accept')}
              selectedStatus={submissionStatus}
              color={'primary'}
            />
          </>
        );

      case KaizenStatuses.Accepted:
      case KaizenStatuses.ImplementationRejected:
        return (
          <KaizenSubmissionStatusChangeButton
            withCustomButton={true}
            customButton={<SendToImplementationButton />}
            submissionId={submissionId}
            selectedStatus={submissionStatus}
            color={'primary'}
          />
        );
      default:
        return null;
    }
  };

  return {
    renderButtonForCommisionLeader: renderButtonForCommissionLeader,
  };
};
