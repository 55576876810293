import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalizeStepCommonTypes } from '@e-flow/pages/newAnalysis/AnalizeStepCommon.types.ts';

import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography, styled } from '@mui/material';

import { EflowCommentModel } from '@/__generated__/graphql';
import { CustomSnackBar } from '@/core/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { RowStack } from '@/pages/organizations/styles';
import { theme } from '@/theme';

import { AddCommentModal } from './AddCommentModal.tsx';
import { CommentsTable } from './CommentsTable';

export const CommentsModule = ({
  stepBack,
  moveNext,
  setMainAnalysisPart,
  currentFormValues,
}: AnalizeStepCommonTypes) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation('translation');

  const [comments, setComments] = useState<EflowCommentModel[]>([]);

  const [isAddNewCategoryModalVisible, setIsAddNewCategoryModalVisible] =
    useState(false);
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState('');

  useEffect(() => {
    setComments(currentFormValues.commentTypes || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSnackBar = (message: string) => {
    setIsShowSnackbar(true);
    setSnackBarMessage(message);
  };

  const handleRemoveComment = (index: number) => {
    setComments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = (callback: () => void) => {
    setMainAnalysisPart({
      commentTypes: comments,
    });
    callback();
  };

  return (
    <>
      <Container>
        <RowStack justifyContent={'space-between'}>
          <Typography variant="h3">
            {t('newAnalysis.comments.title')}
          </Typography>

          <PrimaryButton
            sx={{
              color: theme.palette.common.black,
            }}
            onClick={() => setIsAddNewCategoryModalVisible(true)}
          >
            <AddIcon fontSize="small" sx={{ marginRight: '5px' }} />
            <Typography variant="bodyMedium" sx={{ fontSize: '14px' }}>
              {t('newAnalysis.comments.addButton')}
            </Typography>
          </PrimaryButton>
        </RowStack>

        {/* Table section */}
        <CommentsTable
          symbolsArray={comments}
          removeComment={handleRemoveComment}
        />

        {/* button section */}
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="start"
          gap="12px"
        >
          <PrimaryButton
            sx={{
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.common.black,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
              },
            }}
            onClick={() => handleSubmit(stepBack)}
          >
            <Typography variant="bodyRegular">
              {commonT('organization.back')}
            </Typography>
          </PrimaryButton>
          <PrimaryButton
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary[600],
            }}
            onClick={() => handleSubmit(moveNext)}
          >
            <Typography variant="bodyRegular">
              {commonT('common.continue')}
            </Typography>
          </PrimaryButton>
        </Stack>
      </Container>
      {isAddNewCategoryModalVisible && (
        <AddCommentModal
          addComment={(comment) => setComments((prev) => [...prev, comment])}
          comments={comments}
          open={isAddNewCategoryModalVisible}
          close={() => setIsAddNewCategoryModalVisible(false)}
          setSnackBar={setSnackBar}
        />
      )}
      <CustomSnackBar
        open={isShowSnackbar}
        close={() => setIsShowSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
};

const Container = styled(Stack)({
  maxWidth: '728px',
  padding: '24px 0px',
  gap: '24px',
});
