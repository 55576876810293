import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { useAddKpiRateContext } from '@kpi/contexts';
import {
  KpiRateValidationSchema,
  createKpiRateDefaultValues,
} from '@kpi/modals/AddKpiRate/validation';

import { useCompanyId, useGetCurrentUserDetails } from '@core/hooks';

import {
  Employee,
  KpiRatingTargetType,
  useFindOrganizationSubunitsLazyQuery,
} from '@/__generated__/graphql.ts';

export const useAddKpiModalState = () => {
  const [selectedOrganizationUnit, setSelectedOrganizationUnit] = useState('');
  const [selectedReporterId, setSelectedReporterId] = useState('');
  const [defaultOrganizationUnit, setDefaultOrganizationUnit] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [defaultReporter, setDefaultReporter] = useState<Employee>();

  const { t: commonT } = useTranslation();

  const { kpiRate, isUpdateModal } = useAddKpiRateContext();
  const { companyId } = useCompanyId();
  const { user } = useGetCurrentUserDetails();

  const [loadOrganizationUnits, { data }] =
    useFindOrganizationSubunitsLazyQuery();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    resetField,
  } = useForm({
    defaultValues: createKpiRateDefaultValues(kpiRate),
    resolver: zodResolver(
      KpiRateValidationSchema(commonT('error.requiredField')),
    ),
  });

  useEffect(() => {
    if (!companyId) return;

    setValue('organizationId', companyId);
  }, [companyId, setValue]);

  const setReporterId = (value: string) => {
    setValue('reporterId', value);
    setSelectedReporterId(value);
  };

  const resetReporterId = () => {
    setValue('reporterId', '');
    setSelectedReporterId('');
    setDefaultReporter(undefined);
    setReporterId('');
  };

  const setOrganizationUnit = (
    value?: string | string[],
    dataLoad?: boolean,
  ) => {
    if (!value) {
      setValue('organizationUnitId', '');
      setSelectedOrganizationUnit('');
      !dataLoad && resetReporterId();
      return;
    }

    if (Array.isArray(value)) {
      return;
    }

    setValue('organizationUnitId', value);
    setSelectedOrganizationUnit(value);
    !dataLoad && resetReporterId();
  };

  const setUnitName = (value: string) => {
    setValue('unit.unitName', value);
  };

  const setUnitShortcut = (value: string) => {
    setValue('unit.unitShortcut', value);
  };

  const setTargetTypeValue = (value: KpiRatingTargetType) => {
    setValue('targetType', value);
  };

  const toggleTargetType = () => {
    if (getValues('targetType') === KpiRatingTargetType.MonthlyUnified) {
      setTargetTypeValue(KpiRatingTargetType.MonthlyCustom);
    } else {
      setTargetTypeValue(KpiRatingTargetType.MonthlyUnified);
    }

    resetField('targetData');
  };

  const loadOrganizationsData = () => {
    if (!companyId) return;

    void loadOrganizationUnits({
      variables: {
        input: {
          organizationId: companyId,
          ...(user?.organizationUnitGuid && {
            organizationUnitId: user.organizationUnitGuid,
          }),
        },
      },
    });
  };

  useEffect(() => {
    void loadOrganizationsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (data) {
      if (
        data.findOrganizationSubunits.rootUnit.id === user?.organizationUnitGuid
      ) {
        setOrganizationUnit(data.findOrganizationSubunits.rootUnit.id, true);
        setDefaultOrganizationUnit({
          id: user.organizationUnitGuid,
          name: data.findOrganizationSubunits.rootUnit.name,
        });
      } else {
        data.findOrganizationSubunits.subunits.forEach((unit) => {
          if (unit.id === user?.organizationUnitGuid) {
            setOrganizationUnit(unit.id, true);
            setDefaultOrganizationUnit({
              id: unit.id,
              name: unit.name,
            });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (kpiRate) {
      setOrganizationUnit(kpiRate.organizationUnitId, true);
      setReporterId(kpiRate.assignee.id);
      setDefaultReporter(kpiRate.assignee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiRate]);

  return {
    errors,
    register,
    setValue,
    getValues,
    handleSubmit,
    selectedReporterId,
    selectedOrganizationUnit,
    reset,
    watch,
    setUnitName,
    setReporterId,
    setOrganizationUnit,
    setUnitShortcut,
    toggleTargetType,
    isUpdateModal,
    defaultOrganizationUnit,
    defaultReporter,
  };
};
