import { useParams } from 'react-router-dom';

export const useSchemaRedirects = () => {
  const { companyName } = useParams();

  const structurePath = `/${companyName}/e-schema/structure`;
  const organizationTreePath = `/${companyName}/e-schema/organization`;
  const employeesPath = `/${companyName}/e-schema/employees`;
  return {
    structurePath,
    organizationTreePath,
    employeesPath,
  };
};
