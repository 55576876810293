import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import { styled } from '@mui/styles';

import { NavbarModulesTypes } from '@core/components/NavbarMenuItems/NavbarModules.types.ts';
import { useMenu, useRoutes } from '@core/hooks';

export const NavbarModules = ({ modules }: NavbarModulesTypes) => {
  const { handleMenuClose } = useMenu();

  const { t } = useTranslation();

  const { getMainModulePath, getTarget } = useRoutes();

  const navigate = useNavigate();

  return (
    <>
      {modules.map((module) => (
        <MenuItem
          key={module}
          onClick={() => {
            handleMenuClose();
            if (getTarget(module) === '_blank') {
              window.open(getMainModulePath(module), '_blank');
            } else navigate(getMainModulePath(module));
          }}
          sx={{ display: 'block', borderRadius: 2, width: 96, height: 96 }}
        >
          <StyledImg
            src={`/assets/${module.toLowerCase()}-icon-module.svg`}
            alt={module}
            style={{
              width: 35,
              height: 'auto',
              marginLeft: 17.5,
              marginTop: 5,
            }}
          />
          <div style={{ textAlign: 'center' }}> {t(`tiles.${module}`)}</div>
        </MenuItem>
      ))}
    </>
  );
};

const StyledImg = styled('img')({
  width: 35,
  height: 'auto',
  marginLeft: 17.5,
  marginTop: 5,
});
