import { Box, styled, useTheme } from '@mui/material';

import { AuthContainerProps } from '@/core/components/AuthContainer/AuthContainer.types.ts';
import { FillScreen, FlexCenter } from '@/core/styles';

const AuthContainer = ({ children }: AuthContainerProps) => {
  const theme = useTheme();

  return (
    <FillScreen>
      <FlexCenter
        style={{
          backgroundColor: theme.palette.grey[50],
        }}
      >
        <LoginContainer
          style={{
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.grey[200],
          }}
        >
          <Logo src="/assets/logo.svg" alt="Logo" />
          <Content>{children}</Content>
        </LoginContainer>
      </FlexCenter>
    </FillScreen>
  );
};

const LoginContainer = styled(Box)({
  padding: '96px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  border: '1px solid',
  borderRadius: 8,
});

const Logo = styled('img')({
  position: 'absolute',
  top: '24px',
  left: '24px',
});

const Content = styled(Box)({
  display: 'flex',
  width: '360px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px',
});

export default AuthContainer;
