import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  AddWorkstationModal,
  DeleteWorkstation,
  EditWorkstationModal,
} from '@e-schema/components';
import * as Sentry from '@sentry/browser';

import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  styled,
} from '@mui/material';

import {
  useFindSingleOrganizationPositionsQuery,
  useUpdatePositionsMutation,
} from '@/__generated__/graphql';
import { SearchTextField, Snackbar } from '@/core/components';
import { FlexCenter } from '@/core/styles';
import { useOrganizationStore } from '@/stores';

import { WorkstationRow } from './WorkstationRow';

export const WorkstationsTable = () => {
  const { companyName } = useParams();
  const { organization } = useOrganizationStore();
  const { t } = useTranslation();

  const { data: organizationPositions, loading: isLoadingWorkstations } =
    useFindSingleOrganizationPositionsQuery({
      variables: {
        input: {
          url: companyName!,
        },
      },
      fetchPolicy: 'no-cache',
    });

  const [updatePositions] = useUpdatePositionsMutation();

  const [snackbar, setSnackbar] = useState<string | null>(null);
  const [workstations, setWorkstations] = useState(
    organizationPositions?.findSingleOrganization.positions || [],
  );
  const [search, setSearch] = useState('');
  const [workstationToEdit, setWorkstationToEdit] = useState<string | null>(
    null,
  );
  const [workstationToDelete, setWorkstationToDelete] = useState<string | null>(
    null,
  );

  useEffect(() => {
    setWorkstations(
      organizationPositions?.findSingleOrganization.positions || [],
    );
  }, [organizationPositions]);

  const handleSetWorkstations = async (
    newWorkstations: string[],
    snackbarMessage: string,
  ) => {
    setWorkstations([...newWorkstations]);
    if (snackbarMessage) setSnackbar(snackbarMessage);
    try {
      const res = await updatePositions({
        variables: {
          input: {
            id: organization[companyName!],
            positions: [...newWorkstations],
          },
        },
      });
      setWorkstations(
        res.data?.updatePositions.positions || [...newWorkstations],
      );
    } catch (e) {
      setSnackbar(t('error.errorAppearance'));
      Sentry.captureException(e);
    }
  };

  return (
    <>
      {!!workstationToEdit && (
        <EditWorkstationModal
          workstationToEdit={workstationToEdit}
          closeModal={() => setWorkstationToEdit(null)}
          setWorkstations={handleSetWorkstations}
          workstations={workstations}
        />
      )}
      {!!workstationToDelete && (
        <DeleteWorkstation
          closeModal={() => setWorkstationToDelete(null)}
          workstations={workstations}
          setWorkstations={handleSetWorkstations}
          workstationToDelete={workstationToDelete}
        />
      )}

      <MainContainer>
        <CustomStack>
          <SearchTextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t('organization.search')}
          />
          <AddWorkstationModal
            handleAddWorkstation={handleSetWorkstations}
            workstations={workstations}
          />
        </CustomStack>
        {workstations.length < 1 && !isLoadingWorkstations ? (
          <FlexCenter sx={{ marginTop: 20 }}>
            {t('organizationSettings.noWorkstations')}
          </FlexCenter>
        ) : null}
        <TableContainer>
          {isLoadingWorkstations ? (
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          ) : (
            <Table size="small">
              <TableBody>
                {workstations
                  .filter((workstation) =>
                    workstation.toLowerCase().includes(search.toLowerCase()),
                  )
                  .map((workstation, id) => (
                    <WorkstationRow
                      key={id}
                      row={workstation}
                      handleEditWorkstation={() =>
                        setWorkstationToEdit(workstation)
                      }
                      handleRemoveWorkstation={() =>
                        setWorkstationToDelete(workstation)
                      }
                    />
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </MainContainer>
      <Snackbar
        message={snackbar}
        open={!!snackbar}
        onClose={() => setSnackbar(null)}
      />
    </>
  );
};

const MainContainer = styled('div')({
  padding: '16px 24px',
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
});

const CustomStack = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
});
