import { captureException } from '@sentry/react';

import { useCompanyId } from '@core/hooks';
import { useDashboardStateDataContext } from '@core/modules/DashbordModule/contexts/SetDashboard.context.tsx';

import {
  DashboardTypesEnum,
  SupportedWidgetsEnum,
  usePinToDashboardMutation,
} from '@/__generated__/graphql';

import { useUserDashboardContext } from '../../pages/Dashboard/contexts/UserDashboardContext';

export const useAddKaizenWidget = () => {
  const [pinToDashboard] = usePinToDashboardMutation();

  const { closeWidgetSidebar } = useUserDashboardContext();

  const { companyId } = useCompanyId();

  const { setDashboard } = useDashboardStateDataContext();

  const addKaizenWidget = async (type: SupportedWidgetsEnum) => {
    if (!companyId) return;
    try {
      const { data } = await pinToDashboard({
        variables: {
          input: {
            widgetType: type,
            organizationId: companyId,
            dashboardType: DashboardTypesEnum.UserDashboard,
          },
        },
      });

      if (data?.pinToDashboard) setDashboard(data.pinToDashboard);

      closeWidgetSidebar();
    } catch (ex) {
      captureException(ex);
    }
  };

  return { addKaizenWidget };
};
