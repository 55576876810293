import { useTranslation } from 'react-i18next';

import { KaizenSettings } from '@organizations/pages/organizationSettings/content/KaizenSettings.tsx';
import { KpiSetting } from '@organizations/pages/organizationSettings/content/KpiSettings';

import { Box, styled } from '@mui/material';

import { useCompanyId } from '@core/hooks';
import { PermissionsModuleTypes } from '@core/modules/PermissionModule/PermissionsModule.types.ts';
import { PermissionsModuleProvider } from '@core/modules/PermissionModule/context/PermissionsModuleContext.tsx';

import { ModuleNamesEnum } from '@/__generated__/graphql';
import { TabViewWrapper } from '@/core/components';

import { Users } from './views/Users';

export const PermissionsModule = ({
  moduleName,
  possibleRoles,
}: Omit<PermissionsModuleTypes, 'organizationId'>) => {
  const { companyId } = useCompanyId();

  const { t: permT } = useTranslation('permissions');

  return (
    <PermissionsModuleProvider
      moduleName={moduleName}
      possibleRoles={possibleRoles}
      organizationId={companyId}
    >
      <CustomLayout>
        {moduleName === ModuleNamesEnum.Eflow && (
          <TabViewWrapper
            tabs={[
              {
                tabName: permT('flow.tabs.users'),
                tabComponent: <Users />,
              },
            ]}
          />
        )}
        {moduleName === ModuleNamesEnum.Kaizen && <KaizenSettings />}
        {moduleName === ModuleNamesEnum.Kpi && <KpiSetting />}
      </CustomLayout>
    </PermissionsModuleProvider>
  );
};

const CustomLayout = styled(Box)({
  margin: '-30px -24px',
  overflowY: 'auto',
  overflowX: 'hidden',
});
