import { Employee, FileModel } from '@/__generated__/graphql';

export type SubmissionBuilderInitialStateType = {
  submissionName: string;
  problem: ProblemSolutionModel;
  solution: ProblemSolutionModel;
  authors: Employee[];
};

export type ProblemSolutionModel = {
  description: string;
  files: Array<File | FileModel>;
};

export const SubmissionBuilderInitialState: SubmissionBuilderInitialStateType =
  {
    submissionName: '',
    problem: {
      description: '',
      files: [],
    },
    solution: {
      description: '',
      files: [],
    },
    authors: [],
  };
