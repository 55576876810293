import { captureException } from '@sentry/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import {
  DashboardFragment,
  useChangeWidgetVisibilityMutation,
} from '@/__generated__/graphql.ts';

export const useChangeWidgetVisibility = (
  setDashboard: Dispatch<SetStateAction<DashboardFragment | undefined>>,
  dashboardId?: string,
) => {
  const [changeWidgetVisibilityMutation] = useChangeWidgetVisibilityMutation();

  const { t } = useTranslation('dashboard');

  const { companyId } = useCompanyId();

  const { openSnackbar } = useSnackbarContext();

  const changeWidgetVisibility = async (
    layoutKey: string,
    isHidden: boolean,
  ) => {
    try {
      openSnackbar(
        t(
          `widgets.${isHidden ? 'widgetHideInProgress' : 'widgetShowInProgress'}`,
        ),
      );
      const { data } = await changeWidgetVisibilityMutation({
        variables: {
          input: {
            dashboardId: dashboardId!,
            organizationId: companyId,
            widgetLayoutKey: layoutKey,
            isHidden,
          },
        },
      });

      if (data) {
        openSnackbar(
          t(
            `widgets.${isHidden ? 'hiddenWidgetSuccess' : 'showWidgetSuccess'}`,
          ),
        );
        setDashboard(data.changeWidgetVisibility as DashboardFragment);
      }
    } catch (e) {
      openSnackbar(t('widgets.changeVisibilityWidgetError'));
      captureException(e);
    }
  };

  return {
    changeWidgetVisibility,
  };
};
