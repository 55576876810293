import { ChartsGroup } from '@kaizen/pages/reports/components/ChartsGroup/ChartsGroup.tsx';
import { StatisticBlockGroup } from '@kaizen/pages/reports/components/StatisticBlockGroup/StatisticBlockGroup.tsx';
import { KaizenReportProvider } from '@kaizen/pages/reports/context/kaizenReport.context.tsx';

import { Box, styled } from '@mui/material';

import { PageTitle } from './components';

export const KaizenReport = () => {
  return (
    <KaizenReportProvider>
      <Container>
        <PageTitle />
        <StatisticBlockGroup />
        <ChartsGroup />
      </Container>
    </KaizenReportProvider>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: 15,
});
