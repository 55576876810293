import { useTranslation } from 'react-i18next';

import { StatusSelect, SubmissionSelect } from '@kaizen/components';
import { useKaizenDashboardFiltersContext } from '@kaizen/components/SubmissionTable/Contexts/useKaizenSubmissionTable.context.tsx';

import { Box, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import {
  AssignedToMeSwitch,
  SearchTextField,
  UserMultiSelect,
} from '@core/components';
import { useCompanyId } from '@core/hooks';

import { GetEmployeesForMultiSelectQuery } from '@/__generated__/graphql.ts';

export const SubmissionTableFilters = () => {
  const { t } = useTranslation('kaizen');

  const { companyId } = useCompanyId();

  const {
    searchDateValue,
    isAssignedToMe,
    setSearchInputValue,
    setSearchDateValue,
    setSearchStatuses,
    setSubmissionPeriodId,
    setAssignedToMe,
    searchInputValue,
    setDebouncedSearchValue,
    selectedEmployees,
    setSelectedEmployees,
  } = useKaizenDashboardFiltersContext();

  return (
    <>
      <Stack flexDirection="row" gap="10px" flexWrap={'wrap'} width={'100vw'}>
        <SearchTextField
          placeholder={t('dashboard.filters.nameEmployee')}
          value={searchInputValue}
          onChange={(e) => {
            setSearchInputValue(e.target.value);
            setDebouncedSearchValue(e.target.value);
          }}
        />
        <DatePicker
          label={t('dashboard.filters.date')}
          value={searchDateValue}
          onChange={(date) => setSearchDateValue(date)}
          format={'DD/MM/YYYY'}
          slotProps={{
            textField: { size: 'small' },
            field: { clearable: true },
          }}
          sx={{ width: '180px' }}
        />
        <UserMultiSelect
          label={t('dashboard.filters.participants')}
          onSelectedUsers={(
            users: GetEmployeesForMultiSelectQuery['getEmployees']['items'],
          ) => setSelectedEmployees(users)}
          selectedUsers={[...selectedEmployees]}
          employeeParams={{
            organisationId: companyId,
          }}
        />
        <StatusSelect
          width="220px"
          onSelect={(statuses) => setSearchStatuses(statuses)}
        />
        <SubmissionSelect
          width="300px"
          onSelect={(periodIds) => setSubmissionPeriodId(periodIds)}
        />

        <Box sx={{ marginLeft: 'auto' }}>
          <AssignedToMeSwitch
            value={isAssignedToMe}
            onChange={(value) => setAssignedToMe(value)}
          />
        </Box>
      </Stack>
    </>
  );
};
