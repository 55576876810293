import { OrganizationNode } from '@core/types';
import { NodeId } from '@/types';
import { getAllChild } from '..';


/**
 *
 * @description it check if all children of selected its selected too
 * @param node specific node
 * @param list list of all node children
 * @param organizationTree source
 * @param localNodes list of selected indexes in a tree
 * @returns {boolean}
 */

export function isAllChildrenChecked(
  node: OrganizationNode | undefined,
  list: NodeId[],
  organizationTree: OrganizationNode[],
  localNodes: NodeId[],
): boolean {
  if (!node) return false;
  const allChild = getAllChild(node.id, organizationTree);
  const nodeIdIndex = allChild.indexOf(node.id);
  allChild.splice(nodeIdIndex, 1);

  return allChild.every((nodeId) => localNodes.concat(list).includes(nodeId));
}