import { useTranslation } from 'react-i18next';

import { UnitsEnum } from '@kpi/common';

import { Checkbox, TableCell, TableRow } from '@mui/material';

import { KpiRateDto } from '@/__generated__/graphql';
import { theme } from '@/theme';

import { useCreateChartContext } from '../../context';
import { KpiRateRowProps } from './KpiRateRow.type';

export const KpiRateRow = (props: KpiRateRowProps) => {
  const { t } = useTranslation('kpi');
  const { t: commonT } = useTranslation();

  const { determineIfDisabled, determineIfSelected, handleSelectKpi } =
    useCreateChartContext();

  const style = determineIfDisabled(props as KpiRateDto)
    ? { color: theme.palette.grey[300] }
    : {};

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={determineIfSelected(props as KpiRateDto)}
          disabled={determineIfDisabled(props as KpiRateDto)}
          onChange={() => handleSelectKpi(props as KpiRateDto)}
        />
      </TableCell>
      <TableCell sx={style}>{props.rateName}</TableCell>
      <TableCell sx={style}>{props.organizationUnit.name}</TableCell>
      <TableCell
        sx={style}
      >{`${props.assignee.firstName} ${props.assignee.lastName}`}</TableCell>
      <TableCell sx={style}>
        {Object.values(UnitsEnum).includes(props.unit.unitName as UnitsEnum)
          ? t(`units.${props.unit.unitName as UnitsEnum}`)
          : props.unit.unitName}{' '}
        ({props.unit.unitShortcut})
      </TableCell>
      <TableCell sx={style}>
        {props.hasTarget ? commonT('common.yes') : '-'}
      </TableCell>
    </TableRow>
  );
};
