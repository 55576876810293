import {
  CancelSubmission,
  EditSubmissionButton,
  KaizenSubmissionStatusChangeButton,
  RejectSubmissionImplementation,
  RemoveSubmissionButton,
} from '@kaizen/components';
import { AcceptImplementation } from '@kaizen/components/Buttons/AcceptImplementationButton/AcceptImplementation.tsx';
import { SendSubmissionToSupervisorAssessmentButton } from '@kaizen/components/Buttons/SendSubmissionToSupervisorAssessment/SendSubmissionToSupervisorAssessmentButton.tsx';

import { KaizenStatuses } from '@/__generated__/graphql.ts';

export const useRenderButtonForSubmissionAuthor = () => {
  const renderButtonsForSubmissionAuthor = (
    submissionStatus: KaizenStatuses,
    submissionId: string,
    isPrivilegedUser: boolean,
  ) => {
    const itemsToRender = [];

    switch (submissionStatus) {
      case KaizenStatuses.Draft:
      case KaizenStatuses.Adjustment:
        itemsToRender.push(
          <RemoveSubmissionButton submissionId={submissionId} />,
          <EditSubmissionButton submissionId={submissionId} />,
          <SendSubmissionToSupervisorAssessmentButton
            submissionId={submissionId}
          />,
        );

        break;

      case KaizenStatuses.SupervisorAssessment:
        itemsToRender.push(<CancelSubmission submissionId={submissionId} />);
        break;

      case KaizenStatuses.ImplementationAccepted:
        itemsToRender.push(
          <RejectSubmissionImplementation submissionId={submissionId} />,
          <AcceptImplementation submissionId={submissionId} />,
        );

        break;

      default:
        return null;
    }

    if (isPrivilegedUser) {
      const lastButton = itemsToRender.pop();

      itemsToRender.push(
        <KaizenSubmissionStatusChangeButton
          withCustomButton={true}
          customButton={lastButton}
          submissionId={submissionId}
          selectedStatus={submissionStatus}
          color={
            submissionStatus === KaizenStatuses.SupervisorAssessment
              ? 'error'
              : 'primary'
          }
        />,
      );
    }

    return itemsToRender;
  };

  return {
    renderButtonsForSubmissionAuthor,
  };
};
