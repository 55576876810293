/* eslint-disable @typescript-eslint/naming-convention */
import { isEqual } from 'lodash';
import { z } from 'zod';

import { KpiRatingTargetType } from '@/__generated__/graphql.ts';

export const KpiRateValidationSchema = (fieldRequired: string) =>
  z
    .object({
      dueToDateTimeout: z.number().min(1, fieldRequired),
      hasTarget: z.boolean(),
      organizationUnitId: z.string().min(1, fieldRequired),
      rateDescription: z.string().optional(),
      organizationId: z.string().min(1, fieldRequired),
      rateName: z.string().min(1, fieldRequired),
      reporterId: z.string().min(1, fieldRequired),
      targetType: z.nativeEnum(KpiRatingTargetType),
      unit: z.object({
        unitName: z.string().min(1, fieldRequired),
        unitShortcut: z.string().min(1, fieldRequired),
      }),
      targetData: z.any().optional(),
    })
    .refine(
      (data) => {
        if (!data.hasTarget) {
          return isEqual(data.targetData, {});
        }
        if (data.targetType === KpiRatingTargetType.MonthlyUnified) {
          return z.object({ 1: z.number().min(1) }).safeParse(data.targetData)
            .success;
        }
        if (data.targetType === KpiRatingTargetType.MonthlyCustom) {
          return z
            .object({
              1: z.number().min(1),
              2: z.number().min(1),
              3: z.number().min(1),
              4: z.number().min(1),
              5: z.number().min(1),
              6: z.number().min(1),
              7: z.number().min(1),
              8: z.number().min(1),
              9: z.number().min(1),
              10: z.number().min(1),
              11: z.number().min(1),
              12: z.number().min(1),
            })
            .safeParse(data.targetData).success;
        }
        return false;
      },
      {
        message: fieldRequired,
        path: ['targetData'],
      },
    );
