import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { RechartBarchartCustomTooltip } from '@core/components/Statistica/Tooltips';
import { ChartInput } from '@core/components/Statistica/types/ChartInput.types.ts';
import { useRecharts } from '@core/hooks';
import { setUnitShortcuts } from '@core/utils';

export const RechartLineChart: FC<ChartInput> = (props) => {
  const ref = useRef(null);
  const { chartLegend, chartValues, toMonthLegend, renderChartLegend } =
    useRecharts(props, ref);
  const { t } = useTranslation('charts');

  return (
    <ResponsiveContainer width="100%" height="100%" ref={ref}>
      <LineChart data={chartValues} width={100} height={100}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          type={'category'}
          interval={0}
          tickFormatter={(value) => toMonthLegend(+value)}
        />
        <YAxis type="number" />
        <Tooltip
          cursor={{ fill: 'rgba(0,0,0,0)' }}
          content={(props) => (
            <RechartBarchartCustomTooltip
              active={props.active}
              payload={props.payload as unknown as never}
              label={props.label}
              untransformedChartValues={chartValues}
            />
          )}
        />
        <Legend content={<>{renderChartLegend()}</>} />
        {chartLegend.map((entry) => (
          <Line
            dataKey={entry.dataKey as string}
            key={entry.dataKey}
            name={
              entry.isTarget
                ? t('target', { chartName: entry.name })
                : entry.name
            }
            stroke={entry.fill}
            fill={entry.fill}
            strokeWidth={props.lineWidth}
            type={entry.isTarget ? 'monotone' : 'linear'}
            {...(entry.isTarget && { strokeDasharray: '5 5' })}
          >
            {props.displayOptions.showDataLabels && (
              <LabelList
                dataKey={entry.dataKey}
                position="top"
                formatter={(value: number | string) =>
                  setUnitShortcuts(props, value)
                }
              />
            )}
          </Line>
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
