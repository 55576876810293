import { SystemRoles } from '@/__generated__/graphql';
import { checkGqlSystemRolesPermission } from '@/core/utils';
import { useUserStore } from '@/stores';

/**
 * Simple hook to check if user has permission to access certain page
 * SystemAdmin and Root roles have access to all pages so there are included by default
 * @returns true if user has permission, false otherwise
 */

export const useCheckUserPermission = () => {
  const { userGroups } = useUserStore();

  return (rolesArray: SystemRoles[] = []) =>
    checkGqlSystemRolesPermission(userGroups, [
      SystemRoles.Root,
      SystemRoles.SystemAdmin,
      ...(rolesArray || []),
    ]);
};
