import { fetchAuthSession } from '@aws-amplify/core';
import { CCognitoUser } from '@core/providers/CognitoUser.ts';
import type { JWT } from 'aws-amplify/auth';
import { fetchUserAttributes, signIn } from 'aws-amplify/auth';
import { SystemRoles } from '@/__generated__/graphql.ts';
import { IUser } from '@/core/types';
import type { CognitoResponseLoginType } from './CognitoHandler.types';

export class CCognitoHandler {
  //================== LOGIN ==================
  /**
   * Login to Cognito with the given username and password.
   * @param username The username to log in with.
   * This is the email address.
   * @param password User password
   * @returns {Promise<CognitoResponseLoginType>} Status of login response
   * @example
   * const response = await CCognitoHandler.login('login@mail.com', 'password');
   * if (response === 'SUCCESS') {
   *  // Login success
   * } else if (response === 'NEW_PASSWORD_REQUIRED') {
   * // User needs to change password
   * } else if (response === 'INVALID_CREDENTIALS') {
   * // Invalid credentials
   * } else {
   * // Unknown error
   * }
   *
   */
  public static async login(
    username: string,
    password: string,
  ): Promise<CognitoResponseLoginType> {
    try {
      const { nextStep, isSignedIn } = await signIn({ username, password });
      //user does not require any action. Login success -> need to extract company name
      const cognitoAtributes = await CCognitoHandler.getCompanyFromUser();

      if (isSignedIn) return { challengeName: 'SUCCESS', cognitoAtributes };
      else return { challengeName: nextStep.signInStep, cognitoAtributes };
    } catch (error) {
      if (
        typeof error === 'object' &&
        error &&
        'name' in error &&
        error.name === 'NotAuthorizedException'
      ) {
        return { challengeName: 'INVALID_CREDENTIALS', cognitoAtributes: null };
      }
      return { challengeName: 'UNKNOWN_ERROR', cognitoAtributes: null };
    }
  }

  /**
   * Extracts the user roles from the user attributes.
   * @returns {Promise<SystemRoles[]>} The user roles from cognito.
   * @throws {Error} If the user is not logged in.
   *
   * @example
   * const userRoles = await CCognitoHandler.getUserGroups();
   * userRoles // ['root']
   *
   * @example
   * const userRoles = await CCognitoHandler.getUserGroups(); // throws Error('NotAuthorizedException')
   *
   */
  public static async getUserGroups(): Promise<SystemRoles[]> {
    const { tokens } = await fetchAuthSession();
    if (!tokens || !tokens.idToken) throw Error('NotAuthorizedException');
    const idToken: JWT = tokens.idToken;
    return (idToken.payload['cognito:groups'] as SystemRoles[]) || [];
  }

  /**
   * Extracts the company name from the user attributes.
   * @returns {Promise<CurrentUserAtributes | null>} The user attributes from cognito. Null if the user is not logged in.
   */
  private static async getCompanyFromUser(): Promise<IUser | null> {
    try {
      return CCognitoUser.transformCognitoAttributes(
        await fetchUserAttributes(),
      );
    } catch (error) {
      return null;
    }
  }
}
