import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Box, CircularProgress, Typography, styled } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import { findPathById } from '@core/utils';

import { useGetCheckboxOrganizationUnitStructureQuery } from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';
import { useOrganizationStore } from '@/stores/useOrganizationStore/useOrganizationStore';
import { RenderTree, TreeTarget } from '@/types';

interface IProps {
  handleSelect: (treeTarget: TreeTarget) => void;
}

export const FilterTreeView = ({ handleSelect }: IProps) => {
  const { t } = useTranslation();
  const { companyName: companyUrl } = useParams();

  const { organization } = useOrganizationStore();
  const { organizationId } = useUserStore();

  const orgId =
    companyUrl != undefined ? organization[companyUrl || ''] : organizationId;

  const [organizationUnits, setOrganizationUnits] = useState<
    RenderTree | undefined
  >(undefined);

  const {
    loading: isLoading,
    data,
    error,
  } = useGetCheckboxOrganizationUnitStructureQuery({
    variables: {
      input: {
        id: orgId!,
      },
    },
  });

  useEffect(() => {
    !!data?.getOrganizationUnitsStructure &&
      setOrganizationUnits(data.getOrganizationUnitsStructure as RenderTree);
  }, [data]);

  const renderTree = (nodes: RenderTree) => (
    <TreeItem
      key={nodes.id}
      itemId={nodes.id}
      style={{ width: '100%' }}
      label={
        <Typography variant="bodyMedium" fontSize={14} lineHeight={'28px'}>
          {nodes.name}
        </Typography>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node: RenderTree) => renderTree(node))
        : null}
    </TreeItem>
  );

  const handle = (
    _event: SyntheticEvent<Element, Event>,
    nodeIds: string | string[] | null,
  ) => {
    if (!organizationUnits || !nodeIds) return;
    handleSelect({
      targetId: nodeIds.toString(),
      path: findPathById(organizationUnits, nodeIds.toString()) || '',
    });
  };

  return (
    <MainTreeView
      aria-label="rich object"
      slots={{
        expandIcon: ChevronRight,
        collapseIcon: ExpandMore,
      }}
      defaultExpandedItems={['root']}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
      onSelectedItemsChange={handle}
    >
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : !!error || !organizationUnits ? (
        <Typography variant="bodyRegular">{t('common.noData')}</Typography>
      ) : (
        renderTree(organizationUnits)
      )}
    </MainTreeView>
  );
};

const MainTreeView = styled(SimpleTreeView)({
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  color: 'var(--text-text-secondary, #333)',
  textOverflow: 'ellipsis',
});
