import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { omit } from 'lodash';

import { Box } from '@mui/material';

import {
  Employee,
  useUpdateUserNameSurnamePhoneMutation,
} from '@/__generated__/graphql.ts';
import { ButtonGroupConfirmCancel } from '@/core/components/ButtonGroupConfirmCancel/ButtonGroupConfirmCancel.tsx';
import { UserEditProperty } from '@/core/components/UserEdit/UserEditProp/UserEditProperity.tsx';
import { UserSectionProps } from '@/core/components/UserEdit/UserSection/UpdateUser.types.ts';
import { UserSectionSchema } from '@/core/components/UserEdit/UserSection/UserSection.Schema.ts';
import {
  FlexEndBox,
  FormContainer,
  SettingsSectionContainer,
} from '@/core/components/UserEdit/style.ts';

export const UserSection = ({
  isReadOnly = false,
  ...props
}: UserSectionProps) => {
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);

  const [updateEmployeeMutation, { data: updateEmployeeData }] =
    useUpdateUserNameSurnamePhoneMutation();

  const submitUserUpdate = async (
    data: Partial<Pick<Employee, 'firstName' | 'lastName' | 'email' | 'phone'>>,
  ) => {
    try {
      const response = await updateEmployeeMutation({
        variables: {
          input: {
            ...omit(data, 'email'),
            id: props.employee!.id,
            organizationId: props.employee!.organizationId,
          },
        },
      });
      if (response) {
        props.setToastMessage(t('common.dataSaved'));
      }
    } catch (e) {
      captureException(e);
      props.setToastMessage(t('common.dataNotSaved'));
    } finally {
      setIsEdit(false);
      if (props.refetch) props.refetch();
    }
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors: userErrors },
  } = useForm({
    defaultValues: props.employee,
    resolver: zodResolver(UserSectionSchema(t)),
  });

  useEffect(() => {
    reset(props.employee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employee]);

  useEffect(() => {
    if (updateEmployeeData) {
      reset({
        ...props.employee,
        ...updateEmployeeData.updateEmployee,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEmployeeData]);

  return (
    <SettingsSectionContainer>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <FormContainer onSubmit={handleSubmit(submitUserUpdate)}>
        <Box width={'100%'}>
          <UserEditProperty
            {...register('firstName')}
            isLoading={props.isLoading}
            propertyName={t('employees.name')}
            propertyValue={props.employee?.firstName || ''}
            placeholder={t('employees.name')}
            error={!!userErrors?.firstName}
            helperText={userErrors?.firstName?.message}
            isEditButtonVisible={!isReadOnly}
            edit={isEdit}
            {...(!isReadOnly && {
              onEdit: () => setIsEdit((prev) => !prev),
            })}
          />
          <UserEditProperty
            {...register('lastName')}
            isLoading={props.isLoading}
            edit={isEdit}
            propertyName={t('employees.lastName')}
            propertyValue={props.employee?.lastName || ''}
            placeholder={t('employees.lastName')}
            error={!!userErrors?.lastName}
            helperText={userErrors?.lastName?.message}
          />
          <UserEditProperty
            isLoading={props.isLoading}
            edit={isEdit}
            propertyName={t('common.email')}
            propertyValue={props.employee?.email || ''}
            placeholder={t('common.email')}
            error={!!userErrors?.email}
            helperText={userErrors?.email?.message}
            {...register('email')}
            disabled={true}
          />
          <UserEditProperty
            isLoading={props.isLoading}
            edit={isEdit}
            propertyName={t('common.phoneNumber')}
            propertyValue={props.employee?.phone || ''}
            placeholder={t('common.phoneNumber')}
            error={!!userErrors?.phone}
            helperText={userErrors?.phone?.message}
            {...register('phone')}
          />
          <FlexEndBox>
            <ButtonGroupConfirmCancel
              isVisible={isEdit}
              onCancel={() => {
                reset();
                setIsEdit(false);
              }}
            />
          </FlexEndBox>
        </Box>
      </FormContainer>
    </SettingsSectionContainer>
  );
};
