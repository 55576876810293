import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useLiveUpdate } from '@e-flow/pages/flowDashboard/hooks/useLiveUpdate.ts';

import { useCompanyId } from '@core/hooks';

import { useGetEflowNameAndParamsLazyQuery } from '@/__generated__/graphql.ts';

export const useMainFlowDashboard = () => {
  const { companyId } = useCompanyId();

  const { flowId } = useParams();

  const [findEflowQuery, { data, loading: isLoading }] =
    useGetEflowNameAndParamsLazyQuery();

  const { init: initFlowLifeUpdate } = useLiveUpdate();
  useEffect(() => {
    if (!companyId || !flowId) return;

    initFlowLifeUpdate({
      organizationId: companyId,
      eFlowId: flowId,
    });

    void findEflowQuery({
      variables: {
        input: {
          organizationId: companyId,
          id: flowId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, findEflowQuery, flowId]);

  return {
    isLoading,
    eFlow: data?.findOneEFlow,
  };
};
