import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectPositionInputProps } from '@e-schema/components';
import { omit } from 'lodash';

import { Autocomplete, TextField } from '@mui/material';

import {
  FindSingleOrganizationPositionsQuery,
  useFindSingleOrganizationPositionsLazyQuery,
} from '@/__generated__/graphql';

export const SelectPositionInput = forwardRef(
  (props: SelectPositionInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();
    const [positions, setPositions] = useState<
      FindSingleOrganizationPositionsQuery['findSingleOrganization']['positions']
    >([]);

    const [query, { data }] = useFindSingleOrganizationPositionsLazyQuery();

    useEffect(() => {
      if (props.organizationId) {
        void query({
          variables: {
            input: {
              id: props.organizationId,
            },
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.organizationId]);

    useEffect(() => {
      if (data?.findSingleOrganization) {
        setPositions(data?.findSingleOrganization.positions);
      }
    }, [data]);

    return (
      <Autocomplete
        size="small"
        defaultValue={props.defaultValue}
        fullWidth
        ref={ref}
        options={positions || []}
        noOptionsText={t('error.noResult')}
        renderInput={(params) => (
          <TextField
            {...omit(props, 'organizationId')}
            {...params}
            label={t('employees.position')}
          />
        )}
      />
    );
  },
);
