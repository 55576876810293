import { writteSingleKaizenSubmissionFragment } from '@kaizen/hooks/useUpdateSingleSubmissionCache/Cache';

import { KaizenSubmissionFragment } from '@/__generated__/graphql.ts';

export const useUpdateSingleSubmissionCache = () => {
  const updateSingleSubmissionCache = (
    kaizenSubmission: KaizenSubmissionFragment,
  ) => {
    writteSingleKaizenSubmissionFragment(
      kaizenSubmission,
      kaizenSubmission.organizationId,
    );
  };

  return {
    updateSingleSubmissionCache,
  };
};
