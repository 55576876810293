import { styled } from '@mui/material';

export const Sales = () => {
  return (
    <HugeIframe src={process.env.REACT_APP_STREAMLIT_SALES_URL}></HugeIframe>
  );
};

const HugeIframe = styled('iframe')`
  width: calc(100vw - 200px);
  height: 100%;
  border: none;
`;
