import styled from '@emotion/styled';

import { MenuItem } from '@mui/material';

import { NotificationContainerProps } from './NotificationContainer.types';

//TODO --> marked as not used
export const NotificationContainer = (props: NotificationContainerProps) => {
  return (
    <Container divider onClick={props.onClick}>
      {props.children}
    </Container>
  );
};

const Container = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '15px',
  gap: '20px',
  minWidth: '300px',
});
