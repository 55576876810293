import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { CreatedByBadge } from '@e-flow/pages/newAnalysis/new-analysis-modules/SettingsModule/CreatedBy/CreatedByBadge.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs, { Dayjs } from 'dayjs';

import { Stack, Typography, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { UserMultiSelect } from '@core/components';
import { AssignToMeButton } from '@core/components/AssignToMeButton';

import {
  CreateEflowInput,
  GetEmployeesForMultiSelectQuery,
  SystemRoles,
} from '@/__generated__/graphql.ts';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { useOrganizationStore, useUserStore } from '@/stores';

import { EFlowSchema } from './Setting.schema';
import { SettingModulesTypes } from './SettingModule.types';
import { defaultValues } from './defaultValues';
import { getAnalizeLeadersIds } from './utils';

export const SettingsModule = ({
  moveNext,
  currentFormValues,
  setMainAnalysisPart,
  analyseLeaders,
  setAnalyseLeaders,
  createdBy,
}: SettingModulesTypes) => {
  const { t } = useTranslation('eflow');
  const { t: tCommon } = useTranslation();

  const { companyName } = useParams();

  const { organization } = useOrganizationStore();

  const {
    userUuid,
    organizationId: currentUserOrganizationId,
    name,
    lastName,
  } = useUserStore();

  const { handleSubmit, setValue } = useForm({
    defaultValues,
    shouldUnregister: false,
    resolver: zodResolver(EFlowSchema),
  });

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    if (!currentFormValues?.startDate) return;
    setValue('startDate', new Date(currentFormValues.startDate as string));
    setSelectedDate(dayjs(new Date(currentFormValues.startDate as string)));
    setValue(
      'analyzeLeadersIds',
      getAnalizeLeadersIds(currentFormValues.analyzeLeadersIds),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormValues]);

  useEffect(() => {
    setValue(
      'analyzeLeadersIds',

      analyseLeaders.map((user) => ({
        id: user.id,
        organizationId: user.organizationId,
      })),
    );
  }, [analyseLeaders, setValue, userUuid]);

  const onSubmit: SubmitHandler<typeof defaultValues> = (
    data: Pick<CreateEflowInput, 'startDate' | 'analyzeLeadersIds'>,
  ) => {
    setMainAnalysisPart(data);
    moveNext();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3">{t('newAnalysis.settings.settings')}</Typography>
      <Stack gap="12px">
        <Typography variant="bodyMedium">
          {t('newAnalysis.settings.startAnalize')}
        </Typography>
        {/*start date*/}
        <StartDatePicker
          slotProps={{
            textField: { size: 'small' },
          }}
          value={selectedDate}
          format="DD.MM.YYYY"
          defaultValue={selectedDate}
          onChange={(date) => {
            setSelectedDate(date as Dayjs);
            setValue('startDate', (date as Dayjs).toDate());
          }}
        />
      </Stack>
      {/*creator info*/}
      <CreatedByBadge createdBy={createdBy} />

      <Stack gap="12px">
        <Typography variant="bodyMedium">
          {t('newAnalysis.settings.analysisConductor')}
        </Typography>
        <Stack flexDirection="row" alignItems="center">
          <UserMultiSelect
            onSelectedUsers={(
              users: GetEmployeesForMultiSelectQuery['getEmployees']['items'],
            ) => setAnalyseLeaders(users)}
            selectedUsers={[...analyseLeaders]}
            employeeParams={{
              organisationId: organization[companyName!],
              systemRoles: [
                SystemRoles.EflowCreator,
                SystemRoles.Administrator,
                SystemRoles.SystemAdmin,
                SystemRoles.KeyUser,
              ],
            }}
          />

          <AssignToMeButton
            onClick={() => {
              setAnalyseLeaders((previous) => [
                ...previous,
                {
                  id: userUuid,
                  firstName: name,
                  lastName: lastName,
                  organizationId: currentUserOrganizationId,
                } as GetEmployeesForMultiSelectQuery['getEmployees']['items'][0],
              ]);
            }}
            disabled={analyseLeaders.some(
              (
                user: GetEmployeesForMultiSelectQuery['getEmployees']['items'][0],
              ) => user.id === userUuid,
            )}
          />
        </Stack>
      </Stack>

      <ContinueButton type="submit" variant={'contained'}>
        {tCommon('common.continue')}
      </ContinueButton>
    </Form>
  );
};

const StartDatePicker = styled(DatePicker)({
  width: '168px',
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const ContinueButton = styled(PrimaryButton)({
  width: '100px',
});
