import { TFunction } from 'i18next';
import { z } from 'zod';

export const CommentSchema = (t: TFunction<'eflow'>) =>
  z.object({
    content: z
      .string()
      .min(1, {
        message: t('flowSteps.validationErrors.commentText'),
      })
      .max(250),
    commentCategory: z.string().min(1, {}),
    showInReport: z.boolean().optional(),
  });
