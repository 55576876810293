import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OperationDurationTypes } from '@e-flow/pages/flowReport/components/OperationDuration/OperationDuration.types.ts';

import { StatisticBox, StatisticRow } from '@core/components';

export const OperationDuration = (props: OperationDurationTypes) => {
  const { t } = useTranslation('eflow');

  const [asIsTimeDuration, setAsIsTimeDuration] = useState(0);
  const [toBeTimeDuration, setToBeTimeDuration] = useState(0);

  useEffect(() => {
    setAsIsTimeDuration(
      props.asIs.totalTime
        ? Math.round(
            (props.asIs.addedValueDuration / props.asIs.totalTime) * 100,
          ) || 0
        : 0,
    );
    setToBeTimeDuration(
      props.toBe.totalTime
        ? Math.round(
            (props.toBe.addedValueDuration / (props.toBe?.totalTime || 1)) *
              100,
          ) || 0
        : 0,
    );
  }, [props.asIs, props.toBe]);

  return (
    <StatisticBox
      title={t('reports.duration')}
      withCharts={true}
      chartsToRender={[
        {
          title: asIsTimeDuration !== 0 ? `${asIsTimeDuration}%` : '-',
          values: [props.asIs.addedValueDuration || 1, props.asIs.totalTime],
          variant: 'danger',
          chartSubtitle: t('reports.addedValue'),
          chartTitle: t('reports.asIs'),
          width: 50,
        },
        {
          title: toBeTimeDuration !== 0 ? `${toBeTimeDuration}%` : '-',
          values: [props.toBe.addedValueDuration || 1, props.toBe.totalTime],
          variant: 'secondary',
          chartSubtitle: t('reports.addedValue'),
          chartTitle: t('reports.toBe'),
          width: 50,
        },
      ]}
      withTable={true}
      tableHeaderTitles={[null, t('reports.asIs'), t('reports.toBe')]}
      headerAlignment={'right'}
      children={[
        <StatisticRow
          key={0}
          rowLabel={t('reports.duration')}
          rowValues={[props.asIs.totalTime, props.toBe.totalTime]}
          isLoading={props.isLoading}
          rowType={props.unit}
        />,

        <StatisticRow
          key={1}
          rowLabel={t('reports.addedValueDuration')}
          rowValues={[
            props.asIs.addedValueDuration,
            props.toBe.addedValueDuration,
          ]}
          isLoading={props.isLoading}
          rowType={props.unit}
        />,

        <StatisticRow
          key={2}
          rowLabel={t('reports.percentageOfAddedValueTime')}
          rowValues={[
            asIsTimeDuration !== 0 ? `${asIsTimeDuration}%` : '-',
            toBeTimeDuration !== 0 ? `${toBeTimeDuration}%` : '-',
          ]}
          isLoading={props.isLoading}
        />,
      ]}
    />
  );
};
