import { Route, Routes } from 'react-router-dom';

import { ESchemaLayout } from '@e-schema/layout';
import {
  EmployeeSettings,
  Employees,
  OrganizationTree,
  Structure,
} from '@e-schema/pages';
import { BasicUserInformation } from '@organizations/pages/organizations';
import { ProtectedRoute } from '@router/index';

import { OrganizationModules } from '@/__generated__/graphql';
import { ErrorContent } from '@/core/components';
import { FlexCenter } from '@/core/styles';

export const ESchemaRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute module={OrganizationModules.ESchema} />}>
        <Route path={'/'} element={<ESchemaLayout />}>
          <Route path="structure" element={<Structure />} />
          <Route path="organization" element={<OrganizationTree />} />
          <Route path="employees">
            <Route index element={<Employees />} />
            <Route path=":id" element={<EmployeeSettings />}>
              <Route
                path="general-settings"
                element={<BasicUserInformation forSystemUser={false} />}
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <FlexCenter sx={{ height: '100vh' }}>
              <ErrorContent errorCode="404" />
            </FlexCenter>
          }
        />
      </Route>
    </Routes>
  );
};
