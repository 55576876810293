import { v4 } from 'uuid';

import { formatKey } from '@core/hooks/useRecharts/functions/FormatKey.ts';
import { PickColor } from '@core/hooks/useRecharts/functions/PickColor.ts';

import { ChartTypes } from '@/__generated__/graphql.ts';

import { ChartLegendType, ExtractChartKeyType } from '../types';
import { getName } from './FormatName';
import { formatReturnOfLegend } from './FormatReturnOfLegend';

export const FormatChartLegend = (
  props: ExtractChartKeyType,
  hasTarget: boolean,
  chartType: ChartTypes,
) => {
  const usedCharts: ChartLegendType = {};
  const usedColors: string[] = [];

  props.forEach((chartEntry) => {
    for (const key in chartEntry) {
      if (key === 'name' || key in usedCharts) {
        continue;
      }

      usedCharts[key] = {
        dataKey: key,
        stackId: v4(),
        fill: PickColor.pickColor(usedColors, chartType),
        name: getName(key),
        valueKey: formatKey(key, 'value'),
        ...(hasTarget && { targetKey: formatKey(key, 'target') }),
      };
    }
  });

  return formatReturnOfLegend(usedCharts, chartType);
};
