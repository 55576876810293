import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ModuleContainer } from '@e-flow/pages/flowSteps/components';
import { ParameterInput } from '@e-flow/pages/flowSteps/components/ParameterInput/ParameterInput.tsx';
import { SystemParameterKeysEnum } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum.ts';
import { ParameterWithValue } from '@e-flow/types';
import { isEqual } from 'lodash';

import { Stack, Typography, styled } from '@mui/material';

import { TimeInputFormatTypes } from '@core/components/inputs/TimeInput/types';

import { ParameterTypes } from '@/__generated__/graphql.ts';
import { TimeInput } from '@/core/components/inputs';

import { ParameterProps } from './Parameters.types';

export const Parameters: FC<ParameterProps> = ({
  parameters,
  onFlowStepChange,
  parameterValues,
}) => {
  const { t } = useTranslation('eflow');

  // Sets new values

  const handleChange = (param: ParameterWithValue) => {
    if (isEqual(parameterValues[param.name], param)) return;

    onFlowStepChange({
      parameters: {
        ...parameterValues,
        [param.name]: param,
      },
    });
  };

  return (
    <ModuleContainer title={t('flowSteps.parameters')}>
      {parameters?.map((parameter) => {
        return (
          <StyledTopography key={parameter.name}>
            <Typography>
              {parameter.type === ParameterTypes.System
                ? t(
                    `newAnalysis.parameters.systemParams.${parameter.name as SystemParameterKeysEnum}`,
                  )
                : parameter.name}
              &nbsp;-&nbsp;
              {t(`newAnalysis.parameters.params.${parameter.unit}`)}
            </Typography>
            {parameter?.unit?.startsWith('TIME') ? (
              <TimeInput
                format={parameter.unit as TimeInputFormatTypes}
                onChange={(value) =>
                  handleChange({
                    ...parameter,
                    value,
                  })
                }
                value={
                  parameterValues && parameterValues[parameter.name]?.value
                }
              />
            ) : (
              <ParameterInput
                onChange={(value) =>
                  handleChange({
                    ...parameter,
                    value,
                  })
                }
                value={
                  parameterValues && parameterValues[parameter.name]?.value
                }
              />
            )}
          </StyledTopography>
        );
      })}
    </ModuleContainer>
  );
};

const StyledTopography = styled(Stack)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});
