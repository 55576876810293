import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNetworkState } from '@uidotdev/usehooks';

import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Divider, Menu, MenuItem, Tooltip, styled } from '@mui/material';

import { CommentMenuProps } from '../../../pages/e-flow/components/SingleComment/SingleComment.types';
import { RemoveItemModal } from '../Modals/RemoveItemModal';

export const CommentMenu = (props: CommentMenuProps) => {
  const { t: commonT } = useTranslation();
  const { t } = useTranslation('eflow');

  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);

  const { online: isOnline } = useNetworkState();

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        open={!!props.anchorEl}
        onClose={props.handleCloseMenu}
      >
        {props.canEdit && (
          <StyledMenuItem
            onClick={() => {
              props.handleStartEdit();
              props.handleCloseMenu();
            }}
            disableRipple
          >
            <EditOutlined />
            {commonT('common.edit')}
          </StyledMenuItem>
        )}
        {!isOnline && (
          <Tooltip title={t('common.canNotEditWhileOffline')}>
            <StyledMenuItem
              onClick={props.handleCloseMenu}
              disableRipple
              disabled
            >
              {commonT('common.edit')}
            </StyledMenuItem>
          </Tooltip>
        )}
        {props.canEdit && (
          <StyledMenuItem
            onClick={() => {
              setIsDeleteModalShown(true);
              props.handleCloseMenu();
            }}
            disableRipple
          >
            <DeleteOutline />
            {commonT('common.delete')}
          </StyledMenuItem>
        )}
        {props.canEdit && props.additionalMenuItems && (
          <Divider sx={{ my: 0.5 }} />
        )}
        {props.additionalMenuItems}
      </Menu>
      <RemoveItemModal
        display={isDeleteModalShown}
        closeModal={() => setIsDeleteModalShown(false)}
        onRemove={() => {
          setIsDeleteModalShown(false);
          props.onRemove();
        }}
        headerText={t('newAnalysis.comments.deleteComment')}
        content={t('newAnalysis.comments.deleteCommentConfirmation')}
      />
    </>
  );
};

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  alignItems: 'center',
  padding: '10px 16px',
});
