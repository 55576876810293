import { TFunction } from 'i18next';
import { z } from 'zod';
import { SystemRoles } from '@/__generated__/graphql.ts';


/**
 * Permission section schema
 * @param t - tfunction
 * @constructor
 */
export const PermissionSectionSchema = (t: TFunction) =>
  z.object({
    permissions: z.nativeEnum(SystemRoles, {
      description: t('common.fillTheField'),
      required_error: t('common.fillTheField'),
    }),
  });