import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import { z } from 'zod';

import { Typography, styled, useTheme } from '@mui/material';

import {
  Organization,
  useAddKeyUserToOrganizationMutation,
} from '@/__generated__/graphql';
import { CustomModal, ModalContainer } from '@/core/components';

import { OrganizationSelect } from './OrganizationSelect';

type AssignOrganizationProps = {
  closeModal: (organization?: Organization) => void;
};

export const AssignOrganization = ({ closeModal }: AssignOrganizationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams();
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      organization: '',
    },
    resolver: zodResolver(
      z.object({
        organization: z.string().nonempty(t('error.requiredField')),
      }),
    ),
  });

  const [addKeyUserToOrganization] = useAddKeyUserToOrganizationMutation();

  const onSubmit: SubmitHandler<{
    organization: string;
  }> = async (data) => {
    try {
      if (data.organization && selectedOrganization) {
        await addKeyUserToOrganization({
          variables: {
            input: {
              organizationId: data.organization,
              userId: id!,
            },
          },
        });
        closeModal(selectedOrganization);
      } else {
        setError('organization', {
          type: 'manual',
          message: t('error.requiredField'),
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <CustomModal display={true}>
      <ModalContainer
        closeModal={closeModal}
        headerText={t('organizationSettings.addWorkstation')}
        height="auto"
        sx={{ maxWidth: '528px' }}
        width="100%"
      >
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <CustomForm onSubmit={handleSubmit(onSubmit)}>
          <OrganizationSelect
            onChange={(value) => {
              setValue('organization', value?.id ?? '');
              setSelectedOrganization(value);
            }}
            width="100%"
            error={errors.organization}
          />

          <CustomStack>
            <PrimaryButton onClick={() => closeModal()}>
              <Typography>{t('common.cancel')}</Typography>
            </PrimaryButton>
            <PrimaryButton
              data-testid="addButtonModal"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
              type="submit"
            >
              <Typography>{t('systemSettings.assignOrganization')}</Typography>
            </PrimaryButton>
          </CustomStack>
        </CustomForm>
      </ModalContainer>
    </CustomModal>
  );
};

const CustomForm = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const CustomStack = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 24px 24px 24px',
  gap: '12px',
  flexDirection: 'row',
});
