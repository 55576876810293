import { useEffect, useState } from 'react';

export const useCopied = (toBeCount: number) => {
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [expectationNumberOfSteps, setExpectationNumberOfSteps] =
    useState(toBeCount);

  const setCopied = (numberOfSteps: number) => {
    setExpectationNumberOfSteps((prevValue) => prevValue + numberOfSteps);
    setIsCopied(true);
  };

  useEffect(() => {
    if (toBeCount > expectationNumberOfSteps) {
      setExpectationNumberOfSteps(toBeCount);
    }
  }, [toBeCount, expectationNumberOfSteps]);

  useEffect(() => {
    if (isCopied) setIsCopyModalOpen(true);
  }, [isCopied]);

  useEffect(() => {
    if (expectationNumberOfSteps === toBeCount) setIsCopied(false);
  }, [toBeCount, expectationNumberOfSteps]);

  return {
    isCopied,
    setIsCopied,
    isCopyModalOpen,
    setIsCopyModalOpen,
    setCopied,
  };
};
