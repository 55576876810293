// hooks/useHamburgerMenu.tsx
import { useParams } from 'react-router-dom';

import { EFlowStatuses } from '@/__generated__/graphql';
import { useMenu, useSnackbar } from '@/core/hooks';
import { useIsLastPathReport } from '@/pages/e-flow/hooks';

import { useUpdateEflowStatus } from '..';

export const useHamburgerMenu = (status: EFlowStatuses) => {
  const { flowId } = useParams();

  const { anchorEl, isMenuOpen, handleMenuClose, handleMenuOpen } = useMenu();
  const isLastPathReport = useIsLastPathReport();
  const { isSnackBarOpen, snackBarMessage, openSnackbar, closeSnackbar } =
    useSnackbar();

  const { setEflowStatusAsCompleted, isSetAsFinishedDisabled } =
    useUpdateEflowStatus(flowId!, status, openSnackbar);

  return {
    anchorEl,
    isMenuOpen,
    handleMenuClose,
    handleMenuOpen,
    isSetAsFinishedDisabled,
    isLastPathReport,
    setEflowStatusAsCompleted,
    isSnackBarOpen,
    snackBarMessage,
    openSnackbar,
    closeSnackbar,
  };
};
