import { useNavigate, useParams } from 'react-router-dom';

export const useKpiRedirects = () => {
  const { companyName } = useParams();
  const navigate = useNavigate();

  const dashboardPath = `/${companyName}/kpi/dashboard`;
  const indicatorsPath = `/${companyName}/kpi/indicators`;

  const redirectToDashboard = () => navigate(dashboardPath);

  return {
    dashboardPath,
    indicatorsPath,
    redirectToDashboard,
  };
};
