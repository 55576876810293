import { ReactNode, createContext, useContext } from 'react';

import { useDashboard } from '@kpi/pages/Dashboard/hooks';

import { DashboardTypesEnum } from '@/__generated__/graphql.ts';

type KpiDashboardContextProps = ReturnType<typeof useDashboard>;

const KpiDashboardContext = createContext<KpiDashboardContextProps | undefined>(
  undefined,
);

export const KpiDashboardProvider = ({
  children,
  dashboardType,
}: {
  children: ReactNode;
  dashboardType: DashboardTypesEnum;
}) => {
  const kpiDashboard = useDashboard(dashboardType);

  return (
    <KpiDashboardContext.Provider value={kpiDashboard}>
      {children}
    </KpiDashboardContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useKpiDashboardContext = (): KpiDashboardContextProps => {
  const context = useContext(KpiDashboardContext);
  if (context === undefined) {
    throw new Error(
      'useKpiDashboardContext must be used within a KpiDashboardProvider',
    );
  }
  return context;
};
