import React, { useCallback } from 'react';

import {
  ActivateSettlementPeriodModal,
  CreateSettlementPeriodModal,
  RemoveSettlementPeriodModal,
} from '@kaizen/module-settings/SettlementPeriods/Modals';
import { UpdateSettlementPeriodModal } from '@kaizen/module-settings/SettlementPeriods/Modals/UpdateSettlementPeriod.modal.tsx';
import {
  LoadingView,
  MainSettlementPeriodView,
  NoSettlementPeriods,
} from '@kaizen/module-settings/SettlementPeriods/Views';
import { useKaizenSettlementPeriodContext } from '@kaizen/module-settings/SettlementPeriods/contexts/KaizenSettlementPeriod.context.tsx';
import { useSettlementPeriod } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod.tsx';

import { AlertSnackbar } from '@core/components';

export const SettlementPeriods: React.FC = () => {
  const { isLoading, settlementPeriods } = useSettlementPeriod();

  const {
    severity,
    isOpen: isSnackbarOpen,
    close: closeSnackbar,
    message: snackbarMessage,
  } = useKaizenSettlementPeriodContext();

  const RenderProperView = useCallback(() => {
    if (isLoading) return <LoadingView />;

    if (settlementPeriods.length === 0) {
      return <NoSettlementPeriods />;
    }

    return <MainSettlementPeriodView settlementPeriods={settlementPeriods} />;
  }, [isLoading, settlementPeriods]);

  return (
    <>
      {RenderProperView()}
      <UpdateSettlementPeriodModal />
      <CreateSettlementPeriodModal />
      <ActivateSettlementPeriodModal />
      <RemoveSettlementPeriodModal />
      <AlertSnackbar
        severity={severity}
        isOpen={isSnackbarOpen}
        onClose={closeSnackbar}
        text={snackbarMessage}
      />
    </>
  );
};
