import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, TableCell, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { KpiRatingOrderBy, OrderBy } from '@/__generated__/graphql';

import { EnhancedTableProps, HeadCell } from '.';
import { useKpiDashboardFiltersContext } from '../context';
import { KpiTableCell } from './components/KpiTableCell';
import { filterMonthsByPeriod } from './functions/filter-months';

export const KpiTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;

  const { yearPeriodValue } = useKpiDashboardFiltersContext();

  const { t } = useTranslation('kpi');
  const { t: commontT } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: KpiRatingOrderBy.Name,
      numeric: false,
      disablePadding: false,
      label: t('table.nameCell'),
    },
    {
      id: KpiRatingOrderBy.OrganizationUnit,
      numeric: false,
      disablePadding: false,
      label: t('table.orgUnitCell'),
    },
    {
      id: KpiRatingOrderBy.Employee,
      numeric: false,
      disablePadding: false,
      label: t('table.assigneeCell'),
    },
  ];
  const headCellsMonths: HeadCell[] = [
    {
      id: '1',
      numeric: true,
      disablePadding: false,
      label: commontT('months.1'),
    },
    {
      id: '2',
      numeric: true,
      disablePadding: false,
      label: commontT('months.2'),
    },
    {
      id: '3',
      numeric: true,
      disablePadding: false,
      label: commontT('months.3'),
    },
    {
      id: '4',
      numeric: true,
      disablePadding: false,
      label: commontT('months.4'),
    },
    {
      id: '5',
      numeric: true,
      disablePadding: false,
      label: commontT('months.5'),
    },
    {
      id: '6',
      numeric: true,
      disablePadding: false,
      label: commontT('months.6'),
    },
    {
      id: '7',
      numeric: true,
      disablePadding: false,
      label: commontT('months.7'),
    },
    {
      id: '8',
      numeric: true,
      disablePadding: false,
      label: commontT('months.8'),
    },
    {
      id: '9',
      numeric: true,
      disablePadding: false,
      label: commontT('months.9'),
    },
    {
      id: '10',
      numeric: true,
      disablePadding: false,
      label: commontT('months.10'),
    },
    {
      id: '11',
      numeric: true,
      disablePadding: false,
      label: commontT('months.11'),
    },
    {
      id: '12',
      numeric: true,
      disablePadding: false,
      label: commontT('months.12'),
    },
  ];

  const createSortHandler =
    (property: KpiRatingOrderBy) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableRow>
      {headCells.map((headCell) => (
        <KpiTableCell
          variant={'light'}
          key={headCell.id}
          textAlignment={'center'}
        >
          <TableSortLabel
            sx={{ backgroundColor: 'white' }}
            active={(orderBy as string) === headCell.id}
            direction={
              (orderBy === (headCell.id as KpiRatingOrderBy)
                ? order
                : OrderBy.Desc) === OrderBy.Asc
                ? 'asc'
                : 'desc'
            }
            onClick={createSortHandler(headCell.id as KpiRatingOrderBy)}
          >
            {headCell.label}
          </TableSortLabel>
        </KpiTableCell>
      ))}
      {(
        filterMonthsByPeriod(yearPeriodValue, headCellsMonths) as HeadCell[]
      ).map((headCell, idx) => (
        <KpiTableCell
          variant={(idx + 1) % 2 !== 1 ? 'light' : 'dark'}
          key={headCell.id}
          textAlignment={'center'}
        >
          {headCell.label}
          {(orderBy as string) === headCell.id ? (
            <Box component="span" sx={visuallyHidden}>
              {order === OrderBy.Desc
                ? 'sorted descending'
                : 'sorted ascending'}
            </Box>
          ) : null}
        </KpiTableCell>
      ))}
      <TableCell id="more" />
    </TableRow>
  );
};
