import { useEffect, useState } from 'react';

import { IDBPDatabase } from 'idb';
import { v4 } from 'uuid';

import { IdbHandler } from '@core/hooks/useIndexDB/Handlers/IDBHandler.ts';
import { useIndexDBType } from '@core/hooks/useIndexDB/useIndexDB.types.ts';

/**
 *
 * @description hook used to interact with IndexDB
 * @param objectStoreNameInput {string} - the name of the object store
 * @param databaseNameInput {string} - the name of the database
 * @param indexNameInput {keyof T} - the name of the index
 * @author bryndalski
 * @version 1.0
 *
 *
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const useIndexDB = <T>(
  objectStoreNameInput?: string,
  databaseNameInput: string = 'ecpsDB',
  indexNameInput?: keyof T,
): useIndexDBType<T> => {
  const [objectStoreName, setObjectStoreName] = useState<string | undefined>(
    objectStoreNameInput,
  );
  const [databaseName, setDatabaseName] = useState<string>(databaseNameInput);
  const [indexName, setIndexName] = useState<keyof T | undefined>(
    indexNameInput,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSupported, setIsSupported] = useState<boolean>(
    'indexedDB' in window,
  );
  const [isReady, setSetIsReady] = useState<boolean>(false);
  const [database, setDatabase] = useState<IDBPDatabase | null>(null);

  const [data, setData] = useState<{ [key: string]: T }>({});

  /*
   * Open the IndexDB
   * @private
   * @throws {Error} - if the indexDB is not ready
   * @throws {Error} - if the objectStoreName is not provided
   * @throws {Error} - if the databaseName is not provided
   */
  const openDatabase = async () => {
    if (!objectStoreName) return;

    setIsLoading(true);

    const database = await IdbHandler.createConnection<T>(
      objectStoreName,
      databaseName,
      indexName,
    );

    setDatabase(database);
    await hydrate(database, indexName);
    setSetIsReady(true);
    setIsLoading(false);
  };

  /**
   * Initialize the IndexDB
   * @param objectStoreName
   * @param databaseName
   * @param index - optional index to be created
   * @public
   */
  const init: useIndexDBType<T>['init'] = async (
    objectStoreName: string,
    databaseName?: string,
    index?: keyof T,
  ) => {
    setSetIsReady(false);
    setObjectStoreName(objectStoreName);

    if (index) setIndexName(index);
    if (databaseName) setDatabaseName(databaseName);

    await openDatabase();
  };

  /**
   * Hydrate the data from the IndexDB
   * @param database {IDBPDatabase} - the database to be used
   * @param index {string} - optional index to be used
   * @private - this function is used internally
   * @throws {Error} - if the objectStoreName is not found
   * @throws {Error} - if the index is not found
   * @throws {Error} - if the database is not ready
   *
   */
  const hydrate = async (database: IDBPDatabase, index?: keyof T) => {
    setIsLoading(true);

    if (!database.objectStoreNames.contains(objectStoreName!)) {
      throw new Error(`Object store ${objectStoreName} not found`);
    }

    const tx = database.transaction(objectStoreName!, 'readonly');
    const store = tx.objectStore(objectStoreName!);

    const [all, keys] = index
      ? await Promise.all([
          database.getAllFromIndex(objectStoreName!, index as string),
          database.getAllKeysFromIndex(objectStoreName!, index as string),
        ])
      : await Promise.all([store?.getAll(), store?.getAllKeys()]);

    const result: { [key: string]: T } = {};

    keys.forEach((key, index) => {
      result[key as string] = all[index];
    });

    setData(result);
    setIsLoading(false);
  };

  /**
   * Defines if the IndexDB is supported
   */
  useEffect(() => {
    setIsSupported('indexedDB' in window);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  /**
   * If the IndexDB is supported, and the objectStoreName is provided, open the database
   */
  useEffect(() => {
    if ('indexedDB' in window && objectStoreName) {
      void openDatabase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectStoreName, databaseName]);

  const saveElement: useIndexDBType<T>['saveElement'] = async (
    value: T,
    key?: string,
    indexInDatabase?: number,
  ) => {
    canPerformAction();
    setIsLoading(true);
    const id = key || v4();
    const tx = database!.transaction(objectStoreName!, 'readwrite');
    const store = tx.objectStore(objectStoreName!);
    await store?.put(value, id);

    if (indexInDatabase === Object.keys(data).length || !indexInDatabase) {
      setData((prev) => ({ ...prev, [id]: value }));
    } else {
      await reHydrate();
    }

    setIsLoading(false);
    return id;
  };

  const updateElement: useIndexDBType<T>['updateElement'] = async (
    key: string,
    value: T,
  ) => {
    canPerformAction();
    setIsLoading(true);
    const tx = database?.transaction(objectStoreName!, 'readwrite');
    const store = tx?.objectStore(objectStoreName!);
    const prev = await store!.get(key);
    await store?.put({ ...prev, ...value }, key);
    setData((prev) => ({
      ...prev,
      [key]: { ...prev[key], ...value },
    }));

    setIsLoading(false);
  };

  const removeElement: useIndexDBType<T>['removeElement'] = async (
    key: string,
  ) => {
    canPerformAction();
    setIsLoading(true);
    const tx = database?.transaction(objectStoreName!, 'readwrite');
    const store = tx?.objectStore(objectStoreName!);
    await store?.delete(key);
    setData((prev) => {
      delete prev[key];
      return prev;
    });
    setIsLoading(false);
  };

  const getElement: useIndexDBType<T>['getElement'] = (key: string) => {
    return data[key];
  };

  const reHydrate: useIndexDBType<T>['reHydrate'] = async () => {
    canPerformAction();
    const tx = database?.transaction(objectStoreName!, 'readwrite');
    const store = tx?.objectStore(objectStoreName!);

    const [all, keys] = indexName
      ? await Promise.all([
          database!.getAllFromIndex(objectStoreName!, indexName as string),
          database!.getAllKeysFromIndex(objectStoreName!, indexName as string),
        ])
      : await Promise.all([store?.getAll(), store?.getAllKeys()]);

    const result: { [key: string]: T } = {};

    keys!.forEach((key, index) => {
      result[key as string] = all![index];
    });

    setData(result);
    setIsLoading(false);
  };

  const reIndexRecords: useIndexDBType<T>['reIndexRecords'] = async (
    recordNumber: number,
    indexFieldName: keyof T,
    increaseBy: number = 0,
  ) => {
    canPerformAction();
    if (!indexName)
      throw new Error(
        `No index provided for: objectStore: ${objectStoreName} - database: ${databaseName} ${indexName as string}`,
      );

    const recordsToReindex = await database!.getAllKeysFromIndex(
      objectStoreName!,
      indexName as string,
    );
    const records = recordsToReindex.slice(recordNumber);
    await Promise.all(
      records.map(async (recordKey, currentEndloopIndex: number) => {
        //each transaction must be unique, so we need to create a new transaction for each record
        const tx = database?.transaction(objectStoreName!, 'readwrite');
        const store = tx?.objectStore(objectStoreName!);
        const prev = await store!.get(recordKey);

        await store?.put(
          {
            ...prev,
            [indexFieldName]: recordNumber + currentEndloopIndex + increaseBy,
          },
          recordKey,
        );
      }),
    );
    await reHydrate();
  };

  const purge: useIndexDBType<T>['purge'] = async () => {
    canPerformAction();
    setIsLoading(true);

    const tx = database?.transaction(objectStoreName!, 'readwrite');
    const store = tx?.objectStore(objectStoreName!);
    await store?.clear();
    setData({});
    setIsLoading(false);
  };

  /**
   * Check if the indexDB can perform an action
   * @private
   * @throws {Error} - if the indexDB is not ready
   */
  const canPerformAction = () => {
    if (!isReady || !database) {
      throw new Error(
        `IndexDB is not ready ${databaseName} ${objectStoreName}`,
      );
    }
  };

  return {
    isReady,
    isSupported,
    isLoading,
    data,
    init,
    saveElement,
    updateElement,
    removeElement,
    getElement,
    purge,
    reHydrate,
    reIndexRecords,
  };
};
