import { useCallback } from 'react';

import { useResourceOwner } from '@core/hooks';

import { useUpdateCommentMutation } from '@/__generated__/graphql.ts';

import { useUpdateCommentCategory, useUpdateCommentContent } from './hooks';

export const useUpdateComment = (
  commentId: string,
  organizationId: string,
  commentAuthorId: string,
) => {
  const { isOwner } = useResourceOwner(commentAuthorId);
  const [updateMutation, { loading: isCommentUpdateLoading }] =
    useUpdateCommentMutation();

  const { isLoading: isUpdateCategoryLoading, updateCategory } =
    useUpdateCommentCategory(commentId, organizationId);
  const { isLoading: isUpdateContentLoading, updateContent } =
    useUpdateCommentContent(commentId, organizationId);

  const updateComment = async (commentCategory: string, comment: string) => {
    await updateMutation({
      variables: {
        input: {
          id: commentId,
          organizationId: organizationId,
          commentCategory,
          content: comment,
        },
      },
    });
  };

  const _isCommentLoading = useCallback(() => {
    return (
      isUpdateCategoryLoading ||
      isUpdateContentLoading ||
      isCommentUpdateLoading
    );
  }, [isUpdateCategoryLoading, isUpdateContentLoading, isCommentUpdateLoading]);

  return {
    isLoading: _isCommentLoading,
    isOwner,
    updateCategory,
    updateContent,
    updateComment,
  };
};
