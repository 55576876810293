import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlowStatusTypes } from '@e-flow/components/FlowStatusFilter/FlowStatus.types.ts';

import { MenuItem, OutlinedInput, Select } from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';

import { EFlowStatuses } from '@/__generated__/graphql.ts';
import { AllStatuses } from '@/types';

// TODO: @bryndalski, make switch generic
export const FlowStatusFilter = forwardRef((props: SelectProps, ref) => {
  const { t } = useTranslation('eflow');
  const [value, setVal] = useState<EFlowStatuses | AllStatuses>(
    AllStatuses.All,
  );

  const statuses: FlowStatusTypes[] = [
    {
      value: AllStatuses.All,
      label: t('statuses.ALL'),
    },
    {
      value: EFlowStatuses.New,
      label: t('statuses.NEW'),
    },
    {
      value: EFlowStatuses.InProgress,
      label: t('statuses.IN_PROGRESS'),
    },
    {
      value: EFlowStatuses.Completed,
      label: t('statuses.COMPLETED'),
    },
  ];

  return (
    <Select
      ref={ref}
      sx={{ height: 40 }}
      fullWidth={true}
      defaultValue={props.defaultValue}
      {...props}
      value={value}
      onChange={(event, node) => {
        setVal(event.target.value as EFlowStatuses);
        if (props.onChange) props.onChange(event, node);
      }}
      input={<OutlinedInput label={props.label} />}
    >
      {statuses.map((status) => (
        <MenuItem key={status.value} value={status.value}>
          {status.label}
        </MenuItem>
      ))}
    </Select>
  );
});
