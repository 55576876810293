import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Box, Typography, styled } from '@mui/material';

import { theme } from '@/theme';
import { palette } from '@/theme/pallete';

import { NoDataInfoProps } from './NoDataInfo.types';

export const NoDataInfo = ({
  headerText,
  infoText,
  buttonText,
  onClick,
  withButton = true,
}: NoDataInfoProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <CustomStyledHeaderTitle>{headerText}</CustomStyledHeaderTitle>
      <CustomStyledHeaderSubtitle>
        {withButton ? infoText : t('organization.noAssignedOrganizations')}
      </CustomStyledHeaderSubtitle>
      {withButton && (
        <PrimaryButton
          variant="contained"
          style={{
            backgroundColor: theme.palette.primary[600],
          }}
          onClick={onClick}
        >
          <Typography
            fontFamily="Inter"
            fontWeight={500}
            fontSize={'14px'}
            lineHeight={'20px'}
          >
            {buttonText}
          </Typography>
        </PrimaryButton>
      )}
    </Container>
  );
};

const CustomStyledHeaderTitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '24px',
  lineHeight: '24px',
  fontWeight: 500,
  color: palette.text.primary,
  marginLeft: '16px',
  marginRight: '16px',
});

const CustomStyledHeaderSubtitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  textAlign: 'center',
  color: palette.text.secondary,
});

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
  width: '100%',
});
