import { useTranslation } from 'react-i18next';

import { TableRow } from '@mui/material';

import { KaizenStatuses } from '@/__generated__/graphql';
import { useKaizenSettlementPeriodContext } from '@/pages/kaizen/contexts/useKaizenSettlementPeriod.context';
import { transformDateStringToDDMMYYYY } from '@/utils';

import { SubmissionRowProps } from './SubmissionRow.types';
import { AvatarCell, MenuCell, StatusCell, TextCell, TitleCell } from './cells';

export const SubmissionRow = (props: SubmissionRowProps) => {
  const settlementsPeriod = useKaizenSettlementPeriodContext();
  const { t } = useTranslation('kaizen');

  const { row } = props;

  return (
    <TableRow>
      <TextCell text={row.submissionKey} />
      <TitleCell text={row.name} submissionId={row.id} />
      <AvatarCell members={[row.createdBy, ...(row.authors || [])]} />
      <TextCell text={transformDateStringToDDMMYYYY(row.createdAt)} />
      <TextCell text={transformDateStringToDDMMYYYY(row.updatedAt)} />
      <StatusCell status={row.status} />
      <AvatarCell
        members={row.assignedContractor ? [row.assignedContractor] : null}
      />
      <TextCell
        text={settlementsPeriod[row.settlementPeriodId] ?? t('archive')}
      />
      <MenuCell
        submissionId={row.id}
        creatorId={row.createdBy.id}
        handleRemove={props.handleRemove}
        isModficationsEnabled={[
          KaizenStatuses.Draft,
          KaizenStatuses.Adjustment,
        ].includes(row.status)}
      />
    </TableRow>
  );
};
