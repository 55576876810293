import { usePeriodSelect } from '@kpi/components/SettlementPeriodSelect/hooks/usePeriodSelect.tsx';
import { useSettlementPeriod } from '@kpi/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod';

import { MenuItem, Select, Typography } from '@mui/material';

import { PeriodProps } from '../KpiRatesTable/KpiFilters/types';

export const PeriodSelect = ({ width, value, onChange }: PeriodProps) => {
  const { settlementPeriods } = useSettlementPeriod();
  const { selectedValue, handleChange } = usePeriodSelect({
    value,
    onChange,
    width,
  });

  return (
    <Select
      size="small"
      sx={{ width: width }}
      onChange={handleChange}
      value={selectedValue}
      displayEmpty
    >
      {settlementPeriods.map((period) => (
        <MenuItem key={period.id} value={period.id}>
          <Typography variant="bodyRegular">{period.periodName}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
