import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ReportSections } from '@e-flow/pages/flowReport/components';
import { BalanceChartTypes } from '@e-flow/pages/flowReport/components/BalanceChart/BalanceChart.types.ts';
import { UnitSelect } from '@e-flow/pages/flowReport/components/BalanceChart/components/UnitSelect/UnitSelect.tsx';
import { useBalanceChart } from '@e-flow/pages/flowReport/components/BalanceChart/hooks/useBalanceChart.tsx';
import { chunk } from 'lodash';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, styled } from '@mui/material';

import { Button, ChartWithBalance, NumberInput } from '@core/components';

import {
  OperationTypesEnum,
  ParameterUnitsTypes,
  useFindOneEflowLazyQuery,
  useGetAsIsToBeEflowParamsLazyQuery,
} from '@/__generated__/graphql.ts';
import { OperationsSelect, ParameterSelect } from '@/pages/e-flow/components';
import { useOrganizationStore } from '@/stores';
import { theme } from '@/theme';

import {
  calculateDataSeries,
  convertUnitType,
  filterOperationTypes,
  recalculateUnits,
} from './logic';

export const BalanceChart = (props: BalanceChartTypes) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  const [maximumValue, setMaximumValue] = useState<number>(0);
  const [minimumValue, setMinimumValue] = useState<number>(0);

  const [getParams, { loading: isLoading, data }] =
    useGetAsIsToBeEflowParamsLazyQuery();

  const [getReportData, { data: eFlowData }] = useFindOneEflowLazyQuery();

  const { organization } = useOrganizationStore();
  const { companyName } = useParams();
  //hooks
  const {
    operation,
    parameter,
    unit,
    balanceLineValue,
    debouncedBalanceLineValue,
    operationNumber,
    debouncedOperationNumber,
    setOperation,
    setBalanceLineValue,
    setParameter,
    setUnit,
    setOperationNumber,
    resetFilters,
  } = useBalanceChart();

  //Initial data fetch
  useEffect(() => {
    if (!props.eFlowId) return;

    void getParams({
      variables: {
        input: {
          id: props.eFlowId,
          organizationId: organization[companyName!],
        },
      },
    });

    void getReportData({
      variables: {
        input: {
          id: props.eFlowId,
          organizationId: organization[companyName!],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eFlowId, organization]);

  useEffect(() => {
    if (data && eFlowData) {
      setParameter(
        eFlowData.findOneEFlow.parameters?.[0]?.name as string,
        eFlowData.findOneEFlow.parameters?.[0]?.unit as ParameterUnitsTypes,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.findOneEFlow, eFlowData?.findOneEFlow]);

  const dataSeries = useMemo(() => {
    if (!data || !unit) return [];

    const eFlowAsIsToBeParams = operation
      ? filterOperationTypes({
          params: data,
          operationType: operation as OperationTypesEnum | 'ALL',
        })
      : data;

    const desiredUnit = convertUnitType(unit);

    const incomingUnit = convertUnitType(
      eFlowData?.findOneEFlow.parameters?.find(
        (param) => param.name === parameter,
      )?.unit as ParameterUnitsTypes,
    );

    const recalculatedEflowSteps =
      !desiredUnit || !incomingUnit
        ? eFlowAsIsToBeParams
        : recalculateUnits({
            desiredUnit,
            params: eFlowAsIsToBeParams,
            parameterName: parameter as string,
            incomingUnit,
          });

    const {
      dataset: rawDataSeries,
      minimumValue,
      maximumValue,
    } = calculateDataSeries(recalculatedEflowSteps, parameter as string);

    setMaximumValue(maximumValue);
    setMinimumValue(minimumValue);

    if (!debouncedOperationNumber || debouncedOperationNumber < 0)
      return [rawDataSeries];
    return chunk(rawDataSeries, debouncedOperationNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, parameter, debouncedOperationNumber, unit, operation]);

  if (!eFlowData || !parameter || !unit) return null;

  return (
    <ReportSections
      title={t('reports.balanceChart.title')}
      isLoading={isLoading}
    >
      <ControlsContainer>
        <OperationsSelect
          items={eFlowData?.findOneEFlow.operations || []}
          value={operation as OperationTypesEnum}
          onChange={(operation) => setOperation(operation)}
        />

        <ParameterSelect
          items={eFlowData?.findOneEFlow.parameters || []}
          value={parameter}
          onChange={(paramName) =>
            setParameter(
              paramName,
              eFlowData?.findOneEFlow.parameters?.find(
                (param) => param.name === paramName,
              )?.unit as ParameterUnitsTypes,
            )
          }
        />

        <UnitSelect
          selectedParameterUnit={unit}
          onChange={(newUnit) =>
            setUnit(newUnit as unknown as ParameterUnitsTypes)
          }
        />
        {/*define balance line*/}
        <NumberInput
          label={t('reports.balanceChart.clientRate')}
          minVal={0}
          value={balanceLineValue}
          maxVal={100}
          onChange={(balanceLine: number | string) =>
            setBalanceLineValue(balanceLine as number)
          }
          sx={{ width: 150 }}
          endAdornment={unit && t(`paramsShort.${unit}`)}
        />
        {/*find operation number*/}
        <NumberInput
          label={t('reports.balanceChart.operationNumber')}
          minVal={0}
          value={operationNumber}
          maxVal={100}
          onChange={(operationNumber: number | string) =>
            setOperationNumber(operationNumber as number)
          }
          sx={{ width: 150 }}
        />

        <Button
          variant="text"
          textColor={theme.palette.grey[700]}
          onClick={() => resetFilters()}
        >
          <CloseIcon />
          <Typography variant="bodyMedium">
            {commonT('organizationSettings.clearFilters')}
          </Typography>
        </Button>
      </ControlsContainer>

      {dataSeries.map((data, index) => (
        <ChartWithBalance
          key={index}
          data={data}
          balanceLine={debouncedBalanceLineValue as number}
          yRange={[minimumValue, maximumValue]}
          balanceLineLabel={t('reports.balanceChart.clientRate')}
          yLabel={t(`paramsShort.${unit}`)}
        />
      ))}
    </ReportSections>
  );
};

const ControlsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 10,
});
