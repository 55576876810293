import { z } from 'zod';
import {
  NewPasswordValidationSchemaErrors,
  passwordValidationSchema,
} from '@/utils';


type ResetPasswordSchemaErrors = {
  /**
   * Error message for required code
   * @example
   * Code is required
   */
  codeRequired: string;
  /**
   * Error message for invalid code
   * @example
   * Code is invalid
   */
  codeInvalid: string;
} & NewPasswordValidationSchemaErrors;

/**
 * Reset password validation schema
 * @returns zod schema for reset password
 * @params error messages
 * handled values:
 *  - passwordRequired: Password is required.
 *  - code: Must be 6 digits numeric string
 *  - password: Must match password policy requirements
 *
 */
export const ResetPasswordSchema = (params: ResetPasswordSchemaErrors) =>
  z
    .object({
      password: passwordValidationSchema(
        params.passwordRequired,
        params.passwordInvalid,
      ),
      repeatPassword: z
        .string({
          required_error: params.passwordRequired,
        })
        .min(1, params.passwordRequired),
      code: z
        .string({
          required_error: params.codeRequired,
        })
        .min(6, params.codeRequired)
        .refine(
          (value) => /[0-9]{6}/.test(value.toString()),
          params.codeInvalid,
        ),
    })
    .superRefine(({ repeatPassword, password }, ctx) => {
      if (repeatPassword !== password) {
        ctx.addIssue({
          code: 'custom',
          message: params.passwordDonMatch,
          path: ['repeatPassword'],
        });
      }
    });