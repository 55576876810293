import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OutlinedInput, Select } from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';

import { EmployeeState } from '@/__generated__/graphql.ts';
import { EclipseMenuItem } from '@/core/components/EclipseMenuItem/EclipseMenuItem.tsx';
import { Statuses } from '@/core/components/StatusSwitch/StatusSwitch.types.ts';
import { theme } from '@/theme';

export const StatusSwitch = forwardRef((props: SelectProps, ref) => {
  const { t } = useTranslation();
  const [value, setVal] = useState<EmployeeState>(EmployeeState.Active);

  const statuses: Statuses[] = [
    {
      value: EmployeeState.Active,
      label: t('employees.active'),
      badgeColor: theme.palette.success[500],
    },
    {
      value: EmployeeState.Inactive,
      label: t('employees.inActive'),
      badgeColor: theme.palette.error[500],
    },
    {
      value: EmployeeState.Fired,
      label: t('employees.fired'),
      badgeColor: theme.palette.grey[500],
    },
  ];

  return (
    <Select
      ref={ref}
      sx={{ height: 40 }}
      fullWidth={true}
      defaultValue={props.defaultValue}
      {...props}
      value={value}
      onChange={(event, node) => {
        setVal(event.target.value as EmployeeState);
        if (props.onChange) props.onChange(event, node);
      }}
      input={<OutlinedInput label={props.label} />}
    >
      {statuses.map((status) => (
        <EclipseMenuItem
          key={status.value}
          value={status.value}
          badgeColor={status.badgeColor}
        >
          {status.label}
        </EclipseMenuItem>
      ))}
    </Select>
  );
});
