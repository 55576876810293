import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Close } from '@mui/icons-material';
import { Box, IconButton, Snackbar, Typography } from '@mui/material';

import { PasswordSectionTypes } from '@/core/components/UserEdit/PasswordSection/PasswordSection.types.ts';
import { UserEditTypes } from '@/core/types';

import {
  EditButton,
  Row,
  SectionTitleRow,
  SettingsSectionContainer,
  TypographyLabel,
} from '../style';
import { ChangePasswordForm } from './ChangePasswordForm/ChangePasswordForm.tsx';

export const PasswordsSection = (props: PasswordSectionTypes) => {
  const { t } = useTranslation();

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  if (!props.isVisible) return null;

  return (
    <SettingsSectionContainer>
      {props.edit === UserEditTypes.Password ? (
        <ChangePasswordForm
          setIsSnackBarOpen={setIsSnackBarOpen}
          setSnackBarMessage={setSnackBarMessage}
          setEdit={props.setEdit}
        />
      ) : (
        <SectionTitleRow>
          <Row>
            <TypographyLabel variant="bodyRegular" fontSize={14}>
              {t('common.password')}
            </TypographyLabel>
            <Typography variant="bodyRegular" fontSize={14}>
              {'*'.repeat(8)}
            </Typography>
          </Row>
          <EditButton
            disabled={props.edit !== UserEditTypes.None}
            type="button"
            onClick={() => props.setEdit(UserEditTypes.Password)}
          >
            <Typography fontSize={14} variant="bodyMedium">
              {t('common.changePassword')}
            </Typography>
          </EditButton>
        </SectionTitleRow>
      )}
      <Box />
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setIsSnackBarOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </SettingsSectionContainer>
  );
};
