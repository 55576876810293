import { useTranslation } from 'react-i18next';

import {
  CommonTableRow,
  TitleTypography,
  ValueTypography,
} from '@kaizen/views/SummaryView/components/Rows/CommonTableRow.tsx';

export const SubmissionCreatedAt = ({ createdAt }: { createdAt: Date }) => {
  const { t } = useTranslation('kaizen');

  return (
    <CommonTableRow>
      <TitleTypography variant="bodyRegular">
        {t('submissionModificationView.summary.table.createdAt')}
      </TitleTypography>
      <ValueTypography variant="bodyRegular">
        {createdAt.toLocaleDateString()}
      </ValueTypography>
    </CommonTableRow>
  );
};
