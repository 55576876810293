/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettlementPeriod } from '@kpi/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod';
import { KpiReportDisplayType } from '@kpi/types';
import dayjs, { Dayjs } from 'dayjs';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import {
  useFindAllKpisLazyQuery,
  useGetEmployeeQuery,
  useReportKpiRateEntryMutation,
} from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

import { useManageCacheKpiReport } from './useManageCacheKpiReport/useManageCacheKpiReport';

export const useReportModal = (closeModal: () => void) => {
  const { t } = useTranslation('kpi');
  const { companyId } = useCompanyId();

  const { userUuid, organizationId } = useUserStore();
  const { currentActivePeriod } = useSettlementPeriod();
  const { openSnackbar } = useSnackbarContext();

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [displayedKpiData, setDisplayedKpiData] = useState<
    KpiReportDisplayType[]
  >([]);
  const [allKpiData, setAllKpiData] = useState<KpiReportDisplayType[]>([]);
  const [userOrganizationId, setUserOrganizationId] = useState<
    string | undefined
  >();

  const { updateEntriesCache } = useManageCacheKpiReport();

  const { data: employee } = useGetEmployeeQuery({
    variables: {
      input: {
        id: userUuid,
        organizationId: organizationId!,
      },
    },
  });

  const [query, { data, loading: isLoading }] = useFindAllKpisLazyQuery();

  const [updateEntry] = useReportKpiRateEntryMutation();

  useEffect(() => {
    const newDate = dayjs(new Date()).add(-1, 'month').format('MMMM.YYYY');
    setDate(dayjs(newDate));
  }, []);

  useEffect(() => {
    if (employee)
      setUserOrganizationId(employee.getEmployee.organizationUnitGuid);
  }, [employee]);

  useEffect(() => {
    if (companyId && currentActivePeriod) {
      void query({
        variables: {
          input: {
            organizationId: companyId,
            limit: 20,
            organizationUnitIds: [userOrganizationId!],
            employeeIds: [userUuid],
            settlementPeriodIds: [
              currentActivePeriod ? currentActivePeriod.id : '',
            ],
          },
        },
      });
    }
  }, [currentActivePeriod, userOrganizationId, companyId]);

  useEffect(() => {
    if (data) {
      const newKpiData = data.findAllKpis.items.map((item) => {
        const entry = item.entries[date!.toDate().getMonth() + 1];
        return {
          id: item.id,
          rateName: item.rateName,
          entry: entry ? entry.entry + '' : '0',
          shortcut: item.unit.unitShortcut,
        };
      });

      setDisplayedKpiData(newKpiData);
      setAllKpiData(newKpiData);
    }
  }, [data, date]);

  useEffect(() => {
    setDisplayedKpiData(
      isSwitchOn
        ? [...displayedKpiData.filter((item) => item.entry === '0')]
        : allKpiData,
    );
  }, [isSwitchOn]);

  const onEntryChange = (id: string, entry: string) => {
    const newData = [...displayedKpiData];

    newData.forEach((item) => {
      if (item.id === id) {
        item.entry = entry;
      }
    });

    setDisplayedKpiData(newData);
    setAllKpiData(newData);
  };

  const onSubmit = useCallback(async () => {
    const input = {
      entries: allKpiData.map((item) => {
        return {
          entry: item.entry !== '' ? parseFloat(item.entry) : 0,
          month: date!.toDate().getMonth() + 1,
          rateId: item.id,
        };
      }),
      organizationId: companyId,
      organizationUnitId: userOrganizationId ? userOrganizationId : '',
    };

    try {
      const result = await updateEntry({
        variables: {
          input,
        },
      });
      if (result.data) {
        updateEntriesCache(input);
        openSnackbar(t('report.snackBarSuccessMessage'));
      }
    } catch (_err) {
      openSnackbar(t('report.snackBarFailMessage'));
    }
    closeModal();
  }, [updateEntry, userOrganizationId, companyId, allKpiData]);

  return {
    isSwitchOn,
    setIsSwitchOn: () => setIsSwitchOn(!isSwitchOn),
    date,
    setDate,
    data: displayedKpiData,
    isLoading,
    onEntryChange,
    onSubmit,
    close: closeModal,
  };
};
