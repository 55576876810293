import { useNavigate, useParams } from 'react-router-dom';

export const useGlobalRedirects = () => {
  const navigate = useNavigate();
  const { companyName, flowId } = useParams();
  const modulesDashboardPath = `/${companyName}`;
  const eSchemaPath = `/${companyName}/e-schema`;
  const eFlowPath = `/${companyName}/e-flow`;
  const kaizenPath = `/${companyName}/kaizen/submissions`;
  const aiPath = `https://digitalfactory.luqam.com/luqam/AI`;
  const kpiPath = `/${companyName}/kpi/dashboard`;
  const opexityPath = `https://www.opexity.com//login`;
  const auditPath = `https://www.panel.luqam.com/`;
  const digitalTwinPath = 'http://10.70.10.8/';
  const timesPath =
    'https://id.atlassian.com/login?continue=https%3A%2F%2Fid.atlassian.com%2Fjoin%2Fuser-access%3Fresource%3Dari%253Acloud%253Ajira%253A%253Asite%252F773dadc0-60cb-47d0-bd56-96391fafed44%26continue%3Dhttps%253A%252F%252Fdigitalfactory-times.atlassian.net%252Fjira%252Fapps%252F13c0f93a-1fff-4106-a191-60ec1497fda2%252F578f5b67-6761-4ee2-96f2-093bf6f118ae&application=jira';
  const homePagePath = `/${companyName}`;

  const createNewAnalysisPath = `${eFlowPath}/create-analysis`;

  const returnToModulesDashboard = () => navigate(modulesDashboardPath);
  const openAccountSettings = () => navigate(`/users/me`);
  const openLoggedOut = () => navigate('/logged-out');
  const returnToThisFlow = () => navigate(`${eFlowPath}/${flowId}`);
  const openOrganizationSettings = () =>
    navigate(
      `/${companyName}/organization-settings/data?backPath=%2F${companyName}`,
    );

  return {
    opexityPath,
    auditPath,
    homePagePath,
    kaizenPath,
    aiPath,
    digitalTwinPath,
    timesPath,
    eFlowPath,
    eSchemaPath,
    createNewAnalysisPath,
    kpiPath,
    modulesDashboardPath,
    returnToModulesDashboard,
    openAccountSettings,
    openLoggedOut,
    returnToThisFlow,
    openOrganizationSettings,
  };
};
