import { UnitKpiRateInput } from '@/__generated__/graphql';

import { UnitsEnum } from './defaultUnits.enum';

export const isPercentage = (unit: UnitKpiRateInput | string | null) => {
  if (typeof unit === 'string')
    return unit === '%' || (unit as UnitsEnum) === UnitsEnum.PERCENT;
  if (unit?.unitName && unit.unitName) {
    return (
      unit.unitShortcut === '%' ||
      (unit.unitName as UnitsEnum) === UnitsEnum.PERCENT
    );
  }
};
