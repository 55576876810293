import React, { useCallback } from 'react';

import { AlertSnackbar } from '@core/components';

import {
  ActivateSettlementPeriodModal,
  CreateSettlementPeriodModal,
  UpdateSettlementPeriodModal,
} from './Modals';
import {
  LoadingView,
  MainSettlementPeriodView,
  NoSettlementPeriods,
} from './Views';
import { useKpiSettlementPeriodContext } from './contexts';
import { useSettlementPeriod } from './hooks/useSettlemetPeriod/useSettlementPeriod';

export const SettlementPeriods: React.FC = () => {
  const { isLoading, settlementPeriods } = useSettlementPeriod();

  const {
    severity,
    isOpen: isSnackbarOpen,
    close: closeSnackbar,
    message: snackbarMessage,
  } = useKpiSettlementPeriodContext();

  const RenderProperView = useCallback(() => {
    if (isLoading) return <LoadingView />;

    if (settlementPeriods.length === 0) {
      return <NoSettlementPeriods />;
    }

    return <MainSettlementPeriodView settlementPeriods={settlementPeriods} />;
  }, [isLoading, settlementPeriods]);

  return (
    <>
      {RenderProperView()}
      <UpdateSettlementPeriodModal />
      <CreateSettlementPeriodModal />
      <ActivateSettlementPeriodModal />
      <AlertSnackbar
        severity={severity}
        isOpen={isSnackbarOpen}
        onClose={closeSnackbar}
        text={snackbarMessage}
      />
    </>
  );
};
