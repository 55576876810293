import { RefObject } from 'react';

import { useGetMonthName } from '@core/hooks';

import useResize from '../useResize/useResize';

export const useResponsiveGetMonthName = <T extends HTMLElement>(
  myRef: RefObject<T>,
) => {
  const { getMonthName } = useGetMonthName();
  const { width } = useResize(myRef);

  const responsiveGetMonthName = (month: number) => {
    if (width > 500) {
      return getMonthName(month);
    } else {
      return `${getMonthName(month)}`.slice(0, 3);
    }
  };

  return { responsiveGetMonthName };
};
