import {
  EmployeeFragmentFragment,
  EmployeesListArgs,
  GetEmployeesForOrganizationDocument,
  GetEmployeesForOrganizationQuery,
} from '@/__generated__/graphql';
import { client } from '@/apollo';

export const useUpdateEmployeesTableCache = () => {
  const readQuery = (input: EmployeesListArgs, cursorForNewData: number) => {
    return client.readQuery({
      query: GetEmployeesForOrganizationDocument,
      variables: {
        input: {
          ...input,
          cursor: cursorForNewData,
        },
      },
    }) as GetEmployeesForOrganizationQuery;
  };

  const updateEmployeesListCache = (
    input: EmployeesListArgs,
    employee: EmployeeFragmentFragment,
    total: number,
  ) => {
    const { limit } = input;
    const cursorForNewData = Math.floor(total / limit!) * limit!;
    const query = readQuery(input, cursorForNewData);

    const newEmployeesArray = [...(query.getEmployees.items || []), employee];

    client.writeQuery({
      query: GetEmployeesForOrganizationDocument,
      variables: {
        input: {
          ...input,
          cursor: cursorForNewData,
        },
      },
      data: {
        getEmployees: {
          items: newEmployeesArray,
          total: total + 1,
        },
      },
    });
  };

  return {
    updateEmployeesListCache,
  };
};
