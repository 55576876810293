import { useTranslation } from 'react-i18next';

import { CommentCategoriesSelect } from '@e-flow/components/CommentModule/components/CommentCategoriesSelect.tsx';
import { useCreateEflowComment } from '@e-flow/components/CommentModule/hooks';
import { PrimaryButton } from '@e-schema/styles/Employee.ts';

import { Box, Checkbox, styled } from '@mui/material';

import { Button, CharsCountingInput } from '@core/components';

import {
  EFlowStepCommentDto,
  FindOneEflowQuery,
} from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

type AddNewCommentType = {
  addCommentToReport?: boolean;
  onCancel?: () => void;
  onSubmit: (data: EFlowStepCommentDto) => void;
} & Pick<FindOneEflowQuery['findOneEFlow'], 'commentTypes'>;
export const AddNewComment = (props: AddNewCommentType) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  const {
    isInReport,
    selectedSymbol,
    commentsCategory,
    isContentError,
    isCategoryError,
    contentErrorMessage,
    setSelectedSymbol,
    handleSubmit,
    setIsInReport,
    clearForm,
    registerContent,
  } = useCreateEflowComment(props);

  const submitForm = (data: EFlowStepCommentDto) => {
    props.onSubmit(data);
    clearForm();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <AddCommentContainer onSubmit={handleSubmit(submitForm)}>
      <CharsCountingInput
        maxInputLength={215}
        sx={{ width: '100%', maxHeight: '70px' }}
        placeholder={commonT('common.addComment')}
        {...registerContent()}
        error={isContentError}
        helperText={contentErrorMessage}
      />
      <RowAlignLeft>
        <CommentCategoriesSelect
          isErrors={isCategoryError}
          selectedSymbol={selectedSymbol}
          setSelectedSymbol={setSelectedSymbol}
          commentsCategory={commentsCategory}
        />

        {props.addCommentToReport && (
          <CheckboxContainer onClick={() => setIsInReport(!isInReport)}>
            <StyledCheckbox checked={!!isInReport} />
            {t('newAnalysis.comments.showInRaport')}
          </CheckboxContainer>
        )}
      </RowAlignLeft>
      <RowAlignRight sx={{ marginTop: '10px' }}>
        {props.onCancel && (
          <Button
            onClick={props.onCancel}
            variant="text"
            textColor={theme.palette.text.primary}
          >
            {commonT('common.cancel')}
          </Button>
        )}
        <PrimaryButton variant={'contained'} type={'submit'}>
          {commonT('common.addComment')}
        </PrimaryButton>
      </RowAlignRight>
    </AddCommentContainer>
  );
};

const AddCommentContainer = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const StyledCheckbox = styled(Checkbox)({
  padding: 0,
  marginRight: '10px',
});

const CheckboxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '10px',
});

const RowAlignLeft = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  gap: '5px',
  width: '100%',
  alignItems: 'center',
});

const RowAlignRight = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  gap: '10px',
  width: '100%',
  alignItems: 'center',
});
