import { ChartFragment } from '@/__generated__/graphql.ts';

export const extractShortcut = (props: ChartFragment) => {
  if (props.displayOptions.showDataAsPercent) return `%`;

  if (!props.additionalInfo.unitShortcut) {
    return '';
  }
  return `${props.additionalInfo.unitShortcut}`;
};

export const setUnitShortcuts = (
  props: ChartFragment,
  value: string | number,
) => {
  const shortCut = extractShortcut(props);

  return `${value} ${shortCut}`;
};
