import { useTranslation } from 'react-i18next';

import { Box, styled } from '@mui/material';

import { CustomSnackBar, StepsTabViewWrapper } from '@/core/components';
import { useKaizenRedirects } from '@/core/redirects';

import { useAddSubmission } from './hooks/useAddSubmission';

export const AddSubmission = () => {
  const { t } = useTranslation('kaizen');

  const {
    renderStepChange,
    currentStep,
    steps,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
  } = useAddSubmission();

  const { redirectToSubmissions } = useKaizenRedirects();

  return (
    <>
      <StepsTabViewWrapper
        title={t('submissionModificationView.newSubmission')}
        currentStep={currentStep}
        steps={steps}
        navbarAction={() => redirectToSubmissions()}
      >
        <FormContainer>{renderStepChange()}</FormContainer>
      </StepsTabViewWrapper>
      <CustomSnackBar
        open={isSnackBarOpen}
        close={closeSnackbar}
        message={snackBarMessage}
      />
    </>
  );
};

const FormContainer = styled(Box)({
  width: '100%',
  padding: '24px',
  gap: '24px',
});
