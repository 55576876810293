import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AddEmployeeModal,
  EmployeeStructureTable,
  FilterTreeView,
  Sidebar,
  StructureEmployeesTableRefTypes,
} from '@e-schema/components';

import { Box, Stack, Typography, styled, useTheme } from '@mui/material';

import {
  checkSystemRolesPermission,
  getLastStructureElement,
} from '@core/utils';

import {
  GetEmployeesForStructureQuery,
  useGetOrganizationUnitStructureQuery,
} from '@/__generated__/graphql';
import { SystemRoles } from '@/__generated__/graphql.ts';
import { useOrganizationStore, useUserStore } from '@/stores';
import { TreeTarget } from '@/types';
import { Unpacked } from '@/types/unpacked.ts';

export const Structure = () => {
  const theme = useTheme();

  const [path, setPath] = useState('');
  const [unitId, setUnitId] = useState('');
  const [canAddEmployee, setCanAddEmployee] = useState(false);

  const { companyName } = useParams();
  const { organization } = useOrganizationStore();
  const { userGroups } = useUserStore();

  const { data } = useGetOrganizationUnitStructureQuery({
    variables: {
      getOrganizationUnitInput: {
        id: organization[companyName!],
      },
    },
  });

  const tableRef = useRef<StructureEmployeesTableRefTypes>(null);

  const handleSelectNode = (treeTarget: TreeTarget) => {
    setPath(treeTarget.path);
    setUnitId(treeTarget.targetId);
  };

  const isUserPermittedToAddEmployees = useMemo(() => {
    const userGroupsCanAddEmployee = [
      SystemRoles.Administrator,
      SystemRoles.Root,
      SystemRoles.SystemAdmin,
    ];
    if (companyName !== 'luqam')
      userGroupsCanAddEmployee.push(SystemRoles.KeyUser);
    return checkSystemRolesPermission(userGroups, userGroupsCanAddEmployee);
  }, [companyName, userGroups]);

  const checkIfStructureExists = () => {
    setCanAddEmployee(isUserPermittedToAddEmployees);
    if (!data?.getOrganizationUnitsStructure?.id) setCanAddEmployee(false);
  };

  useEffect(() => {
    if (!data) return;
    setPath(data?.getOrganizationUnitsStructure?.name);
    setUnitId(data?.getOrganizationUnitsStructure?.id);
    checkIfStructureExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    checkIfStructureExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, userGroups]);

  return (
    <Container>
      <Sidebar>
        <FilterTreeView handleSelect={handleSelectNode} />
      </Sidebar>

      <ContentContainer>
        <Box>
          <Typography
            variant="labelMdRegular"
            fontSize={14}
            color={theme.palette.grey[900]}
          >
            {path}
          </Typography>
          <Box style={{ paddingBlock: 10 }}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3">
                {getLastStructureElement(path)}
              </Typography>
              {canAddEmployee ? (
                <AddEmployeeModal
                  organizationUnitCell={{
                    path,
                    targetId: unitId,
                  }}
                  onEmployeeAdded={(
                    employee: Unpacked<
                      GetEmployeesForStructureQuery['getEmployees']['items'][0]
                    >,
                  ) => {
                    if (tableRef.current) tableRef.current.addNewUser(employee);
                  }}
                />
              ) : (
                <div />
              )}
            </Stack>
          </Box>

          <EmployeeStructureTable unitId={unitId} ref={tableRef} />
        </Box>
      </ContentContainer>
    </Container>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: 'calc(100% - 57px)',
  display: 'flex',
});

const ContentContainer = styled(Box)({
  padding: '24px 24px 0 24px',
  overflow: 'auto',
  width: '100%',
});
