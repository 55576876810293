import { ReactNode, createContext, useContext } from 'react';

import { useUserDashboard } from '../hooks/useUserDashboard';

type UserDashboardContextProps = ReturnType<typeof useUserDashboard>;

const UserDashboardContext = createContext<
  UserDashboardContextProps | undefined
>(undefined);

export const UserDashboardProvider = ({
  children,
  openWidgetSidebar,
  closeWidgetSidebar,
  isDrawerOpen,
}: {
  children: ReactNode;
  openWidgetSidebar: () => void;
  closeWidgetSidebar: () => void;
  isDrawerOpen: boolean;
}) => {
  const userDashboard = useUserDashboard({
    openWidgetSidebar,
    closeWidgetSidebar,
    isDrawerOpen,
  });

  return (
    <UserDashboardContext.Provider value={userDashboard}>
      {children}
    </UserDashboardContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useUserDashboardContext = (): UserDashboardContextProps => {
  const context = useContext(UserDashboardContext);
  if (context === undefined) {
    throw new Error(
      'useUserDashboardContext must be used within a UserDashboardProvider',
    );
  }
  return context;
};
