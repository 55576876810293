export class MonthRange {
  static get months() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  static getRange(start: number, end: number) {
    return MonthRange.months.slice(start, end);
  }

  static getRangeToSelectedMonth(end: number) {
    return MonthRange.getRange(0, end);
  }
}
