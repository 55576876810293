import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Popover,
} from '@mui/material';

import { MenuItems } from '@core/components';
import { EmployeeMenuProps } from '@core/components/Menus/EmployeeMenu/EmployeeMenu.types.ts';
import { useEmployeeMenu } from '@core/components/Menus/EmployeeMenu/useEmployeeMenu.tsx';
import { FlexCenter } from '@core/styles';

import { Employee } from '@/__generated__/graphql.ts';
import { useMenu } from '@/core/hooks';

import { EmployeeMenuRow } from './EmployeeMenuRow';

export const EmployeeMenu = ({
  display = true,
  ...props
}: EmployeeMenuProps) => {
  const {
    isLoading,
    fetchedEmployees,
    inputValue,
    setInputValue,
    userAlreadyIncluded,
  } = useEmployeeMenu(props);

  const { anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose } = useMenu();

  const id = isMenuOpen ? 'simple-popover' : undefined;

  if (!display) return null;

  return (
    <>
      <IconButton onClick={handleMenuOpen}>{props.fireElement}</IconButton>
      <Popover
        id={id}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Input
          sx={{
            margin: 2,
            width: '90%',
          }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color={'primary'} />
            </InputAdornment>
          }
        />
        {isLoading ? (
          <MenuItem disabled>
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          </MenuItem>
        ) : (
          <MenuItems
            handleMenuItemClick={(it) =>
              !userAlreadyIncluded(it.id) && props.onChange(it)
            }
            options={fetchedEmployees}
            menuItemBody={(option: Employee) => (
              <EmployeeMenuRow
                option={option}
                disabled={userAlreadyIncluded(option.id)}
              />
            )}
          />
        )}
      </Popover>
    </>
  );
};
