import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { statusColors } from '@kaizen/common';

import { BarSeriesType } from '@mui/x-charts';

import { PieElementProps } from '@core/components/Statistica/ReportPieChart/ReportPieChart.types.ts';

import {
  KaizenStatuses,
  KaizenSubmissionReportDto,
} from '@/__generated__/graphql.ts';

import {
  calculateTotalSubmissionAccepted,
  legendBarChart,
  legendPieChart,
} from './utils';

export const useCalculateReport = (report?: KaizenSubmissionReportDto) => {
  const { t } = useTranslation('kaizen');

  const calculateTotalNumberOfAcceptedSubmissions = useMemo(() => {
    if (!report) return 0;

    const acceptedSubmissionNumber = calculateTotalSubmissionAccepted(
      report.submissionStatusCount,
    );

    if (acceptedSubmissionNumber === 0 || report.totalNumberOfSubmissions === 0)
      return 0;

    return Math.ceil(
      (acceptedSubmissionNumber / report.totalNumberOfSubmissions) * 100,
    );
  }, [report]);

  const calculateTotalNumberOfImplementedSubmissions = useMemo(() => {
    if (!report) return 0;

    const implementedSubmissionNumber =
      report.submissionStatusCount.IMPLEMENTED;
    const totalNumberOfSubmissions = report.totalNumberOfSubmissions;

    if (totalNumberOfSubmissions === 0) return 0;

    return Math.ceil(
      (implementedSubmissionNumber / totalNumberOfSubmissions) * 100,
    );
  }, [report]);

  const formatPieChartData = useMemo(() => {
    if (!report) return [];

    const submissionChartData: PieElementProps[] = [];

    for (const legendItem of legendPieChart) {
      if (
        legendItem in report.submissionStatusCount &&
        report.submissionStatusCount[legendItem] !== 0
      )
        submissionChartData.push({
          value: report.submissionStatusCount[legendItem],
          color: statusColors[legendItem as KaizenStatuses],
          label: t(`statusLabels.${legendItem as KaizenStatuses}`),
        });
    }

    return submissionChartData;
  }, [report, t]);

  const formatBarChartData = useMemo(() => {
    if (!report) return [];

    const barChartSeries: BarSeriesType[] = [];

    for (const legendItem of legendBarChart) {
      if (
        legendItem in report.submissionProcessingTime &&
        report.submissionProcessingTime[legendItem] !== 0
      )
        barChartSeries.push({
          data: [
            parseFloat(report.submissionProcessingTime[legendItem].toFixed(1)),
          ],
          stack: legendItem,
          color: statusColors[legendItem as KaizenStatuses],
          label: t(`statusLabels.${legendItem as KaizenStatuses}`),
          type: 'bar',
        });
    }

    return barChartSeries;
  }, [report, t]);

  return {
    percentageOfAcceptedSubmissions: calculateTotalNumberOfAcceptedSubmissions,
    percentageOfImplementedSubmissions:
      calculateTotalNumberOfImplementedSubmissions,
    formatPieChartData: formatPieChartData,
    formatBarChartData: formatBarChartData,
  };
};
