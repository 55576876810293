import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';

import { TablePaginationWrapper, UserRow } from '@core/components';

import { FlexCenter } from '@/core/styles';

import { NoEmployeesInfo } from './NoEmployeesInfo';
import { UserTableProps } from './UserTable.types';
import { useUserTable } from './useUserTable';

export const UserTable = (props: UserTableProps) => {
  const {
    selectAllRows,
    isDetermineChecked,
    isDetermineIndeterminate,
    determineDisabledRow,
    determineSelected,
    handleCheck,
    getData,
    setSelectedRows,
  } = useUserTable(props);

  if (props.isLoading) {
    return (
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    );
  }
  if (!props.employees.length && props.onNoEmployeesButtonClick)
    return (
      <FlexCenter>
        <NoEmployeesInfo
          infoText={props.noEmployeesInfoText}
          buttonText={props.noEmployeesButtonText}
          onClick={props.onNoEmployeesButtonClick}
        />
      </FlexCenter>
    );

  return (
    <TableContainer>
      <TablePaginationWrapper
        total={props.total}
        rowsPerPage={props.rowsPerPage}
        setRowsPerPage={(newValue) => props.setRowsPerPage(newValue)}
        cursor={props.cursor}
        setCursor={(newValue) => props.setCursor(newValue)}
      >
        <CustomTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  color="primary"
                  indeterminate={isDetermineIndeterminate}
                  checked={isDetermineChecked}
                  onChange={(_e, checked) => {
                    if (checked) {
                      selectAllRows();
                    } else {
                      setSelectedRows([]);
                    }
                  }}
                />
              </TableCell>
              {props.cells.map((cell) => (
                <TableCell key={cell.key}>{cell.label}</TableCell>
              ))}
              {!!props.isMenuVisible && <TableCell key={'menu'} />}
            </TableRow>
          </TableHead>

          <TableContent>
            {props.employees.map((employee) => {
              return (
                <UserRow
                  key={employee.id}
                  IsCheckboxVisible
                  isMenuVisible={!!props.isMenuVisible}
                  disabled={determineDisabledRow(employee)}
                  isSelected={determineSelected(employee)}
                  onCheck={() => handleCheck(employee)}
                  data={getData(employee)}
                  onEmployeMenu={() =>
                    props.onEmployeeModalOpen &&
                    props.onEmployeeModalOpen(employee)
                  }
                />
              );
            })}
          </TableContent>
        </CustomTable>
      </TablePaginationWrapper>
    </TableContainer>
  );
};

const CustomTable = styled(Table)({
  width: '100%',
});

const TableContent = styled(TableBody)({
  overflowY: 'auto',
});
