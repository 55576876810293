import { useTranslation } from 'react-i18next';

import { Box, Switch, Typography, styled } from '@mui/material';

import { theme } from '@/theme';

import { useSettingsSwitches } from './useSettingsSwitches';

export const SettingsSwitches = () => {
  const { t } = useTranslation('dashboard');

  const { options, changeDisplayOptions, isLoading } = useSettingsSwitches();

  return (
    <Switches>
      {Object.entries(options).map(([key, value]) => (
        <SwitchRow key={key}>
          <Switch
            disabled={isLoading}
            checked={value}
            onChange={(e) => {
              void changeDisplayOptions(key, e.target.checked);
            }}
          />
          <Typography variant="bodyRegular">
            {t(`settings.switches.${key}`, key)}
          </Typography>
        </SwitchRow>
      ))}
    </Switches>
  );
};

const Switches = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
});

const SwitchRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(4),
});
