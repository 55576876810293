import { useTranslation } from 'react-i18next';

import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';

import { WorkstationRowProps } from './Workstation.types';

export const WorkstationRow = ({
  row,
  handleEditWorkstation,
  handleRemoveWorkstation,
}: WorkstationRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow key={row}>
      <CustomTableCell width="auto" sx={{ gap: '12px' }}>
        <Typography variant="bodyRegular">{row}</Typography>
      </CustomTableCell>

      <CustomTableCell padding="none" width={20}>
        <Tooltip title={t('common.edit')} placement="top">
          <IconButton aria-label="more" onClick={handleEditWorkstation}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      </CustomTableCell>
      <CustomTableCell padding="none" width={20}>
        <Tooltip title={t('common.delete')} placement="top">
          <IconButton aria-label="delete" onClick={handleRemoveWorkstation}>
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      </CustomTableCell>
    </TableRow>
  );
};

const CustomTableCell = styled(TableCell)({
  align: 'right',
  height: '48px',
});
