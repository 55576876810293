import { useTranslation } from 'react-i18next';

import { Box, styled } from '@mui/material';

import {
  BarChart,
  Button,
  CustomModal,
  LightButton,
  ModalContainer,
} from '@core/components';
import { ReportPieChart } from '@core/components/Statistica/ReportPieChart/ReportPieChart';

import { SupportedWidgetsEnum } from '@/__generated__/graphql';

import { useAddKaizenWidget } from '../hooks/useAddKaizenWidget';
import { KaizenPreviewWidgetType } from './KaizenWidgetPreview.enum';
import { KaizenWidgetPreviewType } from './KaizenWidgetPreview.types';

export const KaizenWidgetPreview = (props: KaizenWidgetPreviewType) => {
  const { t } = useTranslation('kaizen');

  const { t: commonT } = useTranslation();

  const { addKaizenWidget } = useAddKaizenWidget();

  return (
    <CustomModal display={props.isOpen}>
      <ModalContainer
        closeModal={props.closeModal}
        sx={{ minHeight: '516px', paddingX: 4, paddingBottom: 4 }}
        headerText={t('report.addChart')}
      >
        {props.previewType === KaizenPreviewWidgetType.PIE_CHART && (
          <ReportPieChart
            isLoading={false}
            values={props.formatPiechart}
            title={t('report.submissionCount')}
            emptyMessage={t('report.noData')}
            width={'100%'}
          />
        )}
        {props.previewType === KaizenPreviewWidgetType.BAR_CHART && (
          <BarChart
            series={props.formatBarChartData}
            title={t('report.duration')}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 0,
              },
            }}
            grid={{ horizontal: true }}
            bottomAxis={null}
            xAxis={[
              { scaleType: 'band', data: [t('report.submissionStatus')] },
            ]}
          />
        )}
        <ButtonSection>
          <LightButton onClick={props.closeModal}>
            {commonT('common.cancel')}
          </LightButton>
          <Button
            onClick={() =>
              void addKaizenWidget(
                props.previewType === KaizenPreviewWidgetType.BAR_CHART
                  ? SupportedWidgetsEnum.KaizenSubmissionProcessingTimeChart
                  : SupportedWidgetsEnum.KaizenSubmissionNumberChart,
              )
            }
          >
            {commonT('common.add')}
          </Button>
        </ButtonSection>
      </ModalContainer>
    </CustomModal>
  );
};

const ButtonSection = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 10,
});
