import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OperationNumberTypes } from '@e-flow/pages/flowReport/components/OperationNumber/OperationNumber.types.ts';

import { StatisticBox, StatisticRow } from '@core/components';

export const OperationNumber = (props: OperationNumberTypes) => {
  const { t } = useTranslation('eflow');

  const [
    operationsWithAddedValueAsIsPercentage,
    setOperationsWithAddedValueAsIsPercentage,
  ] = useState(0);
  const [
    operationsWithAddedValueToBePercentage,
    setOperationsWithAddedValueToBePercentage,
  ] = useState(0);

  useEffect(() => {
    setOperationsWithAddedValueAsIsPercentage(
      Math.floor(
        (props.operationsWithAddedValue.asIs / props.totalOperations.asIs ||
          0) * 100,
      ),
    );
    setOperationsWithAddedValueToBePercentage(
      Math.floor(
        (props.operationsWithAddedValue.toBe / props.totalOperations.toBe ||
          0) * 100,
      ),
    );
  }, [
    props.operationsWithAddedValue.asIs,
    props.totalOperations.asIs,
    props.operationsWithAddedValue.toBe,
    props.totalOperations.toBe,
  ]);

  return (
    <StatisticBox
      title={t('reports.operationsCount')}
      withCharts={true}
      chartsToRender={[
        {
          title:
            operationsWithAddedValueAsIsPercentage !== 0
              ? `${operationsWithAddedValueAsIsPercentage}%`
              : '-',
          values: [
            props.operationsWithAddedValue.asIs,
            props.totalOperations.asIs,
          ],
          variant: 'danger',
          chartSubtitle: t('reports.addedValue'),
          chartTitle: t('reports.asIs'),
          width: 50,
        },
        {
          title:
            operationsWithAddedValueToBePercentage !== 0
              ? `${operationsWithAddedValueToBePercentage}%`
              : '-',
          values: [
            props.operationsWithAddedValue.toBe,
            props.totalOperations.toBe,
          ],
          variant: 'secondary',
          chartSubtitle: t('reports.addedValue'),
          chartTitle: t('reports.toBe'),
          width: 50,
        },
      ]}
      withTable={true}
      tableHeaderTitles={[null, t('reports.asIs'), t('reports.toBe')]}
      headerAlignment={'right'}
      children={[
        <StatisticRow
          key={1}
          rowLabel={t('reports.operationsCount')}
          rowValues={[props.totalOperations.asIs, props.totalOperations.toBe]}
          isLoading={props.isLoading}
        />,
        <StatisticRow
          key={2}
          rowLabel={t('reports.operationWithAddedValue')}
          rowValues={[
            props.operationsWithAddedValue.asIs,
            props.operationsWithAddedValue.toBe,
          ]}
          isLoading={props.isLoading}
        />,
        <StatisticRow
          key={3}
          rowLabel={t('reports.percentageOfAddedValue')}
          rowValues={[
            operationsWithAddedValueAsIsPercentage !== 0
              ? `${operationsWithAddedValueAsIsPercentage}%`
              : '-',
            operationsWithAddedValueToBePercentage !== 0
              ? `${operationsWithAddedValueToBePercentage}%`
              : '-',
          ]}
          isLoading={props.isLoading}
        />,
      ]}
    />
  );
};
