import { useEffect, useMemo, useState } from 'react';

import { ChartOptionsTypes } from '@kpi/modals/HandleKpiChartModal/hooks/useManageChartOptions/ChartOptions.types.ts';

import { ChartTypes, KpiRateDto, RateSumTypes } from '@/__generated__/graphql';

import { ManageChartOptionAvailability } from './ManageChartOptionAvailability';

export const useManageChartOptions = (
  selectedKpis: KpiRateDto[],
  selectedChartType: ChartTypes | null,
) => {
  const [isAddIndicatorsChecked, setIsAddIndicatorsChecked] = useState(false);

  const [currentOptions, setCurrentOptions] = useState<ChartOptionsTypes>({
    ascendingOrder: false,
    dataAsPercentage: false,
    showTarget: false,
    sumIndicators: undefined,
  });

  const availableOptions = useMemo(() => {
    return ManageChartOptionAvailability.getAvailableOptions(
      selectedKpis,
      selectedChartType,
    );
  }, [selectedKpis, selectedChartType]);

  /**
   * Change values of already selected options if they are not available anymore
   */
  useMemo(() => {
    for (const key in availableOptions) {
      if (!availableOptions[key as unknown as keyof typeof availableOptions]) {
        setCurrentOptions((prev) => ({
          ...prev,
          [key]: false,
        }));
      }
    }
  }, [availableOptions]);

  useEffect(() => {
    setIsAddIndicatorsChecked(!!currentOptions?.sumIndicators);
  }, [currentOptions.sumIndicators]);

  const handleOptionChange = (option: keyof typeof currentOptions) => {
    setCurrentOptions((prev) => ({
      ...currentOptions,
      [option]: !prev[option],
    }));
  };

  const setAddIndicator = (value: RateSumTypes | undefined) => {
    setCurrentOptions((prev) => ({ ...prev, sumIndicators: value }));
  };

  const reset = () => {
    setCurrentOptions({
      ascendingOrder: false,
      dataAsPercentage: false,
      showTarget: false,
      sumIndicators: undefined,
    });
    setIsAddIndicatorsChecked(false);
  };

  return {
    currentOptions,
    availableOptions,
    isAddIndicatorsChecked,
    reset,
    setAddIndicator,
    setCurrentOptions,
    handleOptionChange,
    setIsAddIndicatorsChecked,
  };
};
