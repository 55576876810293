import { SummaryButtonType } from '@kaizen/common';

/**
 * Disable loading button when submitting
 * @param isLoading  - describe the loading state of the button
 * @param desiredButtonType - describe type of button, to which the function will be applied
 * @param buttonType - describe current button state
 * @returns boolean
 */
export function disableLoadingButtonWhenSubmitting(
  isLoading: boolean,
  desiredButtonType: SummaryButtonType,
  buttonType?: SummaryButtonType,
) {
  return buttonType === desiredButtonType && isLoading;
}
