import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import {
  ModulesTypes,
  Rows,
} from '@organizations/pages/addOrganization/Modules/Modules.types.ts';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Alert,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { OrganizationModules } from '@/__generated__/graphql';
import { theme } from '@/theme';

/**
 * Array with the available modules names
 */
const names = [...Object.values(OrganizationModules)];

export const Modules = ({
  stepBack,
  register,
  setValue,
  isLoading,
}: ModulesTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isAllChecked, setIsAllCheck] = useState(false);
  const [rows, setRows] = useState<Rows[]>([]);
  const [isModulesEmpty, setIsModulesEmpty] = useState(false);

  useEffect(() => {
    const rows: Rows[] = [];
    names.forEach((singleRecord, counter) => {
      rows.push({
        id: counter,
        iconSrc: '/assets/logo-small.svg',
        name: singleRecord,
        isChecked: false,
        readOnly: false,
      });
    });
    setRows(rows);
  }, []);

  /**
   * Function to set value of all checkboxes
   */
  const setIsAllCheckbox = () => {
    const helperRows = rows.map((row) => ({
      ...row,
      isChecked: row.readOnly ? row.isChecked : !isAllChecked,
    }));
    setRows(helperRows);
    setIsAllCheck(!isAllChecked);
    setValue(
      'modules',

      isAllChecked ? [OrganizationModules.ESchema] : [...names],
      {
        shouldValidate: true,
      },
    );
  };

  /**
   * Set value of a single checkbox
   * @param name Name of the module
   */
  const setRowCheckbox = (name: string) => {
    const helperRows = rows.map((row) => {
      if (row.name == name)
        return {
          ...row,
          isChecked: !row.isChecked,
        };
      return row;
    });
    setRows(helperRows);
  };

  /**
   * Check if any checkbox is checked
   */
  const checkIsAnythingChecked = () => {
    const checked = rows.filter((row) => row.isChecked);
    if (checked.length === 0) {
      setIsModulesEmpty(true);
    }
  };

  return (
    <>
      <Typography variant="h3">{t('organization.modules')}</Typography>
      <StyledAlert
        severity="error"
        icon={
          <ErrorOutlineIcon
            sx={{
              color: theme.palette.grey[900],
            }}
          />
        }
      >
        <Typography variant="bodyRegular">
          {t('organization.moduleInfo')}
        </Typography>
      </StyledAlert>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={30}>
                <Checkbox color="default" onClick={() => setIsAllCheckbox()} />
              </TableCell>
              <TableCell>
                <Typography variant="bodyRegular" fontWeight={500}>
                  {t('organization.moduleName')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell align="left" height="48px">
                  <Checkbox
                    value={row.name}
                    sx={{ height: '24px' }}
                    color="default"
                    checked={row.isChecked}
                    onClick={() => setRowCheckbox(row.name)}
                    disabled={row.readOnly}
                    {...register('modules')}
                  />
                </TableCell>
                <TableCell align="left">
                  <Stack display="flex" flexDirection="row">
                    {' '}
                    <img src={row.iconSrc} alt="logo"></img>
                    &nbsp;&nbsp;
                    <Typography variant="bodyRegular">
                      {t(
                        `organizationModules.${row.name as unknown as OrganizationModules}`,
                      )}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isModulesEmpty && (
        <StyledAlert severity="error" icon={<ErrorOutlineIcon />}>
          <Typography variant="bodyRegular">
            {t('organizationSettings.organizationEmptyModules')}
          </Typography>
        </StyledAlert>
      )}
      <ButtonsWrapper>
        <PrimaryButton onClick={stepBack}>
          <Typography color={theme.palette.common.black} variant="bodyRegular">
            {t('organization.back')}
          </Typography>
        </PrimaryButton>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <PrimaryButton
            variant="contained"
            type="submit"
            onClick={checkIsAnythingChecked}
            style={{
              backgroundColor: theme.palette.primary[600],
            }}
          >
            <Typography variant="bodyRegular">
              {t('organization.save')}
            </Typography>
          </PrimaryButton>
        )}
      </ButtonsWrapper>
    </>
  );
};

const ButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '16px',
});

const StyledAlert = styled(Alert)({
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '8px',
  color: theme.palette.grey[900],
  padding: '10px 12px 10px 12px',
  gap: '16px',
});
