import React, { FC, useEffect, useMemo, useReducer, useState } from 'react';

import { pick } from 'lodash';

import { Box, styled } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

import { useTimeFormat } from '@core/hooks';

import { StringedTime, TimeInputProps, TimeInputReducerType } from './types';

export const TimeEditInputCell: FC<TimeInputProps> = (props) => {
  const [currentValue, setCurrentValue] = useState<number>(
    props.value as number,
  );

  const initialState: StringedTime = {
    hours: '',
    minutes: '',
    seconds: '',
  };

  const timeReducer = (
    state: StringedTime,
    action: TimeInputReducerType,
  ): StringedTime => {
    switch (action.type) {
      case 'HH':
      case 'H':
        return {
          ...state,
          hours: action.payload,
        };
      case 'MM':
      case 'MIN':
      case 'M':
        return {
          ...state,
          minutes: action.payload,
        };
      case 'SS':
      case 'S':
        return {
          ...state,
          seconds: action.payload,
        };
      case 'SET_TIME':
        return action.payload as unknown as StringedTime;
      default:
        return state;
    }
  };

  const [time, dispatch] = useReducer(timeReducer, initialState);

  const apiRef = useGridApiContext();

  const timeFormat = useMemo(() => {
    const unitList = props.format.split('_');
    unitList.shift();
    return unitList;
  }, [props.format]);

  const { time: convertedTime, setTimeInSeconds } = useTimeFormat({
    inputTime: 0,
    timeFormat: props.format,
  });

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') void updateDataGridValue();
  };

  useEffect(() => {
    void updateDataGridValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, apiRef.current.stopCellEditMode]);

  useEffect(() => {
    setTimeInSeconds(currentValue);
    void apiRef.current.setEditCellValue({
      ...pick(props, ['id', 'field']),
      value: currentValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue, apiRef.current.stopCellEditMode]);

  useEffect(() => {
    dispatch({
      type: 'SET_TIME',
      payload: currentValue === undefined ? initialState : convertedTime,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedTime, currentValue]);

  const updateDataGridValue = async () => {
    let seconds = parseFloat(time.seconds);
    let minutes = parseFloat(time.minutes);
    let hours = parseFloat(time.hours);

    // Check if the parsed values are NaN and replace them with 0 if they are
    seconds = isNaN(seconds) ? 0 : seconds;
    minutes = isNaN(minutes) ? 0 : minutes;
    hours = isNaN(hours) ? 0 : hours;

    const calculatedTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

    await apiRef.current.setEditCellValue({
      ...pick(props, ['id', 'field']),
      value: calculatedTimeInSeconds,
    });
  };

  const setValue = (value: string) => {
    if (value === '') {
      return;
    }

    let seconds = parseFloat(time.seconds);
    let minutes = parseFloat(time.minutes);
    let hours = parseFloat(time.hours);

    // Check if the parsed values are NaN and replace them with 0 if they are
    seconds = isNaN(seconds) ? 0 : seconds;
    minutes = isNaN(minutes) ? 0 : minutes;
    hours = isNaN(hours) ? 0 : hours;

    const calculatedTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
    setCurrentValue(calculatedTimeInSeconds);
  };

  return (
    <CustomStack>
      {timeFormat.map((unit, index) => {
        return (
          <React.Fragment key={unit + index}>
            <CustomtextField
              onKeyDown={handleKeyPress}
              inputMode={'decimal'}
              placeholder={unit}
              value={
                unit === 'HH' || unit === 'H'
                  ? time.hours
                  : unit === 'MM' || unit === 'MIN' || unit === 'M'
                    ? time.minutes
                    : time.seconds
              }
              onChange={({ target: { value } }) => {
                value = value.replace(',', '.');
                if (!value.match(/^(\d*([.,])?\d{0,2}$)/)) return;
                dispatch({
                  type: unit as 'HH' | 'MM' | 'SS' | 'H' | 'M' | 'S',
                  payload: value as unknown as string,
                });
              }}
              onBlur={(event) => {
                setValue(event.target.value);
              }}
            />
            {index !== timeFormat.length - 1 && <span>:</span>}
          </React.Fragment>
        );
      })}
    </CustomStack>
  );
};

const CustomtextField = styled('input')({
  margin: '0',
  padding: '8px 0',
  border: 'none',
  width: '30%',
  minWidth: 16,
  textAlign: 'center',
});

const CustomStack = styled(Box)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '2px',
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
});
