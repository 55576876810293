import { Route, Routes } from 'react-router-dom';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { ErrorContent } from '@/core/components';
import { FlexCenter } from '@/core/styles';
import {
  BasicUserInformation,
  Organizations,
  SystemSettings,
  SystemUser,
  SystemUsers,
  UserOrganzations,
} from '@/pages/organizations/pages/organizations';
import { ProtectedRoute } from '@/router';

export const SystemOrganizationRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            allowedGroups={[
              SystemRoles.Root,
              SystemRoles.SystemAdmin,
              SystemRoles.KeyUser,
            ]}
            isSystemRoute={true}
          />
        }
      >
        <Route index element={<Organizations />} />
        <Route path="system-settings" element={<SystemSettings />}>
          <Route path="system-users">
            <Route index element={<SystemUsers />} />
          </Route>
        </Route>
      </Route>
      <Route
        element={
          <ProtectedRoute
            allowedGroups={[
              SystemRoles.Root,
              SystemRoles.SystemAdmin,
              SystemRoles.KeyUser,
            ]}
            isSystemRoute={true}
          />
        }
      >
        <Route path="system-settings/system-users/:id" element={<SystemUser />}>
          <Route
            path="basic-information"
            element={<BasicUserInformation forSystemUser={true} />}
          />
          <Route path="organizations" element={<UserOrganzations />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <FlexCenter sx={{ height: '100vh' }}>
            <ErrorContent errorCode="404" />
          </FlexCenter>
        }
      />
    </Routes>
  );
};
