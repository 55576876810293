/*
 * Prepare eFlow step for saving with files
 * converts files to Blob
 * @private
 */
 
import { EFlowStepFile } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types';
import {
  EFlowStepTypes,
  EflowStepWithFiles,
} from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';

/**
 * Prepares eFlow be saved inside dynamoDB.
 * Converts files to Blob and returns the eFlowStep with local files
 * @param eFlow {EFlowStepTypes} - eFlow which needs to be transformed
 * @param files {File[]} - files currently in the eFlowStep
 * @returns {EflowStepWithFiles}
 *
 */
export const toIndexDb = (
  eFlow: EFlowStepTypes,
  files?: File[],
): EflowStepWithFiles => {
  const blobbedFiles: EFlowStepFile[] =
    files?.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      body: new Blob([file]),
    })) || [];
  return { ...eFlow, localFiles: blobbedFiles } as EflowStepWithFiles;
};
