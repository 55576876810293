import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ChartInput } from '@core/components/Statistica/types';
import {
  FormatPieChartSingleDataSetInAscOrder,
  FormatPieChartSingleDataWithTarget,
  FormatPieChartWithAscendingOrderForMultipleDataSet,
  FormatPieChartWithAscendingOrderOnSingleDataSet,
  FormatPiechartInAscendingOrdersWithMultiple,
  PickColor,
  getNameFromDataSet,
} from '@core/hooks/useRecharts/functions';
import { Chart } from '@core/hooks/useRecharts/handlers/ChartHandler.ts';
import { ExtractChartKeyType } from '@core/hooks/useRecharts/types';
import { FormatPieChartDataType } from '@core/hooks/useRecharts/types/FormatPieChartData.type.ts';

import { ChartTypes } from '@/__generated__/graphql';

import { useGetMonthName } from '..';

export const useRechartPieChart = (props: ChartInput) => {
  const { getMonthName } = useGetMonthName();

  const { t } = useTranslation('kpi');

  const formatPieChartData = useMemo((): FormatPieChartDataType[] => {
    if (!props.dataSet || !props.monthsRange?.length) {
      return [];
    }

    if (Chart.isFromSingleDataSet(props)) {
      if (Chart.isAscendingOrder(props)) {
        if (!Chart.isTargetDisplayed(props)) {
          return FormatPieChartWithAscendingOrderOnSingleDataSet(
            props.dataSet as ExtractChartKeyType,
            props.monthsRange,
          );
        } else {
          return FormatPieChartSingleDataSetInAscOrder(
            props.dataSet as ExtractChartKeyType,
            props.monthsRange,
            !!props.displayOptions.showDataAsPercent,
            getMonthName,
            t,
          );
        }
      } else {
        if (Chart.isTargetDisplayed(props)) {
          return FormatPieChartSingleDataWithTarget(
            props.dataSet as ExtractChartKeyType,
            props.selectedMonth,
            Chart.isShowDataAsPercent(props),
            getMonthName,
            t,
          );
        } else {
          if (Chart.isShowDataAsPercent(props)) {
            return [
              {
                name: getNameFromDataSet(props.dataSet as ExtractChartKeyType),
                value: 100,
                fill: PickColor.pickColor([], ChartTypes.PieChart),
              },
            ];
          }
          return FormatPieChartWithAscendingOrderForMultipleDataSet(
            props.dataSet as ExtractChartKeyType,
            props.selectedMonth,
            Chart.isShowDataAsPercent(props),
          );
        }
      }
    } else {
      if (Chart.isAscendingOrder(props)) {
        return FormatPiechartInAscendingOrdersWithMultiple(
          props.dataSet as ExtractChartKeyType,
          props.monthsRange,
          Chart.isShowDataAsPercent(props),
        );
      } else {
        return FormatPieChartWithAscendingOrderForMultipleDataSet(
          props.dataSet as ExtractChartKeyType,
          props.selectedMonth,
          false,
        );
      }
    }

    return [];
    //eslint-disable-next-line
  }, [
    props.displayOptions.showDataInAscendingOrder,
    props.monthsRange,
    props.dataSet,
    props.selectedMonth,
    props.dataSourceSet.length,
  ]);

  return {
    formatPieChartData,
  };
};
