import { useEffect } from 'react';

import { useCompanyId } from '@core/hooks';

import { useFindSingleKaizenReportLazyQuery } from '@/__generated__/graphql';

import { useUserDashboardContext } from '../../pages/Dashboard/contexts/UserDashboardContext';

export const useKaizenReport = () => {
  const { kaizenSettlementPeriodId } = useUserDashboardContext();

  const { companyId } = useCompanyId();
  const [findSigleReport, { data, loading: isLoading }] =
    useFindSingleKaizenReportLazyQuery();

  useEffect(() => {
    if (!kaizenSettlementPeriodId || !companyId) return;

    void findSigleReport({
      variables: {
        input: {
          id: kaizenSettlementPeriodId,
          organizationId: companyId,
        },
      },
    });
    // eslint-disable-next-line
  }, [kaizenSettlementPeriodId, companyId]);

  return { kaizenReport: data?.findSingleKaizenReport, isLoading };
};
