import { ChartLegendEntryTypes } from '@core/components/Statistica/types';
import { PickColor } from '@core/hooks/useRecharts/functions/PickColor.ts';

import { ChartTypes } from '@/__generated__/graphql.ts';

import { ChartLegendType } from '../types';

export function formatReturnOfLegend(
  input: ChartLegendType,
  chartType: ChartTypes,
): ChartLegendEntryTypes[] {
  // eslint-disable-next-line unused-imports/no-unused-vars
  return Object.entries(input).flatMap(([__key, element]) => {
    const baseEntry: ChartLegendEntryTypes = {
      dataKey: element.valueKey,
      stackId: element.stackId,
      fill: element.fill,
      name: element.name,
      isTarget: false,
    };

    return 'targetKey' in element
      ? [
          baseEntry,
          {
            ...baseEntry,
            dataKey: element.targetKey,
            fill:
              chartType === ChartTypes.LineChart
                ? element.fill
                : PickColor.targetColor,
            isTarget: true,
          },
        ]
      : [baseEntry];
  });
}
