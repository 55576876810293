import { Clear } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

import { theme } from '@/theme';

import { FilePreviewLayoutProps } from './types';

export const FilePreviewLayout = (props: FilePreviewLayoutProps) => {
  return (
    <FilePreviewContent>
      {props.children}
      <ButtonContainer>
        <IconButton onClick={props.onClose}>
          <Clear sx={{ color: theme.palette.common.white }} />
        </IconButton>
      </ButtonContainer>
      <DarkBackground onClick={props.onClose} />
    </FilePreviewContent>
  );
};

export const FilePreviewContent = styled(Box)({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 50,
  img: {
    maxWidth: '90%',
    maxHeight: '90%',
    zIndex: 60,
  },
});

export const DarkBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 40,
});

export const ButtonContainer = styled(Box)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  zIndex: 70,
});
