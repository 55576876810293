import { captureException } from '@sentry/react';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import {
  GetCheckboxOrganizationUnitStructureQuery,
  useCreateOrganizationUnitMutation,
} from '@/__generated__/graphql';
import { ModalContainer, SelectCellModal } from '@/core/components';
import { useOrganizationStore } from '@/stores';
import { TreeTarget } from '@/types';
import { Unpacked } from '@/types/unpacked.ts';

import { AddCellModalValidationWithUnitSchema } from './AddCellModal.schema';
import { defaultValuesWithUnit } from './defaultValues';
import { HorizontalStack } from './styles';
import { AddCellModalProps, FormFieldWithUnit } from './types';

export const AddCellModalWithUnit: FC<AddCellModalProps> = ({
  headerText,
  closeModal,
  openSnackbar,
  onUnitAdded,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { companyName } = useParams();

  const { organization } = useOrganizationStore();

  const [organizationUnit, setOrganizationUnit] = useState<TreeTarget>();

  const [createOrganizationUnit, { loading: isLoading }] =
    useCreateOrganizationUnitMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: defaultValuesWithUnit,
    resolver: zodResolver(AddCellModalValidationWithUnitSchema(t)),
  });

  const onSubmit: SubmitHandler<FormFieldWithUnit> = useCallback(
    async (data) => {
      try {
        const response = await createOrganizationUnit({
          variables: {
            createOrganizationUnitInput: {
              organizationId: organization[companyName!],
              parentGuid: data.organizationUnitGuid,
              name: data.cellName,
            },
          },
        });

        if (response.data?.createOrganizationUnit && onUnitAdded) {
          onUnitAdded(
            response.data.createOrganizationUnit as unknown as Unpacked<
              GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure']
            >,
          );
        }

        openSnackbar(t('organizationSettings.addUnitSuccess'));
      } catch (err) {
        openSnackbar(t('organizationSettings.errorWithCreatingUnit'));
        captureException(err);
      } finally {
        closeModal();
      }
    },
    // eslint-disable-next-line
    [onUnitAdded, organization, companyName],
  );

  return (
    <ModalContainer
      width="528px"
      closeModal={closeModal}
      headerText={headerText}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack padding="24px" gap="16px">
          <SelectCellModal
            value={organizationUnit?.path || ''}
            setValue={(value: TreeTarget) => {
              setOrganizationUnit(value);
              setValue('organizationUnitGuid', value.targetId);
            }}
            label={t('systemSettings.precedentUnit')}
            error={!!errors.organizationUnitGuid}
            helperText={errors.organizationUnitGuid?.message}
          />
          <TextField
            {...register('cellName')}
            sx={{ flex: 1 }}
            size="small"
            label={t('systemSettings.unitName')}
            error={!!errors.cellName}
            helperText={errors.cellName?.message}
          />
        </Stack>
        <HorizontalStack>
          <PrimaryButton onClick={() => closeModal()}>
            <Typography color={theme.palette.text.primary}>
              {t('common.cancel')}
            </Typography>
          </PrimaryButton>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <PrimaryButton
              data-testid="addButtonModal"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
              type="submit"
            >
              <Typography>{t('organizationSettings.addNewCell')}</Typography>
            </PrimaryButton>
          )}
        </HorizontalStack>
      </form>
    </ModalContainer>
  );
};
