import {
  createCommentKey,
  formatComment,
} from '@e-flow/components/CommentModule/functions';
import {
  useRemoveEflowStepComment,
  useSetInReport,
} from '@e-flow/components/CommentModule/hooks';

import { Box, styled } from '@mui/material';

import { SingleComment } from '@/core/components';

import { DisplayCommentsType } from './types';

export const DisplayComments = (props: DisplayCommentsType) => {
  const { setIsInTheReport } = useSetInReport({
    onFlowStepChange: props.onFlowStepChange,
    updateCommentModels: props.updateCommentModels,
    commentModels: props.commentModels,
    currentComments: props.currentComments,
  });

  const { removeComment } = useRemoveEflowStepComment({
    onFlowStepChange: props.onFlowStepChange,
    updateCommentModels: props.updateCommentModels,
    commentModels: props.commentModels,
    currentComments: props.currentComments,
  });

  return (
    <CommentsContainer>
      {props.currentComments.map((comment, index) => (
        <SingleComment
          {...formatComment(comment)}
          onFlowStepChange={props.onFlowStepChange}
          commentTypes={props.commentTypes}
          stepId={props.stepId!}
          key={createCommentKey(index, comment)}
          setIsInTheReport={(isChecked) => {
            setIsInTheReport({
              commentIndex: index,
              commentId: 'id' in comment ? comment.id : undefined,
              isChecked,
            });
          }}
          onEdit={props.onEdit}
          onRemove={() =>
            removeComment({
              commentIndex: index,
              commentId: 'id' in comment ? comment.id : undefined,
            })
          }
        />
      ))}
    </CommentsContainer>
  );
};

const CommentsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  width: '100%',
});
