import { useTranslation } from 'react-i18next';

import { OrganizationModules } from '@/__generated__/graphql';
import { useKaizenRedirects } from '@/core/redirects';

export const useKaizenTabs = () => {
  const { t } = useTranslation('kaizen');
  const { submissionsPath, rankingPath, reportPath } = useKaizenRedirects();

  const kaizenNavbarTabs = {
    variant: OrganizationModules.Kaizen,
    tabs: [
      {
        tabName: t('navigation.dashboard'),
        tabDirection: submissionsPath,
      },
      {
        tabName: t('navigation.ranking'),
        tabDirection: rankingPath,
      },
      {
        tabName: t('navigation.reports'),
        tabDirection: reportPath,
      },
    ],
  };

  return { kaizenNavbarTabs };
};
