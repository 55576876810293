import { useContext } from 'react';

import { EflowStepSyncContext } from '@e-flow/hooks/useSynchronizedFlows';
import { SynchronizedFlowsActionTypes } from '@e-flow/hooks/useSynchronizedFlows/SynchronizedFlowsAction.enum.ts';
import {
  useGetDocumentType,
  useRemoveEflowStepFromCache,
} from '@e-flow/pages/flowDashboard/hooks/useCacheUpdate/hooks';
import {
  UseRemoveEflowStepReturnTypes,
  UseRemoveEflowStepTypes,
} from '@e-flow/pages/flowSteps/hooks/useEflowSteps/useRemoveEflowStep/useRemoveEflowStep.types.ts';
import { useNetworkState } from '@uidotdev/usehooks';

import {
  AsIsToBeNamesEnum,
  useRemoveEflowStepsMutation,
} from '@/__generated__/graphql.ts';
import { IncrementDecrementOperationEnum } from '@/core/types';
import { useAsIsToBeCount } from '@/pages/e-flow/hooks';

export const useRemoveEflowSteps = (
  props: UseRemoveEflowStepTypes,
): UseRemoveEflowStepReturnTypes => {
  const { online: isOnline } = useNetworkState();
  const { pushToSynchronize } = useContext(EflowStepSyncContext);

  const [removeEflowStepsMutation] = useRemoveEflowStepsMutation();
  const { updateCache } = useAsIsToBeCount({ flowId: props.eFlowId });

  const { removeRecordsFromCache } = useRemoveEflowStepFromCache(
    props.eFlowId,
    props.organizationId,
  );
  const { getDocumentType } = useGetDocumentType();

  /**
   * Pushes records to synchronization queue if something goes wrong
   * @param eFlowStepId {string[]} - list of eFlowStepId to push
   */
  const addToSyncQueue = async (eFlowStepId: string[]) => {
    await Promise.all(
      eFlowStepId.map((eFlowStepId) =>
        pushToSynchronize(
          props.eFlowId,
          eFlowStepId,
          SynchronizedFlowsActionTypes.DELETE,
          props.analyzeType,
        ),
      ),
    );
  };

  /**
   * Removes eflow steps from the database
   * @param eFlowStepId
   * @param analyzeType
   */
  const removeOnlineSteps: UseRemoveEflowStepReturnTypes['removeOnlineSteps'] =
    async (eFlowStepId: string[], analyzeType: AsIsToBeNamesEnum) => {
      if (!isOnline) {
        void addToSyncQueue(eFlowStepId);
        return;
      }

      try {
        const { data } = await removeEflowStepsMutation({
          variables: {
            input: {
              eFlowId: props.eFlowId,
              organizationId: props.organizationId,
              eFlowStepsIds: eFlowStepId,
              analizeType: analyzeType,
            },
          },
        });

        if (data?.removeEflowSteps.success) {
          removeRecordsFromCache(
            getDocumentType(analyzeType),
            eFlowStepId,
            analyzeType,
          );
          updateCache(
            eFlowStepId,
            IncrementDecrementOperationEnum.Decrement,
            analyzeType,
          );
          return;
        } else {
          void addToSyncQueue(eFlowStepId);
        }
      } catch (error) {
        void addToSyncQueue(eFlowStepId);
      }
    };

  /**
   * Removes eflow step from the indexDB
   * call this method to remove eflow steps from the indexDB
   */
  const removeStep: UseRemoveEflowStepReturnTypes['removeStep'] = async (
    params,
  ) => {
    const requestedPromises: Promise<void>[] = [];
    if ('id' in params.eFlowStep) {
      requestedPromises.push(
        removeOnlineSteps(
          [params.eFlowStep.id] as string[],
          AsIsToBeNamesEnum.AsIs,
        ),
      );
    }

    requestedPromises.push(
      removeStepsOffline({
        eFlowStepsId: params.eFlowStepsId,
        removeElement: params.removeElement,
        reIndexRecords: params.reIndexRecords,
        reHydrate: params.reHydrate,
      }),
    );

    await Promise.all(requestedPromises);
  };

  const removeStepsOffline: UseRemoveEflowStepReturnTypes['removeStepsOffline'] =
    async (params) => {
      const removeStepsPromise = params.eFlowStepsId.map((eFlowStepId) =>
        params.removeElement(eFlowStepId),
      );

      await Promise.all(removeStepsPromise);

      await params.reIndexRecords(0, 'stepNumber');
      await params.reHydrate();
    };

  return {
    removeStep,
    removeOnlineSteps,
    removeStepsOffline,
  };
};
