import styled from '@emotion/styled';

import { FilePreviewLayout } from './FilePreviewLayout';
import { PreviewProps } from './types';

export const PdfPreview = (props: PreviewProps) => {
  return (
    <FilePreviewLayout onClose={props.onClose}>
      <CustomIframe src={props.filePath} title={props.fileName} />
    </FilePreviewLayout>
  );
};

const CustomIframe = styled('iframe')({
  width: '90%',
  height: '90%',
  transition: 'all 0.3s ease-in-out',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  zIndex: 217,
});
