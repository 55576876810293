import { captureEvent } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useEflowHeader } from '@e-flow/pages/flowDashboard/components/EflowTable/RenderHeader/RenderHeader.tsx';

import { makeStyles } from '@mui/styles';
import {
  DataGrid,
  GridRowClassNameParams,
  GridRowParams,
} from '@mui/x-data-grid';

import { LoadingModal, NoDataInfo } from '@/core/components';

import { RowMenu } from '..';
import { useCopied, useCountParameters } from '../../hooks';
import { getRows, processRowUpdate } from '../utils';
import { AnalizeStepRow, EflowTableProps, Row } from './EflowTable.types';

export const EflowTable = (props: EflowTableProps) => {
  const { t } = useTranslation('eflow');
  const navigate = useNavigate();

  const classes = useStyles();

  const { isCopied, isCopyModalOpen, setIsCopyModalOpen, setCopied } =
    useCopied((props.toBeStepsCount as number) || 0);

  const [rows, setRows] = useState<Row[]>([]);

  const [analizeStep, setAnalizeStep] = useState<AnalizeStepRow>({
    id: '',
    rowIndex: 0,
    isActive: true,
  });

  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const handleOpenMenu = (
    event: MouseEvent,
    paramId: string,
    rowIndex: number,
    isActive: boolean,
  ) => {
    setAnalizeStep({ id: paramId, rowIndex, isActive });
    setAnchorEl(event.currentTarget as HTMLButtonElement);
    setIsMoreMenuOpen(true);
  };

  const summedParams = useCountParameters(props.stepsToDisplay);

  const renderHeader = useEflowHeader(
    props.parameters,
    handleOpenMenu,
    summedParams,
    props.cannotBeDisplayed,
  );

  // Set the rows for the table
  useEffect(() => {
    if (!props.stepsToDisplay) return;
    const rows = getRows(props.stepsToDisplay) as Row[];
    const headers = renderHeader();

    let indexRowCounter = 0;
    const filteredRows = rows.filter((row: Row | undefined) => {
      headers.forEach((header) => {
        Object.keys(row!).includes(header.field)
          ? null
          : (row![header.field] = '-');
      });

      if (props.cannotBeDisplayed.includes('nonActive') && !row!.isActive)
        return null;

      indexRowCounter++;
      row!.index = indexRowCounter;
      return row;
    });
    setRows(filteredRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // Function to handle the error when updating the row
  const handleProcessRowUpdateError = useCallback((error: Error) => {
    captureEvent({
      message: 'Error updating row in flow dashboard',
      extra: { error },
    });
  }, []);

  const getRowClassName = (params: GridRowClassNameParams<Row>) => {
    if (!params.row.isActive) return classes.disabledRow;
    return '';
  };

  return (
    <>
      {props.stepsToDisplay ? (
        <DataGrid
          sx={{ width: '100%', border: 'none' }}
          rows={rows}
          columns={renderHeader()}
          processRowUpdate={(newRow: Row) => processRowUpdate(newRow, setRows)}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          checkboxSelection
          getRowClassName={getRowClassName}
          isRowSelectable={(params: GridRowParams) =>
            params.row.isActive as boolean
          }
          disableRowSelectionOnClick
          hideFooter
          onRowSelectionModelChange={props.onRowSelectionModelChange}
        />
      ) : (
        <NoDataInfo
          onClick={() => navigate('operations/AS_IS')}
          headerText={t('eflow.table.noOperations')}
          infoText={t('eflow.table.clickButtonToBeginAnalysis')}
          buttonText={t('eflow.table.beginAnalysis')}
        />
      )}
      <RowMenu
        anchorEl={anchorEl}
        setCopied={setCopied}
        stepsCount={props.stepsToDisplay?.length || 0}
        analizeType={props.tableType}
        analizeStep={analizeStep}
        eFlowId={props.eFlowId}
        open={isMoreMenuOpen}
        handleClose={() => {
          setIsMoreMenuOpen(false);
          setAnchorEl(null);
        }}
      />
      <LoadingModal
        isLoading={isCopied}
        withTimeout={true}
        onTimeoutText={t('copy.tooLongCopyTime')}
        doneText={t('copy.done')}
        text={t('eflow.table.copyingOperationsSuccess')}
        headerText={t('eflow.table.copyingOperations')}
        closeModal={() => {
          setIsCopyModalOpen(false);
        }}
        open={isCopyModalOpen}
        onTimeoutButtonAction={() => {
          window.location.reload();
        }}
        onTimeoutButtonLabel={t('copy.closeAndSync')}
        timeout={15000}
      />
    </>
  );
};

const useStyles = makeStyles({
  disabledRow: {
    color: '#a0a0a0',
    textDecoration: 'line-through',
  },
});
