import { getName } from '@core/hooks/useRecharts/functions/FormatName.ts';
import { PickColor } from '@core/hooks/useRecharts/functions/PickColor.ts';
import { ExtractChartKeyType } from '@core/hooks/useRecharts/types';
import { FormatPieChartDataType } from '@core/hooks/useRecharts/types/FormatPieChartData.type.ts';

import { ChartTypes } from '@/__generated__/graphql.ts';

export const FormatPieChartWithAscendingOrderForMultipleDataSet = (
  props: ExtractChartKeyType,
  selectedMonth?: number,
  showDataAsPercent?: boolean,
): FormatPieChartDataType[] => {
  if (typeof selectedMonth !== 'number' || isNaN(selectedMonth)) {
    return [];
  }

  const monthData = getMonthData(props, selectedMonth + 1); //because normal months are 1-12, but in the data they are 0-11

  if (!monthData) {
    return [];
  }

  const formattedData = [];

  const usedColors: string[] = [];

  for (const key in monthData) {
    if (key === 'name' || key === 'target') {
      continue;
    }

    if (showDataAsPercent) {
      formattedData.push({
        name: getName(key),
        value: (monthData[key].value / getTarget(monthData[key].target)) * 100,
        fill: PickColor.pickColor(usedColors, ChartTypes.PieChart),
      });
    }

    formattedData.push({
      name: getName(key),
      value: monthData[key].value,
      fill: PickColor.pickColor(usedColors, ChartTypes.PieChart),
    });
  }

  return formattedData;
};

function getMonthData(data: ExtractChartKeyType, selectedMonth: number) {
  return data.filter((entry) => +entry.name === selectedMonth)[0];
}

function getTarget(target?: number) {
  if (!target) {
    return 1;
  }

  return target;
}

//expected return format:
/**
 *
 * const x = [
 *   {
 *     legend_key: 'name',
 *     value:number
 *   }
 * ]
 *
 */
