import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useSchemaTabs } from '@e-schema/constant';

import { Navbar } from '@core/components/Navbar/Navbar.tsx';
import { OrganizationNode } from '@core/types';
import { getAllChild } from '@core/utils';

import {
  OrganizationModules,
  useGetCheckboxOrganizationUnitStructureLazyQuery,
  useGetEmployeeQuery,
} from '@/__generated__/graphql';
import {
  UserSubUnitStore,
  useOrganizationStore,
  useUserStore,
  useUserSubUnitStore,
} from '@/stores';

export const ESchemaLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { schemaNavbarTabs } = useSchemaTabs();

  const { companyName } = useParams();
  const { organization } = useOrganizationStore();
  const { userUuid, organizationId } = useUserStore();
  const { setUserSubUnit } = useUserSubUnitStore();

  const [structureQuery, { data: structure }] =
    useGetCheckboxOrganizationUnitStructureLazyQuery({
      variables: {
        input: {
          id: organization[companyName!],
        },
      },
    });

  const { data: employee } = useGetEmployeeQuery({
    variables: {
      input: {
        id: userUuid,
        organizationId: organizationId!,
      },
    },
  });

  useEffect(() => {
    if (employee?.getEmployee && structure?.getOrganizationUnitsStructure) {
      const idList = getAllChild(employee.getEmployee.organizationUnitGuid, [
        structure.getOrganizationUnitsStructure as unknown as OrganizationNode,
      ]);
      const idMap: UserSubUnitStore['userSubUnit'] = {};

      for (const itemId of idList)
        if (itemId !== employee.getEmployee.organizationUnitGuid)
          idMap[itemId] = true;

      setUserSubUnit(idMap);
    } else if (employee?.getEmployee) {
      void structureQuery();
    }
    // eslint-disable-next-line
  }, [employee, structure]);

  useEffect(() => {
    const pathElements = location.pathname.split('/');
    if (
      pathElements[pathElements.length - 1].trim() === '' &&
      pathElements[pathElements.length - 2].trim() === 'e-schema'
    ) {
      pathElements[pathElements.length - 1] = 'structure';
      navigate(pathElements.join('/'));
    } else if (pathElements[pathElements.length - 1].trim() === 'e-schema') {
      pathElements.push('structure');
      navigate(pathElements.join('/'));
    }
  }, [location.pathname, navigate]);

  return (
    <div style={{ height: '100vh' }}>
      <Navbar
        tabs={schemaNavbarTabs.tabs}
        variant={OrganizationModules.ESchema}
      />

      <Outlet />
    </div>
  );
};
