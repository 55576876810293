import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import {
  CreateDashboardInput,
  DashboardFragment,
  useCreateDashboardMutation,
} from '@/__generated__/graphql';

export const useCreateDashboard = (
  setDashboard: Dispatch<SetStateAction<DashboardFragment | undefined>>,
  createDashboardData: CreateDashboardInput,
) => {
  const { openSnackbar } = useSnackbarContext();
  const { t } = useTranslation('dashboard');
  const { companyId } = useCompanyId();

  const [createDashboardMutation, { loading: isLoading }] =
    useCreateDashboardMutation();

  const createDashboard = async () => {
    try {
      const { data } = await createDashboardMutation({
        variables: {
          input: {
            ...createDashboardData,
            organizationId: companyId,
          },
        },
      });

      if (data) {
        setDashboard(data.createDashboard);
        openSnackbar(t('createDashboard.success'));
        return data.createDashboard;
      }
    } catch (e) {
      openSnackbar(t('createDashboard.error'));
    }
  };

  return {
    isLoading,
    createDashboard,
  };
};
