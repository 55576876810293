import { createContext, useContext } from 'react';

import { useNotifications } from '@core/modules/Notifications/hooks';

import { NotificationsProviderProps } from './NotificationProvider.types';

type NotificationsContextType = ReturnType<typeof useNotifications>;

export const NotificationsContext =
  createContext<NotificationsContextType | null>(null);

export const NotificationsProvider = (props: NotificationsProviderProps) => {
  const UseNotifications = useNotifications();

  return (
    <NotificationsContext.Provider value={UseNotifications}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'There was en error with NotificationsContext. Please check if NotificationsProvider is properly set up.',
    );
  }

  return context;
};
