import React from 'react';
import { useTranslation } from 'react-i18next';

import { statusColors } from '@kaizen/common';

import { Chip, styled } from '@mui/material';

import { KaizenStatuses } from '@/__generated__/graphql.ts';
import { theme } from '@/theme';

import { Props } from './StatusLabel.types';

export const StatusLabel: React.FC<Props> = ({ variant, strikeThrough }) => {
  const { t } = useTranslation('kaizen');

  return (
    <StyledChip
      label={t(`statusLabels.${variant}`)}
      sx={{
        textDecoration: strikeThrough ? 'line-through' : 'none',
        background: statusColors[variant],
        color:
          variant !== KaizenStatuses.Draft
            ? theme.palette.common.white
            : theme.palette.common.black,
      }}
    />
  );
};

const StyledChip = styled(Chip)({
  borderRadius: '7px',
  margin: 0,
});
