import { ChartTypes } from '@/__generated__/graphql';

export class PickColor {
  static get colors() {
    return [
      '#173CB6',
      '#1B60F5',
      '#59A4FF',
      '#133BE1',
      '#3B86FF',
      '#4A91FF',
      '#75B3FF',
      '#4b545d',
      '#B6D8FF',
      '#99BBE8',
      '#19378F',
      '#121212',
    ];
  }

  static get pieChartColors() {
    return [
      '#173CB6',
      '#1B60F5',
      '#59A4FF',
      '#133BE1',
      '#3B86FF',
      '#4A91FF',
      '#75B3FF',
      '#B6D8FF',
      '#99BBE8',
      '#2551da',
      '#4b545d',
      '#121212',
    ];
  }

  static get lineChartColors() {
    return [
      '#4E79A7',
      '#F28E2B',
      '#E15759',
      '#76B7B2',
      '#59A14F',
      '#EDC949',
      '#AF7AA1',
      '#FF9DA7',
      '#9C755F',
      '#BAB0AC',
      '#D37295',
      '#8CD17D',
      '#B07AA1',
      '#FFB55A',
      '#79706E',
    ];
  }

  static get targetColor() {
    return '#d2cece';
  }

  static get randomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  static pickChartPalette(chartType?: ChartTypes) {
    switch (chartType) {
      case ChartTypes.LineChart:
        return PickColor.lineChartColors;

      case ChartTypes.PieChart:
        return PickColor.pieChartColors;

      default:
        return PickColor.colors;
    }
  }

  static pickColor(usedColors: string[], chartType: ChartTypes) {
    const availableColors = this.pickChartPalette(chartType).filter(
      (color) => !usedColors.includes(color),
    );

    if (availableColors.length === 0) {
      const newCreatedColor = PickColor.randomColor;

      usedColors.push(newCreatedColor);

      return newCreatedColor;
    }

    usedColors.push(availableColors[0]);

    return availableColors[0];
  }
}
