import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Typography, useTheme } from '@mui/material';

import { checkSystemRolesPermission } from '@core/utils';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { CustomModal } from '@/core/components';
import { useUserStore } from '@/stores';

import { UserModal } from './UserModal';

export const AddUserModal = () => {
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const { userGroups } = useUserStore();
  const isPermitted = checkSystemRolesPermission(userGroups, [
    SystemRoles.SystemAdmin,
    SystemRoles.Root,
  ]);
  return (
    <>
      {isPermitted ? (
        <PrimaryButton
          data-testid="addButton"
          variant="contained"
          style={{
            backgroundColor: theme.palette.primary[600],
          }}
          onClick={() => setIsModalShowing(true)}
        >
          <Typography>{t('systemSettings.addUser')}</Typography>
        </PrimaryButton>
      ) : null}

      <CustomModal display={isModalShowing}>
        <UserModal closeModal={() => setIsModalShowing(false)} />
      </CustomModal>
    </>
  );
};
