/**
 * ECPS-328 Remove `Eksport` button inside organization tree
 * @bryndalski
 * @brachwal-luqam
 */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformComponent, useControls } from 'react-zoom-pan-pinch';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { TreeViewContentProps } from './TreeViewContent.types';

export const TreeViewContent: FC<TreeViewContentProps> = ({
  children,
  changeVisibility,
  zoom,
  isSideBarVisible,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { zoomIn, zoomOut, centerView } = useControls();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeScale = (value: number) => {
    centerView(value);
  };

  const justifyContent = isSideBarVisible ? 'flex-end' : 'space-between';

  return (
    <>
      <ButtonsPanel style={{ justifyContent }}>
        {!isSideBarVisible && (
          <MainButton
            variant="contained"
            startIcon={<FilterListOutlinedIconComponent />}
          >
            <Typography
              fontSize={14}
              variant="bodyMedium"
              color={theme.palette.text.primary}
              onClick={changeVisibility}
            >
              {t('organizationTree.properties')}
            </Typography>
          </MainButton>
        )}

        <Stack flexDirection="row" gap="12px">
          <Box sx={{ height: '100%' }}>
            <SubButton variant="contained" onClick={() => zoomOut()}>
              <SubIconComponent />
            </SubButton>

            <MainButton
              variant="contained"
              style={{ borderRadius: 0 }}
              endIcon={<ArrowDropDownIconComponent />}
              onClick={handleClick}
            >
              <Typography
                fontSize={14}
                variant="bodyMedium"
                color={theme.palette.text.primary}
              >
                {(zoom * 10).toFixed()}%
              </Typography>
            </MainButton>
            <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
              <MenuItem onClick={() => handleChangeScale(10)}>
                <Typography
                  fontSize={14}
                  variant="bodyMedium"
                  color={theme.palette.text.primary}
                >
                  100%
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleChangeScale(5)}>
                <Typography
                  fontSize={14}
                  variant="bodyMedium"
                  color={theme.palette.text.primary}
                >
                  50%
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleChangeScale(1)}>
                <Typography
                  fontSize={14}
                  variant="bodyMedium"
                  color={theme.palette.text.primary}
                >
                  10%
                </Typography>
              </MenuItem>
            </Menu>
            <AddButton variant="contained" onClick={() => zoomIn()}>
              <AddIconComponent />
            </AddButton>
          </Box>
        </Stack>
      </ButtonsPanel>
      <TransformComponent
        wrapperStyle={{
          width: '100%',
          height: 'auto',
        }}
      >
        {children}
      </TransformComponent>
    </>
  );
};

const ButtonsPanel = styled(Box)({
  width: '100%',
  position: 'absolute',
  paddingRight: '24px',
  paddingLeft: '24px',
  marginTop: '24px',
  height: '40px',
  zIndex: 99,
  display: 'flex',
});

const AddButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  borderTopLeftRadius: 0,
  boxShadow: 'none',
  borderBottomLeftRadius: 0,
  backgroundColor: theme.palette.grey[100],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[200],
  },
  height: '40px',
}));

const SubButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  boxShadow: 'none',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.palette.grey[100],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[200],
  },
  height: '40px',
}));

const MainButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  boxShadow: 'none',
  textTransform: 'none',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[200],
  },
  height: '40px',
}));

// const FileDownloadOutlinedIconComponent = styled(FileDownloadOutlinedIcon)(
//   ({ theme }) => ({
//     color: theme.palette.text.primary,
//   }),
// );

const FilterListOutlinedIconComponent = styled(FilterListOutlinedIcon)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
  }),
);

const ArrowDropDownIconComponent = styled(ArrowDropDownIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const AddIconComponent = styled(AddIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const SubIconComponent = styled(RemoveIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
