import { useState } from 'react';

export const useTablePagination = (defaultRowsPerPage: number = 10) => {
  const [cursor, setCursor] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  return {
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,
  };
};
