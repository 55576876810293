import styled from '@emotion/styled';

import { AvatarGroup, Box, Popover, Stack, Typography } from '@mui/material';

import { useMenu } from '@/core/hooks';

import { EmployeeAvatar } from '../EmployeeAvatar';
import { NamedAvatar } from '../NamedAvatar';
import { UsersIconsProps } from './UsersIcons.types';

export const UsersIcons = (props: UsersIconsProps) => {
  const { users } = props;

  const {
    isMenuOpen: isPopoverOpen,
    handleMenuOpen: handlePopoverOpen,
    handleMenuClose: handlePopoverClose,
    anchorEl: anchorPopover,
  } = useMenu();

  return (
    <>
      <StyledAvatarGroup
        aria-owns={isPopoverOpen ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        total={users.length || 0}
        max={5}
      >
        {users.map((member, index) => (
          <EmployeeAvatar
            key={index}
            imageUrl={member.profilePicture}
            name={
              !member.profilePicture
                ? `${member.firstName[0]}${member.lastName[0]}`
                : ''
            }
          />
        ))}
      </StyledAvatarGroup>
      <Popover
        sx={{
          pointerEvents: 'none',
          padding: '12px',
        }}
        open={isPopoverOpen}
        anchorOrigin={{
          vertical: -70,
          horizontal: 'center',
        }}
        anchorEl={anchorPopover}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {users?.map((member, index) => (
          <Box sx={{ padding: '24px' }} key={index}>
            <Stack flexDirection="row" alignItems="center" gap={'16px'}>
              <NamedAvatar key={member.id} {...member} />
              <Typography>
                {member.firstName} {member.lastName}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Popover>
    </>
  );
};

const StyledAvatarGroup = styled(AvatarGroup)({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& .MuiAvatar-root': {
    width: '24px',
    height: '24px',
    fontSize: '12px',
  },
});
