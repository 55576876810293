import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { AddNewOrganizationTypes } from '@organizations/pages/addOrganization/AddNewOrganization/AddNewOrganization.types.ts';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Alert,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { SystemRoles } from '@/__generated__/graphql.ts';
import {
  Button,
  EmployeeAvatar,
  ImageUpload,
  UserSelect,
} from '@/core/components';
import { useUserStore } from '@/stores';
import { theme } from '@/theme';

export const AddNewOrganization = ({
  nextStep,
  register,
  getValues,
  setValue,
  errors,
  logo,
  setLogo,
}: AddNewOrganizationTypes) => {
  const { t } = useTranslation();
  const { organizationId } = useUserStore();
  const theme = useTheme();

  const [isSelectingOrganizationLogo, setIsSelectingOrganizationLogo] =
    useState<boolean>(false);
  const [imageString, setImageString] = useState<string | null>(null);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    setButtonDisabled(getValues('name') === '' || getValues('url') === '');
  }, [getValues]);

  return (
    <>
      <Typography variant="h3">
        {t('organization.addNewOrganization')}
      </Typography>
      <Stack gap="20px">
        <TextField
          size="small"
          label={t('organization.organizationName')}
          {...register('name')}
          onInput={(event: ChangeEvent<HTMLInputElement>) =>
            setButtonDisabled(
              event.target.value.length < 3 || getValues('url').length < 3,
            )
          }
          error={!!errors.name}
          helperText={errors.name && errors.name?.message}
        />
        <TextField
          size="small"
          sx={{ width: '200px' }}
          label={t('organization.url')}
          {...register('url')}
          onInput={(event: ChangeEvent<HTMLInputElement>) => {
            setButtonDisabled(
              getValues('name').length < 3 || event.target.value.length < 3,
            );
          }}
          error={!!errors.url}
          helperText={errors.url && errors.url?.message}
        />
        <StyledAlert
          severity="error"
          icon={
            <ErrorOutlineIcon
              sx={{
                color: theme.palette.grey[900],
              }}
            />
          }
        >
          <Typography variant="bodyRegular">
            {t('organization.urlInfo')}
          </Typography>
        </StyledAlert>
      </Stack>
      <Stack direction="column" gap="12px" alignItems="start">
        <Typography variant="bodyMedium" fontSize={'14px'}>
          {t('common.logo')}
        </Typography>
        <Button
          variant="contained"
          onClick={() => setIsSelectingOrganizationLogo(true)}
          sx={{
            width: '140px',
          }}
          type="button"
        >
          {t('organization.selectLogo')}
        </Button>
        {logo && (
          <Stack direction="row" alignItems="center">
            <Typography variant="bodyMedium">
              {t('organization.currentLogo')}: &nbsp;
            </Typography>
            <EmployeeAvatar
              size={32}
              imageUrl={imageString}
              name={getValues('name') || ''}
            />
          </Stack>
        )}
      </Stack>
      <Typography variant="bodyMedium">
        {t('organization.systemKeyUser')} ({t('common.optional')})
      </Typography>

      {/**
       * Key user switch
       */}
      <UserSelect
        organizationId={organizationId!}
        onChange={(userId: string) => {
          setValue('systemKeyUserUUID', userId);
        }}
        systemRoles={[SystemRoles.KeyUser]}
      />
      <Stack display="flex" flexDirection="row" justifyContent="end">
        <PrimaryButton
          variant="contained"
          style={{
            backgroundColor: isButtonDisabled
              ? theme.palette.grey[300]
              : theme.palette.primary[600],
          }}
          onClick={nextStep}
          disabled={isButtonDisabled}
        >
          <Typography variant="bodyRegular">
            {t('organization.forward')}
          </Typography>
        </PrimaryButton>
      </Stack>

      <ImageUpload
        isOpen={isSelectingOrganizationLogo}
        onClose={() => setIsSelectingOrganizationLogo(false)}
        onUpload={(file) => {
          if (!file) return;
          setLogo(file);
          setImageString(URL.createObjectURL(file));
        }}
        onRemove={() => {
          setLogo(undefined);
          setImageString(null);
        }}
        imageUrl={imageString}
        title={`${t('common.edit')} ${t('common.logo').toLocaleLowerCase()}`}
        selectImageLabel={t('organization.selectLogo')}
        changeImageLabel={t('organization.changeLogo')}
        removeImageLabel={t('common.delete')}
        altImageText="avatar"
        isOrganizationLogo
      />
    </>
  );
};

const StyledAlert = styled(Alert)({
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '8px',
  color: theme.palette.grey[900],
  padding: '10px 12px 10px 12px',
  gap: '16px',
});
