import { captureEvent } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { omit } from 'lodash';

import {
  CreateEflowInput,
  Employee,
  GetEmployeesForMultiSelectQuery,
  ParameterObject,
  useGetOneEflowQuery,
  useUpdateEflowMutation,
} from '@/__generated__/graphql';
import { useOrganizationStore } from '@/stores';

import {
  CommentsModule,
  OperationsModule,
  ParameterModule,
  ProductModule,
  SettingsModule,
  WorkGroupModule,
} from '../newAnalysis/new-analysis-modules';
import { getNewAddedParameters } from '../newAnalysis/new-analysis-modules/ParameterModule/utils';
import { SelectedRowsTypes } from '../newAnalysis/new-analysis-modules/WorkGroupModule/types';
import { omitTypenameInList } from './utils';

export const useUpdateAnalysis = () => {
  const { companyName, flowId } = useParams();
  const { t } = useTranslation('eflow');
  const { organization } = useOrganizationStore();

  const [updateEflow] = useUpdateEflowMutation();

  const [step, setStep] = useState(1);

  const [author, setAuthor] = useState<
    Pick<Employee, 'firstName' | 'lastName' | 'profilePicture'> | undefined
  >(undefined);

  const [addedParameters, setAddedParameters] = useState<ParameterObject[]>([]);

  const [analyseLeaders, setAnalyseLeaders] = useState<
    GetEmployeesForMultiSelectQuery['getEmployees']['items']
  >([]);
  const [selectedWorkGroupEflowUser, setSelectedWorkGroupEflowUser] =
    useState<SelectedRowsTypes>({});

  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const steps = [
    t('newAnalysis.steps.settings'),
    t('newAnalysis.steps.product'),
    t('newAnalysis.steps.workingGroup'),
    t('newAnalysis.steps.parameters'),
    t('newAnalysis.steps.comments'),
    t('newAnalysis.steps.operations'),
  ];

  const { data, loading: isLoading } = useGetOneEflowQuery({
    variables: {
      input: {
        organizationId: organization[companyName!],
        id: flowId!,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.findOneEFlow) {
      const {
        analyzeLeaders,
        commentTypes,
        name,
        operations,
        parameters,
        workGroupUsers,
        createdBy,
        description,
        startDate,
      } = omitTypenameInList(data.findOneEFlow);
      setNewAnalysis({
        analyzeLeadersIds: analyzeLeaders,
        commentTypes,
        name,
        operations,
        parameters,
        workGroupUsers,
        description,
        organizationId: organization[companyName!],
        startDate,
      });
      setAuthor(createdBy);
      setAnalyseLeaders(data.findOneEFlow.analyzeLeaders || []);
      setAddedParameters([...getNewAddedParameters(parameters)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [newAnalysis, setNewAnalysis] = useState<CreateEflowInput>({
    analyzeLeadersIds: [],
    commentTypes: [],
    name: '',
    operations: [],
    organizationId: organization[companyName!],
    parameters: [],
    workGroupUsers: [],
    description: '',
    startDate: new Date().toISOString(),
  });

  const handleStepBack = () =>
    setStep((currentValue) => (currentValue > 0 ? currentValue - 1 : 0));

  const handleStepNext = () =>
    setStep((currentValue) =>
      currentValue < steps.length ? currentValue + 1 : currentValue,
    );

  /**
   * Set part of analysis
   * @param flowPart
   */
  const handleSetMainAnalysisPart = (flowPart: Partial<CreateEflowInput>) => {
    setNewAnalysis((currentFlow) => {
      if (currentFlow)
        return {
          ...currentFlow,
          ...flowPart,
        } as CreateEflowInput;
      return flowPart as CreateEflowInput;
    });
  };

  const renderStepChange = useCallback(() => {
    switch (step) {
      case 1:
        return (
          <SettingsModule
            analyseLeaders={analyseLeaders}
            setAnalyseLeaders={setAnalyseLeaders}
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            createdBy={author}
          />
        );
      case 2:
        return (
          <ProductModule
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            stepBack={handleStepBack}
          />
        );
      case 3:
        return (
          <WorkGroupModule
            selectedWorkGroupEflowMembers={selectedWorkGroupEflowUser}
            setSelectedWorkGroupEflowMembers={setSelectedWorkGroupEflowUser}
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            stepBack={handleStepBack}
          />
        );

      case 4:
        return (
          <ParameterModule
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            stepBack={handleStepBack}
            addedParameters={addedParameters}
            setAddedParameters={setAddedParameters}
          />
        );
      case 5:
        return (
          <CommentsModule
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            moveNext={handleStepNext}
            stepBack={handleStepBack}
          />
        );
      case 6:
        return (
          <OperationsModule
            currentFormValues={newAnalysis}
            setMainAnalysisPart={handleSetMainAnalysisPart}
            createEFlow={handleSubmit}
            stepBack={handleStepBack}
            isUpdate
          />
        );
    }
    return <div />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleStepNext, step]);

  const handleSubmit = async (): Promise<string | null> => {
    try {
      const { data } = await updateEflow({
        variables: {
          input: {
            ...omit(newAnalysis, ['analyzeLeadersIds']),
            commentTypes: [...(newAnalysis.commentTypes || [])],
            operations: [...(newAnalysis.operations || [])],
            id: flowId!,
            analyzeLeaderModel: newAnalysis?.analyzeLeadersIds?.map((user) => ({
              id: user.id,
              organizationId: user.organizationId,
            })),
          },
        },
      });

      return (data?.updateEflow?.id as string) || null;
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'DUPLICATE_IDS':
            setSnackbarMessage(t('newAnalysis.errors.duplicatesId'));
            break;
          case 'EMPLOYEE_NOT_FOUND':
            setSnackbarMessage(t('newAnalysis.errors.employeeNotExists'));
            break;

          case 'ORGANIZATION_DOES_NOT_HAVE_MODULE':
            setSnackbarMessage(
              t('newAnalysis.errors.organizationDoesNotHaveModule'),
            );
            break;
          default:
            if (error.message) {
              setSnackbarMessage(t('newAnalysis.errors.unknownError'));
            }
            break;
        }
        setIsShowSnackbar(true);
      }

      captureEvent(error as Error);
      return null;
    }
  };

  return {
    newAnalysis,
    renderStepChange,
    isLoading,
    steps,
    setIsShowSnackbar,
    isShowSnackbar,
    snackbarMessage,
    step,
  };
};
