import { useTranslation } from 'react-i18next';

import { useCheckUserPermission } from '@core/hooks';

import { OrganizationModules } from '@/__generated__/graphql';

export const useCheckboxLogic = () => {
  const { t } = useTranslation();
  const checkIfUserHasPermission = useCheckUserPermission();

  const TooltipMessage = (isActive: boolean, name: OrganizationModules) => {
    return name == OrganizationModules.ESchema
      ? t('eSchemaInfo')
      : !isActive &&
          !checkIfUserHasPermission() &&
          t('organizationSettings.disabledModule');
  };

  const CheckboxDisabled = (name: OrganizationModules) =>
    !checkIfUserHasPermission() || name == OrganizationModules.ESchema;

  return { TooltipMessage, CheckboxDisabled };
};
