import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { LanguageStore } from '.';

export const useLanguageStore = create<LanguageStore>()(
  persist(
    (set) => ({
      language: 'pl',
      setLanguage: (lang: string) => set(() => ({ language: lang })),
    }),
    {
      name: 'language-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
