import { useCompanyId } from '@core/hooks';

import { KpiSettlementPeriodFragment } from '@/__generated__/graphql';

import { readQuery, writeQuery } from '../Cache';

export const useUpdateSettlementPeriodCache = () => {
  const { companyId } = useCompanyId();

  const addNewSettlementPeriod = (
    newSettlementPeriod: KpiSettlementPeriodFragment,
  ) => {
    const queryResult = readQuery(companyId);

    if (newSettlementPeriod.isActive) {
      _deactivateAllPeriods(
        queryResult!.findAllKpiSettlementPeriods
          .settlementPeriods as KpiSettlementPeriodFragment[],
      );
    }

    queryResult!.findAllKpiSettlementPeriods.settlementPeriods.push(
      newSettlementPeriod,
    );

    writeQuery(queryResult, companyId);
  };

  const removeSettlementPeriod = (settlementPeriodId: string) => {
    const queryResult = readQuery(companyId);

    const index =
      queryResult!.findAllKpiSettlementPeriods.settlementPeriods.findIndex(
        (settlementPeriod: KpiSettlementPeriodFragment) => {
          return settlementPeriod.id === settlementPeriodId;
        },
      );

    queryResult!.findAllKpiSettlementPeriods.settlementPeriods.splice(index, 1);

    writeQuery(queryResult, companyId);
  };

  const _deactivateAllPeriods = (
    settlementPeriods: KpiSettlementPeriodFragment[],
  ) => {
    settlementPeriods.forEach(
      (settlementPeriod: KpiSettlementPeriodFragment) => {
        settlementPeriod.isActive = false;
      },
    );
  };

  const updateSettlementPeriod = (
    updatedSettlementPeriod: KpiSettlementPeriodFragment,
  ) => {
    const queryResult = readQuery(companyId);

    if (updatedSettlementPeriod.isActive) {
      _deactivateAllPeriods(
        queryResult?.findAllKpiSettlementPeriods
          .settlementPeriods as KpiSettlementPeriodFragment[],
      );
    }

    const index =
      queryResult?.findAllKpiSettlementPeriods.settlementPeriods.findIndex(
        (settlementPeriod: KpiSettlementPeriodFragment) => {
          return settlementPeriod.id === updatedSettlementPeriod.id;
        },
      );

    if (index !== undefined && index !== -1) {
      queryResult!.findAllKpiSettlementPeriods.settlementPeriods[index] =
        updatedSettlementPeriod;
    }

    writeQuery(queryResult, companyId);
  };

  return {
    addNewSettlementPeriod,
    updateSettlementPeriod,
    removeSettlementPeriod,
  };
};
