import { ReactNode, createContext, useContext, useState } from 'react';

import { RemoveSettlementPeriodModalViews } from '@kaizen/module-settings/SettlementPeriods/Enums/RemoveSettlementPeriodModalViews.enum.ts';
import { KaizenSettlementPeriodContextType } from '@kaizen/module-settings/SettlementPeriods/contexts/KaizenSettlementPeriodContext.type.ts';

import { useAlertSnackbar } from '@core/hooks/useAlertSnackbar/useAlertSnakbar.tsx';

import { KaizenSettlementPeriodFragment } from '@/__generated__/graphql.ts';

export const KaizenSettlementPeriodContext = createContext<
  KaizenSettlementPeriodContextType | undefined
>(undefined);

// eslint-disable-next-line react-refresh/only-export-components
export const useKaizenSettlementPeriodContext = () => {
  const context = useContext(KaizenSettlementPeriodContext);

  if (context === undefined) {
    throw new Error(
      'useKaizenSettlementPeriodContext must be used within a KaizenSettlementPeriodProvider',
    );
  }

  return context;
};

export const KaizenSettlementPeriodProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isSettlementPeriodModalOpen, setIsSettlementPeriodModalOpen] =
    useState<boolean>(false);

  const [
    isUpdateSettlementPeriodModalOpen,
    setIsUpdateSettlementPeriodModalOpen,
  ] = useState<boolean>(false);
  const [
    settlementPeriodUpdateModalValues,
    setSettlementPeriodUpdateModalValues,
  ] = useState<KaizenSettlementPeriodFragment>();

  const [
    isActiveSettlementPeriodModalOpen,
    setIsActiveSettlementPeriodModalOpen,
  ] = useState<boolean>(false);
  const [
    settlementPeriodActiveModalValues,
    setSettlementPeriodActiveModalValues,
  ] = useState<KaizenSettlementPeriodFragment>();

  const [
    isRemoveSettlementPeriodModalOpen,
    setIsRemoveSettlementPeriodModalOpen,
  ] = useState<boolean>(false);
  const [settlementPeriodRemoveModalValues, setSettlementPeriodRemoveValues] =
    useState<KaizenSettlementPeriodFragment>();
  const [
    currentRemoveSettlementPeriodModalView,
    setCurrentRemoveSettlementPeriodModalView,
  ] = useState<RemoveSettlementPeriodModalViews>(
    RemoveSettlementPeriodModalViews.CAN_NOT_REMOVE,
  );

  const alertProps = useAlertSnackbar();

  return (
    <KaizenSettlementPeriodContext.Provider
      value={{
        isSettlementPeriodModalOpen,
        openCreateSettlementPeriodModal: () =>
          setIsSettlementPeriodModalOpen(true),
        closeCreateSettlementPeriodModal: () =>
          setIsSettlementPeriodModalOpen(false),
        isUpdateSettlementPeriodModalOpen,
        settlementPeriodUpdateModalValues,
        openUpdateSettlementPeriodModal: (values) => {
          setIsUpdateSettlementPeriodModalOpen(true);
          setSettlementPeriodUpdateModalValues(values);
        },
        closeUpdateSettlementPeriodModal: () => {
          setIsUpdateSettlementPeriodModalOpen(false);
          setSettlementPeriodUpdateModalValues(undefined);
        },

        isActiveSettlementPeriodModalOpen,
        settlementPeriodActiveModalValues,
        openActivateSettlementPeriodModal: (values) => {
          setIsActiveSettlementPeriodModalOpen(true);
          setSettlementPeriodActiveModalValues(values);
        },
        closeActivateSettlementPeriodModal: () => {
          setIsActiveSettlementPeriodModalOpen(false);
          setSettlementPeriodActiveModalValues(undefined);
        },

        //remove modal
        isRemoveSettlementPeriodModalOpen,
        settlementPeriodRemoveModalValues,
        openRemoveSettlementPeriodModal(
          values: KaizenSettlementPeriodFragment,
        ) {
          setIsRemoveSettlementPeriodModalOpen(true);
          setSettlementPeriodRemoveValues(values);
          setCurrentRemoveSettlementPeriodModalView(
            values.isActive
              ? RemoveSettlementPeriodModalViews.CAN_NOT_REMOVE
              : RemoveSettlementPeriodModalViews.CONFIRMATION,
          );
        },
        closeRemoveSettlementPeriodModal() {
          setIsRemoveSettlementPeriodModalOpen(false);
          setSettlementPeriodRemoveValues(undefined);
          setCurrentRemoveSettlementPeriodModalView(
            RemoveSettlementPeriodModalViews.CONFIRMATION,
          );
        },
        currentRemoveSettlementPeriodModalView,
        ...alertProps,
      }}
    >
      {children}
    </KaizenSettlementPeriodContext.Provider>
  );
};
