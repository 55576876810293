import dayjs from 'dayjs';
import { ParameterUnitsTypes } from '@/__generated__/graphql';

/**
 * Function to convert time
 * @param timeInSeconds - Basic time in seconds
 * @param timeFormat - Time format
 * @returns - array of hours, minutes and seconds
 */
export const convertTime = (
  timeInSeconds: number,
  timeFormat: ParameterUnitsTypes,
) => {
  const durationObj = dayjs.duration(timeInSeconds, 'seconds');
  let hours =
    durationObj.hours() +
    durationObj.days() * 24 +
    durationObj.months() * 30 * 24 +
    durationObj.years() * 365 * 24;
  let minutes = durationObj.minutes();
  let seconds = durationObj.seconds() + durationObj.milliseconds() * 0.001;

  switch (timeFormat) {
    case ParameterUnitsTypes.TimeH:
      hours = durationObj.asHours();
      minutes = 0;
      seconds = 0;
      break;
    case ParameterUnitsTypes.TimeMin:
      minutes = durationObj.asMinutes();
      hours = 0;
      seconds = 0;
      break;
    case ParameterUnitsTypes.TimeMmSs:
      minutes =
        durationObj.minutes() +
        hours * 60 +
        durationObj.days() * 24 * 60 +
        durationObj.months() * 30 * 24 * 60 +
        durationObj.years() * 365 * 24 * 60;
      hours = 0;
      break;
    case ParameterUnitsTypes.TimeS:
      seconds = durationObj.asSeconds();
      hours = 0;
      minutes = 0;
      break;
  }

  return {
    hours,
    minutes,
    seconds,
  };
};
