import { useMemo } from 'react';

import type { UseUnitTypesInput } from '@e-flow/hooks/useUnitsTypes/useUnitTypes.type.ts';
import { UseUnitTypesTypeOutput } from '@e-flow/hooks/useUnitsTypes/useUnitTypes.type.ts';

import { ParameterUnitsTypes } from '@/__generated__/graphql.ts';

/**
 * Hook to get the matching selected units
 * @description Hook used to get units, to which parameter can be converted
 *
 * @param props {UseUnitTypesInput} - The input props for the hook
 *
 * @returns {UseUnitTypesTypeOutput} - The output of the hook
 *
 * @example
 * const { types } = useUnitTypes({ selectedParameterUnit });
 *
 */
export const useUnitTypes = (
  props: UseUnitTypesInput,
): UseUnitTypesTypeOutput => {
  const getMatchingSelectedUnits = useMemo(() => {
    const { selectedParameterUnit } = props;

    if (!selectedParameterUnit) return [];

    const unitsMap: Record<string, ParameterUnitsTypes[]> = {
      TIME: [
        ParameterUnitsTypes.TimeD,
        ParameterUnitsTypes.TimeH,
        ParameterUnitsTypes.TimeHhMm,
        ParameterUnitsTypes.TimeHhMmSs,
        ParameterUnitsTypes.TimeMin,
        ParameterUnitsTypes.TimeMmSs,
        ParameterUnitsTypes.TimeS,
      ],
      VOLUME: [ParameterUnitsTypes.VolumeUnits],
      VOLUMEUNITS: [ParameterUnitsTypes.VolumeL, ParameterUnitsTypes.VolumeM3],
      MASS: [ParameterUnitsTypes.MassG, ParameterUnitsTypes.MassKg],
      AREA: [ParameterUnitsTypes.AreaCm2, ParameterUnitsTypes.AreaM2],
      DISTANCE: [
        ParameterUnitsTypes.DistanceCm,
        ParameterUnitsTypes.DistanceM,
        ParameterUnitsTypes.DistanceMm,
      ],
    };

    const matchingUnits = Object.entries(unitsMap).find(([key]) =>
      selectedParameterUnit.includes(key),
    );

    return matchingUnits ? matchingUnits[1] : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedParameterUnit]);

  return {
    types: getMatchingSelectedUnits,
  };
};
