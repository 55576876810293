import { FC, useRef } from 'react';

import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import { EmptyDataset } from '@core/components/Statistica/EmptyDataset/EmptyDataset.tsx';
import { PieChartLegend } from '@core/components/Statistica/Legends';
import { RechartPieChRechartPieChartCustomTooltip } from '@core/components/Statistica/Tooltips';
import { ChartInput } from '@core/components/Statistica/types/ChartInput.types.ts';
import { useRechartPieChart } from '@core/hooks/useRecharts';
import { extractShortcut } from '@core/utils';

export const RechartPieChart: FC<ChartInput> = (props) => {
  const ref = useRef(null);

  const { formatPieChartData } = useRechartPieChart(props);

  if (props.dataSet.length === 0 || formatPieChartData?.length === 0) {
    return <EmptyDataset />;
  }

  return (
    <ResponsiveContainer width={'100%'} height={'100%'} ref={ref}>
      <PieChart>
        <Pie
          data={formatPieChartData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          fill="#8884d8"
          label={!!props.displayOptions.showDataLabels}
          endAngle={90}
          startAngle={450}
          innerRadius={50}
          outerRadius={90}
        >
          {formatPieChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
        <Legend
          content={
            <PieChartLegend pieChartData={formatPieChartData} props={props} />
          }
        />

        <Tooltip
          cursor={{ fill: 'rgba(0,0,0,0)' }}
          content={(tooltipProps) => (
            <RechartPieChRechartPieChartCustomTooltip
              active={tooltipProps.active}
              payload={tooltipProps.payload as unknown as never}
              label={tooltipProps.label}
              unitShortCut={extractShortcut(props)}
            />
          )}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
