import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { KpiNotificationsType } from '@core/modules/Notifications/types/KpiNotifications.type.ts';
import { KpiReportedNotificationsType } from '@core/modules/Notifications/types/KpiReportedNotification.type.ts';
import { isDateGraterThan } from '@core/utils';

export const useKpiNotification = (
  props: KpiNotificationsType | KpiReportedNotificationsType,
) => {
  const { t } = useTranslation();

  const notificationTextTitle = useCallback(() => {
    if (!('dueDate' in props.body)) {
      return '';
    }
    const formattedDueDate = dayjs(new Date(props.body.dueDate)).format(
      'DD/MM/YYYY',
    );
    if (
      isDateGraterThan(new Date(props.body.dueDate), new Date(props.sentAt))
    ) {
      return t('notification.incomingReportDate', {
        reportDate: formattedDueDate,
      });
    }

    return t('notification.missedReportDate', {
      reportDate: formattedDueDate,
    });
  }, [props, t]);

  const notificationTitle = useCallback(() => {
    if (props?.body?.kpiName === 'MISSING_KPI') {
      return t('notification.missingKpi');
    }
    return props?.body?.kpiName || t('notification.missingKpi');
  }, [props, t]);

  const isKpiMissing = useMemo(() => {
    return props?.body.kpiName !== 'MISSING_KPI';
  }, [props]);

  return {
    isKpiMissing: isKpiMissing,
    notificationTitle: notificationTitle(),
    notificationTextTitle: notificationTextTitle(),
  };
};
