import { TextField, styled } from '@mui/material';

export const CustomTextField = styled(TextField)({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
});
