import { TableCell, TableRow, Typography, styled } from '@mui/material';

import { EmployeeAvatar } from '@/core/components';

import { TableRowProps } from './RankingTable.types';

export const RankingTableRow = ({
  employee,
  points,
  submissions,
  index,
}: TableRowProps) => {
  return (
    <TableRow>
      <NumberCell>
        <Typography variant="bodyRegular">{index + 1}.</Typography>
      </NumberCell>
      <EmployeeCell height={'48px'}>
        <EmployeeAvatar
          size={24}
          name={employee.firstName}
          imageUrl={employee.profilePicture}
        />
        <Typography variant="bodyRegular" sx={{ marginLeft: '10px' }}>
          {employee.firstName} {employee.lastName}
        </Typography>
      </EmployeeCell>
      <TableCell>
        <Typography variant="bodyRegular">{submissions}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="bodyRegular">{points}</Typography>
      </TableCell>
    </TableRow>
  );
};

const NumberCell = styled(TableCell)({
  width: '56px',
  textAlign: 'center',
});

const EmployeeCell = styled(TableCell)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
