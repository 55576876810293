import { OperationIcon } from '@e-flow/components';

import { Box, Radio, Stack, styled } from '@mui/material';

import { theme } from '@/theme';

import { OperationSelectRowProps } from './OperationSelectRow.types.ts';

export const OperationSelectRow = (props: OperationSelectRowProps) => {
  return (
    <RowStack onClick={() => props.setSelectedOperation(props.name)}>
      <Radio
        checked={props.selected}
        onClick={() => props.setSelectedOperation(props.name)}
      />
      <ImageContainer>
        <OperationIcon operation={props.operationType} />
      </ImageContainer>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>{props.name}</Box>
      </Stack>
    </RowStack>
  );
};

const ImageContainer = styled(Box)({
  width: '64px',
  height: '36px',
  padding: '0 16px 0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const RowStack = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  borderColor: theme.palette.grey[200],
  borderBottomStyle: 'solid',
  borderBottomWidth: '2.5px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    cursor: 'pointer',
  },
});
