import { useTimeFormatType } from './useTimeFormat.types';

/**
 * Converts the given time object into a formatted string based on the provided format.
 * @param time - The time object to convert.
 * @param format - The format string specifying the desired output format.
 * @returns The formatted time string.
 */
export const getTimeAsString = (time: useTimeFormatType, format: string) => {
  const tempTime: string[] = [];

  const timeValues = Object.values(time);

  const unitList = format.split('_');
  unitList.shift();
  unitList.forEach((_unit, index) => {
    tempTime.push(
      timeValues[index] > 9
        ? String(timeValues[index])
        : `0${timeValues[index]}`,
    );
  });
  return tempTime.join(':');
};
