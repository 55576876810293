import InfiniteScroll from 'react-infinite-scroll-component';

import { CircularProgress } from '@mui/material';

import { NotificationsLimit } from '@core/constants';
import { SingleNotification } from '@core/modules/Notifications/components/SingleNotification.tsx';
import { NoNotifications } from '@core/modules/Notifications/components/Tabs/Empty.tsx';
import { useNotificationsContext } from '@core/modules/Notifications/context/NotificationsContext.tsx';
import { NotificationType } from '@core/modules/Notifications/types/Notifications.types.ts';
import { FlexCenter } from '@core/styles';

export const NotificationTab = () => {
  const { total, notifications, setCursor } = useNotificationsContext();

  if (!notifications?.length) {
    return <NoNotifications />;
  }

  return (
    <InfiniteScroll
      next={() => void setCursor((prev: number) => prev + NotificationsLimit)}
      hasMore={notifications.length < total}
      loader={
        <FlexCenter sx={{ padding: '12px' }}>
          <CircularProgress />
        </FlexCenter>
      }
      dataLength={notifications.length}
      scrollableTarget="main-container"
    >
      {notifications.map((notification: NotificationType) => (
        <SingleNotification key={notification.id} {...notification} />
      ))}
    </InfiniteScroll>
  );
};
