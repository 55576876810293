import { useTranslation } from 'react-i18next';

import { CustomModal, ModalContainer } from '@core/components';

import { HandleChartVisibilityContent } from './HandleChartVisibilityContent';
import { HandleChartVisibilityProvider } from './context/useHandleChartVisibilityContext';
import { HandleChartVisibilityProps } from './types';

export const HandleChartVisibilityModal = ({
  close,
  display,
}: HandleChartVisibilityProps) => {
  const { t } = useTranslation('kpi');

  return (
    <CustomModal display={display}>
      <ModalContainer
        width="600px"
        headerText={t('charts.showedCharts')}
        closeModal={close}
      >
        <HandleChartVisibilityProvider close={close}>
          <HandleChartVisibilityContent />
        </HandleChartVisibilityProvider>
      </ModalContainer>
    </CustomModal>
  );
};
