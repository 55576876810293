import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';

import { SubmissionRowMenu } from '../SubmissionRowMenu';
import { CustomCell } from './styles';
import { MenuCellProps } from './types';

export const MenuCell = ({
  submissionId,
  creatorId,
  handleRemove,
  isModficationsEnabled,
}: MenuCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  return (
    <CustomCell>
      <IconButton
        aria-label="more"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <SubmissionRowMenu
        isModificationsEnabled={isModficationsEnabled}
        handleRemove={handleRemove}
        submissionId={submissionId}
        open={isMenuOpen}
        anchorEl={anchorEl}
        creatorId={creatorId}
        handleClose={() => setAnchorEl(null)}
      />
    </CustomCell>
  );
};
