/* eslint-disable @typescript-eslint/no-misused-promises */
import { captureException } from '@sentry/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { resetPassword } from 'aws-amplify/auth';
import { z } from 'zod';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { AuthScreenWrapper } from '@/core/components';
import { useUserStore } from '@/stores';

export const SendMailScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { setMail } = useUserStore();
  const {
    handleSubmit,
    register,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string({
            required_error: t('common.fillTheField'),
          })
          .email({
            message: t('common.invalidEmail'),
          }),
      }),
    ),
  });

  /**
   * @description Sends a reset password email to the user
   */
  const handleResetPassword = async () => {
    try {
      await resetPassword({
        username: getValues('email'),
      });
      setMail(getValues('email'));
      navigate('/forgot-password/set-new-password');
    } catch (err) {
      if (err instanceof Error && err.name === 'ResourceNotFoundException') {
        setError('email', {
          type: 'manual',
          message: t('forgotPassword.emailNotFound'),
        });
        captureException(err);
      }
    }
  };

  return (
    <AuthScreenWrapper>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <Stack gap={'32px'}>
          <IconButton
            onClick={() => navigate('/login')}
            sx={{
              color: theme.palette.common.black,
              width: '87px',
            }}
          >
            <ArrowBackIcon />
            <Typography variant="bodyMedium" sx={{ fontSize: '14px' }}>
              {t('organization.back')}
            </Typography>
          </IconButton>
          <Stack gap="16px">
            <Typography variant="h3">
              {t('forgotPassword.forgotPassword')}
            </Typography>
            <Typography variant="bodyRegular">
              {t('forgotPassword.message')}
            </Typography>
          </Stack>
          <TextField
            size="small"
            label={t('systemSettings.email')}
            sx={{
              outlineColor: theme.palette.grey[800],
            }}
            error={typeof errors.email?.message !== 'undefined'}
            helperText={
              typeof errors.email?.message !== 'undefined' &&
              errors.email.message
            }
            {...register('email')}
          />
          <PrimaryButton
            variant="contained"
            type={'submit'}
            sx={{
              backgroundColor: theme.palette.primary[600],
            }}
            onClick={handleSubmit(handleResetPassword)}
          >
            <Typography variant="bodyRegular">
              {t('common.continue')}
            </Typography>
          </PrimaryButton>
        </Stack>
      </form>
    </AuthScreenWrapper>
  );
};
