import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography, styled } from '@mui/material';

import {
  ModalContainer,
  PaginationFilterLayout,
  TextButton,
} from '@/core/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { TreeTarget } from '@/types';

import { ModalEmployeesTable } from './tables/ModalEmployeeTable';
import { SelectEmployeeModalProps, SelectedRowsTypes } from './types';

export const SelectEmployeeModal = ({
  close,
  selectedRows,
  setSelectedRows,
  disabledEmployees,
}: SelectEmployeeModalProps) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  const [organizationUnit, setOrganizationUnit] = useState<TreeTarget>({
    targetId: '',
    path: '',
  });
  const [search, setSearch] = useState('');
  const [localSelectedRows, setLocalSelectedRows] = useState<SelectedRowsTypes>(
    {
      ...selectedRows,
    },
  );

  return (
    <ModalContainer
      closeModal={close}
      headerText={t('newAnalysis.groups.chooseEmployees')}
      width="90vw"
      sx={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 20px 20px 20px',
      }}
    >
      <ContentContainer>
        <PaginationFilterLayout
          search={search}
          setSearch={setSearch}
          organizationUnit={organizationUnit}
          setOrganizationUnit={setOrganizationUnit}
        />
        {/*table logic*/}
        <Stack>
          {/* table */}
          <ModalEmployeesTable
            selectedRows={localSelectedRows}
            setSelectedRows={setLocalSelectedRows}
            search={search}
            organizationUnit={organizationUnit}
            disabledEmployees={disabledEmployees?.map(
              (employee) => employee.id,
            )}
          />
        </Stack>
      </ContentContainer>
      {/*final buttons*/}
      <CustomButtonsContainer>
        <TextButton
          text={commonT('common.cancel')}
          width="auto"
          onClick={close}
        />
        <PrimaryButton
          variant="contained"
          onClick={() => {
            setSelectedRows(localSelectedRows);
            close();
          }}
        >
          <Typography>{t('newAnalysis.groups.choose')}</Typography>
        </PrimaryButton>
      </CustomButtonsContainer>
    </ModalContainer>
  );
};

const CustomButtonsContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'end',
  gap: '12px',
});

const ContentContainer = styled(Box)({
  flex: 1,
  flexDirection: 'column',
  overflow: 'auto',
  padding: '5px',
});
