import { useEffect, useMemo, useState } from 'react';

import { formatKpiData } from '@kpi/components/KpiRatesTable/KpiTable/functions/format-kpi-data.ts';
import { useFindKpiData } from '@kpi/components/KpiRatesTable/KpiTable/hooks/useFindKpiData.tsx';
import { useKpiDashboardFiltersContext } from '@kpi/components/KpiRatesTable/context';
import { uniqBy } from 'lodash';

import {
  KpiRatingOrderBy,
  OrderBy,
  useFindAllKpisLazyQuery,
} from '@/__generated__/graphql';

import { KpiRateType } from '../KpiTable.types';

export const useKpiTable = () => {
  const [order, setOrder] = useState<OrderBy>(OrderBy.Desc);
  const [orderBy, setOrderBy] = useState<KpiRatingOrderBy>(
    KpiRatingOrderBy.Name,
  );

  const [isReloading, setIsReloading] = useState(false);

  const { queryInputVariables, rowsPerPage, setCursor } = useFindKpiData({
    orderBy,
    order,
  });

  const {
    debounceSearch,
    assignedPersonId,
    organizationUnitId,
    selectedUnit,
    periodId,
  } = useKpiDashboardFiltersContext();

  const [findAllKpis, { data, loading: isLoading }] = useFindAllKpisLazyQuery();

  const [kpiRates, setKpiRates] = useState<KpiRateType[]>([]);

  const newData = useMemo(() => formatKpiData(data?.findAllKpis.items), [data]);

  useEffect(() => {
    if (newData && newData.length > 0)
      setKpiRates(uniqBy([...kpiRates, ...newData].reverse(), 'id').reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  useEffect(() => {
    setKpiRates([]);
    setCursor(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debounceSearch,
    assignedPersonId,
    organizationUnitId,
    selectedUnit,
    periodId,
  ]);

  useEffect(() => {
    if (
      !queryInputVariables.organizationId ||
      !queryInputVariables.organizationUnitIds
    )
      return;
    void findAllKpis({
      variables: {
        input: queryInputVariables,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryInputVariables)]);

  useEffect(() => {
    setIsReloading(true);
  }, [order, orderBy]);

  useEffect(() => {
    if (!isLoading) setIsReloading(false);
  }, [isLoading]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>, // Remove the unused 'event' parameter
    property: KpiRatingOrderBy,
  ) => {
    const isDesc = orderBy === property && order === OrderBy.Desc;
    setOrder(isDesc ? OrderBy.Asc : OrderBy.Desc);
    setOrderBy(property);
  };

  const filterKpiRates = (id: string) => {
    setKpiRates(kpiRates.filter((kpi) => kpi.id !== id));
  };

  return {
    kpiRates,
    isLoading:
      (isLoading && kpiRates.length === 0) || (isReloading && isLoading),
    isReloading,
    handleRequestSort,
    order,
    orderBy,
    setCursor,
    rowsPerPage,
    total: data?.findAllKpis.total || 0,
    filterKpiRates,
  };
};
