/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddParameterSchema } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/AddParameter.schema.ts';
import { UnitData } from '@e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParams.ts';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import {
  ParameterObject,
  ParameterTypes,
  ParameterUnitsTypes,
} from '@/__generated__/graphql.ts';
import {
  CharsCountingInput,
  CustomModal,
  DisableLabel,
  ModalContainer,
  TextButton,
} from '@/core/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee';
import { RowStack } from '@/pages/organizations/styles';
import { theme } from '@/theme';

import { AddParameterModuleProps } from './AddParameterModal.types';

export const AddParameterModal = (props: AddParameterModuleProps) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation('translation');

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ParameterObject>({
    defaultValues: {
      name: '',
      unit: ParameterUnitsTypes.AreaM2,
    },
    resolver: zodResolver(AddParameterSchema(props.paramArray, t)),
  });

  const onSubmit = (data: ParameterObject) => {
    setIsErrorVisible(false);
    props.addParameter({
      ...data,
      type: ParameterTypes.Local,
    });
    props.setSnackBar(t('newAnalysis.parameters.addUnit.snackBarMessage'));
    props.close();
  };

  return (
    <CustomModal display={props.open}>
      <ModalContainer
        closeModal={props.close}
        headerText={t('newAnalysis.parameters.addUnit.title')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack padding={'24px 16px'} gap="16px">
            {isErrorVisible ? (
              <Alert
                variant="outlined"
                severity="error"
                sx={{
                  background: theme.palette.error[100],
                }}
              >
                {t('newAnalysis.parameters.addUnit.paramExistError')}
              </Alert>
            ) : null}
            <CharsCountingInput
              {...register('name')}
              label={t('newAnalysis.parameters.name')}
              helperText={errors.name?.message}
              error={!!errors.name}
              size="small"
              required
              maxInputLength={40}
              sx={{
                maxWidth: '360px',
                marginBottom: '0',
              }}
              fullWidth
            />

            <FormControl sx={{ width: '184px' }} size="small">
              <InputLabel>{t('newAnalysis.parameters.unit')}</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 40 * 4.5,
                    },
                  },
                }}
                label={t('newAnalysis.parameters.unit')}
                {...register('unit')}
                defaultValue={ParameterUnitsTypes.AreaM2}
                error={!!errors.unit}
              >
                {UnitData.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {t(`newAnalysis.parameters.params.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              disabled={true}
              hidden={true}
              control={
                <Checkbox
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />
              }
              label={
                <DisableLabel variant="bodyRegular">
                  {t('newAnalysis.parameters.addUnit.checkboxMessage')}
                </DisableLabel>
              }
            />
            <RowStack justifyContent={'end'}>
              <TextButton
                text={commonT('common.cancel')}
                width="66px"
                onClick={props.close}
              />
              <PrimaryButton
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary[600],
                }}
                type="submit"
              >
                <Typography sx={{ fontSize: '14px' }} variant="bodyMedium">
                  {t('newAnalysis.parameters.addUnit.add')}
                </Typography>
              </PrimaryButton>
            </RowStack>
          </Stack>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};
