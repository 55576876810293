import {
  AdjustmentButton,
  RejectButton,
  SendToCommissionAssessment,
} from '@kaizen/components';
import { SendSubmissionToSupervisorAssessmentButton } from '@kaizen/components/Buttons/SendSubmissionToSupervisorAssessment/SendSubmissionToSupervisorAssessmentButton.tsx';

import { KaizenStatuses } from '@/__generated__/graphql.ts';

export const useRenderButtonForUnitAdmin = () => {
  const renderButtonForSubmissionUnitAdmin = (
    submissionStatus: KaizenStatuses,
    submissionId: string,
  ) => {
    switch (submissionStatus) {
      case KaizenStatuses.Adjustment:
        return (
          <>
            <RejectButton submissionId={submissionId} />
            <AdjustmentButton submissionId={submissionId} />
            <SendSubmissionToSupervisorAssessmentButton
              submissionId={submissionId}
            />
          </>
        );
      case KaizenStatuses.SupervisorAssessment:
        return (
          <>
            <RejectButton submissionId={submissionId} />
            <AdjustmentButton submissionId={submissionId} />
            <SendToCommissionAssessment submissionId={submissionId} />
          </>
        );
      default:
        return null;
    }
  };

  return {
    renderButtonForSubmissionUnitAdmin,
  };
};
