import { TFunction } from 'i18next';
import { z } from 'zod';
import { EmployeeState } from '@/__generated__/graphql.ts';


/**
 * User section schema for zed validation
 * @param t
 * @constructor
 */
export const UnitSectionSchema = (t: TFunction) =>
  z.object({
    position: z.string().min(1, t('common.fillTheField')),
    organizationUnitGuid: z.string().min(1, t('common.fillTheField')),
    state: z.nativeEnum(EmployeeState),
  });