import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isArray } from 'lodash';

import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material';

import { PeriodProps, SubmissionSelectProps } from './SumbissionSelect.types';
import { useSubmissionSelect } from './useSubmissionSelect';

export const SubmissionSelect = ({
  multiselect = true,
  onSelect,
  width,
  limitedTags = 1,
}: SubmissionSelectProps) => {
  const { t } = useTranslation('kaizen');

  const { onChange, data, selectedPeriods, isLoading } =
    useSubmissionSelect(multiselect);

  useEffect(() => {
    const selectedPeriodsIds = isArray(selectedPeriods)
      ? selectedPeriods.map((period) => period.value)
      : [selectedPeriods.value];
    onSelect(selectedPeriodsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriods]);

  return (
    <Autocomplete
      sx={{ width: width }}
      multiple={multiselect}
      limitTags={limitedTags}
      size="small"
      disableClearable={true}
      options={data}
      isOptionEqualToValue={(option: PeriodProps, value: PeriodProps) =>
        option.value === value.value
      }
      loading={isLoading}
      loadingText={<CircularProgress />}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect={multiselect}
      onChange={(_e, value) => onChange(value)}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={option.value} {...optionProps}>
            {multiselect && <Checkbox key={key} checked={selected} />}
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ div: { maxHeight: '40px' } }}
          label={t('dashboard.filters.settlementPeriod')}
        />
      )}
      value={selectedPeriods}
    />
  );
};
