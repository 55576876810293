import {
  handleRemoveCommentsSavedOnClient,
  handleRemoveCommentsSavedOnServer,
} from '@e-flow/components/CommentModule/hooks/functions/HandleRemoveComment.ts';

import { UseRemoveEflowStepComment } from './useRemoveEflowStepComment.type';

export const useRemoveEflowStepComment = (
  hookProps: UseRemoveEflowStepComment,
) => {
  const removeComment = (props: {
    commentId?: string;
    commentIndex: number;
  }) => {
    if (props.commentId && hookProps.commentModels) {
      _removeCommentForCommentOnServer(props.commentId);
    }
    _removeCommentForCommentOnClient(props.commentIndex);
  };

  /**
   * Function used to set comments which are already sent to the server
   * as checked in the report
   */
  function _removeCommentForCommentOnServer(commentId: string) {
    const newEflowReportComments = handleRemoveCommentsSavedOnServer(
      commentId,
      hookProps.commentModels!,
    );

    hookProps.updateCommentModels(newEflowReportComments);
  }

  /**
   * Function used to set comments which are not sent to the server
   * as checked in the report
   */
  function _removeCommentForCommentOnClient(commentIndex: number) {
    const updatedClientComments = handleRemoveCommentsSavedOnClient(
      hookProps.currentComments,
      commentIndex,
    );

    hookProps.onFlowStepChange(updatedClientComments);
  }

  return {
    removeComment,
  };
};
