/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/styles';

import { Button } from '@core/components';
import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context.tsx';

export const EmptyDashboard = () => {
  const { t } = useTranslation('dashboard');

  const { addOnEmpty } = useDashboardContext();

  return (
    <Container>
      <ContentContainer>
        <Typography variant={'h3'}>{t('empty.title')}</Typography>
        <Typography>{t('empty.message')}</Typography>

        <Button
          sx={{
            maxWidth: 200,
          }}
          onClick={addOnEmpty}
        >
          {t('empty.button')}
        </Button>
      </ContentContainer>
    </Container>
  );
};

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: 300,
  gap: 10,
});
