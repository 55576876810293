import { useEffect, useState } from 'react';

import { SelectedUnitType } from '@kpi/components/UnitSelect/UnitSelect.types.ts';
import { useFetchOrganizationUnits } from '@kpi/components/UnitSelect/useFetchOrganizationUnits';
import { useHandleAddKpiRateContext } from '@kpi/modals/AddKpiRate/context';

export const useUnit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateNewUnitActive, setIsCreateNewUnitActive] = useState(false);
  const [defaultValue, setDefaultValue] = useState<
    SelectedUnitType | undefined
  >();
  const { data } = useFetchOrganizationUnits();
  const {
    useManageKpiModalState: { setValue, getValues },
  } = useHandleAddKpiRateContext();

  useEffect(() => {
    setIsLoading(data !== undefined);
    if (
      data &&
      (getValues('unit').unitName || getValues('unit').unitShortcut)
    ) {
      const defaultUnit = data.find(
        (unit) =>
          unit.unitName === getValues('unit').unitName ||
          unit.unitShortcut === getValues('unit').unitShortcut,
      );
      setDefaultValue(defaultUnit);
      setIsCreateNewUnitActive(!defaultUnit);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSwitchChange = () => {
    setIsCreateNewUnitActive((prev) => {
      if (!prev) {
        setValue('unit.unitName', '');
        setValue('unit.unitShortcut', '');
        setDefaultValue(undefined);
      }
      return !prev;
    });
  };

  const setNewUnitFromSelect = (unit: SelectedUnitType) => {
    setValue('unit.unitName', unit.unitName);
    setValue('unit.unitShortcut', unit.unitShortcut);
  };

  return {
    isCreateNewUnitActive,
    setIsCreateNewUnitActive,
    handleSwitchChange,
    setNewUnitFromSelect,
    defaultValue,
    isLoading,
  };
};
