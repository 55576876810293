import { Route, Routes } from 'react-router-dom';

import { KaizenLayout } from '@kaizen/layouts/KaizenLayout.tsx';
import {
  AddSubmission,
  KaizenReport,
  Ranking,
  UpdateSubmission,
} from '@kaizen/pages';

import { OrganizationModules, SystemRoles } from '@/__generated__/graphql.ts';
import { ProtectedRoute } from '@/router';

import { Dashboard, MainSubmissionDashboard } from '../pages';

export const KaizenRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            allowedGroups={[
              SystemRoles.Root,
              SystemRoles.SystemAdmin,
              SystemRoles.KeyUser,
              SystemRoles.Administrator,
              SystemRoles.KaizenMember,
              SystemRoles.KaizenCommisionMember,
              SystemRoles.KaizenCommisionLeader,
            ]}
            module={OrganizationModules.Kaizen}
          />
        }
      >
        <Route element={<KaizenLayout />}>
          <Route path="submissions" element={<Dashboard />} />
          <Route path="ranking" element={<Ranking />} />
          <Route path="submissions" element={<Dashboard />} />
          <Route
            path={'submissions/:submissionId'}
            index
            element={<MainSubmissionDashboard />}
          />
          <Route path={'report'} element={<KaizenReport />} />
        </Route>
        <Route path={'add-submission'} element={<AddSubmission />} />
        <Route
          path={'update-submission/:submissionId'}
          element={<UpdateSubmission />}
        />
      </Route>
    </Routes>
  );
};
