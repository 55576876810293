import { z } from 'zod';

/**
 * Email validation schema
 * @param emailRequired Required error message
 * @param emailInvalid Invalid error message
 * @returns Email validation schema
 * @example const emailValidationSchema = emailValidationSchema('Email is required', 'Email is invalid');
 */
export const emailValidationSchema = (
  emailRequired: string,
  emailInvalid: string,
) => z.string().min(1, emailRequired).email(emailInvalid);
