import { StatisticTableTypes } from '@e-flow/pages/flowReport/components/SybmolicMap/components/SymbolicRow/SybmbolicRow.types.ts';

import { Box, styled } from '@mui/material';

import { SymbolicCell } from '../SymbolicCell/SybmolicCell';

export const SymbolicRow = (props: StatisticTableTypes) => {
  return (
    <Container>
      <RowContainer>
        <SymbolicCell isLabel={true} />

        {props.operations.map((operation, index) => (
          <SymbolicCell key={index} {...operation} />
        ))}
      </RowContainer>
      <NumberContainer>
        <span>{props.startCount}</span>
        <span>{props.endCount}</span>
      </NumberContainer>
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const NumberContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const RowContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '10px 0',
});
