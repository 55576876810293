import { useEffect, useState } from 'react';

import { useDebounceValue } from 'usehooks-ts';

import { useTablePagination } from '@core/hooks';

import {
  EmployeeState,
  EmployeesListArgs,
  SystemRoles,
} from '@/__generated__/graphql';
import { TreeTarget } from '@/types';

import { RequestBuilder } from './RequestBuilder';
import { FetchEmployeesType } from './types';

/**
 * Fetches employees from the server based on the query you provide
 * @param organisationId - id of organisation
 * @param query - query to fetch employees
 * @returns everything? :D look at the return statement
 */
export const useFetchEmployees: FetchEmployeesType = (
  organisationId,
  query,
  possibleRoles = [],
) => {
  const { cursor, setCursor, rowsPerPage, setRowsPerPage } =
    useTablePagination();

  const [input, setInput] = useState<EmployeesListArgs>();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebounceSearch] = useDebounceValue(search, 500);
  const [organizationUnit, setOrganizationUnit] = useState<TreeTarget>({
    targetId: '',
    path: '',
  });
  const [position, setPosition] = useState<string | null>(null);
  const [systemRoles, setSystemRoles] = useState<SystemRoles[]>(possibleRoles);
  const [employeeState, setEmployeeState] = useState<EmployeeState[]>([]);

  const [handleQuery, { data, loading: isLoading }] = query;

  useEffect(() => {
    const requerstBuilder = new RequestBuilder();

    requerstBuilder.addDebounceSearch(debouncedSearch);
    requerstBuilder.addPosition([position]);
    requerstBuilder.addSystemRoles(systemRoles);
    requerstBuilder.addOrganizationUnit(organizationUnit);
    requerstBuilder.addEmployeeState(employeeState);

    const input = {
      organisationId,
      limit: rowsPerPage,
      cursor,
      ...requerstBuilder.build(),
    };
    setInput(input);

    void handleQuery({
      variables: {
        input,
      },
      pollInterval: 30000,
    });
    // eslint-disable-next-line
  }, [
    organisationId,
    cursor,
    rowsPerPage,
    debouncedSearch,
    position,
    organizationUnit.targetId,
    employeeState,
  ]);

  useEffect(() => {
    setCursor(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, position, organizationUnit.targetId, employeeState]);

  return {
    employees: data?.getEmployees.items || [],
    totalEmployees: data?.getEmployees.total || 0,
    search,
    setSearch: (value: string) => {
      setSearch(value);
      setDebounceSearch(value);
    },
    organizationUnit,
    setOrganizationUnit,
    position,
    setPosition,
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,
    employeeState,
    setEmployeeState,
    systemRoles,
    setSystemRoles,
    isLoading,
    input,
  };
};
