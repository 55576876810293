import { useTranslation } from 'react-i18next';

import {
  ArrowBackOutlined,
  Check,
  FileDownloadOutlined,
  SettingsOutlined,
  ShareOutlined,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, IconButton, Menu, styled } from '@mui/material';

import { EFlowStatuses } from '@/__generated__/graphql';
import { CustomMenuItem, Snackbar } from '@/core/components';
import { useFlowRedirects } from '@/core/redirects/useFlowRedirects';
import { useEflowPermissions, useHamburgerMenu } from '@/pages/e-flow/hooks';

import { NavbarHamburgerMenuProps } from './NavbarHamburgerMenu.types';

export const NavbarHamburgerMenu = (props: NavbarHamburgerMenuProps) => {
  const { t } = useTranslation('eflow');
  const { t: commonT } = useTranslation();

  const {
    redirectToFlowDashboard,
    redirectToSingleFlow,
    openSingleFlowSettings,
  } = useFlowRedirects();

  const {
    anchorEl,
    isMenuOpen,
    handleMenuClose,
    handleMenuOpen,
    isSetAsFinishedDisabled,
    isLastPathReport,
    setEflowStatusAsCompleted,
    isSnackBarOpen,
    snackBarMessage,
    closeSnackbar,
  } = useHamburgerMenu(props.status!);

  const { canModifyAnalize } = useEflowPermissions({
    eFlowId: props.eFlowId,
  });

  return (
    <>
      <NavbarMenu onClick={(e) => handleMenuOpen(e)}>
        <MenuIcon />
      </NavbarMenu>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        <CustomMenuItem
          onClick={
            isLastPathReport ? redirectToSingleFlow : redirectToFlowDashboard
          }
          icon={<ArrowBackOutlined />}
          text={
            isLastPathReport
              ? t('eflow.navbar.goBackToAnalysis')
              : t('eflow.navbar.goBackToAnalysisList')
          }
        />
        <Divider />
        <CustomMenuItem
          onClick={() => openSingleFlowSettings(true)}
          icon={<SettingsOutlined />}
          text={commonT('common.settings')}
          disabled={!canModifyAnalize}
        />
        <CustomMenuItem
          disabled
          icon={<ShareOutlined />}
          text={commonT('common.share')}
        />
        {isLastPathReport && props.handleExport && (
          <CustomMenuItem
            icon={<FileDownloadOutlined />}
            text={commonT('common.export')}
            onClick={() => props.handleExport!()}
          />
        )}
        <Divider />
        <CustomMenuItem
          disabled={
            isSetAsFinishedDisabled ||
            props.status === EFlowStatuses.Completed ||
            !canModifyAnalize
          }
          icon={<Check />}
          text={t('eflow.navbar.signAsCompleted')}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async (): Promise<void> => {
            await setEflowStatusAsCompleted();
          }}
        />
      </Menu>
      <Snackbar
        open={isSnackBarOpen}
        message={snackBarMessage}
        onClose={closeSnackbar}
      />
    </>
  );
};

const NavbarMenu = styled(IconButton)({
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 8,
});
