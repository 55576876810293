import { FC, useEffect, useMemo, useReducer } from 'react';

import { Stack, TextField } from '@mui/material';

import {
  StringedTime,
  TimeInputProps,
  TimeInputReducerType,
} from '@core/components/inputs/TimeInput/types';
import { useTimeFormat } from '@core/hooks';

export const TimeInput: FC<TimeInputProps> = ({
  format,
  onChange,
  value: propsValue,
  width = 104,
}) => {
  const timeFormat = useMemo(() => {
    const unitList = format.split('_');
    unitList.shift();
    return unitList;
  }, [format]);

  const { time: convertedTime, setTimeInSeconds } = useTimeFormat({
    inputTime: 0,
    timeFormat: format,
  });

  useEffect(() => {
    setTimeInSeconds(propsValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsValue]);

  useEffect(() => {
    dispatch({
      type: 'SET_TIME',
      payload: propsValue === undefined ? initialState : convertedTime,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedTime, propsValue]);

  const initialState: StringedTime = {
    hours: '',
    minutes: '',
    seconds: '',
  };

  const timeReducer = (
    state: StringedTime,
    action: TimeInputReducerType,
  ): StringedTime => {
    switch (action.type) {
      case 'HH':
      case 'H':
        return {
          ...state,
          hours: action.payload,
        };
      case 'MM':
      case 'MIN':
      case 'M':
        return {
          ...state,
          minutes: action.payload,
        };
      case 'SS':
      case 'S':
        return {
          ...state,
          seconds: action.payload,
        };
      case 'SET_TIME':
        return action.payload as unknown as StringedTime;
      default:
        return state;
    }
  };

  const [time, dispatch] = useReducer(timeReducer, initialState);

  return (
    <Stack flexDirection="row" gap="8px">
      {timeFormat.map((unit, index) => {
        return (
          <TextField
            inputMode={'decimal'}
            sx={{ width }}
            size="small"
            placeholder={unit}
            key={unit + index}
            value={
              unit === 'HH' || unit === 'H'
                ? time.hours
                : unit === 'MM' || unit === 'MIN' || unit === 'M'
                  ? time.minutes
                  : time.seconds
            }
            onChange={({ target: { value } }) => {
              value = value.replace(',', '.');
              if (!value.match(/^(\d*([.,])?\d{0,2}$)/)) return;
              dispatch({
                type: unit as 'HH' | 'MM' | 'SS' | 'H' | 'M' | 'S',
                payload: value as unknown as string,
              });
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                return;
              }

              let seconds = parseFloat(time.seconds);
              let minutes = parseFloat(time.minutes);
              let hours = parseFloat(time.hours);

              // Check if the parsed values are NaN and replace them with 0 if they are
              seconds = isNaN(seconds) ? 0 : seconds;
              minutes = isNaN(minutes) ? 0 : minutes;
              hours = isNaN(hours) ? 0 : hours;

              const calculatedTimeInSeconds =
                hours * 3600 + minutes * 60 + seconds;
              onChange(calculatedTimeInSeconds);
            }}
          />
        );
      })}
    </Stack>
  );
};
