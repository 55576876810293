import { RowStack } from '@organizations/styles';

import { OrganizationUnitSelect, UserSelect } from '@core/components';
import { useCompanyId, useGetCurrentUserDetails } from '@core/hooks';

import { useHandleAddKpiRateContext } from '../context';

export const OrganizationUnitAndEmployeeSection = () => {
  const {
    useManageKpiModalState: {
      errors,
      selectedOrganizationUnit,
      setOrganizationUnit,
      setReporterId,
      defaultOrganizationUnit,
      defaultReporter,
    },
  } = useHandleAddKpiRateContext();

  const { companyId } = useCompanyId();

  const { user } = useGetCurrentUserDetails();

  return (
    <RowStack gap="16px" alignItems={'flex-start'}>
      <OrganizationUnitSelect
        key={1}
        onChange={setOrganizationUnit}
        organizationUnitId={user?.organizationUnitGuid}
        error={!!errors.organizationUnitId}
        helperText={errors.organizationUnitId?.message}
        defaultValue={defaultOrganizationUnit && [defaultOrganizationUnit]}
      />

      <UserSelect
        width="232px"
        key={selectedOrganizationUnit}
        disabled={selectedOrganizationUnit === ''}
        defaultUser={defaultReporter}
        organizationId={companyId}
        onChange={setReporterId}
        organisationUnitIds={[selectedOrganizationUnit]}
        error={!!errors.reporterId}
        helperText={errors.reporterId?.message}
      />
    </RowStack>
  );
};
