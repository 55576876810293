import { omit } from 'lodash';

import { CircularProgress } from '@mui/material';

import { Button, DisabledButton, LightButton } from '@core/components';

import { LoadingButtonType } from './LoadingButton.types';

export const LoadingButton = (props: LoadingButtonType) => {
  if (props.isLoading)
    return (
      <DisabledButton
        {...omit(props, ['isLoading', 'synchronizeText'])}
        disabled={true}
      >
        <CircularProgress size={24} color={'info'} />
      </DisabledButton>
    );

  if (props.variantLight)
    return (
      <LightButton
        {...omit(props, ['isLoading', 'synchronizeText'])}
        disabled={props.disabled}
      >
        {props.children}
      </LightButton>
    );

  return (
    <Button
      {...omit(props, ['isLoading', 'synchronizeText'])}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};
