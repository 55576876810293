import { useEffect, useMemo, useState } from 'react';

import { useSettlementPeriod } from '@kpi/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod.tsx';
import dayjs from 'dayjs';

import { useGetCurrentUserDetails } from '@core/hooks';

import { DashboardTypesEnum } from '@/__generated__/graphql.ts';

export const useDashboardFilters = (dashboardType: DashboardTypesEnum) => {
  const { user } = useGetCurrentUserDetails();

  const [organizationUnit, setOrganizationUnit] = useState<string | undefined>(
    user?.organizationUnitGuid,
  );
  const [settlementPeriod, setSettlementPeriod] = useState<
    string | undefined
  >();
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
    dayjs().subtract(1, 'month').toDate().getMonth(),
  );

  const [employeeId, setEmployeeId] = useState<string | undefined>(user?.id);

  const { currentActivePeriod } = useSettlementPeriod();

  const [isFirstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (currentActivePeriod) {
      setSettlementPeriod(currentActivePeriod.id);
    }
  }, [currentActivePeriod]);

  useEffect(() => {
    if (user && isFirstTime) setFirstTime(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUnit]);

  const defaultOrganizationUnitData = useMemo(() => {
    if (user) {
      return [
        {
          id: user.organizationUnitGuid,
          name: user.organizationUnit.name,
        },
      ];
    }
  }, [user]);

  const isCreateChartButtonDisabled = useMemo(() => {
    if (!settlementPeriod) {
      return false;
    }

    switch (dashboardType) {
      case DashboardTypesEnum.KpiEmployeeDashboard:
        return !(employeeId && organizationUnit);
      case DashboardTypesEnum.KpiOrganizationUnitDashboard:
        return !organizationUnit;
      default:
        return false;
    }
  }, [organizationUnit, employeeId, dashboardType, settlementPeriod]);

  return {
    isFirstTime,
    employeeId,
    selectedMonth,
    organizationUnit,
    isCreateChartButtonDisabled,
    settlementPeriod,
    defaultOrganizationUnitData,
    setEmployeeId,
    setSelectedMonth,
    setOrganizationUnit,
    setSettlementPeriod,
  };
};
