import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { BackButtonProps } from '.';
import { Button } from '..';

export const BackButton: FC<BackButtonProps> = ({ href, label }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Button
      variant="text"
      onClick={() => navigate(`${href}`)}
      sx={{
        padding: 0,
      }}
      type="button"
    >
      <ArrowBack />
      <Typography color={theme.palette.text.primary} variant="bodyMedium">
        {label ? label : t('login.comeBack')}
      </Typography>
    </Button>
  );
};

const ArrowBack = styled(ArrowBackIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
