import { Stack } from '@mui/material';

import { ReportDataGrid, SettingSection } from './sections';
import { ButtonSection } from './sections/ButtonSection';

export const ReportModalContext = () => {
  return (
    <Stack gap="16px" padding={'0 24px 24px 24px '}>
      <SettingSection />
      <ReportDataGrid />
      <ButtonSection />
    </Stack>
  );
};
