import { CreateEmployeeInput } from '@/__generated__/graphql.ts';


/**
 * Default values for add employee modal
 */
export const AddEmployeeModalDefaultValues: CreateEmployeeInput = {
  organizationId: '',
  organizationUnitGuid: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '+48123123123',
  position: '',
};