import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { IconWrapper } from './Wrapper/IconWrapper';
import { IconType } from './Wrapper/type';

export const InfoIconWithTooltip = (props: IconType) => {
  return (
    <IconWrapper
      {...props.wrapperProps}
      icon={<InfoOutlinedIcon sx={{}} {...props.iconProps} />}
    />
  );
};
