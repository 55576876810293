import { SingleNotification } from '@core/modules/Notifications/components/SingleNotification.tsx';
import { NoNotifications } from '@core/modules/Notifications/components/Tabs/Empty.tsx';
import { useNotificationsContext } from '@core/modules/Notifications/context/NotificationsContext.tsx';
import { NotificationType } from '@core/modules/Notifications/types/Notifications.types.ts';

export const NewNotificationTab = () => {
  const { unOpenedNotifications } = useNotificationsContext();

  if (!unOpenedNotifications.length) {
    return <NoNotifications />;
  }

  return unOpenedNotifications.map((notification: NotificationType) => (
    <SingleNotification key={notification.id} {...notification} />
  ));
};
