import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';
import { PrimaryButton } from '@e-schema/styles/Employee';
import {
  Node,
  findAndAddChild,
  findAndChangeCell,
  findAndDeleteChild,
  findAndReplaceChild,
} from '@organizations/logic';
import { NoStructure } from '@organizations/pages/organizationSettings';
import RenderTreeItem from '@organizations/pages/organizationSettings/content/OrganizationTree.tsx';
import _ from 'lodash';

import {
  Box,
  CircularProgress,
  Modal,
  Snackbar,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';

import {
  GetCheckboxOrganizationUnitStructureDocument,
  GetCheckboxOrganizationUnitStructureQuery,
  useGetCheckboxOrganizationUnitStructureQuery,
} from '@/__generated__/graphql';
import { FlexCenter } from '@/core/styles';
import { useOrganizationStore } from '@/stores';
import { Unpacked } from '@/types/unpacked';

import { AddCellModalWithUnit } from '../AddCellModal/AddCellModalWithUnit';

export const OrganizationStructure = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { companyName } = useParams();

  const { organization } = useOrganizationStore();

  const [organizationUnit, setOrganizationUnit] = useState<
    | GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure']
    | undefined
  >(undefined);

  const [isAddCellModalVisible, setIsAddCellModalVisible] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { cache } = useApolloClient();

  const closeAddCellModal = () => {
    setIsAddCellModalVisible(false);
  };

  const openSnackbar = (message: string) => {
    setIsSnackbarOpen(true);
    setSnackbarMessage(message);
  };
  const {
    data,
    loading: isLoading,
    error,
  } = useGetCheckboxOrganizationUnitStructureQuery({
    variables: {
      input: {
        id: organization[companyName!],
      },
    },
  });

  useEffect(() => {
    if (data?.getOrganizationUnitsStructure)
      setOrganizationUnit(_.cloneDeep(data.getOrganizationUnitsStructure));
  }, [data]);

  const setNewCache = (newTree: Node | null) => {
    cache.writeQuery({
      query: GetCheckboxOrganizationUnitStructureDocument,
      variables: {
        input: {
          id: organization[companyName!],
        },
      },
      data: {
        getOrganizationUnitsStructure: newTree,
      },
    });
  };

  const handleOnUnitAdded = useCallback(
    (
      unit: Unpacked<
        GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure']
      >,
    ) => {
      if (!unit || !organizationUnit) return;
      const copyOrganizationUnit = {
        ...organizationUnit,
      };

      const newTree = findAndAddChild(copyOrganizationUnit, unit);

      setNewCache(newTree);
      !!newTree && setOrganizationUnit(newTree);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationUnit],
  );

  const handleAddFirstUnit = (
    unit: Unpacked<
      GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure']
    >,
  ) => {
    setNewCache({ ...unit });
    unit && setOrganizationUnit({ ...unit });
  };

  const handleOnEditUnit = useCallback(
    (
      unit: Unpacked<
        GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure']
      >,
    ) => {
      if (!unit || !organizationUnit) return;
      const copyOrganizationUnit = {
        ...organizationUnit,
      };
      const newTree = findAndReplaceChild(copyOrganizationUnit, unit);
      setNewCache(newTree);
      !!newTree && setOrganizationUnit(newTree);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationUnit],
  );

  const handleOnChangeUnit = useCallback(
    (
      unit: Unpacked<
        GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure']
      >,
    ) => {
      if (!unit || !organizationUnit) return;
      const copyOrganizationUnit = {
        ...organizationUnit,
      };
      const newTree = findAndChangeCell(copyOrganizationUnit, unit);
      setNewCache(newTree);

      !!newTree && setOrganizationUnit(newTree);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationUnit],
  );

  const handleOnUnitDeleted = useCallback(
    (id: string) => {
      if (!organizationUnit) return;
      const copyOrganizationUnit = {
        ...organizationUnit,
      };
      const newTree = findAndDeleteChild(copyOrganizationUnit, id);
      setNewCache(newTree);
      !!newTree && setOrganizationUnit(newTree);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationUnit],
  );

  return (
    <CustomBox>
      {isLoading ? (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      ) : error && !error?.message.includes('EMPTY_ORGANIZATION') ? (
        <Typography>{t('error.noResult')}</Typography>
      ) : !organizationUnit ? (
        <NoStructure handleAddUnit={handleAddFirstUnit} />
      ) : (
        <>
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            height="56px"
          >
            <PrimaryButton
              variant="contained"
              onClick={() => setIsAddCellModalVisible(true)}
              type={'submit'}
              sx={{
                backgroundColor: theme.palette.primary[600],
              }}
            >
              <Typography variant="bodyRegular">
                {t('organizationSettings.addNewCell')}
              </Typography>
            </PrimaryButton>
          </Stack>
          <MainTreeView
            aria-label="rich object"
            style={{
              height: 'calc(100% - 100.5px)',
              width: '100%',
              overflow: 'auto',
            }}
          >
            {organizationUnit && (
              <RenderTreeItem
                handleUnitAdded={handleOnUnitAdded}
                handleUnitDeleted={handleOnUnitDeleted}
                nodes={organizationUnit}
                handleUnitEdited={handleOnEditUnit}
                handleUnitChanged={handleOnChangeUnit}
              />
            )}
          </MainTreeView>
        </>
      )}
      <Modal open={isAddCellModalVisible}>
        <AddCellModalWithUnit
          headerText={t('systemSettings.addOrganizationUnit')}
          closeModal={closeAddCellModal}
          openSnackbar={openSnackbar}
          onUnitAdded={(unit) => handleOnUnitAdded(unit)}
        />
      </Modal>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => {
          setIsSnackbarOpen(false);
        }}
        message={snackbarMessage}
      />
    </CustomBox>
  );
};

const CustomBox = styled(Box)({
  padding: '16px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const MainTreeView = styled(SimpleTreeView)({
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  color: 'var(--text-text-secondary, #333)',
  textOverflow: 'ellipsis',
});
