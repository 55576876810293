import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { SettingsListItemButton } from '@organizations/components/ListItemButton/ListItemButton.tsx';
import { RowStack } from '@organizations/styles';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  Skeleton,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import {
  OrganizationModules,
  useFindSingleOrganizationQuery,
} from '@/__generated__/graphql';
import { EmployeeAvatar } from '@/core/components';
import { useGlobalRedirects } from '@/core/redirects';
import {
  FlexCenter,
  NavbarMainContainer,
  NavbarTitle,
  NavbarUserMenu,
} from '@/core/styles';

export const OrganizationSettings = () => {
  const { t } = useTranslation();
  const { modulesDashboardPath } = useGlobalRedirects();
  const [searchparams] = useSearchParams();
  const { companyName } = useParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);
  const [backPath, setBackPath] = useState(modulesDashboardPath);

  const [organizationName, setOrganizationName] = useState<string>();
  const [logo, setLogo] = useState<string>();
  const [modules, setModules] = useState<OrganizationModules[]>();

  const { data, loading: isLoading } = useFindSingleOrganizationQuery({
    variables: {
      input: {
        url: companyName,
      },
    },
  });

  useEffect(() => {
    if (data?.findSingleOrganization.name) {
      setOrganizationName(data.findSingleOrganization.name);
      setLogo((data?.findSingleOrganization?.logo as string) || undefined);
      setModules(data?.findSingleOrganization?.modules);
    }
  }, [data]);

  useEffect(() => {
    const backPath = searchparams.get('backPath');
    if (backPath != null) setBackPath(backPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <NavbarMainContainer height="56px">
        <NavbarUserMenu
          sx={{
            marginLeft: '0',
          }}
        >
          <IconButton
            onClick={() => {
              navigate(backPath);
            }}
          >
            <CloseIcon />
          </IconButton>

          <NavbarTitle>
            <Typography variant="bodyRegular">
              {t('organizationSettings.organizationSettings')}
            </Typography>
          </NavbarTitle>
        </NavbarUserMenu>
      </NavbarMainContainer>
      <Stack
        display="flex"
        flexDirection="row"
        padding="0 24px 0 0 "
        gap={'24px'}
      >
        <SideBox>
          <RowStack paddingLeft="16px" gap="5px">
            <EmployeeAvatar
              name={organizationName || ''}
              imageUrl={logo}
              size={32}
            />
            {isLoading ? (
              <Skeleton width={100} />
            ) : (
              <Typography variant="h4">{organizationName}</Typography>
            )}
          </RowStack>
          <List sx={{ width: '312px' }}>
            <SettingsListItemButton
              organizationModule={'data'}
              organizationModules={modules as OrganizationModules[]}
            />
            <SettingsListItemButton
              organizationModule={'global-permissions'}
              organizationModules={modules as OrganizationModules[]}
            />

            <CustomListItemButton
              onClick={() => {
                navigate(`modules`);
              }}
            >
              <Typography variant="bodyRegular">
                {t('organization.modules')}
              </Typography>
              <FlexCenter
                onClick={() => setIsOpen(!isOpen)}
                sx={{ width: 'auto' }}
              >
                {!isOpen ? (
                  <ExpandLess sx={{ rotate: '-90deg' }} fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </FlexCenter>
            </CustomListItemButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <SettingsListItemButton
                  organizationModule={OrganizationModules.ESchema}
                  organizationModules={modules as OrganizationModules[]}
                />
                <SettingsListItemButton
                  organizationModule={OrganizationModules.EFlowAnalize}
                  organizationModules={modules as OrganizationModules[]}
                />
                <SettingsListItemButton
                  organizationModule={OrganizationModules.Kaizen}
                  organizationModules={modules as OrganizationModules[]}
                />
                <SettingsListItemButton
                  organizationModule={OrganizationModules.Kpi}
                  organizationModules={modules as OrganizationModules[]}
                />
              </List>
            </Collapse>
          </List>
        </SideBox>
        <Stack padding="32px 0" gap="32px" width={'100%'}>
          <Outlet />
        </Stack>
      </Stack>
    </Container>
  );
};

const Container = styled(Stack)({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 5,
  backgroundColor: 'white',
});
const SideBox = styled(Stack)({
  width: '360px',
  height: 'calc(100vh - 56px)',
  borderRight: '1px solid var(--neutrals-gray-200, #dcdfe1)',
  padding: '32px 24px',
  gap: '32px',
});

const CustomListItemButton = styled(ListItemButton)({
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});
