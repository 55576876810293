import { DocumentNode } from 'graphql/language';

import {
  AsIsToBeNamesEnum,
  FindEflowToBeStepsDocument,
  GetEflowAsIsStepsDocument,
} from '@/__generated__/graphql.ts';

export const useGetDocumentType = () => {
  const getRecordStepType = (analizeType: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    return analizeType === AsIsToBeNamesEnum.AsIs ? 'asIsSteps' : 'toBeSteps';
  };

  const getRecordState = (
    analizeType: AsIsToBeNamesEnum,
  ): 'asIsState' | 'toBeState' => {
    return analizeType === AsIsToBeNamesEnum.AsIs ? 'asIsState' : 'toBeState';
  };

  const getDocumentType = (analizeType: AsIsToBeNamesEnum): DocumentNode => {
    return analizeType === AsIsToBeNamesEnum.AsIs
      ? GetEflowAsIsStepsDocument
      : FindEflowToBeStepsDocument;
  };

  return {
    getRecordStepType,
    getDocumentType,
    getRecordState,
  };
};
