/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { RowStack } from '@organizations/styles';
import { useDebounceValue } from 'usehooks-ts';

import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import {
  GetEmployeesWithOrganizationUnitQuery,
  SystemRoles,
  useGetEmployeesWithOrganizationUnitLazyQuery,
  useGetOrganizationUnitChildrenNameQuery,
} from '@/__generated__/graphql';
import { CustomSnackBar } from '@/core/components';
import { useTablePagination } from '@/core/hooks';
import { FlexCenter } from '@/core/styles';
import { useOrganizationStore } from '@/stores';
import { theme } from '@/theme';

import { AddUnitAdminModal } from './AddUnitAdminModal';
import { DeleteUnitAdminModal } from './DeleteUnitAdminModal';
import { UnitAdminTable } from './UnitAdminTable';
import { CellI, UnitEmployee } from './types';

export const UnitAdmin = () => {
  const { t } = useTranslation();
  const { organization } = useOrganizationStore();
  const { companyName } = useParams();
  const { cursor, setCursor, rowsPerPage, setRowsPerPage } =
    useTablePagination();

  const [cells, setCells] = useState<CellI[]>([]);
  const [rows, setRows] = useState<
    GetEmployeesWithOrganizationUnitQuery['getEmployees']['items']
  >([]);
  const [selectedRow, setSelectedRow] = useState<string>('');
  const [search, setSearch] = useState('');
  const [organizationUnitId, setOrganizationUnitId] = useState<string | null>(
    null,
  );
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState('');

  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search, 500);

  const getNames = (child: any, names: CellI[]) => {
    names.push({
      id: child.id as string,
      label: child.name as string,
    });
    if (!child.children) return;
    child.children.forEach((child: any) => {
      getNames(child, names);
    });
  };

  const removeUser = (id: string) => {
    setRows((currentEmployees) =>
      currentEmployees.filter((employee) => employee.id != id),
    );
  };

  const addUser = (employee: UnitEmployee) => {
    setRows((row) => [...row, employee]);
  };

  const setSnackBar = (message: string) => {
    setIsShowSnackbar(true);
    setSnackBarMessage(message);
  };

  const showModal = (id: string) => {
    setSelectedRow(id);
    setIsDeleteModalVisible(true);
  };

  const [query, { data, loading: isLoading }] =
    useGetEmployeesWithOrganizationUnitLazyQuery();

  const { data: cellData } = useGetOrganizationUnitChildrenNameQuery({
    variables: {
      getOrganizationUnitInput: {
        id: organization[companyName!],
      },
    },
  });

  useEffect(() => {
    void query({
      variables: {
        getEmployeesInput: {
          limit: rowsPerPage,
          cursor,
          organisationId: organization[companyName!],
          ...(organizationUnitId && {
            organisationUnitIds: [organizationUnitId],
          }),
          systemRoles: [SystemRoles.UnitAdmin],
          ...(search && { search }),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, query, rowsPerPage, debouncedSearch, organizationUnitId]);

  useEffect(() => {
    if (data) {
      setRows(data.getEmployees.items || []);
    }
  }, [data]);

  useEffect(() => {
    if (cellData) {
      const names: CellI[] = [
        {
          id: cellData.getOrganizationUnitsStructure.id,
          label: cellData.getOrganizationUnitsStructure.name,
        },
      ];
      cellData.getOrganizationUnitsStructure.children.forEach((child: any) => {
        getNames(child, names);
      });
      setCells(names);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellData]);

  return (
    <>
      <CustomStack gap={'24px'}>
        <RowStack justifyContent={'space-between'}>
          <RowStack gap="16px">
            <FormControl sx={{ width: 328 }} size="small">
              <TextField
                size="small"
                placeholder={t('organization.search')}
                onChange={(event) => {
                  setSearch(event.target.value);
                  setDebouncedSearch(event.target.value);
                  setCursor(0);
                }}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Autocomplete
              size="small"
              options={cells}
              sx={{ minWidth: '190px' }}
              onChange={(_e, value) => {
                setOrganizationUnitId(value != null ? value.id : null);
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('organizationSettings.unitAdminSection.cell')}
                />
              )}
            />
          </RowStack>
          <PrimaryButton
            variant="contained"
            onClick={() => setIsAddModalVisible(true)}
            sx={{
              backgroundColor: theme.palette.primary[600],
            }}
          >
            <Typography variant="bodyMedium" fontSize={'14px'} width={'71px'}>
              {t('common.add')}
            </Typography>
          </PrimaryButton>
        </RowStack>
        {isLoading ? (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        ) : (
          <UnitAdminTable
            showModal={showModal}
            rows={rows}
            total={data?.getEmployees.total || 0}
            cursor={cursor}
            setCursor={(newValue) => setCursor(newValue)}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(newValue) => setRowsPerPage(newValue)}
          />
        )}
      </CustomStack>
      {isDeleteModalVisible ? (
        <DeleteUnitAdminModal
          open={isDeleteModalVisible}
          row={[...rows].find((row) => row.id == selectedRow)!}
          closeModal={() => setIsDeleteModalVisible(false)}
          removeUser={removeUser}
          setSnackBar={setSnackBar}
        />
      ) : null}
      {isAddModalVisible ? (
        <AddUnitAdminModal
          open={isAddModalVisible}
          closeModal={() => setIsAddModalVisible(false)}
          setSnackBar={setSnackBar}
          addUser={addUser}
        />
      ) : null}

      <CustomSnackBar
        open={isShowSnackbar}
        close={() => setIsShowSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
};
const CustomStack = styled(Stack)({
  padding: '0px 24px 0px 24px',
  margin: '16px',
  marginTop: '24px',
});
