import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import UploadIcon from '@mui/icons-material/Upload';
import { Alert, AlertColor, Snackbar, Typography, styled } from '@mui/material';

import {
  allowedExtensions as allowedDefaultImages,
  maxFileSizeInBytes,
} from '@/core/constants';
import { theme } from '@/theme';

import { IResultMessage } from '..';
import { FilesDropDownInputProps } from './FilesDropDownInput.types';

export const FilesDropDownInput = ({
  onChange,
  allowedExtensions = allowedDefaultImages,
}: FilesDropDownInputProps) => {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [uploadResultMessage, setUploadResultMessage] =
    useState<IResultMessage>({
      message: undefined,
      type: undefined,
    });
  const [communicat, setCommunicat] = useState(
    t('filesDropDownInput.communicats.base'),
  );

  useEffect(() => {
    window.ondragover = function (e) {
      setCommunicat(t('filesDropDownInput.communicats.drop'));
      e.preventDefault();
      e.stopPropagation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSnackbar = (message: string, type: AlertColor | undefined) => {
    setUploadResultMessage({ message, type });
    setIsSnackbarOpen(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    setCommunicat(t('filesDropDownInput.communicats.dropHere'));
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragover = (e: React.DragEvent<HTMLDivElement>) => {
    setCommunicat(t('filesDropDownInput.communicats.drop'));
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragDrop = (e: React.DragEvent<HTMLDivElement>) => {
    setCommunicat(t('filesDropDownInput.communicats.base'));
    e.stopPropagation();
    e.preventDefault();
    handleFilesUpload(e.dataTransfer.files);
  };

  const handleOpenFileDialog = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFilesUpload = (files: FileList | null) => {
    if (!files || files.length < 0) return;

    const filesArray: File[] = [];

    if (files) {
      for (let i = 0; i < files.length; i++)
        if (files[i]) {
          const fileNameParts = files[i].name.split('.');
          const fileExtension =
            fileNameParts[fileNameParts.length - 1].toLowerCase();

          if (!allowedExtensions.includes(fileExtension)) {
            updateSnackbar(t('imageUpload.selectValidFile'), 'error');
          } else if (files[i].size > maxFileSizeInBytes) {
            updateSnackbar(t('imageUpload.fileSizeExceedsLimit'), 'error');
          } else {
            filesArray.push(files[i]);
          }
        }
      const res = onChange(filesArray);
      if (res) updateSnackbar(res, 'error');
      else updateSnackbar(t('imageUpload.fileUploadedSuccess'), 'success');
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        multiple
        type="file"
        style={{ display: 'none' }}
        onInput={(e) => {
          handleFilesUpload(e.currentTarget.files);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }}
      />
      <InputContainer
        onDragLeave={(e) => handleDragLeave(e)}
        onDragOver={(e) => handleDragover(e)}
        onDrop={(e) => handleDragDrop(e)}
        onClick={handleOpenFileDialog}
        onKeyDown={handleOpenFileDialog}
      >
        <UploadIcon />
        <Typography variant="bodyRegular">{communicat}</Typography>
      </InputContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={uploadResultMessage.type}
          sx={{ width: '100%' }}
        >
          {uploadResultMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const InputContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: '8px',
  padding: '32px 24px 32px 24px',
  border: '2px dashed #ccc',
  color: theme.palette.grey[400],
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'all 0.3s',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    border: '2px dashed #000',
    color: theme.palette.text.primary,
  },
});
