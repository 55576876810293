import { useTranslation } from 'react-i18next';

import { StatusLabel } from '../../../StatusLabel';
import { SubmissionDetailsProps } from '../../SubmissionDetails.types';
import { DetailRow, DetailRowLabel } from '../styles/styles';

export const SubmissionStatusSection = (props: SubmissionDetailsProps) => {
  const { t } = useTranslation('kaizen');
  return (
    <DetailRow>
      <DetailRowLabel variant="subtitle1">
        {t('submissionDetails.status')}
      </DetailRowLabel>
      <StatusLabel variant={props.status} />
    </DetailRow>
  );
};
