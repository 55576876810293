import { useTranslation } from 'react-i18next';

import { FormControlLabel, Switch, Typography } from '@mui/material';

import { ParameterTypes } from '@/__generated__/graphql';
import { SystemParameterKeysEnum } from '@/pages/e-flow/pages/newAnalysis/new-analysis-modules/ParameterModule/SystemParameterKeys.enum';

import { ParametersSectionProps } from './TableDisplayMenu.types';

export const ParametersSection = (props: ParametersSectionProps) => {
  const { t } = useTranslation('eflow');

  return (
    <>
      {props.parameters?.map((parameter) => {
        const systemParam =
          parameter.type === ParameterTypes.System
            ? t(
                `newAnalysis.parameters.systemParams.${parameter.name as SystemParameterKeysEnum}`,
              )
            : parameter.name;

        const paramUnit =
          parameter.type === ParameterTypes.System
            ? t(`newAnalysis.parameters.params.${parameter.unit}`)
            : parameter.name;
        return (
          <FormControlLabel
            control={
              <Switch
                checked={props.parametersOptions[parameter.name]}
                onChange={(e) => {
                  props.setParametersOptions({
                    ...props.parametersOptions,
                    [parameter.name]: e.target.checked,
                  });
                }}
              />
            }
            label={
              <Typography variant="bodyRegular">
                {paramUnit ? `${systemParam} - ${paramUnit}` : systemParam}
              </Typography>
            }
            key={parameter.name}
          />
        );
      })}
    </>
  );
};
