import { useState } from 'react';

import { AlertSnackbarType } from '@core/components/Snackbars/AlertSnackbar/AlertSnackbar.types.ts';

export const useAlertSnackbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] =
    useState<AlertSnackbarType['severity']>('success');

  const open = (severity: AlertSnackbarType['severity'], message: string) => {
    setIsOpen(true);
    setMessage(message);
    setSeverity(severity);
  };

  const close = () => {
    setIsOpen(false);
    setMessage('');
    setSeverity('success');
  };

  return {
    open,
    close,
    isOpen,
    message,
    severity,
  };
};
