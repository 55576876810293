import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';

import { Stack, Typography, useTheme } from '@mui/material';

import { ModalContainer } from '@/core/components';
import { FilterTreeView } from '@/pages/e-schema/components';

import type { CellModalProps } from './CellModal.types';

export const CellModal = ({
  handleConfirm,
  handleSelect,
  handleCloseModal,
}: CellModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ModalContainer
      width={'60%'}
      height={'440px'}
      headerText={t('employees.selectCell')}
      closeModal={handleCloseModal}
    >
      <Stack padding="0 24px 0 24px" height={'280px'} gap="24px">
        <FilterTreeView handleSelect={handleSelect} />
      </Stack>
      <Stack
        padding="16px 24px 24px 24px"
        justifyContent="flex-end"
        gap="12px"
        direction="row"
      >
        <PrimaryButton onClick={handleCloseModal}>
          <Typography>{t('common.cancel')}</Typography>
        </PrimaryButton>
        <PrimaryButton
          data-testid="confirmButtonModal"
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary[600],
          }}
          onClick={handleConfirm}
        >
          <Typography>{t('common.confirm')}</Typography>
        </PrimaryButton>
      </Stack>
    </ModalContainer>
  );
};
