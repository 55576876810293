import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { useCanAddSubmission } from '@kaizen/hooks';

import { Box } from '@mui/material';

import { NoDataInfo } from '@core/components';
import { useKaizenRedirects } from '@core/redirects';

export const NoSubmissionView = () => {
  const { t: kaizenT } = useTranslation('kaizen');

  const { navigateToAddSubmission } = useKaizenRedirects();

  const { canAddSubmission } = useCanAddSubmission();

  return (
    <Container>
      <NoDataInfo
        onClick={navigateToAddSubmission}
        headerText={kaizenT('dashboard.noDataInfo.noSubmissions')}
        infoText={kaizenT('dashboard.noDataInfo.noSubmissionsInOrganization')}
        buttonText={kaizenT('dashboard.addSubmission')}
        withButton={canAddSubmission}
      />
    </Container>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '50px',
});
