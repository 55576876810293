import { useEffect } from 'react';

import { CachePhotosTypes } from './CachePhotos.types';

export const CachePhotos = (props: CachePhotosTypes) => {
  const cacheImages = async (srcArray: string[]) => {
    const promises = srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    await Promise.all(promises);
  };

  useEffect(() => {
    void cacheImages(props.imageUrls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
