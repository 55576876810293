import { SyncStoreType } from '@e-flow/hooks/useSynchronizedFlows';
import { PrepareRecordForSynchronization } from '@e-flow/hooks/useSynchronizedFlows/Functions/PrepareRecordForSynchronization.ts';
import { IDBPObjectStore } from 'idb';

import { IdbHandler } from '@core/hooks/useIndexDB/Handlers/IDBHandler.ts';

import { AsIsToBeNamesEnum } from '@/__generated__/graphql.ts';

export class RecordReader {
  private readonly _flowId: string;
  private readonly _syncStoreValue: SyncStoreType;

  constructor(_flowId: string, _syncStoreValue: SyncStoreType) {
    this._flowId = _flowId;
    this._syncStoreValue = _syncStoreValue;
  }

  public async readEflowSteps() {
    const returnValue: {
      asIs: Awaited<ReturnType<typeof PrepareRecordForSynchronization>>[];
      toBe: Awaited<ReturnType<typeof PrepareRecordForSynchronization>>[];
    } = { asIs: [], toBe: [] };

    const openedDatabase = await this._openDatabase();

    if (!openedDatabase) {
      return returnValue;
    }

    const { asIs, toBe } = this._groupAsIsToBe(this._syncStoreValue);

    if (Object.keys(asIs).length !== 0) {
      returnValue.asIs =
        (await this._readEflowRecodsFromDatabase(
          AsIsToBeNamesEnum.AsIs,
          openedDatabase as unknown as IDBDatabase,
          asIs,
        )) || [];
    }

    if (Object.keys(toBe).length !== 0) {
      returnValue.toBe =
        (await this._readEflowRecodsFromDatabase(
          AsIsToBeNamesEnum.ToBe,
          openedDatabase as unknown as IDBDatabase,
          toBe,
        )) || [];
    }

    return returnValue;
  }

  private _groupAsIsToBe = (values: SyncStoreType) => {
    const asIs: SyncStoreType = {};
    const toBe: SyncStoreType = {};

    for (const [key, value] of Object.entries(values)) {
      if (value.analizeType === AsIsToBeNamesEnum.AsIs) {
        asIs[key] = value;
      } else if (value.analizeType === AsIsToBeNamesEnum.ToBe) {
        toBe[key] = value;
      }
    }

    return {
      asIs,
      toBe,
    };
  };

  private async _openDatabase() {
    return IdbHandler.crateNoStoreConnection(this._flowId);
  }

  private async _readEflowRecodsFromDatabase(
    analizeType: AsIsToBeNamesEnum,
    openedDatabase: IDBDatabase,
    steps: SyncStoreType,
  ) {
    if (!openedDatabase) {
      return;
    }

    const tx = openedDatabase.transaction(analizeType, 'readonly');
    const store = tx.objectStore(analizeType);

    if (!store) {
      return;
    }

    return (
      (await Promise.all([
        ...Object.entries(steps).map(async ([eFlowStepId, entry]) => {
          return PrepareRecordForSynchronization(
            eFlowStepId,
            store as unknown as IDBPObjectStore,
             
            entry.actionType,
          );
        }),
      ])) || []
    );
  }
}
