import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useControls } from 'react-zoom-pan-pinch';

import { buildSelectedTree } from '@core/utils';

import { useGetOrganizationUnitStructureLazyQuery } from '@/__generated__/graphql';
import { getTreeWithSortedMembers } from '@/core/utils/getTreeWithSortedMembers/getTreeWithSortedMembers';
import { useOrganizationStore } from '@/stores';
import { NodeId } from '@/types';

import { OrganizationNode } from '../../components/CheckboxTree/types';
import { TreeDetailsInterface } from './OrganizationTree.types';

export const useTransformContent = (
  details: TreeDetailsInterface,
  selectedNodes: NodeId[],
) => {
  const { centerView } = useControls();
  const { t } = useTranslation();

  const { organization } = useOrganizationStore();

  const { companyName } = useParams();

  const [organizationUnits, setOrganizationUnits] =
    useState<OrganizationNode | null>(null);

  const [query, { data, loading: isLoading, error }] =
    useGetOrganizationUnitStructureLazyQuery();

  useEffect(() => {
    void (async () => {
      await query({
        variables: {
          getOrganizationUnitInput: {
            id: organization[companyName!],
          },
        },
      });
    })();
    // eslint-disable-next-line
  }, []);

  const units = useMemo(() => {
    return data?.getOrganizationUnitsStructure
      ? getTreeWithSortedMembers(
          data.getOrganizationUnitsStructure as unknown as OrganizationNode,
        )
      : null;
  }, [data]);

  useEffect(() => {
    !!units &&
      setOrganizationUnits(
        buildSelectedTree(units, selectedNodes) as unknown as OrganizationNode,
      );
  }, [units, selectedNodes]);

  useEffect(() => {
    centerView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, isLoading]);

  useEffect(() => {
    if (organizationUnits) centerView(0.1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUnits]);

  return {
    t,
    organizationUnits,
    isLoading,
    error,
    centerView,
  };
};
