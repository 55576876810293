import { captureException } from '@sentry/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { updatePassword } from 'aws-amplify/auth';

import { Stack } from '@mui/material';

import { Button, PasswordTextField } from '@/core/components';
import { ISectionProps } from '@/core/components/UserEdit/PasswordSection/ChangePasswordForm/ChangePasswordForm.types.ts';
import { PasswordSectionSchema } from '@/core/components/UserEdit/PasswordSection/PasswordSectionSchema.ts';
import { Row, TypographyLabel } from '@/core/components/UserEdit/style.ts';
import { IPasswords, UserEditTypes } from '@/core/types';
import { theme } from '@/theme/index.ts';

import { ChangePasswordFormDefaultValues } from './ChangePasswiordFormDefaultValues.ts';

export const ChangePasswordForm = (props: ISectionProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm({
    defaultValues: ChangePasswordFormDefaultValues,
    resolver: zodResolver(PasswordSectionSchema(t)),
  });

  /**
   * Handle edit password via cognito action
   * @param data
   */
  const handleEditPassword: SubmitHandler<IPasswords> = async (data) => {
    try {
      await updatePassword({
        oldPassword: data.oldPassword,
        newPassword: data.password,
      });
      props.setSnackBarMessage(t('accountSettings.passwordChangeSuccess'));
    } catch (err) {
      captureException(err);
      props.setSnackBarMessage(t('accountSettings.passwordChangeFail'));
    } finally {
      props.setIsSnackBarOpen(true);
      props.setEdit(UserEditTypes.None);
      reset();
    }
  };

  return (
    <Stack width="100%" maxWidth={568}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(handleEditPassword)}>
        <Row>
          {/*old password*/}
          <TypographyLabel variant="bodyRegular">
            {t('common.oldPassword')}
          </TypographyLabel>
          <PasswordTextField
            error={!!errors.oldPassword}
            label={t('common.oldPassword')}
            helperText={errors?.oldPassword?.message}
            {...register('oldPassword')}
          />
        </Row>
        <Row>
          {/*new password*/}
          <TypographyLabel variant="bodyRegular">
            {t('employee.newPassword')}
          </TypographyLabel>
          <PasswordTextField
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            label={t('employee.newPassword')}
          />
        </Row>
        <Row>
          {/*repeat password*/}
          <TypographyLabel variant="bodyRegular" fontSize={14}>
            {t('common.repeatPassword')}
          </TypographyLabel>
          <PasswordTextField
            {...register('repeatPassword')}
            error={!!errors.repeatPassword}
            helperText={errors.repeatPassword?.message}
            label={t('common.repeatPassword')}
          />
        </Row>
        {/*buttons*/}
        <Row style={{ justifyContent: 'end' }}>
          <Button
            type="button"
            textColor={theme.palette.common.black}
            variant="text"
            onClick={() => {
              reset();
              props.setEdit(UserEditTypes.None);
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button variant="contained" type="submit">
            {t('common.changePassword')}
          </Button>
        </Row>
      </form>
    </Stack>
  );
};
