import { useReportModalContext } from '@kpi/contexts';
import { KpiReportDisplayType } from '@kpi/types';

import {
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';

export const ReportModalTableRow = (props: KpiReportDisplayType) => {
  const { onEntryChange } = useReportModalContext();

  return (
    <TableRow>
      <CustomCell>
        <Typography variant="bodyRegular" sx={{ wordBreak: 'break-word' }}>
          {props.rateName}
        </Typography>
      </CustomCell>
      <CustomCell>
        <TextField
          size="small"
          value={props.entry}
          inputProps={{
            style: { textAlign: 'right' },
          }}
          onChange={(e) => onEntryChange(props.id, e.target.value)}
        />
      </CustomCell>
      <CustomCell>
        <Typography variant="bodyRegular">{props.shortcut}</Typography>
      </CustomCell>
    </TableRow>
  );
};

const CustomCell = styled(TableCell)({
  border: 'none',
});
