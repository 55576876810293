import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee';
import { IDeleteUserModalProps } from '@organizations/pages/organizations';

import { Box, Stack, Typography, useTheme } from '@mui/material';

import { useRemoveUserMutation } from '@/__generated__/graphql.ts';
import { CustomModal, ModalContainer } from '@/core/components';

export const DeleteUserModal = (props: IDeleteUserModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isFail, setIsFail] = useState(false);

  const [removeUser] = useRemoveUserMutation();
  const handleDeleteUser = async () => {
    try {
      const { data } = await removeUser({
        variables: {
          input: {
            id: props.id,
            organizationId: props.organizationId,
          },
        },
      });
      if (data?.removeEmployee.success) {
        await props.setSnackBar(t('systemSettings.userWasRemoved'));
        props.closeModal();
        if (props.setDeleteUserOperationStatus) {
          props.setDeleteUserOperationStatus(true, props.id);
        }
      }
    } catch (_err) {
      setIsFail(true);
    }
  };

  return (
    <CustomModal display={props.open}>
      <ModalContainer
        sx={{ width: '528px' }}
        closeModal={props.closeModal}
        headerText={t('systemSettings.deleteUser')}
      >
        <Box padding="24px">
          <Typography fontSize={14}>
            {isFail ? (
              t('systemSettings.removingUserFail')
            ) : (
              <Trans
                i18nKey="systemSettings.areYouSureToDelete"
                values={{
                  username: `${props.firstName} ${props.lastName}`,
                }}
              ></Trans>
            )}
          </Typography>
        </Box>
        <Stack
          padding="16px 24px 24px 24px"
          justifyContent="flex-end"
          gap="12px"
          direction="row"
        >
          {!isFail && (
            <PrimaryButton
              sx={{
                color: theme.palette.common.black,
              }}
              onClick={props.closeModal}
            >
              <Typography fontSize={14}>{t('common.cancel')}</Typography>
            </PrimaryButton>
          )}
          <PrimaryButton
            data-testid="addButtonModal"
            variant="contained"
            sx={{
              backgroundColor: theme.palette[isFail ? 'primary' : 'error'][600],
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ':hover': {
                backgroundColor:
                  theme.palette[isFail ? 'primary' : 'error'][700],
              },
            }}
            onClick={isFail ? props.closeModal : handleDeleteUser}
          >
            <Typography fontSize={14}>
              {isFail ? t('common.ok') : t('systemSettings.deleteUser')}
            </Typography>
          </PrimaryButton>
        </Stack>
      </ModalContainer>
    </CustomModal>
  );
};
