import { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import { useOrganizationStore } from '@/stores/useOrganizationStore/useOrganizationStore';

export const OrganizationUrlWrapper: FC = () => {
  const param = useParams();
  const url = param.companyName as string;
  const [isLoading, setIsLoading] = useState(true);
  const organizationStore = useOrganizationStore();

  const getOrganizationId = async () => {
    if (!organizationStore.organization[url])
      await organizationStore.setOrganization(url);
    setIsLoading(false);
  };

  useEffect(() => {
    // eslint-disable-next-line
    getOrganizationId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
};
