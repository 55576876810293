import { useNavigate, useParams } from 'react-router-dom';

import { OrganizationModules } from '@/__generated__/graphql.ts';

export const useOrganizationSettingsRedirects = () => {
  const navigate = useNavigate();
  const { companyName } = useParams();

  const paths = {
    dashboardPath: `/${companyName}/organization-settings/data`,
    globalPermissionsPath: `/${companyName}/organization-settings/global-permissions`,
    structurePath: `/${companyName}/organization-settings/e-schema`,
    flowPath: `/${companyName}/organization-settings/e-flow`,
    kaizenPath: `/${companyName}/organization-settings/kaizen`,
    kpiPath: `/${companyName}/organization-settings/kpi`,
    eFlowAnalizePath: `/${companyName}/organization-settings/e-flow`,
    eSchemaPath: `/${companyName}/organization-settings/e-schema`,
  };

  const pathMap: Record<
    OrganizationModules | 'global-permissions' | 'data',
    string
  > = {
    [OrganizationModules.Ai]: '',
    [OrganizationModules.ActionPlan]: '',
    [OrganizationModules.Audit5s]: '',
    [OrganizationModules.Calendar]: '',
    [OrganizationModules.DigitalTwin]: '',
    [OrganizationModules.EFlowAnalize]: paths.eFlowAnalizePath,
    [OrganizationModules.ESchema]: paths.eSchemaPath,
    [OrganizationModules.Kaizen]: paths.kaizenPath,
    [OrganizationModules.Kpi]: paths.kpiPath,
    [OrganizationModules.Matrix]: '',
    [OrganizationModules.Opexity]: '',
    [OrganizationModules.Project]: '',
    [OrganizationModules.TiMes]: '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'global-permissions': paths.globalPermissionsPath,
    data: paths.dashboardPath,
  };

  const isPathSelected = (
    organizationModule: OrganizationModules | 'global-permissions' | 'data',
  ) => {
    return window.location.pathname === pathMap[organizationModule];
  };

  const navigateTo = (
    organizationModule: OrganizationModules | 'global-permissions' | 'data',
  ) => {
    return navigate(pathMap[organizationModule]);
  };

  return {
    ...paths,
    navigateTo,
    isPathSelected,
  };
};
