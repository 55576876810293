import { SubmissionTable } from '@kaizen/components/SubmissionTable/Components/SubmissionTable.tsx';

import { KaizenDashboardFiltersProvider } from './Contexts/useKaizenSubmissionTable.context';

export const KaizenSubmissionTableHandler = () => {
  return (
    <KaizenDashboardFiltersProvider>
      <SubmissionTable />
    </KaizenDashboardFiltersProvider>
  );
};
