import { KpiReported } from '@core/modules/Notifications/components/templates/KpiReported/KpiReported.tsx';
import { useNotificationsContext } from '@core/modules/Notifications/context/NotificationsContext.tsx';
import { useChangeNotificationStatus } from '@core/modules/Notifications/hooks/useChangeNotificationStatus.tsx';

import { NotificationTypesEnum } from '@/__generated__/graphql.ts';

import { NotificationType } from '../types/Notifications.types';
import {
  KaizenNewCommentNotification,
  KaizenStatusChangeNotification,
  KpiReportSchedule,
} from './templates';

export const SingleNotification = (notification: NotificationType) => {
  const { handleMarkAsRead } = useChangeNotificationStatus(
    notification.isOpened,
  );
  const { markNotificationAsRead } = useNotificationsContext();

  switch (notification.notificationTemplate) {
    case NotificationTypesEnum.KaizenNewComment:
      return (
        <KaizenNewCommentNotification
          key={notification.id}
          onClick={() => {
            void handleMarkAsRead(notification.id);
            markNotificationAsRead(notification.id);
          }}
          {...notification}
        />
      );
    case NotificationTypesEnum.KaizenStatusChange:
      return (
        <KaizenStatusChangeNotification
          key={notification.id}
          onClick={() => {
            void handleMarkAsRead(notification.id);
            markNotificationAsRead(notification.id);
          }}
          {...notification}
        />
      );

    case NotificationTypesEnum.KpiReportSchedule:
      return (
        <KpiReportSchedule
          key={notification.id}
          onClick={() => {
            void handleMarkAsRead(notification.id);
            markNotificationAsRead(notification.id);
          }}
          {...notification}
        />
      );

    case NotificationTypesEnum.KpiReported:
      return (
        <KpiReported
          key={notification.id}
          onClick={() => {
            void handleMarkAsRead(notification.id);
            markNotificationAsRead(notification.id);
          }}
          {...notification}
        />
      );
    default:
      return <span>UNKNOWN TEMPLATE</span>;
  }
};
