/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useGetKaizenRanksLazyQuery } from '@/__generated__/graphql';
import { useCompanyId, useTablePagination } from '@/core/hooks';

import { useKaizenRankingFiltersContext } from '../contexts/useKaizenRankingFilters.context';

export const useRanking = () => {
  const { cursor, setCursor, rowsPerPage, setRowsPerPage } =
    useTablePagination();
  const { companyId } = useCompanyId();

  const { debouncedSearch, periodIds } = useKaizenRankingFiltersContext();

  const [getKaizenRanksLazyQuery, { data, loading: isLoading }] =
    useGetKaizenRanksLazyQuery();

  useEffect(() => {
    if (!companyId) return;

    void getKaizenRanksLazyQuery({
      variables: {
        input: {
          limit: rowsPerPage,
          organizationId: companyId,
          search: debouncedSearch,
          settlementPeriodIds: periodIds,
          cursor,
        },
      },
    });
  }, [companyId, cursor, debouncedSearch, periodIds, rowsPerPage]);

  useEffect(() => {
    setCursor(0);
  }, [debouncedSearch, periodIds]);

  return {
    data: data?.getKaizenRanks.items || [],
    total: data?.getKaizenRanks.total || 0,
    cursor,
    setCursor,
    rowsPerPage,
    setRowsPerPage,

    isLoading,
  };
};
