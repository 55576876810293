import { Stack, Typography, styled } from '@mui/material';

import { theme } from '@/theme';

import { ChartLayoutProps } from './ChartIconLayout.types';

export const ChartIconLayout = ({
  isActive,
  label,
  onClick,
  icon,
}: ChartLayoutProps) => {
  return (
    <Container
      onClick={onClick}
      sx={{
        borderColor: isActive
          ? theme.palette.primary[600]
          : theme.palette.grey[200],
        color: isActive ? theme.palette.common.black : theme.palette.grey[900],
      }}
    >
      {icon}
      <Typography variant="bodyRegular">{label}</Typography>
    </Container>
  );
};

const Container = styled(Stack)({
  width: '150px',
  height: '124px',
  borderRadius: '8px',
  border: '2px solid',
  padding: '24px, 8px,24px, 8px',
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  flex: 1,
});
