import { useEffect, useState } from 'react';

import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context';

import { DashboardWidgetDto } from '@/__generated__/graphql';

export const useHandleChartVisibility = (close: () => void) => {
  const [charts, setCharts] = useState<DashboardWidgetDto[]>([]);

  const { dashboard, changeWidgetVisibility } = useDashboardContext();

  const handleCheck = async (layoutKey: string, isChecked: boolean) => {
    if (!dashboard) return;
    await changeWidgetVisibility(layoutKey, !isChecked);
  };

  useEffect(() => {
    if (!dashboard) return;
    setCharts([...dashboard.widgets]);
  }, [dashboard]);

  return {
    charts,
    handleCheck,
    close,
  };
};
