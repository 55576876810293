import { RefObject, useCallback, useEffect, useState } from 'react';

import { UseResizeTypes } from '@core/hooks/useResize/useResize.types.ts';

/**
 * useResize hook
 * @description This hook is used to track the width and height of an element
 *
 * @param T extends HTMLElement defines what kind of element we are targeting
 * @param myRef RefObject<T> reference to the element we want to track
 * @returns {width, height} width and height of the element
 *
 * @example
 * const myRef = useRef<HTMLDivElement>(null);
 * const { width, height } = useResize(myRef);
 *
 * return <div ref={myRef}>Width: {width}, Height: {height}</div>;
 *
 */
const useResize = <T extends HTMLElement>(
  myRef: RefObject<T>,
): UseResizeTypes => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const handleResize = useCallback(() => {
    if (myRef.current) {
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
    }
  }, [myRef]);

  useEffect(() => {
    handleResize(); // Call handleResize on initial mount

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return { width, height };
};

export default useResize;
