import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@e-schema/styles/Employee.ts';

import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';

import {
  Button,
  Checkbox,
  CustomModal,
  InfoModalContent,
  ModalContainer,
} from '@core/components';

import { theme } from '@/theme';

import { CheckBoxWithTextModalProps } from './CheckBoxWithTextModal.types.ts';
import { useCheckBoxWithTextModal } from './useCheckBoxWithTextModal.tsx';

export const CheckBoxWithTextModal: React.FC<CheckBoxWithTextModalProps> = ({
  isOpen = false,
  closeModal,
  headerText,
  placeholder,
  checkboxLabel,
  submitButton = '',
  onResponse,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    setValue,
    handleSubmit,
    isCheckboxChecked,
    onSubmit,
    resetForm,
    setCheckboxValue,
    handleCheckboxChange,
  } = useCheckBoxWithTextModal({ handleResponse: onResponse });

  useEffect(() => {
    if (props.defaultValues?.text !== undefined)
      setValue('text', props.defaultValues?.text);

    if (props.defaultValues?.checkbox !== undefined)
      setCheckboxValue(props.defaultValues?.checkbox);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValues?.text, props.defaultValues?.checkbox]);

  return (
    <CustomModal display={isOpen}>
      <ModalContainer
        closeModal={() => {
          closeModal();
          resetForm();
        }}
        headerText={headerText}
        width={'528px'}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <InfoModalContent
            contentText={
              <CustomStack>
                <RowStack>
                  <TextField
                    size={'small'}
                    defaultValue={props.defaultValues?.text}
                    fullWidth
                    placeholder={placeholder}
                    onChange={(event) => {
                      setValue('text', event.target.value);
                    }}
                    error={props.isError}
                    helperText={props.isError && props.errorInputMessage}
                  />
                  {props.additionalInput}
                </RowStack>
                <Checkbox
                  label={checkboxLabel}
                  defaultChecked={props.defaultValues?.checkbox}
                  value={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                  disabled={props.disableCheckbox}
                  checkboxTooltip={props.checkboxTooltip}
                />
                {props.additionalContent}
              </CustomStack>
            }
          >
            <Button
              type={'button'}
              variant={'text'}
              textColor={theme.palette.common.black}
              textVariant={'bodyRegular'}
              onClick={() => closeModal()}
            >
              {t('common.cancel')}
            </Button>
            <PrimaryButton type="submit" variant={'contained'}>
              {submitButton}
            </PrimaryButton>
          </InfoModalContent>
        </form>
      </ModalContainer>
    </CustomModal>
  );
};

const CustomStack = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
});

const RowStack = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
  alignContent: 'baseline',
  justifyContent: 'baseline',
});
