import { useTranslation } from 'react-i18next';

import { CustomTextField } from '@/core/styles';

import { IEmailTextFieldProps } from './EmailTextField.types';

export const EmailTextField = ({
  error,
  helperText,
  field,
}: IEmailTextFieldProps) => {
  const { t } = useTranslation();

  return (
    <CustomTextField
      {...field}
      id="outlined-required"
      label={t('login.email')}
      size="small"
      error={error}
      helperText={helperText}
      FormHelperTextProps={{
        style: {
          marginBottom: '-15px',
        },
      }}
      fullWidth
    />
  );
};
