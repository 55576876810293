import { deepClone } from '@mui/x-data-grid/internals';

import {
  FindAllKpiSettlementPeriodsDocument,
  FindAllKpiSettlementPeriodsQueryResult,
} from '@/__generated__/graphql.ts';
import { client } from '@/apollo.ts';

/**
 * Function to read query of kpi settlement periods
 * @param query DocumentNode
 * @param eFlowId string
 * @param organizationId  string
 */
export const readQuery = (
  organizationId: string,
): FindAllKpiSettlementPeriodsQueryResult['data'] => {
  const results = client.readQuery({
    query: FindAllKpiSettlementPeriodsDocument,
    variables: {
      input: {
        organizationId,
      },
    },
  });

  if (results === null) {
    return {
      findAllKpiSettlementPeriods: {
        settlementPeriods: [],
        __typename: 'FindAllKpiSettlementPeriodsDto',
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return deepClone(results) as FindAllKpiSettlementPeriodsQueryResult['data'];
};
