import { cloneDeep } from '@apollo/client/utilities';
import { RecalculateUnitsProps } from '@e-flow/pages/flowReport/components/BalanceChart/logic/RecalculateUnits/RecalculateUnits.types.ts';
import convert from 'convert-units';
import { GetAsIsToBeEflowParamsQuery } from '@/__generated__/graphql.ts';

/**
 * Recalculates units of parameters
 * @param desiredUnit - Defines unit of selected parameter
 * @param params - Defines parameters which will be recalculated
 * @param parameterName - Defines name of parameter which will be recalculated
 * @param incomingUnit - Defines unit provided by user
 * @returns recalculated parameters
 */
export const recalculateUnits = ({
  desiredUnit,
  params,
  parameterName,
  incomingUnit,
}: RecalculateUnitsProps): Pick<
  GetAsIsToBeEflowParamsQuery,
  'findOneEFlow'
> => {
  const eFlowData = params?.findOneEFlow;

  const asIsSteps = cloneDeep(eFlowData?.asIsSteps) || [];
  const toBeSteps = cloneDeep(eFlowData?.toBeSteps) || [];

  if (!eFlowData || (asIsSteps.length === 0 && toBeSteps.length === 0)) {
    return params;
  }

  const lastIndex = Math.max(asIsSteps.length, toBeSteps.length);

  for (let i = 0; i < lastIndex; i++) {
    if (asIsSteps[i]?.parameters?.[parameterName]?.value) {
      asIsSteps[i].parameters[parameterName].value = convert(
        asIsSteps[i].parameters[parameterName].value as number,
      )
        .from(incomingUnit)
        .to(desiredUnit);
    }

    if (toBeSteps[i]?.parameters?.[parameterName]?.value !== undefined) {
      toBeSteps[i]!.parameters[parameterName].value = convert(
        toBeSteps[i]?.parameters[parameterName].value as number,
      )
        .from(incomingUnit)
        .to(desiredUnit);
    }
  }

  return {
    ...params,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    findOneEFlow: {
      ...params.findOneEFlow,
      asIsSteps,
      toBeSteps,
    },
  };
};
