import { Menu } from '@mui/material';

import { MenuItem } from '@core/components/MenuItem';
import { useWidgetMenu } from '@core/modules/DashbordModule/hooks';
import { WidgetMenuTypes } from '@core/modules/DashbordModule/types/WidgetMenu.types.ts';

import { DashboardTypesEnum } from '@/__generated__/graphql';

import { useDashboardContext } from '../../contexts/DashboardModule.context';

export const WidgetMenu = (props: WidgetMenuTypes) => {
  const { dashboardType, openRemoveModal } = useDashboardContext();

  const { renderMenuVariant } = useWidgetMenu(
    props.widgetData,
    props.widgetContent,
    props.handleMenuClose,
  );

  if (dashboardType === DashboardTypesEnum.UserDashboard)
    return (
      <>
        <Menu
          anchorEl={props.anchorEl}
          onClose={props.handleMenuClose}
          open={props.isMenuOpen}
        >
          <MenuItem
            variant={'delete'}
            onClick={() => {
              props.handleMenuClose();
              openRemoveModal(props.widgetData.layoutKey, '');
            }}
          />
        </Menu>
      </>
    );

  return (
    <Menu
      anchorEl={props.anchorEl}
      onClose={props.handleMenuClose}
      open={props.isMenuOpen}
    >
      {renderMenuVariant()}
    </Menu>
  );
};
