import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import { Sidebar } from '@e-schema/components';

import { Box, styled } from '@mui/material';

import { checkSystemRolesPermission } from '@core/utils';

import {
  SystemRoles as GQLSystemRoles,
  useGetEmployeeQuery,
} from '@/__generated__/graphql';
import { SystemRoles } from '@/__generated__/graphql.ts';
import { SimpleNavbar, UserSidebarContent } from '@/core/components';
import { useUserStore } from '@/stores';

export const SystemUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const id = params.id;
  const { userGroups, organizationId } = useUserStore();

  const { data } = useGetEmployeeQuery({
    variables: {
      input: {
        id: id!,
        organizationId: organizationId!,
      },
    },
  });

  const [menu, setMenu] = useState([
    {
      title: t('systemSettings.basicInformation'),
      to: `/organizations/system-settings/system-users/${id}/basic-information`,
    },
  ]);

  useEffect(() => {
    if (
      checkSystemRolesPermission(userGroups, [
        SystemRoles.Root,
        SystemRoles.SystemAdmin,
      ]) &&
      data?.getEmployee.systemRole.includes(GQLSystemRoles.KeyUser)
    ) {
      setMenu([
        {
          title: t('systemSettings.basicInformation'),
          to: `/organizations/system-settings/system-users/${id}/basic-information`,
        },
        {
          title: t('systemSettings.userOrganizations'),
          to: `/organizations/system-settings/system-users/${id}/organizations`,
        },
      ]);
    }
  }, [data?.getEmployee.systemRole, id, t, userGroups]);

  if (!id) return <Navigate to="/organizations/system-settings" />;
  return (
    <>
      <SimpleNavbar
        action={() => navigate('/organizations')}
        title={t('systemSettings.title')}
      />
      <Container>
        <Sidebar baseWidth={320} resize={false}>
          <SidebarContent>
            <UserSidebarContent
              title={t('systemSettings.title')}
              back="/organizations/system-settings/system-users"
              menu={menu}
            />
          </SidebarContent>
        </Sidebar>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </Container>
    </>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: 'calc(100vh - 56px)',
  display: 'flex',
});

const ContentContainer = styled(Box)({
  padding: 24,
  overflow: 'auto',
  display: 'flex',
  width: '100%',
});

const SidebarContent = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '8px 12px 32px 12px',
  rowGap: '32px',
});
