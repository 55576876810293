import React from 'react';

import { StepModalViewsProps } from './types';

export const StepModalViewsContext = React.createContext<StepModalViewsProps>({
  openViews: [],
  handleChangeViews: () => {},
  asIsStepId: '',
  toBeStepId: '',
  setAsIsStepId: () => {},
  setToBeStepId: () => {},
});
