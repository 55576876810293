import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CreateReportTypes } from '@e-flow/pages/flowReport/components/CreatingReportScreen/CreateReport.types.ts';

import { Box, LinearProgress, Typography, styled } from '@mui/material';

import { useFlowReport } from '../../hook';

export const CreatingReport = (props: CreateReportTypes) => {
  const { t } = useTranslation('eflow');

  const { companyName } = useParams();

  const { createReport } = useFlowReport();

  useEffect(() => {
    if (!companyName || !props.eflowId) return;
    void createReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, props.eflowId]);

  useEffect(() => {
    const interval = setInterval(() => {
      props.fetchQuery();
    }, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LinearProgress sx={{ width: '100%' }} />
      <Container>
        <TextContainer>
          <StyledTypography variant={'h3'}>
            {t('reports.underCreation.title')}
          </StyledTypography>
          <StyledTypography variant={'h5'}>
            {t('reports.underCreation.description')}
          </StyledTypography>
        </TextContainer>
      </Container>
    </>
  );
};

const Container = styled(Box)({
  display: 'flex',
  width: '100%',
  height: 'calc(100vh - 80px)',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 30,
});

const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '1rem',
});

const StyledTypography = styled(Typography)({
  whiteSpace: 'pre-wrap',
});
