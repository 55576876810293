import { useTranslation } from 'react-i18next';

import { Box, CircularProgress, Typography } from '@mui/material';

import { CustomModal, ModalContainer } from '@core/components';

import { ReportSingleKpiContent } from './ReportSingleKpiContent';
import { SingleKpiReportProvider } from './context';
import { ReportModalProps } from './types';

export const ReportSingleKpiModal = ({
  id,
  close,
  display,
  kpi,
  isLoading,
  isError,
  defaultDate,
}: ReportModalProps) => {
  const { t } = useTranslation('kpi');

  if (isLoading) {
    return (
      <CustomModal display={display}>
        <ModalContainer headerText={t('report.title')} closeModal={close}>
          <Box
            padding="24px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </ModalContainer>
      </CustomModal>
    );
  }

  if (isError) {
    return (
      <CustomModal display={display}>
        <ModalContainer
          headerText={t('report.errorFetchingKpiTitle')}
          closeModal={close}
        >
          <Typography variant="h6">{t('report.errorFetchingKpi')}</Typography>
        </ModalContainer>
      </CustomModal>
    );
  }

  return (
    <CustomModal display={display}>
      <ModalContainer headerText={t('report.title')} closeModal={close}>
        <SingleKpiReportProvider
          id={id!}
          close={close}
          kpi={kpi!}
          defaultDate={defaultDate}
        >
          <ReportSingleKpiContent />
        </SingleKpiReportProvider>
      </ModalContainer>
    </CustomModal>
  );
};
