/* eslint-disable @typescript-eslint/no-misused-promises */
import { captureException } from '@sentry/react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';

import { Box } from '@mui/material';

import { GlobalSystemRoles } from '@core/auth/GlobalSystemRoles.ts';
import { useHandleRoleUpdate } from '@core/hooks/useHandleRoleUpdate/useHandleRoleUpdate.ts';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { PermissionSelect } from '@/core/components';
import { ButtonGroupConfirmCancel } from '@/core/components/ButtonGroupConfirmCancel/ButtonGroupConfirmCancel.tsx';
import { PermissionSectionSchema } from '@/core/components/UserEdit/PermissionSection/PermissionSection.schema.ts';
import { PermissionsSectionTypes } from '@/core/components/UserEdit/PermissionSection/PermissionSection.types.ts';
import { UserEditPropertyWrapper } from '@/core/components/UserEdit/UserEditProp/UserEditProperityWrapper.tsx';
import { EmployeeRoles } from '@/core/helpers';

import { FlexEndBox, FormContainer, SettingsSectionContainer } from '../style';

export const PermissionsSection = (props: PermissionsSectionTypes) => {
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);

  const { updateSystemRole } = useHandleRoleUpdate();

  const findSystemRoles = useMemo(
    () => props.permissions.filter((role) => GlobalSystemRoles.includes(role)),
    [props.permissions],
  );

  const { handleSubmit, reset, register, getValues } = useForm({
    defaultValues: {
      permissions: findSystemRoles[0],
    },
    resolver: zodResolver(PermissionSectionSchema(t)),
  });

  useEffect(() => {
    reset({
      permissions: findSystemRoles[0],
    });
  }, [findSystemRoles, props.permissions, reset]);

  const handleEditPermissions = async (data: { permissions: SystemRoles }) => {
    try {
      await updateSystemRole(
        props.employee!.id,
        data.permissions,
        props.permissions,
      );
      props.setToastMessage(t('common.dataSaved'));
    } catch (e) {
      captureException(e);
      props.setToastMessage(t('common.dataNotSaved'));
    } finally {
      if (props.refetch) props.refetch();
      setIsEdit(false);
    }
  };

  return (
    <SettingsSectionContainer>
      <FormContainer onSubmit={handleSubmit(handleEditPermissions)}>
        <Box width="100%">
          <UserEditPropertyWrapper
            isLoading={props.isLoading}
            edit={isEdit}
            propertyName={t('common.permissions')}
            propertyValue={
              EmployeeRoles.getSystemRoles(
                props.permissions,
              ) as unknown as string
            }
            isEditButtonVisible={!props.isReadOnly}
            onEdit={() => {
              setIsEdit(true);
            }}
          >
            <PermissionSelect
              defaultValue={getValues('permissions')}
              register={{
                ...register('permissions'),
              }}
              organizationId={props.employee?.organizationId}
            />
          </UserEditPropertyWrapper>
          <FlexEndBox>
            <ButtonGroupConfirmCancel
              isVisible={isEdit}
              onCancel={() => {
                reset();
                setIsEdit(false);
              }}
            />
          </FlexEndBox>
        </Box>
      </FormContainer>
    </SettingsSectionContainer>
  );
};
