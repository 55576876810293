import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DomainIcon from '@mui/icons-material/Domain';
import {
  Alert,
  AlertColor,
  Avatar,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  styled,
} from '@mui/material';

import { CustomModal, ModalContainer } from '@/core/components';
import { allowedExtensions, maxFileSizeInBytes } from '@/core/constants';
import { theme } from '@/theme';

import { IImageUploadProps, IModalButtonProps, IResultMessage } from '.';

export const ImageUpload: FC<IImageUploadProps> = ({
  isOpen,
  onClose,
  onUpload,
  onRemove,
  imageUrl,
  title,
  selectImageLabel,
  changeImageLabel,
  removeImageLabel,
  placeholderLetter,
  altImageText,
  isOrganizationLogo,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [uploadResultMessage, setUploadResultMessage] =
    useState<IResultMessage>({
      message: undefined,
      type: undefined,
    });
  const [isVisibleSaveSettings, setIsVisibleSaveSettings] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [tempFile, setTempFile] = useState<File | null>(null);
  const [tempSrc, setTempSrc] = useState<string>('');

  const handleUpload = async (file: File) => {
    await onUpload(file);
  };

  const handleCancel = () => {
    setIsVisibleSaveSettings(false);
    setTempFile(null);
  };

  const handleSave = () => {
    setIsUploading(true);
    handleUpload(tempFile!)
      .then(() => {
        updateSnackbar(t('imageUpload.fileUploadedSuccess'), 'success');
        setIsUploading(false);
        handleCancel();
      })
      .catch((error: Error) => {
        setIsUploading(false);
        updateSnackbar(error.message, 'error');
      });
  };

  const clickFileInput = () => {
    fileInputRef.current?.click();
  };

  const updateSnackbar = (message: string, type: AlertColor | undefined) => {
    setUploadResultMessage({ message, type });
    setIsSnackbarOpen(true);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length < 0) return;

    const file = event.target.files[0];
    if (file) {
      const fileNameParts = file.name.split('.');
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        updateSnackbar(t('imageUpload.selectValidFile'), 'error');
      } else if (file.size > maxFileSizeInBytes) {
        updateSnackbar(t('imageUpload.fileSizeExceedsLimit'), 'error');
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setTempSrc(reader.result as string);
        };
        reader.readAsDataURL(file);

        setIsVisibleSaveSettings(true);
        setTempFile(file);
      }
    }
  };

  return (
    <CustomModal display={isOpen}>
      <ModalContainer closeModal={onClose} headerText={title} width="360px">
        <Stack display="flex" alignItems="center" padding="24px" gap="24px">
          {imageUrl || tempSrc ? (
            <CustomImg src={(tempSrc || imageUrl)!} alt={altImageText} />
          ) : (
            <CustomAvatar>
              {isOrganizationLogo ? (
                <DomainIcon fontSize="large" />
              ) : (
                placeholderLetter
              )}
            </CustomAvatar>
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleFileUpload(event);
            }}
          />
          {isVisibleSaveSettings ? (
            <Stack direction="row" spacing={1} width={'100%'}>
              <ModalButton onClick={handleSave} disabled={isUploading}>
                {isUploading ? (
                  <CircularProgress size={'24px'} />
                ) : (
                  t('common.confirm')
                )}
              </ModalButton>
              <ModalButton onClick={() => handleCancel()}>
                {t('common.cancel')}
              </ModalButton>
            </Stack>
          ) : imageUrl ? (
            <Stack direction="row" spacing={1} width={'100%'}>
              <ModalButton onClick={() => clickFileInput()}>
                {changeImageLabel}
              </ModalButton>
              <ModalButton
                onClick={() => {
                  onRemove();
                  setTempSrc('');
                }}
              >
                <DeleteOutlineIcon
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                />
                {removeImageLabel}
              </ModalButton>
            </Stack>
          ) : (
            <ModalButton fullWidth onClick={clickFileInput}>
              {selectImageLabel}
            </ModalButton>
          )}
        </Stack>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isSnackbarOpen}
          autoHideDuration={5000}
          onClose={() => setIsSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setIsSnackbarOpen(false)}
            severity={uploadResultMessage.type}
            sx={{ width: '100%' }}
          >
            {uploadResultMessage.message}
          </Alert>
        </Snackbar>
      </ModalContainer>
    </CustomModal>
  );
};

const ModalButton: FC<IModalButtonProps> = ({ children, ...props }) => {
  return (
    <StyledButton variant="contained" color="inherit" {...props}>
      {children}
    </StyledButton>
  );
};

const CustomAvatar = styled(Avatar)`
  width: 152px;
  height: 152px;
  font-size: 64px;
  background-color: ${theme.palette.primary[600]};
`;

const CustomImg = styled('img')`
  width: 152px;
  height: 152px;
  border-radius: 50%;
`;

const StyledButton = styled(Button)<{
  fullWidth?: boolean;
}>`
  gap: 6px;
  height: 40px;
  border-radius: 8px;
  background-color: ${theme.palette.grey[100]};
  color: ${theme.palette.text.primary};
  box-shadow: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: none;
  letter-spacing: 0px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};

  &:hover {
    background-color: ${theme.palette.grey[200]};
    box-shadow: none;
  }
`;
