import { isNumber } from 'lodash';

import { formatKey } from '@core/hooks/useRecharts/functions/FormatKey.ts';

import { ExtractChartKeyType } from '../types';

export const FormatStackedChartWithTargetValues = (
  props: ExtractChartKeyType,
  hasTarget: boolean,
  dataRange?: number[],
): { [key: string]: number | string }[] => {
  const chartValues: { [key: string]: number | string }[] =
    dataRange?.map((value) => ({ name: value })) || [];

  props.forEach((chartEntry) => {
    const singleChartEntry: { [key: string]: number | string } = {
      name: '',
    };

    for (const key in chartEntry) {
      if (key === 'name') {
        singleChartEntry['name'] = chartEntry[key] as unknown as number;
        continue;
      }

      const target = hasTarget ? chartEntry[key].target : undefined;
      const value = chartEntry[key].value;

      if (isNumber(target) && isNumber(value)) {
        if (target > value) {
          singleChartEntry[formatKey(key, 'value')] = value;
          singleChartEntry[formatKey(key, 'target')] = target - value;
        }

        if (target <= value) {
          singleChartEntry[formatKey(key, 'value')] = value;
          singleChartEntry[formatKey(key, 'target')] = 0;
        }
      } else {
        singleChartEntry[formatKey(key, 'value')] = value;
        if (hasTarget)
          singleChartEntry[formatKey(key, 'target') as unknown as string] =
            target as number;
      }
    }

    if (dataRange) {
      if (dataRange.includes(singleChartEntry.name as unknown as number))
        if ('name' in singleChartEntry)
          chartValues[(singleChartEntry['name'] as number) - 1] =
            singleChartEntry;
    } else chartValues.push(singleChartEntry);
  });

  return chartValues;
};
