import { KaizenStatuses } from '@/__generated__/graphql';
import { StatusLabel } from '@/pages/kaizen/components';

import { CustomCell } from './styles';

export const StatusCell = ({ status }: { status: KaizenStatuses }) => {
  return (
    <CustomCell>
      <StatusLabel variant={status} />
    </CustomCell>
  );
};
