/**
 * @description bfs algorithm to find specific node
 * @param {OrganizationNode} graph
 * root tree
 * @param {number} targetId target id
 *
 *
 * @returns {OrganizationNode | undefined} node with unique id or undefined when it's not found
 */
import { OrganizationNode } from '@core/types';

export const bfsSearch = (
  graph: OrganizationNode[],
  targetId: number | string,
): OrganizationNode | undefined => {
  const queue = [...graph];

  while (queue.length > 0) {
    const currNode = queue.shift();
    if (currNode && currNode.id === targetId) {
      return currNode;
    }
    if (currNode && currNode.children) {
      queue.push(...currNode.children);
    }
  }
  return undefined; // Target node isn't found
};