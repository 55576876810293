import { useEffect, useState } from 'react';

import { useFindKaizenModuleInfoLazyQuery } from '@/__generated__/graphql.ts';
import { useCompanyId } from '@/core/hooks';

export const useCanAddSubmission = () => {
  const [hasSettlementPeriod, setHasSettlementPeriod] =
    useState<boolean>(false);
  const [hasScoringSet, setHasScoringSet] = useState<boolean>(false);

  const { companyId } = useCompanyId();

  const [findModuleInfoQuery, { data, loading: isLoading }] =
    useFindKaizenModuleInfoLazyQuery({
      initialFetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    });

  useEffect(() => {
    if (!companyId) return;

    void findModuleInfoQuery({
      variables: {
        input: {
          organizationId: companyId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (isLoading) return;

    if (data?.findKaizenModuleInfo) {
      if (data.findKaizenModuleInfo.scoringSet) {
        setHasSettlementPeriod(true);
      }

      if (data.findKaizenModuleInfo.currentSettlementPeriod) {
        setHasScoringSet(true);
      }
    }
  }, [data, isLoading]);

  return {
    isLoading,
    hasSettlementPeriod,
    hasScoringSet,
    canAddSubmission: hasSettlementPeriod && hasScoringSet,
  };
};
