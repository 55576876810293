import { useState } from 'react';

import { useModalView } from '@kpi/modals/HandleKpiChartModal/hooks/useModalView/useModalView.tsx';

import { useModal } from '@core/hooks';

import { ChartFragment } from '@/__generated__/graphql.ts';

import { KpiChartModalHookProps } from './types';

export const useKpiChartModal = ({
  setValues,
  getKpis,
}: KpiChartModalHookProps) => {
  const [modalMode, setModalMode] = useState<'add' | 'update'>('add');
  const [dashboardId, setDashboardId] = useState<string>('');

  const modalViewSettings = useModalView();

  const { isModalVisible, setIsModalVisible } = useModal();

  const addNewKpiChart = (dashboardId: string) => {
    setModalMode('add');
    setIsModalVisible(true);
    setDashboardId(dashboardId);
  };

  const updateKpiChart = async (
    dashboardId: string,
    kpiChart: ChartFragment,
  ) => {
    setModalMode('update');
    setDashboardId(dashboardId);
    setIsModalVisible(true);
    setValues(kpiChart);
    await getKpis(kpiChart.dataSourceSet);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return {
    modalMode,
    dashboardId,
    ...modalViewSettings,
    isAddKpiModalOpen: isModalVisible,
    closeModal,
    updateKpiChart,
    addNewKpiChart,
  };
};
