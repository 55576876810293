import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AvatarGroup,
  Box,
  IconButton,
  Popover,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { NamedAvatar } from '@core/components/NamedAvatar/NamedAvatar.tsx';
import { useTimestamp } from '@core/hooks';

import { EmployeeAvatar } from '@/core/components';
import { EflowStatus } from '@/core/components/EflowStatus';
import { CustomTooltip } from '@/core/styles';
import { useUserStore } from '@/stores';
import { theme } from '@/theme';

import { AnalizeCardProps } from './AnalizeCard.types';
import { AnalizeMenu } from './AnalizeMenu';

//@bryndalski - refactor me please -  move rendering of user group into separate component, and make this code looks less like a mess
export const AnalizeCard: FC<AnalizeCardProps> = (props) => {
  const { t } = useTranslation('eflow');
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [anchorPopover, setAnchorPopover] = useState<HTMLElement | null>(null);

  const { toDdMmYyyy } = useTimestamp();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorPopover(null);
  };

  const isOpen = Boolean(anchorPopover);

  const { userUuid, name, lastName } = useUserStore();

  return (
    <>
      <Container onClick={() => navigate(`${props.id}`)}>
        <Description>
          {/*Top panel*/}
          <TitlePanel>
            <CustomTooltip
              title={props.name}
              placement="bottom-start"
              TransitionProps={{ timeout: 600 }}
            >
              <TitleText variant="bodyMedium">{props.name}</TitleText>
            </CustomTooltip>
            <TitlePanelControl>
              <EflowStatus status={props.status} />
              <IconButton
                aria-label="more"
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </TitlePanelControl>
          </TitlePanel>

          {/*Description*/}
          <DescriptionText variant="bodyRegular">
            {props.description}
          </DescriptionText>
        </Description>
        {/*Bottom*/}
        <BottomPanel>
          <StyledAvatarGroup
            aria-owns={isOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            total={props?.analyzeLeaders?.length}
            max={3}
          >
            {props.analyzeLeaders
              ? props?.analyzeLeaders?.map((member, index) => (
                  <EmployeeAvatar
                    key={index}
                    imageUrl={member.profilePicture}
                    name={
                      !member.profilePicture
                        ? `${member.firstName[0]}${member.lastName[0]}`
                        : ''
                    }
                  ></EmployeeAvatar>
                ))
              : null}
          </StyledAvatarGroup>
          <Popover
            sx={{
              pointerEvents: 'none',
              padding: '12px',
            }}
            open={isOpen}
            anchorOrigin={{
              vertical: -70,
              horizontal: 'center',
            }}
            anchorEl={anchorPopover}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {props.analyzeLeaders
              ? props?.analyzeLeaders?.map((member, index) => (
                  <Box sx={{ padding: '24px' }} key={index}>
                    <Stack flexDirection="row" alignItems="center" gap={'16px'}>
                      <NamedAvatar key={member.id} {...member} />
                      <Typography>
                        {member.firstName} {member.lastName}
                      </Typography>
                    </Stack>
                  </Box>
                ))
              : null}
          </Popover>
          <Typography variant="bodyRegular">
            {props.analyzeLeaders?.length === 0 && t('dashboard.noLeaders')}
            {props.analyzeLeaders?.some((leader) => leader.id === userUuid) &&
              `${name}\xa0${lastName}`}
            &nbsp;-&nbsp;
            {toDdMmYyyy(props.startDate as string)}
          </Typography>
        </BottomPanel>
      </Container>
      <AnalizeMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        handleClose={() => setAnchorEl(null)}
        flowData={props}
      />
    </>
  );
};

const Container = styled(Box)({
  minHeight: '240px',
  flexWrap: 'wrap',
  width: '100%',
  padding: '24px',
  border: 'solid 1px',
  borderColor: '#DCDFE1',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  justifyContent: 'space-between',
  cursor: 'pointer',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    backgroundColor: 'rgba(18, 18, 18, 0.06)',
  },
});

const Description = styled(Stack)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  maxHeight: '100px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const TitlePanel = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitlePanelControl = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  alignItems: 'center',
});

const DescriptionText = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  color: theme.palette.text.secondary,
  WebkitLineClamp: 3,
});

const TitleText = styled(Typography)({
  fontSize: '14px',
  maxWidth: '66%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 500,
  lineHeight: '20px',
  color: theme.palette.text.primary,
});

const BottomPanel = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const StyledAvatarGroup = styled(AvatarGroup)({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& .MuiAvatar-root': {
    width: '24px',
    height: '24px',
    fontSize: '12px',
  },
});
