import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import { EmailOutlined, LocalPhoneOutlined } from '@mui/icons-material';
import { Avatar, Box, Menu, Stack, Typography } from '@mui/material';

import { getUserInitials } from '@core/utils';

import { KeyUserPopoverProps } from './types';

export const KeyUsersPopover = (props: KeyUserPopoverProps) => {
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.isMenuOpen}
      onClose={() => props.handleMenuClose()}
    >
      <KeyUserContainer>
        <Typography variant="h3" sx={{ padding: '12px', textAlign: 'left' }}>
          {t('common.keyUsers')}
        </Typography>
        {props.keyUsers?.map((user) => (
          <Stack direction="row" alignItems="center" gap="16px">
            <Avatar
              key={user.id}
              alt={user.firstName}
              src={user.profilePicture ?? undefined}
              sx={{ width: 32, height: 32 }}
            >
              {getUserInitials(user)}
            </Avatar>
            <Stack gap="4px">
              <Typography variant="bodyMedium">{`${user.firstName} ${user.lastName}`}</Typography>
              <Stack direction="row" alignItems="center" gap="16px">
                <ContactDataContainer>
                  <EmailOutlined fontSize="small" />
                  <Typography variant="bodyRegular">{user.email}</Typography>
                </ContactDataContainer>
                <ContactDataContainer>
                  <LocalPhoneOutlined fontSize="small" />
                  <Typography variant="bodyRegular">{user.phone}</Typography>
                </ContactDataContainer>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </KeyUserContainer>
    </Menu>
  );
};

const KeyUserContainer = styled(Stack)({
  padding: '18px 24px',
  gap: '24px',
});

const ContactDataContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  flexDirection: 'row',
});
