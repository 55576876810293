import { TFunction } from 'i18next';
import { z } from 'zod';

export const productModuleValidationSchema = (t: TFunction<'eflow'>) =>
  z.object({
    name: z
      .string()
      .min(10, {
        message: t('newAnalysis.errors.productNameMinLength', {
          length: 10,
        }),
      })
      .max(128, {
        message: t('newAnalysis.errors.productNameMaxLength', {
          length: 128,
        }),
      }),
    description: z
      .string()
      .min(10, {
        message: t('newAnalysis.errors.productDescriptionMinLength', {
          length: 10,
        }),
      })
      .max(512, {
        message: t('newAnalysis.errors.productDescriptionMaxLength', {
          length: 512,
        }),
      }),
  });
