import {
  CircularProgress,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { TableContentProps } from './TableContent.types';

export const TableContent = ({
  kpiChartsList,
  setIcon,
  setSelectedChart,
  isLoading,
}: TableContentProps) => {
  if (isLoading)
    return (
      <Stack width={'100%'} alignItems="center">
        <CircularProgress />
      </Stack>
    );

  return (
    <Table>
      {kpiChartsList.map((kpiChart) => (
        <TableRow
          key={kpiChart.id}
          sx={{ cursor: 'pointer', gap: '2px' }}
          onClick={() => setSelectedChart(kpiChart.id)}
        >
          <TableCell sx={{ width: '48px' }}>
            {setIcon(kpiChart.chartType)}
          </TableCell>
          <TableCell>
            <Typography>{kpiChart.chartName}</Typography>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
