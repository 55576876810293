import { Box, Typography, styled } from '@mui/material';

import type { IProps } from './TitleContainer.types';

export const TitleContainer = ({
  children,
  title,
  leftContainer,
  rightContainer,
}: IProps) => {
  return (
    <MainContainer>
      <TitleRow>
        {title && <Typography variant="h3">{title}</Typography>}
        {leftContainer}
        {rightContainer}
      </TitleRow>
      {children}
    </MainContainer>
  );
};

const MainContainer = styled(Box)({
  display: 'flex',
  minWidth: '312px',
  padding: '32px 0 32px 0',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  flex: '1 0 0',
});

const TitleRow = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});
