import { useTranslation } from 'react-i18next';

import { useCalculateReport } from '@kaizen/pages/reports/hooks';

import { CircularProgress } from '@mui/material';

import { BarChart } from '@core/components';
import { useDashboardWidgetContext } from '@core/modules/DashbordModule/contexts/DashboardWidget.context.tsx';

import { useKaizenReport } from '@/pages/home/views/hooks/useKaizenReport.ts';

export const KaizenSubmissionProcessingTimeChartWidget = () => {
  const { t } = useTranslation('kaizen');
  const { setWidgetTitle } = useDashboardWidgetContext();

  const { isLoading, kaizenReport } = useKaizenReport();

  const { formatBarChartData } = useCalculateReport(kaizenReport);

  setWidgetTitle(t('report.duration'));

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <BarChart
      isLoading={isLoading}
      series={formatBarChartData}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'bottom', horizontal: 'middle' },
          padding: 0,
        },
      }}
      grid={{ horizontal: true }}
      bottomAxis={null}
      xAxis={[{ scaleType: 'band', data: [t('report.submissionStatus')] }]}
      cardSx={{
        padding: 0,
        border: 'none',
        margin: 0,
        boxShadow: 'none',
        maxHeight: 'fit-content',
        gap: 0,
        backgroundColor: 'transparent',
      }}
    />
  );
};
