import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CommonTableRow,
  TitleTypography,
  ValueTypography,
} from '@kaizen/views/SummaryView/components/Rows/CommonTableRow.tsx';

import { Skeleton } from '@mui/material';

import { useGetEmployeeLazyQuery } from '@/__generated__/graphql.ts';
import { useUserStore } from '@/stores';

export const OrganizationUnitRow = () => {
  const { t } = useTranslation('kaizen');

  const [getEmployee, { data, loading: isLoading, error }] =
    useGetEmployeeLazyQuery();
  const { organizationId, userUuid } = useUserStore();

  useEffect(() => {
    if (!organizationId || !userUuid) return;

    void getEmployee({
      variables: {
        input: {
          organizationId,
          id: userUuid,
        },
      },
    });
  }, [getEmployee, organizationId, userUuid]);

  if (isLoading) {
    return (
      <CommonTableRow>
        <TitleTypography variant="bodyRegular">
          {t('submissionModificationView.summary.table.organizationUnitCell')}
        </TitleTypography>
        <ValueTypography variant="bodyRegular">
          <Skeleton variant="rectangular" width={210} height={20} />
        </ValueTypography>
      </CommonTableRow>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <CommonTableRow>
      <TitleTypography variant="bodyRegular">
        {t('submissionModificationView.summary.table.organizationUnitCell')}
      </TitleTypography>
      <ValueTypography variant="bodyRegular">
        {data?.getEmployee?.organizationUnit.name}
      </ValueTypography>
    </CommonTableRow>
  );
};
