import { OperationKeyTypes } from '@e-flow/pages/flowSteps/components/OperationKey/OperationKey.types.ts';

import { Typography, styled } from '@mui/material';

export const OperationKey = (props: OperationKeyTypes) => {
  if (props.isSelected)
    return (
      <SelectedTypography>
        {props.operationNumber}.{props.operationText}
      </SelectedTypography>
    );

  return (
    <NotSelectedTypography variant="bodyRegular" onClick={props.onClick}>
      {props.operationNumber}.{props.operationText}
    </NotSelectedTypography>
  );
};

const OperationTypography = styled(Typography)({
  padding: ' 12px 24px 12px 24px',
  minHeight: 50,
  maxHeight: 50,
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const SelectedTypography = styled(OperationTypography)({
  backgroundColor: '#f5f5f5',
  fontWeight: 'bold',
  cursor: 'default',
});

const NotSelectedTypography = styled(OperationTypography)({
  cursor: 'pointer',

  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});
