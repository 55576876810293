import { ForwardedRef, forwardRef, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';

import { CustomTextField } from '@/core/styles';

export const PasswordTextField = forwardRef(
  (props: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [isShownPassword, setIsShownPassword] = useState(false);
    return (
      <CustomTextField
        size="small"
        type={isShownPassword ? 'text' : 'password'}
        FormHelperTextProps={{
          style: {
            marginBottom: '-10px',
            borderRadius: '8px',
          },
        }}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsShownPassword(!isShownPassword)}
                onMouseDown={() => setIsShownPassword(!isShownPassword)}
                edge="end"
              >
                {isShownPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
        ref={ref}
      />
    );
  },
);
