import { PrimaryButton } from '@e-schema/styles/Employee.ts';

import { Box, Typography, styled } from '@mui/material';

import { TextWithButtonsTypes } from '@core/components/Texts/TextWithButtons/TextWithButtons.types.ts';

import { theme } from '@/theme';
import { palette } from '@/theme/pallete.ts';

export const TextWithButtons = (props: TextWithButtonsTypes) => {
  return (
    <Container>
      <CustomStyledHeaderTitle>{props.title}</CustomStyledHeaderTitle>
      <CustomStyledHeaderSubtitle>{props.subtitle}</CustomStyledHeaderSubtitle>
      <ButtonContainer>
        <PrimaryButton
          variant="contained"
          disabled={props.primaryButton.disabled || false}
          style={{
            backgroundColor: theme.palette.grey[600],
          }}
          onClick={props.primaryButton.onClick}
        >
          <Typography
            fontFamily="Inter"
            fontWeight={500}
            fontSize={'14px'}
            lineHeight={'20px'}
          >
            {props.primaryButton.text}
          </Typography>
        </PrimaryButton>

        <PrimaryButton
          variant="contained"
          disabled={props.secondaryButton.disabled || false}
          style={{
            backgroundColor: theme.palette.primary[600],
          }}
          onClick={props.secondaryButton.onClick}
        >
          <Typography
            fontFamily="Inter"
            fontWeight={500}
            fontSize={'14px'}
            lineHeight={'20px'}
          >
            {props.secondaryButton.text}
          </Typography>
        </PrimaryButton>
      </ButtonContainer>
    </Container>
  );
};
const CustomStyledHeaderTitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '24px',
  lineHeight: '24px',
  fontWeight: 500,
  color: palette.text.primary,
});

const CustomStyledHeaderSubtitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  whiteSpace: 'pre-wrap',
  color: palette.text.secondary,
});

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '20px',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: '20px',
});
