export enum SystemParameterKeysEnum {
  TIME = 'TIME',
  TIME_MINIMAL = 'TIME_MINIMAL',
  TIME_MAXIMAL = 'TIME_MAXIMAL',
  TJ = 'TJ',
  TPZ = 'TPZ',
  AREA = 'AREA',
  OPERATOR_NUMBERS = 'OPERATOR_NUMBERS',
  QUANTITY = 'QUANTITY',
  DISTANCE = 'DISTANCE',
}
