import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApolloCache } from '@apollo/client';

import {
  EFlow,
  EFlowStatuses,
  UpdateEflowStatusMutation,
  useUpdateEflowStatusMutation,
} from '@/__generated__/graphql';
import { useOrganizationStore } from '@/stores';

export const useUpdateEflowStatus = (
  flowId: string,
  status: EFlowStatuses,
  openSnackbar?: (message: string) => void,
) => {
  const { t } = useTranslation('eflow');

  const { organization } = useOrganizationStore();
  const { companyName } = useParams();

  const [updateEflowStatus] = useUpdateEflowStatusMutation();
  const [isSetAsFinishedDisabled, setIsSetAsFinishedDisabled] = useState(
    status === EFlowStatuses.Completed,
  );

  const updateEflowCache = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cache: ApolloCache<any>,
    status: EFlowStatuses,
    data: UpdateEflowStatusMutation,
  ) => {
    cache.modify({
      id: cache.identify(data.updateEflowStatus),
      fields: {
        getEflow(currentEflow) {
          return {
            ...(currentEflow as EFlow),
            status,
          };
        },
      },
    });
  };

  const setEflowStatusAsCompleted = async () => {
    try {
      await updateEflowStatus({
        variables: {
          input: {
            id: flowId,
            organizationId: organization[companyName!],
            status: EFlowStatuses.Completed,
          },
        },
        update(cache, { data }) {
          updateEflowCache(cache, EFlowStatuses.Completed, data!);
        },
      });
      if (openSnackbar)
        openSnackbar(t('eflow.analysisCompletion.snackBarSuccessMessage'));
    } catch (e) {
      if (openSnackbar)
        openSnackbar(t('eflow.analysisCompletion.snackBarErrorMessage'));
    }
    setIsSetAsFinishedDisabled(false);
  };

  return {
    isSetAsFinishedDisabled,
    setEflowStatusAsCompleted,
  };
};
