import {
  CalculateAllOperationsResults,
  CalculateAllOperationsTypes,
} from '@e-flow/pages/flowReport/Logic/CalculateAllOperations/CalculateAlloperations.types.ts';

/**
 * Calculate number of all operations in eFLow
 * @param props - CalculateAllOperationsTypes
 * @returns CalculateAllOperationsResults - Number of all operations
 */
export const CalculateAllOperations = (
  props?: CalculateAllOperationsTypes,
): CalculateAllOperationsResults => {
  const results: CalculateAllOperationsResults = { asIs: 0, toBe: 0 };

  if (!props) return results;

  for (const key in props) {
    results.asIs += props[key].asIs;
    results.toBe += props[key].toBe;
  }

  return results;
};
