import { z } from 'zod';

/**
 * Password validation schema
 * @param passwordRequired Required error message
 * @param passwordInvalid Invalid error message
 * @returns Password validation schema
 * @example const passwordValidationSchema = passwordValidationSchema('Password is required', 'Password is invalid');
 */
export const passwordValidationSchema = (
  passwordRequired: string,
  passwordInvalid: string,
) =>
  z
    .string({
      required_error: passwordRequired,
    })
    .min(1, passwordRequired)
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{12,}$/,
      passwordInvalid,
    );
