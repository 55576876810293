import { RowStack } from '@organizations/styles';

import { Stack, Typography } from '@mui/material';

import { CellModalProps } from './InfoModalContent.types';

export const InfoModalContent = ({ contentText, children }: CellModalProps) => {
  return (
    <>
      <Stack padding={'20px 24px 20px 24px'}>
        <Typography variant="bodyRegular">{contentText}</Typography>
      </Stack>
      <RowStack
        padding={'16px 24px 24px 24px'}
        gap="12px"
        justifyContent={'end'}
      >
        {children}
      </RowStack>
    </>
  );
};
