import { Box, CircularProgress, styled } from '@mui/material';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`;

export const LoadingView = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};
