import {
  AddEflowCommentInput,
  EFlowStepCommentDto,
} from '@/__generated__/graphql.ts';

export function createCommentKey(
  index: number,
  comment: EFlowStepCommentDto | AddEflowCommentInput,
) {
  if ('id' in comment) {
    return comment.id;
  }
  return index;
}
