import { useEffect, useState } from 'react';

import { TextField } from '@mui/material';

import { ParameterInputProps } from './ParameterInput.types';

export const ParameterInput = ({ onChange, value }: ParameterInputProps) => {
  const [paramValue, setParamValue] = useState<string>('');

  useEffect(() => {
    setParamValue(value ? value.toFixed(2).toString() : '');
  }, [value]);

  return (
    <TextField
      onChange={({ target: { value } }) => {
        const replacedValue = value.replace(',', '.');
        if (!replacedValue.match(/^(\d*([.,])?\d{0,2}$)/)) return;
        setParamValue(replacedValue);
      }}
      onBlur={() => {
        if (paramValue && !isNaN(+paramValue)) {
          setParamValue(parseFloat(paramValue).toFixed(2));
          onChange(parseFloat(paramValue));
        } else {
          setParamValue('');
        }
      }}
      value={paramValue}
      sx={{ width: '328px' }}
      size="small"
      placeholder="Dodaj"
      inputMode={'decimal'}
    />
  );
};
