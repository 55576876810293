import { Tree } from 'react-organizational-chart';

import { Box, useTheme } from '@mui/material';

import { OrganizationNode } from '@core/types';

import NodeCell from './NodeCell/NodeCell';
import { TreeDetailsInterface } from './OrganizationTree.types';
import RenderNode from './RenderNode';

export function ConvertJsonToReactTree(
  json: OrganizationNode,
  details: TreeDetailsInterface,
) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '30px',
      }}
    >
      {/* {//TODO when adding networking please add security for renderind :)} */}

      <Tree
        key={`${json?.id}_${json?.level_id}`}
        label={
          <NodeCell
            {...json}
            details={details}
            members={json.members}
            bgColor={theme.palette.success[800]}
            level_id={0}
          />
        }
        lineHeight="25px"
        lineWidth=""
        lineBorderRadius="0px"
        lineColor="#dcdfe1"
        nodePadding="12px"
      >
        {json?.children?.map((child) => RenderNode(child, 1, details))}
      </Tree>
    </Box>
  );
}
