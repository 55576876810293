/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import { Sidebar } from '@e-schema/components';

import { Box, Divider, styled } from '@mui/material';

import { useGlobalRedirects } from '@core/redirects';

import { UserSidebarContent } from '@/core/components';

export const EmployeeSettings = () => {
  const { t } = useTranslation();
  const params = useParams();
  const spitedUrl = window.location.href.split('/');
  const navigate = useNavigate();
  const { eSchemaPath } = useGlobalRedirects();

  const companyName = params.companyName || '';
  const id = params.id || '-1';
  const baseUrl = `/${companyName}/e-schema/employees/${id}/`;

  useEffect(() => {
    if (
      params.id === spitedUrl[spitedUrl.length - 1] ||
      spitedUrl[spitedUrl.length - 1] === ''
    ) {
      navigate(`general-settings`);
    }
  }, []);

  if (!companyName) return <Navigate to={`/login`} />;
  return (
    <Container>
      <Divider>
        <Sidebar baseWidth={360} resize={false}>
          <UserSidebarContent
            title={t('employees.title')}
            back={eSchemaPath}
            menu={[
              {
                title: t('employee.menu.generalSetting'),
                to: baseUrl + 'general-settings',
              },
            ]}
          />
        </Sidebar>
      </Divider>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: 'calc(100% - 57px)',
  display: 'flex',
});

const ContentContainer = styled(Box)({
  padding: 24,
  overflow: 'auto',
  display: 'flex',
  width: '100%',
});
