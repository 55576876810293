import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PeriodSelect } from '@kpi/components/SettlementPeriodSelect';
import { HandleChartVisibilityModal } from '@kpi/modals/HandleChartVisibility/HandleChartVisibilityModal';
import { useKpiDashboardContext } from '@kpi/pages/Dashboard/contexts/KpiDashbaordContext.tsx';
import { useDashboardPermissions } from '@kpi/pages/Dashboard/hooks/useDashboardPermissions.tsx';
import dayjs from 'dayjs';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton } from '@mui/material';
import { styled } from '@mui/styles';
import { Stack } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';

import { Button, OrganizationUnitSelect, UserSelect } from '@core/components';
import { useCompanyId, useGetCurrentUserDetails, useModal } from '@core/hooks';
import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context.tsx';

import { Employee } from '@/__generated__/graphql';

export const KpiFilters = () => {
  const {
    canAddChart,
    organizationUnit,
    settlementPeriod,
    isEmployeeDashboard,
    canChangeSearchParams,
    isCreateChartButtonDisabled,
    isOrganizationUnitDashboard,
    isFirstTime,
    defaultOrganizationUnitData,
    setEmployeeId,
    setSelectedMonth,
    setOrganizationUnit,
    setSettlementPeriod,
  } = useKpiDashboardContext();

  const { user } = useGetCurrentUserDetails();
  const { companyId } = useCompanyId();

  const { isModalVisible, setIsModalVisible } = useModal();

  const { addOnEmpty } = useDashboardContext();

  const { t } = useTranslation('kpi');

  const { dashboardType } = useDashboardContext();

  const { canHideCharts } = useDashboardPermissions(dashboardType);

  useEffect(() => {
    if (user) {
      setEmployeeId(user.id);
    }
  }, [user, setEmployeeId]);

  return (
    <Container>
      <Stack flexDirection={'row'} gap={'24px'}>
        <PeriodSelect
          onChange={(val) => {
            setSettlementPeriod(val);
          }}
          value={settlementPeriod}
          width={'100px'}
        />

        <DatePicker
          openTo="month"
          views={['month']}
          sx={{ width: '150px' }}
          slotProps={{ textField: { size: 'small' } }}
          defaultValue={dayjs().subtract(1, 'month')}
          onChange={(date) => {
            setSelectedMonth(date?.month());
          }}
        />

        {(isOrganizationUnitDashboard || isEmployeeDashboard) && (
          <OrganizationUnitSelect
            disabled={!canChangeSearchParams}
            organizationUnitId={user?.organizationUnitGuid}
            defaultValue={defaultOrganizationUnitData}
            onChange={(organizationUnitId) =>
              setOrganizationUnit(organizationUnitId as string)
            }
          />
        )}

        {isEmployeeDashboard && (
          <UserSelect
            key={organizationUnit}
            organizationId={companyId}
            organisationUnitIds={
              organizationUnit ? [organizationUnit] : undefined
            }
            onChange={setEmployeeId}
            disabled={!organizationUnit || !canChangeSearchParams}
            defaultUser={user as Employee}
            flag={isFirstTime}
            placeholder={
              !organizationUnit ? t('dashboards.chooseUnitFirst') : ''
            }
          />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" gap="16px">
        {canHideCharts && (
          <IconButton
            onClick={() => {
              document.body.style.overflow = 'hidden';
              setIsModalVisible(true);
            }}
          >
            <SettingsOutlinedIcon />
          </IconButton>
        )}
        {canAddChart && (
          <>
            <Button
              onClick={() => {
                void addOnEmpty();
              }}
              disabled={isCreateChartButtonDisabled}
            >
              {t('dashboards.createChart')}
            </Button>
          </>
        )}
      </Stack>
      <HandleChartVisibilityModal
        close={() => {
          document.body.style.overflow = 'auto';
          setIsModalVisible(false);
        }}
        display={isModalVisible}
      />
    </Container>
  );
};

const Container = styled(Stack)({
  gap: '24px',
  marginTop: '24px',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
