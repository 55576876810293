import { useMemo } from 'react';

import { useFetchSettlementPeriods } from '@kaizen/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/Functions';
import { useSettlementPeriod } from '@kpi/module-settings/SettlementPeriods/hooks/useSettlemetPeriod/useSettlementPeriod';

import { useCompanyId, useGetCurrentUserDetails } from '@core/hooks';

export const useUserDashboard = ({
  openWidgetSidebar,
  closeWidgetSidebar,
  isDrawerOpen,
}: {
  openWidgetSidebar: () => void;
  closeWidgetSidebar: () => void;
  isDrawerOpen: boolean;
}) => {
  const { user } = useGetCurrentUserDetails();
  const { companyId } = useCompanyId();

  const { settlementPeriods } = useFetchSettlementPeriods();

  const { currentActivePeriod } = useSettlementPeriod();

  const kaizenSettlementPeriod = useMemo(() => {
    return settlementPeriods.find((period) => period.isActive);
  }, [settlementPeriods]);

  return {
    companyId,
    organizationUnitId: user?.organizationUnitGuid,
    id: user?.id,
    openWidgetSidebar,
    kaizenSettlementPeriodId: kaizenSettlementPeriod?.id,
    closeWidgetSidebar,
    activeKpiSettlentPeriod: currentActivePeriod?.id,
    isDrawerOpen,
  };
};
