import { useFormatEflowStepForUpdate } from '@e-flow/hooks/useFormatEflowForUpdate/useFormatEflowStepForUpdate.ts';
import {
  PrepareRecordForSynchronization,
  ReturnTypeForCreate,
  ReturnTypeForDelete,
  ReturnTypeForUpdate,
} from '@e-flow/hooks/useSynchronizedFlows/Functions/PrepareRecordForSynchronization.ts';
import { SynchronizedFlowsActionTypes } from '@e-flow/hooks/useSynchronizedFlows/SynchronizedFlowsAction.enum.ts';
import { EFlowStepTypes } from '@e-flow/pages/flowSteps/hooks/useEflowSteps/types/eFlowStep.types.ts';
import { pick } from 'lodash';

import {
  AsIsToBeNamesEnum,
  useCreateEflowStepMutation,
  useRemoveEflowStepsMutation,
  useUpdateEflowStepMutation,
} from '@/__generated__/graphql.ts';

export const useSynchronizer = (companyId: string) => {
  const { handlePrepareEflowStepForUpdate } =
    useFormatEflowStepForUpdate(companyId);

  const [createEflowStep] = useCreateEflowStepMutation();
  const [updateEflowStep] = useUpdateEflowStepMutation();
  const [deleteEflowStep] = useRemoveEflowStepsMutation();

  const synchronizeStep = async (
    record: Awaited<ReturnType<typeof PrepareRecordForSynchronization>>,
    eFlowId: string,
    analizeType: AsIsToBeNamesEnum,
  ) => {
    if (record.actionType === SynchronizedFlowsActionTypes.CREATE) {
      if (record.files.length <= 1)
        return _createEflowStep(record, eFlowId, analizeType);

      return await _createEflowStepWithMultipleFiles(
        record,
        eFlowId,
        analizeType,
      );
    }

    if (record.actionType === SynchronizedFlowsActionTypes.UPDATE) {
      if (record.files.length === 0)
        return _updateEflowStep(record, eFlowId, analizeType);

      for (const file of record.files) {
        const updateData: ReturnTypeForUpdate = {
          eFlowStep: {
            ...record.eFlowStep,
            id: record.eFlowStepId,
          },
          eFlowStepId: record.eFlowStepId,
          actionType: SynchronizedFlowsActionTypes.UPDATE,
          files: [file],
        };

        await _updateEflowStep(updateData, eFlowId, analizeType);
      }
    }

    if (record.actionType === SynchronizedFlowsActionTypes.DELETE) {
      return _deleteEflowStep(record, eFlowId, analizeType);
    }
  };

  const _createEflowStep = async (
    record: ReturnTypeForCreate,
    eFlowId: string,
    analizeType: AsIsToBeNamesEnum,
  ) => {
    return await createEflowStep({
      variables: {
        input: {
          ...record.eFlowStep,
          organizationId: companyId,
          analizeType,
          eFlowId,
        },
        ...(record.files && { files: record.files }),
      },
    });
  };

  const _updateEflowStep = async (
    record: ReturnTypeForUpdate,
    eFlowId: string,
    analizeType: AsIsToBeNamesEnum,
  ) => {
    return await updateEflowStep({
      variables: {
        input: {
          ...handlePrepareEflowStepForUpdate(
            record.eFlowStep as unknown as EFlowStepTypes,
          ),
          organizationId: companyId,
          analizeType,
          eFlowId,
        },
        ...(record.files && { files: record.files }),
      },
    });
  };

  const _deleteEflowStep = async (
    record: ReturnTypeForDelete,
    eFlowId: string,
    analizeType: AsIsToBeNamesEnum,
  ) => {
    return await deleteEflowStep({
      variables: {
        input: {
          eFlowStepsIds: [record.eFlowStep.id],
          eFlowId: eFlowId,
          organizationId: companyId,
          analizeType,
        },
      },
    });
  };

  const _createEflowStepWithMultipleFiles = async (
    record: ReturnTypeForCreate,
    eFlowId: string,
    analizeType: AsIsToBeNamesEnum,
  ) => {
    const { data } = await createEflowStep({
      variables: {
        input: {
          ...record.eFlowStep,
          organizationId: companyId,
          analizeType,
          eFlowId,
        },
        files: [record.files[0]],
      },
    });

    if (data?.createEflowStep) {
      const updateData: ReturnTypeForUpdate = {
        eFlowStep: {
          ...pick(record.eFlowStep, [
            'operation',
            'operationName',
            'eFlowId',
            'organizationId',
            'analizeType',
          ]),
          id: data.createEflowStep.id,
        },
        eFlowStepId: record.eFlowStepId,
        actionType: SynchronizedFlowsActionTypes.UPDATE,
        files: record.files.slice(1),
      };

      const newFiles = record.files.slice(1, record.files.length);

      for (const file of newFiles) {
        await Promise.all([
          _updateEflowStep(
            { ...updateData, files: [file] },
            eFlowId,
            analizeType,
          ),
        ]);
      }

      return true;
    }
  };

  return {
    synchronizeStep,
  };
};
