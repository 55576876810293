import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { SubmissionDetailsProps } from '../../SubmissionDetails.types';
import { DetailRow, DetailRowLabel } from '../styles/styles';

export const SubmissionPointsSection = (props: SubmissionDetailsProps) => {
  const { t } = useTranslation('kaizen');
  return (
    <DetailRow>
      <DetailRowLabel>{t('submissionDetails.points')}</DetailRowLabel>
      <Typography>
        {props.assignedPoints ? props.assignedPoints : '-'}
      </Typography>
    </DetailRow>
  );
};
