import { ReactNode, createContext, useContext } from 'react';

import { Snackbar } from '@mui/material';

import { useSnackbar } from '@core/hooks';

type SnackbarContextType = ReturnType<typeof useSnackbar>;

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

// eslint-disable-next-line react-refresh/only-export-components
export const useSnackbarContext = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      'useSnackbarContext must be used within a SnackbarProvider',
    );
  }
  return context;
};

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const snackbarControls = useSnackbar();

  return (
    <SnackbarContext.Provider value={snackbarControls}>
      {children}
      <Snackbar
        autoHideDuration={3000}
        open={snackbarControls.isSnackBarOpen}
        message={snackbarControls.snackBarMessage}
        onClose={snackbarControls.closeSnackbar}
      />
    </SnackbarContext.Provider>
  );
};
