import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AnalizeStepCommonTypes } from '@e-flow/pages/newAnalysis/AnalizeStepCommon.types.ts';
import { zodResolver } from '@hookform/resolvers/zod';

import { Stack, Typography, styled } from '@mui/material';

import { Button, CharsCountingInput } from '@/core/components';
import { PrimaryButton } from '@/pages/e-schema/styles/Employee.ts';
import { theme } from '@/theme';

import { productModuleValidationSchema } from './ProductModule.schema';
import { ProductModuleDefaultValues } from './ProductModuleDefaultValues.ts';

export const ProductModule = (props: AnalizeStepCommonTypes) => {
  const { t } = useTranslation('eflow');
  const { t: tCommon } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: ProductModuleDefaultValues,
    resolver: zodResolver(productModuleValidationSchema(t)),
  });

  useEffect(() => {
    reset({
      ...props.currentFormValues,
      description: props.currentFormValues?.description || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentFormValues]);

  const onSubmit: SubmitHandler<typeof ProductModuleDefaultValues> = (data) => {
    props.setMainAnalysisPart(data);
    props.moveNext();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <CustomForm onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3">{t('newAnalysis.steps.product')}</Typography>
      <FormSection>
        <Typography variant="bodyMedium">
          {t('newAnalysis.product.productNameLabel')}
        </Typography>
        <Stack maxWidth={328} gap="8px">
          <CharsCountingInput
            maxInputLength={128}
            {...register('name')}
            helperText={errors.name?.message}
            error={!!errors.name}
            placeholder={t('newAnalysis.product.productNamePlaceholder')}
            variant="outlined"
            size="small"
            fullWidth
            required
          />
        </Stack>
      </FormSection>
      <FormSection>
        <Typography variant="bodyMedium">
          {t('newAnalysis.product.descriptionLabel')}
        </Typography>
        <Stack gap="8px">
          <CharsCountingInput
            maxInputLength={512}
            {...register('description')}
            error={!!errors.description}
            placeholder={t('newAnalysis.product.descriptionPlaceholder')}
            variant="outlined"
            size="small"
            helperText={errors.description?.message}
            multiline
            fullWidth
            required
          />
        </Stack>
      </FormSection>
      <StyledStack>
        <PrimaryButton
          onClick={props.stepBack}
          sx={{
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.common.black,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '&:hover': {
              backgroundColor: theme.palette.grey[200],
            },
          }}
        >
          <Typography variant="bodyRegular">
            {tCommon('common.back')}
          </Typography>
        </PrimaryButton>
        <Button
          variant="contained"
          disabled={watch().description.length < 10 || watch().name.length < 10}
          sx={{
            backgroundColor: theme.palette.primary[600],
          }}
        >
          <Typography variant="bodyRegular">
            {tCommon('common.continue')}
          </Typography>
        </Button>
      </StyledStack>
    </CustomForm>
  );
};

const CustomForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxWidth: '528px',
});

const FormSection = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const StyledStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  gap: '12px',
  marginTop: '10px',
});
