import { useTranslation } from 'react-i18next';

import { AddNewComment } from '@e-flow/components/CommentModule/components/AddNewComment.tsx';
import { DisplayComments } from '@e-flow/components/CommentModule/components/DisplayComments.tsx';
import { useCommentModule } from '@e-flow/components/CommentModule/hooks/useCommentModule/useCommentModule.tsx';
import { ModuleContainer } from '@e-flow/pages/flowSteps/components';

import { EFlowStepCommentDto } from '@/__generated__/graphql';
import { CommentModuleTypes } from '@/pages/e-flow/components/CommentModule/CommentModule.types';
import { sortComments } from '@/utils';

export const CommentModule = ({
  titleVariant = 'h3',
  ...props
}: CommentModuleTypes) => {
  const { t } = useTranslation('eflow');

  const { onSubmit, onEdit } = useCommentModule({
    currentComments: props.currentComments,
    commentTypes: props.commentTypes,
    onFlowStepChange: props.onFlowStepChange,
    stepId: props.stepId,
  });

  return (
    <ModuleContainer
      title={`${t('flowSteps.comments')} ${props.showNumberOfComments ? ` (${props.currentComments ? props.currentComments.length : 0})` : ''}`}
      titleVariant={titleVariant}
    >
      <AddNewComment
        onCancel={props.onCancel}
        addCommentToReport={props.addCommentToReport}
        commentTypes={props.commentTypes}
        onSubmit={onSubmit}
      />
      <DisplayComments
        /** Due to using multiple types for comments that is the most optimal way I found for this to work */
        currentComments={
          props.currentComments && props.currentComments.length > 0
            ? (sortComments(
                props.currentComments as EFlowStepCommentDto[],
              ) as EFlowStepCommentDto[])
            : []
        }
        commentTypes={props.commentTypes}
        stepId={props.stepId}
        onFlowStepChange={props.onFlowStepChange}
        updateCommentModels={props.updateCommentModels}
        commentModels={props.commentModels}
        onEdit={onEdit}
      />
    </ModuleContainer>
  );
};
