import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  CheckBoxWithTextModalDefaultTypes,
  useCheckBoxWithTextModalHookProps,
} from './CheckBoxWithTextModal.types';

export const useCheckBoxWithTextModal = (
  props: useCheckBoxWithTextModalHookProps,
) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CheckBoxWithTextModalDefaultTypes>({
    defaultValues: {},
  });

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const onSubmit = async (data: CheckBoxWithTextModalDefaultTypes) => {
    const isValid = await props.handleResponse(data);

    if (!isValid) return;
    resetForm();
  };

  const resetForm = () => {
    setValue('text', '');
    setValue('checkbox', false);
    setIsCheckboxChecked(false);
  };

  return {
    handleSubmit,
    setValue,
    errors,
    onSubmit,
    resetForm,
    isCheckboxChecked,
    handleCheckboxChange: () => {
      setValue('checkbox', !isCheckboxChecked);
      setIsCheckboxChecked(!isCheckboxChecked);
    },
    setCheckboxValue: (value: boolean) => {
      setValue('checkbox', value);
      setIsCheckboxChecked(value);
    },
  };
};
