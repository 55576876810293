/* eslint-disable @typescript-eslint/no-misused-promises */
import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Box, Button, styled, useTheme } from '@mui/material';

import {
  AuthScreenWrapper,
  EmailTextField,
  PasswordTextField,
  TextButton,
} from '@/core/components';
import { CCognitoHandler } from '@/core/providers/Cognito';
import { InputsColumnContainer } from '@/core/styles';
import { useLoginStatusStore } from '@/stores';
import {
  ValidationSchema,
  validationSchema,
} from '@/utils/loginValidationSchema';

const defaultValues = {
  email: '',
  password: '',
};

export const Login = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setStatus } = useLoginStatusStore();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues,
    resolver: zodResolver(validationSchema(t('login.requiredField'))),
  });

  const EmailValidationSchema = z.string().email();

  const onSubmit: SubmitHandler<ValidationSchema> = useCallback(
    async (data) => {
      try {
        EmailValidationSchema.parse(data.email);
      } catch (error) {
        setError('email', {
          type: 'custom',
          message: t('login.invalidEmail'),
        });
        return;
      }

      try {
        const { challengeName, cognitoAtributes } = await CCognitoHandler.login(
          data.email,
          data.password,
        );

        switch (challengeName) {
          case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
            navigate('/login/set-new-password');
            setStatus('CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED');
            break;
          case 'SUCCESS':
            if (cognitoAtributes) {
              return navigate(`/${cognitoAtributes.organizationUrl}/`);
            }
            break;
          case 'INVALID_CREDENTIALS':
            setError('email', {
              type: 'custom',
              message: t('login.noExistEmail'),
            });
            setError('password', {
              type: 'custom',
              message: t('login.wrongPassword'),
            });
            break;

          default:
            break;
        }
      } catch (e) {
        captureException(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <AuthScreenWrapper>
      <h2>{t('login.title')}</h2>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputsColumnContainer>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <EmailTextField
                field={field}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />

          <Box width="100%">
            <PasswordTextField
              label={t('login.password')}
              error={!!errors.password}
              helperText={errors.password?.message}
              {...register('password')}
            />
          </Box>
          <SubmitButton
            type="submit"
            variant="contained"
            size="medium"
            style={{
              color: theme.palette.common.white,
            }}
            fullWidth
          >
            {t('login.title')}
          </SubmitButton>
          <TextButton
            text={t('login.forgotPassword')}
            onClick={() => navigate('/forgot-password/email')}
          />
        </InputsColumnContainer>
      </FormContainer>
    </AuthScreenWrapper>
  );
};

const FormContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
});

const SubmitButton = styled(Button)({
  fontFamily: 'Inter',
  fontWeight: '500',
  height: '40px',
  lineHeight: '20px',
  textTransform: 'none',
});
