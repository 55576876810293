import { useTranslation } from 'react-i18next';

import { SettlementPeriodsTableRow } from '@kaizen/module-settings/SettlementPeriods/components';
import { SettlementPeriodTableProps } from '@kaizen/module-settings/SettlementPeriods/components/SettlementPeriodTable/SettlementPeriodTable.types.ts';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';

import { KaizenSettlementPeriodFragment } from '@/__generated__/graphql.ts';

export const SettlementPeriodsTable = ({
  settlementPeriods,
}: SettlementPeriodTableProps) => {
  const { t } = useTranslation('kaizen');

  return (
    <TableContainer>
      <Table size="medium">
        <TableHead>
          <TableCell>
            <b>{t('settlementPeriod.name')}</b>
          </TableCell>
        </TableHead>
        <TableBody>
          {settlementPeriods.map(
            (settlementPeriod: KaizenSettlementPeriodFragment) => (
              <SettlementPeriodsTableRow
                {...settlementPeriod}
                key={settlementPeriod.id}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
