/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';

import { SubmissionManagementCommonTypes } from '@kaizen/components/Buttons/SubmissionManagementCommon/SubmissionManagementCommon.types.ts';
import { useCancelSubmission } from '@kaizen/hooks/useCancelSubmission/useCancellSubmission.tsx';

import { Typography } from '@mui/material';

import { LoadingButton } from '@core/components';
import { useSnackbarContext } from '@core/contexts';

export const CancelSubmission = ({
  submissionId,
}: SubmissionManagementCommonTypes) => {
  const { t: kaizenT } = useTranslation('kaizen');

  const { openSnackbar } = useSnackbarContext();

  const { cancelSubmission, isLoading } = useCancelSubmission();

  const cancelSubmissionHandler = async () => {
    if (await cancelSubmission(submissionId))
      openSnackbar(kaizenT('updateStatus.successMessage'));
    else openSnackbar(kaizenT('updateStatus.errorMessage'));
  };

  return (
    <LoadingButton
      isLoading={isLoading}
      onClick={cancelSubmissionHandler}
      color={'error'}
    >
      <Typography>{kaizenT('buttons.cancelSubmission')}</Typography>
    </LoadingButton>
  );
};
