import { useCreateChartContext } from '@kpi/modals/HandleKpiChartModal/context';
import { EmptyDashboard } from '@kpi/pages/Dashboard/components/EmptyDashboard.tsx';
import { KpiFilters } from '@kpi/pages/Dashboard/components/KpiFilters.tsx';
import { useKpiDashboardContext } from '@kpi/pages/Dashboard/contexts/KpiDashbaordContext.tsx';

import { Box } from '@mui/material';
import { styled } from '@mui/styles';

import { DashboardProvider } from '@core/modules/DashbordModule/contexts/DashboardModule.context.tsx';
import { Dashboard } from '@core/modules/DashbordModule/views/Dashboard.tsx';

import { DashboardTypesEnum } from '@/__generated__/graphql';

export const KpiDashboard = (props: { dashboardType: DashboardTypesEnum }) => {
  const { organizationUnit, employeeId, companyId, settlementPeriod } =
    useKpiDashboardContext();

  const { addNewKpiChart } = useCreateChartContext();

  return (
    <DashboardProvider
      dashboardType={props.dashboardType}
      dashboardData={{
        type: props.dashboardType,
        organizationUnitId: organizationUnit,
        organizationId: companyId,
        settlementPeriodId: settlementPeriod,
        employeeId: employeeId,
      }}
      emptyDashboardComponent={<EmptyDashboard />}
      addOnEmpty={addNewKpiChart}
    >
      <Container>
        <KpiFilters />
        <Dashboard />
      </Container>
    </DashboardProvider>
  );
};

const Container = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});
