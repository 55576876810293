import { useTranslation } from 'react-i18next';

import { signOut } from '@aws-amplify/auth';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Divider, Menu, Stack, Typography, styled } from '@mui/material';

import { checkSystemRolesPermission } from '@core/utils';

import { SystemRoles } from '@/__generated__/graphql.ts';
import { client } from '@/apollo.ts';
import { useGlobalRedirects } from '@/core/redirects';
import { useUserStore } from '@/stores';
import { useOrganizationStore } from '@/stores/useOrganizationStore/useOrganizationStore.ts';

import { CustomMenuItem, EmployeeAvatar } from '../index.ts';
import { MenuToAvatarProps } from './types.ts';

export const CustomMenu = ({
  anchorEl,
  open,
  handleClose,
}: MenuToAvatarProps) => {
  const { t } = useTranslation();

  const { openAccountSettings, openLoggedOut, openOrganizationSettings } =
    useGlobalRedirects();

  const { name, email, lastName, userGroups, profilePicture } = useUserStore();
  const { resetStorage: resetOrganizationStore } = useOrganizationStore();
  const { resetStorage: resetUserStore } = useUserStore();

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <HeaderMenuItem>
        <EmployeeAvatar name={name} imageUrl={profilePicture} size={48} />
        <Stack>
          <Typography variant="bodyMedium" sx={{ fontSize: '14px' }}>
            {`${name} ${lastName}`}
          </Typography>
          <Typography variant="bodyRegular">{email}</Typography>
        </Stack>
      </HeaderMenuItem>
      <Divider />
      {checkSystemRolesPermission(userGroups, [
        SystemRoles.Root,
        SystemRoles.SystemAdmin,
        SystemRoles.KeyUser,
      ]) && (
        <CustomMenuItem
          text={t('common.AdminPanel')}
          link="/organizations"
          icon={<DashboardOutlinedIcon />}
        />
      )}
      {checkSystemRolesPermission(userGroups, [
        SystemRoles.Root,
        SystemRoles.SystemAdmin,
        SystemRoles.KeyUser,
        SystemRoles.Administrator,
      ]) && (
        <CustomMenuItem
          text={t('common.organizationSettings')}
          icon={<SettingsOutlinedIcon />}
          onClick={openOrganizationSettings}
        />
      )}
      <CustomMenuItem
        text={t('common.accountSettings')}
        icon={<ManageAccountsOutlinedIcon />}
        onClick={openAccountSettings}
      />
      <CustomMenuItem
        text={t('login.logout')}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={async (): Promise<void> => {
          await signOut();
          resetOrganizationStore();
          resetUserStore();
          void client.cache.reset();
          openLoggedOut();
        }}
        icon={<ExitToAppIcon />}
      />
    </Menu>
  );
};
const HeaderMenuItem = styled(Box)({
  width: '328px',
  height: '96px',
  padding: '24px',
  gap: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
