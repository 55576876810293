import { useTranslation } from 'react-i18next';

import { RemoveSettlementPeriodModalViews } from '@kaizen/module-settings/SettlementPeriods/Enums/RemoveSettlementPeriodModalViews.enum.ts';
import { RemoveSettlementPeriodWarningModal } from '@kaizen/module-settings/SettlementPeriods/Modals/RemoveSettlementPeriodModalViews/RemoveSettlementPeriodWarningModal.tsx';
import { useKaizenSettlementPeriodContext } from '@kaizen/module-settings/SettlementPeriods/contexts';

import { InformationModal } from '@core/components';

export const RemoveSettlementPeriodModal = () => {
  const { t } = useTranslation('kaizen');

  const {
    isRemoveSettlementPeriodModalOpen,
    closeRemoveSettlementPeriodModal,
    currentRemoveSettlementPeriodModalView,
  } = useKaizenSettlementPeriodContext();

  if (
    currentRemoveSettlementPeriodModalView ===
    RemoveSettlementPeriodModalViews.CAN_NOT_REMOVE
  ) {
    return (
      <InformationModal
        headerText={t('deleteSettlementPeriod.modalHeader')}
        closeModal={closeRemoveSettlementPeriodModal}
        confirmText={t('deleteSettlementPeriod.infoModalSubmitButton')}
        content={t('deleteSettlementPeriod.infoModalContentText')}
        onConfirm={closeRemoveSettlementPeriodModal}
        display={isRemoveSettlementPeriodModalOpen}
      />
    );
  }

  if (
    currentRemoveSettlementPeriodModalView ===
    RemoveSettlementPeriodModalViews.CONFIRMATION
  ) {
    return <RemoveSettlementPeriodWarningModal />;
  }

  return null;
};
