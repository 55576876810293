import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import 'react-photo-view/dist/react-photo-view.css';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import 'dayjs/locale/pl';

import { ThemeProvider } from '@mui/material';
import { ThemeProvider as DoNotRemoveMePleaseOrEverythingWillFailProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  FilePreviewProvider,
  ModalProvider,
  SnackbarProvider,
} from '@core/contexts';

import { configureAmplify } from '@/amplify.ts';

import App from './App.tsx';
import { client } from './apollo.ts';
import './i18n';
import './index.css';
import { theme } from './theme/index.ts';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID!);
configureAmplify();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transaction
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <DoNotRemoveMePleaseOrEverythingWillFailProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
            <SnackbarProvider>
              <ModalProvider>
                <FilePreviewProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </FilePreviewProvider>
              </ModalProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ApolloProvider>
      </ThemeProvider>
    </DoNotRemoveMePleaseOrEverythingWillFailProvider>
  </React.StrictMode>,
);
