import { useEffect } from 'react';

import { useCompanyId } from '@core/hooks';

import { useFindChartLazyQuery } from '@/__generated__/graphql';

export const useFindKpiChart = (chartId?: string) => {
  const [findChart, { data, loading: isLoading }] = useFindChartLazyQuery();

  const { companyId } = useCompanyId();

  useEffect(() => {
    if (!companyId || !chartId) return;
    void findChart({
      variables: { input: { organizationId: companyId, id: chartId } },
    });
    // eslint-disable-next-line
  }, [companyId, chartId]);

  return {
    isLoading,
    chart: data?.findChart,
  };
};
