import { captureException } from '@sentry/react';
import { GetCheckboxOrganizationUnitStructureQuery } from '@/__generated__/graphql';

export type Node =
  GetCheckboxOrganizationUnitStructureQuery['getOrganizationUnitsStructure'];

/**
 *
 * @param parent whole tree
 * @param child new Added child
 * @returns whole tree with added new node
 * @description function find node and add new to children array
 *
 * @example
 * @param tree {id: 0, name: 'zarzad', children: [{id:1, parentGuid: 1, name: 'podzarzad'}]}
 * @param newNode {id:2, parentGuid: 0, name: 'podzarzad2'}
 *
 * @returns wholeTree {id: 0, name: 'zarzad', children: [{id:1, parentGuid: 1, name: 'newpodzarzad'},{id:2, parentGuid: 0, name: 'podzarzad2'}]}
 */

export function findAndAddChild(parent: Node, child: Node): Node | null {
  try {
    const root = { ...parent };

    const addChildren = (currentNode: Node) => {
      if (currentNode.id === child.parentGuid) {
        if (!currentNode.children) {
          currentNode.children = [];
        }
        currentNode.children.push(child);
        return;
      }

      if (currentNode.children) {
        for (const existingChild of currentNode.children as Node[]) {
          addChildren(existingChild);
        }
      }
    };

    addChildren(root);

    return root;
  } catch (err) {
    captureException(err);
    return null;
  }
}