import { omit } from 'lodash';
import { GetOneEflowQuery } from '@/__generated__/graphql';

/**
 *
 * @param data data from get one eflow
 * @returns data without __typename property which interrupt mutation
 */

export const omitTypenameInList = (data: GetOneEflowQuery['findOneEFlow']) => {
  return {
    ...data,
    commentTypes: data.commentTypes?.map((comment) =>
      omit(comment, ['__typename']),
    ),
    parameters: data.parameters?.map((param) => omit(param, ['__typename'])),
    operations: data.operations?.map((operation) =>
      omit(operation, ['__typename']),
    ),
    analyzeLeaders: data.analyzeLeaders?.map((leader) =>
      omit(leader, ['__typename']),
    ),
    workGroupUsers: data.workGroupUsers?.map((user) =>
      omit(user, ['__typename']),
    ),
  };
};
