import { useTranslation } from 'react-i18next';
import 'react-photo-view/dist/react-photo-view.css';

import { Box } from '@mui/material';
import { styled } from '@mui/styles';

import { Delete, Download, Preview } from '@core/components';
import { FileTableRowMenuTypes } from '@core/components/Files/FileTable/FileTableRowMenu/FileTableRowMenu.types.ts';

import { useFilePreviewContext } from '@/core/contexts';

export const FileTableRowMenu = (props: FileTableRowMenuTypes) => {
  const { t: commonT } = useTranslation();
  const { openPreview } = useFilePreviewContext();

  return (
    <>
      <FlexRowStack>
        <Preview
          wrapperProps={{
            title: commonT('common.preview'),
            onClick: () => {
              openPreview(props.file);
            },
          }}
        />
        {props.downloadEnabled && (
          <Download
            wrapperProps={{
              title: commonT('common.download'),
              placement: 'bottom',
              onClick: props.onDownload,
            }}
          />
        )}
        {props.deleteEnabled && (
          <Delete
            wrapperProps={{
              title: commonT('common.delete'),
              onClick: props.onDelete,
            }}
          />
        )}
      </FlexRowStack>
    </>
  );
};

const FlexRowStack = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '100%',
  gap: 1,
  padding: '0 8px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  maxWidth: '100px',
});
