import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { IconType } from '@core/components/Icons/Wrapper/type.ts';

import { IconWrapper } from './Wrapper/IconWrapper.tsx';

export const NavigateBefore = (props: IconType) => {
  return (
    <IconWrapper
      {...props.wrapperProps}
      icon={<NavigateBeforeIcon {...props.iconProps} />}
    />
  );
};
