import React from 'react';

import { GraphIconTypes } from '@kpi/common/GraphIconComponent/GraphIcon.types.ts';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip, Typography } from '@mui/material';

const GraphInfoIconWrapper = (props: GraphIconTypes) => {
  return (
    <Tooltip
      title={
        <div>
          {props.graphs.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 'medium',
              }}
            >
              <Typography>&#x2022; {item}</Typography>
            </div>
          ))}
        </div>
      }
    >
      <IconButton>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default GraphInfoIconWrapper;
