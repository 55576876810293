import { useTranslation } from 'react-i18next';

import { useNetworkState } from '@uidotdev/usehooks';

import { CloudOff } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';

import { theme } from '@/theme';

export const NetworkIndicatorBar = () => {
  const { online: isOnline, effectiveType } = useNetworkState();
  const { t: commonT } = useTranslation();

  if (effectiveType && ['slow-2g', '2g'].includes(effectiveType))
    return (
      <Container>
        <WarningText>{commonT('common.weakConnection')}</WarningText>
        <CloudOff
          sx={{
            color: theme.palette.common.white,
          }}
        />
      </Container>
    );

  if (isOnline) return null;

  return (
    <Container>
      <WarningText>{commonT('common.offline')}</WarningText>
      <CloudOff
        sx={{
          color: theme.palette.common.white,
        }}
      />
    </Container>
  );
};

const Container = styled(Box)({
  backgroundColor: theme.palette.error[400],
  width: '100%',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '4px',
});

const WarningText = styled(Typography)({
  color: theme.palette.common.white,
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 500,
});
